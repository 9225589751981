import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { useConfigs } from "api/useFirebase";
import { ComponentParameter, SelectOption } from "model/datatypes";

export const ConfigSelector: React.FC<{
  parameter: ComponentParameter;
  onUpdate: (newParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate }) => {
  const { configs, loadingConfigs } = useConfigs(parameter.tag);
  const options: SelectOption[] = configs.map((config) => ({
    id: config.Name,
    display: config.Name,
    value: config.Name,
  }));
  return (
    <Dropdown
      className={`${loadingConfigs ? "opacity-50" : ""} bg-white`}
      options={options}
      onSelect={(dropdownOption) => {
        onUpdate({ ...parameter, value: dropdownOption.value });
      }}
      selectedID={parameter.value === null ? "default" : (parameter.value as string)}
      placeholder={options.length > 0 ? "Select value" : "No configs found"}
      emptyMsg="No configs found for tag"
    />
  );
};
