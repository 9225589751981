import { PlusCircle } from "@phosphor-icons/react";
import { Tag } from "model/datatypes";
import React, { useState, useMemo, useRef, useCallback, useEffect } from "react";
import { escapeRegExp } from "utils/escapeRegExp";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import { useMaxHeightTransitionAutoAnimate } from "utils/hooks/useMaxHeightTransitionAutoAnimate";
import { ensureInView } from "utils/jsUtils/ensureInView";
import highlightMatchingSubString from "utils/jsUtils/highlightMatchingSubString";
import { sortAlphabetical } from "../../utils/jsUtils/sortAlphabetical";
import { TagInline } from "./TagInline";

const TagSelector: React.FC<{
  tags: (string | Tag)[];
  selectedTagIDs: string[];
  selectTag: (tagID: string) => void;
  showInlineTags?: boolean;
  removeTag?: (tagID: string) => void;
  canAddNewTag?: true;
  inputPlaceholder?: string;
  type?: string;
  headless?: true;
  className?: string;
}> = ({
  tags,
  selectedTagIDs,
  selectTag,
  showInlineTags,
  removeTag,
  canAddNewTag,
  inputPlaceholder,
  type = "tags",
  headless,
  className = "",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [matchedTags, setMatchedTags] = useState<Tag[]>([]);
  const [showAddNewTag, setShowAddNewTag] = useState<boolean>(canAddNewTag || false);
  const [inputAlreadyAdded, setInputAlreadyAdded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { open, setOpen, isOverflown } = useMaxHeightTransitionAutoAnimate(
    "0px",
    "400px",
    100,
    false,
    listRef
  );
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [keyboardController, setKeyboardController] = useState(true);

  const closeDropdown = useCallback(() => {
    setOpen(false);
    setFocusedIndex(0);
  }, [setOpen, setFocusedIndex]);

  const handleTagClick = useCallback(
    (tagID: string) => {
      if (tagID.trim().length === 0) return;
      selectTag(tagID.trim());
      inputRef.current?.focus();
      setSearchTerm("");
    },
    [selectTag, setSearchTerm]
  );

  useClickOutsideEffect(ref, closeDropdown);

  // Handles filtering tags to display in dropdown
  useEffect(() => {
    if (searchTerm.length > 0) setOpen(true);

    const remainingTags = tags
      .map((tag) => (typeof tag === "string" ? { id: tag, displayName: tag } : tag))
      .filter((tag) => !selectedTagIDs.some((id) => id === tag.id)); // Don't include selected tags
    const escapedRegExpSearchTerm = escapeRegExp(searchTerm);
    const matchingTags = remainingTags
      .filter(
        // Find the search word in displaynames
        (tag) => tag.displayName.search(new RegExp(escapedRegExpSearchTerm, "i")) !== -1
      )
      .sort((a, b) => sortAlphabetical(a.displayName, b.displayName));

    // Shows "add new tag"-item or "tag already added"-item
    // if new tags can be added and no full match is found in tags
    if (canAddNewTag) {
      const hasFullMatch = matchingTags.some((tag) => tag.displayName === searchTerm);
      setShowAddNewTag(false);
      setInputAlreadyAdded(false);

      if (!hasFullMatch && searchTerm.length > 0) {
        if (selectedTagIDs.includes(searchTerm)) {
          setInputAlreadyAdded(true);
          setFocusedIndex(1);
        } else {
          setShowAddNewTag(true);
          setFocusedIndex(0);
        }
        matchingTags.unshift({ id: searchTerm, displayName: searchTerm });
      }
    }

    setMatchedTags(matchingTags);
  }, [searchTerm, tags, selectedTagIDs, setOpen, canAddNewTag]);

  const tagsMap = useMemo(
    () =>
      new Map(
        tags.map((tag) => {
          const id = typeof tag === "string" ? tag : tag.id;
          const displayName = typeof tag === "string" ? tag : tag.displayName;
          return [id, { id, displayName }];
        })
      ),
    [tags]
  );

  useEffect(() => {
    const parent = listRef.current;
    const focusEl = parent?.children.item(focusedIndex);
    if (!(focusEl && parent && keyboardController)) return;
    ensureInView(parent, focusEl as HTMLElement);
  }, [focusedIndex, keyboardController]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const ArrowUp = !isOverflown ? "ArrowUp" : "ArrowDown";
    const ArrowDown = ArrowUp === "ArrowUp" ? "ArrowDown" : "ArrowUp";
    const minIndex = inputAlreadyAdded ? 1 : 0;
    setKeyboardController(true);
    switch (e.code) {
      case "Backspace": {
        if (!showInlineTags || removeTag === undefined || searchTerm.length > 0) return;
        const tag = selectedTagIDs[selectedTagIDs.length - 1];
        if (tag) removeTag(tag);
        break;
      }
      case ArrowDown:
        e.preventDefault();
        setFocusedIndex((index) => (index >= matchedTags.length - 1 ? minIndex : index + 1));
        break;
      case ArrowUp:
        e.preventDefault();
        setFocusedIndex((index) => (index <= minIndex ? matchedTags.length - 1 : index - 1));
        break;
      case "Enter":
        e.preventDefault();
        if (matchedTags[focusedIndex]) handleTagClick(matchedTags[focusedIndex].id);
        setFocusedIndex((index) => (index > minIndex ? index - 1 : index));
        break;
      case "Space":
        if (canAddNewTag) e.preventDefault();
        break;
      case "Escape":
      case "Tab":
        if (open) e.stopPropagation();
        setFocusedIndex(0);
        setOpen(false);
        break;
      default:
    }
  };

  return (
    <div className="relative w-full text-sm" ref={ref}>
      <div
        className={`${
          !headless
            ? "flex flex-wrap overflow-visible rounded border bg-white px-1"
            : className
        }`}
      >
        {showInlineTags &&
          selectedTagIDs.map((tag) => (
            <TagInline
              key={tag}
              tag={tagsMap.get(tag) || tag}
              removeTag={removeTag !== undefined ? () => removeTag(tag) : undefined}
            />
          ))}
        <input
          data-testid="tagList"
          ref={inputRef}
          className="flex-grow p-1 focus:outline-none"
          placeholder={inputPlaceholder}
          onFocus={() => setOpen(true)}
          onClick={() => setOpen(true)}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => {
            e.stopPropagation();
          }}
        />
        {open && (
          <div
            data-testid="tagElement"
            style={{
              minHeight: "0px",
            }}
            onMouseMove={() => setKeyboardController(false)}
            onMouseLeave={() => setKeyboardController(true)}
            ref={listRef}
            className={`absolute left-0 z-50 mt-8 flex w-full min-w-min overflow-auto rounded-lg border border-gray-200 bg-white text-sm shadow-md
              ${
                isOverflown
                  ? " -top-12 -translate-y-full transform flex-col-reverse"
                  : " -bottom-1 translate-y-full transform flex-col"
              }
            `}
          >
            {matchedTags.map((tag, index) => {
              if (inputAlreadyAdded && index === 0) {
                return (
                  <div
                    key={tag.id}
                    className="flex w-full cursor-default border-b border-gray-100 px-4 py-1 text-trueGray-700 transition-colors"
                  >
                    {`Tag already added: "`}
                    <span className="font-bold text-indigo-600">{tag.displayName}</span>
                    {`"`}
                  </div>
                );
              }
              return (
                <div
                  data-testid={
                    index === focusedIndex && keyboardController
                      ? "focused-tagElement"
                      : "tagElement"
                  }
                  key={tag.id}
                  className={`flex w-full cursor-pointer border-b border-gray-100 px-4 py-1 text-trueGray-700 transition-colors
                ${index === focusedIndex && keyboardController && " bg-indigo-200"}
                ${keyboardController ? "duration-150" : "duration-75 hover:bg-indigo-100"}
                `}
                  onClick={() => {
                    if (inputAlreadyAdded) return;
                    handleTagClick(tag.id);
                  }}
                  onMouseMove={() => setFocusedIndex(index)}
                >
                  {index === 0 && showAddNewTag ? (
                    <>
                      <PlusCircle className="mr-2 h-4 w-4" />
                      {`Add new tag: "`}
                      <span className="font-bold text-indigo-600">{tag.displayName}</span>
                      {`"`}
                    </>
                  ) : (
                    highlightMatchingSubString(tag.displayName, searchTerm)
                  )}
                </div>
              );
            })}
            {(!matchedTags || matchedTags.length === 0) && (
              <div className="w-full py-2 text-center">No {type} found...</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagSelector;
