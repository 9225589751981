import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Organization_Pending_InvitationsQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const ORGANIZATION_PENDING_INVITATIONS = graphql(`
  query ORGANIZATION_PENDING_INVITATIONS {
    organization {
      id
      pendingInvitations {
        id
        email
        createdAt
        role
        isExpired
        expiresAt
      }
    }
  }
`);

export const useGetPendingOrganizationInvitations = () => {
  const query = useQuery(ORGANIZATION_PENDING_INVITATIONS);

  return query;
};

export type PendingOrganizationInvitation = ArrayElement<
  Organization_Pending_InvitationsQuery["organization"]["pendingInvitations"]
>;
export type PendingOrganizationInvitations =
  Organization_Pending_InvitationsQuery["organization"]["pendingInvitations"];
