import { useGlobalUser } from "Components/Providers/User/UserProvider";
import Text from "thermo/typography/Typography";

export const UserProfile = () => {
  const { firstName, lastName } = useGlobalUser();
  return (
    <div
      className="rounded-full flex shrink-0 items-center text-center justify-center
    leading-none h-6 w-6 bg-indigo-50 py-1 mr-1 transition-colors duration-75"
    >
      <Text.XSmall className="font-medium text-indigo-900">
        {firstName.slice(0, 1).toUpperCase() + lastName.slice(0, 1).toUpperCase()}
      </Text.XSmall>
    </div>
  );
};
