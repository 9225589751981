import { WarningOctagon } from "@phosphor-icons/react";
import React, { ReactNode } from "react";
import ActionModal from "./ActionModal";

interface BasicProps {
  onConfirm: () => void;
  loading?: boolean;
  confirmBtnText?: string;
  headline?: string | ReactNode;
  description?: string | ReactNode;
  warning?: true;
  disableConfirmButtonIf?: boolean;
}

interface ModalProps extends BasicProps {
  onCancel: () => void;
  open?: boolean;
  cancelBtnText?: string;
}

export const ConfirmModal: React.FC<ModalProps> = ({
  onConfirm,
  onCancel,
  cancelBtnText = "Cancel",
  confirmBtnText = "Confirm",
  open,
  loading,
  headline,
  description,
  warning,
  disableConfirmButtonIf = false,
}) => (
  <ActionModal
    onClose={onCancel}
    onSave={onConfirm}
    saveButtonName={confirmBtnText}
    cancelButtonName={cancelBtnText}
    open={open}
    loading={loading}
    buttonType={warning && "warning"}
    disableConfirmButtonIf={disableConfirmButtonIf}
  >
    <div data-testid="confirmActionModal" className="flex max-w-xl px-6 py-7">
      <div className="flex items-center justify-end pr-4">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-100">
          <WarningOctagon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
        </div>
      </div>
      <div>
        <div className="mb-2 text-lg font-normal leading-6 text-gray-900">
          {headline || "Delete?"}
        </div>
        <div className="text-sm leading-normal	text-gray-500">
          {description || "This is a destructive event that can't be undone."}
        </div>
      </div>
    </div>
  </ActionModal>
);
