import Gleap from "gleap";
import { ToastBar, Toaster } from "react-hot-toast";
import Text from "thermo/typography/Typography";
import { zToast } from "thermo/zIndex/zIndexValues";

export const Toast = () => {
  return (
    <Toaster toastOptions={{ style: { zIndex: zToast, maxWidth: "700px" } }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              <Text.Small data-testid="toast">{message}</Text.Small>
              {t.type === "error" && (
                <button
                  className="ml-2 w-28 rounded-full bg-red-100 p-1 text-sm font-medium text-red-900"
                  onClick={() => Gleap.startFeedbackFlow("bugreporting")}
                >
                  Found a bug?
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
