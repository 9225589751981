import { useGetProjectPortfolioIDs } from "Components/Portfolio/api/getProjectPortfolioIDs";
import axios from "axios";

interface Params {
  projectID: string;
  scenarioID?: string;
}

export const useTriggerPortfolioUpdate = ({ projectID, scenarioID = "" }: Params) => {
  const { portfolioIDs } = useGetProjectPortfolioIDs(projectID);
  const triggerPortfolioUpdate = async () => {
    const url = "https://results-worker-moaqps6jeq-ew.a.run.app/trigger_query";
    const body = {
      query: "portfolio",
      args: { portfolio: portfolioIDs }, // Takes an array of portfolios that should update
      project: projectID,
      scenario: scenarioID,
    };
    const config = { headers: { "Content-Type": "application/json" } };

    try {
      const response = await axios.post(url, body, config);

      if (response.status !== 200)
        console.error("Error triggering portfolio update", response);
    } catch (error) {
      console.error(error);
    }
  };

  return triggerPortfolioUpdate;
};
