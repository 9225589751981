import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useFirestore } from "api/useFirebase";
import { collection, deleteDoc, doc, onSnapshot, setDoc } from "firebase/firestore";
import { ComponentType } from "model/datatypes";
import { useEffect, useState } from "react";
import {
  convertFromFirestoreFormat,
  convertToFirestoreFormat,
} from "utils/firebase/firestoreFormatter";

/**
 * Custom hooks that return and object containing the Component Library, a function to create/update components and a function to delete components in the library.
 */
// TODO: Missing schema
export const useComponentLibrary = () => {
  const [componentLib, setComponentLib] = useState<ComponentType[]>([]);
  const fs = useFirestore();
  const activeOrganizationId = useActiveOrganizationId();

  useEffect(() => {
    if (!activeOrganizationId) return undefined;
    const collectionRef = collection(
      fs,
      "organizations",
      activeOrganizationId,
      "ComponentLibrary",
      activeOrganizationId,
      "SavedComponents"
    );
    const unsub = onSnapshot(
      collectionRef,
      (snapshot) => {
        const components: ComponentType[] = [];
        snapshot.forEach((componentDoc) => {
          if (!componentDoc.exists()) return;
          const c = convertFromFirestoreFormat({
            id: componentDoc.id,
            ...componentDoc.data(),
          }) as ComponentType;
          components.push(c);
        });
        setComponentLib(components);
      },
      (error) => console.error({ error })
    );

    return unsub;
  }, [activeOrganizationId, fs]);
  /**
   * Function that takes in ID of a component from the component library, and deletes the record from firestore.
   * @param componentID - String
   * @returns Promise<void>
   */
  const removeComponentFromLib = (componentID: string) =>
    new Promise<void>((resolve, reject) => {
      if (!activeOrganizationId) reject(new Error("Organization id missing"));
      else {
        const componentDoc = doc(
          fs,
          "organizations",
          activeOrganizationId,
          "ComponentLibrary",
          activeOrganizationId,
          "SavedComponents",
          componentID
        );
        deleteDoc(componentDoc).then(resolve).catch(reject);
      }
    });
  /**
   * Takes a componentID and new component, and overwrites the previous component with same ID in the component Library in firestore. It uses firestore Set function and can therefore also create new documents
   * @param componentID - The id (firestore document ID) of the component you want to set
   * @param newComponent - The new or update component
   * @returns Promise<void>
   */
  const updateLibraryComponent = (componentID: string, newComponent: ComponentType) =>
    new Promise<void>((resolve, reject) => {
      if (!activeOrganizationId) reject(new Error("Organization id missing"));
      else {
        const componentDoc = doc(
          fs,
          "organizations",
          activeOrganizationId,
          "ComponentLibrary",
          activeOrganizationId,
          "SavedComponents",
          componentID
        );
        setDoc(componentDoc, convertToFirestoreFormat({ ...newComponent }), { merge: true })
          .then(resolve)
          .catch(reject);
      }
    });

  return { componentLib, updateLibraryComponent, removeComponentFromLib };
};
