import {
  FileText,
  File,
  FileHtml,
  FileCsv,
  FilePdf,
  MicrosoftExcelLogo,
} from "@phosphor-icons/react";
import classNames from "utils/jsUtils/className";

type Props = {
  fileType: string;
  className?: string;
};

const FileIcon = ({ fileType, className }: Props) => {
  switch (fileType) {
    case "html":
      return <FileHtml className={classNames(className)} />;
    case "csv":
      return <FileCsv className={classNames(className)} />;
    case "pdf":
      return <FilePdf className={classNames(className)} />;
    case "text":
      return <FileText className={classNames(className)} />;
    case "xlsx":
      return <MicrosoftExcelLogo className={classNames(className)} />;
    default:
      return <File className={classNames(className)} />;
  }
};

export default FileIcon;
