import React from "react";

interface Props {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

/**
 * Component that can add a conditional wrapper element based on the condition prop.
 * @property {boolean} condition - The condition that determines whether the children should be wrapped.
 * @property {function} wrapper - The wrapper function. This is passed the children and should return the wrapper element with the children.
 * @returns Either the children or the wrapped children.
 * @example
 * let link = "...";
 *
 * return (
 *    <ConditionalWrapper
 *      condition={link.length > 0}
 *      wrapper={(children) => <a href={link}>{children}</a>}
 *    />
 *      <div>Hello, Wrapper!</div>
 *    </ConditionalWrapper>
 * )
 */
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: React.PropsWithChildren<Props>) => (
  <>
    {condition && children && wrapper(children)}
    {!condition && children}
  </>
);
