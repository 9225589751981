import ActionModal from "Components/Basic/ActionModal";
import { Input, TextArea } from "Components/Basic/Input";
import { GetGroup } from "api/group/getGroup";
import { useUpdateGroupNameAndDescription } from "api/group/updateGroupNameAndDescription";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { deepEqual } from "utils/jsUtils/equality";

const EditGroup: React.FC<{
  onFinish: () => void;
  group: GetGroup;
}> = ({ onFinish, group }) => {
  const [editedGroup, setEditedGroup] = useState<GetGroup>(group);
  const { projectID = "" } = useParams<{ projectID: string }>();
  const [updateGroupNameAndDescription, { loading: updatingGroup }] =
    useUpdateGroupNameAndDescription();

  const handleSave = () => {
    if (!updatingGroup) {
      updateGroupNameAndDescription({
        variables: {
          name: editedGroup.name || "",
          projectId: projectID,
          groupId: editedGroup.id,
          description: editedGroup.description || "",
        },
      }).catch((err) => {
        console.error(err);
        toast.error("There was an issue updating the group information");
      });
      onFinish();
    }
  };

  const handleClose = () => {
    if (!updatingGroup) onFinish();
  };

  return (
    <ActionModal
      onClose={handleClose}
      disableConfirmButtonIf={deepEqual(group, editedGroup)}
      className="w-3/5"
      saveButtonName="Update group"
      blockNavigateAwayIf={!deepEqual(group, editedGroup)}
      onSave={handleSave}
    >
      <div className="z-30 flex flex-col rounded-t-lg bg-white p-4 shadow">
        <label className="text-sm font-bold">Group name</label>
        <Input
          data-testid="editGroupName"
          type="text"
          className="mb-2"
          value={editedGroup.name || ""}
          onChange={(e) => setEditedGroup({ ...editedGroup, name: e.target.value })}
        />
        <label className="text-sm font-bold">Group description</label>
        <TextArea
          data-testid="editGroupDescription"
          className="h-20"
          value={editedGroup.description || ""}
          onChange={(e) => setEditedGroup({ ...editedGroup, description: e.target.value })}
        />
      </div>
    </ActionModal>
  );
};

export default EditGroup;
