import { motion } from "framer-motion";
import React from "react";
import Skeleton from "thermo/skeleton/Skeleton";

const animate = { opacity: 1, transition: { duration: 0.4 } };

export const CommitListCardSkeleton = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={animate} exit={animate} className="mt-2">
      <Skeleton.Paragraph typography="Base" lines={3} width="w-64" />
    </motion.div>
  );
};
