import { CaretRight } from "@phosphor-icons/react";
import BlockRouteModal from "Components/Basic/BlockRouteModal";
import { Button } from "Components/Basic/Buttons";
import ToggleContainer from "Components/Basic/ToggleContainer/ToggleContainer";
import { useTriggerPortfolioUpdate } from "Components/Projects/api/triggerPortfolioUpdate";
import { useProjectType } from "api/useFirebase";
import { AnimatePresence, motion } from "framer-motion";
import { ComponentParameter, Project } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { deepEqual } from "utils/jsUtils/equality";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import ProjectParameter from "./ProjectParameter";

interface Props {
  project: Project;
  updateParameters: (updated: ComponentParameter[]) => Promise<void>;
}

const ProjectParameters: React.FC<Props> = ({ project, updateParameters }) => {
  const { parameters, projectType } = project;
  const projectTypeInfo = useProjectType(projectType);
  const parameterTypes = projectTypeInfo?.parameters;

  const [editedParameters, setEditedParameters] = useState(parameters || []);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const triggerPortfolioUpdate = useTriggerPortfolioUpdate({ projectID: project.id });

  const shownParameters = useMemo(
    () =>
      editedParameters?.filter(
        (parameter) =>
          parameter.type !== "reference" &&
          parameter.displayMode !== "hidden" &&
          parameter.displayMode !== "advanced"
      ),
    [editedParameters]
  );

  const advancedParameters = useMemo(
    () => editedParameters?.filter((parameter) => parameter.displayMode === "advanced"),
    [editedParameters]
  );

  const saveChanges = useMemo(() => {
    if (!parameters) return undefined;
    const parametersChanged = !deepEqual(parameters, editedParameters);
    if (!parametersChanged) return undefined;

    return (
      <div className="flex gap-2">
        <Button
          data-testid="saveProjectParameters"
          buttonType="primary"
          onClick={(e) => {
            e.stopPropagation();
            updateParameters(editedParameters)
              .then(triggerPortfolioUpdate)
              .catch(() => toast.error("Could not save project parameters"));
          }}
        >
          Save changes
        </Button>
        <Button
          buttonType="tertiary"
          className="text-red-400 hover:text-red-700"
          onClick={(e) => {
            e.stopPropagation();
            setEditedParameters(parameters);
          }}
        >
          Clear changes
        </Button>
      </div>
    );
  }, [editedParameters, parameters, triggerPortfolioUpdate, updateParameters]);

  const topBarHeight = document.getElementById("topBar")?.offsetHeight || 0;

  if (!editedParameters || !parameterTypes) return null;

  return (
    <ToggleContainer
      contentId={`projectParameters_${project.id}`}
      title="Project parameters"
      containerOptions={saveChanges}
      stickyHeader={{ pxOffsetTop: topBarHeight }}
      data-testid="projectParametersToggleBar"
    >
      <dl
        data-testid="projectParametersContainer"
        className="grid grid-flow-dense grid-cols-1 border-b lg:grid-cols-3 2xl:grid-cols-4"
      >
        {shownParameters?.map((parameter) => {
          const protoParam = parameterTypes.find((type) => parameter.id === type.id);

          return (
            <ProjectParameter
              key={parameter.id}
              parameter={parameter}
              parameterType={protoParam}
              onUpdate={(updatedParam) =>
                setEditedParameters(updateArrayVal(editedParameters, updatedParam))
              }
            />
          );
        })}
      </dl>
      {advancedParameters.length > 0 && (
        <div className="border-t border-b border-gray-300 bg-gray-100">
          <div
            data-testid="toggleGroupInformation"
            className="flex cursor-pointer items-center justify-between px-4 py-2 text-sm font-medium"
            onClick={() => setShowAdvanced((prev) => !prev)}
          >
            <div className="mr-1 py-1 text-left text-sm font-medium uppercase tracking-wider text-gray-700">
              Advanced parameters
            </div>
            <CaretRight
              className={`ml-4 h-5 w-5 text-gray-500 transition-all ${
                showAdvanced && "rotate-90"
              }`}
            />
          </div>
          <AnimatePresence>
            {showAdvanced && (
              <motion.div
                key="content"
                initial="closed"
                animate="open"
                exit="closed"
                transition={{ bounce: 0, ease: "easeInOut" }}
                variants={{
                  open: { height: "auto", overflow: "visible", opacity: 1 },
                  closed: { height: 0, overflow: "hidden", opacity: 0 },
                }}
                className="border-t border-gray-200 bg-white"
              >
                <dl className="grid grid-flow-dense grid-cols-1 border-b lg:grid-cols-2 2xl:grid-cols-3">
                  {advancedParameters.map((parameter) => {
                    const protoParam = parameterTypes.find((type) => parameter.id === type.id);

                    return (
                      <ProjectParameter
                        key={parameter.id}
                        parameter={parameter}
                        parameterType={protoParam}
                        onUpdate={(updatedParam) =>
                          setEditedParameters(updateArrayVal(editedParameters, updatedParam))
                        }
                      />
                    );
                  })}
                </dl>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      {saveChanges && <BlockRouteModal unsavedState={!!saveChanges} />}
    </ToggleContainer>
  );
};

export default ProjectParameters;
