import { WarningOctagon } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import React from "react";

export const FailedInvitationFetchWarning = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="flex items-center gap-2 elevation-raised p-3 border border-gray-100 rounded-md absolute bg-white top-10"
    >
      <WarningOctagon size={20} weight="fill" className="text-warning-500" />
      <p className="text-base">
        There was an issue getting your invitation information. Try again or contact your
        administrator.
      </p>
    </motion.div>
  );
};
