import useLocalStorage from "Components/hooks/useLocalStorage";
import ResizableContainer from "thermo/ResizableContainer/ResizableContainer";
import { Button } from "thermo/button/Button";
import { GlobalNavigationButtons } from "./GlobalNavigationButtons/GlobalNavigationButtons";
import { ReportIssue } from "./IssueReporter/ReportIssue";
import Legal from "./Legal/Legal";
import { MaintenanceWarning } from "./MaintenanceWarning/MaintenanceWarning";
import { OrganizationManager } from "./OrganizationManager/OrganizationManager";
import { PinnedSection } from "./Pinned/PinnedSection";
import { RecentlyViewed } from "./RecentlyViewed/RecentlyViewedActivation";
import { WorkspaceList } from "./WorkspaceList/WorkspaceList";

export const Sidebar = () => {
  const [sidebarWidth, setSidebarWidth] = useLocalStorage("sidebarWidth", 220);

  return (
    <ResizableContainer
      initialSize={sidebarWidth}
      resizeAxis="x"
      maxSize={330}
      minSize={220}
      className="border-r border-gray-200 h-full justify-between items-start overflow-y-auto"
      hideDragHandle
      data-testid="sidebar"
      onSizeChanged={setSidebarWidth}
    >
      <div className="w-full">
        <OrganizationManager />
        <div className="w-full p-4">
          <MaintenanceWarning />
          <RecentlyViewed />
          <GlobalNavigationButtons />
          <PinnedSection />
          <WorkspaceList />
        </div>
      </div>

      <div className="mt-4 p-4 flex flex-col items-start gap-0.5 text-gray-500">
        <Button size="sm" borderless>
          <a href="https://docs.numerous.com/" target="_blank" rel="noopener noreferrer">
            Documentation
          </a>
        </Button>
        <Legal />
        <ReportIssue />
      </div>
    </ResizableContainer>
  );
};
