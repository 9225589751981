import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useFirestore } from "api/useFirebase";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { SimulationScenario } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";

/**
 * Hook used to remove a mark from a scenario.
 *
 * @param scenario - the scenario that has the mark.
 * @returns handleDeleteMark() and deletingMark state
 */

type Props = {
  scenario: SimulationScenario;
};

// NUM-64
export const useDeleteScenarioMark = ({ scenario }: Props) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [deletingMark, setDeletingMark] = useState(false);
  const fs = useFirestore();

  const handleDeleteMark = async () => {
    if (!scenario || !activeOrganizationId) return;

    try {
      setDeletingMark(true);
      const docRef = doc(
        fs,
        "organizations",
        activeOrganizationId,
        "projects",
        scenario.projectID,
        "scenarios",
        scenario.id
      );
      await updateDoc(docRef, { mark: deleteField(), label: deleteField() }).then(() => {
        toast.success("Label successfully removed");
      });
    } catch (error) {
      console.error(error);
      toast.error("The label could not be removed");
    } finally {
      setDeletingMark(false);
    }
  };

  return { handleDeleteMark, deletingMark };
};
