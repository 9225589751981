import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UNARCHIVE_SCENARIO = graphql(`
  mutation MyMutation($projectId: ID!, $scenarioId: ID!) {
    scenarioUnarchive(projectId: $projectId, scenarioId: $scenarioId) {
      ... on Scenario {
        id
        archived
      }
    }
  }
`);

export const useUnarchiveScenario = () => {
  const mutation = useMutation(UNARCHIVE_SCENARIO);

  return mutation;
};
