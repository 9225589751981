import { WarningOctagon } from "@phosphor-icons/react";
import { Button } from "Components/Basic/Buttons";
import { InputNumber } from "Components/Basic/Input";
import RootPortal from "Components/Basic/RootPortal";
import { TimeslotInput } from "GraphQL/gql/graphql";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Tooltip from "thermo/tooltip/Tooltip";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import { usePagePosition } from "utils/hooks/usePagePosition";
import { TimePicker } from "./Timepicker";

interface Props {
  slot: TimeslotInput;
  onUpdate: (slot: TimeslotInput) => void;
  isNew?: boolean;
  isScrolling?: boolean;
}

export const TimeSlotEditable: React.FC<Props> = ({ slot, onUpdate, isNew, isScrolling }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [settingTime, setSettingTime] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const { pagePosition, updatePosition, width } = usePagePosition(ref);

  const handleClickoutside = useCallback(() => {
    setIsFocused(false);
    setSettingTime(false);
  }, [setIsFocused, setSettingTime]);

  useClickOutsideEffect(popupRef, handleClickoutside, `timeInput${slot.id}`);

  useEffect(() => {
    if (!isScrolling) {
      updatePosition();
    }
    if (isScrolling && settingTime) {
      setSettingTime(false);
      setIsFocused(false);
    }
  }, [isScrolling, settingTime, updatePosition]);

  const periodSetupIsInvalid = slot.startTime.isAfter(slot.endTime);
  return (
    <div
      ref={ref}
      className={`relative my-1.5 flex w-full cursor-pointer items-center rounded border ${
        isFocused ? " border-indigo-600" : "border-gray-200"
      }`}
    >
      <div
        className={`my-0 cursor-pointer select-none rounded-l-sm px-4 py-1.5 text-sm transition-all duration-100 hover:bg-indigo-600 hover:text-white focus:outline-none md:min-w-[7rem] ${
          settingTime ? "bg-indigo-600 text-white" : "bg-indigo-50 text-gray-700"
        }`}
        id={`timeInput${slot.id}`}
        onClick={() => {
          if (!settingTime) {
            setIsFocused(true);
            setSettingTime(true);
          } else {
            setIsFocused(false);
            setSettingTime(false);
          }
        }}
      >
        {`${slot.startTime.format("HH:mm")} - ${slot.endTime.format("HH:mm")}`}
      </div>
      {periodSetupIsInvalid && (
        <Tooltip label="The Start time must come before the end time within the same day. Time periods cannot pass the 24 hour period (00-24).">
          <WarningOctagon className="text-red-600 ml-1" />
        </Tooltip>
      )}
      <InputNumber
        ref={inputRef}
        value={slot.value}
        onUpdate={(val) => {
          const updatedSlot = { ...slot, value: val };
          onUpdate(updatedSlot);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        autoFocus={isNew}
        headless
        className="w-3/5 flex-shrink flex-grow rounded-l-none border-none px-2 pr-4 text-right text-sm focus:outline-none focus:ring-0"
      />
      <AnimatePresence>
        {settingTime && (
          <RootPortal>
            <motion.div
              onClick={(e) => e.stopPropagation()}
              ref={popupRef}
              className="absolute top-0 left-0 w-fit origin-top-left"
              initial={{ opacity: 0, scale: 0.75 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.75 }}
              transition={{ duration: 0.15, ease: "easeInOut" }}
              style={{
                marginTop: pagePosition.distanceTop + 30,
                marginBottom: pagePosition.distanceBottom + 30,
                marginLeft: pagePosition.distanceLeft,
                zIndex: 1000,
                width,
              }}
            >
              <TimePicker
                onUpdate={(updated) => {
                  const updatedSlot = { ...slot, ...updated };
                  onUpdate(updatedSlot);
                  setSettingTime(false);
                }}
                slot={slot}
              />
            </motion.div>
          </RootPortal>
        )}
      </AnimatePresence>
    </div>
  );
};

interface AddTimeslotProps {
  onAdd: (slot: TimeslotInput) => void;
}

export const AddTimeslot: React.FC<AddTimeslotProps> = ({ onAdd }) => {
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => {
    setisOpen(false);
  }, []);

  useClickOutsideEffect(ref, closeDropdown);

  const onAddedTime = useCallback(
    (newTimeslot: TimeslotInput) => {
      onAdd(newTimeslot);
      setisOpen(false);
    },
    [onAdd]
  );

  return (
    <div className="relative">
      <Button buttonType="white" onClick={() => setisOpen(true)}>
        Add new setting
      </Button>
      {isOpen && (
        <div ref={ref} className="absolute right-0 z-10 mt-2 w-88">
          <TimePicker onUpdate={onAddedTime} />
        </div>
      )}
    </div>
  );
};
