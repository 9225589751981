import React, { ReactNode } from "react";
import Text from "thermo/typography/Typography";

type Props = {
  icon: JSX.Element;
  children: ReactNode;
};

/**
 * Do not import from here. Import from ContextMenu.tsx
 */
export const ContextMenuItem = ({ children, icon }: Props) => {
  return (
    <div className="h-8 group/context-item flex items-center justify-start text-gray-700">
      <button className="w-full min-w-0 py-1.5 flex h-full items-center px-2">
        <span className="text-gray-500 group-hover/context-item:text-gray-700 mr-2">
          {icon}
        </span>
        <Text.Base className="truncate w-full text-start">{children}</Text.Base>
      </button>
    </div>
  );
};
