import React from "react";
import Text from "thermo/typography/Typography";

type Props = {
  onClick: () => void;
  text: string;
  icon?: JSX.Element;
  highlight?: boolean;
};

export const OrganizationPickerDropdownItem = ({
  icon,
  onClick,
  text,
  highlight,
  ...htmlProps
}: Props) => {
  return (
    <div
      data-testid="projectContextMenuButton"
      onClick={onClick}
      {...htmlProps}
      className="cursor-pointer whitespace-nowrap rounded-lg text-start flex items-center px-2 py-1.5 text-sm
       hover:bg-gray-100 focus:bg-gray-100"
    >
      {icon && icon}
      <Text.Small
        className={`${highlight && "text-indigo-700"} ${icon ? "ml-2" : "ml-0.5"} text-start`}
      >
        {text}
      </Text.Small>
    </div>
  );
};
