import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const UPDATE_WORKSPACE_INFORMATION = graphql(`
  mutation UPDATE_WORKSPACE_INFORMATION(
    $description: String
    $name: String
    $workspaceId: ID!
  ) {
    workspaceUpdateInformation(
      workspaceId: $workspaceId
      name: $name
      description: $description
    ) {
      id
      description
      name
    }
  }
`);

export const useUpdateWorkspaceInformation = () => {
  const mutation = useMutation(UPDATE_WORKSPACE_INFORMATION);

  return mutation;
};
