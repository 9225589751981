import BooleanTick from "Components/Basic/BooleanTick";
import { Button } from "Components/Basic/Buttons";
import { Input, InputNumber } from "Components/Basic/Input";
import Modal from "Components/Basic/Modal";
import JSONParameterValue from "Components/Systems/editSystem/componentEditor/parameters/JSONParameterValue";
import TimeValueInput from "Components/Systems/editSystem/componentEditor/parameters/TimeValueInput";
import { AnimatePresence } from "framer-motion";
import {
  ComponentParameter,
  ComponentParamType,
  ParameterTable,
  TimeValue,
} from "model/datatypes";
import { useState, useMemo } from "react";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import { objectMap } from "utils/jsUtils/objectMap";
import { ConfigSelector } from "./ConfigSelector";
import CurrencySelector from "./CurrencySelector";
import { FileSelector } from "./FileSelector";
import { MonthSelector } from "./MonthSelector";
import { SelectorParameter } from "./SelectorParameter";

const TableRowInputs: React.FC<{
  value: null | ParameterTable;
  tableColoumns: ComponentParamType["tableColumns"];
  onUpdate: (newVal: ParameterTable) => void;
}> = ({ value, tableColoumns, onUpdate }) => {
  const [tableInputOpen, setTableInputOpen] = useState(false);

  const amountOfRows = useMemo(() => {
    if (value === null) return 0;
    const entry = Object.entries(value);
    if (entry.length <= 0) return 0;
    const rowOne = entry[0][1];
    const length = rowOne && rowOne.length;
    return length || 0;
  }, [value]);

  const renderRowNumbers = () => {
    const rowNumbers = new Array(amountOfRows).fill(0).map((v, i) => i);
    return (
      <div className="w-8 italic">
        {rowNumbers.map((nr) => (
          <div
            key={nr}
            className={`flex justify-center border py-2 ${
              nr % 2 === 0 ? "border-gray-100 bg-gray-100" : "border-white bg-white"
            }`}
          >
            {nr}
          </div>
        ))}
      </div>
    );
  };

  const renderRowDeleteButtons = () => {
    const rowNumbers = new Array(amountOfRows).fill(0).map((v, i) => i);
    return (
      <div className="w-20 italic">
        {rowNumbers.map((nr) => (
          <div
            key={`${nr}delete`}
            className={`py-0.5 px-2 ${nr % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
          >
            <Button
              buttonType="white"
              className="w-full"
              onClick={() => {
                if (value) {
                  const updatedValues = objectMap(value, (v) => immutableSplice(v, nr, 1)); // delete number at "nr" location
                  onUpdate(updatedValues);
                }
              }}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>
    );
  };

  const renderTableCol = (tc: { uuid: string; colKey: string; colDisplay: string }) => {
    const rowData = value && value[tc.colKey];
    return (
      <div key={tc.uuid} className="mx-px w-32">
        <div className="h-12 w-full bg-gray-200 px-2 py-1">
          <div className="font-medium">{tc.colDisplay}</div>
          <div className="italic">{tc.colKey}</div>
        </div>
        {rowData &&
          rowData.map((val, i) => (
            <div
              key={`${tc.uuid}-${i + 1}`}
              className={`px-2 py-1 ${i % 2 === 0 ? "bg-gray-100" : ""}`}
            >
              <InputNumber
                headless
                className="w-full rounded border border-gray-200 px-2 py-1 focus:outline-none"
                value={val}
                onUpdate={(inputVal) => {
                  const updatedArray = immutableSplice(rowData, i, 1, inputVal);
                  onUpdate({ ...value, [tc.colKey]: updatedArray });
                }}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderEditTableInputs = () => (
    <Modal
      onClose={() => setTableInputOpen(false)}
      className="max-h-full max-w-full overflow-auto"
    >
      <div className="flex text-sm font-medium">
        <div className="w-8 ">
          <div className="h-12 bg-gray-200" />
          {renderRowNumbers()}
        </div>
        {tableColoumns?.map(renderTableCol)}
        <div className="w-20">
          <div className="h-12 bg-gray-200" />
          {renderRowDeleteButtons()}
        </div>
      </div>
      <Button
        buttonType="white"
        className="mt-4"
        onClick={() => {
          if (value) {
            const updated = objectMap(value, (v) => [...v, 0]) as ParameterTable;
            onUpdate(updated);
          } else if (tableColoumns) {
            const colEntries = tableColoumns.map((col) => [col.colKey, [0]]);
            const newParameterTable = Object.fromEntries(colEntries);
            onUpdate(newParameterTable);
          }
        }}
      >
        Add row
      </Button>
    </Modal>
  );

  return (
    <>
      <Button buttonType="white" onClick={() => setTableInputOpen(true)} className="min-w-max">
        {`${amountOfRows} row${amountOfRows === 1 ? "" : "s"}`}
      </Button>
      <AnimatePresence>{tableInputOpen && renderEditTableInputs()}</AnimatePresence>
    </>
  );
};

export const ParameterEditValue: React.FC<{
  parameter: ComponentParameter;
  paramType?: ComponentParamType;
  onUpdate: (updatedParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate, paramType }) => (
  <>
    {parameter.type === "string" && (
      <Input
        type="text"
        data-testid="parameterText"
        className="w-full text-sm outline-none focus:outline-none  focus:ring"
        value={
          typeof parameter.value === "string" ? parameter.value : parameter.value?.toString()
        }
        onChange={(e) => onUpdate({ ...parameter, value: e.target.value })}
      />
    )}
    {parameter.type === "number" && (
      <InputNumber
        data-testid="parameterInputNumber"
        className="w-full text-sm outline-none focus:outline-none  focus:ring"
        value={typeof parameter.value === "number" ? parameter.value : undefined}
        onUpdate={(newValue) => {
          if (newValue !== parameter.value) onUpdate({ ...parameter, value: newValue });
        }}
      />
    )}
    {parameter.type === "boolean" && (
      <BooleanTick
        selected={!!parameter.value}
        onCheck={(newVal) => {
          onUpdate({ ...parameter, value: newVal });
        }}
      />
    )}
    {parameter.type === "selecter" && (
      <SelectorParameter
        parameter={parameter}
        className="text-sm"
        onUpdate={(newParam) => {
          onUpdate(newParam);
        }}
      />
    )}
    {parameter.type === "month" && (
      <MonthSelector
        parameter={parameter}
        onUpdate={(newParam) => {
          onUpdate(newParam);
        }}
      />
    )}
    {parameter.type === "config" && (
      <ConfigSelector
        parameter={parameter}
        onUpdate={(newParam) => {
          onUpdate(newParam);
        }}
      />
    )}
    {parameter.type === "reference" && <div>{parameter.value?.toString()}</div>}
    {parameter.type === "file" && paramType?.fileQuery && (
      <FileSelector
        fileQuery={paramType.fileQuery}
        parameter={parameter}
        onUpdate={(newParam) => {
          onUpdate(newParam);
        }}
      />
    )}
    {parameter.type === "table" && paramType?.tableColumns && (
      <TableRowInputs
        tableColoumns={paramType.tableColumns}
        value={parameter.value as null | ParameterTable}
        onUpdate={(newVal) => {
          onUpdate({ ...parameter, value: newVal });
        }}
      />
    )}
    {parameter.type === "time_value" && (
      <TimeValueInput
        timeValue={parameter.value as TimeValue}
        updateValue={(updatedVal) => {
          onUpdate({
            ...parameter,
            value: { ...(parameter.value as TimeValue), value: updatedVal },
          });
        }}
        update={(updated) => {
          onUpdate({
            ...parameter,
            value: { ...updated },
          });
        }}
      />
    )}
    {parameter.type === "json" && (
      <JSONParameterValue
        updateMetadata={(updated) => {
          onUpdate({
            ...parameter,
            value: updated,
          });
        }}
        parameter={parameter}
      />
    )}
    {parameter.type === "currency" && (
      <CurrencySelector parameter={parameter} onUpdate={onUpdate} />
    )}
  </>
);
