import { Info, Warning } from "@phosphor-icons/react";
import React from "react";
import Text from "thermo/typography/Typography";

type Props = {
  commitId?: string;
};

export const CommitCardError = ({ commitId }: Props) => {
  return (
    <div className="flex gap-2 items-center">
      {commitId ? (
        <>
          <Warning className="text-warning-600" />
          <Text.Small className="text-gray-600" data-testid="commitCardErrorMessage">
            There was an error getting the commit
          </Text.Small>
        </>
      ) : (
        <>
          <Info className="text-indigo-600" />
          <Text.Small className="text-gray-600" data-testid="commitCardErrorMessageNoCommit">
            You have not chosen a commit to view
          </Text.Small>
        </>
      )}
    </div>
  );
};
