import { FloatingPortal } from "@floating-ui/react-dom-interactions";
import { CircleNotch } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";

interface Props {
  className?: string;
}

const LoadingOverlay = ({ className }: Props) => (
  <AnimatePresence>
    <FloatingPortal>
      <motion.div
        animate={{ y: 200, opacity: 1 }}
        initial={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={classNames(
          className,
          getZIndexClassName("zLoadingOverlay"),
          "absolute top-2 left-1/2 translate-y-1/2 rounded-lg bg-white p-3 shadow-lg"
        )}
      >
        <CircleNotch className="animate-spin" size={22} />
      </motion.div>
    </FloatingPortal>
  </AnimatePresence>
);

export default LoadingOverlay;
