import React, { ReactNode } from "react";
import classNames from "utils/jsUtils/className";

type Props = {
  children: ReactNode;
  isActive?: boolean;
};

export const SidebarButtonWrapper = ({ children, isActive }: Props) => {
  return (
    <div
      data-testid="sidebarButtonWrapper"
      className={classNames(
        isActive && "bg-slate-100",
        "flex-1 hover:bg-slate-100 text-gray-700 group/sidebar group/item transition-colors duration-100 cursor-pointer rounded-[4px] select-none"
      )}
    >
      {children}
    </div>
  );
};
