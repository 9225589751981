import React from "react";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";
import { LoadingRecentItem } from "./LoadingRecentItem";

type Props = {
  icon: JSX.Element;
  text: string;
  type: string;
  loading?: boolean;
  onClick: () => void;
  recentItemIsActivePage: boolean;
};

export const RecentItemTemplate = ({
  icon,
  text,
  type,
  loading,
  onClick,
  recentItemIsActivePage,
}: Props) => {
  if (loading) return <LoadingRecentItem />;
  return (
    <button
      data-testid="recentItem"
      onClick={onClick}
      className={classNames(
        recentItemIsActivePage && "bg-slate-100",
        "rounded-[4px] h-8 hover:bg-slate-100 w-full py-1.5 px-2 text-gray-700 flex items-center gap-2"
      )}
    >
      <Text.Small
        data-testid="recentItemType"
        className="text-gray-500 w-14 text-left capitalize"
      >
        {type}
      </Text.Small>
      <span data-testid="recentItemIcon">{icon}</span>
      <Text.Base data-testid="recentItemText" className="max-w-[20rem] truncate text-left">
        {text}
      </Text.Base>
    </button>
  );
};
