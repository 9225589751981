/**
 * Calculates whether a child within a scrollable container is visible.
 * @param child The child div element moved to.
 * @param container The scrollable parent container.
 * @param topAlign If true, the container will scroll to align the child with its top.
 * @param offsetTop An offset to be added to the top, e.g. to clear a sticky positioned header element.
 * @returns The scroll position the container should scroll to for the child element to
 * be visible or null if scrolling is not necessary to keep the child visible.
 */

interface Props {
  child: Element;
  container: Element;
  topAlign?: boolean;
  offsetTop?: number;
}

export const getScrollToPosition = ({ child, container, topAlign, offsetTop = 0 }: Props) => {
  let childRect = child.getBoundingClientRect();
  let containerRect = container.getBoundingClientRect();

  const topIsVisible = childRect.top > containerRect.top;
  const bottomIsVisible = childRect.bottom < containerRect.bottom;

  const distanceFromTop = childRect.top - containerRect.top;
  const distanceFromBottom = containerRect.bottom - childRect.bottom;

  let containerScrollPos = null;

  if (!topIsVisible || topAlign)
    containerScrollPos = container.scrollTop + distanceFromTop - offsetTop;
  if (!bottomIsVisible && !topAlign)
    containerScrollPos = container.scrollTop + Math.abs(distanceFromBottom);

  return containerScrollPos;
};
