import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { FirebaseProviderContext } from "Components/Providers/Firebase/FirebaseProvider";
// eslint-disable-next-line import/no-cycle
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { FileQuery as FileParameterQuery, OrganizationMemberRole } from "GraphQL/gql/graphql";
import app from "firebase/compat/app";
import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  getDoc,
  doc as getDocRef,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
// eslint-disable-next-line import/no-cycle
import { useDataInfo } from "grpc/api/grpcReact";
import {
  AdditionalScenarioForGraph,
  BaseScenario,
  DatasetScenario,
  FileQuery,
  LocalSimulationModel,
  Project,
  ProjectType,
  RawDataReport,
  SimFile,
  SimulationScenario,
  SystemModel,
} from "model/datatypes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useGlobalState } from "store";
import { convertFromFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { mergeArrays } from "utils/jsUtils/imutableArray";
import { useGetAllTeams } from "./team/getAllTeams";

//returns firebase state from context when callen from a component.
export const useFirebase = () =>
  useContext(FirebaseProviderContext)?.firebaseInstance as app.app.App;

export const useDeprecatedFirestore = () => {
  const fb = useFirebase();
  return fb.firestore();
};

// NUM-45
export const useProject = (projectID: string | null | undefined) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [project, setProject] = useState<Project | null>(null);
  const [loadingProject, setLoadingProject] = useState(true);
  const fb = useFirebase();
  useEffect(() => {
    if (!projectID || !activeOrganizationId) {
      setProject(null);
      return undefined;
    }

    const unsub = fb
      .firestore()
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("projects")
      .doc(projectID)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (data) {
          setProject(convertFromFirestoreFormat({ ...data, id: doc.id }) as Project);
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
        }
      });

    return () => {
      unsub();
    };
  }, [fb, projectID, loadingProject, activeOrganizationId]);
  return { project, loadingProject };
};

// Not firebase related - move somewhere else after migration
export const useHasEditAccess = (ownerId: string) => {
  const user = useGlobalUser();

  const hasEditAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner ||
      user.id === ownerId,
    [ownerId, user]
  );
  return hasEditAccess;
};

export const useUserRole = () => {
  const user = useGlobalUser();
  const { projectID } = useParams<{ projectID: string }>();
  const { project } = useProject(projectID);

  const isProjectOwner = useMemo(() => project?.ownerId === user?.id, [project, user]);
  const hasOwnerAccess = useMemo(
    () => user.activeOrganizationRole === OrganizationMemberRole.Owner,
    [user]
  );
  const hasDeveloperAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner,
    [user]
  );

  const hasSimulatorAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner ||
      user.activeOrganizationRole === OrganizationMemberRole.Simulator,
    [user]
  );

  const hasProjectEditAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner ||
      isProjectOwner,
    [user, isProjectOwner]
  );

  const hasProjectAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner ||
      user.activeOrganizationRole === OrganizationMemberRole.Simulator ||
      user.activeOrganizationRole === OrganizationMemberRole.Guest,
    [user]
  );

  const hasPortfolioManagerAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner ||
      user.activeOrganizationRole === OrganizationMemberRole.Simulator ||
      user.activeOrganizationRole === OrganizationMemberRole.PortfolioManager,
    [user]
  );

  const hasPortfolioAccess = useMemo(
    () =>
      user.activeOrganizationRole === OrganizationMemberRole.Developer ||
      user.activeOrganizationRole === OrganizationMemberRole.Owner ||
      user.activeOrganizationRole === OrganizationMemberRole.Simulator ||
      user.activeOrganizationRole === OrganizationMemberRole.PortfolioUser ||
      user.activeOrganizationRole === OrganizationMemberRole.PortfolioManager,
    [user]
  );

  return {
    hasDeveloperAccess,
    hasSimulatorAccess,
    hasOwnerAccess,
    hasProjectEditAccess,
    isProjectOwner,
    hasProjectAccess,
    hasPortfolioManagerAccess,
    hasPortfolioAccess,
  };
};

export const useFirestore = () => {
  const fb = useFirebase();
  return getFirestore(fb);
};

// TODO Depends on Jens' has answer to NUM-463
export const useProjectType = (projectTypeID?: string) => {
  const [projectType, setProjectType] = useState<ProjectType>();
  const fb = useFirebase();

  useEffect(() => {
    if (!projectTypeID) {
      setProjectType(undefined);
      return undefined;
    }
    const unsub = fb
      .firestore()
      .collection("ProjectTypes")
      .doc(projectTypeID)
      .onSnapshot(
        (doc) => {
          const data = doc.data();
          const type = convertFromFirestoreFormat({ ...data, id: doc.id }) as ProjectType;
          setProjectType(type);
        },
        (error) => {
          console.error({ error });
        }
      );
    return () => {
      unsub();
    };
  }, [fb, projectTypeID]);

  return projectType;
};

// NUM-494
export const useSystemName = (systemID: string | undefined) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [systemName, setSystemName] = useState<null | string>(null);
  const [loadingSystemName, setLoadingSystemName] = useState(true);
  const fb = useFirebase();

  useEffect(() => {
    if (!systemID || !activeOrganizationId) {
      setLoadingSystemName(false);
      return;
    }

    fb.firestore()
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("systems")
      .doc(systemID)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            setSystemName(data.displayName);
            setLoadingSystemName(false);
          } else {
            setLoadingSystemName(false);
          }
        } else {
          setSystemName(null);
          setLoadingSystemName(false);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("There was an issue getting the system name");
      });
  }, [fb, systemID, loadingSystemName, activeOrganizationId]);
  return { systemName, loadingSystemName };
};

// NUM-473
export const useScenarios = (projectID: string | null, groupID: string, limit?: number) => {
  const [scenarios, setScenarios] = useState<SimulationScenario[] | null>(null);
  const [loadingScenarios, setLoadingScenarios] = useState(false);
  const fb = useFirebase();
  const organizationId = useActiveOrganizationId();
  useEffect(() => {
    if (!projectID || !organizationId) return undefined;
    setLoadingScenarios(true);
    const unsub = fb
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("projects")
      .doc(projectID)
      .collection("scenarios")
      .where("parentScenarioID", "==", null)
      .where("groupID", "==", groupID)
      .limit(limit || 30)
      .onSnapshot((snap) => {
        const tempScenarios: SimulationScenario[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          if (data.optimizationTargetScenarioID) return;
          const tempScenario = convertFromFirestoreFormat({
            ...data,
            id: doc.id,
          }) as SimulationScenario;
          if (data?.mark && organizationId) {
            tempScenario.label = {
              label_id: data.mark.markId,
              created: data.mark.creationDate,
              owner_id: data.mark.ownerId,
              comment: data.mark.comment || "",
              organizationID: organizationId,
            };
            delete (tempScenario as any).mark;
          }

          tempScenarios.push(tempScenario);
        });
        setScenarios(tempScenarios);
        setLoadingScenarios(false);
      });
    return unsub;
  }, [fb, projectID, groupID, limit, organizationId]);
  return { scenarios, loadingScenarios };
};

// NUM-474
export const useScenariosInProject = (projectID: string) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [scenarios, setScenarios] = useState<SimulationScenario[]>([]);
  const [loadingScenariosInProject, setLoadingScenariosInProject] = useState(false);
  const fb = useFirebase();

  useEffect(() => {
    if (!activeOrganizationId) return;
    setLoadingScenariosInProject(true);
    fb.firestore()
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("projects")
      .doc(projectID)
      .collection("scenarios")
      .where("parentScenarioID", "==", null)
      .get()
      .then((query) => {
        const tempScenarios: SimulationScenario[] = [];
        query.forEach((doc) => {
          tempScenarios.push(
            convertFromFirestoreFormat({
              ...doc.data(),
              id: doc.id,
            }) as SimulationScenario
          );
        });
        setScenarios(tempScenarios);
        setLoadingScenariosInProject(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingScenariosInProject(false);
      });
  }, [activeOrganizationId, fb, projectID]);

  return { scenarios, loadingScenariosInProject };
};

// NUM-475
export const useScenario = (projectID: string, scenarioID: string, listen = true) => {
  const activeOrganizationId = useActiveOrganizationId();
  const fs = useFirestore();
  const [scenario, setScenario] = useState<SimulationScenario>();
  const [scenarioDataset, setScenarioDataset] = useState<DatasetScenario>();
  const [loadingScenario, setLoadingScenario] = useState(true);
  const organizationId = useActiveOrganizationId();

  useEffect(() => {
    if (!projectID || !scenarioID || !activeOrganizationId) {
      setScenario(undefined);
      setScenarioDataset(undefined);
      return undefined;
    }
    const docRef = getDocRef(
      fs,
      "organizations",
      activeOrganizationId,
      "projects",
      projectID,
      "scenarios",
      scenarioID
    );
    const successCallback = (docSnapshot: DocumentSnapshot<DocumentData>) => {
      const rawData = docSnapshot.data();
      if (rawData === undefined) {
        setLoadingScenario(false);
        return;
      }
      const data = convertFromFirestoreFormat({
        ...rawData,
        id: docSnapshot.id,
      });
      if (data.type === "scenario") {
        const tempScenario = data as SimulationScenario;
        if (data?.mark && organizationId) {
          tempScenario.label = {
            label_id: data.mark.markId,
            created: data.mark.creationDate,
            owner_id: data.mark.ownerId,
            comment: data.mark.comment || "",
            organizationID: organizationId,
          };
          delete (tempScenario as any).mark;
        }
        setScenario(tempScenario);
      } else if (data.type === "dataset") setScenarioDataset(data as DatasetScenario);

      setLoadingScenario(false);
    };
    const errorCallback = (error: FirestoreError) => {
      console.error(error);
      setLoadingScenario(false);
    };
    if (listen) {
      const unsub = onSnapshot(docRef, successCallback, errorCallback);
      return unsub;
    }
    getDoc(docRef).then(successCallback).catch(errorCallback);
    return undefined;
  }, [
    fs,
    projectID,
    scenarioID,
    loadingScenario,
    organizationId,
    listen,
    activeOrganizationId,
  ]);
  return { scenario, scenarioDataset, loadingScenario };
};

// Utility
const setSystemListener = (
  fs: app.firestore.Firestore,
  setSystems: (systems: SystemModel[]) => void,
  queryParams: {
    organisationID: string;
    status: SystemModel["status"];
    ownerId?: string;
    teamIds?: string[];
    collaboratorId?: string;
  }
) => {
  const systemCollection = fs
    .collection("organizations")
    .doc(queryParams.organisationID)
    .collection("systems");
  let query = systemCollection
    .where("organisation", "==", queryParams.organisationID)
    .where("status", "==", queryParams.status);

  if (queryParams.teamIds)
    query = query.where("teams", "array-contains-any", queryParams.teamIds);

  if (queryParams.collaboratorId)
    query = query.where("collaborators", "array-contains", queryParams.collaboratorId);

  if (queryParams.ownerId) query = query.where("ownerId", "==", queryParams.ownerId);

  const unsub = query.onSnapshot((snap) => {
    const systems: SystemModel[] = [];
    snap.docs.forEach((doc) => {
      const data = doc.data();
      if (data.created !== null) {
        const system = convertFromFirestoreFormat({
          ...data,
          id: doc.id,
          version: data.version || 0,
        }) as SystemModel;
        systems.push(system);
      }
    });
    setSystems(systems);
  });
  return unsub;
};

// NUM-481
export const useSimulationModels = () => {
  const { data: teamsData } = useGetAllTeams();
  const teamIds = teamsData?.teams.edges.map((edge) => edge.node.id);
  const user = useGlobalUser();
  const activeOrganizationId = useActiveOrganizationId();
  const { hasDeveloperAccess } = useUserRole();

  const [allPublicModels, setAllPublicModels] = useState<SystemModel[]>([]);
  const [draftModels, setDraftModels] = useState<SystemModel[]>([]);
  const [optimizationModels, setOptimizationModels] = useState<SystemModel[]>([]);
  const [collaboratorModels, setCollaboratorModels] = useState<SystemModel[]>([]);
  const [teamCollaboratorModels, setTeamCollaboratorModels] = useState<SystemModel[]>([]);

  const [allCurrentModels, setAllCurrentModels] = useState<SystemModel[]>([]);

  const setOptimizationAndAllCurrentModels = useCallback(
    (allModels: SystemModel[]) => {
      let tempAllCurrentModels: SystemModel[] = [];
      let tempOptimizationModels: SystemModel[] = [];
      allModels.forEach((model) => {
        if (model.modelType === "optimization_model") tempOptimizationModels.push(model);
        else tempAllCurrentModels.push(model);
      });
      setAllCurrentModels(tempAllCurrentModels);
      setOptimizationModels(tempOptimizationModels);
    },
    [setAllCurrentModels, setOptimizationModels]
  );

  //set all models for developer:
  useEffect(() => {
    if (hasDeveloperAccess) {
      const allModels = [...allPublicModels, ...draftModels];
      setOptimizationAndAllCurrentModels(allModels);
    }
  }, [hasDeveloperAccess, allPublicModels, draftModels, setOptimizationAndAllCurrentModels]);

  //setAllmodels for non-developers
  useEffect(() => {
    if (!hasDeveloperAccess) {
      const allModels = mergeArrays(
        collaboratorModels,
        teamCollaboratorModels
      ) as SystemModel[];
      setOptimizationAndAllCurrentModels(allModels);
    }
  }, [
    hasDeveloperAccess,
    collaboratorModels,
    teamCollaboratorModels,
    setOptimizationAndAllCurrentModels,
  ]);

  const fb = useFirebase();

  //get all public models for developers
  useEffect(() => {
    if (!hasDeveloperAccess || !activeOrganizationId) return undefined;
    const unsub = setSystemListener(fb.firestore(), setAllPublicModels, {
      organisationID: activeOrganizationId,
      status: "published",
    });
    return unsub;
  }, [fb, hasDeveloperAccess, activeOrganizationId]);

  //get all drafs for developers:
  useEffect(() => {
    if (!hasDeveloperAccess || !activeOrganizationId) return undefined;
    const unsub = setSystemListener(fb.firestore(), setDraftModels, {
      organisationID: activeOrganizationId,
      status: "draft",
      ownerId: user.id,
    });
    return unsub;
  }, [activeOrganizationId, fb, hasDeveloperAccess, user]);

  //get collaboartor models for non-dev users:
  useEffect(() => {
    if (hasDeveloperAccess || !activeOrganizationId) return undefined;
    const unsub = setSystemListener(fb.firestore(), setCollaboratorModels, {
      organisationID: activeOrganizationId,
      status: "published",
      collaboratorId: user.id,
    });
    return unsub;
  }, [activeOrganizationId, fb, hasDeveloperAccess, user]);

  //get team collaborator models for non-developers
  useEffect(() => {
    if (hasDeveloperAccess || (teamIds && teamIds.length >= 0) || !activeOrganizationId)
      return undefined;
    const unsub = setSystemListener(fb.firestore(), setTeamCollaboratorModels, {
      organisationID: activeOrganizationId,
      status: "published",
      teamIds,
    });
    return unsub;
  }, [activeOrganizationId, fb, hasDeveloperAccess, teamIds, user]);

  return { draftModels, allCurrentModels, optimizationModels, allPublicModels };
};

// NUM-482
export const useArchivedSimulationModels = () => {
  const { hasDeveloperAccess } = useUserRole();
  const activeOrganizationId = useActiveOrganizationId();

  const [archivedModels, setArchivedModels] = useState<SystemModel[]>([]);
  const fs = useDeprecatedFirestore();
  //get all public models for developers
  useEffect(() => {
    if (!hasDeveloperAccess || !activeOrganizationId) return undefined;
    const unsub = setSystemListener(fs, setArchivedModels, {
      organisationID: activeOrganizationId,
      status: "archived",
    });
    return unsub;
  }, [fs, hasDeveloperAccess, activeOrganizationId]);

  const { models, optimizationModels } = useMemo(() => {
    let tempModels: SystemModel[] = [];
    let tempOptimizationModels: SystemModel[] = [];
    archivedModels.forEach((model) => {
      if (model.modelType === "optimization_model") tempOptimizationModels.push(model);
      else tempModels.push(model);
    });
    return { models: tempModels, optimizationModels: tempOptimizationModels };
  }, [archivedModels]);

  return { archivedModels: models, archivedOptimizationModels: optimizationModels };
};

// NUM-480
export const useFixedSystems = (fixedSystems?: { systemID: string }[]) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [systemsAvailable, setSystemsAvailable] = useState<SystemModel[]>([]);
  const [fsSystemsAvailable, setFsSystemsAvailable] = useState<SystemModel[]>([]);

  const fs = useDeprecatedFirestore();
  const systemIDs: string[] = useMemo(() => {
    return fixedSystems ? fixedSystems.map((systemRef) => systemRef.systemID) : [];
  }, [fixedSystems]);

  useEffect(() => {
    if (systemIDs.length <= 0 || !activeOrganizationId) return undefined;
    const unsub = fs
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("systems")
      .onSnapshot((snap) => {
        const systems: SystemModel[] = [];
        snap.docs.forEach((doc) => {
          const system = convertFromFirestoreFormat({
            ...doc.data(),
            id: doc.id,
          }) as SystemModel;
          if (system.status !== "archived") systems.push(system);
        });
        setFsSystemsAvailable(systems);
      });
    return () => {
      unsub();
    };
  }, [systemIDs, fs, activeOrganizationId]);

  useEffect(() => {
    const temp = systemIDs.length
      ? fsSystemsAvailable.filter((system) => {
          if (!system.id) return null;
          return systemIDs.includes(system.id);
        })
      : fsSystemsAvailable;
    setSystemsAvailable(temp);
  }, [fsSystemsAvailable, systemIDs]);

  return systemsAvailable;
};

// NUM-484
export const useLocalSimulationModel = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [simulationModel, setSimulationModel] = useState<LocalSimulationModel | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (!simModelID || !projectID || !scenarioID || !activeOrganizationId) {
      setSimulationModel(null);
      return undefined;
    }
    const unsub = fb
      .firestore()
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("projects")
      .doc(projectID)
      .collection("scenarios")
      .doc(scenarioID)
      .collection("SimulationModel")
      .doc(simModelID)
      .onSnapshot(
        (doc) => {
          const data = doc.data();
          if (doc.exists && data) {
            const model: LocalSimulationModel = convertFromFirestoreFormat({
              ...data,
              id: doc.id,
              version: data.version || 0,
              localVersion: data.localVersion || 0,
              componentTypes: data.componentTypes || [],
              containers: data.containers || [],
            }) as LocalSimulationModel;
            setSimulationModel(model);
          } else {
            console.error("Error fetching the model");
            console.error(`Document exists: ${doc.exists}`);
            setSimulationModel(null);
          }
        },
        (error) => console.error(error)
      );
    return unsub;
  }, [activeOrganizationId, fb, projectID, scenarioID, simModelID]);
  return simulationModel;
};

// NUM-485
export const useSimFiles = ({
  tags,
  type,
  projectRef: project,
  modelRef: model,
}: FileQuery) => {
  const fs = useDeprecatedFirestore();
  const activeOrganizationId = useActiveOrganizationId();

  const [fsFiles, setFsFiles] = useState<SimFile[]>([]);
  const [simFiles, setSimFiles] = useState<SimFile[]>([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  useEffect(() => {
    if (!activeOrganizationId) return undefined;

    const projectID = project?.id;
    const modelID = model?.id;

    let collection:
      | app.firestore.Query<app.firestore.DocumentData>
      | app.firestore.CollectionReference<app.firestore.DocumentData> = fs
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("files");
    if (projectID) collection = collection.where("projectID", "==", projectID);
    if (modelID) collection = collection.where("modelID", "==", modelID);
    if (type) collection = collection.where("type", "==", type);

    setLoadingFiles(true);
    const unsub = collection.onSnapshot((snap) => {
      const files: SimFile[] = [];
      snap.docs.forEach((doc) => {
        files.push({ ...doc.data(), id: doc.id } as SimFile);
      });
      setLoadingFiles(false);
      setFsFiles(files);
    });
    return () => {
      setLoadingFiles(false);
      unsub();
    };
  }, [fs, project, model, type, activeOrganizationId]);

  useEffect(() => {
    const temp = tags.length
      ? fsFiles.filter((file) => {
          if (!file.tags) return null;
          return file.tags.some((tag) => tags.includes(tag));
        })
      : fsFiles;
    setSimFiles(temp);
  }, [fsFiles, tags]);

  return { simFiles, loadingFiles };
};

export const useSimFilesGraphQLType = (fileQuery: FileParameterQuery | undefined | null) => {
  const fs = useDeprecatedFirestore();
  const activeOrganizationId = useActiveOrganizationId();

  const [fsFiles, setFsFiles] = useState<SimFile[]>([]);
  const [simFiles, setSimFiles] = useState<SimFile[]>([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  useEffect(() => {
    if (!activeOrganizationId || !fileQuery?.mimeType) return undefined;

    let collection:
      | app.firestore.Query<app.firestore.DocumentData>
      | app.firestore.CollectionReference<app.firestore.DocumentData> = fs
      .collection("FileLibrary")
      .doc(activeOrganizationId)
      .collection("files")
      .where("type", "==", fileQuery.mimeType);

    setLoadingFiles(true);
    const unsub = collection.onSnapshot((snap) => {
      const files: SimFile[] = [];
      snap.docs.forEach((doc) => {
        files.push({ ...doc.data(), id: doc.id } as SimFile);
      });
      setLoadingFiles(false);
      setFsFiles(files);
    });
    return () => {
      setLoadingFiles(false);
      unsub();
    };
  }, [fs, fileQuery?.mimeType, activeOrganizationId]);

  useEffect(() => {
    const temp = fileQuery?.fileTags.length
      ? fsFiles.filter((file) => {
          if (!file.tags) return null;
          return file.tags.some((tag) => fileQuery.fileTags.includes(tag));
        })
      : fsFiles;
    setSimFiles(temp);
  }, [fsFiles, fileQuery?.fileTags]);

  return { simFiles, loadingFiles };
};

// NUM-486
//tags for finding files:
export const useFileTags = () => {
  const activeOrganizationId = useActiveOrganizationId();

  const [tags, setTags] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    if (!activeOrganizationId) return undefined;
    const unsub = fb
      .firestore()
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("FileLibrary")
      .doc(activeOrganizationId)
      .onSnapshot((doc) => {
        const tempTags: string[] = doc.data()?.tags;
        const tempTypes: string[] = doc.data()?.types;
        if (tempTags) setTags(tempTags);
        if (tempTypes) setTypes(tempTypes);
      });
    return unsub;
  }, [activeOrganizationId, fb]);

  return { tags, types };
};

type Config = {
  id: string;
  Name: string;
  Tags: string[];
  [key: string]: any;
};

//this should be moved to some specified EM extension some time.
export const useConfigs = (tag?: string) => {
  const [configs, setConfigs] = useState<Config[]>([]);
  const [loadingConfigs, setLoadingConfigs] = useState(false);
  const fb = useFirebase();
  const activeOrganizationId = useActiveOrganizationId();

  useEffect(() => {
    if (!tag || !activeOrganizationId) {
      setConfigs([]);
      return undefined;
    }
    setLoadingConfigs(true);
    const unsub = fb
      .firestore()
      .collection("Configs")
      .where("Tags", "array-contains", tag)
      .onSnapshot((snap) => {
        const confs: any[] = [];
        snap.docs.forEach((doc) => {
          confs.push({ ...doc.data(), id: doc.id });
        });
        setLoadingConfigs(false);
        setConfigs(confs);
      });
    return unsub;
  }, [activeOrganizationId, fb, tag]);
  return { configs, loadingConfigs };
};

// TODO Return here after figuring out report to graphql functionality
export const useAdditionalScenarios = (
  projectId: string,
  scenarioId: string,
  reportId: string,
  lineGraphId: string
) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [additionalScenarios, setAdditionalScenarios] =
    useState<AdditionalScenarioForGraph[]>();
  const fs = useDeprecatedFirestore();

  useEffect(() => {
    if (!activeOrganizationId) return undefined;
    const unsub = fs
      .collection("organizations")
      .doc(activeOrganizationId)
      .collection("projects")
      .doc(projectId)
      .collection("scenarios")
      .doc(scenarioId)
      .collection("RawDataReports")
      .doc(reportId)
      .collection("lineGraphAdditionalScenariosData")
      .doc(lineGraphId)
      .collection("additionalScenariosForGraph")
      .onSnapshot((snap) => {
        const tempAdditionalScenarios = snap.docs.map(
          (doc) => doc.data() as AdditionalScenarioForGraph
        );
        setAdditionalScenarios(tempAdditionalScenarios);
      });
    return unsub;
  }, [fs, projectId, reportId, scenarioId, lineGraphId, activeOrganizationId]);

  return additionalScenarios;
};

// TODO Return here after figuring out report to graphql functionality
export const useRawdataReports = (
  modelID: string,
  projectID?: string,
  scenarioID?: string
) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [rawDataReports, setRawDataReports] = useState<RawDataReport[]>([]);
  const fs = useDeprecatedFirestore();
  useEffect(() => {
    if (!activeOrganizationId) return undefined;
    const docSource =
      projectID && scenarioID
        ? fs
            .collection("organizations")
            .doc(activeOrganizationId)
            .collection("projects")
            .doc(projectID)
            .collection("scenarios")
            .doc(scenarioID)
        : fs

            .collection("organizations")
            .doc(activeOrganizationId)
            .collection("systems")
            .doc(modelID);

    const unsub = docSource.collection("RawDataReports").onSnapshot((snap) => {
      const reports: RawDataReport[] = [];
      snap.docs.forEach((doc) => {
        reports.push({ ...doc.data(), id: doc.id } as RawDataReport);
      });
      setRawDataReports(reports);
    });
    return () => {
      unsub();
      setRawDataReports([]);
    };
  }, [modelID, projectID, scenarioID, fs, activeOrganizationId]);

  return rawDataReports;
};

// NUM-502
export const useIdToken = () => {
  const fb = useFirebase();
  const [idToken, setIdToken] = useState<string | null>(null);
  const { authStatus } = useGlobalState();

  useEffect(() => {
    if (authStatus !== "authenticated") {
      setIdToken(null);
      return undefined;
    }
    const handleSetIdToken = () => {
      fb.auth().currentUser?.getIdToken().then(setIdToken).catch(console.error);
    };

    handleSetIdToken();

    // Firebase id tokens expire after one hour. We force refresh the idToken to not have expired id token by mistake
    const intervalID = setInterval(handleSetIdToken, 3300000); // Fires every 55 mins
    return () => clearInterval(intervalID);
  }, [fb, authStatus]);
  return idToken;
};

// TODO Return here after figuring out input graphql functionality
export const useInputFullInputSource = (
  scenarioID: string,
  projectID: string,
  type: "scenario" | "dataset",
  offset?: number
) => {
  const [fullSource, setFullSource] = useState<null | BaseScenario>(null);
  const fs = useDeprecatedFirestore();
  const activeOrganizationId = useActiveOrganizationId();
  useEffect(() => {
    if (!activeOrganizationId) return;
    fs.collection("organizations")
      .doc(activeOrganizationId)
      .collection("projects")
      .doc(projectID)
      .collection("scenarios")
      .doc(scenarioID)
      .get()
      .then((doc) => {
        const scenario = { ...doc.data(), type } as BaseScenario;

        //@ts-ignore
        if (scenario.datasetName) scenario.scenarioName = scenario.datasetName;

        setFullSource(scenario);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [scenarioID, projectID, type, fs, activeOrganizationId]);

  const { timeInfo, producingData } = useDataInfo(
    projectID,
    scenarioID,
    fullSource?.lastest_main_execution,
    offset
  );

  return { fullSource, timeInfo, producingData };
};
