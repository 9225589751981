import { Workspace } from "api/workspace/getWorkspaces";
import React, { useState } from "react";
import { Button } from "thermo/button/Button";
import Popover from "thermo/popover/Popover";
import { SearchBar } from "thermo/searchbar/SearchBar";
import DeleteWorkspace from "../DeleteWorkspace";
import ExistingWorkspaceMembers from "./ExistingWorkspaceMembers";
import { InviteWorkspaceUsers } from "./InviteWorkspaceUsers";

type Props = {
  isWorkspaceOwner: boolean;
  workspace: Workspace;
  closeModal: () => void;
};

export const MemberManagementSection = ({
  isWorkspaceOwner,
  workspace,
  closeModal,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div data-testid="manageMembersHeader">
            Manage members
            <span className="text-sm text-gray-600"> ({workspace.members.length})</span>
          </div>
        </div>
        <div className="flex items-center mr-2 gap-2">
          <SearchBar
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            placeholder="Search members"
          />
          {isWorkspaceOwner && (
            <Popover.Root>
              <Popover.Trigger>
                <Button data-testid="inviteWorkspaceUserButton" size="sm">
                  Invite Users
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <InviteWorkspaceUsers workspace={workspace} closeModal={closeModal} />
              </Popover.Content>
            </Popover.Root>
          )}
        </div>
      </div>
      <div className="mt-4">
        <ExistingWorkspaceMembers workspace={workspace} searchTerm={searchTerm} />
      </div>
      {isWorkspaceOwner && (
        <>
          <div className="my-5 h-px bg-gray-200" />
          <div className="mt-4 w-full items-center justify-between">
            <DeleteWorkspace workspaceId={workspace.id} workspaceName={workspace.name} />
          </div>
        </>
      )}
    </div>
  );
};
