import { FileArrowDown, LinkSimpleHorizontal, X } from "@phosphor-icons/react";
import { Button } from "Components/Basic/Buttons";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import Modal from "Components/Basic/Modal";
import { useGetOrganization } from "api/organisation/getOrganization";
import { useFirebase, useIdToken, useUserRole } from "api/useFirebase";
import { getDownloadFileUrl } from "grpc/api/grpcUtilAPI";
import { ReportChange, ScenarioOutputFile } from "model/datatypes";
import React, { useState, useEffect, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useGlobalState } from "store";
import useCopyToClipboard from "utils/clipboard/copyToClipboard";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

const UndoIcon = () => (
  <svg
    className="h-full w-full"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L3.71 8.71C3.08 8.08 2 8.52 2 9.41V15c0 .55.45 1 1 1h5.59c.89 0 1.34-1.08.71-1.71l-1.91-1.91c1.39-1.16 3.16-1.88 5.12-1.88 3.16 0 5.89 1.84 7.19 4.5.27.56.91.84 1.5.64.71-.23 1.07-1.04.75-1.72C20.23 10.42 16.65 8 12.5 8z" />
  </svg>
);

// TODO Scenario graphql
const ReportEditor: React.FC<{
  onClose?: () => void;
  report_download: ScenarioOutputFile;
  savedChanges?: ReportChange[];
  sourcetype: "group" | "scenario";
  sourceID: string;
  useModal: boolean;
  reportName: string;
}> = ({
  report_download,
  onClose,
  savedChanges,
  sourceID,
  sourcetype,
  useModal = false,
  reportName,
}) => {
  const activeOrganization = useGetOrganization().data?.organization;
  const [changeLog, setChangeLog] = useState<ReportChange[][]>([savedChanges || []]); //list of changes in session
  const [changes, setChanges] = useState(savedChanges || []); //the changes actually send to the report
  const [selectedChange, setSelectedChange] = useState<ReportChange | null>(null); //change being edited
  const [savingChanges, setSavingChanges] = useState(false);
  const [loadingReportUrl, setLoadingReportUrl] = useState(false);
  const [reportUrl, setReportUrl] = useState<string>();
  const [error, setError] = useState<string>();
  const [iframeLoading, setIframeLoading] = useState(true);

  const fb = useFirebase();
  const { hasSimulatorAccess } = useUserRole();
  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();
  const { projectID } = useParams<{ projectID: string }>();
  const [, copyLinkToClipboard] = useCopyToClipboard({ itemTypeToCopy: "The link" });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  //Load URL for Iframe:
  useEffect(() => {
    if (!idToken || !activeOrganization?.slug) return;

    setLoadingReportUrl(true);
    getDownloadFileUrl(grpcURL, idToken, activeOrganization.slug, report_download.report_path)
      .then((url) => {
        //open URL in new tab....
        setLoadingReportUrl(false);
        setReportUrl(url);
      })
      .catch((err) => {
        toast.error("Error loading report");
        console.error(err);
        setLoadingReportUrl(false);
        setReportUrl(undefined);
        setError("error loading report");
      });
  }, [
    report_download.report_path,
    report_download.bucket_id,
    report_download.timestamp,
    idToken,
    grpcURL,
    activeOrganization?.slug,
  ]);

  const retryLoadReport = () => {
    if (!idToken || !activeOrganization) return;
    setLoadingReportUrl(true);
    setError(undefined);
    getDownloadFileUrl(grpcURL, idToken, activeOrganization.slug, report_download.report_path)
      .then((url) => {
        setLoadingReportUrl(false);
        setReportUrl(url);
      })
      .catch((err) => {
        toast.error("Error loading report");
        console.error(err);

        setLoadingReportUrl(false);
        setError("error loading report");
      });
  };

  //sync changes to report iframe:
  useEffect(() => {
    const reportWindow = iframeRef.current?.contentWindow;
    if (changes && changes.length > 0 && reportWindow && !iframeLoading) {
      reportWindow.postMessage({ type: "UPDATE_CONTENT", payload: changes }, "*");
    }
  }, [changes, iframeLoading]);

  const onUpdateChanges = async (updatedChanges: ReportChange[]) => {
    try {
      if (projectID && !savingChanges && activeOrganization?.id) {
        setSavingChanges(true);
        await fb
          .firestore()
          .collection("organizations")
          .doc(activeOrganization.id)
          .collection("projects")
          .doc(projectID)
          .collection(sourcetype === "group" ? "groups" : "scenarios")
          .doc(sourceID)
          .update({
            reportChanges: updatedChanges,
          });
        setChanges(updatedChanges);
        setChangeLog([...changeLog, updatedChanges]);
        setSavingChanges(false);
      }
    } catch (err) {
      setSavingChanges(false);
      console.error(err);
      setError("Error saving changes...");
    }
  };

  const goBack = async () => {
    try {
      if (projectID && !savingChanges && changeLog.length > 1 && activeOrganization?.id) {
        const prevChanges = changeLog[changeLog.length - 2];
        setSavingChanges(true);
        await fb
          .firestore()
          .collection("organizations")
          .doc(activeOrganization.id)
          .collection("projects")
          .doc(projectID)
          .collection(sourcetype === "group" ? "groups" : "scenarios")
          .doc(sourceID)
          .update({
            reportChanges: prevChanges,
          });
        //set changes to set removed changes to === ""
        setChanges(
          changeLog[changeLog.length - 1].map(
            (change) =>
              prevChanges.find((c) => c.id === change.id) || {
                ...change,
                innerHTML: change.original,
              }
          )
        );
        setSavingChanges(false);
        setChangeLog(changeLog.slice(0, changeLog.length - 1));
      }
    } catch (err) {
      setSavingChanges(false);
      console.error(err);
      setError("Error saving changes...");
    }
  };

  const [iframeScrollTop, setIframeScrollTop] = useState(0);
  const handleIframeScroll = (scrollData: { top: number }) => {
    setIframeScrollTop(scrollData.top);
  };

  const latestClickedRef = useRef<{ clickedId: string; uuid: string } | null>(null);

  const onReportClick = useCallback(
    (clicked: ReportChange) => {
      if (hasSimulatorAccess) {
        const isDoubleClick = latestClickedRef.current?.clickedId === clicked.id;
        if (isDoubleClick && clicked.id.length > 0) {
          const existingChange = changes?.find((change) => change.id === clicked.id);
          setSelectedChange(existingChange || clicked);
        } else setSelectedChange(null);
        const clickUUID = getUUID();
        latestClickedRef.current = { clickedId: clicked.id, uuid: clickUUID };
        setTimeout(() => {
          if (latestClickedRef.current?.uuid === clickUUID) latestClickedRef.current = null;
        }, 1000); //double click reset
      }
    },
    [changes, hasSimulatorAccess]
  );

  //on recieve iframe communication
  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const action = event.data;
      switch (action.type) {
        case "CLICK":
          onReportClick(action.payload);
          break;
        case "SCROLL":
          handleIframeScroll(action.payload);
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onReportClick]);

  const renderChangeEditor = () => {
    if (selectedChange)
      return (
        <div
          className="absolute top-0 left-0 flex flex-col rounded border border-gray-300 bg-white px-4 py-2 shadow-md"
          style={{
            height: "250px",
            width: "450px",
            marginTop: selectedChange.pos.top - iframeScrollTop - 250,
            marginLeft: selectedChange.pos.left - 225,
          }}
        >
          <div>{selectedChange.id}</div>
          <textarea
            value={selectedChange.innerHTML}
            className="mb-2 w-full flex-grow border px-2 py-1 focus:shadow-md focus:outline-none"
            onChange={(e) =>
              setSelectedChange({ ...selectedChange, innerHTML: e.target.value })
            }
          />
          <div className="flex items-center">
            <Button
              buttonType="white"
              className="mr-1 flex-1"
              onClick={() => {
                onUpdateChanges(updateArrayVal(changes || [], selectedChange)).then(() => {
                  setSelectedChange(null);
                });
              }}
            >
              Save
            </Button>
            <Button
              buttonType="white"
              className="mx-1 flex-1"
              onClick={() => {
                setSelectedChange(null);
              }}
            >
              Cancel
            </Button>
            <Button
              buttonType="white"
              className="ml-1 flex-1 border-red-300 bg-red-300"
              onClick={() => {
                const resatChange = {
                  ...selectedChange,
                  innerHTML: selectedChange.original,
                };
                onUpdateChanges(updateArrayVal(changes || [], resatChange)).then(() => {
                  setSelectedChange(null);
                });
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      );
    return null;
  };

  const [overviewOpen, setOverviewOpen] = useState(false);
  const renderChangeOverview = () => {
    const activeChanges = changes?.filter((c) => c.innerHTML !== c.original);
    return (
      <div className="absolute bottom-0 right-0 mb-4 mr-4">
        <div
          className={`border border-gray-400 bg-white shadow-lg ${
            overviewOpen ? "h-128 w-64 rounded" : "h-8 w-8 rounded-full"
          }`}
          style={{ transition: "width 0.2s, height 0.2s" }}
        >
          {overviewOpen && (
            <div className="flex h-full w-full flex-col px-2 py-2">
              <div className="px-2 text-sm font-medium">Report changes</div>
              <div className="flex-grow overflow-auto text-sm">
                {activeChanges &&
                  activeChanges.map((change) => {
                    const isSelected = change.id === selectedChange?.id;
                    return (
                      <div
                        key={change.id}
                        className={`my-2 cursor-pointer rounded-lg border py-1 px-2 ${
                          isSelected ? "border-green-200 bg-green-200" : "border-white"
                        }`}
                        onClick={() => setSelectedChange(change)}
                      >
                        <div>{change.id}</div>
                        <div className="truncate font-medium">{change.innerHTML}</div>
                      </div>
                    );
                  })}
              </div>
              <button
                className="text-sm italic text-blue-700 focus:outline-none"
                onClick={() => setOverviewOpen(false)}
              >
                Hide
              </button>
            </div>
          )}
          {!overviewOpen && (
            <div
              className="flex h-full w-full cursor-pointer items-center justify-center"
              onClick={() => setOverviewOpen(true)}
            >
              {activeChanges?.length || 0}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTheReport = () => {
    if (loadingReportUrl)
      return (
        <div className="flex h-full w-full items-center justify-center text-xl">
          <LoadingIcon />
        </div>
      );
    if (error)
      return (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <div className="italic text-red-500">{error}</div>
          <Button buttonType="white" onClick={() => retryLoadReport()}>
            Retry
          </Button>
        </div>
      );
    if (reportUrl)
      return (
        <>
          <iframe
            ref={iframeRef}
            className="h-full w-full"
            src={reportUrl}
            title="Report"
            onLoad={() => setIframeLoading(false)}
          />

          {selectedChange && renderChangeEditor()}
          {hasSimulatorAccess && renderChangeOverview()}

          <div className="absolute top-0 right-0 mt-4 mr-4 flex">
            {changeLog.length > 1 && (
              <Button
                buttonType="white"
                className="mr-2 h-6 w-12 hover:text-black hover:shadow-md"
                onClick={() => {
                  goBack();
                }}
              >
                {savingChanges ? <LoadingIcon /> : <UndoIcon />}
              </Button>
            )}
            <Button
              title="Copy link to the report"
              data-testid="reportCopyLinkBtn"
              buttonType="white"
              className="mr-2 h-6 w-12 hover:text-black hover:shadow-md"
              onClick={() => {
                copyLinkToClipboard(reportUrl);
              }}
            >
              <LinkSimpleHorizontal size="90%" />
            </Button>
            <Button
              title="Download the report"
              data-testid="reportDownloadBtn"
              buttonType="white"
              className="mr-2 h-6 w-12 hover:text-black hover:shadow-md"
              onClick={() => {
                const reportWindow = iframeRef.current?.contentWindow;

                if (reportWindow) {
                  reportWindow.postMessage(
                    {
                      type: "DOWNLOAD_REPORT",
                      payload: reportName,
                    },
                    "*"
                  );
                  toast.success("The report is being downloaded", { duration: 3000 });
                }
              }}
            >
              <FileArrowDown className="h-4" />
            </Button>
            <Button
              title="Close the report"
              data-testid="reportCloseBtn"
              buttonType="white"
              className="mr-2 h-6 w-12 hover:text-black hover:shadow-md"
              onClick={() => {
                if (onClose) onClose();
              }}
            >
              <X size="70%" className="text-gray-800 hover:text-black" />
            </Button>
          </div>
        </>
      );
    return null;
  };

  if (useModal)
    return (
      <Modal
        className="h-[calc(100vh-4rem)] w-[calc(100vw-4rem)]  overflow-hidden"
        noPadding
        onClose={() => {
          if (onClose) onClose();
        }}
      >
        {renderTheReport()}
      </Modal>
    );
  return <div className="h-full w-full  bg-white">{renderTheReport()}</div>;
};

export default ReportEditor;
