import { Currency } from "GraphQL/gql/graphql";
import { currencies } from "currencies.json";
import { SelectOption } from "model/datatypes";

const currencyOptions: SelectOption[] = currencies.map((currency) => ({
  id: currency.name,
  display: `${currency.name} (${currency.code})`,
  value: {
    __typename: "Currency",
    name: currency.name,
    code: currency.code,
  } as Currency,
}));

export default currencyOptions;
