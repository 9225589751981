import { CheckCircle, Info, Warning } from "@phosphor-icons/react";
import React, { ReactNode, isValidElement } from "react";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

type AlertTypes = "info" | "success" | "error" | "default";

type Props = {
  type: AlertTypes;
  text: string;
  subText?: string | ReactNode;
};

function getAlertColor(type: AlertTypes) {
  switch (type) {
    case "error":
      return { text: "text-yellow-800", subText: "text-yellow-700", bgColor: "bg-yellow-50" };
    case "info":
      return { text: "text-indigo-800", subText: "text-indigo-700", bgColor: "bg-indigo-50" };
    case "success":
      return {
        text: "text-success-800",
        subText: "text-success-700",
        bgColor: "bg-success-50",
      };
    case "default":
      return {
        text: "text-gray-800",
        subText: "text-gray-700",
        bgColor: "bg-gray-50",
      };
    default:
      return { text: "text-gray-800", subText: "text-gray-700", bgColor: "bg-gray-50" };
  }
}

function getAlertIcon(type: AlertTypes) {
  switch (type) {
    case "error":
      return <Warning className="h-5 w-5 text-yellow-400" aria-hidden="true" />;
    case "info":
      return <Info weight="bold" className="h-5 w-5 text-indigo-400" aria-hidden="true" />;
    case "success":
      return <CheckCircle className="h-5 w-5 text-success-400" aria-hidden="true" />;
    case "default":
      return <Info weight="bold" className="h-5 w-5 text-gray-400" aria-hidden="true" />;
    default:
      return null;
  }
}

export const Alert = ({ type, text, subText, ...htmlProps }: Props) => {
  return (
    <div
      data-testid="alert"
      {...htmlProps}
      className={classNames(getAlertColor(type).bgColor, "rounded-md p-4")}
    >
      <div className="flex">
        <div className="flex-shrink-0">{getAlertIcon(type)}</div>
        <div className="ml-3" data-testid="alertContent">
          <Text.Base className={classNames("text-sm font-medium", getAlertColor(type).text)}>
            {text}
          </Text.Base>
          {subText && (
            <div className={classNames(getAlertColor(type).subText, "mt-2 text-sm")}>
              {isValidElement(subText) ? subText : <p>{subText}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
