import React from "react";
import Text from "thermo/typography/Typography";

export const TermsAndConditionsDisclaimer = () => {
  return (
    <div className="flex items-center mt-6">
      <Text.XSmall className="block text-gray-900">
        By creating an account, you agree to the{" "}
        <a
          className="text-primary-800"
          href="https://storage.googleapis.com/numerous-legal/terms_and_conditions.html"
          target="_blank"
          rel="noreferrer"
        >
          terms and conditions
        </a>{" "}
        and{" "}
        <a
          className="text-primary-800"
          href="https://storage.googleapis.com/numerous-legal/policy_1.html"
          target="_blank"
          rel="noreferrer"
        >
          privacy policy.
        </a>
      </Text.XSmall>
    </div>
  );
};
