import { BookOpen } from "@phosphor-icons/react";
import Modal from "Components/Basic/Modal";
import ComponentLibrary from "Components/Systems/ComponentLibrary";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";

const ComponentLibraryCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Modal onClose={() => setIsOpen(false)} className="w-1/2" cardColor="gray-100">
            <ComponentLibrary />
          </Modal>
        )}
      </AnimatePresence>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center rounded bg-white py-2 px-4 text-sm font-medium shadow hover:font-bold focus:outline-none"
      >
        <div className="mr-4 flex p-3 text-gray-700">
          <BookOpen size={24} />
        </div>
        <span>Component Library</span>
      </button>
    </>
  );
};

export default ComponentLibraryCard;
