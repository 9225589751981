import { useGetCurrentUser } from "api/user/getCurrentUser";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useActiveOrganizationId = () => {
  const [id, setId] = useState<string | undefined>();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { data } = useGetCurrentUser();

  useEffect(() => {
    if (!data?.me.organizations || !organizationSlug) return;

    const organizationId = data.me.organizations.find(
      ({ slug }) => slug === organizationSlug
    )?.id;
    setId(organizationId);
  }, [data?.me.organizations, organizationSlug]);

  return id;
};
