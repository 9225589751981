import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UPDATE_PROJECT_DESCRIPTION = graphql(`
  mutation UPDATE_PROJECT_DESCRIPTION($description: String!, $projectId: ID!) {
    projectUpdateDescription(description: $description, projectId: $projectId) {
      ... on Project {
        id
        description
      }
    }
  }
`);

export const useUpdateProjectDescription = () => {
  const mutation = useMutation(UPDATE_PROJECT_DESCRIPTION);

  return mutation;
};
