import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useNavigate, NavigateOptions as RouterNavigateOptions } from "react-router-dom";

type BaseOptions = {
  openInNewTab?: boolean;
};

type CompletePathOptions = BaseOptions & {
  useAsCompletePath?: boolean;
  appendToCurrentPath?: never;
  useAsCompleteUrl?: never;
};

type AppendPathOptions = BaseOptions & {
  useAsCompletePath?: never;
  appendToCurrentPath?: boolean;
  useAsCompleteUrl?: never;
};

type CompleteUrlOptions = BaseOptions & {
  useAsCompletePath?: never;
  appendToCurrentPath?: never;
  useAsCompleteUrl?: boolean;
};

type NavigateOptions = (CompletePathOptions | AppendPathOptions | CompleteUrlOptions) &
  Omit<RouterNavigateOptions, "relative">;

export const useNavigateApp = () => {
  const navigate = useNavigate();
  const organizationSlug = useOrganizationSlugFromParams();

  /**
   * Enables in-app navigation.
   *
   * @param to The path to navigate to. If passed a negative number, will navigate back through the browser history. Otherwise will by default be prefixed by the organization slug unless passing `useAsCompletePath` through `options`
   * @param options An object containing either `useAsCompletePath` or `appendToCurrentPath`. Can also contain optional `replace`, `state` and `preventScrollReset` (see react-router docs)
   * @param useAsCompletePath Uses the passed `to` as the complete path to navigate to. Use to prevent prefixing the path with the organization slug
   * @param useAsCompleteUrl Uses the passed `to` as the complete url to navigate to. Always opens in a new tab
   * @param appendToCurrentPath Appends the passed `to` to the path pre-navigation
   * @param openInNewTab Opens the link in a new tab
   * @example
   * // previous url: dev.numerous.cloud/orgSlug/files
   * // current url: dev.numerous.cloud/orgSlug/tools
   * const navigate = useNavigateApp();
   *
   * navigate("administration") // new url: dev.numerous.cloud/orgSlug/administration
   * navigate("/administration") // new url: dev.numerous.cloud/orgSlug/administration
   * navigate("toolId", { appendToCurrentPath: true }) // new url: dev.numerous.cloud/orgSlug/tools/toolId
   * navigate("login", { useAsCompletePath: true }) // new url: dev.numerous.cloud/login
   * navigate(-1) // new url: dev.numerous.cloud/orgSlug/files
   * navigate("www.numerous.com", { useAsCompleteUrl: true }) // new url: www.numerous.com
   */
  const navigateApp = (to: string | number, options?: NavigateOptions) => {
    // Navigates back through browser history
    if (typeof to === "number") return navigate(to);

    // Removes initial "/" as react-router handles "/..." and "..." paths differently
    const parsedTo = to[0] === "/" ? to.slice(1) : to;

    if (!options) return navigate(`/${organizationSlug}/${parsedTo}`);

    const {
      appendToCurrentPath,
      useAsCompletePath,
      useAsCompleteUrl,
      openInNewTab,
      ...routerOptions
    } = options;

    if (useAsCompleteUrl) return window.open(to, "__blank");

    if (openInNewTab) {
      let path = `/${parsedTo}`;
      if (appendToCurrentPath) path = window.location.pathname.concat(path);
      if (!useAsCompletePath && !appendToCurrentPath && organizationSlug)
        path = `/${organizationSlug}${path}`;

      const baseURL = `${window.location.protocol}//${window.location.host}`;

      return window.open(`${baseURL}${path}`, "_blank");
    }

    if (useAsCompletePath) return navigate(`/${parsedTo}`, routerOptions);

    if (appendToCurrentPath) return navigate(parsedTo, routerOptions);

    return navigate(`/${organizationSlug}/${parsedTo}`, routerOptions);
  };

  return navigateApp;
};
