import { useGlobalState } from "@/store";
import { CaretRight, Info } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import ActionModal from "Components/Basic/ActionModal";
import { Input, TextArea } from "Components/Basic/Input";
import DropdownSearchAddCustom from "Components/Basic/Selector/DropdownSearchAddCustom";
import ToggleButton from "Components/Basic/ToggleButton";
import { useCreateGroup } from "api/group/createGroup";
import { GetGroup } from "api/group/getGroup";
import { Group, useGroups } from "api/group/getGroups";
import { useDuplicateScenario } from "api/scenario/duplicateScenario";
import { useProject } from "api/useFirebase";
import { SelectOption, SimulationScenario } from "model/datatypes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Tooltip from "thermo/tooltip/Tooltip";
import DropdownProjects from "./DropdownProjects";

interface Props {
  onFinish: () => void;
  projectID: string;
  group: GetGroup;
  fromScenario: SimulationScenario;
  headerText?: string;
}
type GroupInformationState = {
  selectedGroupID?: string;
  groupDescription: string;
};

type ScenarioInformationState = {
  scenarioName: string;
  scenarioDescription: string;
  includeOptimization: boolean;
};

const DuplicateScenario: React.FC<Props> = ({
  onFinish,
  group,
  projectID,
  fromScenario,
  headerText,
}) => {
  const navigate = useNavigateApp();
  const { activeWorkspaceId } = useGlobalState();

  // ********** Projects **********
  const [selectedProjectID, setSelectedProjectID] = useState(projectID);
  const { project: relatedProject } = useProject(selectedProjectID);

  // ********** Groups **********
  const [groupInformation, setGroupInformationState] = useState<GroupInformationState>({
    selectedGroupID: group?.id || fromScenario.groupID,
    groupDescription: "",
  });
  const setGroupInformation = useCallback(
    (fields: Partial<GroupInformationState>) =>
      setGroupInformationState((prev) => ({ ...prev, ...fields })),
    []
  );
  const [newGroup, setNewGroup] = useState<SelectOption>();
  const { data: selectedProjectGroups } = useGroups(selectedProjectID);

  const [createGroup] = useCreateGroup();

  // ********** Scenarios **********
  const hasOptimization = !!fromScenario?.optimizationScenarioID;
  const [scenarioInformation, setScenarioInformationState] =
    useState<ScenarioInformationState>({
      scenarioName: headerText || `${fromScenario.scenarioName} copy`,
      scenarioDescription: "",
      includeOptimization: hasOptimization,
    });
  const setScenarioInformation = useCallback(
    (fields: Partial<ScenarioInformationState>) =>
      setScenarioInformationState((prev) => ({ ...prev, ...fields })),
    []
  );

  // ********** Handle options for dropdown **********
  const selectedGroup: SelectOption = useMemo(() => {
    const groupOption = {
      id: groupInformation.selectedGroupID || "",
      display: "",
      value: groupInformation.selectedGroupID,
    };

    if (selectedProjectGroups?.groups === null) return groupOption;
    const grp = selectedProjectGroups?.groups.find(
      (g) => g.id === groupInformation.selectedGroupID
    );
    if (grp && grp.name) groupOption.display = grp.name;
    else if (newGroup) {
      return newGroup;
    }
    return groupOption;
  }, [selectedProjectGroups?.groups, groupInformation.selectedGroupID, newGroup]);

  const groupOptions: SelectOption[] = useMemo(
    () =>
      selectedProjectGroups?.groups
        ?.map((g) => ({
          id: g.id,
          display: g.name || "",
          value: g.id,
        }))
        .sort((a, b) => {
          if (a.id === groupInformation.selectedGroupID) return -1;
          if (b.id === groupInformation.selectedGroupID) return 1;
          return a.display.localeCompare(b.display);
        }) || [],
    [selectedProjectGroups?.groups, groupInformation.selectedGroupID]
  );

  // ********** Update selected group depending on the selected project **********
  useEffect(() => {
    if (selectedProjectID === projectID) {
      setGroupInformation({ selectedGroupID: group?.id || fromScenario.groupID });
    } else if (selectedProjectGroups?.groups.length && selectedProjectID !== projectID) {
      setGroupInformation({ selectedGroupID: selectedProjectGroups?.groups[0].id });
    } else {
      setGroupInformation({ selectedGroupID: undefined });
    }
  }, [
    selectedProjectID,
    selectedProjectGroups,
    projectID,
    fromScenario.groupID,
    group?.id,
    setGroupInformation,
  ]);

  // ********** Fields filled in properly **********
  const readyToSave = useMemo(
    () => !!scenarioInformation.scenarioName.length && !!groupInformation.selectedGroupID,

    [scenarioInformation.scenarioName, groupInformation.selectedGroupID]
  );

  // ********** Saving scenario **********
  const [duplicateScenario, { loading: duplicatingScenario }] = useDuplicateScenario();

  const routeToNewScenarioPage = (newScenarioID: string) => {
    if (newScenarioID) navigate(`/project/${selectedProjectID}/${newScenarioID}`);
  };

  const duplicateAScenario = (newGroupID: string) => {
    if (!fromScenario) return;
    duplicateScenario({
      variables: {
        newDescription: scenarioInformation.scenarioDescription,
        newGroupId: newGroupID,
        newName: scenarioInformation.scenarioName,
        newProjectId: selectedProjectID,
        projectId: projectID,
        scenarioId: fromScenario.id,
        includeOptimization: scenarioInformation.includeOptimization,
      },
    })
      .then((response) => {
        if (response.data?.scenarioDuplicate.__typename === "Scenario") {
          onFinish();
          toast.success("The scenario was successfully created");
          const newScenarioID = response.data?.scenarioDuplicate.id;
          if (newScenarioID) routeToNewScenarioPage(newScenarioID);
        }
        if (response.data?.scenarioDuplicate.__typename !== "Scenario") {
          toast.error("There was a problem creating the scenario");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("The scenario could not be created. Try again later");
      });
  };

  const onSave = async () => {
    if (!readyToSave || !relatedProject || !selectedProjectGroups?.groups) {
      toast.error("Please select a system and fill in a scenario name");
      return;
    }

    try {
      let newGroupID = groupInformation.selectedGroupID;
      const selectedGroupExistsInSelectedProject = !!selectedProjectGroups?.groups.find(
        (g: Group) => g.name === selectedGroup.display
      );

      if (!selectedGroupExistsInSelectedProject) {
        const createdGroup = await createGroup({
          variables: {
            projectID: relatedProject.id,
            groupName: selectedGroup.display,
            description: groupInformation.groupDescription,
          },
        });
        if (createdGroup.data?.groupCreate.__typename === "ProjectDoesNotAllowGroupCreation") {
          console.error(
            `Project with id ${createdGroup.data.groupCreate.projectId} does not allow group creation`
          );
          return;
        }
        newGroupID = createdGroup.data?.groupCreate.id;
        setGroupInformation({ selectedGroupID: newGroupID });
      }

      if (!newGroupID) return;
      duplicateAScenario(newGroupID);
    } catch (error) {
      toast.error("The scenario could not be duplicated. Try again later");
      console.error(error);
    }
  };

  return (
    <ActionModal
      onSave={() => onSave()}
      saveButtonName="Create scenario"
      loading={duplicatingScenario}
      disableConfirmButtonIf={!readyToSave}
      onClose={onFinish}
      className="relative w-10/12 text-sm lg:w-1/2"
    >
      <div className="flex flex-col flex-wrap border-b border-gray-200 p-6 pb-4 text-lg font-medium">
        <div className="flex gap-4">
          {group && (
            <div className="flex items-center gap-4 text-gray-400">
              {group.name} <CaretRight className="h-4 w-4" />
            </div>
          )}
          New {headerText ? headerText.toLowerCase() : "simulation scenario"}
        </div>
        {fromScenario && (
          <div className="mt-2 text-sm font-light italic text-gray-500">
            Copy of {fromScenario.scenarioName}
          </div>
        )}
      </div>

      <div className="px-6 py-4">
        <div className="mt-4 mb-2 text-sm font-medium">Scenario Name</div>
        <Input
          data-testid="addScenarioName"
          type="text"
          autoFocus
          className="mb-2 w-full text-sm"
          value={scenarioInformation.scenarioName}
          onChange={(e) => {
            setScenarioInformation({ scenarioName: e.target.value });
          }}
        />
        <div className="mt-4 mb-2 text-sm font-medium">Scenario Description</div>
        <TextArea
          onChange={(e) => setScenarioInformation({ scenarioDescription: e.target.value })}
          placeholder="Optional"
          className="mt-1 w-full pl-3 text-sm"
          data-testid="optionalScenarioDescription"
        />
        {hasOptimization && (
          <div className="mt-4 mb-2 flex flex-row items-center gap-4">
            <span className="text-sm font-medium">Include optimization settings</span>
            <ToggleButton
              active={scenarioInformation.includeOptimization}
              onChange={() =>
                setScenarioInformation({
                  includeOptimization: !scenarioInformation.includeOptimization,
                })
              }
            />
          </div>
        )}
        {fromScenario && (
          <>
            <DropdownProjects
              activeWorkspaceId={activeWorkspaceId}
              selectedProjectID={selectedProjectID}
              setSelectedOption={(option) => {
                if (option.id !== selectedProjectID) {
                  setSelectedProjectID(option.id);
                }
              }}
              createNew={false}
            />
            <div className="mt-4 mb-2 text-sm font-medium flex flex-row items-center gap-1">
              Group
              <Tooltip label="Type to create a new group">
                <Info size={15} />
              </Tooltip>
            </div>
            <DropdownSearchAddCustom
              className="w-full"
              options={newGroup ? [newGroup, ...groupOptions] : groupOptions}
              selectedOption={selectedGroup}
              setSelectedOption={(option) => {
                setGroupInformation({ selectedGroupID: option.id });
                if (groupOptions.findIndex((grOption) => grOption.id === option.id) === -1)
                  setNewGroup(option);
                else setNewGroup(undefined);
              }}
              data-testid="groupDropdownSearch"
              createNew
            />
            {selectedGroup.display === newGroup?.display && (
              <>
                <div className="mt-2 text-sm font-medium">Group Description</div>
                <TextArea
                  onChange={(e) => setGroupInformation({ groupDescription: e.target.value })}
                  placeholder="Optional"
                  className="mt-1 w-full pl-3 text-sm"
                  data-testid="optionalGroupDescription"
                />
              </>
            )}
          </>
        )}
      </div>
    </ActionModal>
  );
};
export default DuplicateScenario;
