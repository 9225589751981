import { MagnifyingGlass } from "@phosphor-icons/react";
import { RemoteRepositories } from "api/repository/getRemoteRepositories";
import { format } from "date-fns";
import React, { useState } from "react";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

type Props = {
  remoteRepositories: RemoteRepositories | undefined;
  selectedRemoteRepositoryId: string | null;
  setSelectedRemoteRepositoryId: (id: string) => void;
  blockSelection: boolean;
};

export const RemoteRepositoryList = ({
  remoteRepositories,
  selectedRemoteRepositoryId,
  setSelectedRemoteRepositoryId,
  blockSelection,
}: Props) => {
  const [repositorySearchTerm, setRepositorySearchTerm] = useState("");

  const filteredRemoteRepositories = remoteRepositories?.filter((remoteRepository) => {
    return remoteRepository.node.path
      .toLowerCase()
      .includes(repositorySearchTerm.toLowerCase());
  });

  if (remoteRepositories && remoteRepositories?.length < 1) {
    return (
      <div className="p-3 border border-gray-300 mt-2 rounded-md bg-gray-50">
        <Text.Base>No remote repositories were available to add to numerous</Text.Base>
      </div>
    );
  }
  return (
    <>
      <div className="sticky top-0 group" data-blocked={blockSelection}>
        <div
          className="flex w-full appearance-none group-data-[blocked=true]:text-gray-300 text-gray-600 bg-white transition-colors duration-100 group-data-[blocked=true]:cursor-not-allowed h-9
            border-t-0 border border-gray-300 px-3 py-2 placeholder-gray-400 sm:text-sm"
        >
          <span className="inline-flex items-center sm:text-sm">
            <MagnifyingGlass className="" size={16} />
          </span>
          <input
            type="text"
            value={repositorySearchTerm}
            disabled={blockSelection}
            onChange={(e) => setRepositorySearchTerm(e.target.value)}
            className="block min-w-0 flex-1 px-2 py-1 border-none disabled:placeholder:text-gray-300 text-sm focus:ring-0 disabled:cursor-not-allowed"
            placeholder="Search remote repositories"
          />
        </div>

        <div className="border border-gray-300 border-t-0">
          <div className="flex justify-between bg-slate-100">
            <Text.Small className="py-1 px-3 text-gray-600 transition-colors duration-100 group-data-[blocked=true]:text-gray-400 font-medium">
              Repository
            </Text.Small>
            <Text.Small className="py-1 pr-6 text-gray-600 transition-colors duration-100 group-data-[blocked=true]:text-gray-400 font-medium">
              Last updated
            </Text.Small>
          </div>
        </div>
      </div>
      <div
        className="border border-gray-300 border-t-0 rounded-b-md group max-h-[45vh] overflow-auto"
        data-blocked={blockSelection}
      >
        {filteredRemoteRepositories?.map(({ node }, idx) => (
          <button
            data-testid="remoteRepositoryListItem"
            disabled={blockSelection}
            key={node.id}
            onClick={() => setSelectedRemoteRepositoryId(node.id)}
            className={classNames(
              selectedRemoteRepositoryId === node.id
                ? "bg-indigo-200"
                : "bg-white hover:bg-indigo-100 transition-colors duration-100 group-data-[blocked=true]:hover:bg-white",
              filteredRemoteRepositories.length - 1 === idx && "rounded-b-md",
              "flex justify-between max-h-80 overflow-y-auto py-0.5 w-full transition-colors duration-100 group-data-[blocked=true]:cursor-default"
            )}
          >
            <Text.Small className="py-1 px-3 transition-colors duration-100 group-data-[blocked=true]:text-gray-400 text-left">
              {node.path}
            </Text.Small>
            <Text.Small className="py-1 pr-6 transition-colors duration-100 group-data-[blocked=true]:text-gray-400 text-right">
              {format(node.updatedAt, "HH:mm MMM dd yyyy")}
            </Text.Small>
          </button>
        ))}
      </div>
    </>
  );
};
