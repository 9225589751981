import { Button } from "Components/Basic/Buttons";
import React from "react";

type Props = {
  onClick?: () => void;
  text: string;
  buttonText?: string;
  icon?: JSX.Element;
  hideSection?: () => void;
  hidden?: boolean;
};

const PageSection = ({ onClick, text, buttonText, icon, hideSection, hidden }: Props) => (
  <div className="relative mt-7 mb-4">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex items-center justify-between">
      <span className="bg-slate-100 pr-3 text-sm font-medium text-gray-700">{text}</span>
      {hideSection && (
        <button
          data-testid="pageSectionShowHideButton"
          className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform rounded-md border border-gray-400 bg-slate-100 px-2 text-sm text-gray-600"
          onClick={hideSection}
        >
          {hidden ? "Hide" : "Show"}
        </button>
      )}
      {onClick && buttonText && (
        <Button data-testid="pageSectionButton" onClick={onClick} buttonType="secondary">
          {icon && icon} <span className={`${icon && "ml-2"}`}>{buttonText}</span>
        </Button>
      )}
    </div>
  </div>
);

export default PageSection;
