import { ReactNode } from "react";
import classNames from "utils/jsUtils/className";
import { usePopoverContext } from "./PopoverContext";

interface Props {
  className?: HTMLDivElement["className"];
  children: ReactNode | ((open: boolean) => ReactNode);
}

const PopoverTrigger = ({ className, children }: Props) => {
  const { open, openPopover, closePopover, triggerProps } = usePopoverContext();

  const togglePopover = open ? closePopover : openPopover;

  return (
    <div
      {...triggerProps}
      className={classNames("group/popoverTrigger cursor-pointer", className)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        togglePopover();
      }}
      data-testid="popoverTrigger"
      data-open={open}
    >
      {typeof children === "function" ? children(open) : children}
    </div>
  );
};

export default PopoverTrigger;
