import { ClockCounterClockwise } from "@phosphor-icons/react";
import Popover from "thermo/popover/Popover";
import Text from "thermo/typography/Typography";
import { RecentlyViewedOverview } from "./RecentlyViewedOverview";

export const RecentlyViewed = () => {
  return (
    <div className="flex w-full mb-3 relative items-center px-2 py-1 select-none">
      <Popover.Root placement="bottom-start">
        <Popover.Trigger>
          <button
            className="group/recentlyviewed flex items-center gap-1.5"
            data-testid="recentlyViewedBtn"
          >
            <ClockCounterClockwise
              size={16}
              className="text-gray-500 group-hover/recentlyviewed:text-gray-700"
            />
            <Text.Small className="text-gray-500 group-hover/recentlyviewed:text-gray-700">
              Recently viewed
            </Text.Small>
          </button>
        </Popover.Trigger>
        <Popover.Content>
          {(closePopover) => <RecentlyViewedOverview closePopover={closePopover} />}
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};
