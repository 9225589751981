import { TeamMember as TeamMemberType } from "api/team/getAllTeams";
import React from "react";

type Props = {
  member: TeamMemberType;
  isOwner: boolean;
};

const TeamMember: React.FC<Props> = ({ member, isOwner = false }) => {
  return member.fullName ? (
    <div className="flex items-center justify-between border-b border-gray-100 px-4 py-2 text-sm text-gray-500">
      <div className="flex items-center">
        <span className="mr-2 h-4 w-0.5 bg-indigo-400" />
        <span>{member.fullName}</span>
        {isOwner && <span className="ml-3 text-sm text-slate-400">&#x2022; Owner</span>}
      </div>
    </div>
  ) : null;
};

export default TeamMember;
