import { CaretLeft } from "@phosphor-icons/react";
import { useLayoutEffect, useRef, useState } from "react";
import Popover from "thermo/popover/Popover";
import Text from "thermo/typography/Typography";

const getPreviousPathStep = (path: string) => {
  const index = path.lastIndexOf("/");
  return index < 0 ? "" : path.slice(0, index);
};

const getPathFromStep = (step: number, fullPath: string) => {
  return fullPath
    .split("/")
    .slice(0, step + 1)
    .join("/");
};

const getPathBreadcrumbs = (currentPath: string, shouldCompressBreadcrumbs: boolean) => {
  const tempPathBreadcrumbs = currentPath.split("/");
  if (shouldCompressBreadcrumbs)
    return {
      pathBreadcrumbs: [tempPathBreadcrumbs.at(-1) || ""],
      compressedPathBreadcrumbs: tempPathBreadcrumbs.slice(0, -1),
    };
  return { pathBreadcrumbs: tempPathBreadcrumbs, compressedPathBreadcrumbs: undefined };
};

const COMPRESS_THRESHOLD = 6;

interface Props {
  fullPath: string;
  navigateTo: (path: string) => void;
}

const ExplorerBreadcrumbs = ({ fullPath, navigateTo }: Props) => {
  const [shouldCompressBreadcrumbs, setShouldCompressBreadcrumbs] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { pathBreadcrumbs, compressedPathBreadcrumbs } = getPathBreadcrumbs(
    fullPath,
    shouldCompressBreadcrumbs
  );

  useLayoutEffect(() => {
    if (!containerRef.current || !containerRef.current.childNodes?.length) return undefined;

    const setBreadcrumbCompression = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const pathCharacterLength = fullPath.length;
      const numberOfBreadcrumbs = fullPath.split("/").length;

      // Setting the width based on number of characters and accounting for gap between breadcrumbs
      const breadcrumbWidth = pathCharacterLength + numberOfBreadcrumbs * 5;

      if (containerWidth / breadcrumbWidth < COMPRESS_THRESHOLD)
        setShouldCompressBreadcrumbs(true);
      else setShouldCompressBreadcrumbs(false);
    };

    setBreadcrumbCompression();
    window.addEventListener("resize", setBreadcrumbCompression);

    return () => window.removeEventListener("resize", setBreadcrumbCompression);
  }, [fullPath]);

  return (
    <div
      className="w-full border-b border-gray-200 grid py-1"
      style={{ gridTemplateColumns: "8% 1fr" }}
    >
      {fullPath !== "" && (
        <CaretLeft
          data-testid="pathBack"
          className="justify-self-center cursor-pointer text-gray-600 hover:text-gray-700"
          onClick={() => navigateTo(getPreviousPathStep(fullPath))}
        />
      )}
      <Text.Small className="select-none text-gray-500 col-start-2">
        <div ref={containerRef} className="flex gap-2">
          {shouldCompressBreadcrumbs && (
            <Popover.Root placement="bottom-start">
              <Popover.Trigger>
                <button data-testid="compressedBreadcrumbs">
                  ...
                  <span className="ml-2">/</span>
                </button>
              </Popover.Trigger>
              <Popover.Content>
                <div className="flex flex-col gap-1 p-1">
                  <button
                    // eslint-disable-next-line react/no-array-index-key
                    data-testid="popoverBreadcrumb"
                    onClick={() => navigateTo("")}
                    className="bg-white px-2 py-1 rounded hover:bg-gray-100 text-left"
                  >
                    ...
                  </button>
                  {compressedPathBreadcrumbs?.map((breadcrumb, i) => (
                    <button
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${breadcrumb}_${i}`}
                      data-testid="popoverBreadcrumb"
                      onClick={() => navigateTo(getPathFromStep(i, fullPath))}
                      className="bg-white px-2 py-1 rounded hover:bg-gray-100 text-left"
                    >
                      <Text.Small>{breadcrumb}</Text.Small>
                    </button>
                  ))}
                </div>
              </Popover.Content>
            </Popover.Root>
          )}
          <div
            className={shouldCompressBreadcrumbs ? "" : "cursor-pointer hover:text-gray-700"}
            onClick={() => !shouldCompressBreadcrumbs && navigateTo("")}
            data-testid="pathBreadcrumbRoot"
          >
            root
          </div>
          {fullPath &&
            pathBreadcrumbs.map((path, i) => (
              <div key={path}>
                <span className="mr-2">/</span>
                <span
                  className={
                    shouldCompressBreadcrumbs ? "" : "cursor-pointer hover:text-gray-700"
                  }
                  data-testid="pathBreadcrumb"
                  onClick={() =>
                    !shouldCompressBreadcrumbs && navigateTo(getPathFromStep(i, fullPath))
                  }
                >
                  {path}
                </span>
              </div>
            ))}
        </div>
      </Text.Small>
    </div>
  );
};

export default ExplorerBreadcrumbs;
