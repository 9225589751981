import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Repository_CommitsQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_COMMITS = graphql(`
  query REPOSITORY_COMMITS($repositoryId: ID!, $query: String, $first: Int!, $after: String) {
    connectedRepositoryCommits(
      repositoryId: $repositoryId
      query: $query
      first: $first
      after: $after
    ) @connection(key: "RepositoryCommits") {
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          message
          title
          authoredAt
          committedAt
          branches
          author {
            name
          }
        }
      }
    }
  }
`);

export type CommitEdge = ArrayElement<
  Repository_CommitsQuery["connectedRepositoryCommits"]["edges"]
>;

type Props = {
  repositoryId: string;
  query: string;
  first: number;
};

export const useGetCommits = ({ repositoryId, first, query }: Props) => {
  const result = useQuery(GET_COMMITS, {
    variables: {
      repositoryId,
      query,
      first,
      after: null,
    },
    fetchPolicy: "cache-and-network",
  });
  return result;
};
