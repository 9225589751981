import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useFirestore } from "api/useFirebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { convertFromFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { LabelNew, ScenarioLabel } from "../types";

/**
 * Hook used to get the scenario mark details.
 *
 * @param markId - markId from a scenario that needs the mark details.
 * @returns An object with the mark details.
 */

type Props = {
  markId?: ScenarioLabel["label_id"];
};
// NUM-719
export const useGetScenarioMark = ({ markId }: Props) => {
  const [labelDetails, setLabelDetails] = useState<LabelNew>();
  const [hasLabelError, setHasLabelError] = useState(false);
  const fs = useFirestore();
  const activeOrganizationId = useActiveOrganizationId();

  useEffect(() => {
    if (!markId || !fs || !activeOrganizationId) return;
    const markDocRef = doc(fs, "organizations", activeOrganizationId, "labels", markId);
    getDoc(markDocRef)
      .then((snapshot) => {
        if (!snapshot.exists) {
          console.error(`The label with id: ${markId}, did not exist`);
          setHasLabelError(true);
          return;
        }
        const data = snapshot.data();
        if (!data) {
          console.error(`The label with id: ${markId}, could not fetch data`);
          setHasLabelError(true);
          return;
        }
        setLabelDetails(convertFromFirestoreFormat(data) as LabelNew);
        setHasLabelError(false);
      })
      .catch(console.error);
  }, [activeOrganizationId, fs, markId]);

  return { labelDetails, hasLabelError };
};
