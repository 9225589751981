import React from "react";

type Props = {
  onClick: () => void;
  icon: JSX.Element;
  buttonText: string;
  buttonTextColor?: string;
  className?: string;
  disabled?: boolean;
};

const TopBarButton = ({
  onClick,
  icon,
  buttonText,
  buttonTextColor,
  className = "",
  disabled,
  ...htmlProps
}: Props) => (
  <button
    {...htmlProps}
    disabled={disabled}
    onClick={onClick}
    type="button"
    className={`${className} ${disabled && "cursor-not-allowed"} ${
      buttonTextColor || "text-gray-600"
    }  flex items-center rounded-lg
    px-4 py-0.5 text-sm font-medium leading-8 transition-all
    duration-100 hover:border-gray-200 hover:bg-indigo-100 hover:text-indigo-800`}
  >
    {icon}
    <span>{buttonText}</span>
  </button>
);

export default TopBarButton;
