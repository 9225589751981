import { Warning } from "@phosphor-icons/react";
import ActionModal from "Components/Basic/ActionModal";
import { Input } from "Components/Basic/Input";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { DataTagInput, StaticDataSetInput } from "GraphQL/gql/graphql";
import { useCreateDataSet } from "api/dataSet/createDataSet";
import { DatasetScenario } from "model/datatypes";
import Papa from "papaparse";
import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Tooltip from "thermo/tooltip/Tooltip";
import { convertRawTimeFromUnitToSeconds } from "utils/dataTransform/timeConvert";
import { CSVData } from "../types";
import CSVPreviewer from "./CSVPreviewer";
import FileUploader from "./csvUploader/FileUploader";
import TimeSelector, { IndexColumn } from "./csvUploader/TimeSelector";
import { parseCSV } from "./utils/parseCSV";

type AvailableEncodingFormats = "utf-8" | "ISO-8859-1";

const checkIfFileHasNonSupportedCharacters = (fileText: string) => {
  return fileText.includes("�");
};

const NewCSV: React.FC<{
  onFinish: () => void;
  onAddCSV: (newCSV: DatasetScenario) => void;
}> = ({ onFinish, onAddCSV }) => {
  const { projectID = "" } = useParams<{ projectID: string }>();
  const { id: userId } = useGlobalUser();
  const [dataName, setDataName] = useState("");
  const [csvData, setCsvData] = useState<CSVData[] | null>(null);
  const [startDate, setStartDate] = useState(new Date("00:00 01/01/21"));
  const [indexColumn, setIndexCol] = useState<IndexColumn>({
    tag: "_index",
    timeUnit: "hours",
  });
  const [createDataset, { loading: creatingDataSet }] = useCreateDataSet();

  const handleFileAdded = async (inputFile: File) => {
    setDataName(inputFile.name);
    let encodingFormat: AvailableEncodingFormats = "utf-8";

    const fileAsText = await inputFile.text();
    if (checkIfFileHasNonSupportedCharacters(fileAsText)) {
      // The file is not using UTF-8, we redirect to ISO-8859-1 which is the default
      // for saving to csv from excel
      encodingFormat = "ISO-8859-1";
    }

    const parseAndSetCsvData = (res: Papa.ParseResult<any>) => {
      try {
        const parsedCsvData = parseCSV(res);
        setCsvData(parsedCsvData);
      } catch (error) {
        console.error(error);
        setCsvData(null);
        setDataName("");
        toast.error("There was an error parsing the data");
      }
    };

    Papa.parse(inputFile, {
      complete: parseAndSetCsvData,
      skipEmptyLines: true,
      encoding: encodingFormat,
    });
  };

  const indexValues = useMemo(() => {
    if (!csvData) return null;
    if (indexColumn.tag === "_index") {
      const { length } = csvData[0].values;
      return Array.from({ length }, (_, index) =>
        convertRawTimeFromUnitToSeconds(index, indexColumn.timeUnit)
      );
    }
    return (csvData.find((col) => col.tag === indexColumn.tag)?.values as number[]) || null;
  }, [indexColumn, csvData]);

  const handleCreateDataSet = async () => {
    if (!csvData || !indexValues) return;

    const dataTags: DataTagInput[] = csvData
      .filter((col) => col.included)
      .map((col) => {
        return { tag: col.tag, values: col.values as number[] };
      });

    const input: StaticDataSetInput = {
      data: dataTags,
      index: indexValues,
      name: dataName,
      startTime: startDate,
    };

    const createDataResponse = await createDataset({
      variables: {
        dataSetInput: input,
        projectId: projectID,
      },
    });

    if (createDataResponse.errors) {
      console.error(createDataResponse);
    }

    const createdDataSet = createDataResponse.data?.dataSetCreateStatic;
    if (!createdDataSet) return;
    onAddCSV({
      created: new Date(),
      dataTags: createdDataSet.tags,
      id: createdDataSet.id,
      isArchived: false,
      ownerId: userId,
      projectID: createdDataSet.projectId,
      scenarioName: createdDataSet.name,
      type: "dataset",
    });
    onFinish();
  };

  const isCsvMissingColumnsForEnergyDemand = useMemo(() => {
    if (!csvData) return false;
    const tagsRequiredForEnergyDemand = ["P-VS (kW)", "P-KB (kW)", "P-VV (kW)", "P-VVC (kW)"];
    const tagsIncludedInCsv = new Set(
      csvData.filter((col) => col.included).map((col) => col.tag)
    );
    return tagsRequiredForEnergyDemand.some((tag) => !tagsIncludedInCsv.has(tag));
  }, [csvData]);

  const noSelectedColumns =
    !csvData || csvData.filter((column) => column.included).length <= 0;

  return (
    <ActionModal
      onClose={() => {
        if (!creatingDataSet) onFinish();
      }}
      saveButtonName="Upload"
      loading={creatingDataSet}
      className="relative w-11/12 lg:w-3/4"
      onSave={handleCreateDataSet}
      disableConfirmButtonIf={noSelectedColumns}
      blockNavigateAwayIf={csvData !== null}
    >
      <div className="p-4">
        <div className="mb-2 font-medium">Add CSV dataset</div>
        {!csvData && <FileUploader onFileAdded={handleFileAdded} />}
        {csvData && (
          <>
            <div className="my-4 flex w-full items-center gap-4">
              <div className="text-sm font-medium">Data name</div>
              <Input
                type="text"
                value={dataName}
                onChange={(e) => setDataName(e.target.value)}
                className="w-1/2 text-sm"
              />
              {isCsvMissingColumnsForEnergyDemand && (
                <Tooltip
                  label={`To calculate the heating, cooling and hot water energy demand,
            the dataset must contain columns: "P-VS (kW)", "P-KB (kW)", "P-VV (kW)" and "P-VVC (kW)"`}
                >
                  <Warning
                    data-testid="missingDataWarning"
                    className="h-6 w-6 text-yellow-600 mr-0 ml-auto"
                  />
                </Tooltip>
              )}
            </div>
            <CSVPreviewer
              csvData={csvData}
              onUpdate={(updated) => setCsvData(updated)}
              indexColumnTag={indexColumn.tag}
              indexValues={indexValues || []}
            />
            <TimeSelector
              csvData={csvData}
              indexColumn={indexColumn}
              setIndexColumn={setIndexCol}
              startDate={startDate}
              setStartDate={setStartDate}
              indexOffsetInSeconds={indexValues?.slice(-1)[0]}
            />
          </>
        )}
      </div>
    </ActionModal>
  );
};

export default NewCSV;
