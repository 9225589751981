import MapComponent from "Components/Map/MapComponent";
import LocationSearch from "Components/Map/Search/LocationSearch";
import { getMapLayers } from "Components/Map/Utils/getMapLayers";
import {
  GeoCodingResponse,
  MapClick,
  MapFeature,
  MapMoveTo,
  MapSource,
} from "Components/Map/types";
import axios from "axios";
import { ComponentParameter, MapLocation, SelectOption } from "model/datatypes";
import React, { useEffect, useMemo, useState } from "react";
import classNames from "utils/jsUtils/className";

const MAPBOX_TOKEN = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN;
const ZOOM_LEVEL = 14;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  parameter: ComponentParameter;
  onUpdate: (newParam: ComponentParameter) => void;
}

const MapParameter: React.FC<Props> = ({ parameter, onUpdate, className }) => {
  const [moveTo, setMoveTo] = useState<MapMoveTo | undefined>();

  const { unclusteredLayer } = getMapLayers({ sourceID: "location" });
  const value = parameter.value as MapLocation | null;

  const source: MapSource | undefined = useMemo(() => {
    if (!value) return undefined;

    const tempSource: MapSource = {
      key: `${value.id}Source`,
      id: "location",
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            id: value.id,
            type: "Feature",
            properties: {
              color: "#4F46E5",
              radius: 7,
            },
            geometry: {
              type: "Point",
              coordinates: value.center,
            },
          } as MapFeature,
        ],
      },
    };

    return tempSource;
  }, [value]);

  const handleSelectItem = (selected: SelectOption) => {
    onUpdate({
      ...parameter,
      value: {
        id: selected.id,
        name: selected.display,
        center: selected.value,
      } as MapLocation,
    });
  };

  const handleMapClick = async (feature: MapClick) => {
    if (feature.isFeatureClick) return;
    const { latitude, longitude } = feature;
    const reverseGeocodingURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`;

    const response = await axios.get(reverseGeocodingURL);

    if (response.status !== 200) return;

    // First object of features array is closest match to query
    const clickedLocation = (response.data as GeoCodingResponse).features[0];

    const selectedLocation: MapLocation = {
      id: clickedLocation.id,
      name: clickedLocation.place_name,
      center: clickedLocation.center,
    };

    onUpdate({ ...parameter, value: selectedLocation });
  };

  useEffect(() => {
    if (!value) return;

    const [longitude, latitude] = value.center;

    const move: MapMoveTo = {
      type: "flyTo",
      settings: {
        center: {
          latitude,
          longitude,
        },
        zoom: ZOOM_LEVEL,
      },
      clear: () => setMoveTo(undefined),
    };

    setMoveTo(move);
  }, [value]);

  return (
    <div
      className={classNames(
        "relative w-full cursor-pointer overflow-hidden rounded",
        className
      )}
    >
      <div className="absolute top-2 left-2 z-[8] w-4/6">
        <LocationSearch
          onSelectItem={handleSelectItem}
          placeholder={value?.name || "Search for location or select on map"}
        />
      </div>
      <MapComponent
        source={source}
        layers={unclusteredLayer}
        initialCamera={
          value
            ? {
                longitude: value.center[0],
                latitude: value.center[1],
                zoom: ZOOM_LEVEL,
              }
            : undefined
        }
        moveTo={moveTo}
        onMapClick={handleMapClick}
        cursorPointerOnHover
      />
    </div>
  );
};

export default MapParameter;
