import { useEffect, useState, useCallback, useLayoutEffect } from "react";

function getActualtHeight(el: HTMLDivElement, maxHeight: number): number {
  return el.scrollHeight + 2 < maxHeight ? el.scrollHeight + 2 : maxHeight;
}

export const useMaxHeightTransitionAutoAnimate = (
  small: string,
  large: string,
  time = 100,
  initialState?: boolean,
  element?: React.RefObject<HTMLDivElement>
) => {
  const [open, setisOpen] = useState(!!initialState);
  const [isOverflown, setIsOverflown] = useState(false);

  const setOpen = useCallback(
    (newState: boolean) => {
      const el = element?.current;
      if (newState) {
        setisOpen(true);
      } else {
        if (el) {
          // set Max height to actual height for smooth transition
          const trans = el.style.transition;
          el.style.transition = "none";
          const actualHeight = getActualtHeight(el, parseInt(large, 10));
          el.style.maxHeight = `${actualHeight}px`;
          el.style.setProperty("overflow", "hidden", "important");
          // Next event loop make the transition
          setTimeout(() => {
            el.style.transition = trans;
            el.style.maxHeight = small;
          }, 0);
        }

        setTimeout(() => {
          setIsOverflown(false);
          setisOpen(false);
        }, time);
      }
    },
    [small, time, element, large, setisOpen]
  );

  // This effect run on open
  useLayoutEffect(() => {
    const el = element?.current;
    if (!el) return undefined;

    el.style.maxHeight = small; // INITALIZE MAX Height because the element is first rendered to the DOM when open == true
    const rect = el.getBoundingClientRect();
    const actualHeight = getActualtHeight(el, parseInt(large, 10));

    // Determines if the element will overflow in the bottom of the screen
    setIsOverflown(rect.y + actualHeight > window.innerHeight);
    //Apply actual height for smooth transition
    const oldOverflow = el.style.overflow;
    el.style.setProperty("overflow", "hidden", "important");
    el.style.maxHeight = `${actualHeight}px`;
    el.style.minHeight = "0px";
    el.style.transition = ` max-height ${time / 1000}s ease-out`;

    const timeout = setTimeout(() => {
      //Set max height to passed prop as it is the actual maxHeight we want
      if (el.style.maxHeight !== small || el.style.maxHeight !== large)
        el.style.overflow = oldOverflow;
      el.style.maxHeight = large;
    }, time);

    return () => timeout && clearTimeout(timeout);
  }, [open, element, small, large, time]);

  // ENSURE DEFAULT STYLES
  useEffect(() => {
    if (!element?.current) return;
    const el = element.current;
    if (!el.style.maxHeight) el.style.maxHeight = initialState ? large : small;
    if (!el.style.transition) el.style.transition = `max-height ${time / 1000}s ease-out`;
    if (!el.style.minHeight) el.style.minHeight = "0px";
  }, [element, time, small, large, initialState]);

  return { open, setOpen, isOverflown };
};
