import { DotsSixVertical, CaretRight } from "@phosphor-icons/react";
import { useSessionStorage } from "Components/hooks/useSessionStorage";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode } from "react";
import classNames from "utils/jsUtils/className";

interface Props {
  title: string | JSX.Element | ReactNode;
  subtitle?: string | JSX.Element | ReactNode;
  containerOptions?: JSX.Element;
  toggledByDefault?: boolean;
  hideIf?: boolean;
  isDisabled?: boolean;
  isNested?: boolean;
  contentId: string;
  isInsideContainer?: boolean;
  inPopup?: true;
  stickyHeader?: true | { pxOffsetTop: number };
  customUI?: boolean;
  removeable?: boolean;
  draggable?: boolean;
}

const ToggleContainer: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  containerOptions,
  toggledByDefault,
  hideIf,
  isDisabled,
  children,
  isNested,
  isInsideContainer,
  contentId,
  inPopup,
  stickyHeader,
  customUI,
  removeable,
  draggable,
  ...htmlProps
}) => {
  const [toggled, setToggled] = useSessionStorage(contentId, toggledByDefault || false);
  if (inPopup && !isNested) return children as JSX.Element;
  if (!isNested && !isInsideContainer) {
    return (
      <div
        {...htmlProps}
        className={classNames(
          stickyHeader && "relative",
          "mt-3 border border-gray-300 bg-white shadow-sm",
          customUI ? "rounded-lg" : "rounded-sm"
        )}
      >
        <div
          className={classNames(
            stickyHeader && "sticky z-10",
            typeof stickyHeader === "object" && `top-[${stickyHeader.pxOffsetTop}px]`,
            "flex w-full flex-col border-b bg-white px-4 py-3 shadow-sm",
            hideIf ? "" : "cursor-pointer"
          )}
          onClick={() => (!draggable ? setToggled((prev) => !prev) : () => false)}
        >
          <div className={`flex items-center justify-between ${draggable && "cursor-move"}`}>
            <div className="flex flex-row gap-2">
              {draggable && <DotsSixVertical size={24} />}
              <div
                className={`text-base font-medium leading-6 ${
                  isDisabled ? "text-gray-400" : "text-gray-700 "
                }`}
              >
                {title}
              </div>
              {subtitle && (
                <div className="flex items-center text-sm leading-5 text-gray-500">
                  {subtitle}
                </div>
              )}
            </div>
            <div
              className={`flex cursor-pointer items-center ${removeable && "mr-12"}`}
              onClick={() => setToggled((prev) => !prev)}
            >
              {containerOptions}
              <CaretRight
                data-testid="toggleContainerCaretRight"
                weight="bold"
                className={`ml-5 h-4 w-4 transition-all ${
                  hideIf ? "text-gray-200" : "text-gray-500"
                }  ${toggled && !hideIf && "rotate-90"}`}
              />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {!hideIf && toggled && (
            <motion.div
              key="content"
              initial={`${toggledByDefault ? "open" : "closed"}`}
              animate="open"
              exit="closed"
              className=""
              transition={{
                bounce: 0,
                ease: "easeInOut",
              }}
              variants={{
                open: { height: "auto", overflow: "visible", opacity: 1 },
                closed: { height: 0, overflow: "hidden", opacity: 0 },
              }}
            >
              <div className="">{children}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
  if (!isInsideContainer) {
    return (
      <div className="relative mt-3 h-full text-sm">
        <div className="relative ml-4">
          <div
            className="w-full border-b border-gray-200 shadow-sm"
            onClick={() => {
              setToggled((prev) => !prev);
            }}
          >
            <div
              className={`h-full w-full bg-slate-100 ${
                hideIf ? "" : "cursor-pointer"
              } px-4 py-2 text-sm font-medium`}
            >
              <div
                className={`${
                  hideIf ? "bg-indigo-300" : "bg-indigo-600"
                }  absolute top-0 -left-2  h-[48.5px] w-2`}
              />
              <div className="flex w-full items-center justify-between">
                <div className="mr-1 flex w-full py-1 text-left text-sm font-medium uppercase tracking-wider text-gray-700">
                  <div className={`flex w-full justify-between ${draggable && "cursor-move"}`}>
                    <div className="flex flex-row gap-2">
                      {draggable && <DotsSixVertical size={24} />}
                      <div className="text-sm font-medium leading-6 text-gray-700">
                        {title}
                      </div>
                    </div>
                    <div className="mr-11 flex cursor-pointer items-center pl-2">
                      {containerOptions}
                      <CaretRight
                        className={`ml-5 h-4 w-4 transition-all ${
                          hideIf ? "text-gray-200" : "text-gray-500"
                        }  ${toggled && !hideIf && "rotate-90"}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AnimatePresence>
              {!hideIf && toggled && (
                <motion.div
                  key="content"
                  initial={`${toggledByDefault ? "open" : "closed"}`}
                  animate="open"
                  exit="closed"
                  className=""
                  transition={{
                    bounce: 0,
                    ease: "easeInOut",
                  }}
                  variants={{
                    open: { height: "auto", overflow: "visible", opacity: 1 },
                    closed: { height: 0, overflow: "hidden", opacity: 0 },
                  }}
                >
                  <div className="" onClick={(e) => e.stopPropagation()}>
                    {children}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    );
  }
  return children as JSX.Element;
};

export default ToggleContainer;
