import { Toolbox } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useSystemName } from "api/useFirebase";
import { useLocation } from "react-router-dom";
import { RecentItem } from "../types";
import { RecentItemTemplate } from "./RecentItemTemplate";

type Props = {
  toolId: string;
  type: Extract<RecentItem["type"], "tool">;
  close: () => void;
};

export const RecentToolItem = ({ toolId, type, close }: Props) => {
  const navigate = useNavigateApp();
  const { systemName, loadingSystemName } = useSystemName(toolId);
  const organizationName = useOrganizationSlugFromParams();
  const location = useLocation();

  const isCurrentlyOnItemPage = location.pathname === `/${organizationName}/tools/${toolId}`;

  if (systemName === null) return null;
  return (
    <RecentItemTemplate
      icon={<Toolbox size={16} weight="bold" />}
      type={type}
      text={systemName || ""}
      loading={loadingSystemName}
      onClick={() => {
        if (isCurrentlyOnItemPage) return;
        navigate(`/tools/${toolId}`);
        close();
      }}
      recentItemIsActivePage={isCurrentlyOnItemPage}
    />
  );
};
