import { WarningOctagon } from "@phosphor-icons/react";
import { ReactNode } from "react";
import ActionModal from "./ActionModal";

interface Props {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  loading?: boolean;
  headline?: string | ReactNode;
  description?: string | ReactNode;
  disableConfirm?: boolean;
  isWarning?: true;
}

const ConfirmModal = ({
  open,
  onConfirm,
  onCancel,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  loading,
  headline = "Delete?",
  description = "This is a destructive action that can't be undone.",
  disableConfirm,
  isWarning,
}: Props) => {
  return (
    <ActionModal open={open} onClose={onCancel} hideDefaultActionBar>
      <div data-testid="confirmActionModal" className="flex max-w-xl px-6 py-7">
        <div className="flex items-center justify-end pr-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-100">
            <WarningOctagon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
        </div>
        <div>
          <div className="mb-2 text-lg font-normal leading-6 text-gray-900">{headline}</div>
          <div className="text-sm leading-normal	text-gray-500">{description}</div>
        </div>
      </div>

      <ActionModal.ActionBar>
        <ActionModal.ActionButton data-testid="cancelButton" onClick={onCancel}>
          {cancelButtonText}
        </ActionModal.ActionButton>
        <ActionModal.ActionButton
          onClick={onConfirm}
          loading={loading}
          disabled={disableConfirm}
          variant={isWarning ? "warning" : "brandColor"}
          data-testid="confirmButton"
        >
          {confirmButtonText}
        </ActionModal.ActionButton>
      </ActionModal.ActionBar>
    </ActionModal>
  );
};

export default ConfirmModal;
