/* eslint-disable react/destructuring-assignment */
import { RepositoryActivity } from "api/repository/getRepositoryActivity";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import Text from "thermo/typography/Typography";
import { ActivityIcon } from "./ActivityIcon";

type Props = {
  activity: RepositoryActivity;
};

const ActivityDescription = (activity: RepositoryActivity) => {
  const activityType = activity.__typename;
  switch (activityType) {
    case "RepositoryActivityDeleteBranch":
      return <Text.Small>has deleted a branch ({activity.branchName})</Text.Small>;
    case "RepositoryActivityPushNewBranch":
      return (
        <Text.Small>
          has pushed a new branch{" "}
          <a
            className="text-indigo-900"
            href={`${activity.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            ({activity.branchName})
          </a>
        </Text.Small>
      );
    case "RepositoryActivityPushNewTag":
      return (
        <Text.Small>
          has pushed a new tag <span className="text-indigo-900">({activity.tagName})</span>
        </Text.Small>
      );
    case "RepositoryActivityPushToBranch":
      return (
        <Text.Small>
          pushed a new commit to{" "}
          <a
            className="text-indigo-900"
            href={`${activity.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {activity.branchName}
          </a>
        </Text.Small>
      );
    default:
      return <Text.Small className="italic"> - No description found</Text.Small>;
  }
};

export const ActivityItem = ({ activity }: Props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, activityDate, userName } = activity;

  return (
    <>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        <ActivityIcon type={__typename} />
      </div>
      <div className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <Text.Small className="font-medium inline-block text-gray-900">{userName}</Text.Small>{" "}
        <Text.Small className="inline-block">{ActivityDescription(activity)}</Text.Small>
      </div>
      <time
        dateTime={activityDate.toString()}
        className="flex-none py-0.5 first-letter:uppercase text-xs leading-5 text-gray-500"
      >
        {formatDistanceToNow(activityDate, { addSuffix: true })}
      </time>
    </>
  );
};
