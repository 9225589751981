import { CaretRight, Stack, Database, ListPlus } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import ProjectDataSets from "Components/DataSets/ProjectDataSets";
import AddGroup from "Components/Group/AddGroup";
import ProjectGroups from "Components/Group/ProjectGroups";
import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import PageSection from "Components/PageSection";
import ProjectInformation from "Components/Project/ProjectInformation/ProjectInformation";
import ProjectParameters from "Components/Project/ProjectParameters/ProjectParameters";
import NewInput from "Components/Scenario/Input/NewInput";
import ProjectBarOptions from "Components/TopBar/ProjectBarOptions";
import { TopBarWrapper } from "Components/TopBar/TopBarWrapper";
import { useDeprecatedFirestore, useProject } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import { ComponentParameter } from "model/datatypes";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "./PageNotFound";

const ProjectPage = () => {
  const activeOrganizationId = useActiveOrganizationId();
  const fs = useDeprecatedFirestore();
  const { projectID = "" } = useParams<{ projectID: string }>();
  const { project, loadingProject } = useProject(projectID);
  const [addingGroup, setAddingGroup] = useState(false);
  const [addingDataset, setAddingDataset] = useState(false);
  const [showProjectDataSets, setShowProjectDataSets] = useState(false);

  const updateProjectParameters = useCallback(
    (parameters: ComponentParameter[]) =>
      new Promise<void>((resolve, reject) => {
        if (!activeOrganizationId) {
          reject(new Error("no organization id"));
        }
        fs.collection("organizations")
          .doc(activeOrganizationId)
          .collection("projects")
          .doc(projectID)
          .update({ parameters })
          .then(resolve)
          .catch(reject);
      }),
    [activeOrganizationId, fs, projectID]
  );

  if (loadingProject) return <LoadingOverlay />;
  return (
    <div data-testid="projectPage">
      {project ? (
        <div className="mb-8">
          <TopBarWrapper
            pageTitle={
              <div className="flex gap-2 items-center">
                <Link to="/workspaces">
                  <Stack size={16} weight="fill" data-testid="scenarioBreadCrumbHome" />
                </Link>
                <CaretRight className="flex-none" size={12} weight="bold" />
                <span data-testid="projectNameBreadCrumb" className="flex-1 truncate min-w-0">
                  {project.projectName}
                </span>
              </div>
            }
          >
            <ProjectBarOptions project={project} />
          </TopBarWrapper>
          <div className="mx-3">
            <PageSection text="Information" />

            <ProjectInformation project={project} />

            {project.parameters && (
              <ProjectParameters
                project={project}
                updateParameters={updateProjectParameters}
              />
            )}

            <PageSection
              icon={<ListPlus size={15} weight="bold" />}
              text="Groups"
              buttonText="Add group"
              onClick={() => setAddingGroup(true)}
            />
            <ProjectGroups project={project} />

            <PageSection
              icon={<Database size={15} weight="bold" />}
              text="Datasets"
              buttonText="Add dataset"
              hidden={showProjectDataSets}
              onClick={() => setAddingDataset(true)}
              hideSection={() => setShowProjectDataSets((prev) => !prev)}
            />
            {showProjectDataSets && <ProjectDataSets projectID={projectID || ""} />}
          </div>
        </div>
      ) : (
        <PageNotFound />
      )}
      <AnimatePresence>
        {addingGroup && project && (
          <AddGroup
            key="NewGroupModal"
            onFinish={() => {
              setAddingGroup(false);
            }}
          />
        )}
        {addingDataset && project && (
          <NewInput
            key="NewDatasetModal"
            projectID={project.id}
            onFinish={() => setAddingDataset(false)}
            onCSVAdded={() => setAddingDataset(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectPage;
