import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const DATASET = graphql(`
  query DataSet($dataSetId: ID!, $projectId: ID!) {
    dataSet(dataSetId: $dataSetId, projectId: $projectId) {
      ... on ScheduledDataSet {
        __typename
        id
        name
        projectId
        schedules {
          __typename
          periods {
            __typename
            periodType
            defaultValue
            timeslots {
              __typename
              endTime
              id
              startTime
              value
            }
          }
          tag
        }
        tags
      }
      ... on StaticDataSet {
        __typename
        name
        projectId
        tags
        id
      }
    }
  }
`);

type Props = {
  dataSetId: string;
  projectId: string;
  skip?: boolean;
};

const useGetDataSet = ({ dataSetId, projectId, skip }: Props) => {
  const result = useQuery(DATASET, { variables: { dataSetId, projectId }, skip });
  return result;
};

export default useGetDataSet;
