import { ConditionalWrapper } from "Components/Basic/ConditionalWrapper";
import { ReactNode, RefObject, useEffect, useState } from "react";
import Tooltip from "thermo/tooltip/Tooltip";

const TruncateTooltip = ({
  tooltipContent,
  shouldTruncate,
  childRef,
  children,
}: {
  tooltipContent: ReactNode;
  shouldTruncate: boolean;
  childRef: RefObject<HTMLDivElement>;
  children: ReactNode;
}) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  useEffect(() => {
    if (!shouldTruncate) return undefined;

    const checkTextOverflow = () => {
      if (!childRef.current) return;
      const textIsTruncated = childRef.current.scrollWidth > childRef.current.clientWidth;
      setDisplayTooltip(textIsTruncated);
    };

    checkTextOverflow();
    window.addEventListener("resize", checkTextOverflow);

    return () => window.removeEventListener("resize", checkTextOverflow);
  }, [childRef, shouldTruncate]);

  return (
    <ConditionalWrapper
      condition={shouldTruncate && displayTooltip}
      wrapper={(wrappedChildren) => (
        <Tooltip label={tooltipContent}>
          <span>{wrappedChildren}</span>
        </Tooltip>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

export default TruncateTooltip;
