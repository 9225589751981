export function ensureInView(container: HTMLElement, element: HTMLElement) {
  //Determine container top and bottom
  let cTop = container.scrollTop;
  let cBottom = cTop + container.clientHeight;

  //Determine element top and bottom
  let eTop = element.offsetTop;
  let eBottom = eTop + element.clientHeight;

  //Check if out of view
  if (eTop < cTop || eBottom > cBottom) {
    element.scrollIntoView({ block: "nearest", behavior: "smooth" });
  }
}
