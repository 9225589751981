import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const ADD_SCHEDULE = graphql(`
  mutation ADD_SCHEDULE($dataSetId: ID!, $projectId: ID!, $schedule: ScheduleInput!) {
    dataSetScheduleCreate(dataSetId: $dataSetId, projectId: $projectId, schedule: $schedule) {
      ... on ScheduledDataSet {
        __typename
        id
        projectId
        name
        tags
        schedules {
          ...ScheduleFragment
        }
      }
    }
  }
`);

export const useAddSchedule = () => {
  const mutation = useMutation(ADD_SCHEDULE);

  return mutation;
};
