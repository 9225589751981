import { useLazyQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_REMOTE_REPOSITORY = graphql(`
  query GET_REMOTE_REPOSITORY($remoteRepositoryId: ID!, $remoteRepositoryProvider: String!) {
    remoteRepository(
      remoteRepositoryId: $remoteRepositoryId
      remoteRepositoryProvider: $remoteRepositoryProvider
    ) {
      ... on RemoteRepository {
        __typename
        id
      }
      ... on RemoteRepositoryNotFound {
        __typename
        remoteRepositoryId
      }
      ... on RemoteRepositoryProviderNotSupported {
        __typename
        unsupportedRemoteRepositoryProvider
      }
    }
  }
`);

export const useGetRemoteRepositoryLazy = () => {
  const result = useLazyQuery(GET_REMOTE_REPOSITORY);
  return result;
};
