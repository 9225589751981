import { DataTag } from "GraphQL/gql/graphql";
import { PlotData } from "plotly.js";
import { useMemo } from "react";
import Plot from "react-plotly.js";

export const Graph: React.FC<{ fields: string[]; data: DataTag[]; t: number[] | null }> = ({
  fields,
  data,
  t,
}) => {
  const mappedData = useMemo(() => {
    const plotData: Partial<PlotData>[] = [];
    fields.forEach((field) => {
      const theData = data.find((d) => d.tag === field)?.values;
      const xAxis = t || theData?.map((v, i) => i);
      if (theData && xAxis)
        plotData.push({
          x: xAxis,
          y: theData,
          type: "scatter",
          mode: "lines",
          name: field,
        });
    });
    return plotData;
  }, [data, t, fields]);

  return (
    <Plot
      className=""
      data={mappedData}
      useResizeHandler
      config={{ displayModeBar: false }}
      style={{ width: "100%" }}
      layout={{
        autosize: true,
        paper_bgcolor: "#F7FAFC",
        plot_bgcolor: "#F7FAFC",
        margin: {
          t: 40,
          b: 40,
          l: 40,
          r: 40,
          pad: 0,
        },
      }}
    />
  );
};
