import { useGlobalUser } from "Components/Providers/User/UserProvider";
import useLocalStorage from "Components/hooks/useLocalStorage";
import React from "react";
import { SidebarButtonWrapper } from "../SidebarButtonWrapper";
import { PinnedHeading } from "./PinnedHeading/PinnedHeading";
import { PinnedItemsList } from "./PinnedItemList/PinnedItemsList";

export const PinnedSection = () => {
  const user = useGlobalUser();

  const [showPinnedSection, setShowPinnedSection] = useLocalStorage("showPinnedSection", true);

  if (!user.pinned.length) return null;
  return (
    <>
      <SidebarButtonWrapper>
        <PinnedHeading
          showPinnedItems={showPinnedSection}
          toggleShowPinned={() => setShowPinnedSection((prev) => !prev)}
        />
      </SidebarButtonWrapper>
      {showPinnedSection && <PinnedItemsList pinnedItems={user.pinned} />}
    </>
  );
};
