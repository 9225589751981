import { useCallback, useEffect, useState } from "react";

const hasWindow = (): boolean => {
  return typeof window === "object";
};
interface UseKeyPressOptions {
  targetKey: KeyboardEvent["key"];
  fn?: () => void;
}

/**
 *
 * @param targetKey The key you want to set a listener for.
 * @param fn OPTIONAL: The function you want to run when the key is pressed
 * @link Available targetKeys https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 * @returns A boolean indicating whether the key was pressed
 */
export const useKeyPressAction = ({ targetKey, fn }: UseKeyPressOptions): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(
    ({ key }: { key: UseKeyPressOptions["targetKey"] }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey]
  );

  const upHandler = useCallback(
    ({ key }: { key: UseKeyPressOptions["targetKey"] }) => {
      if (key === targetKey) {
        if (fn) fn();
        setKeyPressed(false);
      }
    },
    [fn, targetKey]
  );

  useEffect(() => {
    if (!hasWindow()) {
      return undefined;
    }
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]);

  return keyPressed;
};
