import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import { TextSizes } from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

type TypographySizes = keyof typeof TextSizes;

interface Props extends HTMLMotionProps<"div"> {
  children?: React.ReactNode;
  disableAnimation?: true;
}

const Skeleton = ({ children, disableAnimation, ...htmlProps }: Props) => {
  return (
    <motion.div
      {...htmlProps}
      className={classNames(htmlProps.className, "bg-slate-300/60 rounded-md")}
      animate={!disableAnimation ? { opacity: [1, 0.4, 1] } : undefined}
      transition={
        !disableAnimation
          ? { repeat: Infinity, duration: 1.8, times: [0.2, 0.4, 0.8] }
          : undefined
      }
    >
      {children}
    </motion.div>
  );
};

interface TextLineProps extends HTMLMotionProps<"div"> {
  typography: TypographySizes;
  width?: `w-${string}` | "w-full";
  disableAnimation?: true;
}

const TextLine = ({ typography, width, disableAnimation, ...htmlProps }: TextLineProps) => {
  const textSize = TextSizes[typography as TypographySizes];
  return (
    <Skeleton className={width} {...htmlProps} disableAnimation={disableAnimation}>
      <div className={classNames("invisible", textSize)}>.</div>
    </Skeleton>
  );
};

interface ParagraphProps extends HTMLMotionProps<"div"> {
  typography: Extract<TypographySizes, "Base" | "Small" | "XSmall">;
  lines: number;
  width: `w-${string}`; // defaults to w-full
  disableAnimation?: true;
}

const Paragraph = ({
  typography,
  lines,
  width,
  disableAnimation,
  ...htmlProps
}: ParagraphProps) => {
  return (
    <div className="flex flex-col gap-1">
      {Array(lines)
        .fill(undefined)
        .map((_, i) => (
          <Skeleton.TextLine
            {...htmlProps}
            typography={typography}
            disableAnimation={disableAnimation}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            width={i === lines - 1 ? "w-1/2" : width}
          />
        ))}
    </div>
  );
};

Skeleton.TextLine = TextLine;
Skeleton.Paragraph = Paragraph;

export default Skeleton;
