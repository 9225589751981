import { useGlobalUser } from "@/Components/Providers/User/UserProvider";
import { OrganizationMemberRole } from "@/GraphQL/gql/graphql";

export const useFallbackNavigate = () => {
  const user = useGlobalUser();

  switch (user.activeOrganizationRole) {
    case OrganizationMemberRole.PortfolioManager:
    case OrganizationMemberRole.PortfolioUser:
      return "dashboard";
    case OrganizationMemberRole.Developer:
    case OrganizationMemberRole.Simulator:
    case OrganizationMemberRole.Owner:
      return "/";
    case OrganizationMemberRole.Guest:
      return "workspaces";
    default:
      return "";
  }
};
