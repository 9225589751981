import * as ContextMenu from "@radix-ui/react-context-menu";
import React, { ReactNode } from "react";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";

type Props = {
  children: ReactNode;
  menuContent: ReactNode;
};

/**
 * Do not import from here. Import from ContextMenu.tsx
 * @children The children will act as the trigger for the context menu. This is the component you want to show in your UI.
 * @contextContent The content is the items in your context menu.
 */
export const ContextMenuWrapper = ({ children, menuContent }: Props) => {
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger data-testid="contextMenuTrigger">{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content
          loop
          data-testid="contextMenuContent"
          className={classNames(
            getZIndexClassName("zDropdown"),
            "py-1 max-w-[200px] bg-white rounded-lg shadow-[0px_1px_1px_rgb(0,0,0,0.09%)] border-gray-200 border"
          )}
        >
          {menuContent}
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};
