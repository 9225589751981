import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import useLocalStorage from "Components/hooks/useLocalStorage";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RecentItem } from "./types";

type PathParams = {
  projectId: string | undefined;
  scenarioId: string | undefined;
  toolId: string | undefined;
};

const getRouteParams = (pathName: string): PathParams => {
  let projectId: string | undefined;
  let toolId: string | undefined;

  let [, , type, projectOrToolId, scenarioId] = pathName.split("/");

  if (type === "project" && projectOrToolId) projectId = projectOrToolId;
  else if (type === "tools" && projectOrToolId) toolId = projectOrToolId;

  return { projectId, scenarioId, toolId };
};

const queueItem = (
  existingItems: RecentItem[],
  id: string,
  type: RecentItem["type"],
  projectId?: string
) => {
  let updatedRecentItems = [...existingItems];

  // If item is in queue already, move it to the most recent
  const itemExistsInRecentItems = updatedRecentItems.findIndex(
    (updatedRecentItem) => updatedRecentItem.id === id
  );
  if (itemExistsInRecentItems !== -1) {
    updatedRecentItems.unshift(...updatedRecentItems.splice(itemExistsInRecentItems, 1));
    return updatedRecentItems;
  }

  switch (type) {
    case "project":
      updatedRecentItems.unshift({ id, type: "project" });
      break;
    case "scenario":
      if (!projectId) break;
      updatedRecentItems.unshift({ id, type: "scenario", projectId });
      break;
    case "tool":
      updatedRecentItems.unshift({ id, type: "tool" });
      break;
    default:
      break;
  }

  // Keep list of recent items at maximum 5 items
  if (updatedRecentItems.length > 5) updatedRecentItems.length = 5;

  return updatedRecentItems;
};

const getUpdatedViewQueue = (pathname: Location["pathname"], existingItems: RecentItem[]) => {
  const params = getRouteParams(pathname);
  const { projectId, scenarioId, toolId } = params;

  if (projectId && scenarioId)
    return queueItem(existingItems, scenarioId, "scenario", projectId);
  if (projectId && !scenarioId) return queueItem(existingItems, projectId, "project");
  if (toolId) return queueItem(existingItems, toolId, "tool");

  return existingItems;
};

export const useRecentlyViewedQueue = () => {
  const organizationSlug = useOrganizationSlugFromParams();
  const location = useLocation();

  const [recentItems, setRecentItems] = useLocalStorage<RecentItem[]>(
    `${organizationSlug}:recent-views`,
    []
  );

  useEffect(() => {
    if (!organizationSlug) return;

    const organizationRecentViews = localStorage.getItem(`${organizationSlug}:recent-views`);
    const previousQueue: RecentItem[] = organizationRecentViews
      ? JSON.parse(organizationRecentViews)
      : [];

    setRecentItems(() => getUpdatedViewQueue(location.pathname, previousQueue));
  }, [organizationSlug, location, setRecentItems]);

  return { recentItems };
};
