import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PAGINATED_DATASETS } from "./getPaginatedDataSets";

const DELETE_DATASET = graphql(`
  mutation DELETE_DATASET($datasetId: ID!, $projectId: ID!) {
    dataSetDelete(dataSetId: $datasetId, projectId: $projectId) {
      ... on DeletedDataSet {
        __typename
        id
      }
      ... on DataSetNotFound {
        __typename
        dataSetId
        projectId
      }
    }
  }
`);

export const useDeleteDataSet = () => {
  const mutation = useMutation(DELETE_DATASET, {
    update(cache, { data }, { variables }) {
      if (!data || !variables) return;
      const deletedDataSet = data?.dataSetDelete;
      if (!deletedDataSet || deletedDataSet.__typename === "DataSetNotFound") return;

      cache.updateQuery(
        { query: PAGINATED_DATASETS, variables: { projectId: variables.projectId } },
        (projectQuery) => {
          if (!projectQuery || !projectQuery.project?.dataSets) return projectQuery;
          let updatedProjectQuery = structuredClone(projectQuery);
          if (!updatedProjectQuery.project?.dataSets.edges) return projectQuery;
          updatedProjectQuery.project.dataSets.edges =
            updatedProjectQuery.project?.dataSets.edges.filter(
              (edge) => edge.node.id !== deletedDataSet.id
            );
          return updatedProjectQuery;
        }
      );
    },
  });
  return mutation;
};
