import { useApolloClient } from "@apollo/client";
import { Plus, SignOut, SquareSplitVertical } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useFirebaseCloudInstance } from "Components/Providers/Firebase/FirebaseManager";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useLogOut } from "Components/UserSettings/API/logOut";
import { User } from "api/user/getCurrentUser";
import { UserPendingInvitation } from "api/user/getUserPendingInvitations";
import { useGlobalDispatch } from "store";
import { sortAlphabetical } from "utils/jsUtils/sortAlphabetical";
import OrganizationInvitationsList from "../OrganizationInvitations/OrganizationInvitationsList";
import { OrganizationPickerDropdownItem } from "./OrganizationPickerDropdownItem";

type Props = {
  allOrganizations: User["organizations"];
  pendingInvitations: UserPendingInvitation[] | undefined;
  closePopover: () => void;
};

export const OrganizationPickerDropdown = ({
  allOrganizations,
  pendingInvitations,
  closePopover,
}: Props) => {
  const { logOut } = useLogOut();
  const client = useApolloClient();
  const dispatch = useGlobalDispatch();
  const { shutDownOrganizations, activeOrganizationId } = useGlobalUser();
  const activeOrganization = allOrganizations.find((org) => org.id === activeOrganizationId);
  const navigate = useNavigateApp();
  const { permissions } = useFirebaseCloudInstance();

  return (
    <div
      data-testid="organizationPickerDropdown"
      className="max-h-80 overflow-y-auto mx-0.5 gap-0.5 flex flex-col py-0.5 text-gray-600"
    >
      {[...allOrganizations]
        .sort((a, b) => sortAlphabetical(a.name, b.name))
        .map((organization) => (
          <OrganizationPickerDropdownItem
            key={organization.id}
            onClick={() => {
              client.clearStore().then(() => {
                localStorage.removeItem(`${activeOrganization?.slug}:recent-views`);
                dispatch({ type: "SET_ACTIVE_WORKSPACE_ID", payload: null });
                navigate(`/${organization.slug}`, { useAsCompletePath: true });
                closePopover();
              });
            }}
            text={organization.name}
            highlight={organization.id === activeOrganization?.id}
          />
        ))}
      {!permissions?.disallowLoggedInOrganizationCreation && (
        <>
          <div className="h-px bg-gray-100" />
          <OrganizationPickerDropdownItem
            icon={<Plus size={14} />}
            onClick={() => {
              closePopover();
              navigate("/create-organization", { useAsCompletePath: true });
            }}
            text="Create new organization"
          />
        </>
      )}
      {allOrganizations.length + shutDownOrganizations.length > 1 && (
        <>
          <div className="h-px bg-gray-100" />
          <OrganizationPickerDropdownItem
            data-testid="viewAllOrganizationsButton"
            icon={<SquareSplitVertical size={14} />}
            onClick={() => {
              closePopover();
              navigate("/", { useAsCompletePath: true });
              localStorage.removeItem("activeOrganizationSlug");
            }}
            text="View all organizations"
          />
        </>
      )}
      {pendingInvitations && pendingInvitations.length > 0 && (
        <>
          <div className="h-px bg-gray-100" />
          <OrganizationInvitationsList pendingInvitations={pendingInvitations} />
        </>
      )}
      <div className="h-px bg-gray-100" />
      <OrganizationPickerDropdownItem
        icon={<SignOut size={14} />}
        onClick={logOut}
        text="Log out"
      />
    </div>
  );
};
