import { useState } from "react";
import toast from "react-hot-toast";
import { useDeprecatedFirestore } from "../../../api/useFirebase";
import { useGetCurrentUser } from "../../../api/user/getCurrentUser";
import { SystemModel } from "../../../model/datatypes";
import { convertToFirestoreFormat } from "../../../utils/firebase/firestoreFormatter";
import { useNavigateApp } from "../../AppNavigation/useNavigateApp";
import { useActiveOrganizationId } from "../../Organisation/hooks/useActiveOrganizationId";

export const useAddNewToolFromClipboard = () => {
  const fs = useDeprecatedFirestore();
  const [loading, setLoading] = useState(false);
  const { data } = useGetCurrentUser();
  const navigate = useNavigateApp();

  const activeOrganizationId = useActiveOrganizationId() || "";

  const addModelFromClipboard = async (
    tool: SystemModel,
    newDisplayName: string,
    newDescription?: string
  ) => {
    if (!activeOrganizationId) return undefined;
    if (!data) return undefined;
    const { me } = data;
    try {
      setLoading(true);
      const batch = fs.batch();
      const newModelDoc = fs
        .collection("organizations")
        .doc(activeOrganizationId)
        .collection("systems")
        .doc();

      const newModel: SystemModel = {
        ...tool,
        id: newModelDoc.id,
        displayName: newDisplayName,
        organisation: activeOrganizationId,
        description: newDescription || "",
        ownerId: me.id,
        SPM_version: 2,
        latestEdited: { userId: me.id, time: new Date() },
      };

      batch.set(newModelDoc, convertToFirestoreFormat(newModel));

      //copy reports under model:
      const snap = await fs
        .collection("organizations")
        .doc(activeOrganizationId)
        .collection("systems")
        .doc(tool.id)
        .collection("RawDataReports")
        .get();
      snap.docs.forEach((doc) => {
        const copyReportDoc = newModelDoc.collection("RawDataReports").doc(doc.id);
        batch.set(copyReportDoc, doc.data());
      });

      await batch.commit();
      setLoading(false);
      navigate(`/tools/${newModelDoc.id}`);
      return newModelDoc.id;
    } catch (error) {
      console.error(error);
      toast.error("There was an error creating the system");
    }
    return undefined;
  };

  return { loading, addModelFromClipboard };
};
