import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const UNPIN_TOOL = graphql(`
  mutation UNPIN_TOOL($systemId: ID!) {
    userUnpinSystemModel(systemModelId: $systemId) {
      ... on User {
        ...PinnedItems
      }
    }
  }
`);

export const useUnpinTool = () => {
  const mutation = useMutation(UNPIN_TOOL);

  return mutation;
};
