import { MapCamera } from "../types";

const CENTER_THRESHOLD = 0.4;
const ZOOM_THRESHOLD = 4;

interface Params {
  from: MapCamera;
  to: MapCamera;
}

/**
 * Function that calculates the animation duration of a map camera move based on the zoom and coordinate distance between two points.
 * @param from - An object specifying the zoom, latitude and longitude of the starting position.
 * @param to - An object specifying the zoom, latitude and longitude of the target position.
 * @returns A number value between 700ms and 2000ms that should be set for the animation duration.
 */
export const calcMapAnimationDuration = ({ from, to }: Params) => {
  const { latitude: fromLat, longitude: fromLng, zoom: fromZoom } = from;
  const { latitude: toLat, longitude: toLng, zoom: toZoom } = to;

  const zoomDiff = Math.abs(toZoom - fromZoom);
  const lngDiff = Math.abs(toLng - fromLng);
  const latDiff = Math.abs(toLat - fromLat);
  const centerDiff = (lngDiff + latDiff) / 2;

  let time = 700;

  // Arbitrarily selected values that somehow result in a nice animation
  if (zoomDiff > ZOOM_THRESHOLD) time += zoomDiff * 130;
  if (centerDiff > CENTER_THRESHOLD) time += centerDiff * 1300;

  if (time > 2000) time = 2000;

  return time;
};
