import { TimeslotInput } from "GraphQL/gql/graphql";
import React, { useMemo, useState } from "react";
import { DayTime } from "utils/DayTime";
import classNames from "utils/jsUtils/className";
import getUUID from "utils/jsUtils/getUUID";

interface Props {
  onUpdate: (slot: TimeslotInput) => void;
  slot?: TimeslotInput;
}

const times = Array.from({ length: 25 }, (_, i) => new DayTime().addHours(i));

export const TimePicker: React.FC<Props> = ({ onUpdate, slot }) => {
  const [selectedFromTime, setSelectedFromTime] = useState<DayTime | null>(
    slot?.startTime || null
  );
  const [selectedToTime, setSelectedToTime] = useState<DayTime | null>(slot?.endTime || null);
  const [hoveredTime, setHoveredTime] = useState<DayTime | null>(null);
  const isSelectingPeriod = useMemo(
    () => selectedFromTime && !selectedToTime,
    [selectedFromTime, selectedToTime]
  );

  const updateTheTime = (from: DayTime, to: DayTime) => {
    if (!slot) {
      onUpdate({
        id: getUUID(),
        startTime: from,
        endTime: to,
        value: 0,
      });
    } else if (
      from.inSeconds() !== slot.startTime.inSeconds() ||
      to.inSeconds() !== slot.endTime.inSeconds()
    ) {
      onUpdate({
        ...slot,
        startTime: from,
        endTime: to,
      });
    }
  };

  const onSelectTime = (time: DayTime) => {
    try {
      if (selectedFromTime && selectedToTime) {
        setSelectedToTime(null);
        setSelectedFromTime(time);
      } else if (!selectedFromTime) setSelectedFromTime(time);
      else if (selectedFromTime.isSameHour(time)) {
        setSelectedFromTime(null);
        setSelectedToTime(null);
      } else if (time.isBefore(selectedFromTime)) {
        setSelectedToTime(selectedFromTime);
        setSelectedFromTime(time);
        updateTheTime(time, selectedFromTime);
      } else {
        setSelectedToTime(time);
        updateTheTime(selectedFromTime, time);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className=" w-88 rounded border border-gray-200 bg-white px-4 py-2 text-sm shadow-lg">
      <div className="mb-2 text-sm font-bold">From - To</div>
      <div className="flex flex-wrap">
        {times.map((time) => {
          const isSelectedTo = selectedFromTime && selectedFromTime.isSameHour(time);

          let isSelected = false;

          try {
            if (
              isSelectedTo ||
              (selectedFromTime &&
                selectedToTime &&
                time.isBetween(selectedFromTime, selectedToTime))
            ) {
              isSelected = true;
            }
          } catch (error) {
            console.error(error);
          }

          const isBetweenStartAndEnd =
            selectedFromTime !== null &&
            isSelectingPeriod &&
            hoveredTime &&
            ((hoveredTime >= time && selectedFromTime < time) ||
              (hoveredTime <= time && selectedFromTime > time));
          const isHovered = hoveredTime === time;

          // Determines if timeslot is the start time of the period being selected (only while selecting the end time)
          const isStartTime =
            isSelectingPeriod &&
            ((isHovered && selectedFromTime && time < selectedFromTime) ||
              (time === selectedFromTime && hoveredTime && hoveredTime > time));

          // Determines if timeslot is the end time of the period being selected (only while selecting the end time)
          const isEndTime =
            isSelectingPeriod &&
            ((isHovered && selectedFromTime && time > selectedFromTime) ||
              (time === selectedFromTime && hoveredTime && hoveredTime < time));

          return (
            <button
              key={time.inSeconds()}
              onMouseEnter={() => {
                setHoveredTime(time);
              }}
              className={classNames(
                "w-1/5 px-1 py-1 transition-all duration-100 focus:outline-none",
                isStartTime && "rounded-l-xl",
                isEndTime && "rounded-r-xl",
                isSelected && "bg-indigo-600 text-white hover:bg-indigo-200",
                !isSelected &&
                  isBetweenStartAndEnd &&
                  "bg-indigo-200 text-gray-700 hover:bg-indigo-600 hover:text-white",
                !isSelected &&
                  !isBetweenStartAndEnd &&
                  "bg-white text-gray-700 hover:bg-indigo-200"
              )}
              onClick={(e) => {
                onSelectTime(time);
                e.stopPropagation();
              }}
            >
              {time.format("HH:mm")}
            </button>
          );
        })}
      </div>
    </div>
  );
};
