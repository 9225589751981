import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const GET_USERS_PROJECTS = graphql(`
  query GET_USERS_PROJECTS {
    me {
      __typename
      id
      ownedProjects {
        id
        name
      }
    }
  }
`);

export const useGetUsersProjects = () => {
  const result = useQuery(GET_USERS_PROJECTS);

  return result;
};
