import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_All_Users_In_OrganisationQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_ALL_USERS_IN_ORGANIZATION = graphql(`
  query GET_ALL_USERS_IN_ORGANISATION($after: String, $first: Int) {
    organization {
      id
      members(after: $after, first: $first) @connection(key: "OrganizationMembers") {
        edges {
          cursor
          node {
            role
            user {
              id
              firstName
              fullName
              lastName
              mail
              preferences {
                enableExperimentalFeatures
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
      }
    }
  }
`);

type OrganizationUsers = ArrayElement<
  Get_All_Users_In_OrganisationQuery["organization"]["members"]["edges"]
>["node"][];

export type OrganizationUser = ArrayElement<OrganizationUsers>;

type Props = {
  first: number;
};

export const useGetAllUsersInOrganization = ({ first }: Props) => {
  const result = useQuery(GET_ALL_USERS_IN_ORGANIZATION, {
    variables: { after: null, first },
  });

  return result;
};
