import { DotsSixVertical } from "@phosphor-icons/react";
import React, { PropsWithChildren, useCallback, useRef, useState } from "react";
import classNames from "utils/jsUtils/className";

interface ResizableContainerProps {
  initialSize: number;
  resizeAxis?: "y" | "x";
  minSize?: number;
  maxSize?: number;
  className?: string;
  hideDragHandle?: boolean;
  onSizeChanged?: (newSize: number) => void;
}

const calculateNewContainerSize = (
  clientValue: number,
  containerRectValue: number,
  isUsingDragHandle: boolean,
  dragHandleValue?: number
) => {
  let newSize = clientValue - containerRectValue;
  if (isUsingDragHandle && dragHandleValue) {
    newSize += dragHandleValue;
  }
  return newSize;
};

const ResizableContainer: React.FC<PropsWithChildren<ResizableContainerProps>> = ({
  initialSize,
  resizeAxis = "y",
  minSize = 30,
  maxSize,
  hideDragHandle,
  className,
  children,
  onSizeChanged,
  ...htmlProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<SVGSVGElement>(null);
  const [containerSize, setContainerSize] = useState(initialSize);
  const [isResizing, setIsResizing] = useState(false);
  const sizeRef = useRef<number>(containerSize);

  const resizeContainer = useCallback(
    (e: MouseEvent) => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const isUsingDragHandle = dragHandleRef.current === e.target;

      sizeRef.current =
        resizeAxis === "x"
          ? calculateNewContainerSize(
              e.clientX,
              containerRect.left,
              isUsingDragHandle,
              dragHandleRef.current?.width.baseVal.value
            )
          : calculateNewContainerSize(
              e.clientY,
              containerRect.top,
              isUsingDragHandle,
              dragHandleRef.current?.width.baseVal.value
            );

      setContainerSize(sizeRef.current);
    },
    [resizeAxis]
  );

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
    document.querySelector("#appRoot")?.classList.remove("select-none");
    window.removeEventListener("mousemove", resizeContainer);
    window.removeEventListener("mouseup", stopResizing);
    onSizeChanged?.(sizeRef.current);
  }, [onSizeChanged, resizeContainer]);

  const startResizing = useCallback(() => {
    setIsResizing(true);
    document.querySelector("#appRoot")?.classList.add("select-none");
    window.addEventListener("mousemove", resizeContainer);
    window.addEventListener("mouseup", stopResizing);
  }, [resizeContainer, stopResizing]);

  return (
    <div className="h-full" {...htmlProps}>
      <div
        className={classNames(
          resizeAxis === "y" && `flex flex-row w-full`,
          resizeAxis === "x" && `flex flex-col h-full`,
          className,
          "relative group"
        )}
        ref={containerRef}
        style={{
          ...(resizeAxis === "x" && {
            width: containerSize,
            maxWidth: maxSize,
            minWidth: minSize,
          }),
          ...(resizeAxis === "y" && {
            height: containerSize,
            maxHeight: maxSize,
            minHeight: minSize,
          }),
        }}
        data-testid="resizableContainer"
      >
        {children}
        <div
          className={classNames(
            isResizing && "bg-gray-400",
            `absolute hover:bg-gray-400 transition-all duration-200 ease-in-out`,
            resizeAxis === "y" && "cursor-row-resize w-full h-0.5  left-0 right-0 bottom-0",
            resizeAxis === "x" && "cursor-col-resize h-full w-0.5 top-0 bottom-0 right-0"
          )}
          onMouseDown={startResizing}
          data-testid="resizeAxis"
        />

        {!hideDragHandle && (
          <DotsSixVertical
            className={classNames(
              isResizing && "opacity-100",
              "h-5 w-5 absolute group-hover:opacity-100 opacity-0 transition duration-200 ease-out",
              resizeAxis === "y" &&
                "cursor-row-resize left-1/2 -translate-x-1/2 bottom-2 rotate-90",
              resizeAxis === "x" && "cursor-col-resize top-1/2 -translate-y-1/2 right-2"
            )}
            onMouseDown={startResizing}
            data-testid="dragHandle"
            ref={dragHandleRef}
          />
        )}
      </div>
    </div>
  );
};

export default ResizableContainer;
