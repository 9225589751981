import { Placement } from "@floating-ui/react-dom-interactions";

export const getTransformOrigin = (placement: Placement) => {
  switch (placement) {
    case "bottom":
      return "top";
    case "bottom-end":
      return "top right";
    case "bottom-start":
      return "top left";
    case "top":
      return "bottom";
    case "top-end":
      return "bottom right";
    case "top-start":
      return "bottom left";
    case "left":
      return "right";
    case "left-end":
      return "right bottom";
    case "left-start":
      return "right top";
    case "right":
      return "left";
    case "right-end":
      return "left bottom";
    case "right-start":
      return "left top";
    default:
      return "center";
  }
};
