import PopoverButton from "./PopoverButton";
import PopoverContent from "./PopoverContent";
import PopoverRoot from "./PopoverRoot";
import PopoverTrigger from "./PopoverTrigger";

/**
 * UI component that can toggle and display a popover
 */
const Popover = {
  Root: PopoverRoot,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
  Button: PopoverButton,
};

export default Popover;
