import * as ContextMenu from "@radix-ui/react-context-menu";
import React, { ReactNode } from "react";
import { ContextMenuItem } from "./ContextMenuItem";

type Props = {
  children: ReactNode;
  icon: JSX.Element;
  onClick: () => void;
};

/**
 * Do not import from here. Import from ContextMenu.tsx
 */
export const ContextMenuButton = ({ children, icon, onClick }: Props) => {
  return (
    <ContextMenu.Item
      data-testid="contextMenuButton"
      className="hover:bg-slate-100 mx-1 rounded-lg outline-none focus:bg-slate-100"
      onClick={onClick}
    >
      <ContextMenuItem icon={icon}>{children}</ContextMenuItem>
    </ContextMenu.Item>
  );
};
