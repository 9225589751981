import React, { useRef } from "react";
import classNames from "utils/jsUtils/className";
import { isThermoColor } from "../Typesafes/TailwindTypesafes";
import TruncateTooltip from "./TruncateTooltip";

interface ITextProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  truncate?: boolean;
  children: React.ReactNode;
}

export enum TextSizes {
  H1 = "text-3xl tracking-wide max-w-[45ch]",
  H2 = "text-2xl tracking-normal max-w-[50ch]",
  H3 = "text-xl tracking-normal max-w-[60ch]",
  H4 = "text-lg tracking-normal max-w-[60ch]",
  Base = "text-base tracking-normal max-w-[65ch]",
  Small = "text-sm tracking-normal max-w-[65ch]",
  XSmall = "text-xs font-light tracking-wide max-w-[66ch]",
}

const isThermoTextColor = (cssClass: string) => {
  const [property, modifier] = cssClass.split("-");

  if (property !== "text") return false;
  return isThermoColor(modifier);
};

const getTextColor = (className: string) => {
  const textColor = className.split(" ").find(isThermoTextColor) || "text-gray-700";
  const newClassName = className.replace(textColor, "");

  return { textColor, newClassName };
};

const H1 = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(newClassName, textColor, TextSizes.H1, truncate && "truncate")}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const H2 = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(newClassName, textColor, TextSizes.H2, truncate && "truncate")}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const H3 = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(newClassName, textColor, TextSizes.H3, truncate && "truncate")}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const H4 = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(newClassName, textColor, TextSizes.H4, truncate && "truncate")}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const Base = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(newClassName, textColor, TextSizes.Base, truncate && "truncate")}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const Small = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(
          newClassName,
          textColor,
          TextSizes.Small,
          truncate && "truncate"
        )}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const XSmall = ({ className = "", children, truncate = false, ...htmlProps }: ITextProps) => {
  const { textColor, newClassName } = getTextColor(className);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <TruncateTooltip shouldTruncate={truncate} tooltipContent={children} childRef={ref}>
      <div
        {...htmlProps}
        ref={ref}
        className={classNames(
          newClassName,
          textColor,
          TextSizes.XSmall,
          truncate && "truncate"
        )}
      >
        {children}
      </div>
    </TruncateTooltip>
  );
};

const Text = {
  H1,
  H2,
  H3,
  H4,
  Base,
  Small,
  XSmall,
};

export default Text;
