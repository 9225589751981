import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_USER = graphql(`
  query GET_USER($userId: ID!) {
    user(userId: $userId) {
      ... on User {
        id
        fullName
      }
      ... on UserNotFound {
        __typename
        userId
      }
    }
  }
`);

export const useGetUser = (userId: string) => {
  const result = useQuery(GET_USER, {
    variables: { userId },
  });
  return result;
};
