import { Clipboard, Plus } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { TabButton } from "Components/Basic/Buttons";
import ProjectTypes from "Components/Projects/ProjectTypes";
import ComponentLibraryCard from "Components/Systems/ComponentLibraryCard";
import ToolsList from "Components/Systems/Overview/ToolsList";
import { TopBarWrapper } from "Components/TopBar/TopBarWrapper";
import {
  useArchivedSimulationModels,
  useSimulationModels,
  useUserRole,
} from "api/useFirebase";
import { SystemModel } from "model/datatypes";
import { useEffect, useMemo, useState } from "react";
import { Alert } from "thermo/Alert/Alert";
import { Button } from "thermo/button/Button";
import { SearchBar } from "thermo/searchbar/SearchBar";
import Text from "thermo/typography/Typography";
import { escapeRegExp } from "utils/escapeRegExp";
import { useGetCurrentUser } from "../../api/user/getCurrentUser";
import Popover from "../../thermo/popover/Popover";
import PageNotFound from "./PageNotFound";

const ToolsOverview = () => {
  const { data } = useGetCurrentUser();
  const userHasEnabledExperimentalFeatures = data?.me.preferences.enableExperimentalFeatures;
  const { hasSimulatorAccess } = useUserRole();
  const { allCurrentModels, optimizationModels } = useSimulationModels();
  const { archivedModels, archivedOptimizationModels } = useArchivedSimulationModels();

  const [viewedTab, setViewedTab] = useState<"active" | "archived">("active");
  const [searchWord, setSearchWord] = useState("");
  const optimizationName = "optimization type";

  const { shownTools, shownOptimizationTypes } = useMemo(() => {
    let models = viewedTab === "archived" ? archivedModels : allCurrentModels;

    let optimizatioModelsArray =
      viewedTab === "archived" ? archivedOptimizationModels : optimizationModels;

    if (searchWord.length > 0) {
      const escapedRegExpSearchTerm = escapeRegExp(searchWord);
      const filterAlg = (model: SystemModel) =>
        !!model.displayName.match(new RegExp(escapedRegExpSearchTerm, "i"));

      models = models.filter(filterAlg);
      optimizatioModelsArray.filter(filterAlg);
    }

    const sortLastEditedByMostRecent = (a: SystemModel, b: SystemModel) => {
      return (b.latestEdited?.time.valueOf() || 0) - (a.latestEdited?.time.valueOf() || 0);
    };

    const tempShownModels = models.sort(sortLastEditedByMostRecent);
    const tempShownOptimizationModels = optimizatioModelsArray.sort(
      sortLastEditedByMostRecent
    );
    return {
      shownTools: tempShownModels,
      shownOptimizationTypes: tempShownOptimizationModels,
    };
  }, [
    allCurrentModels,
    searchWord,
    archivedModels,
    viewedTab,
    optimizationModels,
    archivedOptimizationModels,
  ]);

  useEffect(() => {
    if (viewedTab === "active") return;
    const totalArchived = archivedModels.length + archivedOptimizationModels.length;
    if (totalArchived <= 0) setViewedTab("active");
  }, [viewedTab, archivedModels, archivedOptimizationModels]);

  if (!hasSimulatorAccess) return <PageNotFound />;

  return (
    <div data-testid="systemsOverviewPage" className="w-full min-h-full bg-white pb-8">
      <TopBarWrapper pageTitle="Tools">
        <div className="flex w-full items-center gap-2">
          <div className="flex-grow">
            {(archivedModels.length > 0 || archivedOptimizationModels.length > 0) && (
              <>
                <TabButton
                  onClick={() => setViewedTab("active")}
                  active={viewedTab === "active"}
                  className="text-sm"
                >
                  Active tools
                </TabButton>
                <TabButton
                  onClick={() => setViewedTab("archived")}
                  active={viewedTab === "archived"}
                  className="text-sm"
                >
                  Archived
                </TabButton>
              </>
            )}
          </div>
          <SearchBar
            searchValue={searchWord}
            setSearchValue={(updatedValue) => setSearchWord(updatedValue)}
            placeholder="Search tools"
          />

          {userHasEnabledExperimentalFeatures ? (
            <Popover.Root>
              <Popover.Trigger>
                <Button data-testid="addSystem" icon={<Plus size={14} />} size="sm">
                  New tool
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <Link to="tools/create-tool">
                  <Popover.Button icon={<Plus />}>New tool</Popover.Button>
                </Link>

                <Link to="tools/create-tool-from-clipboard">
                  <Popover.Button icon={<Clipboard />}>Tool from clipboard</Popover.Button>
                </Link>
              </Popover.Content>
            </Popover.Root>
          ) : (
            <Link to="tools/create-tool">
              <Button data-testid="addSystem" icon={<Plus size={14} />} size="sm">
                New tool
              </Button>
            </Link>
          )}
        </div>
      </TopBarWrapper>

      {/* Tools */}
      <div className="mb-12">
        {shownTools.length < 1 && (
          <div className="w-full px-4 pt-2">
            {!searchWord && viewedTab === "active" && (
              <>
                <Alert type="info" text="No tools found" />
                <Link to="tools/create-tool">
                  <Button
                    className="mt-3"
                    size="md"
                    variant="brandColor"
                    icon={<Plus size={14} />}
                  >
                    Add new tool
                  </Button>
                </Link>
              </>
            )}

            {shownTools.length < 1 && searchWord && (
              <Alert type="info" text="No tools found matching the search term" />
            )}
          </div>
        )}

        {shownTools.length > 0 && <ToolsList tools={shownTools} />}
      </div>

      {/* Optimization */}
      <div className="mb-12">
        <div className="mt-6 mb-4 px-4 flex w-full flex-row items-center justify-between">
          <Text.H4 className="font-medium capitalize">{`${optimizationName}s`}</Text.H4>

          <Link to="tools/create-tool" state={{ type: optimizationName }}>
            <Button
              size="sm"
              icon={<Plus size={14} />}
              className="text-sm font-medium capitalize"
            >
              {`Add new ${optimizationName}`}
            </Button>
          </Link>
        </div>

        {shownOptimizationTypes.length < 1 && (
          <div className="w-full px-4 pt-2">
            {!searchWord && viewedTab === "active" && (
              <Alert type="info" text={`No ${optimizationName}s found`} />
            )}

            {shownOptimizationTypes.length < 1 && searchWord && (
              <Alert
                type="info"
                text={`No ${optimizationName}s found matching the search term`}
              />
            )}
          </div>
        )}

        {shownOptimizationTypes.length > 0 && (
          <>
            <hr />
            <ToolsList tools={shownOptimizationTypes} />
          </>
        )}
      </div>

      {/* Project templates */}
      <ProjectTypes />

      <Text.H4 className="pl-4 mb-4 font-medium">Other</Text.H4>
      <div className="w-1/3 px-4">
        <ComponentLibraryCard />
      </div>
    </div>
  );
};

export default ToolsOverview;
