import { WarningOctagon } from "@phosphor-icons/react";
import React from "react";
import { Button } from "./Buttons";
import Modal from "./Modal";

type Props = {
  open?: boolean;
  cancel: () => void;
  confirmUnsafeNavigation: () => void;
};

export const UnsavedStateModal = ({ open, cancel, confirmUnsafeNavigation }: Props) => (
  <Modal open={open} onClose={cancel} noPadding>
    <>
      <div data-testid="blockRouteModal" className="flex px-6 py-7">
        <div className="flex items-center justify-end pr-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-100">
            <WarningOctagon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
        </div>
        <div className="">
          <div className="mb-2 text-lg font-normal leading-6 text-gray-900">
            Unsaved changes
          </div>
          <div className="text-sm text-gray-500">
            You have not saved your changes. Are you sure you want to continue?
          </div>
        </div>
      </div>

      <div className="flex justify-end  rounded-b-lg bg-gray-50 p-3">
        <Button
          onClick={cancel}
          className=""
          buttonType="secondary"
          data-testid="cancelUnsavedStateModalButton"
        >
          Cancel
        </Button>
        <Button
          data-testid="continueUnsavedStateModalButton"
          onClick={confirmUnsafeNavigation}
          className="ml-2"
          buttonType="primary"
        >
          Continue
        </Button>
      </div>
    </>
  </Modal>
);
