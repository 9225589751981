import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Connected_RepositoriesQuery } from "GraphQL/gql/graphql";
import { GET_CONNECTED_REPOSITORIES } from "./getConnectedRepositories";

const REMOVE_CONNECTED_REPOSITORY = graphql(`
  mutation REMOVE_CONNECTED_REPOSITORY($repositoryId: ID!) {
    connectedRepositoryDelete(repositoryId: $repositoryId) {
      repositoryId
    }
  }
`);

export const useRemoveConnectedRepository = (repositoryId: string) => {
  const mutation = useMutation(REMOVE_CONNECTED_REPOSITORY, {
    variables: {
      repositoryId,
    },
    update(cache, { data }) {
      const removedRepositoryId = data?.connectedRepositoryDelete.repositoryId;
      if (!removedRepositoryId) return;

      cache.updateQuery({ query: GET_CONNECTED_REPOSITORIES }, (cacheData) => {
        if (!cacheData) return undefined;

        const updatedConnectedRepositories: Get_Connected_RepositoriesQuery["connectedRepositories"] =
          {
            ...cacheData.connectedRepositories,
            edges: cacheData.connectedRepositories.edges.filter(
              (edge) => edge.node.id !== removedRepositoryId
            ),
          };

        const updatedData: Get_Connected_RepositoriesQuery = {
          ...cacheData,
          connectedRepositories: updatedConnectedRepositories,
        };

        return updatedData;
      });
    },
  });

  return mutation;
};
