import { ConditionalWrapper } from "Components/Basic/ConditionalWrapper";
import Modal from "Components/Basic/Modal";
import NewCSV from "Components/Input/newInput/NewCSV";
import { useGetOrganizationCreditBalance } from "api/organisation/credit/getOrganizationCreditBalance";
import { AnimatePresence } from "framer-motion";
import { DatasetScenario } from "model/datatypes";
import { useState } from "react";
import { Button } from "thermo/button/Button";
import Tooltip from "thermo/tooltip/Tooltip";
import ScheduleInput from "../../Input/newSchedule/ScheduleInput";

interface Props {
  onFinish: () => void;
  onCSVAdded: (newCSV: DatasetScenario) => void;
  projectID: string;
}

const NewInput = ({ onFinish, onCSVAdded, projectID }: Props) => {
  const [inputType, setInputType] = useState<null | "schedule" | "newCSV">(null);

  const { data, error } = useGetOrganizationCreditBalance();
  const insufficientCredits = !!error || data?.organization.creditBalance <= 0;

  return (
    <>
      <Modal onClose={onFinish} className="relative w-1/2">
        <div className="font-medium">Select input type</div>
        <div className="flex gap-4 mt-4">
          <ConditionalWrapper
            condition={insufficientCredits}
            wrapper={(wrappedChildren) => (
              <Tooltip label="Cannot upload csv due to insufficient credits. Contact your organization owner to add more credits.">
                {wrappedChildren}
              </Tooltip>
            )}
          >
            <Button
              size="lg"
              data-testid="uploadCSV"
              className="mt-4 h-10 text-sm"
              onClick={() => {
                setInputType("newCSV");
              }}
              disabled={insufficientCredits}
            >
              Upload CSV
            </Button>
          </ConditionalWrapper>
          <Button
            size="lg"
            className="mt-4 h-10 text-sm"
            onClick={() => {
              setInputType("schedule");
            }}
          >
            Create schedule
          </Button>
        </div>
      </Modal>
      <AnimatePresence>
        {inputType === "newCSV" && projectID && (
          <NewCSV
            key="newCSV"
            onAddCSV={(newCSV) => {
              onCSVAdded(newCSV);
            }}
            onFinish={() => {
              setInputType(null);
            }}
          />
        )}
        {inputType === "schedule" && (
          <ScheduleInput
            key="schedule"
            onFinished={() => {
              setInputType(null);
              onFinish();
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default NewInput;
