import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PaginatedProjectsQuery } from "GraphQL/gql/graphql";
import { PAGINATED_PROJECTS } from "api/project/getPaginatedProjects";

const UPDATE_WORKSPACE_OF_PROJECT = graphql(`
  mutation UPDATE_WORKSPACE_OF_PROJECT($workspaceId: ID, $projectId: ID!) {
    projectMoveToWorkspace(projectId: $projectId, workspaceId: $workspaceId) {
      __typename
      name
      mine
      mostRecentRun
      id
      archived
      isPinned
      scenarioCount
      description
      createdAt
      workspaceId
      owner {
        ... on User {
          id
          __typename
          fullName
        }
      }
    }
  }
`);

type Props = {
  currentWorkspaceId: string | null;
};

export const useUpdateWorkspaceOfProject = ({ currentWorkspaceId }: Props) => {
  const mutation = useMutation(UPDATE_WORKSPACE_OF_PROJECT, {
    update(cache, result) {
      const project = result.data?.projectMoveToWorkspace;
      if (!project) {
        console.error(
          "The project was not returned from the server - cache update cancelled."
        );
        return;
      }
      const projectId = project.id;
      const newWorkspaceId = project.workspaceId;

      if (newWorkspaceId === undefined) {
        console.error("The new workspace ID was undefined - cache update cancelled.");
        return;
      }

      const oldWorkspaceId = currentWorkspaceId;

      // Remove project from the old workspace
      cache.updateQuery(
        { query: PAGINATED_PROJECTS, variables: { workspaceId: oldWorkspaceId } },
        (cacheData) => {
          if (!cacheData) return undefined;

          const updatedWorkspaceProjectList: PaginatedProjectsQuery = {
            ...cacheData,
            workspace: {
              ...cacheData.workspace,
              projects: {
                ...cacheData.workspace.projects,
                edges: cacheData.workspace.projects.edges.filter(
                  (edge) => edge.node.id !== projectId
                ),
              },
            },
          };
          return updatedWorkspaceProjectList;
        }
      );

      // Insert project into the new workspace
      cache.updateQuery(
        { query: PAGINATED_PROJECTS, variables: { workspaceId: newWorkspaceId } },
        (cacheData) => {
          if (!project) {
            console.error(
              "The project to insert into the new workspace could not be found - cache update cancelled"
            );
            return undefined;
          }
          if (!cacheData) return undefined;

          const updatedWorkspaceProjectList: PaginatedProjectsQuery = {
            ...cacheData,
            workspace: {
              ...cacheData.workspace,
              projects: {
                ...cacheData.workspace.projects,
                edges: [
                  { node: project, __typename: "ProjectEdge", cursor: project.id },
                  ...cacheData.workspace.projects.edges,
                ],
              },
            },
          };

          return updatedWorkspaceProjectList;
        }
      );
    },
  });

  return mutation;
};
