import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { WORKSPACES } from "./getWorkspaces";

const CREATE_WORKSPACE = graphql(`
  mutation CREATE_WORKSPACE($name: String!, $description: String) {
    workspaceCreate(name: $name, description: $description) {
      __typename
      id
      createdAt
      description
      members {
        id
        role
        user {
          firstName
          fullName
          id
          lastName
          mail
        }
      }
      name
    }
  }
`);

export const useCreateWorkspace = () => {
  const mutation = useMutation(CREATE_WORKSPACE, {
    update(cache, { data }) {
      if (!data) return;
      const createdWorkspace = data.workspaceCreate;

      const workspaceList = cache.readQuery({
        query: WORKSPACES,
      });
      if (!workspaceList) return;

      cache.writeQuery({
        query: WORKSPACES,
        data: {
          workspaces: [createdWorkspace, ...workspaceList.workspaces],
        },
      });
    },
  });

  return mutation;
};
