import { Rows } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useGetScenario } from "api/scenario/getScenario";
import { useLocation } from "react-router-dom";
import { RecentItem } from "../types";
import { RecentItemTemplate } from "./RecentItemTemplate";

type Props = {
  scenarioId: string;
  projectId: string;
  type: Extract<RecentItem["type"], "scenario">;
  close: () => void;
};

export const RecentScenarioItem = ({ scenarioId, projectId, type, close }: Props) => {
  const navigate = useNavigateApp();
  const location = useLocation();

  const { loading, data } = useGetScenario({ scenarioId, projectId });
  const scenario = data?.scenario;

  const isCurrentlyOnItemPage = location.pathname === `/project/${projectId}/${scenarioId}`;

  if (scenario?.__typename !== "Scenario") return null;
  return (
    <RecentItemTemplate
      icon={<Rows size={16} weight="bold" />}
      type={type}
      text={scenario.name}
      loading={loading}
      onClick={() => {
        if (isCurrentlyOnItemPage) return;
        navigate(`/project/${projectId}/${scenarioId}`);
        close();
      }}
      recentItemIsActivePage={isCurrentlyOnItemPage}
    />
  );
};
