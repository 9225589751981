import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { WorkspacesQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const WORKSPACES = graphql(`
  query WORKSPACES {
    workspaces {
      __typename
      id
      name
      createdAt
      description
      members {
        role
        user {
          id
          firstName
          lastName
          fullName
          mail
        }
      }
    }
  }
`);

export const useGetWorkspaces = () => {
  const result = useQuery(WORKSPACES, {
    fetchPolicy: "cache-and-network",
  });
  return result;
};

export type Workspace = ArrayElement<WorkspacesQuery["workspaces"]>;
export type Workspaces = WorkspacesQuery["workspaces"];
