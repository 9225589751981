import { Stack } from "@phosphor-icons/react";
import { Workspace as WorkspaceType } from "api/workspace/getWorkspaces";
import { Reorder } from "framer-motion";
import React, { useState } from "react";
import { GeneralWorkspace, Workspace } from "./Workspace/Workspace";

type Props = {
  workspaces: Array<GeneralWorkspace | WorkspaceType>;
  refetchWorkspaces: () => void;
};

export const Workspaces = ({ workspaces, refetchWorkspaces }: Props) => {
  // Reorder will not work currently (rendering reorderableworkspaces makes updates not come through i.e. removing a user from a workspace)
  const [reorderableWorkspaces, setReorderableWorkspaces] = useState(workspaces);

  return (
    <Reorder.Group
      axis="y"
      onReorder={setReorderableWorkspaces}
      values={reorderableWorkspaces}
      className="w-full flex flex-col gap-0.5"
    >
      {workspaces.map((workspace) => (
        <Workspace
          key={workspace.id}
          workspace={workspace}
          refetchWorkspaces={refetchWorkspaces}
          icon={
            <Stack
              size={14}
              weight="fill"
              className={`${workspace.name === "General" && "text-indigo-600"}`}
            />
          }
        />
      ))}
    </Reorder.Group>
  );
};
