import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_CONNECTED_REPOSITORY = graphql(`
  query GET_CONNECTED_REPOSITORY($repositoryId: ID!) {
    connectedRepository(repositoryId: $repositoryId) {
      ... on ConnectedRepository {
        __typename
        id
        displayName
        path
        lastCommit {
          ... on Commit {
            id
            message
            title
          }
        }
      }
      ... on RemoteRepositoryNotFound {
        __typename
        remoteRepositoryId
        remoteRepositoryProvider
      }
    }
  }
`);

export const useGetConnectedRepository = (repositoryId: string) => {
  const result = useQuery(GET_CONNECTED_REPOSITORY, {
    variables: {
      repositoryId,
    },
  });

  return result;
};
