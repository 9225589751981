/**
 * @description This function is used to download a file directly from a link
 * @param url A direct download link
 * @param fileName the name of the file to download
 * @example downloadFileFromUrl("urlToDownloadFileFrom")
 */
export const downloadFileFromUrl = async (url: string, fileName: string) => {
  const fetchResponse = await fetch(url);
  const fileBlob = await fetchResponse.blob();

  const objectURL = URL.createObjectURL(fileBlob);
  const link = document.createElement("a");
  link.href = objectURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(objectURL);
};
