import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_PROJECT = graphql(`
  query GET_PROJECT($projectId: ID!) {
    project(projectId: $projectId) {
      __typename
      name
      id
    }
  }
`);

type Props = {
  projectId: string;
};

export const useGetProject = ({ projectId }: Props) => {
  const query = useQuery(GET_PROJECT, { variables: { projectId } });

  return query;
};
