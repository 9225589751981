import { useApolloClient } from "@apollo/client";
import { Plus, Trash } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { removeProjectType } from "api/firestore/firestoreAPI";
import {
  GET_PROJECT_TEMPLATES,
  ProjectTemplate,
  useGetProjectTemplates,
} from "api/project/getProjectTemplates";
import { useDeprecatedFirestore } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Alert } from "thermo/Alert/Alert";
import { Button } from "thermo/button/Button";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import Text from "thermo/typography/Typography";

type Props = {
  projectType: ProjectTemplate;
};

const ProjectTypeComp = ({ projectType }: Props) => {
  const client = useApolloClient();
  const activeOrganizationId = useActiveOrganizationId();
  const [loading, setLoading] = useState(false);
  const [showDeleteTemplateOpen, setShowDeleteTemplateOpen] = useState(false);
  const fs = useDeprecatedFirestore();

  let constraintsInfo = "";
  if (projectType.__typename === "FixedGroupsProjectTemplate")
    constraintsInfo = `${projectType.groups.length || 0}
    ${projectType.groups?.length === 1 ? "group" : "groups"}`;
  else if (projectType.__typename === "FixedSystemsProjectTemplate")
    constraintsInfo = `${projectType.scenarioConfig.systems?.length || 0}
  ${projectType.scenarioConfig.systems?.length === 1 ? "tool" : "tools"}`;

  return (
    <Link key={projectType.id} to={`/tools/project-template/${projectType.id}`}>
      <div
        data-testid="projectTypeRow"
        key={projectType.id}
        className="relative mb-2 flex cursor-pointer rounded border border-gray-200 bg-white px-4 py-2"
      >
        <span className="w-1/3 self-center px-2 text-sm">{projectType.type}</span>
        <span className="w-1/3 self-center px-2 text-sm">{constraintsInfo}</span>
        <div className="flex w-1/3 justify-end">
          <PopoverMenu placement="left-start">
            <Popover.Button
              data-testid="deleteProjectTypeBtn"
              onClick={() => setShowDeleteTemplateOpen(true)}
              className="text-red-500"
              icon={<Trash width="100%" height="100%" />}
            >
              Delete project template
            </Popover.Button>
          </PopoverMenu>
        </div>
        <AnimatePresence>
          {showDeleteTemplateOpen && activeOrganizationId && (
            <ConfirmModal
              warning
              onCancel={() => setShowDeleteTemplateOpen(false)}
              loading={loading}
              onConfirm={() => {
                if (!loading) {
                  setLoading(true);
                  removeProjectType(fs, projectType.id)
                    .then(() => {
                      client.refetchQueries({ include: [GET_PROJECT_TEMPLATES] });
                      setLoading(false);
                      setShowDeleteTemplateOpen(false);
                    })
                    .catch((error) => {
                      console.error(error);
                      setLoading(false);
                    });
                }
              }}
              headline="Remove project template?"
              description="You will no longer be able to create projects of this type."
            />
          )}
        </AnimatePresence>
        {loading && <LoadingOverlay />}
      </div>
    </Link>
  );
};

const ProjectTypes = () => {
  const { data, loading, fetchMore } = useGetProjectTemplates({ first: 20 });

  useEffect(() => {
    const canFetchMoreProjects = data?.organization.projectTemplates.pageInfo.hasNextPage;
    const endCursor = data?.organization.projectTemplates.pageInfo.endCursor;

    if (canFetchMoreProjects && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    data?.organization.projectTemplates.pageInfo.endCursor,
    data?.organization.projectTemplates.pageInfo.hasNextPage,
    fetchMore,
  ]);

  const projectTypes = data?.organization.projectTemplates.edges;

  return (
    <div className="mb-12">
      <div className="mt-6 mb-4 px-4 flex w-full flex-row items-center justify-between">
        <Text.H4 className="font-medium capitalize">Project templates</Text.H4>

        <Link to="/create-project-template">
          <Button
            size="sm"
            icon={<Plus size={14} />}
            data-testid="addProjectType"
            className="text-sm font-medium capitalize"
          >
            Add new project template
          </Button>
        </Link>
      </div>

      <div className="mb-4 px-4">
        {(!projectTypes || projectTypes.length < 1) && (
          <Alert type="info" text="No project templates found" />
        )}

        {projectTypes?.map((projectType) => (
          <ProjectTypeComp key={projectType.node.id} projectType={projectType.node} />
        ))}
      </div>
      {loading && <LoadingIcon />}
    </div>
  );
};

export default ProjectTypes;
