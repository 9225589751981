import { SidebarSimple, Trash } from "@phosphor-icons/react";
import { Get_Connected_RepositoryQuery } from "GraphQL/gql/graphql";
import { useState } from "react";
import { Button } from "thermo/button/Button";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import RemoveRepositoryModal from "../RemoveRepository/RemoveRepositoryModal";

interface Props {
  repository?: Extract<
    Get_Connected_RepositoryQuery["connectedRepository"],
    { __typename: "ConnectedRepository" }
  >;
  showDrawer: boolean;
  toggleDrawer: () => void;
}

const RepositoryBarOptions = ({ repository, showDrawer, toggleDrawer }: Props) => {
  const [showRemoveRepositoryModal, setShowRemoveRepositoryModal] = useState(false);
  return (
    <>
      <div className="flex items-center gap-2">
        <Button
          size="sm"
          borderless
          onClick={toggleDrawer}
          icon={
            <SidebarSimple
              className="rotate-180"
              size={14}
              weight={showDrawer ? "fill" : "regular"}
            />
          }
        />
        <PopoverMenu iconSize={16} placement="bottom-end">
          <Popover.Button icon={<Trash size={16} />} onClick={() => setShowRemoveRepositoryModal(true)}>Remove repository</Popover.Button>
        </PopoverMenu>
      </div>
      {showRemoveRepositoryModal && repository && (
        <RemoveRepositoryModal
          repositoryId={repository.id}
          repositoryName={repository.displayName}
          closeModal={() => setShowRemoveRepositoryModal(false)}
        />
      )}
    </>
  );
};

export default RepositoryBarOptions;
