import Badge from "Components/Basic/Badge";
import { useGetUser } from "api/userManagement/getUser";
import React from "react";

interface IScenarioLastChangedCell {
  userID: string;
}

const ScenarioLastChangedCell = ({ userID: cellValue }: IScenarioLastChangedCell) => {
  const userData = useGetUser(cellValue).data?.user;
  const user = userData?.__typename === "User" ? userData : undefined;

  return (
    <div className="flex min-w-max flex-row whitespace-nowrap text-sm">
      <span className="truncate">
        {user?.fullName || (
          <Badge text="N/A" backgroundColor="bg-gray-100" textColor="text-gray-600" />
        )}
      </span>
    </div>
  );
};

export default ScenarioLastChangedCell;
