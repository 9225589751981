// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,generate_dependencies
// @generated from protobuf file "spm.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message NoopContent
 */
export interface NoopContent {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
}
/**
 * @generated from protobuf message Scenario
 */
export interface Scenario {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project = 2;
     */
    project: string;
    /**
     * @generated from protobuf field: string execution = 3;
     */
    execution: string;
}
/**
 * @generated from protobuf message ScenarioStatsRequest
 */
export interface ScenarioStatsRequest {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project = 2;
     */
    project: string;
    /**
     * @generated from protobuf field: string execution = 3;
     */
    execution: string;
    /**
     * @generated from protobuf field: string tag = 4;
     */
    tag: string;
}
/**
 * @generated from protobuf message DuplicateScenarioRequest
 */
export interface DuplicateScenarioRequest {
    /**
     * @generated from protobuf field: string scenario_id = 1;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: bool create_child = 3;
     */
    createChild: boolean;
    /**
     * @generated from protobuf field: repeated ScenarioSetting override_settings = 4;
     */
    overrideSettings: ScenarioSetting[];
    /**
     * @generated from protobuf field: string execution_id = 5;
     */
    executionId: string;
}
/**
 * @generated from protobuf message ScenarioSetting
 */
export interface ScenarioSetting {
    /**
     * @generated from protobuf field: repeated string path = 1;
     */
    path: string[];
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: float value = 3;
     */
    value: number;
}
/**
 * @generated from protobuf message SystemRequest
 */
export interface SystemRequest {
    /**
     * @generated from protobuf field: string system = 1;
     */
    system: string;
}
/**
 * @generated from protobuf message UserRequest
 */
export interface UserRequest {
    /**
     * @generated from protobuf field: string user = 1;
     */
    user: string;
}
/**
 * @generated from protobuf message User
 */
export interface User {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string full_name = 4;
     */
    fullName: string;
    /**
     * @generated from protobuf field: string mail = 5;
     */
    mail: string;
    /**
     * @generated from protobuf field: repeated string organisations = 8;
     */
    organisations: string[];
    /**
     * @generated from protobuf field: repeated string favorite_projects = 9;
     */
    favoriteProjects: string[];
}
/**
 * @generated from protobuf message Group
 */
export interface Group {
    /**
     * @generated from protobuf field: string group = 1;
     */
    group: string;
    /**
     * @generated from protobuf field: string project = 2;
     */
    project: string;
}
/**
 * @generated from protobuf message Project
 */
export interface Project {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
}
/**
 * @generated from protobuf message ReadScenario
 */
export interface ReadScenario {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: repeated string tags = 2;
     */
    tags: string[];
    /**
     * @generated from protobuf field: double start = 3;
     */
    start: number;
    /**
     * @generated from protobuf field: double end = 4;
     */
    end: number;
    /**
     * @generated from protobuf field: bool time_range = 5;
     */
    timeRange: boolean;
    /**
     * @generated from protobuf field: bool listen = 6;
     */
    listen: boolean;
    /**
     * @generated from protobuf field: string execution = 7;
     */
    execution: string;
    /**
     * @generated from protobuf field: string project = 8;
     */
    project: string;
}
/**
 * @generated from protobuf message ScenarioStats
 */
export interface ScenarioStats {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 11;
     */
    execution: string;
    /**
     * @generated from protobuf field: string project = 12;
     */
    project: string;
    /**
     * @generated from protobuf field: double min = 2;
     */
    min: number;
    /**
     * @generated from protobuf field: double max = 3;
     */
    max: number;
    /**
     * @generated from protobuf field: bool equi_space = 4;
     */
    equiSpace: boolean;
    /**
     * @generated from protobuf field: double spacing = 5;
     */
    spacing: number;
    /**
     * @generated from protobuf field: int64 n_blocks = 6;
     */
    nBlocks: bigint;
    /**
     * @generated from protobuf field: bool equi_block_len = 7;
     */
    equiBlockLen: boolean;
    /**
     * @generated from protobuf field: int64 block_len0 = 8;
     */
    blockLen0: bigint;
    /**
     * @generated from protobuf field: int64 block_len_last = 9;
     */
    blockLenLast: bigint;
    /**
     * @generated from protobuf field: int64 total_val_len = 10;
     */
    totalValLen: bigint;
}
/**
 * @generated from protobuf message DataBlock
 */
export interface DataBlock {
    /**
     * @generated from protobuf field: string tag = 1;
     */
    tag: string;
    /**
     * @generated from protobuf field: repeated double values = 2;
     */
    values: number[]; // bool row_complete = 3;
    // bool block_complete = 4;
}
/**
 * @generated from protobuf message DataList
 */
export interface DataList {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: repeated DataBlock data = 2;
     */
    data: DataBlock[];
    /**
     * @generated from protobuf field: bool clear = 3;
     */
    clear: boolean;
    /**
     * @generated from protobuf field: bool row_complete = 4;
     */
    rowComplete: boolean;
    /**
     * @generated from protobuf field: bool block_complete = 5;
     */
    blockComplete: boolean;
    /**
     * @generated from protobuf field: bool ignore_index = 6;
     */
    ignoreIndex: boolean;
    /**
     * @generated from protobuf field: string execution = 7;
     */
    execution: string;
    /**
     * @generated from protobuf field: string project = 8;
     */
    project: string;
    /**
     * @generated from protobuf field: bool reset_block_counter = 9;
     */
    resetBlockCounter: boolean;
    /**
     * @generated from protobuf field: string stream_status = 10;
     */
    streamStatus: string;
}
/**
 * @generated from protobuf message IndexedDataList
 */
export interface IndexedDataList {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: DataList data_list = 2;
     */
    dataList?: DataList;
    /**
     * @generated from protobuf field: string writer_id = 3;
     */
    writerId: string; // TODO: pass it once
}
/**
 * @generated from protobuf message DataCompleted
 */
export interface DataCompleted {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 4;
     */
    execution: string;
    /**
     * @generated from protobuf field: string project = 5;
     */
    project: string;
    /**
     * @generated from protobuf field: double eta = 2;
     */
    eta: number;
    /**
     * @generated from protobuf field: bool finalized = 3;
     */
    finalized: boolean;
}
/**
 * @generated from protobuf message Tag
 */
export interface Tag {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string displayName = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string unit = 3;
     */
    unit: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: string type = 7;
     */
    type: string;
    /**
     * @generated from protobuf field: double scaling = 5;
     */
    scaling: number;
    /**
     * @generated from protobuf field: double offset = 6;
     */
    offset: number;
}
/**
 * @generated from protobuf message Alias
 */
export interface Alias {
    /**
     * @generated from protobuf field: string tag = 1;
     */
    tag: string;
    /**
     * @generated from protobuf field: repeated string alias = 2;
     */
    alias: string[];
}
/**
 * @generated from protobuf message Closed
 */
export interface Closed {
    /**
     * @generated from protobuf field: bool is_closed = 1;
     */
    isClosed: boolean;
}
/**
 * @generated from protobuf message ScenarioMetaData
 */
export interface ScenarioMetaData {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: repeated Tag tags = 2;
     */
    tags: Tag[];
    /**
     * @generated from protobuf field: repeated Alias aliases = 3;
     */
    aliases: Alias[];
    /**
     * @generated from protobuf field: double offset = 4;
     */
    offset: number;
    /**
     * @generated from protobuf field: string timezone = 5;
     */
    timezone: string;
    /**
     * @generated from protobuf field: string epoch_type = 6;
     */
    epochType: string;
    /**
     * @generated from protobuf field: string execution = 7;
     */
    execution: string;
    /**
     * @generated from protobuf field: string project = 8;
     */
    project: string;
}
/**
 * @generated from protobuf message ScenarioCustomMetaData
 */
export interface ScenarioCustomMetaData {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project = 4;
     */
    project: string;
    /**
     * @generated from protobuf field: string execution = 5;
     */
    execution: string;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
    /**
     * @generated from protobuf field: string meta = 3;
     */
    meta: string;
}
/**
 * @generated from protobuf message ScenarioDocument
 */
export interface ScenarioDocument {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project = 4;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario_document = 2;
     */
    scenarioDocument: string;
    /**
     * @generated from protobuf field: repeated FileSignedUrl files = 3;
     */
    files: FileSignedUrl[];
}
/**
 * @generated from protobuf message GroupDocument
 */
export interface GroupDocument {
    /**
     * @generated from protobuf field: string group = 1;
     */
    group: string;
    /**
     * @generated from protobuf field: string group_document = 2;
     */
    groupDocument: string;
}
/**
 * @generated from protobuf message ProjectDocument
 */
export interface ProjectDocument {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string project_document = 2;
     */
    projectDocument: string;
}
/**
 * @generated from protobuf message ScenarioProgress
 */
export interface ScenarioProgress {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: string status = 4;
     */
    status: string;
    /**
     * @generated from protobuf field: bool clean = 5;
     */
    clean: boolean;
    /**
     * @generated from protobuf field: double progress = 6;
     */
    progress: number;
    /**
     * @generated from protobuf field: string job_id = 7;
     */
    jobId: string;
}
/**
 * Deprecated
 *
 * @generated from protobuf message ScenarioDataTags
 */
export interface ScenarioDataTags {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 2;
     */
    execution: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
}
/**
 * @generated from protobuf message ScenarioResults
 */
export interface ScenarioResults {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: repeated string names = 3;
     */
    names: string[];
    /**
     * @generated from protobuf field: repeated double values = 4;
     */
    values: number[];
    /**
     * @generated from protobuf field: repeated string units = 5;
     */
    units: string[];
}
/**
 * @generated from protobuf message ScenarioResultsDocument
 */
export interface ScenarioResultsDocument {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 3;
     */
    execution: string;
    /**
     * @generated from protobuf field: string result = 4;
     */
    result: string;
}
/**
 * @generated from protobuf message ScenarioError
 */
export interface ScenarioError {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string error = 3;
     */
    error: string;
    /**
     * @generated from protobuf field: string spm_job_id = 4;
     */
    spmJobId: string;
}
/**
 * Deprecated
 *
 * @generated from protobuf message ScenarioLogEntry
 */
export interface ScenarioLogEntry {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: string spm_job_id = 4;
     */
    spmJobId: string;
    /**
     * @generated from protobuf field: bool initialize = 5;
     */
    initialize: boolean;
}
/**
 * @generated from protobuf message ScenarioFormattedError
 */
export interface ScenarioFormattedError {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: bool initialize = 4;
     */
    initialize: boolean;
    /**
     * @generated from protobuf field: string hint = 5;
     */
    hint: string;
    /**
     * @generated from protobuf field: string category = 6;
     */
    category: string;
    /**
     * @generated from protobuf field: string exception_object_type = 7;
     */
    exceptionObjectType: string;
    /**
     * @generated from protobuf field: string exception_object_message = 8;
     */
    exceptionObjectMessage: string;
    /**
     * @generated from protobuf field: string full_traceback = 9;
     */
    fullTraceback: string;
}
/**
 * @generated from protobuf message FileSignedUrl
 */
export interface FileSignedUrl {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: string id = 3;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 4;
     */
    url: string;
}
/**
 * @generated from protobuf message FileSignedUrls
 */
export interface FileSignedUrls {
    /**
     * @generated from protobuf field: repeated FileSignedUrl files = 1;
     */
    files: FileSignedUrl[];
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
}
/**
 * @generated from protobuf message SignedUrl
 */
export interface SignedUrl {
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message ScenarioFilePath
 */
export interface ScenarioFilePath {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string path = 3;
     */
    path: string;
    /**
     * @generated from protobuf field: string file_id = 4;
     */
    fileId: string;
    /**
     * @generated from protobuf field: string content_type = 5;
     */
    contentType: string;
}
/**
 * @generated from protobuf message FilePath
 */
export interface FilePath {
    /**
     * @generated from protobuf field: string path = 1;
     */
    path: string;
    /**
     * @generated from protobuf field: string content_type = 2;
     */
    contentType: string;
}
/**
 * @generated from protobuf message FilePaths
 */
export interface FilePaths {
    /**
     * @generated from protobuf field: repeated string paths = 1;
     */
    paths: string[];
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project_id = 3;
     */
    projectId: string;
    /**
     * @generated from protobuf field: repeated FilePath file_paths = 4;
     */
    filePaths: FilePath[];
}
/**
 * @generated from protobuf message Link
 */
export interface Link {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message ScenarioUploadURL
 */
export interface ScenarioUploadURL {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message Model
 */
export interface Model {
    /**
     * @generated from protobuf field: string model_id = 1;
     */
    modelId: string;
    /**
     * @generated from protobuf field: string model = 2;
     */
    model: string;
    /**
     * @generated from protobuf field: repeated FileSignedUrl files = 3;
     */
    files: FileSignedUrl[];
    /**
     * @generated from protobuf field: string project_id = 4;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 5;
     */
    scenarioId: string;
}
/**
 * @generated from protobuf message ProbeInformation
 */
export interface ProbeInformation {
    /**
     * @generated from protobuf field: double epoch_time = 1;
     */
    epochTime: number;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 3;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string probe_doc_id = 4;
     */
    probeDocId: string;
    /**
     * @generated from protobuf field: string kubernetes_deployment_id = 5;
     */
    kubernetesDeploymentId: string;
    /**
     * @generated from protobuf field: string kubernetes_deployment_namespace = 6;
     */
    kubernetesDeploymentNamespace: string;
}
/**
 * @generated from protobuf message ProbeResponse
 */
export interface ProbeResponse {
    /**
     * @generated from protobuf field: string document_id = 1;
     */
    documentId: string;
}
/**
 * @generated from protobuf message Subscription
 */
export interface Subscription {
    /**
     * @generated from protobuf field: repeated string channel_patterns = 1;
     */
    channelPatterns: string[];
    /**
     * @generated from protobuf field: string scenario = 3;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project_id = 4;
     */
    projectId: string;
}
/**
 * @generated from protobuf message SubscriptionMessage
 */
export interface SubscriptionMessage {
    /**
     * @generated from protobuf field: string channel = 1;
     */
    channel: string;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
}
/**
 * @deprecated
 * @generated from protobuf message ExecutionMessage
 */
export interface ExecutionMessage {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 2;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string job_id = 3;
     */
    jobId: string;
    /**
     * @generated from protobuf field: string execution_id = 4;
     */
    executionId: string;
}
/**
 * @generated from protobuf message ExecutionState
 */
export interface ExecutionState {
    /**
     * @generated from protobuf field: bool active = 1;
     */
    active: boolean;
    /**
     * @generated from protobuf field: bool archived = 2;
     */
    archived: boolean;
    /**
     * @generated from protobuf field: bool hibernating = 3;
     */
    hibernating: boolean;
    /**
     * @generated from protobuf field: Scenario scenario = 4;
     */
    scenario?: Scenario;
}
/**
 * @generated from protobuf message ExecutionStateMessage
 */
export interface ExecutionStateMessage {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string execution_id = 2;
     */
    executionId: string;
}
/**
 * @generated from protobuf message CompleteExecutionMessage
 */
export interface CompleteExecutionMessage {
    /**
     * @deprecated
     * @generated from protobuf field: ExecutionMessage execution = 1 [deprecated = true];
     */
    execution?: ExecutionMessage;
    /**
     * @generated from protobuf field: bool hibernate = 2;
     */
    hibernate: boolean;
    /**
     * @generated from protobuf field: string project_id = 3;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 4;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string job_id = 5;
     */
    jobId: string;
    /**
     * @generated from protobuf field: string execution_id = 6;
     */
    executionId: string;
}
/**
 * @generated from protobuf message ExecutionId
 */
export interface ExecutionId {
    /**
     * @generated from protobuf field: string execution_id = 1;
     */
    executionId: string;
}
/**
 * @generated from protobuf message ExecutionReadLogs
 */
export interface ExecutionReadLogs {
    /**
     * @generated from protobuf field: string execution_id = 1;
     */
    executionId: string;
    /**
     * @generated from protobuf field: double start = 2;
     */
    start: number;
    /**
     * @generated from protobuf field: double end = 3;
     */
    end: number;
    /**
     * @generated from protobuf field: string project_id = 4;
     */
    projectId: string;
}
/**
 * @generated from protobuf message Empty
 */
export interface Empty {
}
/**
 * @generated from protobuf message Json
 */
export interface Json {
    /**
     * @generated from protobuf field: string json = 1;
     */
    json: string;
}
/**
 * @generated from protobuf message LogEntries
 */
export interface LogEntries {
    /**
     * @generated from protobuf field: string execution_id = 1;
     */
    executionId: string;
    /**
     * @generated from protobuf field: repeated string log_entries = 2;
     */
    logEntries: string[];
    /**
     * @generated from protobuf field: repeated double timestamps = 3;
     */
    timestamps: number[];
    /**
     * @generated from protobuf field: string scenario = 4;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string project_id = 5;
     */
    projectId: string;
}
/**
 * @generated from protobuf message ExeLogEntry
 */
export interface ExeLogEntry {
    /**
     * @generated from protobuf field: string execution_id = 1;
     */
    executionId: string;
    /**
     * @generated from protobuf field: string log_entry = 2;
     */
    logEntry: string;
    /**
     * @generated from protobuf field: double timestamp = 3;
     */
    timestamp: number;
}
/**
 * @generated from protobuf message DequeueRequest
 */
export interface DequeueRequest {
    /**
     * @generated from protobuf field: string queue = 1;
     */
    queue: string;
    /**
     * @generated from protobuf field: float timeout = 2;
     */
    timeout: number;
}
/**
 * @generated from protobuf message EnqueueRequest
 */
export interface EnqueueRequest {
    /**
     * @generated from protobuf field: string queue = 1;
     */
    queue: string;
    /**
     * @generated from protobuf field: string json = 2;
     */
    json: string;
}
/**
 * @generated from protobuf message SetOptimizationResultRequest
 */
export interface SetOptimizationResultRequest {
    /**
     * @generated from protobuf field: string scenario_id = 1;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string optimal_scenario_id = 3;
     */
    optimalScenarioId: string;
    /**
     * @generated from protobuf field: float score = 4;
     */
    score: number;
    /**
     * @generated from protobuf field: repeated ScenarioSetting settings = 5;
     */
    settings: ScenarioSetting[];
}
/**
 * @generated from protobuf message SetOptimizationIterationScoreRequest
 */
export interface SetOptimizationIterationScoreRequest {
    /**
     * @generated from protobuf field: string scenario_id = 1;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: float score = 3;
     */
    score: number;
}
/**
 * @generated from protobuf message StreamData
 */
export interface StreamData {
    /**
     * @generated from protobuf field: repeated double values = 1;
     */
    values: number[];
}
/**
 * @generated from protobuf message WriteDataStreamRequest
 */
export interface WriteDataStreamRequest {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 2;
     */
    execution: string;
    /**
     * @generated from protobuf field: bool overwrite = 3;
     */
    overwrite: boolean;
    /**
     * @generated from protobuf field: repeated double index = 4;
     */
    index: number[];
    /**
     * @generated from protobuf field: map<string, StreamData> data = 5;
     */
    data: {
        [key: string]: StreamData;
    };
    /**
     * @generated from protobuf field: bool update_stats = 6;
     */
    updateStats: boolean;
}
/**
 * @generated from protobuf message WriteDataStreamResponse
 */
export interface WriteDataStreamResponse {
    /**
     * @generated from protobuf field: double index = 1;
     */
    index: number;
}
/**
 * @generated from protobuf message ReadDataStreamRequest
 */
export interface ReadDataStreamRequest {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 2;
     */
    execution: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
    /**
     * @generated from protobuf field: double start = 4;
     */
    start: number;
    /**
     * @generated from protobuf field: double end = 5;
     */
    end: number;
    /**
     * @generated from protobuf field: uint64 length = 6;
     */
    length: bigint;
}
/**
 * @generated from protobuf message ReadDataStreamResponse
 */
export interface ReadDataStreamResponse {
    /**
     * @generated from protobuf field: repeated double index = 1;
     */
    index: number[];
    /**
     * @generated from protobuf field: map<string, StreamData> data = 2;
     */
    data: {
        [key: string]: StreamData;
    };
}
/**
 * @generated from protobuf message GetDataStreamStatsRequest
 */
export interface GetDataStreamStatsRequest {
    /**
     * @generated from protobuf field: string scenario = 1;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string execution = 2;
     */
    execution: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
}
/**
 * @generated from protobuf message DataStreamStats
 */
export interface DataStreamStats {
    /**
     * @generated from protobuf field: double min = 1;
     */
    min: number;
    /**
     * @generated from protobuf field: double max = 2;
     */
    max: number;
    /**
     * @generated from protobuf field: uint64 len = 3;
     */
    len: bigint;
}
/**
 * @generated from protobuf message GetDataStreamStatsResponse
 */
export interface GetDataStreamStatsResponse {
    /**
     * @generated from protobuf field: map<string, DataStreamStats> stats = 1;
     */
    stats: {
        [key: string]: DataStreamStats;
    };
}
/**
 * namespace and nodepool are "optional"
 *
 * @generated from protobuf message ImageInformation
 */
export interface ImageInformation {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 2;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string google_project = 3;
     */
    googleProject: string;
    /**
     * @generated from protobuf field: string image = 4;
     */
    image: string;
    /**
     * @generated from protobuf field: string deployment_name = 5;
     */
    deploymentName: string;
    /**
     * @generated from protobuf field: string namespace = 6;
     */
    namespace: string;
    /**
     * @generated from protobuf field: string nodepool = 7;
     */
    nodepool: string;
    /**
     * @generated from protobuf field: string token = 8;
     */
    token: string;
    /**
     * @generated from protobuf field: string spm_job_id = 9;
     */
    spmJobId: string;
    /**
     * Resource config
     *
     * @generated from protobuf field: string cpu_limit = 10;
     */
    cpuLimit: string;
    /**
     * @generated from protobuf field: string cpu_request = 11;
     */
    cpuRequest: string;
    /**
     * @generated from protobuf field: string memory_limit = 12;
     */
    memoryLimit: string;
    /**
     * @generated from protobuf field: string memory_request = 13;
     */
    memoryRequest: string;
}
/**
 * @generated from protobuf message Deployment
 */
export interface Deployment {
    /**
     * @generated from protobuf field: string kubernetes_id = 1;
     */
    kubernetesId: string;
    /**
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
}
/**
 * @generated from protobuf message Job
 */
export interface Job {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 2;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string job_id = 3;
     */
    jobId: string;
    /**
     * @generated from protobuf field: string user_id = 4;
     */
    userId: string;
}
/**
 * @generated from protobuf message Schedule
 */
export interface Schedule {
    /**
     * @generated from protobuf field: bool enable_scheduling = 3;
     */
    enableScheduling: boolean;
    /**
     * @generated from protobuf field: double sleep_after = 4;
     */
    sleepAfter: number;
    /**
     * @generated from protobuf field: double sleep_for = 5;
     */
    sleepFor: number;
}
/**
 * @generated from protobuf message ExecutionAndSchedule
 */
export interface ExecutionAndSchedule {
    /**
     * @generated from protobuf field: ExecutionId execution = 1;
     */
    execution?: ExecutionId;
    /**
     * @generated from protobuf field: Schedule schedule = 2;
     */
    schedule?: Schedule;
}
/**
 * @generated from protobuf message JobTime
 */
export interface JobTime {
    /**
     * @generated from protobuf field: Job job = 1;
     */
    job?: Job;
    /**
     * @generated from protobuf field: double start_time = 2;
     */
    startTime: number;
    /**
     * @generated from protobuf field: double end_time = 3;
     */
    endTime: number;
}
/**
 * @generated from protobuf message JobSchedule
 */
export interface JobSchedule {
    /**
     * @generated from protobuf field: Job job = 1;
     */
    job?: Job;
    /**
     * @generated from protobuf field: Schedule schedule = 2;
     */
    schedule?: Schedule;
}
/**
 * @generated from protobuf message JobTimeSchedule
 */
export interface JobTimeSchedule {
    /**
     * @generated from protobuf field: Job job = 1;
     */
    job?: Job;
    /**
     * @generated from protobuf field: Schedule schedule = 2;
     */
    schedule?: Schedule;
    /**
     * @generated from protobuf field: double start_time = 3;
     */
    startTime: number;
    /**
     * @generated from protobuf field: double end_time = 4;
     */
    endTime: number;
}
/**
 * @generated from protobuf message StartLocalJobRequest
 */
export interface StartLocalJobRequest {
    /**
     * @generated from protobuf field: string job_id = 1;
     */
    jobId: string;
    /**
     * @generated from protobuf field: string scenario_id = 2;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string project_id = 3;
     */
    projectId: string;
}
/**
 * @generated from protobuf message StartLocalJobResponse
 */
export interface StartLocalJobResponse {
    /**
     * @generated from protobuf field: string execution_id = 1;
     */
    executionId: string;
}
/**
 * Token manager definitions
 *
 * @generated from protobuf message TokenRequest
 */
export interface TokenRequest {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 2;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: bool admin = 3;
     */
    admin: boolean;
    /**
     * @generated from protobuf field: string execution_id = 4;
     */
    executionId: string;
    /**
     * @generated from protobuf field: string job_id = 5;
     */
    jobId: string;
    /**
     * @generated from protobuf field: string user_id = 6;
     */
    userId: string;
    /**
     * @generated from protobuf field: string agent = 8;
     */
    agent: string;
    /**
     * @generated from protobuf field: string purpose = 9;
     */
    purpose: string;
    /**
     * @generated from protobuf field: int64 access_level = 10;
     */
    accessLevel: bigint;
}
/**
 * @generated from protobuf message Token
 */
export interface Token {
    /**
     * @generated from protobuf field: string val = 1;
     */
    val: string;
}
/**
 * @generated from protobuf message RefreshRequest
 */
export interface RefreshRequest {
    /**
     * @generated from protobuf field: Token refresh_token = 1;
     */
    refreshToken?: Token;
    /**
     * @generated from protobuf field: string instance_id = 2;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: string execution_id = 3;
     */
    executionId: string;
    /**
     * @generated from protobuf field: string project_id = 4;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 5;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string job_id = 6;
     */
    jobId: string;
}
/**
 * @generated from protobuf message AuthenticateUserRequest
 */
export interface AuthenticateUserRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
}
/**
 * @generated from protobuf message AuthenticateUserResponse
 */
export interface AuthenticateUserResponse {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: string id_token = 2;
     */
    idToken: string;
    /**
     * @generated from protobuf field: string refresh_token = 3;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message RefreshUserRequest
 */
export interface RefreshUserRequest {
    /**
     * @generated from protobuf field: string refresh_token = 3;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message RefreshUserResponse
 */
export interface RefreshUserResponse {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: string id_token = 2;
     */
    idToken: string;
    /**
     * @generated from protobuf field: string refresh_token = 3;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message Snapshot
 */
export interface Snapshot {
    /**
     * @generated from protobuf field: string snapshot_id = 1;
     */
    snapshotId: string;
    /**
     * @generated from protobuf field: string repository = 2;
     */
    repository: string;
    /**
     * @generated from protobuf field: string file_structure = 3;
     */
    fileStructure: string;
    /**
     * @generated from protobuf field: bool only_get_uploads = 4;
     */
    onlyGetUploads: boolean;
}
/**
 * @generated from protobuf message HistoryUpdate
 */
export interface HistoryUpdate {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string snapshot_id = 2;
     */
    snapshotId: string;
    /**
     * @generated from protobuf field: string repository = 3;
     */
    repository: string;
    /**
     * @generated from protobuf field: string project_id = 4;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 5;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string parent_commit_id = 6;
     */
    parentCommitId: string;
    /**
     * @generated from protobuf field: string comment = 7;
     */
    comment: string;
    /**
     * @generated from protobuf field: double timestamp = 8;
     */
    timestamp: number;
    /**
     * @generated from protobuf field: HistoryUser user = 9;
     */
    user?: HistoryUser;
}
/**
 * @generated from protobuf message HistoryUser
 */
export interface HistoryUser {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string full_name = 3;
     */
    fullName: string;
}
/**
 * @generated from protobuf message History
 */
export interface History {
    /**
     * @generated from protobuf field: repeated HistoryUpdate updates = 1;
     */
    updates: HistoryUpdate[];
}
/**
 * @generated from protobuf message HistoryRequest
 */
export interface HistoryRequest {
    /**
     * @generated from protobuf field: string repository = 1;
     */
    repository: string;
    /**
     * @generated from protobuf field: string scenario_id = 2;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: repeated string history_update_ids = 3;
     */
    historyUpdateIds: string[];
}
/**
 * @generated from protobuf message HistoryReply
 */
export interface HistoryReply {
    /**
     * @generated from protobuf field: string commit_id = 1;
     */
    commitId: string;
}
/**
 * @generated from protobuf message SnapshotReply
 */
export interface SnapshotReply {
    /**
     * @generated from protobuf field: string snapshot_id = 2;
     */
    snapshotId: string;
    /**
     *    repeated string upload_urls = 1;
     *    repeated string filenames = 3;
     *
     * @generated from protobuf field: string upload_url = 4;
     */
    uploadUrl: string;
}
/**
 * @generated from protobuf message PullRequest
 */
export interface PullRequest {
    /**
     * @generated from protobuf field: string repository = 2;
     */
    repository: string;
    /**
     * @generated from protobuf field: string snapshot_id = 1;
     */
    snapshotId: string;
    /**
     * @generated from protobuf field: string project_id = 3;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 4;
     */
    scenarioId: string;
}
/**
 * @generated from protobuf message PullResponse
 */
export interface PullResponse {
    /**
     * @generated from protobuf field: string snapshot_id = 1;
     */
    snapshotId: string;
    /**
     *    repeated FileSignedUrl files = 2;
     *    repeated string subdirs = 3;
     *
     * @generated from protobuf field: FileSignedUrl archive_url = 4;
     */
    archiveUrl?: FileSignedUrl;
}
/**
 * @generated from protobuf message BuildRequest
 */
export interface BuildRequest {
    /**
     * @generated from protobuf field: string repository = 1;
     */
    repository: string;
    /**
     * @generated from protobuf field: string commit_id = 2;
     */
    commitId: string;
    /**
     * @generated from protobuf field: bool launch_after_build = 3;
     */
    launchAfterBuild: boolean;
    /**
     * @generated from protobuf field: string project_id = 4;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string scenario_id = 5;
     */
    scenarioId: string;
    /**
     * @generated from protobuf field: string job_id = 6;
     */
    jobId: string;
    /**
     * @generated from protobuf field: repeated string tags = 7;
     */
    tags: string[];
    /**
     * @generated from protobuf field: float timeout = 8;
     */
    timeout: number;
}
/**
 * @generated from protobuf message BuildReply
 */
export interface BuildReply {
    /**
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
}
/**
 * @generated from protobuf message BuildUpdate
 */
export interface BuildUpdate {
    /**
     * @generated from protobuf field: string repository = 1;
     */
    repository: string;
    /**
     * @generated from protobuf field: string build_id = 2;
     */
    buildId: string;
    /**
     * @generated from protobuf field: string update = 3;
     */
    update: string;
}
/**
 * @generated from protobuf message BuildInfo
 */
export interface BuildInfo {
    /**
     * @generated from protobuf field: string info = 1;
     */
    info: string;
}
/**
 * @generated from protobuf message BuildInfoRequest
 */
export interface BuildInfoRequest {
    /**
     * @generated from protobuf field: string repository = 1;
     */
    repository: string;
    /**
     * @generated from protobuf field: string build_id = 2;
     */
    buildId: string;
}
/**
 * @generated from protobuf message JobImageReferenceUpdate
 */
export interface JobImageReferenceUpdate {
    /**
     * @generated from protobuf field: string project = 1;
     */
    project: string;
    /**
     * @generated from protobuf field: string scenario = 2;
     */
    scenario: string;
    /**
     * @generated from protobuf field: string job = 3;
     */
    job: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string path = 5;
     */
    path: string;
    /**
     * @generated from protobuf field: string repository = 6;
     */
    repository: string;
    /**
     * @generated from protobuf field: string commit_id = 7;
     */
    commitId: string;
    /**
     * @generated from protobuf field: bool internal = 8;
     */
    internal: boolean;
    /**
     * @generated from protobuf field: string build = 9;
     */
    build: string;
}
/**
 * @generated from protobuf message BuildDequeueRequest
 */
export interface BuildDequeueRequest {
    /**
     * @generated from protobuf field: float timeout = 1;
     */
    timeout: number;
}
/**
 * @generated from protobuf message BuildResponse
 */
export interface BuildResponse {
    /**
     * @generated from protobuf field: string status = 1;
     */
    status: string;
}
/**
 * @generated from protobuf message FileUrl
 */
export interface FileUrl {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message FileUrls
 */
export interface FileUrls {
    /**
     * @generated from protobuf field: repeated FileUrl urls = 1;
     */
    urls: FileUrl[];
}
// @generated message type with reflection information, may provide speed optimized methods
class NoopContent$Type extends MessageType<NoopContent> {
    constructor() {
        super("NoopContent", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NoopContent>): NoopContent {
        const message = { scenario: "", content: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NoopContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NoopContent): NoopContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NoopContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NoopContent
 */
export const NoopContent = new NoopContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Scenario$Type extends MessageType<Scenario> {
    constructor() {
        super("Scenario", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Scenario>): Scenario {
        const message = { scenario: "", project: "", execution: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Scenario>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Scenario): Scenario {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string project */ 2:
                    message.project = reader.string();
                    break;
                case /* string execution */ 3:
                    message.execution = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Scenario, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string project = 2; */
        if (message.project !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.project);
        /* string execution = 3; */
        if (message.execution !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.execution);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Scenario
 */
export const Scenario = new Scenario$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioStatsRequest$Type extends MessageType<ScenarioStatsRequest> {
    constructor() {
        super("ScenarioStatsRequest", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioStatsRequest>): ScenarioStatsRequest {
        const message = { scenario: "", project: "", execution: "", tag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioStatsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioStatsRequest): ScenarioStatsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string project */ 2:
                    message.project = reader.string();
                    break;
                case /* string execution */ 3:
                    message.execution = reader.string();
                    break;
                case /* string tag */ 4:
                    message.tag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioStatsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string project = 2; */
        if (message.project !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.project);
        /* string execution = 3; */
        if (message.execution !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.execution);
        /* string tag = 4; */
        if (message.tag !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioStatsRequest
 */
export const ScenarioStatsRequest = new ScenarioStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateScenarioRequest$Type extends MessageType<DuplicateScenarioRequest> {
    constructor() {
        super("DuplicateScenarioRequest", [
            { no: 1, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "create_child", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "override_settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ScenarioSetting },
            { no: 5, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DuplicateScenarioRequest>): DuplicateScenarioRequest {
        const message = { scenarioId: "", projectId: "", createChild: false, overrideSettings: [], executionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DuplicateScenarioRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateScenarioRequest): DuplicateScenarioRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario_id */ 1:
                    message.scenarioId = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* bool create_child */ 3:
                    message.createChild = reader.bool();
                    break;
                case /* repeated ScenarioSetting override_settings */ 4:
                    message.overrideSettings.push(ScenarioSetting.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string execution_id */ 5:
                    message.executionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateScenarioRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario_id = 1; */
        if (message.scenarioId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenarioId);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* bool create_child = 3; */
        if (message.createChild !== false)
            writer.tag(3, WireType.Varint).bool(message.createChild);
        /* repeated ScenarioSetting override_settings = 4; */
        for (let i = 0; i < message.overrideSettings.length; i++)
            ScenarioSetting.internalBinaryWrite(message.overrideSettings[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string execution_id = 5; */
        if (message.executionId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.executionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DuplicateScenarioRequest
 */
export const DuplicateScenarioRequest = new DuplicateScenarioRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioSetting$Type extends MessageType<ScenarioSetting> {
    constructor() {
        super("ScenarioSetting", [
            { no: 1, name: "path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioSetting>): ScenarioSetting {
        const message = { path: [], type: "", value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioSetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioSetting): ScenarioSetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string path */ 1:
                    message.path.push(reader.string());
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* float value */ 3:
                    message.value = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioSetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string path = 1; */
        for (let i = 0; i < message.path.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.path[i]);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* float value = 3; */
        if (message.value !== 0)
            writer.tag(3, WireType.Bit32).float(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioSetting
 */
export const ScenarioSetting = new ScenarioSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SystemRequest$Type extends MessageType<SystemRequest> {
    constructor() {
        super("SystemRequest", [
            { no: 1, name: "system", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SystemRequest>): SystemRequest {
        const message = { system: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SystemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SystemRequest): SystemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string system */ 1:
                    message.system = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SystemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string system = 1; */
        if (message.system !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.system);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SystemRequest
 */
export const SystemRequest = new SystemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRequest$Type extends MessageType<UserRequest> {
    constructor() {
        super("UserRequest", [
            { no: 1, name: "user", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserRequest>): UserRequest {
        const message = { user: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRequest): UserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user */ 1:
                    message.user = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user = 1; */
        if (message.user !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.user);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserRequest
 */
export const UserRequest = new UserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "full_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "mail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "organisations", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "favorite_projects", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { id: "", firstName: "", lastName: "", fullName: "", mail: "", organisations: [], favoriteProjects: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string full_name */ 4:
                    message.fullName = reader.string();
                    break;
                case /* string mail */ 5:
                    message.mail = reader.string();
                    break;
                case /* repeated string organisations */ 8:
                    message.organisations.push(reader.string());
                    break;
                case /* repeated string favorite_projects */ 9:
                    message.favoriteProjects.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string full_name = 4; */
        if (message.fullName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fullName);
        /* string mail = 5; */
        if (message.mail !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.mail);
        /* repeated string organisations = 8; */
        for (let i = 0; i < message.organisations.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.organisations[i]);
        /* repeated string favorite_projects = 9; */
        for (let i = 0; i < message.favoriteProjects.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.favoriteProjects[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Group$Type extends MessageType<Group> {
    constructor() {
        super("Group", [
            { no: 1, name: "group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Group>): Group {
        const message = { group: "", project: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Group>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Group): Group {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string group */ 1:
                    message.group = reader.string();
                    break;
                case /* string project */ 2:
                    message.project = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Group, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string group = 1; */
        if (message.group !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.group);
        /* string project = 2; */
        if (message.project !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.project);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Group
 */
export const Group = new Group$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Project$Type extends MessageType<Project> {
    constructor() {
        super("Project", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Project>): Project {
        const message = { project: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Project>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Project): Project {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Project, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Project
 */
export const Project = new Project$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadScenario$Type extends MessageType<ReadScenario> {
    constructor() {
        super("ReadScenario", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "start", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "end", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "time_range", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "listen", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReadScenario>): ReadScenario {
        const message = { scenario: "", tags: [], start: 0, end: 0, timeRange: false, listen: false, execution: "", project: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReadScenario>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadScenario): ReadScenario {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* repeated string tags */ 2:
                    message.tags.push(reader.string());
                    break;
                case /* double start */ 3:
                    message.start = reader.double();
                    break;
                case /* double end */ 4:
                    message.end = reader.double();
                    break;
                case /* bool time_range */ 5:
                    message.timeRange = reader.bool();
                    break;
                case /* bool listen */ 6:
                    message.listen = reader.bool();
                    break;
                case /* string execution */ 7:
                    message.execution = reader.string();
                    break;
                case /* string project */ 8:
                    message.project = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadScenario, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* repeated string tags = 2; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.tags[i]);
        /* double start = 3; */
        if (message.start !== 0)
            writer.tag(3, WireType.Bit64).double(message.start);
        /* double end = 4; */
        if (message.end !== 0)
            writer.tag(4, WireType.Bit64).double(message.end);
        /* bool time_range = 5; */
        if (message.timeRange !== false)
            writer.tag(5, WireType.Varint).bool(message.timeRange);
        /* bool listen = 6; */
        if (message.listen !== false)
            writer.tag(6, WireType.Varint).bool(message.listen);
        /* string execution = 7; */
        if (message.execution !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.execution);
        /* string project = 8; */
        if (message.project !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.project);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadScenario
 */
export const ReadScenario = new ReadScenario$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioStats$Type extends MessageType<ScenarioStats> {
    constructor() {
        super("ScenarioStats", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "min", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "max", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "equi_space", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "spacing", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "n_blocks", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "equi_block_len", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "block_len0", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "block_len_last", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "total_val_len", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioStats>): ScenarioStats {
        const message = { scenario: "", execution: "", project: "", min: 0, max: 0, equiSpace: false, spacing: 0, nBlocks: 0n, equiBlockLen: false, blockLen0: 0n, blockLenLast: 0n, totalValLen: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioStats): ScenarioStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 11:
                    message.execution = reader.string();
                    break;
                case /* string project */ 12:
                    message.project = reader.string();
                    break;
                case /* double min */ 2:
                    message.min = reader.double();
                    break;
                case /* double max */ 3:
                    message.max = reader.double();
                    break;
                case /* bool equi_space */ 4:
                    message.equiSpace = reader.bool();
                    break;
                case /* double spacing */ 5:
                    message.spacing = reader.double();
                    break;
                case /* int64 n_blocks */ 6:
                    message.nBlocks = reader.int64().toBigInt();
                    break;
                case /* bool equi_block_len */ 7:
                    message.equiBlockLen = reader.bool();
                    break;
                case /* int64 block_len0 */ 8:
                    message.blockLen0 = reader.int64().toBigInt();
                    break;
                case /* int64 block_len_last */ 9:
                    message.blockLenLast = reader.int64().toBigInt();
                    break;
                case /* int64 total_val_len */ 10:
                    message.totalValLen = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 11; */
        if (message.execution !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.execution);
        /* string project = 12; */
        if (message.project !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.project);
        /* double min = 2; */
        if (message.min !== 0)
            writer.tag(2, WireType.Bit64).double(message.min);
        /* double max = 3; */
        if (message.max !== 0)
            writer.tag(3, WireType.Bit64).double(message.max);
        /* bool equi_space = 4; */
        if (message.equiSpace !== false)
            writer.tag(4, WireType.Varint).bool(message.equiSpace);
        /* double spacing = 5; */
        if (message.spacing !== 0)
            writer.tag(5, WireType.Bit64).double(message.spacing);
        /* int64 n_blocks = 6; */
        if (message.nBlocks !== 0n)
            writer.tag(6, WireType.Varint).int64(message.nBlocks);
        /* bool equi_block_len = 7; */
        if (message.equiBlockLen !== false)
            writer.tag(7, WireType.Varint).bool(message.equiBlockLen);
        /* int64 block_len0 = 8; */
        if (message.blockLen0 !== 0n)
            writer.tag(8, WireType.Varint).int64(message.blockLen0);
        /* int64 block_len_last = 9; */
        if (message.blockLenLast !== 0n)
            writer.tag(9, WireType.Varint).int64(message.blockLenLast);
        /* int64 total_val_len = 10; */
        if (message.totalValLen !== 0n)
            writer.tag(10, WireType.Varint).int64(message.totalValLen);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioStats
 */
export const ScenarioStats = new ScenarioStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataBlock$Type extends MessageType<DataBlock> {
    constructor() {
        super("DataBlock", [
            { no: 1, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DataBlock>): DataBlock {
        const message = { tag: "", values: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataBlock>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataBlock): DataBlock {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tag */ 1:
                    message.tag = reader.string();
                    break;
                case /* repeated double values */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.values.push(reader.double());
                    else
                        message.values.push(reader.double());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataBlock, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tag = 1; */
        if (message.tag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tag);
        /* repeated double values = 2; */
        if (message.values.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.values.length; i++)
                writer.double(message.values[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DataBlock
 */
export const DataBlock = new DataBlock$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataList$Type extends MessageType<DataList> {
    constructor() {
        super("DataList", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataBlock },
            { no: 3, name: "clear", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "row_complete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "block_complete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "ignore_index", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "reset_block_counter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "stream_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DataList>): DataList {
        const message = { scenario: "", data: [], clear: false, rowComplete: false, blockComplete: false, ignoreIndex: false, execution: "", project: "", resetBlockCounter: false, streamStatus: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataList): DataList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* repeated DataBlock data */ 2:
                    message.data.push(DataBlock.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool clear */ 3:
                    message.clear = reader.bool();
                    break;
                case /* bool row_complete */ 4:
                    message.rowComplete = reader.bool();
                    break;
                case /* bool block_complete */ 5:
                    message.blockComplete = reader.bool();
                    break;
                case /* bool ignore_index */ 6:
                    message.ignoreIndex = reader.bool();
                    break;
                case /* string execution */ 7:
                    message.execution = reader.string();
                    break;
                case /* string project */ 8:
                    message.project = reader.string();
                    break;
                case /* bool reset_block_counter */ 9:
                    message.resetBlockCounter = reader.bool();
                    break;
                case /* string stream_status */ 10:
                    message.streamStatus = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* repeated DataBlock data = 2; */
        for (let i = 0; i < message.data.length; i++)
            DataBlock.internalBinaryWrite(message.data[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool clear = 3; */
        if (message.clear !== false)
            writer.tag(3, WireType.Varint).bool(message.clear);
        /* bool row_complete = 4; */
        if (message.rowComplete !== false)
            writer.tag(4, WireType.Varint).bool(message.rowComplete);
        /* bool block_complete = 5; */
        if (message.blockComplete !== false)
            writer.tag(5, WireType.Varint).bool(message.blockComplete);
        /* bool ignore_index = 6; */
        if (message.ignoreIndex !== false)
            writer.tag(6, WireType.Varint).bool(message.ignoreIndex);
        /* string execution = 7; */
        if (message.execution !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.execution);
        /* string project = 8; */
        if (message.project !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.project);
        /* bool reset_block_counter = 9; */
        if (message.resetBlockCounter !== false)
            writer.tag(9, WireType.Varint).bool(message.resetBlockCounter);
        /* string stream_status = 10; */
        if (message.streamStatus !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.streamStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DataList
 */
export const DataList = new DataList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndexedDataList$Type extends MessageType<IndexedDataList> {
    constructor() {
        super("IndexedDataList", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "data_list", kind: "message", T: () => DataList },
            { no: 3, name: "writer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IndexedDataList>): IndexedDataList {
        const message = { id: 0n, writerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IndexedDataList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndexedDataList): IndexedDataList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* DataList data_list */ 2:
                    message.dataList = DataList.internalBinaryRead(reader, reader.uint32(), options, message.dataList);
                    break;
                case /* string writer_id */ 3:
                    message.writerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndexedDataList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* DataList data_list = 2; */
        if (message.dataList)
            DataList.internalBinaryWrite(message.dataList, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string writer_id = 3; */
        if (message.writerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.writerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IndexedDataList
 */
export const IndexedDataList = new IndexedDataList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataCompleted$Type extends MessageType<DataCompleted> {
    constructor() {
        super("DataCompleted", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "eta", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "finalized", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DataCompleted>): DataCompleted {
        const message = { scenario: "", execution: "", project: "", eta: 0, finalized: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataCompleted>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataCompleted): DataCompleted {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 4:
                    message.execution = reader.string();
                    break;
                case /* string project */ 5:
                    message.project = reader.string();
                    break;
                case /* double eta */ 2:
                    message.eta = reader.double();
                    break;
                case /* bool finalized */ 3:
                    message.finalized = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataCompleted, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 4; */
        if (message.execution !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.execution);
        /* string project = 5; */
        if (message.project !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.project);
        /* double eta = 2; */
        if (message.eta !== 0)
            writer.tag(2, WireType.Bit64).double(message.eta);
        /* bool finalized = 3; */
        if (message.finalized !== false)
            writer.tag(3, WireType.Varint).bool(message.finalized);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DataCompleted
 */
export const DataCompleted = new DataCompleted$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tag$Type extends MessageType<Tag> {
    constructor() {
        super("Tag", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "scaling", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "offset", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<Tag>): Tag {
        const message = { name: "", displayName: "", unit: "", description: "", type: "", scaling: 0, offset: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Tag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tag): Tag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string displayName */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string unit */ 3:
                    message.unit = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string type */ 7:
                    message.type = reader.string();
                    break;
                case /* double scaling */ 5:
                    message.scaling = reader.double();
                    break;
                case /* double offset */ 6:
                    message.offset = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string displayName = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string unit = 3; */
        if (message.unit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.unit);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string type = 7; */
        if (message.type !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.type);
        /* double scaling = 5; */
        if (message.scaling !== 0)
            writer.tag(5, WireType.Bit64).double(message.scaling);
        /* double offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Bit64).double(message.offset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Tag
 */
export const Tag = new Tag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Alias$Type extends MessageType<Alias> {
    constructor() {
        super("Alias", [
            { no: 1, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "alias", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Alias>): Alias {
        const message = { tag: "", alias: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Alias>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Alias): Alias {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tag */ 1:
                    message.tag = reader.string();
                    break;
                case /* repeated string alias */ 2:
                    message.alias.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Alias, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tag = 1; */
        if (message.tag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tag);
        /* repeated string alias = 2; */
        for (let i = 0; i < message.alias.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.alias[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Alias
 */
export const Alias = new Alias$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Closed$Type extends MessageType<Closed> {
    constructor() {
        super("Closed", [
            { no: 1, name: "is_closed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Closed>): Closed {
        const message = { isClosed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Closed>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Closed): Closed {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_closed */ 1:
                    message.isClosed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Closed, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_closed = 1; */
        if (message.isClosed !== false)
            writer.tag(1, WireType.Varint).bool(message.isClosed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Closed
 */
export const Closed = new Closed$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioMetaData$Type extends MessageType<ScenarioMetaData> {
    constructor() {
        super("ScenarioMetaData", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag },
            { no: 3, name: "aliases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Alias },
            { no: 4, name: "offset", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "epoch_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioMetaData>): ScenarioMetaData {
        const message = { scenario: "", tags: [], aliases: [], offset: 0, timezone: "", epochType: "", execution: "", project: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioMetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioMetaData): ScenarioMetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* repeated Tag tags */ 2:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Alias aliases */ 3:
                    message.aliases.push(Alias.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* double offset */ 4:
                    message.offset = reader.double();
                    break;
                case /* string timezone */ 5:
                    message.timezone = reader.string();
                    break;
                case /* string epoch_type */ 6:
                    message.epochType = reader.string();
                    break;
                case /* string execution */ 7:
                    message.execution = reader.string();
                    break;
                case /* string project */ 8:
                    message.project = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioMetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* repeated Tag tags = 2; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated Alias aliases = 3; */
        for (let i = 0; i < message.aliases.length; i++)
            Alias.internalBinaryWrite(message.aliases[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* double offset = 4; */
        if (message.offset !== 0)
            writer.tag(4, WireType.Bit64).double(message.offset);
        /* string timezone = 5; */
        if (message.timezone !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.timezone);
        /* string epoch_type = 6; */
        if (message.epochType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.epochType);
        /* string execution = 7; */
        if (message.execution !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.execution);
        /* string project = 8; */
        if (message.project !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.project);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioMetaData
 */
export const ScenarioMetaData = new ScenarioMetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioCustomMetaData$Type extends MessageType<ScenarioCustomMetaData> {
    constructor() {
        super("ScenarioCustomMetaData", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "meta", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioCustomMetaData>): ScenarioCustomMetaData {
        const message = { scenario: "", project: "", execution: "", key: "", meta: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioCustomMetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioCustomMetaData): ScenarioCustomMetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string project */ 4:
                    message.project = reader.string();
                    break;
                case /* string execution */ 5:
                    message.execution = reader.string();
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                case /* string meta */ 3:
                    message.meta = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioCustomMetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string project = 4; */
        if (message.project !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.project);
        /* string execution = 5; */
        if (message.execution !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.execution);
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        /* string meta = 3; */
        if (message.meta !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.meta);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioCustomMetaData
 */
export const ScenarioCustomMetaData = new ScenarioCustomMetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioDocument$Type extends MessageType<ScenarioDocument> {
    constructor() {
        super("ScenarioDocument", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_document", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "files", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FileSignedUrl }
        ]);
    }
    create(value?: PartialMessage<ScenarioDocument>): ScenarioDocument {
        const message = { scenario: "", project: "", scenarioDocument: "", files: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioDocument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioDocument): ScenarioDocument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string project */ 4:
                    message.project = reader.string();
                    break;
                case /* string scenario_document */ 2:
                    message.scenarioDocument = reader.string();
                    break;
                case /* repeated FileSignedUrl files */ 3:
                    message.files.push(FileSignedUrl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioDocument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string project = 4; */
        if (message.project !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.project);
        /* string scenario_document = 2; */
        if (message.scenarioDocument !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioDocument);
        /* repeated FileSignedUrl files = 3; */
        for (let i = 0; i < message.files.length; i++)
            FileSignedUrl.internalBinaryWrite(message.files[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioDocument
 */
export const ScenarioDocument = new ScenarioDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupDocument$Type extends MessageType<GroupDocument> {
    constructor() {
        super("GroupDocument", [
            { no: 1, name: "group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "group_document", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GroupDocument>): GroupDocument {
        const message = { group: "", groupDocument: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GroupDocument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GroupDocument): GroupDocument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string group */ 1:
                    message.group = reader.string();
                    break;
                case /* string group_document */ 2:
                    message.groupDocument = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GroupDocument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string group = 1; */
        if (message.group !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.group);
        /* string group_document = 2; */
        if (message.groupDocument !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.groupDocument);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GroupDocument
 */
export const GroupDocument = new GroupDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectDocument$Type extends MessageType<ProjectDocument> {
    constructor() {
        super("ProjectDocument", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_document", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProjectDocument>): ProjectDocument {
        const message = { project: "", projectDocument: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProjectDocument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProjectDocument): ProjectDocument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string project_document */ 2:
                    message.projectDocument = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProjectDocument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string project_document = 2; */
        if (message.projectDocument !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectDocument);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectDocument
 */
export const ProjectDocument = new ProjectDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioProgress$Type extends MessageType<ScenarioProgress> {
    constructor() {
        super("ScenarioProgress", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "clean", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "progress", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioProgress>): ScenarioProgress {
        const message = { project: "", scenario: "", message: "", status: "", clean: false, progress: 0, jobId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioProgress>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioProgress): ScenarioProgress {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* bool clean */ 5:
                    message.clean = reader.bool();
                    break;
                case /* double progress */ 6:
                    message.progress = reader.double();
                    break;
                case /* string job_id */ 7:
                    message.jobId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioProgress, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* bool clean = 5; */
        if (message.clean !== false)
            writer.tag(5, WireType.Varint).bool(message.clean);
        /* double progress = 6; */
        if (message.progress !== 0)
            writer.tag(6, WireType.Bit64).double(message.progress);
        /* string job_id = 7; */
        if (message.jobId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.jobId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioProgress
 */
export const ScenarioProgress = new ScenarioProgress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioDataTags$Type extends MessageType<ScenarioDataTags> {
    constructor() {
        super("ScenarioDataTags", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioDataTags>): ScenarioDataTags {
        const message = { scenario: "", execution: "", tags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioDataTags>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioDataTags): ScenarioDataTags {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 2:
                    message.execution = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioDataTags, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 2; */
        if (message.execution !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.execution);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioDataTags
 */
export const ScenarioDataTags = new ScenarioDataTags$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioResults$Type extends MessageType<ScenarioResults> {
    constructor() {
        super("ScenarioResults", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "units", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioResults>): ScenarioResults {
        const message = { project: "", scenario: "", names: [], values: [], units: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioResults>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioResults): ScenarioResults {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* repeated string names */ 3:
                    message.names.push(reader.string());
                    break;
                case /* repeated double values */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.values.push(reader.double());
                    else
                        message.values.push(reader.double());
                    break;
                case /* repeated string units */ 5:
                    message.units.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioResults, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* repeated string names = 3; */
        for (let i = 0; i < message.names.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.names[i]);
        /* repeated double values = 4; */
        if (message.values.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.values.length; i++)
                writer.double(message.values[i]);
            writer.join();
        }
        /* repeated string units = 5; */
        for (let i = 0; i < message.units.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.units[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioResults
 */
export const ScenarioResults = new ScenarioResults$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioResultsDocument$Type extends MessageType<ScenarioResultsDocument> {
    constructor() {
        super("ScenarioResultsDocument", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "result", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioResultsDocument>): ScenarioResultsDocument {
        const message = { project: "", scenario: "", execution: "", result: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioResultsDocument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioResultsDocument): ScenarioResultsDocument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 3:
                    message.execution = reader.string();
                    break;
                case /* string result */ 4:
                    message.result = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioResultsDocument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 3; */
        if (message.execution !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.execution);
        /* string result = 4; */
        if (message.result !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioResultsDocument
 */
export const ScenarioResultsDocument = new ScenarioResultsDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioError$Type extends MessageType<ScenarioError> {
    constructor() {
        super("ScenarioError", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "spm_job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioError>): ScenarioError {
        const message = { project: "", scenario: "", error: "", spmJobId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioError): ScenarioError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string error */ 3:
                    message.error = reader.string();
                    break;
                case /* string spm_job_id */ 4:
                    message.spmJobId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string error = 3; */
        if (message.error !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.error);
        /* string spm_job_id = 4; */
        if (message.spmJobId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.spmJobId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioError
 */
export const ScenarioError = new ScenarioError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioLogEntry$Type extends MessageType<ScenarioLogEntry> {
    constructor() {
        super("ScenarioLogEntry", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "spm_job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "initialize", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioLogEntry>): ScenarioLogEntry {
        const message = { project: "", scenario: "", message: "", spmJobId: "", initialize: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioLogEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioLogEntry): ScenarioLogEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* string spm_job_id */ 4:
                    message.spmJobId = reader.string();
                    break;
                case /* bool initialize */ 5:
                    message.initialize = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioLogEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* string spm_job_id = 4; */
        if (message.spmJobId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.spmJobId);
        /* bool initialize = 5; */
        if (message.initialize !== false)
            writer.tag(5, WireType.Varint).bool(message.initialize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioLogEntry
 */
export const ScenarioLogEntry = new ScenarioLogEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioFormattedError$Type extends MessageType<ScenarioFormattedError> {
    constructor() {
        super("ScenarioFormattedError", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "initialize", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "hint", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "exception_object_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "exception_object_message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "full_traceback", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioFormattedError>): ScenarioFormattedError {
        const message = { project: "", scenario: "", message: "", initialize: false, hint: "", category: "", exceptionObjectType: "", exceptionObjectMessage: "", fullTraceback: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioFormattedError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioFormattedError): ScenarioFormattedError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* bool initialize */ 4:
                    message.initialize = reader.bool();
                    break;
                case /* string hint */ 5:
                    message.hint = reader.string();
                    break;
                case /* string category */ 6:
                    message.category = reader.string();
                    break;
                case /* string exception_object_type */ 7:
                    message.exceptionObjectType = reader.string();
                    break;
                case /* string exception_object_message */ 8:
                    message.exceptionObjectMessage = reader.string();
                    break;
                case /* string full_traceback */ 9:
                    message.fullTraceback = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioFormattedError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* bool initialize = 4; */
        if (message.initialize !== false)
            writer.tag(4, WireType.Varint).bool(message.initialize);
        /* string hint = 5; */
        if (message.hint !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.hint);
        /* string category = 6; */
        if (message.category !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.category);
        /* string exception_object_type = 7; */
        if (message.exceptionObjectType !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.exceptionObjectType);
        /* string exception_object_message = 8; */
        if (message.exceptionObjectMessage !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.exceptionObjectMessage);
        /* string full_traceback = 9; */
        if (message.fullTraceback !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.fullTraceback);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioFormattedError
 */
export const ScenarioFormattedError = new ScenarioFormattedError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileSignedUrl$Type extends MessageType<FileSignedUrl> {
    constructor() {
        super("FileSignedUrl", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FileSignedUrl>): FileSignedUrl {
        const message = { name: "", path: "", id: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FileSignedUrl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileSignedUrl): FileSignedUrl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* string id */ 3:
                    message.id = reader.string();
                    break;
                case /* string url */ 4:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileSignedUrl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* string id = 3; */
        if (message.id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.id);
        /* string url = 4; */
        if (message.url !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileSignedUrl
 */
export const FileSignedUrl = new FileSignedUrl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileSignedUrls$Type extends MessageType<FileSignedUrls> {
    constructor() {
        super("FileSignedUrls", [
            { no: 1, name: "files", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FileSignedUrl },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FileSignedUrls>): FileSignedUrls {
        const message = { files: [], scenario: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FileSignedUrls>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileSignedUrls): FileSignedUrls {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated FileSignedUrl files */ 1:
                    message.files.push(FileSignedUrl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileSignedUrls, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated FileSignedUrl files = 1; */
        for (let i = 0; i < message.files.length; i++)
            FileSignedUrl.internalBinaryWrite(message.files[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileSignedUrls
 */
export const FileSignedUrls = new FileSignedUrls$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignedUrl$Type extends MessageType<SignedUrl> {
    constructor() {
        super("SignedUrl", [
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SignedUrl>): SignedUrl {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignedUrl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignedUrl): SignedUrl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignedUrl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SignedUrl
 */
export const SignedUrl = new SignedUrl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioFilePath$Type extends MessageType<ScenarioFilePath> {
    constructor() {
        super("ScenarioFilePath", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioFilePath>): ScenarioFilePath {
        const message = { project: "", scenario: "", path: "", fileId: "", contentType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioFilePath>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioFilePath): ScenarioFilePath {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string path */ 3:
                    message.path = reader.string();
                    break;
                case /* string file_id */ 4:
                    message.fileId = reader.string();
                    break;
                case /* string content_type */ 5:
                    message.contentType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioFilePath, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string path = 3; */
        if (message.path !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.path);
        /* string file_id = 4; */
        if (message.fileId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fileId);
        /* string content_type = 5; */
        if (message.contentType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.contentType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioFilePath
 */
export const ScenarioFilePath = new ScenarioFilePath$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilePath$Type extends MessageType<FilePath> {
    constructor() {
        super("FilePath", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FilePath>): FilePath {
        const message = { path: "", contentType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FilePath>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilePath): FilePath {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* string content_type */ 2:
                    message.contentType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilePath, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* string content_type = 2; */
        if (message.contentType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contentType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FilePath
 */
export const FilePath = new FilePath$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilePaths$Type extends MessageType<FilePaths> {
    constructor() {
        super("FilePaths", [
            { no: 1, name: "paths", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_paths", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FilePath }
        ]);
    }
    create(value?: PartialMessage<FilePaths>): FilePaths {
        const message = { paths: [], scenario: "", projectId: "", filePaths: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FilePaths>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilePaths): FilePaths {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string paths */ 1:
                    message.paths.push(reader.string());
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string project_id */ 3:
                    message.projectId = reader.string();
                    break;
                case /* repeated FilePath file_paths */ 4:
                    message.filePaths.push(FilePath.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilePaths, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string paths = 1; */
        for (let i = 0; i < message.paths.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.paths[i]);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string project_id = 3; */
        if (message.projectId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.projectId);
        /* repeated FilePath file_paths = 4; */
        for (let i = 0; i < message.filePaths.length; i++)
            FilePath.internalBinaryWrite(message.filePaths[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FilePaths
 */
export const FilePaths = new FilePaths$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Link$Type extends MessageType<Link> {
    constructor() {
        super("Link", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Link>): Link {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Link>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Link): Link {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Link, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Link
 */
export const Link = new Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScenarioUploadURL$Type extends MessageType<ScenarioUploadURL> {
    constructor() {
        super("ScenarioUploadURL", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScenarioUploadURL>): ScenarioUploadURL {
        const message = { scenario: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScenarioUploadURL>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScenarioUploadURL): ScenarioUploadURL {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScenarioUploadURL, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScenarioUploadURL
 */
export const ScenarioUploadURL = new ScenarioUploadURL$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Model$Type extends MessageType<Model> {
    constructor() {
        super("Model", [
            { no: 1, name: "model_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "files", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FileSignedUrl },
            { no: 4, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Model>): Model {
        const message = { modelId: "", model: "", files: [], projectId: "", scenarioId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Model>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Model): Model {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string model_id */ 1:
                    message.modelId = reader.string();
                    break;
                case /* string model */ 2:
                    message.model = reader.string();
                    break;
                case /* repeated FileSignedUrl files */ 3:
                    message.files.push(FileSignedUrl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string project_id */ 4:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 5:
                    message.scenarioId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Model, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string model_id = 1; */
        if (message.modelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modelId);
        /* string model = 2; */
        if (message.model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.model);
        /* repeated FileSignedUrl files = 3; */
        for (let i = 0; i < message.files.length; i++)
            FileSignedUrl.internalBinaryWrite(message.files[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string project_id = 4; */
        if (message.projectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 5; */
        if (message.scenarioId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.scenarioId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Model
 */
export const Model = new Model$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProbeInformation$Type extends MessageType<ProbeInformation> {
    constructor() {
        super("ProbeInformation", [
            { no: 1, name: "epoch_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "probe_doc_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "kubernetes_deployment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "kubernetes_deployment_namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProbeInformation>): ProbeInformation {
        const message = { epochTime: 0, projectId: "", scenarioId: "", probeDocId: "", kubernetesDeploymentId: "", kubernetesDeploymentNamespace: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProbeInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProbeInformation): ProbeInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double epoch_time */ 1:
                    message.epochTime = reader.double();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 3:
                    message.scenarioId = reader.string();
                    break;
                case /* string probe_doc_id */ 4:
                    message.probeDocId = reader.string();
                    break;
                case /* string kubernetes_deployment_id */ 5:
                    message.kubernetesDeploymentId = reader.string();
                    break;
                case /* string kubernetes_deployment_namespace */ 6:
                    message.kubernetesDeploymentNamespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProbeInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double epoch_time = 1; */
        if (message.epochTime !== 0)
            writer.tag(1, WireType.Bit64).double(message.epochTime);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 3; */
        if (message.scenarioId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.scenarioId);
        /* string probe_doc_id = 4; */
        if (message.probeDocId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.probeDocId);
        /* string kubernetes_deployment_id = 5; */
        if (message.kubernetesDeploymentId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.kubernetesDeploymentId);
        /* string kubernetes_deployment_namespace = 6; */
        if (message.kubernetesDeploymentNamespace !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.kubernetesDeploymentNamespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProbeInformation
 */
export const ProbeInformation = new ProbeInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProbeResponse$Type extends MessageType<ProbeResponse> {
    constructor() {
        super("ProbeResponse", [
            { no: 1, name: "document_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProbeResponse>): ProbeResponse {
        const message = { documentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProbeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProbeResponse): ProbeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string document_id */ 1:
                    message.documentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProbeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string document_id = 1; */
        if (message.documentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.documentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProbeResponse
 */
export const ProbeResponse = new ProbeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Subscription$Type extends MessageType<Subscription> {
    constructor() {
        super("Subscription", [
            { no: 1, name: "channel_patterns", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Subscription>): Subscription {
        const message = { channelPatterns: [], scenario: "", projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Subscription>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Subscription): Subscription {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string channel_patterns */ 1:
                    message.channelPatterns.push(reader.string());
                    break;
                case /* string scenario */ 3:
                    message.scenario = reader.string();
                    break;
                case /* string project_id */ 4:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Subscription, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string channel_patterns = 1; */
        for (let i = 0; i < message.channelPatterns.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.channelPatterns[i]);
        /* string scenario = 3; */
        if (message.scenario !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.scenario);
        /* string project_id = 4; */
        if (message.projectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Subscription
 */
export const Subscription = new Subscription$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionMessage$Type extends MessageType<SubscriptionMessage> {
    constructor() {
        super("SubscriptionMessage", [
            { no: 1, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SubscriptionMessage>): SubscriptionMessage {
        const message = { channel: "", message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubscriptionMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionMessage): SubscriptionMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel */ 1:
                    message.channel = reader.string();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscriptionMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string channel = 1; */
        if (message.channel !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channel);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscriptionMessage
 */
export const SubscriptionMessage = new SubscriptionMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutionMessage$Type extends MessageType<ExecutionMessage> {
    constructor() {
        super("ExecutionMessage", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExecutionMessage>): ExecutionMessage {
        const message = { projectId: "", scenarioId: "", jobId: "", executionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExecutionMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExecutionMessage): ExecutionMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 2:
                    message.scenarioId = reader.string();
                    break;
                case /* string job_id */ 3:
                    message.jobId = reader.string();
                    break;
                case /* string execution_id */ 4:
                    message.executionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExecutionMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 2; */
        if (message.scenarioId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioId);
        /* string job_id = 3; */
        if (message.jobId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.jobId);
        /* string execution_id = 4; */
        if (message.executionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.executionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message ExecutionMessage
 */
export const ExecutionMessage = new ExecutionMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutionState$Type extends MessageType<ExecutionState> {
    constructor() {
        super("ExecutionState", [
            { no: 1, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "archived", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "hibernating", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "scenario", kind: "message", T: () => Scenario }
        ]);
    }
    create(value?: PartialMessage<ExecutionState>): ExecutionState {
        const message = { active: false, archived: false, hibernating: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExecutionState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExecutionState): ExecutionState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool active */ 1:
                    message.active = reader.bool();
                    break;
                case /* bool archived */ 2:
                    message.archived = reader.bool();
                    break;
                case /* bool hibernating */ 3:
                    message.hibernating = reader.bool();
                    break;
                case /* Scenario scenario */ 4:
                    message.scenario = Scenario.internalBinaryRead(reader, reader.uint32(), options, message.scenario);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExecutionState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool active = 1; */
        if (message.active !== false)
            writer.tag(1, WireType.Varint).bool(message.active);
        /* bool archived = 2; */
        if (message.archived !== false)
            writer.tag(2, WireType.Varint).bool(message.archived);
        /* bool hibernating = 3; */
        if (message.hibernating !== false)
            writer.tag(3, WireType.Varint).bool(message.hibernating);
        /* Scenario scenario = 4; */
        if (message.scenario)
            Scenario.internalBinaryWrite(message.scenario, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExecutionState
 */
export const ExecutionState = new ExecutionState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutionStateMessage$Type extends MessageType<ExecutionStateMessage> {
    constructor() {
        super("ExecutionStateMessage", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExecutionStateMessage>): ExecutionStateMessage {
        const message = { projectId: "", executionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExecutionStateMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExecutionStateMessage): ExecutionStateMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string execution_id */ 2:
                    message.executionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExecutionStateMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string execution_id = 2; */
        if (message.executionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.executionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExecutionStateMessage
 */
export const ExecutionStateMessage = new ExecutionStateMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteExecutionMessage$Type extends MessageType<CompleteExecutionMessage> {
    constructor() {
        super("CompleteExecutionMessage", [
            { no: 1, name: "execution", kind: "message", T: () => ExecutionMessage },
            { no: 2, name: "hibernate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteExecutionMessage>): CompleteExecutionMessage {
        const message = { hibernate: false, projectId: "", scenarioId: "", jobId: "", executionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteExecutionMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteExecutionMessage): CompleteExecutionMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ExecutionMessage execution = 1 [deprecated = true];*/ 1:
                    message.execution = ExecutionMessage.internalBinaryRead(reader, reader.uint32(), options, message.execution);
                    break;
                case /* bool hibernate */ 2:
                    message.hibernate = reader.bool();
                    break;
                case /* string project_id */ 3:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 4:
                    message.scenarioId = reader.string();
                    break;
                case /* string job_id */ 5:
                    message.jobId = reader.string();
                    break;
                case /* string execution_id */ 6:
                    message.executionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteExecutionMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ExecutionMessage execution = 1 [deprecated = true]; */
        if (message.execution)
            ExecutionMessage.internalBinaryWrite(message.execution, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool hibernate = 2; */
        if (message.hibernate !== false)
            writer.tag(2, WireType.Varint).bool(message.hibernate);
        /* string project_id = 3; */
        if (message.projectId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 4; */
        if (message.scenarioId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.scenarioId);
        /* string job_id = 5; */
        if (message.jobId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.jobId);
        /* string execution_id = 6; */
        if (message.executionId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.executionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompleteExecutionMessage
 */
export const CompleteExecutionMessage = new CompleteExecutionMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutionId$Type extends MessageType<ExecutionId> {
    constructor() {
        super("ExecutionId", [
            { no: 1, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExecutionId>): ExecutionId {
        const message = { executionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExecutionId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExecutionId): ExecutionId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string execution_id */ 1:
                    message.executionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExecutionId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string execution_id = 1; */
        if (message.executionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.executionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExecutionId
 */
export const ExecutionId = new ExecutionId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutionReadLogs$Type extends MessageType<ExecutionReadLogs> {
    constructor() {
        super("ExecutionReadLogs", [
            { no: 1, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "end", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExecutionReadLogs>): ExecutionReadLogs {
        const message = { executionId: "", start: 0, end: 0, projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExecutionReadLogs>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExecutionReadLogs): ExecutionReadLogs {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string execution_id */ 1:
                    message.executionId = reader.string();
                    break;
                case /* double start */ 2:
                    message.start = reader.double();
                    break;
                case /* double end */ 3:
                    message.end = reader.double();
                    break;
                case /* string project_id */ 4:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExecutionReadLogs, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string execution_id = 1; */
        if (message.executionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.executionId);
        /* double start = 2; */
        if (message.start !== 0)
            writer.tag(2, WireType.Bit64).double(message.start);
        /* double end = 3; */
        if (message.end !== 0)
            writer.tag(3, WireType.Bit64).double(message.end);
        /* string project_id = 4; */
        if (message.projectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExecutionReadLogs
 */
export const ExecutionReadLogs = new ExecutionReadLogs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
    constructor() {
        super("Empty", []);
    }
    create(value?: PartialMessage<Empty>): Empty {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Empty>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Empty): Empty {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Empty
 */
export const Empty = new Empty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Json$Type extends MessageType<Json> {
    constructor() {
        super("Json", [
            { no: 1, name: "json", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Json>): Json {
        const message = { json: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Json>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Json): Json {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string json */ 1:
                    message.json = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Json, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string json = 1; */
        if (message.json !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.json);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Json
 */
export const Json = new Json$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogEntries$Type extends MessageType<LogEntries> {
    constructor() {
        super("LogEntries", [
            { no: 1, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "log_entries", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamps", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogEntries>): LogEntries {
        const message = { executionId: "", logEntries: [], timestamps: [], scenario: "", projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogEntries>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogEntries): LogEntries {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string execution_id */ 1:
                    message.executionId = reader.string();
                    break;
                case /* repeated string log_entries */ 2:
                    message.logEntries.push(reader.string());
                    break;
                case /* repeated double timestamps */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.timestamps.push(reader.double());
                    else
                        message.timestamps.push(reader.double());
                    break;
                case /* string scenario */ 4:
                    message.scenario = reader.string();
                    break;
                case /* string project_id */ 5:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogEntries, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string execution_id = 1; */
        if (message.executionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.executionId);
        /* repeated string log_entries = 2; */
        for (let i = 0; i < message.logEntries.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.logEntries[i]);
        /* repeated double timestamps = 3; */
        if (message.timestamps.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.timestamps.length; i++)
                writer.double(message.timestamps[i]);
            writer.join();
        }
        /* string scenario = 4; */
        if (message.scenario !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.scenario);
        /* string project_id = 5; */
        if (message.projectId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LogEntries
 */
export const LogEntries = new LogEntries$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExeLogEntry$Type extends MessageType<ExeLogEntry> {
    constructor() {
        super("ExeLogEntry", [
            { no: 1, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "log_entry", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamp", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ExeLogEntry>): ExeLogEntry {
        const message = { executionId: "", logEntry: "", timestamp: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExeLogEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExeLogEntry): ExeLogEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string execution_id */ 1:
                    message.executionId = reader.string();
                    break;
                case /* string log_entry */ 2:
                    message.logEntry = reader.string();
                    break;
                case /* double timestamp */ 3:
                    message.timestamp = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExeLogEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string execution_id = 1; */
        if (message.executionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.executionId);
        /* string log_entry = 2; */
        if (message.logEntry !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.logEntry);
        /* double timestamp = 3; */
        if (message.timestamp !== 0)
            writer.tag(3, WireType.Bit64).double(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExeLogEntry
 */
export const ExeLogEntry = new ExeLogEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DequeueRequest$Type extends MessageType<DequeueRequest> {
    constructor() {
        super("DequeueRequest", [
            { no: 1, name: "queue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timeout", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<DequeueRequest>): DequeueRequest {
        const message = { queue: "", timeout: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DequeueRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DequeueRequest): DequeueRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string queue */ 1:
                    message.queue = reader.string();
                    break;
                case /* float timeout */ 2:
                    message.timeout = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DequeueRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string queue = 1; */
        if (message.queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.queue);
        /* float timeout = 2; */
        if (message.timeout !== 0)
            writer.tag(2, WireType.Bit32).float(message.timeout);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DequeueRequest
 */
export const DequeueRequest = new DequeueRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnqueueRequest$Type extends MessageType<EnqueueRequest> {
    constructor() {
        super("EnqueueRequest", [
            { no: 1, name: "queue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "json", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EnqueueRequest>): EnqueueRequest {
        const message = { queue: "", json: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnqueueRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnqueueRequest): EnqueueRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string queue */ 1:
                    message.queue = reader.string();
                    break;
                case /* string json */ 2:
                    message.json = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnqueueRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string queue = 1; */
        if (message.queue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.queue);
        /* string json = 2; */
        if (message.json !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.json);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EnqueueRequest
 */
export const EnqueueRequest = new EnqueueRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOptimizationResultRequest$Type extends MessageType<SetOptimizationResultRequest> {
    constructor() {
        super("SetOptimizationResultRequest", [
            { no: 1, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "optimal_scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "score", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ScenarioSetting }
        ]);
    }
    create(value?: PartialMessage<SetOptimizationResultRequest>): SetOptimizationResultRequest {
        const message = { scenarioId: "", projectId: "", optimalScenarioId: "", score: 0, settings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOptimizationResultRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOptimizationResultRequest): SetOptimizationResultRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario_id */ 1:
                    message.scenarioId = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string optimal_scenario_id */ 3:
                    message.optimalScenarioId = reader.string();
                    break;
                case /* float score */ 4:
                    message.score = reader.float();
                    break;
                case /* repeated ScenarioSetting settings */ 5:
                    message.settings.push(ScenarioSetting.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetOptimizationResultRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario_id = 1; */
        if (message.scenarioId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenarioId);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string optimal_scenario_id = 3; */
        if (message.optimalScenarioId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.optimalScenarioId);
        /* float score = 4; */
        if (message.score !== 0)
            writer.tag(4, WireType.Bit32).float(message.score);
        /* repeated ScenarioSetting settings = 5; */
        for (let i = 0; i < message.settings.length; i++)
            ScenarioSetting.internalBinaryWrite(message.settings[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SetOptimizationResultRequest
 */
export const SetOptimizationResultRequest = new SetOptimizationResultRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOptimizationIterationScoreRequest$Type extends MessageType<SetOptimizationIterationScoreRequest> {
    constructor() {
        super("SetOptimizationIterationScoreRequest", [
            { no: 1, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "score", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<SetOptimizationIterationScoreRequest>): SetOptimizationIterationScoreRequest {
        const message = { scenarioId: "", projectId: "", score: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOptimizationIterationScoreRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOptimizationIterationScoreRequest): SetOptimizationIterationScoreRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario_id */ 1:
                    message.scenarioId = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* float score */ 3:
                    message.score = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetOptimizationIterationScoreRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario_id = 1; */
        if (message.scenarioId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenarioId);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* float score = 3; */
        if (message.score !== 0)
            writer.tag(3, WireType.Bit32).float(message.score);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SetOptimizationIterationScoreRequest
 */
export const SetOptimizationIterationScoreRequest = new SetOptimizationIterationScoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamData$Type extends MessageType<StreamData> {
    constructor() {
        super("StreamData", [
            { no: 1, name: "values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<StreamData>): StreamData {
        const message = { values: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamData): StreamData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated double values */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.values.push(reader.double());
                    else
                        message.values.push(reader.double());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated double values = 1; */
        if (message.values.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.values.length; i++)
                writer.double(message.values[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StreamData
 */
export const StreamData = new StreamData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteDataStreamRequest$Type extends MessageType<WriteDataStreamRequest> {
    constructor() {
        super("WriteDataStreamRequest", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "overwrite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "index", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => StreamData } },
            { no: 6, name: "update_stats", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<WriteDataStreamRequest>): WriteDataStreamRequest {
        const message = { scenario: "", execution: "", overwrite: false, index: [], data: {}, updateStats: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteDataStreamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteDataStreamRequest): WriteDataStreamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 2:
                    message.execution = reader.string();
                    break;
                case /* bool overwrite */ 3:
                    message.overwrite = reader.bool();
                    break;
                case /* repeated double index */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.index.push(reader.double());
                    else
                        message.index.push(reader.double());
                    break;
                case /* map<string, StreamData> data */ 5:
                    this.binaryReadMap5(message.data, reader, options);
                    break;
                case /* bool update_stats */ 6:
                    message.updateStats = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: WriteDataStreamRequest["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof WriteDataStreamRequest["data"] | undefined, val: WriteDataStreamRequest["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = StreamData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field WriteDataStreamRequest.data");
            }
        }
        map[key ?? ""] = val ?? StreamData.create();
    }
    internalBinaryWrite(message: WriteDataStreamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 2; */
        if (message.execution !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.execution);
        /* bool overwrite = 3; */
        if (message.overwrite !== false)
            writer.tag(3, WireType.Varint).bool(message.overwrite);
        /* repeated double index = 4; */
        if (message.index.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.index.length; i++)
                writer.double(message.index[i]);
            writer.join();
        }
        /* map<string, StreamData> data = 5; */
        for (let k of Object.keys(message.data)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            StreamData.internalBinaryWrite(message.data[k], writer, options);
            writer.join().join();
        }
        /* bool update_stats = 6; */
        if (message.updateStats !== false)
            writer.tag(6, WireType.Varint).bool(message.updateStats);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WriteDataStreamRequest
 */
export const WriteDataStreamRequest = new WriteDataStreamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteDataStreamResponse$Type extends MessageType<WriteDataStreamResponse> {
    constructor() {
        super("WriteDataStreamResponse", [
            { no: 1, name: "index", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<WriteDataStreamResponse>): WriteDataStreamResponse {
        const message = { index: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteDataStreamResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteDataStreamResponse): WriteDataStreamResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double index */ 1:
                    message.index = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteDataStreamResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double index = 1; */
        if (message.index !== 0)
            writer.tag(1, WireType.Bit64).double(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WriteDataStreamResponse
 */
export const WriteDataStreamResponse = new WriteDataStreamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadDataStreamRequest$Type extends MessageType<ReadDataStreamRequest> {
    constructor() {
        super("ReadDataStreamRequest", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "start", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "end", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "length", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ReadDataStreamRequest>): ReadDataStreamRequest {
        const message = { scenario: "", execution: "", tags: [], start: 0, end: 0, length: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReadDataStreamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadDataStreamRequest): ReadDataStreamRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 2:
                    message.execution = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                case /* double start */ 4:
                    message.start = reader.double();
                    break;
                case /* double end */ 5:
                    message.end = reader.double();
                    break;
                case /* uint64 length */ 6:
                    message.length = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadDataStreamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 2; */
        if (message.execution !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.execution);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        /* double start = 4; */
        if (message.start !== 0)
            writer.tag(4, WireType.Bit64).double(message.start);
        /* double end = 5; */
        if (message.end !== 0)
            writer.tag(5, WireType.Bit64).double(message.end);
        /* uint64 length = 6; */
        if (message.length !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.length);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadDataStreamRequest
 */
export const ReadDataStreamRequest = new ReadDataStreamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadDataStreamResponse$Type extends MessageType<ReadDataStreamResponse> {
    constructor() {
        super("ReadDataStreamResponse", [
            { no: 1, name: "index", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => StreamData } }
        ]);
    }
    create(value?: PartialMessage<ReadDataStreamResponse>): ReadDataStreamResponse {
        const message = { index: [], data: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReadDataStreamResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadDataStreamResponse): ReadDataStreamResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated double index */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.index.push(reader.double());
                    else
                        message.index.push(reader.double());
                    break;
                case /* map<string, StreamData> data */ 2:
                    this.binaryReadMap2(message.data, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: ReadDataStreamResponse["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReadDataStreamResponse["data"] | undefined, val: ReadDataStreamResponse["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = StreamData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ReadDataStreamResponse.data");
            }
        }
        map[key ?? ""] = val ?? StreamData.create();
    }
    internalBinaryWrite(message: ReadDataStreamResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated double index = 1; */
        if (message.index.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.index.length; i++)
                writer.double(message.index[i]);
            writer.join();
        }
        /* map<string, StreamData> data = 2; */
        for (let k of Object.keys(message.data)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            StreamData.internalBinaryWrite(message.data[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadDataStreamResponse
 */
export const ReadDataStreamResponse = new ReadDataStreamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDataStreamStatsRequest$Type extends MessageType<GetDataStreamStatsRequest> {
    constructor() {
        super("GetDataStreamStatsRequest", [
            { no: 1, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "execution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDataStreamStatsRequest>): GetDataStreamStatsRequest {
        const message = { scenario: "", execution: "", tags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDataStreamStatsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDataStreamStatsRequest): GetDataStreamStatsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string scenario */ 1:
                    message.scenario = reader.string();
                    break;
                case /* string execution */ 2:
                    message.execution = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDataStreamStatsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string scenario = 1; */
        if (message.scenario !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.scenario);
        /* string execution = 2; */
        if (message.execution !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.execution);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDataStreamStatsRequest
 */
export const GetDataStreamStatsRequest = new GetDataStreamStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataStreamStats$Type extends MessageType<DataStreamStats> {
    constructor() {
        super("DataStreamStats", [
            { no: 1, name: "min", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "max", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "len", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<DataStreamStats>): DataStreamStats {
        const message = { min: 0, max: 0, len: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataStreamStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataStreamStats): DataStreamStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double min */ 1:
                    message.min = reader.double();
                    break;
                case /* double max */ 2:
                    message.max = reader.double();
                    break;
                case /* uint64 len */ 3:
                    message.len = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataStreamStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double min = 1; */
        if (message.min !== 0)
            writer.tag(1, WireType.Bit64).double(message.min);
        /* double max = 2; */
        if (message.max !== 0)
            writer.tag(2, WireType.Bit64).double(message.max);
        /* uint64 len = 3; */
        if (message.len !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.len);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DataStreamStats
 */
export const DataStreamStats = new DataStreamStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDataStreamStatsResponse$Type extends MessageType<GetDataStreamStatsResponse> {
    constructor() {
        super("GetDataStreamStatsResponse", [
            { no: 1, name: "stats", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => DataStreamStats } }
        ]);
    }
    create(value?: PartialMessage<GetDataStreamStatsResponse>): GetDataStreamStatsResponse {
        const message = { stats: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDataStreamStatsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDataStreamStatsResponse): GetDataStreamStatsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, DataStreamStats> stats */ 1:
                    this.binaryReadMap1(message.stats, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetDataStreamStatsResponse["stats"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetDataStreamStatsResponse["stats"] | undefined, val: GetDataStreamStatsResponse["stats"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = DataStreamStats.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field GetDataStreamStatsResponse.stats");
            }
        }
        map[key ?? ""] = val ?? DataStreamStats.create();
    }
    internalBinaryWrite(message: GetDataStreamStatsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, DataStreamStats> stats = 1; */
        for (let k of Object.keys(message.stats)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            DataStreamStats.internalBinaryWrite(message.stats[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDataStreamStatsResponse
 */
export const GetDataStreamStatsResponse = new GetDataStreamStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageInformation$Type extends MessageType<ImageInformation> {
    constructor() {
        super("ImageInformation", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "google_project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "deployment_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "nodepool", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "spm_job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "cpu_limit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cpu_request", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "memory_limit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "memory_request", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageInformation>): ImageInformation {
        const message = { projectId: "", scenarioId: "", googleProject: "", image: "", deploymentName: "", namespace: "", nodepool: "", token: "", spmJobId: "", cpuLimit: "", cpuRequest: "", memoryLimit: "", memoryRequest: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageInformation): ImageInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 2:
                    message.scenarioId = reader.string();
                    break;
                case /* string google_project */ 3:
                    message.googleProject = reader.string();
                    break;
                case /* string image */ 4:
                    message.image = reader.string();
                    break;
                case /* string deployment_name */ 5:
                    message.deploymentName = reader.string();
                    break;
                case /* string namespace */ 6:
                    message.namespace = reader.string();
                    break;
                case /* string nodepool */ 7:
                    message.nodepool = reader.string();
                    break;
                case /* string token */ 8:
                    message.token = reader.string();
                    break;
                case /* string spm_job_id */ 9:
                    message.spmJobId = reader.string();
                    break;
                case /* string cpu_limit */ 10:
                    message.cpuLimit = reader.string();
                    break;
                case /* string cpu_request */ 11:
                    message.cpuRequest = reader.string();
                    break;
                case /* string memory_limit */ 12:
                    message.memoryLimit = reader.string();
                    break;
                case /* string memory_request */ 13:
                    message.memoryRequest = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 2; */
        if (message.scenarioId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioId);
        /* string google_project = 3; */
        if (message.googleProject !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.googleProject);
        /* string image = 4; */
        if (message.image !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.image);
        /* string deployment_name = 5; */
        if (message.deploymentName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.deploymentName);
        /* string namespace = 6; */
        if (message.namespace !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.namespace);
        /* string nodepool = 7; */
        if (message.nodepool !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.nodepool);
        /* string token = 8; */
        if (message.token !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.token);
        /* string spm_job_id = 9; */
        if (message.spmJobId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.spmJobId);
        /* string cpu_limit = 10; */
        if (message.cpuLimit !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cpuLimit);
        /* string cpu_request = 11; */
        if (message.cpuRequest !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.cpuRequest);
        /* string memory_limit = 12; */
        if (message.memoryLimit !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.memoryLimit);
        /* string memory_request = 13; */
        if (message.memoryRequest !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.memoryRequest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ImageInformation
 */
export const ImageInformation = new ImageInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Deployment$Type extends MessageType<Deployment> {
    constructor() {
        super("Deployment", [
            { no: 1, name: "kubernetes_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Deployment>): Deployment {
        const message = { kubernetesId: "", namespace: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Deployment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Deployment): Deployment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string kubernetes_id */ 1:
                    message.kubernetesId = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Deployment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string kubernetes_id = 1; */
        if (message.kubernetesId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.kubernetesId);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Deployment
 */
export const Deployment = new Deployment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Job$Type extends MessageType<Job> {
    constructor() {
        super("Job", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Job>): Job {
        const message = { projectId: "", scenarioId: "", jobId: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Job>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Job): Job {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 2:
                    message.scenarioId = reader.string();
                    break;
                case /* string job_id */ 3:
                    message.jobId = reader.string();
                    break;
                case /* string user_id */ 4:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Job, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 2; */
        if (message.scenarioId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioId);
        /* string job_id = 3; */
        if (message.jobId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.jobId);
        /* string user_id = 4; */
        if (message.userId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Job
 */
export const Job = new Job$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Schedule$Type extends MessageType<Schedule> {
    constructor() {
        super("Schedule", [
            { no: 3, name: "enable_scheduling", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "sleep_after", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "sleep_for", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<Schedule>): Schedule {
        const message = { enableScheduling: false, sleepAfter: 0, sleepFor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Schedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Schedule): Schedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enable_scheduling */ 3:
                    message.enableScheduling = reader.bool();
                    break;
                case /* double sleep_after */ 4:
                    message.sleepAfter = reader.double();
                    break;
                case /* double sleep_for */ 5:
                    message.sleepFor = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Schedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enable_scheduling = 3; */
        if (message.enableScheduling !== false)
            writer.tag(3, WireType.Varint).bool(message.enableScheduling);
        /* double sleep_after = 4; */
        if (message.sleepAfter !== 0)
            writer.tag(4, WireType.Bit64).double(message.sleepAfter);
        /* double sleep_for = 5; */
        if (message.sleepFor !== 0)
            writer.tag(5, WireType.Bit64).double(message.sleepFor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Schedule
 */
export const Schedule = new Schedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExecutionAndSchedule$Type extends MessageType<ExecutionAndSchedule> {
    constructor() {
        super("ExecutionAndSchedule", [
            { no: 1, name: "execution", kind: "message", T: () => ExecutionId },
            { no: 2, name: "schedule", kind: "message", T: () => Schedule }
        ]);
    }
    create(value?: PartialMessage<ExecutionAndSchedule>): ExecutionAndSchedule {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExecutionAndSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExecutionAndSchedule): ExecutionAndSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ExecutionId execution */ 1:
                    message.execution = ExecutionId.internalBinaryRead(reader, reader.uint32(), options, message.execution);
                    break;
                case /* Schedule schedule */ 2:
                    message.schedule = Schedule.internalBinaryRead(reader, reader.uint32(), options, message.schedule);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExecutionAndSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ExecutionId execution = 1; */
        if (message.execution)
            ExecutionId.internalBinaryWrite(message.execution, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Schedule schedule = 2; */
        if (message.schedule)
            Schedule.internalBinaryWrite(message.schedule, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExecutionAndSchedule
 */
export const ExecutionAndSchedule = new ExecutionAndSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobTime$Type extends MessageType<JobTime> {
    constructor() {
        super("JobTime", [
            { no: 1, name: "job", kind: "message", T: () => Job },
            { no: 2, name: "start_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "end_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<JobTime>): JobTime {
        const message = { startTime: 0, endTime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JobTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobTime): JobTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Job job */ 1:
                    message.job = Job.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                case /* double start_time */ 2:
                    message.startTime = reader.double();
                    break;
                case /* double end_time */ 3:
                    message.endTime = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Job job = 1; */
        if (message.job)
            Job.internalBinaryWrite(message.job, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* double start_time = 2; */
        if (message.startTime !== 0)
            writer.tag(2, WireType.Bit64).double(message.startTime);
        /* double end_time = 3; */
        if (message.endTime !== 0)
            writer.tag(3, WireType.Bit64).double(message.endTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobTime
 */
export const JobTime = new JobTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobSchedule$Type extends MessageType<JobSchedule> {
    constructor() {
        super("JobSchedule", [
            { no: 1, name: "job", kind: "message", T: () => Job },
            { no: 2, name: "schedule", kind: "message", T: () => Schedule }
        ]);
    }
    create(value?: PartialMessage<JobSchedule>): JobSchedule {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JobSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobSchedule): JobSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Job job */ 1:
                    message.job = Job.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                case /* Schedule schedule */ 2:
                    message.schedule = Schedule.internalBinaryRead(reader, reader.uint32(), options, message.schedule);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Job job = 1; */
        if (message.job)
            Job.internalBinaryWrite(message.job, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Schedule schedule = 2; */
        if (message.schedule)
            Schedule.internalBinaryWrite(message.schedule, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobSchedule
 */
export const JobSchedule = new JobSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobTimeSchedule$Type extends MessageType<JobTimeSchedule> {
    constructor() {
        super("JobTimeSchedule", [
            { no: 1, name: "job", kind: "message", T: () => Job },
            { no: 2, name: "schedule", kind: "message", T: () => Schedule },
            { no: 3, name: "start_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "end_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<JobTimeSchedule>): JobTimeSchedule {
        const message = { startTime: 0, endTime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JobTimeSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobTimeSchedule): JobTimeSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Job job */ 1:
                    message.job = Job.internalBinaryRead(reader, reader.uint32(), options, message.job);
                    break;
                case /* Schedule schedule */ 2:
                    message.schedule = Schedule.internalBinaryRead(reader, reader.uint32(), options, message.schedule);
                    break;
                case /* double start_time */ 3:
                    message.startTime = reader.double();
                    break;
                case /* double end_time */ 4:
                    message.endTime = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobTimeSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Job job = 1; */
        if (message.job)
            Job.internalBinaryWrite(message.job, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Schedule schedule = 2; */
        if (message.schedule)
            Schedule.internalBinaryWrite(message.schedule, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* double start_time = 3; */
        if (message.startTime !== 0)
            writer.tag(3, WireType.Bit64).double(message.startTime);
        /* double end_time = 4; */
        if (message.endTime !== 0)
            writer.tag(4, WireType.Bit64).double(message.endTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobTimeSchedule
 */
export const JobTimeSchedule = new JobTimeSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartLocalJobRequest$Type extends MessageType<StartLocalJobRequest> {
    constructor() {
        super("StartLocalJobRequest", [
            { no: 1, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartLocalJobRequest>): StartLocalJobRequest {
        const message = { jobId: "", scenarioId: "", projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartLocalJobRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartLocalJobRequest): StartLocalJobRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string job_id */ 1:
                    message.jobId = reader.string();
                    break;
                case /* string scenario_id */ 2:
                    message.scenarioId = reader.string();
                    break;
                case /* string project_id */ 3:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartLocalJobRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string job_id = 1; */
        if (message.jobId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jobId);
        /* string scenario_id = 2; */
        if (message.scenarioId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioId);
        /* string project_id = 3; */
        if (message.projectId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StartLocalJobRequest
 */
export const StartLocalJobRequest = new StartLocalJobRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartLocalJobResponse$Type extends MessageType<StartLocalJobResponse> {
    constructor() {
        super("StartLocalJobResponse", [
            { no: 1, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartLocalJobResponse>): StartLocalJobResponse {
        const message = { executionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartLocalJobResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartLocalJobResponse): StartLocalJobResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string execution_id */ 1:
                    message.executionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartLocalJobResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string execution_id = 1; */
        if (message.executionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.executionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StartLocalJobResponse
 */
export const StartLocalJobResponse = new StartLocalJobResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenRequest$Type extends MessageType<TokenRequest> {
    constructor() {
        super("TokenRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "agent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "purpose", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "access_level", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<TokenRequest>): TokenRequest {
        const message = { projectId: "", scenarioId: "", admin: false, executionId: "", jobId: "", userId: "", agent: "", purpose: "", accessLevel: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenRequest): TokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 2:
                    message.scenarioId = reader.string();
                    break;
                case /* bool admin */ 3:
                    message.admin = reader.bool();
                    break;
                case /* string execution_id */ 4:
                    message.executionId = reader.string();
                    break;
                case /* string job_id */ 5:
                    message.jobId = reader.string();
                    break;
                case /* string user_id */ 6:
                    message.userId = reader.string();
                    break;
                case /* string agent */ 8:
                    message.agent = reader.string();
                    break;
                case /* string purpose */ 9:
                    message.purpose = reader.string();
                    break;
                case /* int64 access_level */ 10:
                    message.accessLevel = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 2; */
        if (message.scenarioId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioId);
        /* bool admin = 3; */
        if (message.admin !== false)
            writer.tag(3, WireType.Varint).bool(message.admin);
        /* string execution_id = 4; */
        if (message.executionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.executionId);
        /* string job_id = 5; */
        if (message.jobId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.jobId);
        /* string user_id = 6; */
        if (message.userId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.userId);
        /* string agent = 8; */
        if (message.agent !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.agent);
        /* string purpose = 9; */
        if (message.purpose !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.purpose);
        /* int64 access_level = 10; */
        if (message.accessLevel !== 0n)
            writer.tag(10, WireType.Varint).int64(message.accessLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TokenRequest
 */
export const TokenRequest = new TokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("Token", [
            { no: 1, name: "val", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Token>): Token {
        const message = { val: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Token>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Token): Token {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string val */ 1:
                    message.val = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Token, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string val = 1; */
        if (message.val !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.val);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Token
 */
export const Token = new Token$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshRequest$Type extends MessageType<RefreshRequest> {
    constructor() {
        super("RefreshRequest", [
            { no: 1, name: "refresh_token", kind: "message", T: () => Token },
            { no: 2, name: "instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "execution_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshRequest>): RefreshRequest {
        const message = { instanceId: "", executionId: "", projectId: "", scenarioId: "", jobId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefreshRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshRequest): RefreshRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Token refresh_token */ 1:
                    message.refreshToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.refreshToken);
                    break;
                case /* string instance_id */ 2:
                    message.instanceId = reader.string();
                    break;
                case /* string execution_id */ 3:
                    message.executionId = reader.string();
                    break;
                case /* string project_id */ 4:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 5:
                    message.scenarioId = reader.string();
                    break;
                case /* string job_id */ 6:
                    message.jobId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Token refresh_token = 1; */
        if (message.refreshToken)
            Token.internalBinaryWrite(message.refreshToken, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string instance_id = 2; */
        if (message.instanceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.instanceId);
        /* string execution_id = 3; */
        if (message.executionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.executionId);
        /* string project_id = 4; */
        if (message.projectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 5; */
        if (message.scenarioId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.scenarioId);
        /* string job_id = 6; */
        if (message.jobId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.jobId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RefreshRequest
 */
export const RefreshRequest = new RefreshRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateUserRequest$Type extends MessageType<AuthenticateUserRequest> {
    constructor() {
        super("AuthenticateUserRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateUserRequest>): AuthenticateUserRequest {
        const message = { email: "", password: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateUserRequest): AuthenticateUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthenticateUserRequest
 */
export const AuthenticateUserRequest = new AuthenticateUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateUserResponse$Type extends MessageType<AuthenticateUserResponse> {
    constructor() {
        super("AuthenticateUserResponse", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateUserResponse>): AuthenticateUserResponse {
        const message = { userId: "", idToken: "", refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateUserResponse): AuthenticateUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* string id_token */ 2:
                    message.idToken = reader.string();
                    break;
                case /* string refresh_token */ 3:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* string id_token = 2; */
        if (message.idToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.idToken);
        /* string refresh_token = 3; */
        if (message.refreshToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthenticateUserResponse
 */
export const AuthenticateUserResponse = new AuthenticateUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshUserRequest$Type extends MessageType<RefreshUserRequest> {
    constructor() {
        super("RefreshUserRequest", [
            { no: 3, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshUserRequest>): RefreshUserRequest {
        const message = { refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefreshUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshUserRequest): RefreshUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string refresh_token */ 3:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string refresh_token = 3; */
        if (message.refreshToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RefreshUserRequest
 */
export const RefreshUserRequest = new RefreshUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshUserResponse$Type extends MessageType<RefreshUserResponse> {
    constructor() {
        super("RefreshUserResponse", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshUserResponse>): RefreshUserResponse {
        const message = { userId: "", idToken: "", refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefreshUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshUserResponse): RefreshUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* string id_token */ 2:
                    message.idToken = reader.string();
                    break;
                case /* string refresh_token */ 3:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* string id_token = 2; */
        if (message.idToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.idToken);
        /* string refresh_token = 3; */
        if (message.refreshToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RefreshUserResponse
 */
export const RefreshUserResponse = new RefreshUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Snapshot$Type extends MessageType<Snapshot> {
    constructor() {
        super("Snapshot", [
            { no: 1, name: "snapshot_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "file_structure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "only_get_uploads", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Snapshot>): Snapshot {
        const message = { snapshotId: "", repository: "", fileStructure: "", onlyGetUploads: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Snapshot>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Snapshot): Snapshot {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string snapshot_id */ 1:
                    message.snapshotId = reader.string();
                    break;
                case /* string repository */ 2:
                    message.repository = reader.string();
                    break;
                case /* string file_structure */ 3:
                    message.fileStructure = reader.string();
                    break;
                case /* bool only_get_uploads */ 4:
                    message.onlyGetUploads = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Snapshot, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string snapshot_id = 1; */
        if (message.snapshotId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.snapshotId);
        /* string repository = 2; */
        if (message.repository !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.repository);
        /* string file_structure = 3; */
        if (message.fileStructure !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fileStructure);
        /* bool only_get_uploads = 4; */
        if (message.onlyGetUploads !== false)
            writer.tag(4, WireType.Varint).bool(message.onlyGetUploads);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Snapshot
 */
export const Snapshot = new Snapshot$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryUpdate$Type extends MessageType<HistoryUpdate> {
    constructor() {
        super("HistoryUpdate", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "snapshot_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "parent_commit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "timestamp", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "user", kind: "message", T: () => HistoryUser }
        ]);
    }
    create(value?: PartialMessage<HistoryUpdate>): HistoryUpdate {
        const message = { id: "", snapshotId: "", repository: "", projectId: "", scenarioId: "", parentCommitId: "", comment: "", timestamp: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryUpdate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryUpdate): HistoryUpdate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string snapshot_id */ 2:
                    message.snapshotId = reader.string();
                    break;
                case /* string repository */ 3:
                    message.repository = reader.string();
                    break;
                case /* string project_id */ 4:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 5:
                    message.scenarioId = reader.string();
                    break;
                case /* string parent_commit_id */ 6:
                    message.parentCommitId = reader.string();
                    break;
                case /* string comment */ 7:
                    message.comment = reader.string();
                    break;
                case /* double timestamp */ 8:
                    message.timestamp = reader.double();
                    break;
                case /* HistoryUser user */ 9:
                    message.user = HistoryUser.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryUpdate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string snapshot_id = 2; */
        if (message.snapshotId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.snapshotId);
        /* string repository = 3; */
        if (message.repository !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.repository);
        /* string project_id = 4; */
        if (message.projectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 5; */
        if (message.scenarioId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.scenarioId);
        /* string parent_commit_id = 6; */
        if (message.parentCommitId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.parentCommitId);
        /* string comment = 7; */
        if (message.comment !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.comment);
        /* double timestamp = 8; */
        if (message.timestamp !== 0)
            writer.tag(8, WireType.Bit64).double(message.timestamp);
        /* HistoryUser user = 9; */
        if (message.user)
            HistoryUser.internalBinaryWrite(message.user, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HistoryUpdate
 */
export const HistoryUpdate = new HistoryUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryUser$Type extends MessageType<HistoryUser> {
    constructor() {
        super("HistoryUser", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "full_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HistoryUser>): HistoryUser {
        const message = { id: "", email: "", fullName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryUser): HistoryUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string full_name */ 3:
                    message.fullName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string full_name = 3; */
        if (message.fullName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fullName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HistoryUser
 */
export const HistoryUser = new HistoryUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class History$Type extends MessageType<History> {
    constructor() {
        super("History", [
            { no: 1, name: "updates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryUpdate }
        ]);
    }
    create(value?: PartialMessage<History>): History {
        const message = { updates: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<History>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: History): History {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated HistoryUpdate updates */ 1:
                    message.updates.push(HistoryUpdate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: History, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated HistoryUpdate updates = 1; */
        for (let i = 0; i < message.updates.length; i++)
            HistoryUpdate.internalBinaryWrite(message.updates[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message History
 */
export const History = new History$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryRequest$Type extends MessageType<HistoryRequest> {
    constructor() {
        super("HistoryRequest", [
            { no: 1, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "history_update_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HistoryRequest>): HistoryRequest {
        const message = { repository: "", scenarioId: "", historyUpdateIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryRequest): HistoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string repository */ 1:
                    message.repository = reader.string();
                    break;
                case /* string scenario_id */ 2:
                    message.scenarioId = reader.string();
                    break;
                case /* repeated string history_update_ids */ 3:
                    message.historyUpdateIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string repository = 1; */
        if (message.repository !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.repository);
        /* string scenario_id = 2; */
        if (message.scenarioId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenarioId);
        /* repeated string history_update_ids = 3; */
        for (let i = 0; i < message.historyUpdateIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.historyUpdateIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HistoryRequest
 */
export const HistoryRequest = new HistoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryReply$Type extends MessageType<HistoryReply> {
    constructor() {
        super("HistoryReply", [
            { no: 1, name: "commit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HistoryReply>): HistoryReply {
        const message = { commitId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HistoryReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryReply): HistoryReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string commit_id */ 1:
                    message.commitId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string commit_id = 1; */
        if (message.commitId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.commitId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HistoryReply
 */
export const HistoryReply = new HistoryReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SnapshotReply$Type extends MessageType<SnapshotReply> {
    constructor() {
        super("SnapshotReply", [
            { no: 2, name: "snapshot_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "upload_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SnapshotReply>): SnapshotReply {
        const message = { snapshotId: "", uploadUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SnapshotReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SnapshotReply): SnapshotReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string snapshot_id */ 2:
                    message.snapshotId = reader.string();
                    break;
                case /* string upload_url */ 4:
                    message.uploadUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SnapshotReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string snapshot_id = 2; */
        if (message.snapshotId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.snapshotId);
        /* string upload_url = 4; */
        if (message.uploadUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uploadUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SnapshotReply
 */
export const SnapshotReply = new SnapshotReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PullRequest$Type extends MessageType<PullRequest> {
    constructor() {
        super("PullRequest", [
            { no: 2, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "snapshot_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PullRequest>): PullRequest {
        const message = { repository: "", snapshotId: "", projectId: "", scenarioId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PullRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PullRequest): PullRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string repository */ 2:
                    message.repository = reader.string();
                    break;
                case /* string snapshot_id */ 1:
                    message.snapshotId = reader.string();
                    break;
                case /* string project_id */ 3:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 4:
                    message.scenarioId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PullRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string repository = 2; */
        if (message.repository !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.repository);
        /* string snapshot_id = 1; */
        if (message.snapshotId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.snapshotId);
        /* string project_id = 3; */
        if (message.projectId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 4; */
        if (message.scenarioId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.scenarioId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PullRequest
 */
export const PullRequest = new PullRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PullResponse$Type extends MessageType<PullResponse> {
    constructor() {
        super("PullResponse", [
            { no: 1, name: "snapshot_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "archive_url", kind: "message", T: () => FileSignedUrl }
        ]);
    }
    create(value?: PartialMessage<PullResponse>): PullResponse {
        const message = { snapshotId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PullResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PullResponse): PullResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string snapshot_id */ 1:
                    message.snapshotId = reader.string();
                    break;
                case /* FileSignedUrl archive_url */ 4:
                    message.archiveUrl = FileSignedUrl.internalBinaryRead(reader, reader.uint32(), options, message.archiveUrl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PullResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string snapshot_id = 1; */
        if (message.snapshotId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.snapshotId);
        /* FileSignedUrl archive_url = 4; */
        if (message.archiveUrl)
            FileSignedUrl.internalBinaryWrite(message.archiveUrl, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PullResponse
 */
export const PullResponse = new PullResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildRequest$Type extends MessageType<BuildRequest> {
    constructor() {
        super("BuildRequest", [
            { no: 1, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "commit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "launch_after_build", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "scenario_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "timeout", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<BuildRequest>): BuildRequest {
        const message = { repository: "", commitId: "", launchAfterBuild: false, projectId: "", scenarioId: "", jobId: "", tags: [], timeout: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildRequest): BuildRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string repository */ 1:
                    message.repository = reader.string();
                    break;
                case /* string commit_id */ 2:
                    message.commitId = reader.string();
                    break;
                case /* bool launch_after_build */ 3:
                    message.launchAfterBuild = reader.bool();
                    break;
                case /* string project_id */ 4:
                    message.projectId = reader.string();
                    break;
                case /* string scenario_id */ 5:
                    message.scenarioId = reader.string();
                    break;
                case /* string job_id */ 6:
                    message.jobId = reader.string();
                    break;
                case /* repeated string tags */ 7:
                    message.tags.push(reader.string());
                    break;
                case /* float timeout */ 8:
                    message.timeout = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string repository = 1; */
        if (message.repository !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.repository);
        /* string commit_id = 2; */
        if (message.commitId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.commitId);
        /* bool launch_after_build = 3; */
        if (message.launchAfterBuild !== false)
            writer.tag(3, WireType.Varint).bool(message.launchAfterBuild);
        /* string project_id = 4; */
        if (message.projectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.projectId);
        /* string scenario_id = 5; */
        if (message.scenarioId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.scenarioId);
        /* string job_id = 6; */
        if (message.jobId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.jobId);
        /* repeated string tags = 7; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.tags[i]);
        /* float timeout = 8; */
        if (message.timeout !== 0)
            writer.tag(8, WireType.Bit32).float(message.timeout);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildRequest
 */
export const BuildRequest = new BuildRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildReply$Type extends MessageType<BuildReply> {
    constructor() {
        super("BuildReply", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildReply>): BuildReply {
        const message = { buildId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildReply): BuildReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildReply
 */
export const BuildReply = new BuildReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildUpdate$Type extends MessageType<BuildUpdate> {
    constructor() {
        super("BuildUpdate", [
            { no: 1, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "update", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildUpdate>): BuildUpdate {
        const message = { repository: "", buildId: "", update: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildUpdate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildUpdate): BuildUpdate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string repository */ 1:
                    message.repository = reader.string();
                    break;
                case /* string build_id */ 2:
                    message.buildId = reader.string();
                    break;
                case /* string update */ 3:
                    message.update = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildUpdate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string repository = 1; */
        if (message.repository !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.repository);
        /* string build_id = 2; */
        if (message.buildId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.buildId);
        /* string update = 3; */
        if (message.update !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.update);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildUpdate
 */
export const BuildUpdate = new BuildUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildInfo$Type extends MessageType<BuildInfo> {
    constructor() {
        super("BuildInfo", [
            { no: 1, name: "info", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildInfo>): BuildInfo {
        const message = { info: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildInfo): BuildInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string info */ 1:
                    message.info = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string info = 1; */
        if (message.info !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.info);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildInfo
 */
export const BuildInfo = new BuildInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildInfoRequest$Type extends MessageType<BuildInfoRequest> {
    constructor() {
        super("BuildInfoRequest", [
            { no: 1, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildInfoRequest>): BuildInfoRequest {
        const message = { repository: "", buildId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildInfoRequest): BuildInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string repository */ 1:
                    message.repository = reader.string();
                    break;
                case /* string build_id */ 2:
                    message.buildId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string repository = 1; */
        if (message.repository !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.repository);
        /* string build_id = 2; */
        if (message.buildId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.buildId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildInfoRequest
 */
export const BuildInfoRequest = new BuildInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobImageReferenceUpdate$Type extends MessageType<JobImageReferenceUpdate> {
    constructor() {
        super("JobImageReferenceUpdate", [
            { no: 1, name: "project", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scenario", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "repository", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "commit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "internal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "build", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<JobImageReferenceUpdate>): JobImageReferenceUpdate {
        const message = { project: "", scenario: "", job: "", name: "", path: "", repository: "", commitId: "", internal: false, build: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JobImageReferenceUpdate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JobImageReferenceUpdate): JobImageReferenceUpdate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project */ 1:
                    message.project = reader.string();
                    break;
                case /* string scenario */ 2:
                    message.scenario = reader.string();
                    break;
                case /* string job */ 3:
                    message.job = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string path */ 5:
                    message.path = reader.string();
                    break;
                case /* string repository */ 6:
                    message.repository = reader.string();
                    break;
                case /* string commit_id */ 7:
                    message.commitId = reader.string();
                    break;
                case /* bool internal */ 8:
                    message.internal = reader.bool();
                    break;
                case /* string build */ 9:
                    message.build = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JobImageReferenceUpdate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project = 1; */
        if (message.project !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.project);
        /* string scenario = 2; */
        if (message.scenario !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scenario);
        /* string job = 3; */
        if (message.job !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.job);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string path = 5; */
        if (message.path !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.path);
        /* string repository = 6; */
        if (message.repository !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.repository);
        /* string commit_id = 7; */
        if (message.commitId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.commitId);
        /* bool internal = 8; */
        if (message.internal !== false)
            writer.tag(8, WireType.Varint).bool(message.internal);
        /* string build = 9; */
        if (message.build !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.build);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobImageReferenceUpdate
 */
export const JobImageReferenceUpdate = new JobImageReferenceUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildDequeueRequest$Type extends MessageType<BuildDequeueRequest> {
    constructor() {
        super("BuildDequeueRequest", [
            { no: 1, name: "timeout", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<BuildDequeueRequest>): BuildDequeueRequest {
        const message = { timeout: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildDequeueRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildDequeueRequest): BuildDequeueRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* float timeout */ 1:
                    message.timeout = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildDequeueRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* float timeout = 1; */
        if (message.timeout !== 0)
            writer.tag(1, WireType.Bit32).float(message.timeout);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildDequeueRequest
 */
export const BuildDequeueRequest = new BuildDequeueRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuildResponse$Type extends MessageType<BuildResponse> {
    constructor() {
        super("BuildResponse", [
            { no: 1, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildResponse>): BuildResponse {
        const message = { status: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildResponse): BuildResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 1:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string status = 1; */
        if (message.status !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BuildResponse
 */
export const BuildResponse = new BuildResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileUrl$Type extends MessageType<FileUrl> {
    constructor() {
        super("FileUrl", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FileUrl>): FileUrl {
        const message = { url: "", path: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FileUrl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileUrl): FileUrl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileUrl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileUrl
 */
export const FileUrl = new FileUrl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileUrls$Type extends MessageType<FileUrls> {
    constructor() {
        super("FileUrls", [
            { no: 1, name: "urls", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FileUrl }
        ]);
    }
    create(value?: PartialMessage<FileUrls>): FileUrls {
        const message = { urls: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FileUrls>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileUrls): FileUrls {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated FileUrl urls */ 1:
                    message.urls.push(FileUrl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileUrls, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated FileUrl urls = 1; */
        for (let i = 0; i < message.urls.length; i++)
            FileUrl.internalBinaryWrite(message.urls[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileUrls
 */
export const FileUrls = new FileUrls$Type();
/**
 * @generated ServiceType for protobuf service SPM
 */
export const SPM = new ServiceType("SPM", [
    { name: "Noop", options: {}, I: NoopContent, O: NoopContent },
    { name: "GetDataSetClosed", options: {}, I: Scenario, O: Closed },
    { name: "ClearData", options: {}, I: Scenario, O: Scenario },
    { name: "ReadData", serverStreaming: true, options: {}, I: ReadScenario, O: DataList },
    { name: "CloseData", options: {}, I: DataCompleted, O: Scenario },
    { name: "ClearDataTags", options: {}, I: ScenarioDataTags, O: Empty },
    { name: "WriteDataList", serverStreaming: true, clientStreaming: true, options: {}, I: IndexedDataList, O: Scenario },
    { name: "PushDataList", options: {}, I: IndexedDataList, O: Scenario },
    { name: "SetOptimizationResult", options: {}, I: SetOptimizationResultRequest, O: Scenario },
    { name: "SetOptimizationIterationScore", options: {}, I: SetOptimizationIterationScoreRequest, O: Empty },
    { name: "GetScenarioMetaData", options: {}, I: Scenario, O: ScenarioMetaData },
    { name: "SetScenarioMetaData", options: {}, I: ScenarioMetaData, O: Scenario },
    { name: "GetScenarioCustomMetaData", options: {}, I: ScenarioCustomMetaData, O: ScenarioCustomMetaData },
    { name: "SetScenarioCustomMetaData", options: {}, I: ScenarioCustomMetaData, O: ScenarioCustomMetaData },
    { name: "GetScenarioDataStats", options: {}, I: ScenarioStatsRequest, O: ScenarioStats },
    { name: "GetModel", options: {}, I: Model, O: Model },
    { name: "DuplicateScenario", options: {}, I: DuplicateScenarioRequest, O: Scenario },
    { name: "PushExecutionLogEntries", options: {}, I: LogEntries, O: Empty },
    { name: "ReadExecutionLogEntries", serverStreaming: true, options: {}, I: ExecutionReadLogs, O: ExeLogEntry },
    { name: "GetScenario", options: {}, I: Scenario, O: ScenarioDocument },
    { name: "SetScenarioResultDocument", options: {}, I: ScenarioResultsDocument, O: Empty },
    { name: "GetScenarioResultDocument", options: {}, I: Scenario, O: ScenarioResultsDocument },
    { name: "GetUser", options: {}, I: UserRequest, O: User },
    { name: "GetGroup", options: {}, I: Group, O: GroupDocument },
    { name: "GetProject", options: {}, I: Project, O: ProjectDocument },
    { name: "DeleteScenario", options: {}, I: Scenario, O: Scenario },
    { name: "DeleteProject", options: {}, I: Project, O: Project },
    { name: "DeleteSystem", options: {}, I: SystemRequest, O: Empty },
    { name: "DeleteUser", options: {}, I: UserRequest, O: Empty },
    { name: "ListenScenario", serverStreaming: true, options: {}, I: Scenario, O: ScenarioDocument },
    { name: "SetScenarioProgress", options: {}, I: ScenarioProgress, O: Scenario },
    { name: "SetScenarioDataTags", options: {}, I: ScenarioDataTags, O: Scenario },
    { name: "PushScenarioError", options: {}, I: ScenarioError, O: Scenario },
    { name: "PushScenarioFormattedError", options: {}, I: ScenarioFormattedError, O: Scenario },
    { name: "GetSignedURLs", options: {}, I: FilePaths, O: FileSignedUrls },
    { name: "GetPublicLink", options: {}, I: Link, O: SignedUrl },
    { name: "GenerateScenarioUploadSignedURL", options: {}, I: ScenarioFilePath, O: ScenarioUploadURL },
    { name: "ClearScenarioResults", options: {}, I: Scenario, O: Scenario },
    { name: "SetScenarioResults", options: {}, I: ScenarioResults, O: Scenario },
    { name: "GetScenarioResults", options: {}, I: Scenario, O: ScenarioResults },
    { name: "SubscribeForUpdates", serverStreaming: true, options: {}, I: Subscription, O: SubscriptionMessage },
    { name: "PublishSubscriptionMessage", options: {}, I: SubscriptionMessage, O: Empty },
    { name: "CompleteExecution", options: {}, I: CompleteExecutionMessage, O: Empty },
    { name: "CompleteExecutionIgnoreInstance", options: {}, I: ExecutionMessage, O: Empty },
    { name: "UpdateExecution", options: {}, I: Json, O: Empty },
    { name: "ClearExecutionMemory", options: {}, I: Scenario, O: Empty },
    { name: "RemoveExecutionData", options: {}, I: Scenario, O: Empty },
    { name: "GetLatestMainExecution", options: {}, I: Scenario, O: ExecutionId },
    { name: "UpdateProbe", options: {}, I: ProbeInformation, O: ProbeResponse },
    { name: "WriteDataStream", serverStreaming: true, clientStreaming: true, options: {}, I: WriteDataStreamRequest, O: WriteDataStreamResponse },
    { name: "ReadDataStream", serverStreaming: true, clientStreaming: true, options: {}, I: ReadDataStreamRequest, O: ReadDataStreamResponse },
    { name: "GetDataStreamStats", options: {}, I: GetDataStreamStatsRequest, O: GetDataStreamStatsResponse }
]);
/**
 * @generated ServiceType for protobuf service JobManager
 */
export const JobManager = new ServiceType("JobManager", [
    { name: "StartJob", options: {}, I: Job, O: ExecutionId },
    { name: "StartJobWithTime", options: {}, I: JobTime, O: Job },
    { name: "StartJobWithSchedule", options: {}, I: JobSchedule, O: Job },
    { name: "StartJobWithTimeAndSchedule", options: {}, I: JobTimeSchedule, O: Job },
    { name: "StartLocalJob", options: {}, I: StartLocalJobRequest, O: StartLocalJobResponse },
    { name: "TerminateJob", options: {}, I: Job, O: Job },
    { name: "ResetJob", options: {}, I: Job, O: Job },
    { name: "GetExecutionStatus", options: {}, I: ExecutionId, O: Json },
    { name: "GetExecutionState", options: {}, I: ExecutionStateMessage, O: ExecutionState },
    { name: "DeleteExecution", options: {}, I: ExecutionId, O: Empty },
    { name: "AddJobSchedule", options: {}, I: JobSchedule, O: Empty },
    { name: "UpdateJobSchedule", options: {}, I: JobSchedule, O: Empty },
    { name: "RemoveJobSchedule", options: {}, I: Job, O: Empty },
    { name: "GetJobSchedule", options: {}, I: Job, O: Schedule },
    { name: "AddExecutionSchedule", options: {}, I: ExecutionAndSchedule, O: Empty },
    { name: "UpdateExecutionSchedule", options: {}, I: ExecutionAndSchedule, O: Empty },
    { name: "RemoveExecutionSchedule", options: {}, I: ExecutionId, O: Empty },
    { name: "GetExecutionSchedule", options: {}, I: ExecutionId, O: Schedule },
    { name: "ResumeJob", options: {}, I: Job, O: Job },
    { name: "HibernateJob", options: {}, I: Job, O: Job }
]);
/**
 * @generated ServiceType for protobuf service TokenManager
 */
export const TokenManager = new ServiceType("TokenManager", [
    { name: "AuthenticateUser", options: {}, I: AuthenticateUserRequest, O: AuthenticateUserResponse },
    { name: "RefreshUser", options: {}, I: RefreshUserRequest, O: RefreshUserResponse },
    { name: "CreateRefreshToken", options: {}, I: TokenRequest, O: Token },
    { name: "GetAccessToken", options: {}, I: RefreshRequest, O: Token }
]);
/**
 * @generated ServiceType for protobuf service BuildManager
 */
export const BuildManager = new ServiceType("BuildManager", [
    { name: "CreateSnapshot", options: {}, I: Snapshot, O: SnapshotReply },
    { name: "CreateHistoryUpdate", options: {}, I: HistoryUpdate, O: HistoryReply },
    { name: "GetHistory", options: {}, I: HistoryRequest, O: History },
    { name: "PullSnapshot", options: {}, I: PullRequest, O: PullResponse },
    { name: "LaunchBuild", options: {}, I: BuildRequest, O: BuildReply },
    { name: "Builder", serverStreaming: true, clientStreaming: true, options: {}, I: BuildResponse, O: Json },
    { name: "GetBuildInfo", options: {}, I: BuildInfoRequest, O: BuildInfo },
    { name: "UpdateBuild", options: {}, I: BuildUpdate, O: Empty },
    { name: "UpdateJobImageReference", options: {}, I: JobImageReferenceUpdate, O: Empty }
]);
/**
 * @generated ServiceType for protobuf service FileManager
 */
export const FileManager = new ServiceType("FileManager", [
    { name: "GetDownloadUrls", options: {}, I: FilePaths, O: FileUrls },
    { name: "GetUploadUrls", options: {}, I: FilePaths, O: FileUrls },
    { name: "DeleteFiles", options: {}, I: FilePaths, O: Empty }
]);
