import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const DATASET_TIME_SERIES = graphql(/* GraphQL */ `
  query DATASET_TIME_SERIES($dataSetId: ID!, $projectId: ID!, $tags: [String!]) {
    dataSet(dataSetId: $dataSetId, projectId: $projectId) {
      ... on StaticDataSet {
        __typename
        id
        name
        projectId
        tags
        index
        data(tags: $tags) {
          __typename
          tag
          values
        }
      }
      ... on ScheduledDataSet {
        __typename
        id
        name
        projectId
        tags
        index
        data(tags: $tags) {
          __typename
          tag
          values
        }
      }
    }
  }
`);

type Props = {
  dataSetId: string;
  projectId: string;
  tags?: string[];
  skip?: boolean;
};

const useGetDataSetTimeSeries = ({ dataSetId, projectId, tags, skip = false }: Props) => {
  const result = useQuery(DATASET_TIME_SERIES, {
    variables: { dataSetId, projectId, tags },
    skip,
  });

  return result;
};

export default useGetDataSetTimeSeries;
