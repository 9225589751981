import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const PROJECT_ARCHIVE = graphql(`
  mutation PROJECT_ARCHIVE($projectId: ID!) {
    projectArchive(projectId: $projectId) {
      ... on Project {
        id
        archived
      }
    }
  }
`);

export const useArchiveProject = () => {
  const mutation = useMutation(PROJECT_ARCHIVE);

  return mutation;
};
