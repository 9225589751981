import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const SCENARIO_DELETE = graphql(`
  mutation SCENARIO_DELETE($projectId: ID!, $scenarioId: ID!) {
    scenarioDelete(projectId: $projectId, scenarioId: $scenarioId) {
      ... on DeletedScenario {
        __typename
        name
        projectId
        scenarioId
      }
    }
  }
`);

const useDeleteScenario = () => {
  const mutation = useMutation(SCENARIO_DELETE);
  //TODO: When Project is fetched through GraphQL, update the cache here.
  return mutation;
};

export default useDeleteScenario;
