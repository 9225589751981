import DropdownSearchAddCustom from "@/Components/Basic/Selector/DropdownSearchAddCustom";
import Tooltip from "@/thermo/tooltip/Tooltip";
import { Info } from "@phosphor-icons/react";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { SectionedOptions } from "Components/Basic/Selector/DropdownSearch";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useGetPaginatedProjects } from "api/project/getPaginatedProjects";
import { useGetProject } from "api/project/getProject";
import { SelectOption } from "model/datatypes";
import { useEffect, useMemo } from "react";
import { sortAlphabetical } from "utils/jsUtils/sortAlphabetical";

type Props = {
  activeWorkspaceId: string | null;
  selectedProjectID: string;
  setSelectedOption: (option: SelectOption) => void;
  newProject?: SelectOption;
  setNewProject?: (option: SelectOption | undefined) => void;
  createNew: boolean;
};

const DropdownProjects = ({
  activeWorkspaceId,
  selectedProjectID,
  setSelectedOption,
  newProject,
  setNewProject,
  createNew,
}: Props) => {
  const user = useGlobalUser();
  const { data: initialSelectedProject } = useGetProject({ projectId: selectedProjectID });

  const {
    data: projectData,
    loading: fetchingWorkspaceProjects,
    fetchMore,
  } = useGetPaginatedProjects({ first: 100, workspaceId: activeWorkspaceId });

  useEffect(() => {
    const canFetchMoreProjects = projectData?.workspace.projects.pageInfo.hasNextPage;
    const endCursor = projectData?.workspace.projects.pageInfo.endCursor;

    if (!canFetchMoreProjects || endCursor === undefined) return;
    fetchMore({
      variables: {
        after: endCursor,
      },
    }).catch((err) => console.error(err));
  }, [
    projectData?.workspace.projects.pageInfo.endCursor,
    projectData?.workspace.projects.pageInfo.hasNextPage,
    fetchMore,
  ]);

  const allProjects = projectData?.workspace.projects;

  const selectedProject: SelectOption = useMemo(() => {
    const projectName =
      initialSelectedProject?.project?.name ||
      allProjects?.edges.find((edge) => edge.cursor === selectedProjectID)?.node.name;
    const project = { id: selectedProjectID, display: projectName || "" };

    if (allProjects === null) return project;
    const selectedProjectData = allProjects?.edges.find(
      (edge) => edge.cursor === selectedProjectID
    );
    if (selectedProjectData && selectedProjectData?.node.name)
      project.display = selectedProjectData?.node.name;
    else if (newProject) {
      return newProject;
    }
    return project;
  }, [initialSelectedProject, selectedProjectID, allProjects, newProject]);

  const projectOptions = useMemo(() => {
    if (!allProjects) return [];

    const projects = allProjects.edges
      .map(({ node }) => ({
        id: node.id,
        display: node.name,
        ownerId: node.owner.__typename === "User" && node.owner.id,
      }))
      .sort((a, b) => {
        if (a.id === selectedProjectID) return -1;
        if (b.id === selectedProjectID) return 1;
        return sortAlphabetical(a.display, b.display);
      });

    const myProjects = projects.filter((project) => project.ownerId === user.id);
    const otherProjects = projects.filter((project) => !myProjects.includes(project));

    let sectionArray: SectionedOptions["sections"] = [{ Projects: otherProjects }];
    if (myProjects.length) sectionArray.unshift({ "My Projects": myProjects });
    const sections = {
      sections: sectionArray,
    };
    return sections;
  }, [allProjects, user.id, selectedProjectID]);

  const allProjectsArray: SelectOption[] = useMemo(() => {
    if (!allProjects) return [];

    return allProjects?.edges.map(({ node }) => ({
      id: node.id,
      display: node.name,
    }));
  }, [allProjects]);

  return (
    <div>
      <div className="mb-1 flex flex-row items-center gap-1">
        Project
        <Tooltip label="Type to create a new project">
          <Info size={15} />
        </Tooltip>
        {fetchingWorkspaceProjects && <LoadingIcon className="ml-1" />}
      </div>
      <DropdownSearchAddCustom
        data-testid="projectDropdownSearch"
        options={projectOptions}
        selectedOption={selectedProject}
        setSelectedOption={(option) => {
          setSelectedOption(option);
          if (setNewProject)
            if (allProjectsArray.findIndex((wsOption) => wsOption.id === option.id) === -1)
              setNewProject(option);
            else setNewProject(undefined);
        }}
        createNew={createNew}
      />
    </div>
  );
};

export default DropdownProjects;
