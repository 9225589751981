import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const PAGINATED_SCHEDULED_DATASETS = graphql(`
  query PAGINATED_SCHEDULED_DATASETS($after: String, $first: Int, $projectId: ID!) {
    project(projectId: $projectId) {
      ... on Project {
        dataSets(after: $after, first: $first) @connection(key: "PaginatedScheduledDataSets") {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              ... on ScheduledDataSet {
                __typename
                id
                name
                tags
                projectId
                schedules {
                  __typename
                  tag
                  periods {
                    __typename
                    periodType
                    defaultValue
                    timeslots {
                      __typename
                      id
                      startTime
                      endTime
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

type Props = {
  first: number;
  projectId: string;
};

const useGetPaginatedScheduledDataSets = ({ first, projectId }: Props) => {
  const query = useQuery(PAGINATED_SCHEDULED_DATASETS, {
    variables: { first, projectId, after: null },
  });

  return query;
};

export default useGetPaginatedScheduledDataSets;
