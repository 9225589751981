import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import LoadingIcon from "./LoadingIcon/LoadingIcon";

interface Props {
  showLoading: boolean;
  iconClassName?: string;
}

const AnimateLoadingButtonInlay: React.FC<Props> = ({
  showLoading,
  iconClassName = "text-white ml-4",
}) => (
  <AnimatePresence>
    {showLoading && (
      <motion.div
        className="overflow-hidden"
        initial={{ maxWidth: "0rem" }}
        animate={{
          maxWidth: "2rem",
        }}
        exit={{ maxWidth: "0rem" }}
        transition={{
          duration: 5,
          type: "spring",
          damping: 13,
          stiffness: 140,
        }}
      >
        <LoadingIcon className={iconClassName} />
      </motion.div>
    )}
  </AnimatePresence>
);

export default AnimateLoadingButtonInlay;
