import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_SCENARIO = graphql(`
  query GET_SCENARIO($projectId: ID!, $scenarioId: ID!) {
    scenario(projectId: $projectId, scenarioId: $scenarioId) {
      ... on Scenario {
        __typename
        id
        name
      }
    }
  }
`);

type Props = {
  projectId: string;
  scenarioId: string;
};
export const useGetScenario = ({ projectId, scenarioId }: Props) => {
  const query = useQuery(GET_SCENARIO, { variables: { projectId, scenarioId } });
  return query;
};
