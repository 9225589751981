import { useApolloClient } from "@apollo/client";
import { ArrowLeft } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { Button } from "Components/Basic/Buttons";
import { ConditionalWrapper } from "Components/Basic/ConditionalWrapper";
import TextInput from "Components/Basic/Form/TextInput";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useFirebaseCloudInstance } from "Components/Providers/Firebase/FirebaseManager";
import { useCreateOrganization } from "api/organisation/createOrganization";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useGlobalDispatch } from "store";
import Text from "thermo/typography/Typography";
import * as Yup from "yup";
import AnimatePageTransition from "./utils/AnimatePageTransition";

type FormProps = {
  organizationName: string;
  slug: string;
};

const validationSchema = Yup.object({
  organizationName: Yup.string()
    .required("Name is required")
    .trim()
    .min(1, "Name must be at least one character long"),
  slug: Yup.string()
    .trim()
    .max(64, "The URL cannot be longer than 64 characters")
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      "The URL must only contain alphanumerical characters"
    ),
});

export const CreateOrganization: React.FC = () => {
  const navigate = useNavigateApp();
  const dispatch = useGlobalDispatch();
  const client = useApolloClient();

  const organizationSlug = useOrganizationSlugFromParams();
  const { permissions } = useFirebaseCloudInstance();
  const [createOrganization, { loading: creatingOrganization }] = useCreateOrganization();
  const [error, setError] = useState("");

  const cannotCreateOrganizationWhenSigningUp =
    !organizationSlug && permissions?.disallowSignUpOrganizationCreation;
  const cannotCreateOrganizationWhenLoggedIn =
    organizationSlug && permissions?.disallowLoggedInOrganizationCreation;

  const submitForm = async (values: FormProps) => {
    if (cannotCreateOrganizationWhenSigningUp) {
      toast.error(
        "This environment has disabled organization creation while signing up. Contact your administrator to be assigned an organization."
      );
      return;
    }

    if (cannotCreateOrganizationWhenLoggedIn) {
      toast.error(
        "This environment has disabled organization creation. Contact your administrator to be assigned an organization."
      );
      return;
    }

    const result = await createOrganization({
      variables: {
        name: values.organizationName,
        slug: values.slug,
        description: "",
      },
    });
    const createdOrganization = result.data?.organizationCreate;

    if (createdOrganization?.__typename === "OrganizationSlugNotAvailable") {
      setError("This slug is not available. Please select another one.");
    }

    if (createdOrganization?.__typename === "OrganizationSlugInvalid") {
      setError("This slug is invalid. Please select another one.");
    }
    if (createdOrganization?.__typename === "Organization") {
      client.clearStore().then(() => {
        dispatch({ type: "SET_ACTIVE_WORKSPACE_ID", payload: null });
        navigate(`/${createdOrganization.slug}`, { useAsCompletePath: true, replace: true });
      });
    }
  };

  if (cannotCreateOrganizationWhenLoggedIn || cannotCreateOrganizationWhenSigningUp)
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center">
        <div>
          <button
            data-testid="backButton"
            type="button"
            onClick={() => navigate(-1)}
            className="mb-4 flex w-16 self-start items-center text-indigo-900"
          >
            <ArrowLeft className="mr-1" weight="bold" />
            <span className="font-base">Back</span>
          </button>
          <Text.H4>
            {cannotCreateOrganizationWhenLoggedIn &&
              "This environment does not allow creating a new organization. Contact your administrator to be invited to an organization."}
            {cannotCreateOrganizationWhenSigningUp &&
              "This environment does not allow creating a new organization when signing up. Contact your administrator to be invited to an organization."}
          </Text.H4>
        </div>
      </div>
    );

  return (
    <ConditionalWrapper
      condition={!organizationSlug}
      wrapper={(children) => (
        <div className="w-full h-screen flex flex-col justify-center items-center">
          {children}
        </div>
      )}
    >
      <AnimatePageTransition title="Create organization">
        <Formik
          initialValues={{
            organizationName: "",
            slug: "",
          }}
          onSubmit={(values, { resetForm }) => {
            submitForm(values);
            resetForm();
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form
              data-testid="signupForm"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <div className="mb-4 w-full">
                <div className="w-5/12 min-w-[30rem] space-y-4">
                  <div>
                    <TextInput
                      type="text"
                      data-testid="organizationName"
                      label="Organization name"
                      name="organizationName"
                      autoFocus
                    />
                  </div>
                  <div>
                    <TextInput
                      type="text"
                      data-testid="slug"
                      label="Organization URL"
                      name="slug"
                    />
                  </div>
                  {error && (
                    <motion.span
                      initial={{ y: -4, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      className="text-sm font-medium text-red-700"
                    >
                      {error}
                    </motion.span>
                  )}
                </div>
              </div>
              <div className="flex gap-3">
                <Button
                  type="submit"
                  data-testid="submitButton"
                  loading={creatingOrganization}
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                  buttonType="primary"
                >
                  Create organization
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AnimatePageTransition>
    </ConditionalWrapper>
  );
};
