import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

type Props = {
  searchValue: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  placeholder?: string;
};

/**
 * An input component used in @tanstack/table components that performs global filtering
 * @param searchValue The value to search for in the table.
 * @param onChange OnChange function for setting value in parent component
 * @param debounce Amount of time (ms) to wait before performing onChange function
 */
export const TableTextFilter = ({
  searchValue: initialValue,
  onChange,
  debounce,
  placeholder,
}: Props) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <div className="relative h-10 min-w-[18em] rounded-md  shadow-sm ">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlass weight="bold" className="h-4 w-4 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="tableTextFilter"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        className="block h-full w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder={placeholder || "Search..."}
        data-testid="textTableFilterInput"
      />
    </div>
  );
};
