import { WarningOctagon } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import ActionModal from "Components/Basic/ActionModal";
import { useDeleteProject } from "api/project/deleteProject";
import { Project } from "model/datatypes";
import toast from "react-hot-toast";

interface Props {
  project: Project;
  hideModal: () => void;
}

const DeleteProjectModal = ({ project, hideModal }: Props) => {
  const navigate = useNavigateApp();

  const [deleteProject, { loading: deletingProject }] = useDeleteProject();

  return (
    <ActionModal
      saveButtonName="Delete project"
      onSave={() => {
        deleteProject({ variables: { projectId: project.id } })
          .then(() => {
            navigate("/workspaces");
          })
          .catch((err) => {
            toast.error("An error occurred while deleting the project");
            console.error(err);
          })
          .finally(() => {
            hideModal();
          });
      }}
      loading={deletingProject}
      onClose={hideModal}
    >
      <div data-testid="blockRouteModal" className="flex px-6 py-7">
        <div className="flex items-center justify-end pr-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
            <WarningOctagon
              weight="regular"
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="">
          <div className="mb-2 text-lg font-normal leading-6 text-gray-900">
            Delete project
          </div>
          <div className="text-sm text-gray-500">
            Are you sure you want to delete this project?
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default DeleteProjectModal;
