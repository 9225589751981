import { useUpdateWorkspaceInformation } from "api/workspace/updateWorkspaceInformation";
import React, { useState } from "react";
import { Button } from "thermo/button/Button";

type Props = {
  isWorkspaceOwner: boolean;
  workspaceName: string;
  workspaceId: string;
};

export const WorkspaceNameSection = ({
  isWorkspaceOwner,
  workspaceName,
  workspaceId,
}: Props) => {
  const [updateWorkspaceName, { loading: updatingWorkspaceName }] =
    useUpdateWorkspaceInformation();
  const [editedWorkspaceName, setEditedWorkspaceName] = useState(workspaceName);

  return (
    <div data-testid="workspaceNameSection">
      <div className="flex items-center justify-between">
        <div>
          <div className="mb-1 text-sm">Name</div>
          <div className="mb-1 text-sm text-slate-600">Edit the name of your workspace.</div>
        </div>
        {isWorkspaceOwner && (
          <Button
            onClick={() =>
              updateWorkspaceName({
                variables: { workspaceId, name: editedWorkspaceName },
              })
            }
            size="sm"
            data-testid="updateWorkspaceNameButton"
            loading={updatingWorkspaceName}
          >
            Update
          </Button>
        )}
      </div>
      <input
        type="text"
        disabled={!isWorkspaceOwner}
        value={editedWorkspaceName}
        onChange={(e) => setEditedWorkspaceName(e.target.value)}
        data-testid="workspaceUpdateNameInputField"
        className="mt-2 block w-60 min-w-0 flex-1 rounded-md border border-gray-200 px-3 py-2 text-sm disabled:text-gray-500 text-gray-700 focus:ring-0"
      />
    </div>
  );
};
