/* eslint-disable react/destructuring-assignment */
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { ReactNode, forwardRef } from "react";
import { LinkProps, Link as RouterLink } from "react-router-dom";

interface BaseProps extends LinkProps {
  to: string;
  children: ReactNode;
  openInNewTab?: true;
}

interface CompletePathProps extends BaseProps {
  useAsCompletePath?: boolean;
  appendToCurrentPath?: never;
}

interface AppendPathProps extends BaseProps {
  useAsCompletePath?: never;
  appendToCurrentPath?: boolean;
}

type Props = CompletePathProps | AppendPathProps;

/**
 * Link component that enables in-app navigation
 *
 * @param to The path to navigate to. Will by default be prefixed by the organization slug unless passing `useAsCompletePath`
 * @param useAsCompletePath Uses the passed `to` as the complete path to navigate to. Use to prevent prefixing the path with the organization slug
 * @param appendToCurrentPath Appends the passed `to` to the path pre-navigation
 * @param openInNewTab Opens the passed `to` in a new browser tab
 * @example
 * // url: dev.numerous.cloud/orgSlug/tools
 *
 * return (
 *   <Link to="administration">link 1</Link>
 *   // new url: dev.numerous.cloud/orgSlug/administration
 *   <Link to="/administration">link 2</Link>
 *   // new url: dev.numerous.cloud/orgSlug/administration
 *   <Link to="toolId" appendToCurrentPath>link 3</Link>
 *   // new url: dev.numerous.cloud/orgSlug/tools/toolId
 *   <Link to="login" useAsCompletePath>link 4</Link>
 *   // new url: dev.numerous.cloud/login
 * )
 */
const Link = forwardRef<HTMLAnchorElement, Props>(function Link(props, ref) {
  const organizationSlug = useOrganizationSlugFromParams();
  const {
    to,
    children,
    className,
    openInNewTab,
    useAsCompletePath,
    appendToCurrentPath,
    ...rest
  } = props;
  let parsedTo = to[0] === "/" ? to.slice(1) : to;

  if (useAsCompletePath) {
    parsedTo = `/${parsedTo}`;
  } else if (appendToCurrentPath) {
    parsedTo = `${window.location.pathname}/${parsedTo}`;
  } else {
    parsedTo = `/${organizationSlug}/${parsedTo}`;
  }

  return (
    <RouterLink
      {...rest}
      ref={ref}
      to={parsedTo}
      className={className}
      {...(openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : {})}
    >
      {children}
    </RouterLink>
  );
});

export default Link;
