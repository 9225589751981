import Modal from "Components/Basic/Modal";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Button } from "thermo/button/Button";

const Legal = () => {
  const [showLegalModal, setShowLegalModal] = useState(false);
  return (
    <>
      <Button size="sm" borderless onClick={() => setShowLegalModal(true)}>
        Legal
      </Button>
      <AnimatePresence>
        {showLegalModal && (
          <Modal onClose={() => setShowLegalModal(false)}>
            <div className="flex h-40 flex-col items-center justify-around">
              <a
                className="block text-indigo-800"
                href="https://storage.googleapis.com/numerous-legal/terms_and_conditions.html"
                target="_blank"
                rel="noreferrer"
              >
                Terms and conditions
              </a>

              <a
                className="block text-indigo-800"
                href="https://storage.googleapis.com/numerous-legal/policy_1.html"
                target="_blank"
                rel="noreferrer"
              >
                View privacy policy
              </a>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default Legal;
