import React from "react";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";
import { useNetworkStatus } from "../OfflineIndicator/NetworkStatus";
import { OfflineIndicator } from "../OfflineIndicator/OfflineIndicator";
import { OrganizationPicker } from "./OrganizationPicker/OrganizationPicker";
import { UserProfile } from "./UserProfile/UserProfile";

export const OrganizationManager = () => {
  const { isOnline } = useNetworkStatus();
  return (
    <div
      className={classNames(
        getZIndexClassName("zNavigation"),
        `sticky top-0 w-full bg-white h-14 border-b border-gray-200 flex py-3 px-4`
      )}
    >
      <div className="flex-auto flex justify-between items-center h-full w-full gap-2">
        <OrganizationPicker />
        <div className="flex items-center">
          <OfflineIndicator isOnline={isOnline} />
          <UserProfile />
        </div>
      </div>
    </div>
  );
};
