import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useCreateConnectedRepository } from "api/repository/createConnectedRepository";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import toast from "react-hot-toast";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";

type Props = {
  repositoryBeingLinkedTo: string | undefined;
  selectedRemoteRepositoryId: string | null;
  repositoryProvider: string;
};

export const ConnectRemoteRepository = ({
  repositoryBeingLinkedTo,
  selectedRemoteRepositoryId,
  repositoryProvider,
}: Props) => {
  const navigate = useNavigateApp();
  const [createConnectedRepository] = useCreateConnectedRepository();

  const connectRepository = () => {
    if (!selectedRemoteRepositoryId) return;
    createConnectedRepository({
      variables: {
        remoteRepositoryId: selectedRemoteRepositoryId,
        remoteRepositoryProvider: repositoryProvider,
      },
    })
      .then((res) => {
        if (
          res.data &&
          res.data.connectedRepositoryCreate.__typename === "ConnectedRepository"
        ) {
          const repositoryId = res.data.connectedRepositoryCreate.id;
          const { lastCommit } = res.data.connectedRepositoryCreate;
          toast.success("The repository has been connected to numerous");
          navigate(
            `/repositories/${repositoryId}${
              lastCommit.__typename === "Commit" ? `/${lastCommit.id}` : ""
            }`,
            { replace: true }
          );
          return;
        }
        if (
          res.data?.connectedRepositoryCreate.__typename === "RemoteRepositoryAlreadyConnected"
        ) {
          console.error(res.data.connectedRepositoryCreate.remoteRepositoryId);
          toast.error("The repository is already connected to numerous");
          return;
        }
        if (
          res.data?.connectedRepositoryCreate.__typename ===
          "RemoteRepositoryProviderNotSupported"
        ) {
          console.error(
            res.data.connectedRepositoryCreate.unsupportedRemoteRepositoryProvider
          );
          toast.error("The repository provider is not supported");
          return;
        }

        if (res.data?.connectedRepositoryCreate.__typename === "RemoteRepositoryNotFound") {
          console.error(res.data.connectedRepositoryCreate.remoteRepositoryId);
          toast.error("The repository could not be found");
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="relative">
      <Button
        disabled={!selectedRemoteRepositoryId}
        onClick={connectRepository}
        size="md"
        data-testid="addRepositoryButton"
      >
        Add repository
      </Button>
      <AnimatePresence>
        {repositoryBeingLinkedTo && (
          <motion.div
            className="absolute top-8"
            initial={{ y: -4, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -4, opacity: 0 }}
          >
            <Text.Small className="mt-1 text-gray-700">
              This will connect the repository{" "}
              <span data-testid="repositoryDisplayName" className="font-medium">
                {repositoryBeingLinkedTo}
              </span>{" "}
              to numerous.
            </Text.Small>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
