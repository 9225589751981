import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { OrganizationMemberRole } from "GraphQL/gql/graphql";
import { User, useGetCurrentUser } from "api/user/getCurrentUser";
import { Pinned, useGetPins } from "api/user/getPins";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useGlobalDispatch } from "store";
// eslint-disable-next-line import/no-cycle
import { useApolloClientContext } from "../Apollo/GraphQLProvider";

export type ExtendedUser = {
  activeOrganizationRole: OrganizationMemberRole | undefined;
  activeOrganizationId: string;
  pinned: Pinned;
} & User;

const UserContext = createContext<ExtendedUser | null>(null);

const UserProvider = ({
  children,
  activeOrganizationRole,
}: {
  children: ReactNode;
  activeOrganizationRole: OrganizationMemberRole | undefined;
}) => {
  const dispatch = useGlobalDispatch();
  const { data, loading, error } = useGetCurrentUser();
  const activeOrganizationId = useActiveOrganizationId() || "";
  const activeOrganizationSlug = useOrganizationSlugFromParams();
  const { apolloClientInfo, setApolloClientInfo } = useApolloClientContext();
  const {
    data: pinData,
    loading: fetchingPins,
    error: errorFetchingPins,
  } = useGetPins({ shouldSkip: !apolloClientInfo?.organizationSlug });

  useEffect(() => {
    if (!activeOrganizationSlug) return;
    if (apolloClientInfo?.organizationSlug !== activeOrganizationSlug)
      setApolloClientInfo((prev) =>
        prev ? { ...prev, organizationSlug: activeOrganizationSlug } : prev
      );

    if (activeOrganizationSlug !== localStorage.getItem("activeOrganizationSlug"))
      localStorage.setItem("activeOrganizationSlug", activeOrganizationSlug);
  }, [
    dispatch,
    activeOrganizationSlug,
    apolloClientInfo?.organizationSlug,
    setApolloClientInfo,
  ]);

  if (loading || fetchingPins) return <FullScreenLoadingOverlay />;
  if (error) throw Error("There was an issue authenticating");
  if (errorFetchingPins) {
    console.error(errorFetchingPins);
  }
  const user: ExtendedUser | null = data?.me
    ? {
        ...data.me,
        activeOrganizationId,
        activeOrganizationRole,
        pinned: pinData?.me.pinned || [],
      }
    : null;

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

const useGlobalUser = () => {
  const context = useContext(UserContext);

  if (!context)
    throw new Error(
      "The global user was not available or is attempted to be accessed outside of protected routes"
    );

  return context;
};

export { UserProvider, useGlobalUser, UserContext };
