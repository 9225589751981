import Modal from "Components/Basic/Modal";
import { Workspace } from "api/workspace/getWorkspaces";
import { WorkspaceDescriptionSection } from "./DescriptionSection/WorkspaceDescriptionSection";
import { MemberManagementSection } from "./MemberManagement/MemberManagementSection";
import { WorkspaceNameSection } from "./NameSection/WorkspaceNameSection";

type WorkspaceSettingsProps = {
  close: () => void;
  workspace: Workspace;
  isWorkspaceOwner: boolean;
};

const WorkspaceSettings = ({ close, workspace, isWorkspaceOwner }: WorkspaceSettingsProps) => {
  return (
    <Modal onClose={close} className="w-[600px] text-slate-900">
      <div data-testid="workspaceSettingsModalHeader" className="text-xl truncate flex-1">
        {workspace.name}
      </div>

      <div className="mb-1 mt-1 text-sm text-slate-600">Manage your workspace settings</div>

      <div className="my-5 h-px bg-gray-200" />

      <div className="flex gap-4 flex-col">
        <WorkspaceNameSection
          isWorkspaceOwner={isWorkspaceOwner}
          workspaceId={workspace.id}
          workspaceName={workspace.name}
        />

        <WorkspaceDescriptionSection
          isWorkspaceOwner={isWorkspaceOwner}
          workspaceId={workspace.id}
          workspaceDescription={workspace.description}
        />
        <div className="h-px bg-gray-200" />
        <MemberManagementSection
          workspace={workspace}
          isWorkspaceOwner={isWorkspaceOwner}
          closeModal={close}
        />
      </div>
    </Modal>
  );
};

export default WorkspaceSettings;
