export const mapToObject: any = (map: any) => {
  if (!(map instanceof Map)) return map;
  return Object.fromEntries(
    Array.from(map.entries(), ([k, v]) => {
      if (v instanceof Array) {
        return [k, v.map((value) => mapToObject(value))];
      }
      if (v instanceof Map) {
        return [k, mapToObject(v)];
      }
      return [k, v];
    })
  );
};
