import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_OrganizationQuery } from "GraphQL/gql/graphql";

export const GET_ORGANIZATION = graphql(`
  query GET_ORGANIZATION {
    organization {
      __typename
      id
      logoUrl
      name
      description
      slug
    }
  }
`);

export const useGetOrganization = () => {
  const result = useQuery(GET_ORGANIZATION);
  return result;
};

export type Organization = Get_OrganizationQuery["organization"];
