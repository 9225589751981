import { Link as LinkIcon } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import { TopBarWrapper } from "Components/TopBar/TopBarWrapper";
import { useGetGitlabStatus } from "api/integrations/getGitlabStatus";
import { useDeferredValue, useState } from "react";
import { Button } from "thermo/button/Button";
import { SearchBar } from "thermo/searchbar/SearchBar";
import NotConnectedToGitLab from "../NotConnectedToGitLab";
import RepositoriesTable from "./RepositoriesTable";

export const RepositoriesOverview = () => {
  const [repoSearchTerm, setRepoSearchTerm] = useState("");
  const navigate = useNavigateApp();
  const deferredRepoSearchTerm = useDeferredValue(repoSearchTerm);

  const { data, loading } = useGetGitlabStatus();
  const isConnectedToGitlab = data?.me.gitlabIntegration?.__typename === "GitLabUser";

  if (loading) return <LoadingOverlay />;
  if (!isConnectedToGitlab) return <NotConnectedToGitLab />;

  return (
    <div>
      <TopBarWrapper pageTitle="Repositories">
        <div className="flex items-center gap-2">
          <SearchBar
            searchValue={repoSearchTerm}
            setSearchValue={setRepoSearchTerm}
            placeholder="Search repository titles"
          />

          <Button
            data-testid="addRepository"
            onClick={() => navigate("/repositories/add-repository")}
            icon={<LinkIcon size={14} />}
            size="sm"
          >
            Add repository
          </Button>
        </div>
      </TopBarWrapper>
      <RepositoriesTable searchTerm={deferredRepoSearchTerm} />
    </div>
  );
};
