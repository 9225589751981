import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const GET_GITLAB_STATUS = graphql(`
  query GET_GITLAB_STATUS {
    me {
      id
      gitlabIntegration {
        id
        username
      }
    }
  }
`);

export const useGetGitlabStatus = () => {
  const result = useQuery(GET_GITLAB_STATUS);
  return result;
};
