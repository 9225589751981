import { WarningOctagon } from "@phosphor-icons/react";
import ActionModal from "Components/Basic/ActionModal";
import { Button } from "Components/Basic/Buttons";
import { Input } from "Components/Basic/Input";
import { useDeleteWorkspace } from "api/workspace/deleteWorkspace";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useGlobalDispatch } from "store";

type Props = {
  workspaceId: string;
  workspaceName: string;
};

const DeleteWorkspace = ({ workspaceId, workspaceName }: Props) => {
  const [deleteWorkspace, { loading: deletingWorkspace }] = useDeleteWorkspace();
  const [showDeleteWorkspaceModal, setShowDeleteWorkspaceModal] = useState(false);
  const [inputName, setInputName] = useState("");

  const dispatch = useGlobalDispatch();

  const confirmDeleteValidationText = `delete ${workspaceName}`;

  return (
    <>
      <div>Delete workspace</div>
      <p className="mt-2 text-sm text-gray-700">
        <span className="font-medium">Warning:</span> Deleting the workspace will permanently
        delete any projects associated with it. This action cannot be reversed.
      </p>
      <Button
        onClick={() => setShowDeleteWorkspaceModal(true)}
        className="mt-4"
        buttonType="warning"
        data-testid="deleteWorkspaceButton"
      >
        Delete workspace
      </Button>
      <AnimatePresence>
        {showDeleteWorkspaceModal && (
          <ActionModal
            saveButtonName="Delete"
            loading={deletingWorkspace}
            onSave={async () => {
              if (confirmDeleteValidationText !== inputName) return;
              await deleteWorkspace({ variables: { workspaceId } })
                .then(() => {
                  dispatch({ type: "SET_ACTIVE_WORKSPACE_ID", payload: null });
                  setShowDeleteWorkspaceModal(false);
                })
                .catch((err) => {
                  console.error(err);
                  toast.error("The workspace could not be deleted");
                })
                .finally(() => {
                  setShowDeleteWorkspaceModal(false);
                });
            }}
            disableConfirmButtonIf={confirmDeleteValidationText !== inputName}
            onClose={() => setShowDeleteWorkspaceModal(false)}
          >
            <div data-testid="blockRouteModal" className="flex px-6 py-7">
              <div className="flex items-center justify-end pr-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
                  <WarningOctagon
                    weight="regular"
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="">
                <div
                  data-testid="deleteWorkspaceHeader"
                  className="mb-2 text-lg font-normal leading-6 text-gray-900"
                >
                  Delete workspace
                </div>
                <div className="text-sm text-gray-500">
                  Are you sure you want to delete this workspace? This action cannot be
                  reversed. All projects in this workspace will be permanently deleted.
                </div>

                <div className="mt-2 text-sm font-medium text-gray-500">
                  Please enter{" "}
                  <span className="text-gray-700" data-testid="deleteWorkspaceValidationText">
                    {confirmDeleteValidationText}
                  </span>{" "}
                  to continue
                </div>
                <Input
                  autoFocus
                  data-testid="workspaceDeleteNameInputField"
                  className="mt-2"
                  value={inputName}
                  onChange={(e) => setInputName(e.target.value)}
                />
              </div>
            </div>
          </ActionModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default DeleteWorkspace;
