import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Organization_Pending_InvitationsQuery } from "GraphQL/gql/graphql";
import { ORGANIZATION_PENDING_INVITATIONS } from "api/organisation/getPendingOrganizationInvitations";

const ORGANIZATION_INVITATION_CREATE = graphql(`
  mutation ORGANIZATION_INVITATION_CREATE($role: OrganizationMemberRole!, $email: String!) {
    organizationInvitationCreate(email: $email, role: $role) {
      id
      email
      role
      createdAt
      isExpired
      expiresAt
      organization {
        id
      }
    }
  }
`);

export const useUserInvitationCreate = () => {
  const mutation = useMutation(ORGANIZATION_INVITATION_CREATE, {
    update(cache, result) {
      const invitation = result.data?.organizationInvitationCreate;

      if (!invitation) {
        console.error(
          "The invitation id or organization id was not available - cancelling cache update."
        );
        return;
      }
      cache.updateQuery(
        {
          query: ORGANIZATION_PENDING_INVITATIONS,
        },
        (cacheData) => {
          if (!cacheData) return undefined;

          const updatedUserInvitations = [
            invitation,
            ...cacheData.organization.pendingInvitations,
          ];

          const updatedOrganisationInvitations: Organization_Pending_InvitationsQuery = {
            ...cacheData,
            organization: {
              ...cacheData.organization,
              pendingInvitations: updatedUserInvitations,
            },
          };

          return updatedOrganisationInvitations;
        }
      );
    },
  });
  return mutation;
};
