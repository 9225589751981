import { ArrowLeft } from "@phosphor-icons/react";
import TextInput from "Components/Basic/Form/TextInput";
import { useResetPasswordSendEmail } from "api/user/resetPasswordSendEmail";
import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { Button } from "thermo/button/Button";
import * as Yup from "yup";

const validationSchema = Yup.object({
  resetPasswordEmail: Yup.string().email("Email must be valid").required("Email is required"),
});

type Props = {
  setResettingPassword: () => void;
};

export const ResetPasswordForm = ({ setResettingPassword }: Props) => {
  const [resetPasswordSendEmail, { loading: sendingPasswordResetEmail }] =
    useResetPasswordSendEmail();
  return (
    <>
      <div className="mt-4">
        <Formik
          initialValues={{
            resetPasswordEmail: "",
          }}
          onSubmit={(values, { resetForm }) => {
            resetPasswordSendEmail({ variables: { email: values.resetPasswordEmail } })
              .then(() => {
                toast.success("Password reset email sent - please check your email");
                setResettingPassword();
              })
              .catch((err) => console.error(err));
            resetForm();
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form data-testid="resetPasswordForm" className="gap-4 flex flex-col">
              <TextInput
                id="resetPasswordEmail"
                name="resetPasswordEmail"
                type="email"
                data-testid="passwordResetEmail"
                label="Email"
                autoComplete="email"
              />

              <Button
                size="lg"
                fullWidth
                variant="brandColor"
                loading={sendingPasswordResetEmail}
                type="submit"
                data-testid="resetPasswordButton"
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
              >
                Reset password
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mt-4 flex items-center justify-center">
        <button
          onClick={setResettingPassword}
          className="flex items-center text-sm text-indigo-600"
        >
          <ArrowLeft className="mr-2" weight="bold" /> Back to login
        </button>
      </div>
    </>
  );
};
