import { FlowArrow, GitMerge, Tag, Trash } from "@phosphor-icons/react";
import { ActivityType } from "api/repository/getRepositoryActivity";
import React from "react";

type Props = {
  type: ActivityType;
};

export const ActivityIcon = ({ type }: Props) => {
  switch (type) {
    case "RepositoryActivityDeleteBranch":
      return <Trash weight="fill" className="h-4 w-4 text-error-600" aria-hidden="true" />;
    case "RepositoryActivityPushNewBranch":
      return <FlowArrow className="h-4 w-4 text-gray-600" aria-hidden="true" />;
    case "RepositoryActivityPushNewTag":
      return <Tag weight="fill" className="h-4 w-4 text-gray-400" aria-hidden="true" />;
    case "RepositoryActivityPushToBranch":
      return <GitMerge weight="fill" className="h-4 w-4 text-gray-600" aria-hidden="true" />;
    default:
      return <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />;
  }
};
