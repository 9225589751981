import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import { Table } from "@tanstack/react-table";
import React from "react";

interface ITablePagination {
  table: Table<any>;
}

const TablePagination: React.FC<ITablePagination> = ({ table }) => {
  const {
    previousPage,
    nextPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageIndex,
    getPageCount,
    getState,
    options: { data },
  } = table;

  if (data.length < 11) return null;

  return (
    <div className="sticky flex items-center justify-between rounded-b-md border-t border-gray-200 bg-white px-2 py-3">
      <div className="flex flex-1 items-center justify-between">
        <p className="ml-2 text-sm font-medium text-gray-600">
          Page {getState().pagination.pageIndex + 1} of {getPageCount()}
        </p>
        <div>
          <nav
            className="relative z-0 inline-flex items-center -space-x-px rounded-md text-sm shadow-sm"
            aria-label="Pagination"
          >
            <div className="mr-2">
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="block h-9 rounded-md border-gray-300 text-sm focus:outline-none"
              >
                {[10, 50, 100].map((pageSize) => (
                  <option className="text-sm" key={pageSize} value={pageSize}>
                    Show {pageSize} rows
                  </option>
                ))}
              </select>
            </div>

            <button
              disabled={getState().pagination.pageIndex === 0}
              onClick={() => {
                setPageIndex(0);
              }}
              className="relative inline-flex h-9 items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-200"
            >
              <span className="sr-only">First</span>
              <CaretDoubleLeft weight="bold" className="mx-1 h-3 w-3" aria-hidden="true" />
            </button>
            <button
              disabled={!getCanPreviousPage()}
              onClick={() => {
                previousPage();
              }}
              className="relative inline-flex h-9 items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-200"
            >
              <span className="sr-only">Previous</span>
              <CaretLeft weight="bold" className="mx-1 h-3 w-3" aria-hidden="true" />
            </button>

            <span className="relative z-10 inline-flex h-9 cursor-auto items-center border-b border-t border-gray-300 px-4 py-2 text-sm text-gray-600">
              {getState().pagination.pageIndex + 1}
            </span>

            <button
              disabled={!getCanNextPage()}
              onClick={() => {
                nextPage();
              }}
              className="relative inline-flex h-9 items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-200"
            >
              <span className="sr-only">Next</span>
              <CaretRight weight="bold" className="mx-1 h-3 w-3" aria-hidden="true" />
            </button>
            <button
              disabled={getState().pagination.pageIndex === getPageCount() - 1}
              onClick={() => {
                setPageIndex(getPageCount() - 1);
              }}
              className="relative inline-flex h-9 items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-200"
            >
              <span className="sr-only">Last</span>
              <CaretDoubleRight weight="bold" className="mx-1 h-3 w-3" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
