import TextInput from "Components/Basic/Form/TextInput";
import { useUserSignUp } from "api/user/signup";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Alert } from "thermo/Alert/Alert";
import { Button } from "thermo/button/Button";
import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "The password must have a minimum of 6 characters"),
  email: Yup.string().required("Email is required").email("Email must be valid"),
});

type Props = {
  invitationEmail: string | null;
  setUserFinishedSignUp: () => void;
  failedToFetchInvitation: boolean;
};

export const UserSignUpForm = ({
  invitationEmail,
  setUserFinishedSignUp,
  failedToFetchInvitation,
}: Props) => {
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [userSignUp, { loading: sendingSignUpRequest }] = useUserSignUp();

  return (
    <div className="flex flex-col justify-center items-center">
      {formErrorMessage && (
        <div className="mb-4 w-full">
          <Alert type="error" text={formErrorMessage} />
        </div>
      )}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          email: invitationEmail || "",
        }}
        onSubmit={(values) =>
          userSignUp({
            variables: {
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
            },
          })
            .then((res) => {
              switch (res.data?.userSignUp.__typename) {
                case "EmailAlreadyInUse":
                  setFormErrorMessage("This email is unavailable");
                  return;
                case "EmailFormatInvalid":
                  setFormErrorMessage("The email format is invalid");
                  return;
                case "UserSignUpWeakPassword":
                  setFormErrorMessage("The password must be 6 characters or more");
                  return;
                case "UserSignUpActivationEmailSent":
                  setFormErrorMessage("");
                  setUserFinishedSignUp();
                  break;
                default:
                  break;
              }
            })
            .catch((err) => {
              toast.error("There was a problem creating the account, please try again.");
              setFormErrorMessage("");
              console.error(err);
            })
        }
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form data-testid="signupForm" className="space-y-3 w-full">
            <div>
              <TextInput
                autoComplete="given-name"
                data-testid="firstNameSignup"
                label="First name"
                name="firstName"
              />
            </div>
            <div>
              <TextInput
                data-testid="lastNameSignup"
                autoComplete="family-name"
                label="Last name"
                name="lastName"
              />
            </div>

            <div>
              <TextInput
                autoComplete="email"
                data-testid="signupEmailInput"
                label="Email"
                name="email"
                disabled={!!invitationEmail}
              />
            </div>
            <div>
              <TextInput
                autoComplete="new-password"
                data-testid="passwordSignup"
                type="password"
                label="Password"
                name="password"
              />
            </div>
            <div>
              <Button
                type="submit"
                loading={sendingSignUpRequest}
                data-testid="submitSignUpButton"
                disabled={
                  !formik.isValid ||
                  !formik.dirty ||
                  formik.isSubmitting ||
                  failedToFetchInvitation
                }
                size="lg"
                variant="brandColor"
                fullWidth
              >
                Create account
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
