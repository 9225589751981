import { useGetRepositoryActivity } from "api/repository/getRepositoryActivity";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Alert } from "thermo/Alert/Alert";
import { Button } from "thermo/button/Button";
import classNames from "utils/jsUtils/className";
import { ActivityItem } from "./ActivityItem";
import { LoadingActivityList } from "./LoadingActivityList";

export const ActivityList = () => {
  const { repositoryID } = useParams<{ repositoryID: string }>();
  const [loadingMoreActivityItems, setLoadingMoreActivityItems] = useState(false);

  const { data, loading, error, fetchMore } = useGetRepositoryActivity({
    first: 10,
    repositoryId: repositoryID || "",
    after: null,
    skip: !repositoryID,
  });

  if (loading) return <LoadingActivityList />;
  if (error)
    return (
      <Alert type="error" text="There was an issue getting the recent repository activity" />
    );

  const repositoryActivities = data?.repositoryActivities.edges;

  return (
    <div className="space-y-8 overflow-y-scroll">
      <ul className="space-y-3">
        {repositoryActivities?.map(({ node }, activityItemIdx) => (
          <li
            key={node.activityDate.toString() + node.userName + node.__typename}
            className="relative flex gap-x-2"
          >
            <div
              className={classNames(
                activityItemIdx === repositoryActivities.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            <ActivityItem activity={node} />
          </li>
        ))}
      </ul>
      {data?.repositoryActivities.pageInfo.hasNextPage && (
        <div className="flex justify-center w-full">
          <Button
            onClick={() => {
              setLoadingMoreActivityItems(true);
              fetchMore({
                variables: {
                  after: data.repositoryActivities.pageInfo.endCursor,
                },
              })
                .catch((err) => {
                  toast.error("There was an issue while getting more activities");
                  console.error(err);
                })
                .finally(() => {
                  setLoadingMoreActivityItems(false);
                });
            }}
            loading={loadingMoreActivityItems}
            size="sm"
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};
