import { useEffect } from "react";
import NLogo from "resources/NLogo";

interface Props {
  logoUrl?: string | null;
}

export const OrganizationLogo = ({ logoUrl = null }: Props) => {
  useEffect(() => {
    if (!logoUrl) return;
    const favIconElements = Array.from(document.getElementsByClassName("favicon"));
    favIconElements.forEach((element) => element.setAttribute("href", logoUrl));
  }, [logoUrl]);

  if (!logoUrl) return <NLogo className="h-[14px] fill-indigo-600" />;
  return (
    <img
      src={logoUrl}
      className="h-[14px] aspect-square"
      alt="The logo for this user's organization"
    />
  );
};
