import { ArrowSquareOut, Trash } from "@phosphor-icons/react";
import { Row } from "@tanstack/react-table";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useDeleteFile } from "api/file/deleteFile";
import { useIdToken } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import { getDownloadFileUrl } from "grpc/api/grpcUtilAPI";
import { SimFile } from "model/datatypes";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useGlobalState } from "store";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import { downloadFileFromUrl } from "utils/downloadFileFromUrl";
import { ExtendedFile } from "./types";

interface IFileTableActions {
  row: Row<ExtendedFile>;
}

const FileTableActions = ({ row }: IFileTableActions) => {
  const [deletingFile, setDeletingFile] = useState(false);
  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();
  const [file, setFile] = useState<SimFile>();
  const organizationSlug = useOrganizationSlugFromParams();
  const [deleteFile] = useDeleteFile();
  const downloadFile = useCallback(
    async (fileToOpen: SimFile) => {
      if (!idToken || !organizationSlug) return;
      try {
        const url = await getDownloadFileUrl(
          grpcURL,
          idToken,
          organizationSlug,
          fileToOpen.path
        );
        downloadFileFromUrl(url, fileToOpen.name);
      } catch (error: any) {
        toast.error(error);
        console.error(error);
      }
    },
    [grpcURL, idToken, organizationSlug]
  );

  return (
    <div>
      <PopoverMenu placement="left-start">
        <Popover.Button
          onClick={() => downloadFile(row.original.simFile)}
          icon={<ArrowSquareOut width="100%" height="100%" />}
        >
          Download file
        </Popover.Button>
        <Popover.Button
          onClick={() => {
            setDeletingFile(true);
            setFile(row.original.simFile);
          }}
          className="text-red-500"
          icon={<Trash width="100%" height="100%" />}
        >
          Delete file
        </Popover.Button>
      </PopoverMenu>
      <AnimatePresence>
        {deletingFile && (
          <ConfirmModal
            warning
            description={`This will remove ${file?.name} from the system permanently`}
            onConfirm={() => {
              if (!file) return;
              deleteFile({
                variables: { fileId: file.id },
              })
                .then(() => {
                  setDeletingFile(false);
                })
                .catch((err) => {
                  console.error(err);
                  toast.error("The file could not be deleted");
                });
            }}
            onCancel={() => setDeletingFile(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default FileTableActions;
