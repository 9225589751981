import { CaretDown, Link as LinkIcon } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import ParagraphLineClamp from "Components/Basic/ParagraphLineClamp";
import { ConnectedRepositorySortBy, SortOrder } from "GraphQL/gql/graphql";
import { useConnectedRepositoriesSearch } from "api/repository/connectedRepositoriesSearch";
import { useEffect, useState } from "react";
import { Alert } from "thermo/Alert/Alert";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";
import RepositoryRow from "./RepositoryRow";

type Props = {
  searchTerm: string;
};

const RepositoriesTable = ({ searchTerm }: Props) => {
  const navigate = useNavigateApp();
  const [sortBy, setSortBy] = useState<ConnectedRepositorySortBy>(
    ConnectedRepositorySortBy.DisplayName
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Ascending);

  //* If 'searchTerm' is empty, 'data' shows all connected repositories
  const {
    data: connectedRepositoriesSearchResults,
    loading,
    error,
    fetchMore,
    refetch,
  } = useConnectedRepositoriesSearch({
    query: searchTerm.trim(),
    sortBy,
    sortOrder,
  });

  const connectedRepositories =
    connectedRepositoriesSearchResults?.connectedRepositoriesSearch.edges;

  useEffect(() => {
    if (!connectedRepositoriesSearchResults) return;
    const { hasNextPage: canFetchMore, endCursor } =
      connectedRepositoriesSearchResults.connectedRepositoriesSearch.pageInfo;

    if (!canFetchMore || endCursor === undefined) return;

    fetchMore({
      variables: {
        after: endCursor,
      },
    }).catch(console.error);
  }, [connectedRepositoriesSearchResults, fetchMore]);

  const handleColumnClick = (column: ConnectedRepositorySortBy) => {
    if (column !== sortBy) {
      setSortBy(column);
      setSortOrder(SortOrder.Ascending);
      refetch({ sortBy: column });
    } else {
      setSortOrder((prev) =>
        prev === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending
      );
      refetch({
        sortOrder:
          sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
      });
    }
  };

  if (error) {
    console.error(error);
    return (
      <div className="pt-44 w-full px-12">
        <Alert
          text="An error occurred while loading repositories."
          subText="Try refreshing the page or report the issue if the error persists."
          type="error"
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="sticky top-14 grid py-2 bg-gray-50 select-none px-4 border-b border-gray-200"
        style={{
          gridTemplateColumns: "3fr 4fr 3fr 2fr minmax(4em, 1fr)",
        }}
      >
        <Text.Small
          className="flex items-center gap-2 w-fit cursor-pointer"
          onClick={() => handleColumnClick(ConnectedRepositorySortBy.DisplayName)}
          data-testid="titleColumnHeader"
        >
          Title
          {sortBy === ConnectedRepositorySortBy.DisplayName && (
            <div className="p-1 rounded bg-gray-100">
              <CaretDown className={sortOrder === SortOrder.Ascending ? "rotate-180" : ""} />
            </div>
          )}
          {loading && sortBy === ConnectedRepositorySortBy.DisplayName && <LoadingIcon />}
        </Text.Small>
        <Text.Small className="flex items-center">
          Path
        </Text.Small>
        <Text.Small
          className="flex items-center gap-2 w-fit cursor-pointer"
          onClick={() => handleColumnClick(ConnectedRepositorySortBy.CreatedBy)}
          data-testid="createdByColumnHeader"
        >
          <ParagraphLineClamp maxLines={1}>Added by</ParagraphLineClamp>
          {sortBy === ConnectedRepositorySortBy.CreatedBy && (
            <div className="p-1 rounded bg-gray-100">
              <CaretDown className={sortOrder === SortOrder.Ascending ? "rotate-180" : ""} />
            </div>
          )}
          {loading && sortBy === ConnectedRepositorySortBy.CreatedBy && <LoadingIcon />}
        </Text.Small>
        <Text.Small
          className="flex items-center gap-2 w-fit cursor-pointer"
          onClick={() => handleColumnClick(ConnectedRepositorySortBy.UpdatedAt)}
          data-testid="lastUpdatedColumnHeader"
        >
          <ParagraphLineClamp maxLines={1}>Last updated</ParagraphLineClamp>
          {sortBy === ConnectedRepositorySortBy.UpdatedAt && (
            <div className="p-1 rounded bg-gray-100">
              <CaretDown className={sortOrder === SortOrder.Ascending ? "rotate-180" : ""} />
            </div>
          )}
          {loading && sortBy === ConnectedRepositorySortBy.UpdatedAt && <LoadingIcon />}
        </Text.Small>
      </div>

      <div className="flex flex-col divide-y divide-gray-200">
        {connectedRepositories &&
          connectedRepositories.length > 0 &&
          connectedRepositories.map(({ node }) => (
            <RepositoryRow key={node.id} repository={node} />
          ))}
        {!loading && !connectedRepositories?.length && (
          <div
            className="flex items-center justify-center flex-col mt-44 gap-4"
            data-testid="noRepositoriesFound"
          >
            <Text.Base className="text-gray-600">No repositories found{searchTerm ? " matching search" : ""}</Text.Base>
            <Button
              size="lg"
              variant="brandColor"
              icon={<LinkIcon size={16} color="white" />}
              onClick={() => navigate("/repositories/add-repository")}
            >
              Add repository
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepositoriesTable;
