import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import TextInput from "Components/Basic/Form/TextInput";
import { useUserResetPassword } from "api/user/userResetPassword";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import NumerousLogo from "resources/NumerousLogo";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";
import * as Yup from "yup";

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required("A new password is required")
    .min(6, "The password must be at least 6 characters long"),
});

type FormProps = {
  newPassword: string;
};

export const PerformPasswordReset = () => {
  const [resetPassword, { loading: resettingPassword }] = useUserResetPassword();
  const { resetToken } = useParams<{ resetToken: string }>();
  const navigate = useNavigateApp();

  const submitForm = (values: FormProps, { resetForm }: FormikHelpers<FormProps>) => {
    if (!resetToken) {
      toast.error(
        "There was an issue authenticating the reset password request - please try again"
      );
      console.error("The reset token was not defined or invalid", resetToken);
      return;
    }
    resetPassword({ variables: { newPassword: values.newPassword, resetToken } })
      .then((res) => {
        if (res.data?.userResetPassword.__typename === "UserPasswordResetExpired") {
          return toast.error(
            "The password reset request has expired - please request a new reset password email"
          );
        }

        if (res.data?.userResetPassword.__typename === "UserPasswordResetInvalid") {
          return toast.error(
            "The password reset request was invalid - please request a new reset password email"
          );
        }
        toast.success("Password has been reset");
        return navigate("/login", { useAsCompletePath: true });
      })
      .catch((err) => {
        console.error(err);
      });
    resetForm();
  };

  return (
    <div className="bg-white w-screen h-screen justify-center items-center flex flex-col relative ">
      <div className="w-96">
        <div className="mb-6 justify-start flex flex-col">
          <NumerousLogo className="mb-6 w-32 -ml-1.5" />
          <Text.H1 className="text-gray-800 font-semibold">Reset password</Text.H1>
          <Text.Small className="text-gray-700 mt-1">Choose your new password</Text.Small>
        </div>

        <div className="mt-4">
          <Formik
            initialValues={{
              newPassword: "",
            }}
            onSubmit={submitForm}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form data-testid="resetPasswordForm" className="gap-4 flex flex-col">
                <TextInput
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  label="New password"
                  autoComplete="new-password"
                />

                <Button
                  size="lg"
                  fullWidth
                  variant="brandColor"
                  loading={resettingPassword}
                  type="submit"
                  data-testid="resetPasswordButton"
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                >
                  Reset password
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
