import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Button } from "./Buttons";
import Modal from "./Modal";
import { UnsavedStateModal } from "./UnsavedStateModal";

interface ActionModalProps {
  onClose: (calledFromCancelBtn?: boolean) => void;
  onSave: () => void;
  open?: boolean;
  blockNavigateAwayIf?: boolean;
  className?: string;
  saveButtonName?: string;
  cancelButtonName?: string;
  disableConfirmButtonIf?: boolean;
  canOverflow?: true;
  loading?: boolean;
  saveButtonType?: "submit";
  hideCancelButton?: boolean;
  buttonType?: "primary" | "secondary" | "tertiary" | "white" | "small" | "warning";
}

const ActionModal: React.FC<React.PropsWithChildren<ActionModalProps>> = ({
  onClose,
  onSave,
  saveButtonName = "Save",
  cancelButtonName = "Cancel",
  open,
  children,
  className,
  disableConfirmButtonIf,
  blockNavigateAwayIf,
  loading,
  saveButtonType,
  hideCancelButton,
  buttonType = "primary",
  ...htmlProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleConfirmUnsavedNavigation = () => {
    setShowUnsavedModal(false);
    onClose();
  };

  const handleClose = (calledFromCancelBtn: boolean) => {
    if (blockNavigateAwayIf) {
      setShowUnsavedModal(true);
    } else {
      setShowUnsavedModal(false);
      onClose(calledFromCancelBtn);
    }
  };

  const handleSave = () => {
    onSave();
  };

  const handleCancel = () => {
    setShowUnsavedModal(false);
  };

  return (
    <Modal
      onClose={() => handleClose(false)}
      open={isOpen}
      noPadding
      className={className}
      {...htmlProps}
    >
      <>
        <div>{children}</div>
        <div className="flex justify-end  rounded-b-lg bg-gray-50 p-3">
          {!hideCancelButton && (
            <Button
              onClick={() => handleClose(true)}
              data-testid="actionModalCancel"
              buttonType="secondary"
            >
              {cancelButtonName}
            </Button>
          )}
          <Button
            data-testid="actionModalConfirm"
            onClick={handleSave}
            className="ml-2"
            type={saveButtonType}
            buttonType={buttonType}
            disabled={disableConfirmButtonIf}
            loading={loading}
            autoFocus
          >
            {saveButtonName}
          </Button>
        </div>
        <AnimatePresence>
          {showUnsavedModal && (
            <UnsavedStateModal
              confirmUnsafeNavigation={handleConfirmUnsavedNavigation}
              cancel={handleCancel}
            />
          )}
        </AnimatePresence>
      </>
    </Modal>
  );
};

export default ActionModal;
