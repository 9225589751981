import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PaginatedProjectsQuery } from "GraphQL/gql/graphql";
import { PAGINATED_PROJECTS } from "./getPaginatedProjects";

const DELETE_PROJECT = graphql(`
  mutation DELETE_PROJECT($projectId: ID!) {
    projectDelete(projectId: $projectId) {
      ... on Project {
        __typename
        id
        workspaceId
      }
    }
  }
`);

export const useDeleteProject = () => {
  const mutation = useMutation(DELETE_PROJECT, {
    update(cache, mutationData) {
      const deletedProject =
        mutationData.data?.projectDelete.__typename === "Project" &&
        mutationData.data.projectDelete;
      if (!deletedProject || deletedProject.workspaceId === undefined) return;

      cache.updateQuery(
        {
          query: PAGINATED_PROJECTS,
          variables: {
            workspaceId: deletedProject.workspaceId,
          },
        },
        (data) => {
          if (!data) return data;

          const updatedProjectEdges = data.workspace.projects.edges.filter(
            (edge) => edge.node.id !== deletedProject.id
          );

          const updatedPaginatedProjects: PaginatedProjectsQuery = {
            ...data,
            workspace: {
              ...data.workspace,
              projects: {
                ...data.workspace.projects,
                edges: updatedProjectEdges,
              },
            },
          };

          return updatedPaginatedProjects;
        }
      );
    },
  });

  return mutation;
};
