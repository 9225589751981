import {
  PushPin,
  DownloadSimple,
  Link as LinkIcon,
  Trash,
  UploadSimple,
  UsersThree,
} from "@phosphor-icons/react";
import ProjectCollaboration from "Components/Project/ProjectCollaboration/ProjectCollaboration";
import DeleteProjectModal from "Components/Projects/DeleteProjectModal";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useArchiveProject } from "api/project/archiveProject";
import { usePinProject } from "api/project/pinProject";
import { useUnarchiveProject } from "api/project/unarchiveProject";
import { useUnpinProject } from "api/project/unpinProject";
import { AnimatePresence } from "framer-motion";
import { Project } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import { useCopyProjectLinkToClipboard } from "utils/clipboard/copyProjectLinkToClipboard";
import TopBarButton from "./TopBarButton";

type Props = {
  project: Project;
};

const ProjectBarOptions = ({ project }: Props) => {
  const [editingCollaborators, setEditingCollaborators] = useState(false);
  const [deletingProject, setDeletingProject] = useState(false);
  const { copyProjectLinkToClipboard } = useCopyProjectLinkToClipboard({
    projectID: project.id,
  });
  const user = useGlobalUser();
  const [pinProject] = usePinProject();
  const [unpinProject] = useUnpinProject();
  const projectIsPinned = user?.pinned?.some(({ id }) => id === project.id);

  const togglePinProject = () => {
    if (projectIsPinned) {
      unpinProject({ variables: { projectId: project.id } }).catch((err) =>
        console.error(err)
      );
    } else {
      pinProject({ variables: { projectId: project.id } })
        .then((res) => {
          if (res.data?.userPinProject.__typename === "ProjectNotFound") {
            toast.error("The project could not be pinned");
            console.error("The project could not be found");
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const hasDeleteAccess = user.id === project.ownerId;

  const [archiveProject] = useArchiveProject();
  const [unarchiveProject] = useUnarchiveProject();

  return (
    <div className="flex items-center">
      <TopBarButton
        onClick={togglePinProject}
        data-testid="togglePinProject"
        icon={
          <PushPin
            weight={projectIsPinned ? "fill" : "bold"}
            size={16}
            className={`mr-2 ${projectIsPinned && "text-indigo-500"}`}
          />
        }
        className="hidden sm:flex"
        buttonText={`${projectIsPinned ? "Unpin" : "Pin"}`}
      />
      <TopBarButton
        onClick={() => copyProjectLinkToClipboard()}
        icon={<LinkIcon className="mr-2 h-5 w-5" />}
        buttonText="Copy link"
        className="hidden md:flex"
      />
      <TopBarButton
        onClick={() => setEditingCollaborators(true)}
        icon={<UsersThree className="mr-2 h-5 w-5" />}
        buttonText="Collaborate"
        data-testid="viewCollaborators"
        className="hidden lg:flex"
      />
      <TopBarButton
        onClick={() => {
          if (project.archived) {
            unarchiveProject({ variables: { projectId: project.id } }).catch((err) => {
              console.error(err);
              toast.error("The project could not be unarchived");
            });
          } else {
            archiveProject({ variables: { projectId: project.id } }).catch((err) => {
              console.error(err);
              toast.error("The project could not be archived");
            });
          }
        }}
        icon={
          project.archived ? (
            <UploadSimple width="100%" height="100%" className="mr-2 h-5 w-5" weight="bold" />
          ) : (
            <DownloadSimple
              width="100%"
              height="100%"
              className="mr-2 h-5 w-5"
              weight="bold"
            />
          )
        }
        buttonText={`${project.archived ? "Unarchive" : "Archive"}`}
        data-testid="archiveProject"
        className="hidden lg:flex"
      />
      <div className={`${!hasDeleteAccess ? "lg:hidden" : ""}`}>
        <PopoverMenu placement="bottom-end">
          <Popover.Button
            data-testid="togglePinProject"
            icon={
              <PushPin
                weight={projectIsPinned ? "fill" : "bold"}
                size={16}
                className={`${projectIsPinned && "text-indigo-500"}`}
              />
            }
            className="sm:hidden"
            onClick={() => togglePinProject()}
          >
            {projectIsPinned ? "Unpin" : "Pin"}
          </Popover.Button>
          <Popover.Button
            icon={<LinkIcon height="100%" width="100%" />}
            className="md:hidden"
            onClick={() => copyProjectLinkToClipboard()}
          >
            Copy link
          </Popover.Button>
          <Popover.Button
            data-testid="viewCollaborators"
            icon={<UsersThree width="100%" height="100%" />}
            className="lg:hidden"
            onClick={() => setEditingCollaborators(true)}
          >
            Collaborate
          </Popover.Button>
          <hr className="lg:hidden" />
          <Popover.Button
            data-testid="archiveProject"
            icon={
              project.archived ? (
                <UploadSimple width="100%" height="100%" weight="bold" />
              ) : (
                <DownloadSimple width="100%" height="100%" weight="bold" />
              )
            }
            className="lg:hidden"
            onClick={() => archiveProject()}
          >
            {project.archived ? "Unarchive" : "Archive"}
          </Popover.Button>
          {hasDeleteAccess && (
            <Popover.Button
              data-testid="deleteProject"
              icon={<Trash width="100%" height="100%" />}
              className="text-red-600"
              onClick={() => setDeletingProject(true)}
            >
              Delete project
            </Popover.Button>
          )}
        </PopoverMenu>
      </div>

      <AnimatePresence>
        {editingCollaborators && (
          <ProjectCollaboration
            project={project}
            onClose={() => setEditingCollaborators(false)}
          />
        )}
        {deletingProject && (
          <DeleteProjectModal
            project={project}
            hideModal={() => setDeletingProject((prev) => !prev)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectBarOptions;
