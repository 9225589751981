import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useIdToken } from "api/useFirebase";
import { deleteSystemModel } from "grpc/api/grpcUtilAPI";
import { useState } from "react";
import toast from "react-hot-toast";
import { useGlobalState } from "store";

export const useDeleteSystem = (systemID: string, type: string) => {
  const [deletingSystem, setDeletingSystem] = useState(false);
  const idToken = useIdToken();
  const { grpcURL } = useGlobalState();
  const activeOrganizationSlug = useOrganizationSlugFromParams();
  const deleteSystem = async () => {
    if (!systemID) {
      toast.error(`Could not find the ID of the ${type}.`);
      return;
    }
    if (!idToken) {
      toast.error("Could not verify the user. Please try again later.");
      return;
    }
    if (!activeOrganizationSlug) {
      toast.error("Could not verify the organization. Please try again later.");
      return;
    }

    if (deletingSystem) return;
    try {
      setDeletingSystem(true);
      const promise = deleteSystemModel(
        grpcURL,
        idToken,
        activeOrganizationSlug,
        systemID
      ).response;
      toast
        .promise(promise, {
          success: `The ${type} has been deleted.`,
          loading: `Deleting the ${type}`,
          error: `Could not delete the ${type}. Please try again later.`,
        })
        .catch(console.error);
    } catch (error) {
      console.error(error);
    } finally {
      setDeletingSystem(false);
    }
  };

  return { deletingSystem, deleteSystem };
};
