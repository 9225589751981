import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PAGINATED_PROJECTS } from "./getPaginatedProjects";

const PIN_PROJECT = graphql(`
  mutation PIN_PROJECT($projectId: ID!) {
    userPinProject(projectId: $projectId) {
      ... on User {
        ...PinnedItems
      }
    }
  }
`);

export const usePinProject = () => {
  const mutation = useMutation(PIN_PROJECT, {
    update(cache, mutationResult, { variables }) {
      const activeWorkspaceId = localStorage.getItem("activeWorkspaceId");
      const projectIdToUpdate = variables?.projectId;

      if (!mutationResult || !projectIdToUpdate) return;

      cache.updateQuery(
        {
          query: PAGINATED_PROJECTS,
          variables: {
            workspaceId: activeWorkspaceId,
          },
        },
        (cacheData) => {
          if (!cacheData) return cacheData;

          const updatedWorkspaceProjects = structuredClone(cacheData);
          updatedWorkspaceProjects.workspace.projects.edges.map((edge) => {
            if (edge.node.id === projectIdToUpdate) {
              const tempEdge = edge;
              tempEdge.node.isPinned = true;
              return tempEdge;
            }
            return edge;
          });
          return updatedWorkspaceProjects;
        }
      );
    },
  });

  return mutation;
};
