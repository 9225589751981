import classNames from "utils/jsUtils/className";
import * as zIndexes from "./zIndexValues";

type ZIndexType = keyof typeof zIndexes;
type ZIndexClassNames = `z-${(typeof zIndexes)[ZIndexType]}`;

export const getZIndexClassName = (type: ZIndexType): ZIndexClassNames =>
  `z-${zIndexes[type]}`;

interface IBoxy {
  type: ZIndexType;
  displayName: string;
  className: string;
}

const ZIndexBox = ({ type, displayName, className }: IBoxy) => {
  const zIndexClassName = getZIndexClassName(type);
  return (
    <div
      className={classNames(
        `flex items-start p-2 drop-shadow-xl rounded h-32 w-32 absolute`,
        zIndexClassName,
        className
      )}
    >
      <span className="text-sm tracking-wider text-gray-900 font-semibold uppercase">
        {displayName}
      </span>
    </div>
  );
};

export default ZIndexBox;
