import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PAGINATED_DATASETS } from "./getPaginatedDataSets";

const ADD_SCHEDULE_DATA_SET = graphql(`
  mutation ADD_SCHEDULE_DATA_SET(
    $projectId: ID!
    $scheduledDataSetInput: ScheduledDataSetInput!
  ) {
    dataSetCreateScheduled(
      projectId: $projectId
      scheduledDataSetInput: $scheduledDataSetInput
    ) {
      __typename
      id
      index
      name
      projectId
      tags
      schedules {
        ...ScheduleFragment
      }
    }
  }
`);

export const useAddScheduleDataSet = () => {
  const mutation = useMutation(ADD_SCHEDULE_DATA_SET, {
    update(cache, { data }, { variables }) {
      if (!data || !variables) return;
      const createdDataSet = data?.dataSetCreateScheduled;
      if (!createdDataSet) return;

      cache.updateQuery(
        { query: PAGINATED_DATASETS, variables: { projectId: variables.projectId } },
        (projectQuery) => {
          if (!projectQuery || !projectQuery.project?.dataSets) return projectQuery;
          let updatedProjectQuery = structuredClone(projectQuery);
          updatedProjectQuery.project?.dataSets.edges.unshift({
            __typename: "AbstractDataSetEdge",
            cursor: createdDataSet.id,
            node: createdDataSet,
          });
          return updatedProjectQuery;
        }
      );
    },
  });

  return mutation;
};
