import { X } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { SidebarButtonWrapper } from "Components/Sidebar/SidebarButtonWrapper";
import { Pinned } from "api/user/getPins";
import React from "react";
import { useLocation } from "react-router-dom";
import ContextMenu from "thermo/ContextMenu/ContextMenu";
import Text from "thermo/typography/Typography";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";
import { PinnedItemContextMenu } from "./PinnedItemContextMenu";

type Props = {
  pinnedItem: ArrayElement<Pinned>;
  itemRoute: string;
  icon: JSX.Element;
  unpinFn: () => void;
};

export const PinnedItem = ({ pinnedItem, itemRoute, icon, unpinFn }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigateApp();
  return (
    <ContextMenu.Wrapper
      menuContent={<PinnedItemContextMenu unpinFn={unpinFn} itemRoute={itemRoute} />}
    >
      <SidebarButtonWrapper isActive={itemRoute === pathname}>
        <div className="px-2 py-1 flex justify-between items-center">
          <button
            onClick={() => {
              navigate(itemRoute);
            }}
            className="flex w-full relative items-center select-none"
          >
            <span
              data-testid="pinnedItemIcon"
              className="transition-all duration-100 group-hover/sidebar:text-gray-900"
            >
              {icon}
            </span>
            <Text.Base data-testid="pinnedItemName" className="ml-2 truncate text-left flex-1">
              {pinnedItem.name}
            </Text.Base>
          </button>
          <X
            onClick={unpinFn}
            size={12}
            className="text-gray-500 mr-px hover:text-gray-900 opacity-0 group-hover/item:opacity-100 transition-opacity duration-75"
          />
        </div>
      </SidebarButtonWrapper>
    </ContextMenu.Wrapper>
  );
};
