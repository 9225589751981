import { HTMLAttributes, ReactNode } from "react";
import { regexMatchTailwindPropertyWithColor } from "utils/jsUtils/regexMatchTailwindPropertyColor";
import { usePopoverContext } from "./PopoverContext";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  disableCloseOnClick?: boolean;
  icon?: ReactNode;
  children: ReactNode;
}

const PopoverButton = ({
  disableCloseOnClick,
  icon,
  children,
  onClick,
  className = "",
  ...htmlProps
}: Props) => {
  const { closePopover } = usePopoverContext();

  const textColor = regexMatchTailwindPropertyWithColor("text", className)
    ? ""
    : "text-gray-700";

  return (
    <button
      {...htmlProps}
      onClick={(e) => {
        onClick?.(e);
        if (!disableCloseOnClick) closePopover();
      }}
      className={`${className} ${textColor} rounded flex w-full flex-row gap-2 py-2.5 px-2 text-sm transition-colors duration-75 hover:bg-gray-100 focus:outline-none`}
    >
      {icon && (
        <div className="inline-flex h-4 w-4 content-center items-center justify-center">
          {icon}
        </div>
      )}
      {children}
    </button>
  );
};

export default PopoverButton;
