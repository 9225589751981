import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import {
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getYear,
  isAfter,
  isSameDay,
  isToday,
  set,
  sub,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import Popover from "thermo/popover/Popover";
import classNames from "utils/jsUtils/className";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const WEEKDAYS = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

interface Props {
  initialDate: Date;
  onUpdateDateTime: (updated: Date) => void;
  label?: string;
  excludeDatesAfter?: Date;
}
/**
 * A generic date time picker component
 * @param initialDate (optional) Initial date to use
 * @param onUpdateDateTime Function to update state in parent component
 * @param label (optional) Text shown in label to the left of input
 * @param excludeDatesAfter (optional) inactivate and grey-out all dates after this date.
 */
const DateTimePicker: React.FC<Props> = ({
  initialDate,
  onUpdateDateTime,
  label,
  excludeDatesAfter = null,
}) => {
  const [showInvalidTimeInputError, setShowInvalidTimeInputError] = useState(false);

  const [currentView, setCurrentView] = useState<"days" | "months" | "years">("days");
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(initialDate);

  const selectedYear = getYear(selectedDateTime);

  const [currentYear, setCurrentYear] = useState(getYear(initialDate));
  const [currentMonth, setCurrentMonth] = useState(getMonth(initialDate));

  // Update state if props change
  useEffect(() => {
    setSelectedDateTime(initialDate);
    setCurrentYear(getYear(initialDate));
    setCurrentMonth(getMonth(initialDate));
  }, [initialDate]);

  const handleUpdateDateTime = (
    update:
      | {
          year?: number;
          month?: number;
          date?: number;
          hours?: number;
          minutes?: number;
        }
      | Date
  ) => {
    const updatedDate = update instanceof Date ? update : set(selectedDateTime, update);
    setSelectedDateTime(updatedDate);
    onUpdateDateTime(updatedDate);
  };

  const decadeInYearView = useMemo(() => {
    return [...Array(10).keys()].map((i) => i + Math.floor(currentYear / 10) * 10);
  }, [currentYear]);

  const getNumberOfDaysInMonth = (year: number, month: number) => {
    return getDaysInMonth(new Date(year, month, 1));
  };

  const createDaysForCurrentMonth = (year: number, month: number) => {
    return [...Array(getNumberOfDaysInMonth(year, month))].map((day, index) => {
      return {
        date: new Date(year, month, index + 1),
        dayOfMonth: index + 1,
        isCurrentMonth: true,
      };
    });
  };

  let currentMonthDays = createDaysForCurrentMonth(currentYear, currentMonth);

  const createDaysForPreviousMonth = (year: number, month: number) => {
    const firstWeekDayInMonth = getDay(currentMonthDays[0].date);
    const previousMonth = getMonth(new Date(year, month, 1)) - 1;

    // Account for first day of the month on a Sunday (firstDayOfTheMonthWeekday === 0)
    const visibleNumberOfDaysFromPreviousMonth = firstWeekDayInMonth
      ? firstWeekDayInMonth - 1
      : 6;

    const previousMonthLastMondayDayOfMonth = sub(currentMonthDays[0].date, {
      days: visibleNumberOfDaysFromPreviousMonth,
    });

    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
      return {
        date: new Date(
          year,
          previousMonth,
          getMonth(previousMonthLastMondayDayOfMonth) + index
        ),
        dayOfMonth: getDate(previousMonthLastMondayDayOfMonth) + index,
        isCurrentMonth: false,
      };
    });
  };

  let previousMonthDays = createDaysForPreviousMonth(currentYear, currentMonth);
  function createDaysForNextMonth(year: number, month: number) {
    const lastDayOfTheMonthWeekday = getDay(new Date(year, month, currentMonthDays.length));

    const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
      ? 7 - lastDayOfTheMonthWeekday
      : lastDayOfTheMonthWeekday;

    return [...Array(visibleNumberOfDaysFromNextMonth)].map((_, index) => {
      return {
        date: new Date(year, month + 1, index + 1),
        dayOfMonth: index + 1,
        isCurrentMonth: false,
      };
    });
  }

  let nextMonthDays = createDaysForNextMonth(currentYear, currentMonth);

  let days = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];

  return (
    <Popover.Root>
      <Popover.Trigger>
        <div data-testid="dateTimeTriggerButton" className="flex h-8 rounded-md">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 select-none bg-gray-50 px-3 text-sm text-gray-500">
            {label || "Time"}
          </span>
          <span
            data-testid="dateTimeChosenDate"
            className={classNames(
              `block w-full min-w-0 flex-1 rounded-r-md border border-gray-300 bg-white px-3 py-1.5 text-sm focus:border-indigo-500 focus:ring-indigo-500`
            )}
          >
            {`${format(selectedDateTime, "PPP - HH:mm")}`}
          </span>
        </div>
      </Popover.Trigger>
      <Popover.Content>
        <div className="w-[20rem] rounded-lg bg-white p-4">
          <div>
            {/* Top controls */}
            <div className="flex items-center justify-between">
              <div className="flex">
                <button
                  type="button"
                  data-testid="doubleLeftCaret"
                  onClick={() => {
                    if (currentView === "years") {
                      setCurrentYear((year) => year - 9);
                    }
                    setCurrentYear((year) => year - 1);
                  }}
                  className="p-1.5 text-gray-500 hover:text-gray-600"
                >
                  <CaretDoubleLeft size={18} weight="bold" aria-hidden="true" />
                </button>
                {currentView === "days" && (
                  <button
                    type="button"
                    onClick={() => {
                      if (currentMonth === 1) {
                        setCurrentMonth(12);
                        setCurrentYear((year) => year - 1);
                      } else {
                        setCurrentMonth((month) => month - 1);
                      }
                    }}
                    className="p-1.5 text-gray-500 hover:text-gray-600"
                  >
                    <CaretLeft size={18} weight="bold" aria-hidden="true" />
                  </button>
                )}
              </div>

              <div className="space-x-1">
                {currentView !== "years" && (
                  <button
                    data-testid="dateTimeMonthViewSelect"
                    className="hover:text-indigo-700"
                    onClick={() => setCurrentView("months")}
                  >{`${format(currentMonthDays[0].date, "MMMM")}`}</button>
                )}
                <button
                  data-testid="dateTimeYearViewSelect"
                  className="hover:text-indigo-700"
                  onClick={() => setCurrentView("years")}
                >
                  {currentView === "years" &&
                    `${decadeInYearView[0]} - ${decadeInYearView[9]}`}
                  {currentView !== "years" && format(currentMonthDays[0].date, "yyyy")}
                </button>
              </div>
              <div className="flex">
                {currentView === "days" && (
                  <button
                    type="button"
                    onClick={() => {
                      if (currentMonth === 12) {
                        setCurrentMonth(1);
                        setCurrentYear((year) => year + 1);
                      } else {
                        setCurrentMonth((month) => month + 1);
                      }
                    }}
                    className="p-1.5 text-gray-500 hover:text-gray-600"
                  >
                    <CaretRight size={18} weight="bold" aria-hidden="true" />
                  </button>
                )}
                <button
                  type="button"
                  data-testid="doubleRightCaret"
                  onClick={() => {
                    if (currentView === "years") {
                      setCurrentYear((year) => year + 9);
                    }
                    setCurrentYear((year) => year + 1);
                  }}
                  className="p-1.5 text-gray-500 hover:text-gray-600"
                >
                  <CaretDoubleRight size={18} weight="bold" aria-hidden="true" />
                </button>
              </div>
            </div>

            {currentView === "days" && (
              // Weekday headings
              <>
                <div className="mt-2 grid grid-cols-7 text-center text-sm leading-6 text-gray-500">
                  {WEEKDAYS.map((day) => (
                    <div key={day}>{day}</div>
                  ))}
                </div>
                {/* Calendar dates */}
                <div
                  data-testid="calendarDatesView"
                  className="mt-2 grid h-52 grid-cols-7 text-sm"
                >
                  {days.map((day, dayIdx) => {
                    const dateIsToday = isToday(day.date);
                    const isInCurrentMonth = day.isCurrentMonth;
                    const dateIsSelectedDate = isSameDay(day.date, selectedDateTime);
                    const isNotTodayOrSelected = !dateIsSelectedDate && !dateIsToday;
                    return (
                      <div
                        key={day.date.toString()}
                        className={classNames(
                          dayIdx > 6 && "flex items-center justify-center",
                          "py-1"
                        )}
                      >
                        <button
                          data-testid={
                            isToday(day.date) ? "dateTimeButtonToday" : "dateTimeDayButton"
                          }
                          type="button"
                          onClick={() => {
                            if (excludeDatesAfter && isAfter(day.date, excludeDatesAfter)) {
                              return;
                            }
                            const year = day.date.getFullYear();
                            const month = day.date.getMonth();
                            const date = day.dayOfMonth;
                            handleUpdateDateTime({ year, month, date });
                          }}
                          className={classNames(
                            "mx-auto flex h-7 w-7 items-center justify-center rounded-lg",
                            excludeDatesAfter &&
                              isAfter(day.date, excludeDatesAfter) &&
                              "text-gray-300 hover:cursor-not-allowed",
                            !dateIsSelectedDate && "hover:bg-gray-200",
                            !dateIsSelectedDate && dateIsToday && "text-indigo-600",
                            dateIsSelectedDate && "bg-indigo-600 text-white",
                            isNotTodayOrSelected && isInCurrentMonth && "text-gray-900",
                            isNotTodayOrSelected && !isInCurrentMonth && "text-gray-400",
                            (dateIsSelectedDate || dateIsToday) && "font-semibold"
                          )}
                        >
                          <time dateTime={day.date.toString()}>{day.dayOfMonth}</time>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {currentView === "months" && (
              <div
                data-testid="dateTimeMonthOverview"
                className="mt-2 flex h-60 flex-row flex-wrap text-sm"
              >
                {MONTHS.map((month, i) => {
                  return (
                    <button
                      onClick={() => {
                        setCurrentMonth(i);
                        setCurrentView("days");
                      }}
                      data-testid="monthButton"
                      className="flex w-1/3 cursor-pointer items-center justify-center capitalize hover:bg-indigo-100"
                      key={month}
                    >
                      {month.slice(0, 3)}
                    </button>
                  );
                })}
              </div>
            )}

            {currentView === "years" && (
              <div
                data-testid="dateTimeYearView"
                className=" mt-2 flex h-60 flex-row flex-wrap text-sm"
              >
                <button
                  onClick={() => {
                    setCurrentYear(decadeInYearView[0] - 1);
                    setCurrentView("days");
                  }}
                  className="flex w-1/3 cursor-pointer items-center justify-center capitalize text-gray-400 hover:bg-indigo-100"
                >
                  {decadeInYearView[0] - 1}
                </button>
                {decadeInYearView.map((year) => (
                  <button
                    onClick={() => {
                      setCurrentYear(year);
                      setCurrentView("days");
                    }}
                    data-testid="yearButton"
                    key={year}
                    className={classNames(
                      selectedYear === year && "text-indigo-600",
                      "flex w-1/3 cursor-pointer items-center justify-center capitalize hover:bg-indigo-100"
                    )}
                  >
                    {year}
                  </button>
                ))}
                <button
                  onClick={() => {
                    setCurrentView("days");
                    setCurrentYear(decadeInYearView[9] + 1);
                  }}
                  className="flex w-1/3 cursor-pointer items-center justify-center capitalize text-gray-400 hover:bg-indigo-100"
                >
                  {decadeInYearView[9] + 1}
                </button>
              </div>
            )}
            {(!excludeDatesAfter ||
              isAfter(excludeDatesAfter, new Date()) ||
              isSameDay(excludeDatesAfter, new Date())) && (
              <div className="mt-4 flex justify-evenly">
                <button
                  data-testid="setToNow"
                  onClick={() => {
                    handleUpdateDateTime(new Date());
                  }}
                  className="text-sm font-medium text-indigo-600"
                >
                  Set to now
                </button>
              </div>
            )}
            <div className="my-4 h-px w-full bg-slate-200" />
            {/* Time picker */}
            <div className="flex flex-col items-center justify-center">
              <div className="flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  Time
                </span>
                <input
                  type="time"
                  value={format(selectedDateTime, "HH:mm")}
                  data-testid="timeInput"
                  onChange={(e) => {
                    const [hours, minutes] = e.target.value.split(":").map(Number);
                    const updatedDate = set(selectedDateTime, { hours, minutes });
                    if (excludeDatesAfter && isAfter(updatedDate, excludeDatesAfter)) {
                      setShowInvalidTimeInputError(true);
                      return;
                    }
                    handleUpdateDateTime(updatedDate);
                    setShowInvalidTimeInputError(false);
                  }}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              {showInvalidTimeInputError && (
                <div className="mt-1 flex text-sm font-medium text-red-700">
                  Selected time must not be after simulation start time
                </div>
              )}
            </div>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default DateTimePicker;
