import { PeriodTypeInput, ScheduleInput, TimeslotInput } from "GraphQL/gql/graphql";
import { useCallback } from "react";
import { getCleanTimeslots } from "./getCleanTimeslots";

interface Params {
  schedule: ScheduleInput;
  activePeriod: PeriodTypeInput;
}

/**
 * Custom hook that enables mutation of schedules
 *
 * @property {ScheduleInput} schedule - The schedule that should be mutated
 * @property {PeriodTypeInput} activePeriod - The enum value of a PeriodTypeInput
 * @returns Two functions:
 *   - updatePeriodTimeSetting for changing the period time
 *   - removePeriod for deleting a period
 */
const useMutateSchedulePeriod = ({ schedule, activePeriod }: Params) => {
  ////Whenever new time period is input / updated
  const updatePeriodTimeSetting = useCallback(
    (newSlot: TimeslotInput) => {
      //make sure the settings fit full day, then update the settings:
      const currentPeriodSettingIndex = schedule.periodSettings.findIndex(
        (ps) => ps.periodType === activePeriod
      );
      if (currentPeriodSettingIndex < 0) return undefined;
      const currentPeriodSetting = schedule.periodSettings[currentPeriodSettingIndex];
      let { timeslots } = currentPeriodSetting;
      timeslots = getCleanTimeslots(timeslots, newSlot);
      const newPeriodSetting = {
        ...currentPeriodSetting,
        timeslots,
      };

      const updatedSchedule = {
        ...schedule,
        periodSettings: [
          ...schedule.periodSettings.slice(0, currentPeriodSettingIndex),
          newPeriodSetting,
          ...schedule.periodSettings.slice(currentPeriodSettingIndex + 1),
        ],
      };
      return updatedSchedule;
    },
    [schedule, activePeriod]
  );

  const removePeriod = useCallback(
    (timeSlot: TimeslotInput) => {
      const tempUpdatedSchedule = schedule.periodSettings.map((period) => {
        if (period.periodType !== activePeriod) return period;

        const timeslots = period.timeslots.filter((slot) => slot.id !== timeSlot.id);
        return { ...period, timeslots };
      });

      return { ...schedule, periodSettings: tempUpdatedSchedule };
    },
    [schedule, activePeriod]
  );

  return { updatePeriodTimeSetting, removePeriod };
};

export default useMutateSchedulePeriod;
