import { CircleWavyWarning } from "@phosphor-icons/react";
import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.error(error, errorInfo);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      console.error("err");
      // You can render any custom fallback UI
      return (
        <div
          data-testid="pageNotFound"
          className="flex h-screen w-full items-center justify-center"
        >
          <div className="flex flex-col items-center">
            <CircleWavyWarning size={40} className="text-red-800" />
            <h1 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Sorry! The application has crashed.
            </h1>
            <p className="mt-4 text-base text-gray-500">
              Please try to refresh this page. If the problem persists, please report it.
            </p>
          </div>
        </div>
      );
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
