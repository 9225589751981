import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
} from "date-fns";
import { SimTimeUnit } from "model/datatypes";

export const convertRawTimeFromUnitToSeconds = (
  rawTime: number,
  unit: SimTimeUnit
): number => {
  const startTime = new Date();
  const endTime = add(startTime, { [unit]: rawTime });

  return differenceInSeconds(endTime, startTime);
};

const unitToDiffFunction = {
  years: differenceInYears,
  months: differenceInMonths,
  days: differenceInDays,
  hours: differenceInHours,
  minutes: differenceInMinutes,
};

export const convertRawTimeToUnit = (rawTime: number, unit: SimTimeUnit): number => {
  if (unit === "seconds") return rawTime;

  const startTime = new Date();
  const endTime = add(startTime, { seconds: rawTime });
  const diffFunction = unitToDiffFunction[unit];

  return diffFunction(endTime, startTime);
};
