import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Repository_DirectoryQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_REPOSITORY_DIRECTORY = graphql(`
  query GET_REPOSITORY_DIRECTORY($repositoryId: ID!, $commitId: ID!, $path: String!) {
    connectedRepositoryCommit(commitId: $commitId, repositoryId: $repositoryId) {
      ... on Commit {
        __typename
        id
        directory(path: $path) {
          ... on RepositoryDirectory {
            __typename
            name
            path
          }
          ... on RepositoryFile {
            __typename
            name
            path
            content
            lastCommit {
              id
              title
              committedAt
            }
          }
        }
      }
      ... on CommitNotFound {
        __typename
        repositoryId
        commitId
      }
    }
  }
`);

type DirectoryArray = Extract<
  Get_Repository_DirectoryQuery["connectedRepositoryCommit"],
  { __typename: "Commit" }
>["directory"];

type LastCommit = Extract<
  ArrayElement<DirectoryArray>,
  { __typename: "RepositoryFile" }
>["lastCommit"];

export type RepositoryFile = {
  __typename: "RepositoryFile";
  name: string;
  path: string;
  content: string;
  lastCommit: LastCommit;
};

export type RepositoryDirectory = {
  __typename: "RepositoryDirectory";
  name: string;
  path: string;
};

export const useGetRepositoryDirectory = ({
  repositoryId,
  commitId,
  path,
}: {
  repositoryId: string;
  commitId: string;
  path: string;
}) => {
  const result = useQuery(GET_REPOSITORY_DIRECTORY, {
    variables: {
      repositoryId,
      commitId,
      path,
    },
  });

  return {
    ...result,
    data:
      result.data?.connectedRepositoryCommit.__typename === "Commit"
        ? result.data.connectedRepositoryCommit.directory
        : undefined,
  };
};
