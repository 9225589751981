import { getFileManagerClient, getGRPCClient, getOptions } from "grpc/api/grpcClient";
import { FilePath, FilePaths, SystemRequest } from "grpc/client/spm_pb";

export const uploadFile = async (
  grpcAPI: string,
  idToken: string,
  organizationSlug: string,
  path: string,
  file: File
) => {
  const filePath = FilePath.create({
    path,
    contentType: file.type,
  });
  const fileRequest = FilePaths.create({ filePaths: [filePath] });

  const uploadRef = await getFileManagerClient(
    grpcAPI,
    idToken,
    organizationSlug
  ).getUploadUrls(fileRequest, getOptions(idToken, organizationSlug)).response;

  const { url } = uploadRef.urls[0];
  if (!url) throw new Error("No upload URL returned by API"); // a file is likely already saved at the provided path

  try {
    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "multipart/related" },
      mode: "no-cors",
      body: file,
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getDownloadFileUrl = async (
  grpcAPI: string,
  idToken: string,
  organizationSlug: string,
  path: string
) => {
  const filePath = FilePath.create({
    path,
  });
  const fileRequest = FilePaths.create({
    filePaths: [filePath],
  });

  const uploadRef = await getFileManagerClient(
    grpcAPI,
    idToken,
    organizationSlug
  ).getDownloadUrls(fileRequest, getOptions(idToken, organizationSlug)).response;

  const { url } = uploadRef.urls[0];
  return url;
};

export const deleteFile = async (
  grpcAPI: string,
  idToken: string,
  organizationSlug: string,
  path: string
) => {
  const filePath = FilePath.create({
    path,
  });
  const fileRequest = FilePaths.create({
    filePaths: [filePath],
  });

  await getFileManagerClient(grpcAPI, idToken, organizationSlug).deleteFiles(
    fileRequest,
    getOptions(idToken, organizationSlug)
  ).response;
};

export const deleteSystemModel = (
  grpcAPI: string,
  idToken: string,
  organizationSlug: string,
  systemId: string
) => {
  const systemRequest = SystemRequest.create({
    system: systemId,
  });
  return getGRPCClient(grpcAPI, idToken, organizationSlug).deleteSystem(
    systemRequest,
    getOptions(idToken, organizationSlug)
  );
};
