import { GitBranch } from "@phosphor-icons/react";
import React from "react";
import Text from "thermo/typography/Typography";

export const RepositoryConnectionInformation = () => {
  return (
    <div className="w-1/2 select-none">
      <Text.Base className="font-medium text-gray-600">Repositories on numerous</Text.Base>
      <Text.Small className="text-gray-500 mt-1">
        You can search the list of repositories (GitLab projects) that you have access to on
        GitLab. This does not include public repositories that you are not a member of.
      </Text.Small>
      <Text.Small className="text-gray-500 mt-2.5">
        To link a public repository hosted on GitLab input the project ID. You find the project
        ID on GitLab right beneath the project title on the project page. The ID is an 8 digit
        number like <span className="font-semibold">Project ID: 12345678</span>.
      </Text.Small>
      <hr className="my-4 max-w-[65ch]" />
      <Text.Small className="text-gray-500 mt-2.5">
        On the <GitBranch className="inline-block" weight="fill" size={14} /> Repositories page
        you can see all the repositories added in your organization that you are a member of on
        GitLab.
      </Text.Small>
      <Text.Small className="text-gray-400 mt-4 font-medium">Example 1</Text.Small>
      <Text.Small className="text-gray-500 mt-1">
        A colleague of yours has added a GitLab project that you are also member of on GitLab.
        You can see this repository in the list of repositories.
      </Text.Small>
      <Text.Small className="text-gray-400 mt-4 font-medium">Example 2</Text.Small>
      <Text.Small className="text-gray-500 mt-1">
        A colleague of yours has added a public project from GitLab that you are not a member
        of. You will not be able to see this repository in the list of repositories.
      </Text.Small>
    </div>
  );
};
