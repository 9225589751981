import { AnyLayer } from "mapbox-gl";

export const getMapLayers = ({ sourceID }: { sourceID: string }) => {
  const PRIMARY_COLOR = "#4F46E5"; // tailwind indigo-600

  const unclusteredMarkerLayer: AnyLayer = {
    id: "unclustered-markers",
    type: "circle",
    source: sourceID,
    paint: {
      "circle-color": ["get", "color"],
      "circle-radius": ["get", "radius"] || 5,
    },
  };

  const clusteredMarkerLayer: AnyLayer = {
    id: "clustered-markers",
    type: "circle",
    source: sourceID,
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": ["get", "color"],
      "circle-radius": ["get", "radius"] || 5,
    },
  };

  const clusterCircleLayer: AnyLayer = {
    id: "clusters",
    type: "circle",
    source: sourceID,
    filter: ["has", "point_count"],
    paint: {
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * 16px circles if point count < 100
      //   * 25px circles if point count > 100 and < 350
      //   * 35px circles if point count >= 350
      "circle-color": [
        "step",
        ["get", "point_count"],
        PRIMARY_COLOR,
        100,
        PRIMARY_COLOR,
        350,
        PRIMARY_COLOR,
      ],
      "circle-radius": ["step", ["get", "point_count"], 16, 100, 25, 350, 35],
    },
  };

  const clusterCounterLayer: AnyLayer = {
    id: "cluster-count",
    type: "symbol",
    source: sourceID,
    filter: ["has", "point_count"],
    paint: {
      "text-color": "#fff",
    },
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  return {
    unclusteredLayer: [unclusteredMarkerLayer],
    clusteredLayers: [clusteredMarkerLayer, clusterCircleLayer, clusterCounterLayer],
  };
};
