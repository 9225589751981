import { RadioGroup } from "@headlessui/react";
import { useField, FieldHookConfig } from "formik";
import { useState } from "react";
import classNames from "utils/jsUtils/className";

type Props = {
  options: {
    id: string;
    display: string;
    description?: string;
  }[];
  groupLabel?: string;
  setValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export const RadioGroupInput = ({
  options,
  groupLabel,
  setValue,
  ...props
}: Props & FieldHookConfig<string>) => {
  const [selected, setSelected] = useState(options[0]);
  const [field, meta] = useField(props);

  return (
    <RadioGroup
      value={selected}
      onChange={(newSelection) => {
        setSelected(newSelection);
        setValue(field.name, newSelection);
      }}
    >
      {groupLabel && (
        <RadioGroup.Label className="block text-sm font-medium text-gray-700">
          {groupLabel}
        </RadioGroup.Label>
      )}
      <div className="-space-y-px rounded-md bg-white">
        {options.map((option, settingIdx) => (
          <RadioGroup.Option
            {...field}
            key={option.id}
            value={option}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                settingIdx === options.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                checked ? "z-10 border-indigo-200 bg-indigo-50" : "border-gray-200",
                "relative flex cursor-pointer border p-4 focus:outline-none"
              )
            }
            data-testid="radioGroupOption"
          >
            {({ checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? "border-transparent bg-indigo-600" : "border-gray-300 bg-white",
                    "mt-0.5 flex  h-4 w-4 cursor-pointer items-center justify-center rounded-full border"
                  )}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <div className="ml-3 flex w-full shrink flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {option.display}
                  </RadioGroup.Label>
                  {option.description && (
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm"
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
      {meta.touched && meta.error ? (
        <div data-testid="formErrorMessage" className="mt-1 text-sm font-medium text-red-700">
          {meta.error}
        </div>
      ) : null}
    </RadioGroup>
  );
};
