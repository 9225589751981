import { FileCode } from "@phosphor-icons/react";
import ActionModal from "Components/Basic/ActionModal";
import { Button } from "Components/Basic/Buttons";
import { AnimatePresence } from "framer-motion";
import { ComponentParameter, JSONValue } from "model/datatypes";
import React, { useState, useEffect } from "react";
import { deepEqual } from "utils/jsUtils/equality";
import CodeEditor from "../../pythonCode/CodeEditor";

interface Props {
  updateMetadata: (updated: JSONValue) => void;
  parameter: ComponentParameter;
}

const JSONParameterValue = ({ updateMetadata, parameter }: Props) => {
  const json = parameter.value as JSONValue;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jsonText, setJsonText] = useState(json ? JSON.stringify(json, undefined, 4) : "");
  const [isEdited, setIsEdited] = useState(false);
  const [hasError, setHasError] = useState(false);
  const parseJson = (text: string) => {
    if (text.length > 0) {
      try {
        const parsedJSON = JSON.parse(text);
        setError(null);
        updateMetadata(parsedJSON);
      } catch (err) {
        console.error("error parsing:");
        console.error({ text });
        console.error(err);
        setError("Error parsing json");
      }
    }
  };

  useEffect(() => {
    try {
      const newJSON = JSON.parse(jsonText);
      setIsEdited(!deepEqual(newJSON, json));
      setHasError(false);
    } catch {
      setHasError(true);
    }
  }, [jsonText, parameter.value, json]);

  return (
    <div className="relative w-full text-sm">
      <Button
        buttonType="white"
        onClick={() => setOpen(true)}
        className={`flex w-full cursor-pointer items-center truncate
         ${!open && error ? "text-red-500" : ""}`}
      >
        edit JSON
      </Button>
      <AnimatePresence>
        {open && (
          <ActionModal
            key={`${parameter.uuid}-JsonModal`}
            onSave={() => {
              parseJson(jsonText);
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
            disableConfirmButtonIf={!isEdited || hasError}
            blockNavigateAwayIf={isEdited}
            className="w-3/4"
          >
            <div className="flex flex-wrap items-center justify-between border-b border-gray-200 p-6 pb-4 text-lg font-medium capitalize">
              {parameter?.displayName}
              <div className="flex cursor-default items-end">
                <span className="pr-2 text-sm text-indigo-400">JSON</span>
                <FileCode size="18" className="text-indigo-400" />
              </div>
            </div>

            <div className="p-6">
              <CodeEditor
                classNames="h-[60vh]"
                language="json"
                defaultCode={JSON.stringify(parameter?.value) || "{ }"}
                onUpdate={(updated) => setJsonText(updated)}
              />
            </div>
          </ActionModal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default JSONParameterValue;
