import { ApolloProvider } from "@apollo/client";
import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
// eslint-disable-next-line import/no-cycle
import { useIdToken } from "api/useFirebase";
import { ApolloClientInfo, useApolloClient } from "lib/apollo";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useCloudInstance } from "../Firebase/FirebaseProvider";

type Props = {
  children: ReactNode;
  clientInfo: ApolloClientInfo;
};

const Apollo = ({ children, clientInfo }: Props) => {
  const apolloClient = useApolloClient({ clientInfo });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

type GraphQLProviderProps = {
  children: ReactNode;
};

type ClientContext = {
  apolloClientInfo: ApolloClientInfo | null;
  setApolloClientInfo: React.Dispatch<React.SetStateAction<ApolloClientInfo | null>>;
};

const ApolloClientContext = createContext<ClientContext | null>(null);

export const useApolloClientContext = () => {
  const context = useContext(ApolloClientContext);

  if (!context) throw new Error("Cannot access Apollo Client context outside of Provider");

  return context;
};

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const idToken = useIdToken();
  const cloudInstance = useCloudInstance();
  const [apolloClientInfo, setApolloClientInfo] = useState<ApolloClientInfo | null>(null);

  useEffect(() => {
    setApolloClientInfo((prev) => ({
      organizationSlug: null,
      ...prev,
      gqlURL: cloudInstance.graphql_api,
      token: idToken || null,
    }));
  }, [cloudInstance.graphql_api, idToken, setApolloClientInfo]);

  if (!apolloClientInfo || !apolloClientInfo?.gqlURL) return <FullScreenLoadingOverlay />;
  return (
    <Apollo clientInfo={apolloClientInfo}>
      <ApolloClientContext.Provider value={{ apolloClientInfo, setApolloClientInfo }}>
        {children}
      </ApolloClientContext.Provider>
    </Apollo>
  );
};
