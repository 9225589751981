import { FloatingPortal } from "@floating-ui/react-dom-interactions";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import { getTransformOrigin } from "utils/getTransformOrigin";
import { usePopoverContext } from "./PopoverContext";

const getOriginOffset = (origin: ReturnType<typeof getTransformOrigin>) => {
  const offsetPixels = 5;

  const offsetTop = origin.includes("top") ? -offsetPixels : 0;
  const offsetBottom = origin.includes("bottom") ? offsetPixels : 0;
  const offsetLeft = origin.includes("left") ? -offsetPixels : 0;
  const offsetRight = origin.includes("right") ? offsetPixels : 0;

  return {
    translateX: offsetLeft + offsetRight,
    translateY: offsetTop + offsetBottom,
  };
};

interface Props {
  children: ReactNode | ((closePopover: () => void) => ReactNode);
}

const PopoverContent = ({ children }: Props) => {
  const { open, closePopover, contentProps, contentTransformOrigin } = usePopoverContext();

  return (
    <FloatingPortal>
      <AnimatePresence>
        {open && (
          <motion.div
            {...contentProps}
            className="bg-white border border-gray-200 rounded-md p-1 overflow-y-visible elevation-raised"
            initial={{
              opacity: 0,
              scale: 0.95,
              ...getOriginOffset(contentTransformOrigin),
            }}
            animate={{
              translateX: 0,
              translateY: 0,
              opacity: 1,
              scale: 1,
              transition: { duration: 0.12, ease: "easeOut", type: "tween" },
            }}
            exit={{ opacity: 0, transition: { duration: 0.05 } }}
            data-testid="popoverContent"
            onClick={(e) => e.stopPropagation()}
          >
            {typeof children === "function" ? children(closePopover) : children}
          </motion.div>
        )}
      </AnimatePresence>
    </FloatingPortal>
  );
};

export default PopoverContent;
