import { RadioGroup } from "@headlessui/react";
import { Check } from "@phosphor-icons/react";
import ActionModal from "Components/Basic/ActionModal";
import { Input, TextArea } from "Components/Basic/Input";
import DropdownSearch from "Components/Basic/Selector/DropdownSearch";
import { useGetOrganizationLabels } from "api/organisation/getOrganisationLabels";
import { SimulationScenario } from "model/datatypes";
import { useMemo, useState } from "react";
import classNames from "utils/jsUtils/className";
import { useSaveScenarioMark } from "./api/useSaveScenarioMark";
import { LabelNew, LabelOption } from "./types";

type Props = {
  scenarioID: SimulationScenario["id"];
  scenarioName: SimulationScenario["scenarioName"];
  projectID: SimulationScenario["projectID"];
  onFinish: () => void;
};

const createLabelTab = "Creating a label";
const collectionLabelTab = "Selecting from collection";
const tabOptions = [createLabelTab, collectionLabelTab];

const colorOptions = [
  { color: "bg-yellow-300", colorName: "Yellow" },
  { color: "bg-orange-400", colorName: "Orange" },
  { color: "bg-rose-400", colorName: "Rose" },
  { color: "bg-fuchsia-400", colorName: "Fuchsia" },
  { color: "bg-cyan-300", colorName: "Cyan" },
  { color: "bg-blue-400", colorName: "Blue" },
  { color: "bg-lightGreen-400", colorName: "Lime" },
  { color: "bg-teal-500", colorName: "Teal" },
];

const AddScenarioLabel = ({ scenarioID, scenarioName, projectID, onFinish }: Props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedColor, setSelectedColor] = useState(colorOptions[0]);
  const [comment, setComment] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>(createLabelTab);
  const [selectedFromCollection, setSelectedFromCollection] = useState<LabelOption>(
    {} as LabelOption
  );

  const { data: labelCollection } = useGetOrganizationLabels();

  const newLabelObj = { title, description, color: selectedColor.color } as LabelNew;

  const { onSave, savingMark: savingLabel } = useSaveScenarioMark({
    onFinish,
    projectID,
    scenarioID,
    saveAsNewLabel: selectedTab === createLabelTab,
    newMarkObj: newLabelObj,
    comment,
    selectedFromCollection,
  });

  const labelOptions: LabelOption[] = useMemo(() => {
    const labels =
      labelCollection?.organization.labels.edges.map(
        ({ node }) =>
          ({
            id: node.id,
            display: node.title,
            color: node.color,
            description: node.description,
          } as LabelOption)
      ) || [];
    return labels.sort((a, b) => {
      if (a.id === selectedFromCollection.id) return -1;
      if (b.id === selectedFromCollection.id) return 1;
      return a.display.localeCompare(b.display);
    });
  }, [labelCollection, selectedFromCollection]);

  const disableAddBtn = useMemo(() => {
    if (selectedTab === createLabelTab) {
      if (!title.length) return true;
      return false;
    }
    if (!selectedFromCollection.id) return true;
    return false;
  }, [selectedTab, title, selectedFromCollection]);

  return (
    <ActionModal
      saveButtonName="Add"
      onClose={onFinish}
      onSave={() => onSave()}
      disableConfirmButtonIf={disableAddBtn}
      loading={savingLabel}
    >
      <div className="z-50 flex min-h-[580px] flex-col px-6 py-7 shadow">
        <div className="mb-2 text-lg font-medium">
          Label <span className="text-gray-800">{scenarioName}</span>
        </div>
        <div className="my-4 flex flex-col">
          <label className="text-sm font-medium text-gray-700">Label the scenario by:</label>
          {/* Tabs */}
          <div className="flex flex-row space-x-8">
            {tabOptions &&
              tabOptions.map((tab) => (
                <span
                  data-testid={tab}
                  key={tab}
                  onClick={() => {
                    setSelectedTab(tab);
                  }}
                  className={classNames(
                    tab === selectedTab
                      ? "border-purple-500 text-purple-600"
                      : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-600",
                    "flex cursor-pointer items-center gap-2 whitespace-nowrap border-b-2 px-1 pb-2 pt-4 text-sm font-medium"
                  )}
                >
                  {tab}
                </span>
              ))}
          </div>
        </div>
        {selectedTab === createLabelTab && (
          <div className="mt-4 flex flex-col">
            <label className="mb-1 text-sm font-medium text-gray-700">Label title</label>
            <Input
              type="text"
              value={title}
              data-testid="addTitle"
              autoFocus
              onChange={(e) => setTitle(e.target.value)}
              className="text-sm"
            />
            <label className="mt-4 mb-1 text-sm font-medium text-gray-700">
              Label description
            </label>
            <TextArea
              value={description}
              data-testid="addDescription"
              onChange={(e) => setDescription(e.target.value)}
              className="h-20 text-sm"
            />
            <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
              <RadioGroup.Label className="mb-2 text-sm font-medium text-gray-700">
                Label background color:{" "}
                <span
                  className="font-normal capitalize text-gray-800"
                  data-testid="selectedColor"
                >
                  {selectedColor && selectedColor.colorName}
                </span>
              </RadioGroup.Label>
              <div className="flex items-center justify-start -space-y-px overflow-y-auto rounded-md">
                {colorOptions.map((color) => (
                  <RadioGroup.Option
                    key={color.colorName}
                    value={color}
                    data-testid={color.colorName}
                    className="relative m-1 w-max cursor-pointer focus:outline-none"
                  >
                    {({ checked }) => (
                      <div className="flex items-center justify-between">
                        <RadioGroup.Label
                          as="span"
                          className={`${color.color} flex h-6 w-6 items-center justify-center rounded-md`}
                        >
                          {checked && (
                            <Check size={16} weight="bold" className="text-gray-800" />
                          )}
                        </RadioGroup.Label>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        )}

        {selectedTab === collectionLabelTab && (
          <div>
            {!labelOptions.length ? (
              <div className="flex max-w-fit flex-col gap-2 px-2 py-4 text-sm italic text-gray-600">
                <span>Collection empty.</span>
                <span>Create a new label to add to the collection.</span>
              </div>
            ) : (
              <>
                <DropdownSearch
                  label="Label collection"
                  options={labelOptions}
                  selectedOption={
                    labelOptions.find(({ id }) => id === selectedFromCollection.id) || {
                      id: "null",
                      display: "Select a label",
                    }
                  }
                  setSelectedOption={(selectedLabel) =>
                    setSelectedFromCollection(selectedLabel)
                  }
                  className="mb-2 flex flex-col gap-1 text-sm capitalize text-gray-800"
                />
                {selectedFromCollection.id && (
                  <div className="flex items-start gap-4 rounded px-2 py-4">
                    <div
                      data-testid="scenarioLabel"
                      className={`w-fit min-w-max cursor-default whitespace-nowrap rounded py-0.5 px-2 text-sm font-medium uppercase tracking-wide text-gray-800 shadow ${
                        selectedFromCollection && `bg-${selectedFromCollection?.color}`
                      }`}
                    >
                      {selectedFromCollection.display && selectedFromCollection.display}
                    </div>
                    <p
                      data-testid="labelDescription"
                      className="max-w-fit text-sm font-normal text-gray-800 first-letter:capitalize"
                    >
                      {selectedFromCollection.description ? (
                        selectedFromCollection.description
                      ) : (
                        <span className="text-md font-normal italic text-gray-600">
                          No description
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {selectedTab === collectionLabelTab && !labelOptions.length ? (
          ""
        ) : (
          <>
            <label className="mt-4 mb-1 text-sm font-medium text-gray-700">
              Comment for this scenario
            </label>
            <TextArea
              value={comment}
              data-testid="addComment"
              onChange={(e) => setComment(e.target.value)}
              className="h-20 text-sm"
            />
          </>
        )}
      </div>
    </ActionModal>
  );
};

export default AddScenarioLabel;
