import DateTimePicker from "Components/Basic/DateTimePicker/DateTimePicker";
import Dropdown from "Components/Basic/Dropdown/Dropdown";
import TimeRangeDisplayLine from "Components/Basic/TimeRangeDisplayLine";
import { CSVData } from "Components/Input/types";
import { SimTimeUnitSelector } from "Components/Systems/editSystem/componentEditor/parameters/TimeValueInput";
import { addSeconds } from "date-fns";
import { SelectOption, SimTimeUnit } from "model/datatypes";
import React, { useMemo } from "react";
import { isValidAsIndexColumn } from "../utils/isValidAsIndexColumn";

export type IndexColumn = { tag: string; timeUnit: SimTimeUnit };
type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

interface ITimeSelector {
  startDate: Date;
  setStartDate: StateSetter<Date>;
  csvData: CSVData[];
  indexColumn: IndexColumn;
  setIndexColumn: StateSetter<IndexColumn>;
  indexOffsetInSeconds?: number;
}

const TimeSelector = ({
  startDate,
  setStartDate,
  csvData,
  indexColumn,
  setIndexColumn,
  indexOffsetInSeconds = 0,
}: ITimeSelector) => {
  const endDate = addSeconds(startDate, indexOffsetInSeconds);

  const indexOptions: SelectOption[] = useMemo(() => {
    const validIndexColumns = csvData?.filter(isValidAsIndexColumn) || [];
    const columnOptions = validIndexColumns.map(({ tag }) => ({
      id: tag,
      display: tag,
    }));
    return [{ id: "_index", display: "Standard index" }, ...columnOptions];
  }, [csvData]);

  const setIndexColumnTimeUnit = (timeUnit: SimTimeUnit) => {
    setIndexColumn(({ tag }) => ({ tag, timeUnit }));
  };

  const setIndexColumnTagFromOption = (option: SelectOption) => {
    setIndexColumn(({ timeUnit }) => ({ tag: option.id, timeUnit }));
  };

  return (
    <div className="mt-4 rounded-lg bg-gray-100 py-4 px-4 text-sm">
      <div className="mb-4 text-base font-medium">Time configuration</div>
      <div className="flex">
        <div className="w-1/2 pr-2">
          <div className="font-medium">Index column</div>
          <Dropdown
            className="bg-white text-sm"
            selectedID={indexColumn.tag}
            options={indexOptions}
            onSelect={setIndexColumnTagFromOption}
          />
        </div>
        {indexColumn.tag === "_index" && (
          <div className="w-1/2 pl-2">
            <div className="font-medium">Time interval between rows</div>
            <SimTimeUnitSelector
              unit={indexColumn.timeUnit}
              onUpdate={setIndexColumnTimeUnit}
            />
          </div>
        )}
      </div>
      <div className="mt-4">
        <div className="font-medium">Start date</div>
        <DateTimePicker initialDate={startDate} onUpdateDateTime={setStartDate} />
      </div>
      <div className="mt-4">
        <div className="font-medium">Absolute time range</div>
        <TimeRangeDisplayLine
          startDate={startDate}
          endDate={endDate}
          displayDates
          endStrategy="repeat"
        />
      </div>
    </div>
  );
};

export default TimeSelector;
