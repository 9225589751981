import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const WORKSPACE = graphql(`
  query WORKSPACE($workspaceId: ID) {
    workspace(workspaceId: $workspaceId) {
      ... on Workspace {
        __typename
        name
        id
        description
        createdAt
        members {
          id
          role
          user {
            id
            firstName
            lastName
            mail
          }
        }
      }
      ... on GeneralWorkspace {
        __typename
      }
    }
  }
`);

type Props = {
  workspaceId: string | null;
};

export const useGetWorkspace = ({ workspaceId }: Props) => {
  const result = useQuery(WORKSPACE, {
    variables: { workspaceId: workspaceId || null },
  });

  return result;
};
