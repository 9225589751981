import { ComponentType, Container, SystemModel } from "model/datatypes";
import React, { useContext, useReducer, createContext, FC } from "react";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

type EditedSystemState = {
  editedSystem: SystemModel | null;
  isDeprecated: boolean; // delete at some point
  systemLoaded: boolean;
  isChanged: boolean;
  editedContainers: Container[];
  editedComponentTypes: ComponentType[];
};

type Action =
  | { type: "SET_INITIAL_SYSTEM"; payload: SystemModel }
  | { type: "UPDATE_SYSTEM"; payload: Partial<SystemModel> }
  | { type: "UPDATE_CONTAINER"; payload: Container }
  | { type: "SET_CONTAINERS"; payload: Container[] }
  | { type: "UPDATE_COMPONENT_TYPE"; payload: ComponentType }
  | { type: "SET_COMPONENT_TYPES"; payload: ComponentType[] }
  | { type: "SAVED_UPDATES" }
  | {
      type: "SET_REPOSITORY_REFERENCE";
      payload: Exclude<SystemModel["repositoryReference"], undefined>;
    }
  | { type: "CLEAR_REPOSITORY_REFERENCE" };

const reducer = (state: EditedSystemState, action: Action) => {
  switch (action.type) {
    case "SET_INITIAL_SYSTEM": {
      const editedSystem = action.payload;
      const isDeprecated = !!(
        editedSystem &&
        (!editedSystem.componentTypes || !editedSystem.containers)
      );
      return {
        ...state,
        editedSystem,
        isChanged: false,
        systemLoaded: true,
        editedContainers: editedSystem.containers || ([] as Container[]),
        editedComponentTypes: editedSystem.componentTypes || ([] as ComponentType[]),
        isDeprecated,
      };
    }
    case "UPDATE_SYSTEM": {
      if (!state.editedSystem) {
        console.error("No edited system");
        return { ...state, editedSystem: action.payload as SystemModel };
      }
      const updatedSystem: SystemModel = { ...state.editedSystem, ...action.payload };
      return {
        ...state,
        editedSystem: updatedSystem,
        isChanged: true,
      };
    }
    case "UPDATE_CONTAINER":
      return {
        ...state,
        editedContainers: updateArrayVal(
          state.editedContainers,
          action.payload
        ) as Container[],
        isChanged: true,
      };
    case "SET_CONTAINERS":
      return {
        ...state,
        editedContainers: action.payload,
        isChanged: true,
      };
    case "UPDATE_COMPONENT_TYPE":
      return {
        ...state,
        editedComponentTypes: updateArrayVal(
          state.editedComponentTypes,
          action.payload
        ) as ComponentType[],
        isChanged: true,
      };
    case "SET_COMPONENT_TYPES":
      return {
        ...state,
        editedComponentTypes: action.payload,
        isChanged: true,
      };
    case "SAVED_UPDATES":
      return {
        ...state,
        isChanged: false,
      };
    case "SET_REPOSITORY_REFERENCE":
      return {
        ...state,
        isChanged: true,
        editedSystem: state.editedSystem
          ? {
              ...state.editedSystem,
              repositoryReference: action.payload,
            }
          : null,
      };
    case "CLEAR_REPOSITORY_REFERENCE": {
      const { editedSystem } = state;
      if (!editedSystem) return state;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { repositoryReference, ...restEditedSystem } = editedSystem;
      return {
        ...state,
        editedSystem: restEditedSystem,
        isChanged: true,
      };
    }
    default:
      return state;
  }
};

const initialState: EditedSystemState = {
  editedSystem: null,
  systemLoaded: false,
  isDeprecated: false,
  isChanged: false,
  editedComponentTypes: [] as ComponentType[],
  editedContainers: [] as Container[],
};

type AppStateContext = {
  state: EditedSystemState;
  dispatch: React.Dispatch<Action>;
};

const store = createContext<AppStateContext>({
  state: initialState,
  dispatch: () => {},
});
const { Provider } = store;

export const EditSystemStateProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
//Here's two helper hooks to make access simple:
export const useEditSystemState = () => {
  const { state } = useContext(store);
  return state;
};
export const useEditSystemDispatch = () => {
  const { dispatch } = useContext(store);
  return dispatch;
};
