import { useUserRole } from "api/useFirebase";

const DASHBOARD_ENABLED_ENVIRONMENTS = ["nrep", "staging", "dev"];

export const useAllowDashboardAccess = () => {
  const { hasPortfolioAccess } = useUserRole();
  const { hostname } = window.location;
  const currentEnvironment = hostname.split(".")[0];

  const isDashboardEnabledEnvironment =
    DASHBOARD_ENABLED_ENVIRONMENTS.includes(currentEnvironment) ||
    hostname.includes("localhost");

  return isDashboardEnabledEnvironment && hasPortfolioAccess;
};
