const highlightMatchingSubString = (mainString: string, subString: string) => {
  const matchIndexStart = mainString.toLowerCase().indexOf(subString.toLowerCase());

  if (matchIndexStart < 0) return mainString;

  const matchIndexEnd = matchIndexStart + subString.length;

  const resultStart = mainString.substring(0, matchIndexStart);
  const resultMatch = mainString.substring(matchIndexStart, matchIndexEnd);
  const resultEnd = mainString.substring(matchIndexEnd);

  return (
    <div className="whitespace-pre-wrap">
      {resultStart}
      <span className="font-bold text-indigo-600">{resultMatch}</span>
      {resultEnd}
    </div>
  );
};

export default highlightMatchingSubString;
