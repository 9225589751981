import { relayStylePagination } from "@apollo/client/utilities";
import { StrictTypedTypePolicies } from "GraphQL/gql/graphql";
import { isValid, parseISO } from "date-fns";
import { DayTime } from "utils/DayTime";

const DateField = {
  read(field: string) {
    if (!field) return null;
    if (isValid(field)) return field;
    return parseISO(field);
  },
};

//=========================================================================================//
//   You have to add add field resolvers if you want fields to be converted into Date      //
//=========================================================================================//

export const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      workspace: { merge: true },
      teams: relayStylePagination(),
      connectedRepositoryCommits: relayStylePagination(),
      repositoryActivities: relayStylePagination(),
      remoteRepositories: relayStylePagination(),
    },
  },
  ConnectedRepository: {
    fields: {
      updatedAt: DateField,
    },
  },
  RemoteRepository: {
    fields: {
      updatedAt: DateField,
    },
  },
  RepositoryActivityDeleteBranch: {
    fields: {
      activityDate: DateField,
    },
  },
  RepositoryActivityPushNewBranch: {
    fields: {
      activityDate: DateField,
    },
  },
  RepositoryActivityPushNewTag: {
    fields: {
      activityDate: DateField,
    },
  },
  RepositoryActivityPushToBranch: {
    fields: {
      activityDate: DateField,
    },
  },
  Commit: {
    fields: {
      committedAt: DateField,
      authoredAt: DateField,
    },
  },
  OrganizationInvitation: {
    fields: {
      createdAt: DateField,
      expiresAt: DateField,
    },
  },
  Organization: {
    merge: true,
    fields: {
      deletionTime: DateField,
      creditTransactions: relayStylePagination(),
      members: relayStylePagination(),
    },
  },
  JobScheduledResume: {
    fields: {
      nextResume: DateField,
      currentTime: DateField,
    },
  },
  JobAwaitingResume: {
    fields: {
      nextResume: DateField,
      currentTime: DateField,
    },
  },
  CycleCreditConsumptionTransaction: {
    fields: {
      processedAt: DateField,
    },
  },
  ReplenishCreditTransaction: {
    fields: {
      expiresAt: DateField,
      processedAt: DateField,
    },
  },
  User: {
    fields: {
      preferences: {
        merge: true,
      },
      pinned: {
        merge(_, incoming: any[]) {
          return incoming;
        },
      },
    },
  },
  GeneralWorkspace: {
    fields: { projects: relayStylePagination() },
  },
  Workspace: {
    fields: {
      projects: relayStylePagination(),
    },
  },
  Group: {
    fields: {
      createdAt: DateField,
    },
  },
  Project: {
    fields: {
      createdAt: DateField,
      dataSets: relayStylePagination(),
    },
  },
  ScheduledDataSet: {
    fields: {
      schedules: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Execution: {
    fields: {
      logs: relayStylePagination(),
      startedAt: DateField,
    },
  },
  LogItem: {
    fields: {
      timestamp: DateField,
    },
  },
  JobBuild: {
    fields: {
      logs: relayStylePagination(),
    },
  },
  JobBuildLogRecord: {
    fields: {
      timestamp: DateField,
    },
  },
  Timeslot: {
    fields: {
      endTime: {
        read: (field) => DayTime.fromEndHours(field),
      },
      startTime: {
        read: (field) => new DayTime(field),
      },
    },
  },
};
