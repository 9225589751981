/**
 * Use to display feature highlights in the app
 *
 * @example
 * import FeatureHighlight from "thermo/featureHighlight/FeatureHighlight"
 *
 * const FeatureHighlights = () => {
 *    const [showHighlight, setShowHighlight] = useState(true)
 *    return (
 *      <FeatureHighlight
 *        title="Credits"
 *        description="Credits can be used to run jobs"
 *        featureExample={<div>2000 credits</div>}
 *        open={showHighlight}
 *        onDismiss={() => setShowHighlight(false)}
 *      />
 *    )
 * }
 */
const FeatureHighlights = () => {
  return null;
};

export default FeatureHighlights;
