type FBConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL?: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
};

type GitLabRedirect = {
  appId: string;
  authorizeUrl: string;
};

type OrganizationPermission = {
  disallowLoggedInOrganizationCreation?: boolean;
  disallowSignUpOrganizationCreation?: boolean;
};

export type CloudProject = {
  id: string; //matches URL based on ID
  name: string; //display name
  api: string; //url for GRPC API proxy
  cli_api: string; //url for CLI (GRPC API without proxy)
  config: FBConfig; //firebase configuration
  graphql_api: string; //url for graphQL requests
  gitlab?: GitLabRedirect; //GitLab connection redirect link
  permissions?: OrganizationPermission;
  environment_id: string;
};

export const DevConfig: FBConfig = {
  apiKey: "AIzaSyAKFyHXXOtZopKzFb4jQPzuElri0I9wL0U",
  authDomain: "numerous-development.firebaseapp.com",
  projectId: "numerous-development",
  storageBucket: "numerous-development.appspot.com",
  messagingSenderId: "275421940058",
  appId: "1:275421940058:web:9466a8b8cb19f34e5bcb63",
  measurementId: "G-M4VZYBFZRQ",
  databaseURL: "https://numerous-development.firebaseio.com",
};
