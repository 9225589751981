import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Text from "thermo/typography/Typography";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";

type Props = {
  message?: string;
};

export const FullScreenLoadingOverlay = ({ message }: Props) => {
  return (
    <AnimatePresence>
      <motion.div
        data-testid="fullScreenLoadingOverlay"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        exit={{ opacity: 0 }}
        className={classNames(
          getZIndexClassName("zFullScreenLoadingOverlay"),
          "h-screen w-screen fixed top-0 left-0 bg-white flex items-center justify-center gap-4 flex-col"
        )}
      >
        <LoadingIcon />
        {message && <Text.Base className="text-gray-700 font-medium">{message}</Text.Base>}
      </motion.div>
    </AnimatePresence>
  );
};
