import { DotsThree } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { useGetUser } from "api/userManagement/getUser";
import { format, formatDistanceToNow } from "date-fns";
import { SystemModel } from "model/datatypes";
import { useState } from "react";
import ConfirmModal from "thermo/Modal/ConfirmModal";
import Popover from "thermo/popover/Popover";
import Tooltip from "thermo/tooltip/Tooltip";
import Text from "thermo/typography/Typography";
import { useDeleteSystem } from "../api/deleteSystem";
import ToolOptionsMenu from "./ToolOptionsMenu";

interface Props {
  tool: SystemModel;
  gridTemplateColumns: string;
}

const ToolRow = ({ tool, gridTemplateColumns }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { deleteSystem } = useDeleteSystem(
    tool.id,
    tool.modelType === "optimization_model" ? "optimization type" : "tool"
  );

  const { displayName, ownerId, latestEdited, status } = tool;
  const ownerData = useGetUser(ownerId).data?.user;
  const latestEditorData = useGetUser(latestEdited?.userId || "").data?.user;
  const owner = ownerData?.__typename === "User" ? ownerData : undefined;
  const latestEditor = latestEditorData?.__typename === "User" ? latestEditorData : undefined;

  return (
    <>
      <Link
        to={`tools/${tool.id}`}
        data-testid="toolRow"
        className="grid h-12 bg-white group/toolRow hover:bg-gray-50 px-4 items-center"
        style={{ gridTemplateColumns }}
      >
        <Text.Small truncate data-testid="toolName" className="text-gray-600 font-medium pr-5">
          {displayName}
        </Text.Small>
        <Text.Small truncate className="text-gray-600 pr-5">
          {owner?.fullName}
        </Text.Small>
        <Tooltip label={latestEdited ? format(latestEdited.time, "dd LLL yyyy HH:mm") : null}>
          <div className="flex flex-col">
            <Text.Small truncate className="text-gray-400">
              {latestEditor?.fullName}
            </Text.Small>
            <Text.Small className="text-gray-600">
              {latestEdited ? formatDistanceToNow(latestEdited.time, { addSuffix: true }) : ""}
            </Text.Small>
          </div>
        </Tooltip>
        <Text.Small className="text-gray-600 capitalize">{status}</Text.Small>
        <div className="flex items-center justify-end">
          <Popover.Root>
            <Popover.Trigger>
              <DotsThree
                onClick={(e) => e.preventDefault()}
                size={22}
                weight="bold"
                className={`rounded text-gray-500 hover:text-gray-700 hover:bg-gray-200 hidden group-data-[open="true"]/popoverTrigger:block group-hover/toolRow:block`}
              />
            </Popover.Trigger>
            <Popover.Content>
              {(closePopover) => (
                <ToolOptionsMenu
                  tool={tool}
                  onDelete={() => setShowDeleteModal(true)}
                  onClose={closePopover}
                />
              )}
            </Popover.Content>
          </Popover.Root>
        </div>
      </Link>
      <ConfirmModal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={() => {
          deleteSystem();
          setShowDeleteModal(false);
        }}
        confirmButtonText="Delete tool"
        description={
          <span>
            Are you sure you want to delete <b>{tool.displayName}</b>? This is a destructive
            action that can&apos;t be undone.
          </span>
        }
        isWarning
      />
    </>
  );
};

export default ToolRow;
