import { ComponentProps, HTMLAttributes, ReactNode } from "react";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";
import BaseModal, { BaseModalProps } from "./BaseModal";

interface ActionBarProps extends Omit<HTMLAttributes<HTMLDivElement>, "className"> {
  justify?:
    | "normal"
    | "start"
    | "end"
    | "center"
    | "between"
    | "around"
    | "evenly"
    | "stretch";
  children: ReactNode;
}

const ActionBar = ({ justify = "end", children, ...htmlProps }: ActionBarProps) => {
  return (
    <div {...htmlProps} className={`bg-gray-50 p-3 flex justify-${justify} gap-2`}>
      {children}
    </div>
  );
};

type ActionButtonProps = Omit<ComponentProps<typeof Button>, "size">;

const ActionButton = (buttonProps: ActionButtonProps) => {
  return <Button size="md" {...buttonProps} />;
};

interface DefaultProps {
  actionText?: string;
  abortText?: string;
  onAction: () => void;
  loading?: boolean;
  disableActionButton?: boolean;
  hideDefaultActionBar?: never;
}

interface HideActionBarProps {
  actionText?: never;
  abortText?: never;
  onAction?: never;
  loading?: never;
  disableActionButton?: never;
  hideDefaultActionBar: true;
}

type Props = {
  title?: string;
} & Omit<BaseModalProps, "noPadding"> &
  (DefaultProps | HideActionBarProps);

const ActionModal = ({
  title,
  actionText = "Save",
  abortText = "Cancel",
  onAction,
  loading,
  disableActionButton,
  hideDefaultActionBar,
  children,
  ...baseModalProps
}: Props) => {
  return (
    <BaseModal {...baseModalProps} noPadding>
      <div className="flex flex-col">
        {title && (
          <Text.H4 className="text-gray-400 mt-3 ml-3" data-testid="modalTitle">
            {title}
          </Text.H4>
        )}
        {children}
        {!hideDefaultActionBar && (
          <ActionBar data-testid="defaultActionBar">
            <ActionButton onClick={baseModalProps.onClose} data-testid="actionModalCancel">
              {abortText}
            </ActionButton>
            <ActionButton
              variant="brandColor"
              onClick={onAction}
              loading={loading}
              disabled={disableActionButton}
              data-testid="actionModalConfirm"
            >
              {actionText}
            </ActionButton>
          </ActionBar>
        )}
      </div>
    </BaseModal>
  );
};

ActionModal.ActionBar = ActionBar;
ActionModal.ActionButton = ActionButton;

export default ActionModal;
