import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import { ErrorBoundary } from "Components/Pages/ErrorBoundary/GlobalErrorBoundaryPage";
import { GraphQLProvider } from "Components/Providers/Apollo/GraphQLProvider";
import { FirebaseManager } from "Components/Providers/Firebase/FirebaseManager";
import { FirebaseProvider } from "Components/Providers/Firebase/FirebaseProvider";
import { AppRoutes } from "Components/Routes/AppRoutes";
import { Toast } from "Components/Toast/Toast";
import Gleap from "gleap";
import React, { useEffect } from "react";
import { StateProvider } from "store";

const AppWrapper: React.FC = () => {
  useEffect(() => {
    const hasGleapValuesInLocalStorage =
      localStorage.getItem("gleap-id") && localStorage.getItem("gleap-hash");

    const lastIndexOfURL = window.location.hostname.split(".").pop();
    const isPreviewChannelDeployment = lastIndexOfURL === "app";
    const isLocalHost = window.location.port === "3000";

    if (!isLocalHost && !isPreviewChannelDeployment && !hasGleapValuesInLocalStorage)
      Gleap.initialize("P0XtZXyXIQZOyYq7MMuzUy5cBfq6e2Zv");
  }, []);

  return (
    <ErrorBoundary>
      <Toast />
      <StateProvider>
        <FirebaseManager>
          <FirebaseProvider>
            <GraphQLProvider>
              <TooltipProvider>
                <AppRoutes />
              </TooltipProvider>
            </GraphQLProvider>
          </FirebaseProvider>
        </FirebaseManager>
      </StateProvider>
    </ErrorBoundary>
  );
};

export default AppWrapper;
