import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import "scroll-shadow-element";

interface IScrollShadowProps {
  children: JSX.Element;
}

/**
 * Component that adds scroll shadow indicators for x and y axes if content overflows
 * direct child container.
 *
 * @example
 * return (
 *   <ScrollShadow>
 *     <div className="h-32 overflow-auto">
 *       Lots of content here
 *     </div>
 *   </ScrollShadow>
 * )
 */
const ScrollShadow = ({ children }: IScrollShadowProps) => {
  return <scroll-shadow style={{ width: "inherit" }}>{children}</scroll-shadow>;
};

export default ScrollShadow;
