import { CaretDown, CaretRight } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { ConditionalWrapper } from "Components/Basic/ConditionalWrapper";
import React, { ReactNode } from "react";
import Popover from "thermo/popover/Popover";

const Separator = () => {
  return (
    <CaretRight
      data-testid="breadcrumbSeparator"
      weight="bold"
      className="h-4 w-4 flex-shrink-0"
      aria-hidden="true"
    />
  );
};

const Item = ({
  link,
  label,
  children,
}: {
  link?: string;
  label?: string;
  children: ReactNode;
}) => {
  return (
    <ConditionalWrapper
      condition={!!link}
      wrapper={(wrappedChildren) => (
        <Link to={link || "/"} className="cursor-pointer">
          {wrappedChildren}
        </Link>
      )}
    >
      <li className="flex-col" data-testid="breadcrumb">
        {label && <div className="text-xs text-gray-400 leading-3">{label}</div>}
        {children}
      </li>
    </ConditionalWrapper>
  );
};

const ItemPopover = ({
  popoverContent,
  label,
  children,
}: {
  popoverContent: ReactNode;
  label?: string;
  children: ReactNode;
}) => {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <div className="flex items-center cursor-pointer">
          <Item label={label}>{children}</Item>
          <CaretDown weight="bold" className="ml-2 h-3 w-3" />
        </div>
      </Popover.Trigger>
      <Popover.Content>{popoverContent}</Popover.Content>
    </Popover.Root>
  );
};

const Breadcrumbs = ({ children }: { children: ReactNode }) => {
  return (
    <ol
      className="flex items-center space-x-4 text-gray-700 text-sm font-medium"
      aria-label="Breadcrumb"
      data-testid="breadcrumbs"
    >
      {React.Children.map(children, (child, i) => {
        if (!child) return null;
        return (
          <>
            {i !== 0 && <Separator />}
            {child}
          </>
        );
      })}
    </ol>
  );
};

Breadcrumbs.Item = Item;
Breadcrumbs.ItemPopover = ItemPopover;

export default Breadcrumbs;
