import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import {
  ConnectedRepositorySortBy,
  Get_Connected_RepositoriesQuery,
  SortOrder,
} from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_CONNECTED_REPOSITORIES = graphql(`
  query GET_CONNECTED_REPOSITORIES(
    $first: Int!
    $after: String
    $sortBy: ConnectedRepositorySortBy!
    $sortOrder: SortOrder!
  ) {
    connectedRepositories(
      sortBy: $sortBy
      sortOrder: $sortOrder
      first: $first
      after: $after
    ) @connection(key: "ConnectedRepositories") {
      edges {
        cursor
        node {
          __typename
          id
          displayName
          path
          description
          updatedAt
          createdBy {
            __typename
            fullName
          }
          lastCommit {
            ... on Commit {
              __typename
              id
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`);

export type ConnectedRepository = ArrayElement<
  Get_Connected_RepositoriesQuery["connectedRepositories"]["edges"]
>["node"];

interface Props {
  sortBy: ConnectedRepositorySortBy;
  sortOrder: SortOrder;
}

export const useGetConnectedRepositories = (props?: Props) => {
  const sortBy = props?.sortBy || ConnectedRepositorySortBy.DisplayName;
  const sortOrder = props?.sortOrder || SortOrder.Ascending;

  const result = useQuery(GET_CONNECTED_REPOSITORIES, {
    variables: {
      first: 30,
      after: null,
      sortBy,
      sortOrder,
    },
    fetchPolicy: 'cache-and-network'
  });

  return result;
};
