import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useFirestore } from "api/useFirebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import toast from "react-hot-toast";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { LabelNew, LabelOption } from "../types";

/**
 * Hook used to remove a mark from a scenario.
 *
 * @param onFinish - to close ActionModal
 * @param projectID - project with the scenario to mark
 * @param scenarioID - scenario to mark
 * @param selectedTab - selected tab to check against
 * @param createMarkTab - string for creating a mark
 * @param collectionMarkTab - string for the collection
 * @param newMarkObj - values for the new mark
 * @param comment - comment with the mark
 * @param selectedFromCollection - selected mark from collection
 * @returns onSave() and savingMark state.
 */

type Props = {
  onFinish: () => void;
  projectID: string;
  scenarioID: string;
  saveAsNewLabel: boolean;
  newMarkObj: LabelNew;
  comment: string;
  selectedFromCollection: LabelOption;
};

// NUM-63
export const useSaveScenarioMark = ({
  onFinish,
  projectID,
  scenarioID,
  saveAsNewLabel,
  newMarkObj,
  comment,
  selectedFromCollection,
}: Props) => {
  const [savingMark, setSavingMark] = useState(false);
  const user = useGlobalUser();
  const activeOrganizationId = useActiveOrganizationId();
  const fs = useFirestore();

  const onSave = async () => {
    if (savingMark || !activeOrganizationId) return;
    let labelId = selectedFromCollection.id;
    try {
      setSavingMark(true);
      if (saveAsNewLabel) {
        const labelCollection = collection(fs, `organizations/${activeOrganizationId}/labels`);
        const docRefCollection = doc(labelCollection);
        const newLabel = {
          id: docRefCollection.id,
          title: newMarkObj.title,
          description: newMarkObj.description,
          color: newMarkObj.color.slice(3),
        } as LabelNew;
        labelId = newLabel.id;
        await setDoc(docRefCollection, convertToFirestoreFormat(newLabel));
      }
      const scenarioDocRef = doc(
        fs,
        "organizations",
        activeOrganizationId,
        "projects",
        projectID,
        "scenarios",
        scenarioID
      );
      await updateDoc(
        scenarioDocRef,
        convertToFirestoreFormat({
          label: {
            label_id: labelId,
            owner_id: user.id,
            comment,
            created: new Date(),
            organizationID: activeOrganizationId,
          },
        })
      );
      toast.success(
        saveAsNewLabel
          ? "New label created and added to a scenario"
          : "New label added to a scenario"
      );
    } catch (error) {
      console.error(error);
      toast.error("The label could not be saved");
    } finally {
      setSavingMark(false);
      onFinish();
    }
  };

  return { onSave, savingMark };
};
