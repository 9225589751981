import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_User_Pending_InvitationsQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_USER_PENDING_INVITATIONS = graphql(`
  query GET_USER_PENDING_INVITATIONS {
    me {
      id
      invitations {
        __typename
        createdAt
        id
        isExpired
        role
        organization {
          id
          name
          description
          slug
          logoUrl
        }
        createdBy {
          fullName
        }
      }
    }
  }
`);

export type UserPendingInvitation = Extract<
  ArrayElement<Get_User_Pending_InvitationsQuery["me"]["invitations"]>,
  { __typename: "OrganizationInvitation" }
>;

export const useGetUserPendingInvitations = () => {
  const { data, ...rest } = useQuery(GET_USER_PENDING_INVITATIONS);

  return { ...rest, data: data?.me.invitations };
};
