import { CaretRight, Pen, XCircle } from "@phosphor-icons/react";
import ActionModal from "Components/Basic/ActionModal";
import { Button } from "Components/Basic/Buttons";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import TextInput from "Components/Basic/Form/TextInput";
import { Input, InputNumber } from "Components/Basic/Input";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import { DualButton } from "Components/Basic/ToggleButtonGroup";
import {
  PeriodTypeInput,
  Schedule,
  ScheduleInput as ScheduleInputType,
} from "GraphQL/gql/graphql";
import { useAddSchedule } from "api/dataSet/addSchedule";
import { useAddScheduleDataSet } from "api/dataSet/addScheduleDataSet";
import { DataSetPageNode } from "api/dataSet/getPaginatedDataSets";
import { useUpdateSchedule } from "api/dataSet/updateSchedule";
import { Formik, Form } from "formik";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { deepEqual } from "utils/jsUtils/equality";
import { object, string } from "yup";
import { ScheduleDataSetOptions } from "./ScheduleDataSetOptions";
import ScheduleDayGraph from "./components/ScheduleDayGraph";
import { TimeSlotEditable, AddTimeslot } from "./components/TimeSlotEditable";
import useMutateSchedulePeriod from "./utils/mutateSchedulePeriod";
import {
  convertScheduleInputToGraphQLFormat,
  scheduleInputToSchedule,
  scheduleToScheduleInput,
} from "./utils/scheduleGraphQLHelpers";
import useCopyFromSchedule from "./utils/useCopyFromSchedule";

const validationSchema = object({
  newDataSetName: string().required("Add the new dataset name"),
});

type DataSetFromPage = Extract<DataSetPageNode, { __typename: "ScheduledDataSet" }>;

interface Props {
  dataSet?: DataSetFromPage;
  onFinished: (scheduleDataSet?: { schedule: Schedule; dataSetId: string }) => void;
  startSchedule?: Schedule;
}

const ScheduleInput: React.FC<Props> = ({ onFinished, startSchedule, dataSet }) => {
  const { projectID = "" } = useParams<{ projectID: string }>();
  const [addScheduleDataSet, { loading: addingScheduleDataSet }] = useAddScheduleDataSet();
  const [updateSchedule, { loading: updatingSchedule }] = useUpdateSchedule();
  const [addSchedule, { loading: addingSchedule }] = useAddSchedule();

  const [editedSchedule, setEditedSchedule] = useState<ScheduleInputType>(
    scheduleToScheduleInput(startSchedule)
  );
  const [activePeriod, setActivePeriod] = useState<PeriodTypeInput>(PeriodTypeInput.Weekdays);
  const [newTimeslot, setNewTimeslot] = useState<null | string>(null);
  const [scrollingTimeslots, setScrollingTimeslots] = useState(false);
  const [selectedDataSet, setSelectedDataSet] = useState(dataSet);
  const [newDataSetName, setNewDataSetName] = useState("");
  const [addNewDataSet, setAddNewDataSet] = useState(false);

  const activePeriodInput = useMemo(
    () => editedSchedule.periodSettings.find((p) => p.periodType === activePeriod),
    [editedSchedule, activePeriod]
  );

  const { copyFromSchedule, confirmCopy, setConfirmCopy } = useCopyFromSchedule({
    activePeriod: activePeriodInput,
    schedule: editedSchedule,
  });
  const { updatePeriodTimeSetting, removePeriod } = useMutateSchedulePeriod({
    schedule: editedSchedule,
    activePeriod,
  });

  const timeslotRef = useRef<HTMLDivElement>(null);

  const sortedTimeslots = useMemo(() => {
    if (editedSchedule && activePeriodInput?.timeslots)
      return [...activePeriodInput.timeslots].sort((a, b) => {
        return a.endTime.inSeconds() - b.endTime.inSeconds();
      });
    return [];
  }, [activePeriodInput?.timeslots, editedSchedule]);

  const saveNewDataSet = () => {
    if (addingScheduleDataSet) return;
    addScheduleDataSet({
      variables: {
        projectId: projectID,
        scheduledDataSetInput: {
          name: newDataSetName,
          schedules: [convertScheduleInputToGraphQLFormat(editedSchedule)],
        },
      },
    })
      .then((res) => {
        const response = res.data?.dataSetCreateScheduled;
        if (response?.__typename === "ScheduledDataSet") {
          const newSchedule = response.schedules.find((s) => s.tag === editedSchedule.tag);
          onFinished(
            newSchedule && {
              dataSetId: response.id,
              schedule: scheduleInputToSchedule(editedSchedule),
            }
          );
          toast.success("The schedule was successfully saved");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("The schedule could not be saved. Try again later.");
      });
  };

  const updateExistingDataSetSchedule = async () => {
    if (!selectedDataSet || !startSchedule || updatingSchedule) return;
    try {
      const updatePromise = updateSchedule({
        variables: {
          projectId: projectID,
          dataSetId: selectedDataSet.id,
          initialScheduleTag: startSchedule.tag,
          updatedSchedule: convertScheduleInputToGraphQLFormat(editedSchedule),
        },
      });
      toast.promise(updatePromise, {
        loading: "Updating the schedule ...",
        error: (e) => `An error occured: ${e}`,
        success: "Succesfully updated the schedule",
      });
      const updateResponse = await updatePromise;
      const updatedSchedule =
        updateResponse.data?.dataSetScheduleCreate.__typename === "ScheduledDataSet" &&
        updateResponse.data.dataSetScheduleCreate.schedules.find(
          (s) => s.tag === startSchedule.tag
        );
      onFinished(
        updatedSchedule
          ? {
              schedule: updatedSchedule,
              dataSetId: selectedDataSet.id,
            }
          : undefined
      );
    } catch (error) {
      console.error(error);
    }
  };

  const addScheduleToExistingDataset = () => {
    if (addingSchedule) return;
    addSchedule({
      variables: {
        projectId: projectID,
        dataSetId: selectedDataSet?.id || "",
        schedule: convertScheduleInputToGraphQLFormat(editedSchedule),
      },
    })
      .then((res) => {
        const response = res.data?.dataSetScheduleCreate;
        if (response?.__typename === "ScheduledDataSet") {
          let newSchedule = response.schedules.find((s) => s.tag === editedSchedule.tag);
          onFinished(
            newSchedule && {
              dataSetId: response.id,
              schedule: scheduleInputToSchedule(editedSchedule),
            }
          );
          toast.success("The schedule was successfully saved");
        } else if (response?.__typename === "DataSetNotFound") {
          toast.error("Dataset could not be found.");
        } else {
          toast.error("Could not find the schedule you are trying to save.");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("The schedule could not be saved. Try again later.");
      });
  };

  const handleSaveSchedule = () => {
    if (!selectedDataSet) saveNewDataSet();
    else if (startSchedule) updateExistingDataSetSchedule();
    else addScheduleToExistingDataset();
  };

  const timeslotsHasBeenEdited = useMemo(() => {
    const initialSchedule = scheduleToScheduleInput(startSchedule);
    return !deepEqual(initialSchedule.periodSettings, editedSchedule.periodSettings);
  }, [editedSchedule, startSchedule]);

  useEffect(() => {
    // Passes info on scrolling to TimeSlotEditable
    // to update page position for RootPortal
    const timeslotsContainer = timeslotRef.current;
    let timeout: ReturnType<typeof setTimeout>;

    const handleScroll = () => {
      clearTimeout(timeout);
      setScrollingTimeslots(true);

      timeout = setTimeout(() => setScrollingTimeslots(false), 350);
    };

    timeslotsContainer?.addEventListener("scroll", handleScroll);

    return () => {
      timeslotsContainer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ActionModal
      className="w-[96vw]"
      onClose={() => {
        onFinished();
      }}
      onSave={handleSaveSchedule}
      disableConfirmButtonIf={!timeslotsHasBeenEdited || !editedSchedule.tag.length}
      blockNavigateAwayIf={timeslotsHasBeenEdited}
      cancelButtonName="Cancel"
      saveButtonName="Save schedule"
    >
      <div>
        <div className="flex w-full flex-col flex-wrap border-b border-gray-200 p-6 pb-4 text-lg font-medium">
          <div className="flex gap-4">
            {dataSet?.name && (
              <div className="flex items-center gap-4 text-gray-400">
                {dataSet.name} <CaretRight className="h-4 w-4" />
              </div>
            )}
            {startSchedule ? `Edit ${startSchedule.tag}` : "New Schedule"}
          </div>
        </div>

        <div className="relative mt-2 flex w-full flex-col p-6 pt-4">
          <div className="flex w-full items-center justify-between border-b border-trueGray-200 pb-4">
            <div className="flex w-2/4 flex-col pr-12">
              <div className="mb-2 text-sm font-medium">Schedule name</div>
              <Input
                className="w-full"
                value={editedSchedule.tag}
                onChange={(e) => {
                  setEditedSchedule({ ...editedSchedule, tag: e.target.value });
                }}
              />
            </div>

            {!dataSet && (
              <ScheduleDataSetOptions
                newDataSetName={newDataSetName}
                onAddNew={() => setAddNewDataSet(true)}
                selectedId={selectedDataSet?.id || newDataSetName}
                onSelect={(selected) => {
                  if (selected.id !== newDataSetName) setSelectedDataSet(selected.value);
                  else setSelectedDataSet(undefined);
                }}
              />
            )}
          </div>

          <div className="flex h-[560px] w-full pt-4">
            <div className="my-auto w-2/3 pr-4">
              {activePeriodInput && <ScheduleDayGraph period={activePeriodInput} />}
            </div>
            <div className="mx-auto w-1/3">
              <div className="mb-4 flex justify-end">
                <DualButton
                  optionOne="Weekdays"
                  optionTwo="Weekends"
                  active={activePeriod === PeriodTypeInput.Weekdays ? "one" : "two"}
                  onClickOne={() => {
                    setActivePeriod(PeriodTypeInput.Weekdays);
                  }}
                  onClickTwo={() => {
                    setActivePeriod(PeriodTypeInput.Weekends);
                  }}
                />
              </div>
              <div className="flex w-full justify-between pl-4 pr-10 text-sm text-gray-500">
                <span>Period</span>
                <span>Value</span>
              </div>
              <div ref={timeslotRef} className="max-h-96 overflow-y-auto">
                {sortedTimeslots &&
                  sortedTimeslots.map((slot) => {
                    const isNew = newTimeslot === slot.id;
                    return (
                      <div key={slot.id} className="flex w-full items-center gap-2">
                        <TimeSlotEditable
                          key={slot.id}
                          slot={slot}
                          onUpdate={(newSlot) => {
                            const updatedSchedule = updatePeriodTimeSetting(newSlot);
                            if (updatedSchedule) setEditedSchedule(updatedSchedule);
                          }}
                          isNew={isNew}
                          isScrolling={scrollingTimeslots}
                        />
                        <XCircle
                          onClick={() => {
                            const updatedSchedule = removePeriod(slot);
                            if (updatedSchedule) setEditedSchedule(updatedSchedule);
                          }}
                          className="h-5 w-5 cursor-pointer text-gray-300 transition-colors duration-100 hover:text-red-600"
                        />
                      </div>
                    );
                  })}
              </div>
              {activePeriod && (
                <div
                  className={`my-2 mr-6 flex items-center overflow-hidden rounded border ${
                    newTimeslot === "default" ? "border-indigo-600" : "border-gray-200"
                  }`}
                >
                  <div className="w-1/2 cursor-default select-none px-4 py-1 text-sm">
                    Default
                  </div>
                  <InputNumber
                    headless
                    className="w-1/2 border-none py-1 px-2 pr-3.5 text-right text-sm focus:outline-none focus:ring-0"
                    value={activePeriodInput?.defaultValue}
                    onFocus={() => setNewTimeslot("default")}
                    onBlur={() => setNewTimeslot(null)}
                    onUpdate={(val) => {
                      setEditedSchedule((currentSchedule) => ({
                        ...currentSchedule,
                        periodSettings: currentSchedule.periodSettings.map((periodSetting) => {
                          if (periodSetting.periodType !== activePeriod) return periodSetting;
                          return { ...periodSetting, defaultValue: val };
                        }),
                      }));
                    }}
                  />
                </div>
              )}
              <div className="mt-4 flex w-full flex-col items-end justify-end gap-x-4 gap-y-2 pr-7 xl:flex-row">
                <AddTimeslot
                  onAdd={(newSlot) => {
                    const updatedSchedule = updatePeriodTimeSetting(newSlot);
                    if (updatedSchedule) setEditedSchedule(updatedSchedule);
                    setNewTimeslot(newSlot.id);
                  }}
                />
                <Button
                  className="w-fit"
                  buttonType="white"
                  onClick={() => {
                    const copiedSchedule = copyFromSchedule();
                    if (copiedSchedule) setEditedSchedule(copiedSchedule);
                  }}
                >
                  Copy from{" "}
                  {activePeriod === PeriodTypeInput.Weekdays ? "Weekends" : "Weekdays"}
                </Button>
              </div>
              <div className="flex" />
            </div>
          </div>
        </div>

        {(addingScheduleDataSet || addingSchedule) && (
          <LoadingOverlay className="rounded-lg" />
        )}
      </div>
      <AnimatePresence>
        {confirmCopy && (
          <ConfirmModal
            headline={`Copy schedule from ${
              activePeriod === PeriodTypeInput.Weekdays ? "Weekends" : "Weekdays"
            }?`}
            description={`This will overwrite all added timeslots for ${String(
              activePeriod
            )}.`}
            confirmBtnText="Copy"
            onConfirm={() => {
              const copiedSchedule = copyFromSchedule();
              if (copiedSchedule) setEditedSchedule(copiedSchedule);
            }}
            onCancel={() => setConfirmCopy(false)}
          />
        )}
        {addNewDataSet && (
          <Formik
            initialValues={{
              newDataSetName: "",
            }}
            onSubmit={(values) => {
              setNewDataSetName(values.newDataSetName);
              setSelectedDataSet(undefined);
              setAddNewDataSet(false);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <ActionModal
                saveButtonType="submit"
                onClose={() => setAddNewDataSet(false)}
                onSave={formik.submitForm}
                saveButtonName="Add dataset"
                disableConfirmButtonIf={
                  !formik.isValid ||
                  !formik.dirty ||
                  formik.isSubmitting ||
                  addingSchedule ||
                  addingScheduleDataSet
                }
              >
                <div className="flex w-96 px-6 py-7">
                  <div className="flex w-1/4 items-center justify-center pr-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-100">
                      <Pen className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="w-3/4">
                    <div className="mb-2 text-lg font-normal leading-6 text-gray-900">
                      Add new dataset
                    </div>

                    <Form className="space-y-4">
                      <div>
                        <TextInput name="newDataSetName" defaultValue="" />
                      </div>
                    </Form>
                  </div>
                </div>
              </ActionModal>
            )}
          </Formik>
        )}
      </AnimatePresence>
    </ActionModal>
  );
};

export default ScheduleInput;
