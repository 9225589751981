import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PAGINATED_DATASETS } from "./getPaginatedDataSets";

const CREATE_DATASET = graphql(`
  mutation CREATE_DATASET($projectId: ID!, $dataSetInput: StaticDataSetInput!) {
    dataSetCreateStatic(projectId: $projectId, dataSetInput: $dataSetInput) {
      __typename
      id
      index
      name
      projectId
      tags
      data {
        tag
        values
      }
    }
  }
`);

export const useCreateDataSet = () => {
  const mutation = useMutation(CREATE_DATASET, {
    update(cache, { data }, { variables }) {
      if (!data || !variables) return;
      const createdDataSet = data?.dataSetCreateStatic;
      if (!createdDataSet) return;
      cache.updateQuery(
        { query: PAGINATED_DATASETS, variables: { projectId: variables.projectId } },
        (projectQuery) => {
          if (!projectQuery || !projectQuery.project?.dataSets) return projectQuery;
          let updatedProjectQuery = structuredClone(projectQuery);
          updatedProjectQuery.project?.dataSets.edges.unshift({
            __typename: "AbstractDataSetEdge",
            cursor: createdDataSet.id,
            node: createdDataSet,
          });
          return updatedProjectQuery;
        }
      );
    },
  });

  return mutation;
};
