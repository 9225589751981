import {
  offset,
  useInteractions,
  useClick,
  useFloating,
  FloatingPortal,
  autoUpdate,
  flip,
  useDismiss,
} from "@floating-ui/react-dom-interactions";
import { CaretDown } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import { SelectOption } from "model/datatypes";
import React, { useState } from "react";
import { zDropdown } from "thermo/zIndex/zIndexValues";
import classNames from "utils/jsUtils/className";
import LoadingOverlay from "../LoadingOverlay";

interface Props {
  onSelect: (selected: SelectOption) => void;
  options: SelectOption[];
  className?: string;
  selectedID?: string;
  placeholder?: string;
  emptyMsg?: string;
  onAddNew?: () => void;
  disabled?: boolean;
  loading?: boolean;
  headlessStyle?: true;
  drawerClasses?: string;
  drawerID?: string;
  inlineIcon?: JSX.Element;
  closeOnDisappear?: true;
}

const Dropdown: React.FC<Props> = ({
  className = "",
  options,
  selectedID,
  placeholder,
  onSelect,
  onAddNew,
  disabled,
  emptyMsg,
  loading,
  headlessStyle,
  drawerClasses = "",
  inlineIcon,
  drawerID,
  closeOnDisappear: _, // don't include closeOnDissapear in htmlProps
  ...htmlProps
}) => {
  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy, context, refs, middlewareData } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [flip(), offset(4)],
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    placement: "bottom-start",
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ]);

  const minDrawerWidth = `${refs.reference.current?.getBoundingClientRect().width}px`;
  const selected = options.find((o) => o.id === selectedID);
  const display = selected ? selected.display : placeholder || "";

  return (
    <div
      id="dropdownTrigger"
      className={classNames(
        "relative flex items-center border-gray-300",
        className,
        disabled ? "bg-gray-100 text-trueGray-400" : "cursor-pointer",
        !headlessStyle && "rounded border py-1 pl-3 pr-2 shadow"
      )}
      data-testid="dropdown"
      {...getReferenceProps({ ref: reference })}
      {...htmlProps}
    >
      <span className="flex items-center truncate">
        {!!inlineIcon && <span className="mr-2 inline-block">{inlineIcon}</span>}
        {display}
      </span>
      <div className="flex-grow" />
      <CaretDown
        weight="bold"
        className="h-3 w-3 fill-current"
        style={{
          transform: open ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      />

      {loading && <LoadingOverlay />}

      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  left: x ?? "",
                  top: y ?? "",
                  minWidth: minDrawerWidth,
                  zIndex: zDropdown,
                },
              })}
              id={drawerID}
              className={classNames(
                "max-h-60  overflow-y-auto rounded border border-gray-200 bg-white text-sm shadow-lg",
                middlewareData.flip?.index ? "origin-bottom" : "origin-top",
                drawerClasses
              )}
              initial={{ opacity: 0, scaleY: 0.75 }}
              animate={{ opacity: 1, scaleY: 1 }}
              exit={{ opacity: 0, scaleY: 0.75 }}
              transition={{ duration: 0.08, ease: "easeInOut" }}
              data-testid="dropdownContent"
            >
              {options.length > 0 ? (
                options.map((option) => (
                  <div
                    data-testid="option"
                    className="flex cursor-pointer items-center py-2 px-4 hover:bg-gray-100"
                    key={option.id}
                    onClick={() => {
                      onSelect(option);
                      setOpen(false);
                    }}
                  >
                    <span className="truncate">{option.display}</span>
                  </div>
                ))
              ) : (
                <div data-testid="dropdownNoOptions" className="py-2 px-4 italic">
                  {emptyMsg || "No options"}
                </div>
              )}
              {onAddNew && (
                <button
                  onClick={() => {
                    onAddNew();
                    setOpen(false);
                  }}
                  className="w-full border-t border-gray-200 py-2 hover:bg-gray-100 focus:outline-none"
                  data-test="addNewReportButton"
                >
                  Add new
                </button>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </div>
  );
};

export default Dropdown;
