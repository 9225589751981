import Dropdown from "Components/Basic/Dropdown/Dropdown";
import FileParameterUploader from "Components/Files/FileParameterUploader";
import { useSimFiles } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import {
  FileQuery,
  ComponentParamType,
  ComponentParameter,
  FileRef,
  SelectOption,
} from "model/datatypes";
import { useState } from "react";

export const FileSelector: React.FC<{
  fileQuery: FileQuery;
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate, fileQuery }) => {
  const { simFiles: files } = useSimFiles(fileQuery);

  const [addNewOpen, setAddNewOpen] = useState(false);

  const options: SelectOption[] = files.map((file) => ({
    id: file.id,
    display: file.name,
    value: { id: file.id, path: file.path, name: file.name } as FileRef,
  }));

  const selectedID =
    typeof parameter.value === "object" && parameter.value && "id" in parameter.value
      ? (parameter.value.id as string)
      : undefined;
  return (
    <>
      <Dropdown
        className="bg-white text-sm"
        options={options}
        onSelect={(dropdownOption) => {
          onUpdate({ ...parameter, value: dropdownOption.value });
        }}
        selectedID={selectedID}
        placeholder={options.length > 0 ? "Select file" : "No files found"}
        emptyMsg="No files found"
        onAddNew={() => {
          setAddNewOpen(true);
        }}
      />
      <AnimatePresence>
        {addNewOpen && (
          <FileParameterUploader
            key={`${parameter.uuid}fileParameterUploaderModal`}
            fileQuery={fileQuery}
            onFinish={(fileRef) => {
              setAddNewOpen(false);
              if (fileRef) onUpdate({ ...parameter, value: fileRef });
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};
