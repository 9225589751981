import ActionModal from "Components/Basic/ActionModal";
import { Input, TextArea } from "Components/Basic/Input";
import { useCreateGroup } from "api/group/createGroup";
import { Group } from "model/datatypes";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

interface Props {
  onFinish: (group?: Group) => void;
}

const NewGroup: React.FC<Props> = ({ onFinish }) => {
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const { projectID = "" } = useParams<{ projectID: string }>();

  const [createGroup, { loading: creatingGroup }] = useCreateGroup();

  return (
    <ActionModal
      onClose={() => onFinish()}
      disableConfirmButtonIf={!groupName || creatingGroup}
      className="w-3/5"
      onSave={() => {
        if (creatingGroup || !groupName) return;
        createGroup({ variables: { projectID, groupName, description } })
          .then((res) => {
            if (res.data?.groupCreate.__typename === "ProjectDoesNotAllowGroupCreation") {
              toast.error("This project does not allow group creation");
            }
            onFinish();
          })
          .catch((err) => {
            toast.error("The group could not be created");
            console.error(err);
          });
        setGroupName("");
      }}
      blockNavigateAwayIf={groupName.length > 0 || description.length > 0}
      loading={creatingGroup}
    >
      <div className="z-50 flex flex-col rounded-t-lg bg-white px-4 py-4 shadow">
        <div className="mb-2 text-lg font-medium">Add new group</div>
        <label className="mb-1 text-sm font-medium">Group Name</label>
        <Input
          type="text"
          value={groupName}
          data-testid="addGroupName"
          autoFocus
          onChange={(e) => setGroupName(e.target.value)}
          className="text-sm"
        />
        <label className="mt-4 mb-1 text-sm font-medium">Group Description</label>

        <TextArea
          value={description}
          data-testid="addGroupDescription"
          onChange={(e) => setDescription(e.target.value)}
          className="h-20 text-sm"
        />
      </div>
    </ActionModal>
  );
};

export default NewGroup;
