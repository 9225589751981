import { CaretRight } from "@phosphor-icons/react";
import { Button } from "Components/Basic/Buttons";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import EditGroup from "Components/Simulations/group/EditGroup";
import { GetGroup } from "api/group/getGroup";
import { useGetUser } from "api/userManagement/getUser";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

type Props = {
  group: GetGroup | undefined;
  projectId: string;
  loadingScenarios: boolean;
};

const GroupInformation = ({ group, projectId, loadingScenarios }: Props) => {
  const [editingGroup, setEditingGroup] = useState(false);
  const [showGroupInformation, setShowGroupInformation] = useState(false);
  const groupOwnerData = useGetUser(
    group?.createdBy.__typename === "User" ? group.createdBy.id : ""
  ).data?.user;
  const groupOwner = groupOwnerData?.__typename === "User" ? groupOwnerData : undefined;

  if (!group) return null;
  return (
    <div className="border-t border-b border-gray-300 bg-gray-100">
      <div
        data-testid="toggleGroupInformation"
        className="flex cursor-pointer items-center justify-between px-4 py-2 text-sm font-medium"
        onClick={() => setShowGroupInformation((prev) => !prev)}
      >
        <div className="mr-1 flex items-center py-1 text-left text-sm font-medium uppercase tracking-wider text-gray-700">
          <span className="mr-2">Group information</span>
          {loadingScenarios && <LoadingIcon />}
        </div>
        <CaretRight
          weight="bold"
          className={`ml-4 h-4 w-4 text-gray-500 transition-all ${
            showGroupInformation && "rotate-90"
          }`}
        />
      </div>
      <AnimatePresence>
        {showGroupInformation && (
          <motion.div
            key="content"
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ bounce: 0, ease: "easeInOut" }}
            variants={{
              open: { height: "auto", overflow: "visible", opacity: 1 },
              closed: { height: 0, overflow: "hidden", opacity: 0 },
            }}
            className="border-t border-gray-200 bg-white"
          >
            <dl className="grid border-collapse grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
              <div className="border-b p-5 sm:col-span-1 sm:border-r xl:border-b-0">
                <div className="flex items-center justify-between sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Group name</dt>
                  <Button
                    onClick={() => setEditingGroup(true)}
                    className="pr-2 text-sm leading-4 text-indigo-700"
                    buttonType="small"
                    data-testid="editGroupNameButton"
                  >
                    Edit name
                  </Button>
                </div>
                <dd data-testid="groupName" className="mt-1 text-sm text-gray-900">
                  {group.name}
                </dd>
              </div>
              <div className="border-b p-5 sm:col-span-1 xl:border-r xl:border-b-0">
                <dt className="text-sm font-medium text-gray-500">Created</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {format(group.createdAt, "d/MM/yyyy")}
                </dd>
              </div>
              <div className="border-b p-5 sm:col-span-1 sm:border-r sm:border-b-0">
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-medium text-gray-500">Description</dt>
                  <Button
                    data-testid="editGroupDescriptionButton"
                    onClick={() => setEditingGroup(true)}
                    className="pr-2 text-sm leading-4 text-indigo-700"
                    buttonType="small"
                  >
                    {group.description && group.description.length > 0
                      ? "Edit description"
                      : "Add description"}
                  </Button>
                </div>
                <dd data-testid="groupDescription" className="mt-1 text-sm text-gray-900">
                  {group.description ? (
                    group.description
                  ) : (
                    <span className="text-sm italic">No description</span>
                  )}
                </dd>
              </div>
              <div className="p-5 sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Created by</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {groupOwner ? groupOwner.fullName : <LoadingIcon />}
                </dd>
              </div>
            </dl>
            <AnimatePresence>
              {projectId && editingGroup && (
                <EditGroup
                  key={`${projectId}EditGroupModal`}
                  group={group}
                  onFinish={() => setEditingGroup(false)}
                />
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GroupInformation;
