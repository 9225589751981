import { ArrowLeft } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import React from "react";

const PageNotFound = () => (
  <div data-testid="pageNotFound" className="flex h-screen items-center justify-center">
    <div className="text-center">
      <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
        Page not found.
      </h1>
      <p className="mt-2 text-base text-gray-500">
        Sorry, we couldn’t find the page you’re looking for or you do not have the permission
        to view it.
      </p>
      <Link
        to="/"
        className="mt-6 flex items-center justify-center text-base font-medium text-indigo-600 hover:text-indigo-500"
      >
        <ArrowLeft size={20} weight="bold" className="mr-2" /> Go to Tools
      </Link>
    </div>
  </div>
);

export default PageNotFound;
