/*
 * This file is where we describe the different types of zIndex and their values
 * The variable names will be used to auto-generate the type throughout the app.
 *
 * !! IMPORTANT INFORMATION !!
 * If you adjust the value of these, you will have to go to ./ZIndexTailwind.js
 * and adjust the values to reflect the minimum and maximum value of this file.
 * By setting the minimum and maximum value in there, you will generate the neccesary Tailwind classes
 */

export const zNavigation = 100;
export const zFeedbackButton = 101;
export const zModal = 102;
export const zToast = 103;
export const zDropdown = 104;
export const zPopover = 104;
export const zHoverToolTip = 105;
export const zFeatureHighlight = 106;
export const zLoadingOverlay = 107;
export const zFullScreenLoadingOverlay = 108;
