import { useMutation } from "@apollo/client";
import { graphql } from "../../GraphQL/gql";

const DELETE_DATA_SET_SCHEDULE = graphql(`
  mutation DELETE_DATA_SET_SCHEDULE($dataSetId: ID!, $projectId: ID!, $scheduleTag: String!) {
    dataSetScheduleDelete(
      dataSetId: $dataSetId
      projectId: $projectId
      scheduleTag: $scheduleTag
    ) {
      ... on ScheduledDataSet {
        id
        tags
      }
      ... on DataSetNotFound {
        __typename
        dataSetId
        projectId
      }
      ... on DataSetNotScheduled {
        __typename
        dataSetId
        projectId
      }
      ... on DataSetScheduleNotFound {
        __typename
        dataSetId
        projectId
        scheduleTag
      }
    }
  }
`);

export const useDeleteDataSetSchedule = () => {
  const mutation = useMutation(DELETE_DATA_SET_SCHEDULE);
  return mutation;
};
