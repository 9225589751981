import { ContextMenuButton } from "./ContextMenuButton";
import { ContextMenuSeparator } from "./ContextMenuSeparator";
import { ContextMenuSub } from "./ContextMenuSub";
import { ContextMenuWrapper } from "./ContextMenuWrapper";

/**
 * The context menu is used for right click functionality on any component
 */
const ContextMenu = {
  Wrapper: ContextMenuWrapper,
  Sub: ContextMenuSub,
  Button: ContextMenuButton,
  Separator: ContextMenuSeparator,
};

export default ContextMenu;
