import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { ConnectedRepositorySortBy, SortOrder } from "GraphQL/gql/graphql";

export const CONNECTED_REPOSITORIES_SEARCH = graphql(`
  query CONNECTED_REPOSITORIES_SEARCH(
    $query: String!
    $sortBy: ConnectedRepositorySortBy!
    $sortOrder: SortOrder!
    $first: Int!
    $after: String
  ) {
    connectedRepositoriesSearch(
      query: $query
      sortBy: $sortBy
      sortOrder: $sortOrder
      first: $first
      after: $after
    ) {
      edges {
        __typename
        node {
          __typename
          id
          displayName
          path
          description
          updatedAt
          createdBy {
            __typename
            fullName
          }
          lastCommit {
            ... on Commit {
              __typename
              id
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
    }
  }
`);

type Props = {
  query: string;
  sortOrder: SortOrder;
  sortBy: ConnectedRepositorySortBy;
};

export const useConnectedRepositoriesSearch = ({ query, sortOrder, sortBy }: Props) => {
  const result = useQuery(CONNECTED_REPOSITORIES_SEARCH, {
    variables: { query, sortBy, sortOrder, first: 30, after: null },
    fetchPolicy: "cache-and-network",
  });

  return result;
};
