import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { PeriodSettingInput } from "GraphQL/gql/graphql";
import { PlotData } from "plotly.js";
import React, { Suspense, useMemo } from "react";
import {
  applyScalingAndOffset,
  getDayData,
  tickTexts,
  tickValues,
} from "../utils/ScheduleDayGraphHelpers";

const Plot = React.lazy(() => import("react-plotly.js"));

interface Props {
  period: PeriodSettingInput;
  scaling?: number;
  offset?: number;
  unit?: string;
}

const ScheduleDayGraph: React.FC<Props> = ({ period, unit, scaling, offset }) => {
  const dayData = useMemo(() => getDayData(period), [period]);

  const range = useMemo(() => {
    const timeslotValues = period.timeslots.map(({ value }) => value);
    timeslotValues.push(period.defaultValue);
    const min = applyScalingAndOffset(Math.min(...timeslotValues), scaling, offset);
    const max = applyScalingAndOffset(Math.max(...timeslotValues), scaling, offset);

    return {
      min,
      max: Math.floor(max * 1.1) + 1, // increase max range by 10% to have whitespace in top of graph
    };
  }, [period.timeslots, period.defaultValue, scaling, offset]);

  const mappedData = useMemo(() => {
    const plotData: Partial<PlotData>[] = [];

    const xAxis = dayData.map((v) => v.time.inSeconds());

    if (dayData && xAxis)
      plotData.push({
        x: xAxis,
        y: dayData.map((d) => applyScalingAndOffset(d.setting, scaling, offset)),
        type: "scatter",
        mode: "lines",
        hoverinfo: "skip",
        line: {
          color: "#4F46E5", // tailwind indigo-600
          width: 4,
        },
      });
    return plotData;
  }, [dayData, offset, scaling]);

  return (
    <div className="relative flex w-full items-center overflow-hidden">
      <Suspense
        fallback={
          <div className="w-full flex justify-center">
            <LoadingIcon className="w-5 h-5" />
          </div>
        }
      >
        <Plot
          className="w-full cursor-default"
          data={mappedData}
          useResizeHandler
          config={{ displayModeBar: false }}
          layout={{
            autosize: true,
            paper_bgcolor: "#FFF",
            plot_bgcolor: "#FFF",
            margin: {
              t: 20,
              b: 70,
              l: 70,
              r: 20,
              pad: 10,
            },
            yaxis: {
              fixedrange: true,
              ticksuffix: ` ${unit || ""}`,
              range: [range.min, range.max],
            },
            xaxis: {
              fixedrange: true,
              tickvals: tickValues,
              ticktext: tickTexts,
            },
          }}
        />
      </Suspense>
    </div>
  );
};

export default ScheduleDayGraph;
