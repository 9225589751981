import { Combobox } from "@headlessui/react";
import { Check, DotsThree, Plus, X } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import { ExtendedOption, SelectOption } from "model/datatypes";
import React, { MouseEvent, useState } from "react";
import Tooltip from "thermo/tooltip/Tooltip";
import classNames from "utils/jsUtils/className";

interface Props {
  option: SelectOption | ExtendedOption;
  selectedOptionID: string;
  createNew?: boolean;
}

const DropdownSearchOption: React.FC<Props> = ({ option, selectedOptionID, createNew }) => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const isExtendedOptionType = "icon" in option;

  return (
    <Combobox.Option
      key={option.id}
      data-testid="dropdownSearchOption"
      value={option}
      onClick={(e: MouseEvent<HTMLLIElement>) => {
        const optionOnClick = (option as ExtendedOption).onClick;
        if (optionOnClick) {
          e.preventDefault();
          optionOnClick();
        }
      }}
      className={({ active }) =>
        classNames(
          "relative w-full cursor-pointer select-none py-2 px-3 text-gray-900 transition-colors duration-75",
          active && "bg-gray-100"
        )
      }
    >
      {() => {
        const selected = option.id === selectedOptionID;
        return (
          <div className="flex w-full flex-col items-center">
            <div className="flex w-full">
              <div className="flex w-full items-center gap-2 truncate">
                {isExtendedOptionType && option.icon}
                <div className={classNames("truncate", selected && "text-indigo-600")}>
                  {option.display}
                </div>
                {selected && (
                  <span className="flex items-center text-indigo-600">
                    <Check weight="bold" className="w-3. h-3.5" aria-hidden="true" />
                  </span>
                )}
              </div>
              {isExtendedOptionType && option.moreOptions && (
                <div
                  data-testid="dropdownExtendedOptionControl"
                  className="flex w-full items-center justify-end gap-2"
                >
                  <AnimatePresence>
                    {showMoreOptions && (
                      <motion.div
                        className="flex gap-2 overflow-hidden"
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{
                          duration: 0.25,
                          ease: "easeInOut",
                        }}
                      >
                        {(option as ExtendedOption).moreOptions?.map((secondaryOption) => (
                          <Tooltip key={secondaryOption.title} label={secondaryOption.title}>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                secondaryOption.onClick();
                              }}
                              className="rounded-full p-0.5 text-gray-500 hover:bg-white hover:text-indigo-700"
                              data-testid="secondaryOption"
                            >
                              {secondaryOption.icon}
                            </div>
                          </Tooltip>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                  {showMoreOptions ? (
                    <X
                      className="h-3.5 w-3.5 text-indigo-700"
                      data-testid="hideSearchItemOptions"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMoreOptions(false);
                      }}
                    />
                  ) : (
                    <DotsThree
                      className="h-3.5 w-3.5 text-indigo-700"
                      data-testid="showSearchItemOptions"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMoreOptions(true);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {!!option.subText && (
              <p
                className={classNames(
                  "mt-2 w-full text-gray-400",
                  createNew && "flex items-center gap-1 font-medium text-indigo-500"
                )}
              >
                {createNew && <Plus size={12} weight="bold" />}
                {option.subText}
              </p>
            )}
          </div>
        );
      }}
    </Combobox.Option>
  );
};

export default DropdownSearchOption;
