import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { Button } from "Components/Basic/Buttons";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { useCloudInstance } from "Components/Providers/Firebase/FirebaseProvider";
import { useFirebase } from "api/useFirebase";
import React, { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import EMLogo from "resources/EMlogo.svg";
import NumerousLogo from "resources/NumerousLogo";
import { useGlobalDispatch, useGlobalState } from "store";
import { Alert } from "thermo/Alert/Alert";
import Text from "thermo/typography/Typography";
import { ResetPasswordForm } from "./PasswordReset/ResetPasswordForm/ResetPasswordForm";

const LoginPage: React.FC<Record<string, unknown>> = () => {
  const fb = useFirebase();
  const { authStatus } = useGlobalState();
  const cloudInstance = useCloudInstance();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigateApp();
  const location = useLocation();
  const dispatch = useGlobalDispatch();

  const [resettingPassword, setResettingPassword] = useState(false);

  const sandboxEnvironment = window.location.host.split(".")[0].toLowerCase() === "sandbox";

  // NUM-502
  const authenticate = () => {
    if (!fb) {
      console.error("Firebase authentication was not available - could not login");
      return;
    }

    setLoading(true);

    fb.auth()
      .signInWithEmailAndPassword(email, password)
      .then((u) => {
        if (u.user === null) return;
        dispatch({ type: "SET_AUTH_STATUS", payload: "authenticated" });
        navigate(location.state?.navigateTo || "/", { useAsCompletePath: true });
      })
      .catch((err) => {
        dispatch({ type: "SET_AUTH_STATUS", payload: "error" });
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  const renderOrganistaion = () => {
    if (cloudInstance?.id === "em")
      return (
        <div className="flex w-full justify-center">
          <img className="mx-auto h-14 w-auto" src={EMLogo} alt="Logo" />
        </div>
      );
    return (
      <div className="text-sm">
        <label className="block text-sm font-medium text-gray-700">Cloud</label>
        <div>{cloudInstance?.name || "Unknown"}</div>
      </div>
    );
  };

  if (authStatus === "pending") return <FullScreenLoadingOverlay />;
  if (authStatus === "authenticated") return <Navigate to="/" />;

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {loading && <LoadingOverlay />}

      <div className="w-full max-w-md">
        <div className="mb-6 flex flex-col items-center">
          <NumerousLogo className="mb-6 w-32" />
          <Text.H1 className="text-gray-800 font-semibold">Sign in</Text.H1>
          <Text.Small className="mt-2 text-gray-700">
            Or{" "}
            {sandboxEnvironment && (
              <button
                className="font-medium text-blue-600 transition duration-150 ease-in-out hover:text-blue-500 focus:underline focus:outline-none"
                onClick={() => {
                  navigate("/signup", { useAsCompletePath: true });
                }}
              >
                create an account
              </button>
            )}
            {!sandboxEnvironment && "contact an organization owner to request an invitation"}
          </Text.Small>
        </div>
        <div className="rounded-md border border-gray-200 bg-white px-8 py-6 shadow-md">
          {renderOrganistaion()}

          {error && (
            <div className="my-4">
              <Alert
                type="error"
                text="Invalid credentials. Please check your username and password and try again."
              />
            </div>
          )}

          {resettingPassword ? (
            <ResetPasswordForm setResettingPassword={() => setResettingPassword(false)} />
          ) : (
            <>
              <label htmlFor="email" className="mt-4 block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="email"
                name="email"
                data-testid="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={loading}
                className="block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
              <label
                htmlFor="password"
                className="mt-4 block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                name="password"
                data-testid="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onKeyDown={(e) => e.key === "Enter" && authenticate()}
              />
              <div className="mt-2 flex items-center justify-end">
                <button
                  onClick={() => setResettingPassword(true)}
                  className="text-sm text-indigo-600"
                >
                  Forgot password?
                </button>
              </div>
              <div className="mt-4">
                <Button
                  className="w-full text-sm"
                  type="submit"
                  onClick={() => authenticate()}
                  data-testid="signInButton"
                  disabled={!email || !password || loading}
                  buttonType="primary"
                >
                  Sign in
                </Button>
              </div>
              <div className="mt-3 flex justify-center">
                <Text.Small className="flex gap-1 text-gray-700">
                  Don&apos;t have an account?
                  <Link to="/signup">
                    <Text.Small className="text-primary-600">Sign up</Text.Small>
                  </Link>
                </Text.Small>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
