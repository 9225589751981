import Link from "Components/AppNavigation/Link";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import React, { useEffect } from "react";
import NumerousLogo from "resources/NumerousLogo";

const OrganizationSlugNotFound = () => {
  const organizationSlug = useOrganizationSlugFromParams();

  useEffect(() => {
    // If the organization was not found and it's inserted in localStorage, it needs to be removed
    // Otherwise the user can experience a loop where they keep ending up on this page.
    if (organizationSlug !== localStorage.getItem("activeOrganizationSlug")) return;
    localStorage.removeItem("activeOrganizationSlug");
  }, [organizationSlug]);

  return (
    <div
      data-testid="organizationSlugNotFound"
      className="text-gray-600 flex flex-col items-center gap-1 justify-center h-screen"
    >
      <NumerousLogo className="mb-8 h-10 w-auto" />
      <p>
        The organization URL &quot;{organizationSlug}&quot; does not match any of your
        organizations.
      </p>
      <div>
        Check for errors or&nbsp;
        <Link to="/" useAsCompletePath className="text-indigo-600">
          Select an organization
        </Link>
      </div>
    </div>
  );
};

export default OrganizationSlugNotFound;
