import { CaretRight, Plus, Warning } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { SidebarButtonWrapper } from "Components/Sidebar/SidebarButtonWrapper";
import Tooltip from "thermo/tooltip/Tooltip";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

type Props = {
  loading: boolean;
  error: boolean;
  isShowingWorkspaceList: boolean;
  toggleShowWorkspaceList: () => void;
};

export const WorkspaceListHeader = ({
  loading,
  error,
  isShowingWorkspaceList,
  toggleShowWorkspaceList,
}: Props) => {
  const navigate = useNavigateApp();
  return (
    <SidebarButtonWrapper>
      <div
        data-testid="workspaceListHeaderButton"
        onClick={toggleShowWorkspaceList}
        className="flex text-gray-500 px-2 mb-0.5 py-1 items-center justify-between w-full"
      >
        <div className="flex items-center">
          <CaretRight
            data-testid="workspaceListCollapseIcon"
            size={14}
            className={classNames(
              isShowingWorkspaceList && "rotate-90",
              "transition-all duration-150"
            )}
          />
          <Text.Small className="ml-2" data-testid="workspaceListHeader">
            Your workspaces
          </Text.Small>
          {loading && <LoadingIcon className="ml-3" />}
          {!loading && error && (
            <Tooltip label="There was an error loading your workspaces" placement="right">
              <Warning
                data-testid="workspaceHeaderError"
                className="ml-3 text-warning-400 hover:text-warning-500"
                size={16}
              />
            </Tooltip>
          )}
        </div>

        <Tooltip label="Create workspace" placement="right">
          <button
            data-testid="addWorkspaceButton"
            onClick={(e) => {
              e.stopPropagation();
              navigate("/add-workspace");
            }}
            className="hover:text-gray-900 flex items-center transition-colors duration-75"
          >
            <Plus size={14} />
          </button>
        </Tooltip>
      </div>
    </SidebarButtonWrapper>
  );
};
