import { Combobox } from "@headlessui/react";
import { CaretDown } from "@phosphor-icons/react";
import { ExtendedOption, SelectOption } from "model/datatypes";
import React, { FocusEvent, useMemo, useState } from "react";
import classNames from "utils/jsUtils/className";
import getUUID from "utils/jsUtils/getUUID";
import { SectionedOptions } from "./DropdownSearch";
import DropdownSearchOption from "./DropdownSearchOption";

interface Props {
  label?: string;
  options: SelectOption[] | SectionedOptions;
  selectedOption: SelectOption | ExtendedOption;
  setSelectedOption: (selected: SelectOption | ExtendedOption) => void;
  inlineButtons?: JSX.Element;
  className?: string;
  "data-testid"?: string;
  createNew: boolean;
}

const DropdownSearchAddCustom: React.FC<Props> = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
  inlineButtons,
  createNew,
  className = "",
  ...htmlProps
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [queryOption, setQueryOption] = useState<SelectOption>();

  const isSectionedOptions = useMemo(
    () =>
      (
        sectionedOptions: SelectOption[] | SectionedOptions
      ): sectionedOptions is SectionedOptions =>
        "sections" in options,
    [options]
  );

  const filteredOptions: SelectOption[] | SectionedOptions = useMemo(() => {
    if (isSectionedOptions(options)) {
      let newSection = options?.sections;
      if (queryOption && createNew) {
        newSection = [{ New: [queryOption] }, ...newSection];
      }
      return searchQuery === ""
        ? options
        : ({
            sections: newSection?.map((section) => {
              const key = Object.keys(section)[0];
              return {
                [key]: section[key]?.filter(
                  (option: SelectOption | ExtendedOption) =>
                    "shouldAlwaysDisplay" in option ||
                    option.display.toLowerCase().includes(searchQuery.toLocaleLowerCase())
                ),
              };
            }),
          } as SectionedOptions);
    }

    const queryOptions = queryOption
      ? [
          queryOption,
          ...options.filter((option) =>
            option.display.toLowerCase().includes(searchQuery.toLowerCase())
          ),
        ]
      : options;
    if (searchQuery === "") {
      return options;
    }

    //* If searchQuery text already exists, don't show the + Create new btn
    const option = options.find(
      (op) => op.display.toLowerCase() === searchQuery.toLowerCase()
    );
    if (option) {
      return [
        option,
        ...options.filter(
          (op) =>
            op.display.toLowerCase().includes(searchQuery.toLowerCase()) &&
            op.display.toLowerCase() !== searchQuery.toLowerCase()
        ),
      ];
    }

    return queryOptions;
  }, [searchQuery, options, queryOption, isSectionedOptions, createNew]);

  const searchHasMatches = isSectionedOptions(options)
    ? (filteredOptions as SectionedOptions).sections.some((section) => {
        const key = Object.keys(section)[0];
        return section[key].length > 0;
      })
    : (filteredOptions as SelectOption[]).length > 0;

  return (
    <Combobox
      data-testid="dropdownSearch"
      {...htmlProps}
      as="div"
      value={selectedOption}
      onChange={setSelectedOption}
      className={classNames("w-full", className)}
    >
      {label && (
        <Combobox.Label className="block text-sm font-medium text-gray-700">
          {label}
        </Combobox.Label>
      )}
      <div className="relative">
        <div className="font-normal flex overflow-hidden rounded-md border border-gray-300">
          <Combobox.Input
            className="w-full truncate border-none bg-white py-2 pl-3 pr-3 shadow-sm focus:border-none focus:ring-0 sm:text-sm"
            data-testid="dropdownInput"
            placeholder="Search or type to create"
            onChange={(e) => {
              setSearchQuery(e.target.value.trim());
              setQueryOption({
                id: getUUID(),
                display: e.target.value.trim(),
                subText: "Create new",
              });
            }}
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
            displayValue={(option: SelectOption) => option.display}
          />
          <div className="flex bg-white">
            {inlineButtons}
            <Combobox.Button
              type="button"
              data-testid="toggleDropdownAddCustom"
              className="flex items-center rounded-r-md px-2 text-gray-400 transition-colors duration-100 hover:bg-indigo-600 hover:text-white focus:outline-none"
            >
              <CaretDown className="h-4 w-4" weight="bold" aria-hidden="true" />
            </Combobox.Button>
          </div>
        </div>

        {/* OPTIONS DROPDOWN */}
        <Combobox.Options
          data-testid="dropdownOptions"
          className="absolute z-20 mt-1 max-h-80 w-full divide-y divide-gray-200 overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {searchHasMatches &&
            (isSectionedOptions(options)
              ? (filteredOptions as SectionedOptions).sections.map((section) => {
                  const key = Object.keys(section)[0];
                  return (
                    section[key].length > 0 && (
                      <div key={key} data-testid={`section-${key}`}>
                        {key !== "undefined" && (
                          <div className="ml-3 mt-3 mb-1 text-sm font-bold text-indigo-600 first-letter:capitalize">
                            {key}
                          </div>
                        )}
                        {section[key].map((option) => (
                          <DropdownSearchOption
                            key={option.id}
                            option={
                              option.id === selectedOption.id
                                ? { ...option, subText: "" }
                                : option
                            }
                            selectedOptionID={selectedOption.id}
                            createNew={createNew}
                          />
                        ))}
                      </div>
                    )
                  );
                })
              : (filteredOptions as SelectOption[]).map((option) => (
                  <DropdownSearchOption
                    key={option.id}
                    option={
                      option.id === selectedOption.id ? { ...option, subText: "" } : option
                    }
                    selectedOptionID={selectedOption.id}
                    createNew={createNew}
                  />
                )))}
          {!searchHasMatches && (
            <div className="italic text-gray-500 cursor-default select-none py-2 pl-3 pr-9 font-normal">
              No matches found
            </div>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default DropdownSearchAddCustom;
