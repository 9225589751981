import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const ADD_TEAM_TO_PROJECT_MUTATION = graphql(`
  mutation ADD_TEAM_TO_PROJECT_MUTATION($projectId: ID!, $teamId: ID!) {
    projectAddTeam(projectId: $projectId, teamId: $teamId)
  }
`);

export const useAddTeamToProject = () => {
  const mutation = useMutation(ADD_TEAM_TO_PROJECT_MUTATION); // TODO Requires an update function once we fetch teams on a project through GraphQL

  return mutation;
};
