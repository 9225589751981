export const parseInput = (point: string) => {
  let internalPoint = point;
  if (internalPoint.includes(",")) internalPoint = internalPoint.replace(",", ".");

  const num = parseFloat(internalPoint);
  if (!Number.isNaN(num)) return num;
  //add checks for infinite/other stuff.....

  //if NaN return original string:
  return internalPoint;
};
