import { WifiX } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import Tooltip from "thermo/tooltip/Tooltip";

type Props = {
  isOnline: boolean;
};

export const OfflineIndicator = ({ isOnline }: Props) => {
  return (
    <AnimatePresence>
      {!isOnline && (
        <Tooltip label="There is no internet connection">
          <motion.div
            animate={{ opacity: 100 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className="mr-1"
            data-testid="offlineIndicator"
          >
            <div className="elevation-raised h-6 w-6 border-warning-400 rounded-full border border-opacity-25 flex items-center justify-center">
              <WifiX className="text-warning-600" size={17} />
            </div>
          </motion.div>
        </Tooltip>
      )}
    </AnimatePresence>
  );
};
