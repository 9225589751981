/* eslint-disable import/no-unused-modules */
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { RpcOptions, UnaryCall } from "@protobuf-ts/runtime-rpc";
import { fromUnixTime } from "date-fns";
import { ReadScenario, Scenario, ScenarioStatsRequest } from "grpc/client/spm_pb";
import { SPMClient, JobManagerClient, FileManagerClient } from "grpc/client/spm_pb.client";
import { ScenarioMetadata, TimeInfo } from "model/datatypes";

//enable gRPC devtool extension
//@ts-ignore
// const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
export const getOptions = (
  idToken: string,
  organizationSlug: string,
  timeout?: number
): RpcOptions => ({
  ...(typeof timeout === "number" ? { timeout } : {}),
  interceptors: [
    {
      // adds auth header to unary requests
      interceptUnary(next, method, input, options: RpcOptions): UnaryCall {
        let myOptions = options;
        if (!myOptions.meta) {
          myOptions.meta = {};
        }
        myOptions.meta.authorization = idToken;
        myOptions.meta["numerous-organization"] = organizationSlug;
        return next(method, input, myOptions);
      },
    },
  ],
});

export const getGRPCClient = (
  defaultAPI: string,
  idToken: string,
  organizationSlug: string
) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  let transport = new GrpcWebFetchTransport({
    baseUrl: serverAPI,
    credentials_: { idToken },
    meta: {
      authorization: idToken,
      "numerous-organization": organizationSlug,
    },
  });
  let spmClient = new SPMClient(transport);
  return spmClient;
};

export const getGRPCClientUnauthorized = (defaultAPI: string) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  let transport = new GrpcWebFetchTransport({
    baseUrl: serverAPI,
  });
  let spmClient = new SPMClient(transport);
  return spmClient;
};

export const getJobManagerClient = (
  defaultAPI: string,
  idToken: string,
  organizationSlug: string
) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  let transport = new GrpcWebFetchTransport({
    baseUrl: serverAPI,
    credentials_: { idToken },
    meta: {
      authorization: idToken,
      "numerous-organization": organizationSlug,
    },
  });
  let jobManagerClient = new JobManagerClient(transport);
  return jobManagerClient;
};

export const getFileManagerClient = (
  defaultAPI: string,
  idToken: string,
  organizationSlug: string
) => {
  const serverAPI = localStorage.getItem("customGRPCAPI") || defaultAPI;
  let transport = new GrpcWebFetchTransport({
    baseUrl: serverAPI,
    credentials_: { idToken },
    meta: {
      authorization: idToken,
      "numerous-organization": organizationSlug,
    },
  });
  let fileManagerClient = new FileManagerClient(transport);
  return fileManagerClient;
};

type Dataframe = {
  [key: string]: number[];
};

export const readDataExample = (conf: {
  grpcURL: string;
  idToken: string;
  scenarioID: string;
  projectID: string;
  executionID?: string;
  tags: string[];
  organizationSlug: string;
}) =>
  new Promise<Dataframe>((resolve, reject) => {
    const { grpcURL, tags, scenarioID, projectID, idToken, executionID, organizationSlug } =
      conf;

    const readScenario = ReadScenario.create({
      listen: false,
      project: projectID,
      scenario: scenarioID,
      tags,
      ...(executionID ? { execution: executionID } : {}),
    });

    const stream = getGRPCClient(grpcURL, idToken, organizationSlug).readData(
      readScenario,
      getOptions(idToken, organizationSlug, 50000)
    );

    const data: Dataframe = {};

    stream.responses.onNext((list) => {
      if (!list) return;
      list.data.forEach((block) => {
        const prev = data[block.tag];
        if (Array.isArray(prev)) data[block.tag] = [...prev, ...block.values];
        else data[block.tag] = block.values;
      });
    });

    stream.responses.onError((error) => {
      reject(error);
    });

    stream.responses.onComplete(() => {
      resolve(data);
    });
  });

export const readLatestMainExecution = async (
  grpcURL: string,
  idToken: string,
  projectId: string,
  scenarioId: string,
  organizationSlug: string
) => {
  const scenario = Scenario.create({
    project: projectId,
    scenario: scenarioId,
  });

  const execution = await getGRPCClient(
    grpcURL,
    idToken,
    organizationSlug
  ).getLatestMainExecution(scenario, getOptions(idToken, organizationSlug));
  return execution.response.executionId;
};

export const readScenarioTags = async (
  grpcURL: string,
  idToken: string,
  projectId: string,
  scenarioId: string,
  executionID: string,
  organizationSlug: string
) => {
  const scenario = Scenario.create({
    project: projectId,
    scenario: scenarioId,
    execution: executionID,
  });

  const scenarioMetadata = await getGRPCClient(
    grpcURL,
    idToken,
    organizationSlug
  ).getScenarioMetaData(scenario, getOptions(idToken, organizationSlug)).response;

  return scenarioMetadata.tags;
};

export const readTimeInfo = async (
  grpcURL: string,
  idToken: string,
  organizationSlug: string,
  projectId: string,
  scenarioId: string,
  executionId?: string
) => {
  const options = getOptions(idToken, organizationSlug);
  const request = {
    project: projectId,
    scenario: scenarioId,
    ...(executionId ? { execution: executionId } : {}),
  };
  const client = getGRPCClient(grpcURL, idToken, organizationSlug);
  const rawDataStats = await client.getScenarioDataStats(
    ScenarioStatsRequest.create(request),
    options
  );
  const rawMetadata = await client.getScenarioMetaData(Scenario.create(request), options);

  const scenarioStats = rawDataStats.response;
  const scenarioMetadata = rawMetadata.response;
  const startTimeVal = scenarioStats.min + scenarioMetadata.offset;
  const endTimeVal = scenarioStats.max + scenarioMetadata.offset;
  const epochType = scenarioMetadata.epochType as ScenarioMetadata["epochType"];
  const timeInfo: TimeInfo = {
    scenarioID: scenarioId,
    scenarioStats,
    scenarioMetadata: {
      ...scenarioMetadata,
      epochType,
    },
    offset: scenarioMetadata.offset,
    startTime: fromUnixTime(startTimeVal),
    endTime: fromUnixTime(endTimeVal),
    originalStart: fromUnixTime(scenarioStats.min),
    originalEnd: fromUnixTime(scenarioStats.max),
  };

  return { timeInfo, rawMetadata: scenarioMetadata };
};

export const getDatasetClosed = async (
  grpcURL: string,
  idToken: string,
  organizationSlug: string,
  projectId: string,
  scenarioId: string,
  executionId?: string
) => {
  const scenario = Scenario.create({
    project: projectId,
    scenario: scenarioId,
    ...(executionId ? { execution: executionId } : {}),
  });

  const closed = await getGRPCClient(grpcURL, idToken, organizationSlug).getDataSetClosed(
    scenario,
    getOptions(idToken, organizationSlug)
  ).response;
  return closed.isClosed;
};
