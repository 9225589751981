import { useEffect, useState, useCallback } from "react";

//returns an absolute position of a ref element on page

const getPagePosition = (current: HTMLElement) => {
  const boundingRect = current.getBoundingClientRect();
  const screenScrollPosX = window.pageXOffset || document.documentElement.scrollLeft;
  const screenScrollPosY = window.pageYOffset || document.documentElement.scrollTop;
  const screenMarginRight = window.innerWidth - screenScrollPosX - boundingRect.right;

  const contentBelowScreen =
    document.documentElement.scrollHeight - (window.innerHeight + screenScrollPosY); //pixels of content below the cuttent screen

  return {
    distanceLeft: boundingRect.left + screenScrollPosX,
    distanceTop: boundingRect.top + screenScrollPosY,
    distanceBottom: window.innerHeight - boundingRect.bottom + contentBelowScreen,
    distanceRight: screenMarginRight,
    boundingRect,
  };
};

type PagePosition = {
  distanceLeft: number;
  distanceTop: number; //of scrollHeight
  distanceBottom: number; //of scrollHeight
  distanceRight: number;
  boundingRect?: DOMRect;
};

export const usePagePosition = (ref: React.RefObject<HTMLElement>) => {
  const [pagePosition, setPagePosition] = useState<PagePosition>({
    distanceLeft: 0,
    distanceTop: 0,
    distanceBottom: 0,
    distanceRight: 0,
  });

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const w = ref.current?.offsetWidth;
    const h = ref.current?.offsetHeight;
    setWidth(w || 0);
    setHeight(h || 0);
  }, [ref]);

  useEffect(() => {
    //get the absolute page position initially, uses a timeout to make sure the layout is in place, otherwise reposition lists will be trasitioning in..
    setTimeout(() => {
      if (ref.current) {
        const currentPagePosition = getPagePosition(ref.current);
        setPagePosition(currentPagePosition);
      }
    }, 500);
  }, [ref]);

  const updatePosition = useCallback(() => {
    if (ref.current) {
      const currentPagePosition = getPagePosition(ref.current);
      setPagePosition(currentPagePosition);
    }
  }, [ref]);

  return { pagePosition, updatePosition, width, height };
};
