import React from "react";
import Text from "thermo/typography/Typography";

export const FinishedSignUpScreen = () => {
  return (
    <div
      data-testid="finishedSignUpScreen"
      className="w-screen h-screen flex justify-center items-center"
    >
      <div className="flex flex-col justify-center items-center">
        <Text.H1>Sign-up successful!</Text.H1>
        <Text.Base className="mt-2">Please check your email for an activation link.</Text.Base>
      </div>
    </div>
  );
};
