import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { ComponentParameter, SelectOption } from "model/datatypes";

const monthOptions: SelectOption[] = [
  { id: "off", display: "None", value: null },
  { id: "1", display: "January", value: 1 },
  { id: "2", display: "February", value: 2 },
  { id: "3", display: "March", value: 3 },
  { id: "4", display: "April", value: 4 },
  { id: "5", display: "May", value: 5 },
  { id: "6", display: "June", value: 6 },
  { id: "7", display: "July", value: 7 },
  { id: "8", display: "August", value: 8 },
  { id: "9", display: "September", value: 9 },
  { id: "10", display: "October", value: 10 },
  { id: "11", display: "November", value: 11 },
  { id: "12", display: "December", value: 12 },
];

export const MonthSelector: React.FC<{
  parameter: ComponentParameter;
  onUpdate: (newParam: ComponentParameter) => void;
}> = ({ parameter, onUpdate }) => (
  <Dropdown
    className="bg-white"
    options={monthOptions}
    onSelect={(dropdownOption) => {
      onUpdate({ ...parameter, value: dropdownOption.value });
    }}
    selectedID={parameter.value === null ? "default" : parameter.value.toString()}
    placeholder="Select month"
  />
);
