import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const REMOVE_COLLABORATOR_FROM_PROJECT = graphql(`
  mutation REMOVE_COLLABORATOR_FROM_PROJECT($collaboratorId: ID!, $projectId: ID!) {
    projectRemoveCollaborator(collaboratorId: $collaboratorId, projectId: $projectId)
  }
`);

export const useRemoveUserFromProject = () => {
  const mutation = useMutation(REMOVE_COLLABORATOR_FROM_PROJECT); // TODO Requires an update function once we fetch users on a project through GraphQL

  return mutation;
};
