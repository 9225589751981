import { Check, Link as LinkIcon, X } from "@phosphor-icons/react";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { useDebounce } from "Components/hooks/useDebounce";
import { useGetRemoteRepositoryLazy } from "api/repository/getRemoteRepositoryLazy";
import React, { useEffect, useState } from "react";
import classNames from "utils/jsUtils/className";

type Props = {
  selectedRemoteRepositoryId: string | null;
  setSelectedRemoteRepositoryId: (id: string | null) => void;
  setIsSearchingByProjectId: (isSearching: boolean) => void;
};

export const RemoteRepositoryById = ({
  selectedRemoteRepositoryId,
  setSelectedRemoteRepositoryId,
  setIsSearchingByProjectId,
}: Props) => {
  const [inputRepositoryId, setInputRepositoryId] = useState("");
  const debouncedSearchTerm = useDebounce(inputRepositoryId, 300);

  const [checkIfRemoteRepositoryIsValid, { loading, data, error }] =
    useGetRemoteRepositoryLazy();

  useEffect(() => {
    // If the user stops writing for more than 300ms this effect will check if the
    // remote repository id is valid
    if (!debouncedSearchTerm) return;

    const handleRemoteRepositoryCheck = (id: string) => {
      checkIfRemoteRepositoryIsValid({
        variables: {
          remoteRepositoryId: id,
          remoteRepositoryProvider: "gitlab.com",
        },
      })
        .then((res) => {
          if (res.data?.remoteRepository.__typename === "RemoteRepository") {
            setSelectedRemoteRepositoryId(id);
          } else {
            setSelectedRemoteRepositoryId(null);
          }
        })
        .catch((err) => console.error(err));
    };

    handleRemoteRepositoryCheck(debouncedSearchTerm);
  }, [checkIfRemoteRepositoryIsValid, debouncedSearchTerm, setSelectedRemoteRepositoryId]);

  const shouldDisplayError =
    inputRepositoryId &&
    (error ||
      data?.remoteRepository.__typename === "RemoteRepositoryNotFound" ||
      data?.remoteRepository.__typename === "RemoteRepositoryProviderNotSupported");

  return (
    <div
      data-testid="remoteRepositoryByIdComponent"
      className={classNames(
        `flex w-full appearance-none disabled:text-gray-500 rounded-t-md bg-white disabled:cursor-not-allowed h-9
             border px-3 py-2 placeholder-gray-400 sm:text-sm`,
        selectedRemoteRepositoryId === "repositoryId" ? "border-indigo-700" : "border-gray-300"
      )}
    >
      <span className="inline-flex items-center text-gray-600 sm:text-sm">
        <LinkIcon className="text-gray-600" size={16} />
      </span>
      <input
        data-testid="remoteRepositoryIdInputField"
        type="text"
        onChange={(e) => {
          const userInputRepositoryId = e.target.value;
          setInputRepositoryId(userInputRepositoryId);
          setIsSearchingByProjectId(!!userInputRepositoryId);
        }}
        className="block min-w-0 flex-1 px-2 py-1 border-none text-sm text-gray-600 focus:ring-0"
        placeholder="Input repository ID"
      />
      <span className="inline-flex items-center text-gray-600 sm:text-sm">
        {loading && <LoadingIcon />}
        {data?.remoteRepository.__typename === "RemoteRepository" && (
          <Check data-testid="remoteRepositorySuccessIcon" className="text-success-600" />
        )}
        {shouldDisplayError && (
          <X data-testid="failRemoteRepositoryCheckIcon" className="text-error-500" />
        )}
      </span>
    </div>
  );
};
