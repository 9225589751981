import { createContext, useContext } from "react";
import { getTransformOrigin } from "utils/getTransformOrigin";

export type PopoverContextType = {
  open: boolean;
  openPopover: () => void;
  closePopover: () => void;
  contentTransformOrigin: ReturnType<typeof getTransformOrigin>;
  triggerProps: Record<string, unknown>;
  contentProps: Record<string, unknown>;
};

export const PopoverContext = createContext<PopoverContextType | null>(null);

export const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (!context) throw Error("Cannot access Popover context outside of Popover root");

  return context;
};
