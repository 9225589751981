import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import Link from "Components/AppNavigation/Link";
import { fuzzyFilter } from "Components/Basic/table/fuzzyFilter";
import { useGetPaginatedProjects } from "api/project/getPaginatedProjects";
import { useArchivedSimulationModels, useSimulationModels } from "api/useFirebase";
import { SimFile } from "model/datatypes";
import { useEffect, useMemo, useState } from "react";
import { useGlobalState } from "store";
import classNames from "utils/jsUtils/className";
import FileTableActions from "../FileTableActions";
import { ExtendedFile } from "../types";

interface IFilesTable {
  simFiles: SimFile[];
}

export const useSetFilesTable = ({ simFiles }: IFilesTable) => {
  const { activeWorkspaceId } = useGlobalState();
  const { data, fetchMore } = useGetPaginatedProjects({
    first: 100,
    workspaceId: activeWorkspaceId,
  });

  useEffect(() => {
    const canFetchMoreProjects = data?.workspace.projects.pageInfo.hasNextPage;
    const endCursor = data?.workspace.projects.pageInfo.endCursor;

    if (canFetchMoreProjects && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    data?.workspace.projects.pageInfo.endCursor,
    data?.workspace.projects.pageInfo.hasNextPage,
    fetchMore,
  ]);
  const allProjects = data?.workspace.projects;

  const { allPublicModels } = useSimulationModels();
  const { archivedModels } = useArchivedSimulationModels();

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);

  // Combine files with projects and simulation models to use them later in the table data setup
  const extendedFiles: ExtendedFile[] = useMemo(() => {
    let tempArr: ExtendedFile[] = [];
    const allModels = [...allPublicModels, ...archivedModels];
    simFiles.forEach((f) => {
      let project = allProjects?.edges.find(({ node }) => node.id === f.projectID)?.node;
      let system = allModels.find((model) => model.id === f.modelID);

      tempArr.push({
        simFile: f,
        project,
        system,
      });
    });
    return tempArr;
  }, [allPublicModels, archivedModels, simFiles, allProjects]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ExtendedFile>();
    return [
      columnHelper.accessor((row) => row.simFile.name, {
        header: "File name",
        id: "name",
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor((row) => row.simFile.tags.join(" "), {
        header: "Tags",
        id: "tags",
        enableSorting: false,
        enableGlobalFilter: true,
        cell: (props) => {
          return props.row.original.simFile.tags
            .filter((tag) => !!tag.indexOf("model_") && !!tag.indexOf("project_"))
            .map((tag: string, index: number) => {
              return (
                <span
                  key={tag}
                  className={classNames(
                    index % 2 && "mt-1",
                    "mr-1 inline-flex rounded-full bg-green-100 px-2 font-semibold leading-5 text-green-800"
                  )}
                >
                  {tag}
                </span>
              );
            });
        },
      }),
      columnHelper.accessor((row) => row.simFile.type, {
        header: "Type",
        id: "type",
        sortingFn: "alphanumeric",
      }),
      // To enable global filtering you can't do row.project?.projectName
      // otherwise getCanGlobalFilter === false and it won't be part of search results
      // therefore we pass "" in case it is undefined.
      columnHelper.accessor((row) => (row.project ? row.project.name : ""), {
        header: "Project",
        id: "project",
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
          return row.original.simFile.projectID ? (
            <Link
              to={`/project/${row.original.simFile.projectID}`}
              className="truncate py-2 text-indigo-600"
            >
              {row.original.project?.name}
            </Link>
          ) : null;
        },
      }),
      columnHelper.accessor((row) => (row.system ? row.system.displayName : ""), {
        header: "Tool",
        id: "system",
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
          return row.original.system?.id ? (
            <Link
              to={`/tools/${row.original.system.id}`}
              className="truncate py-2 text-indigo-600"
            >
              {row.original.system?.displayName}
            </Link>
          ) : null;
        },
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => <FileTableActions row={row} />,
      }),
    ];
  }, []);

  const tableData: ExtendedFile[] = useMemo(
    () =>
      extendedFiles.map((extendedFile) => {
        return {
          simFile: extendedFile.simFile,
          project: extendedFile.project,
          system: extendedFile.system,
        };
      }),
    [extendedFiles]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
  });
  return { table };
};
