import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Current_UserQuery, OrganizationMemberRole } from "GraphQL/gql/graphql";
import { GET_CURRENT_USER } from "api/user/getCurrentUser";

const CREATE_ORGANIZATION = graphql(`
  mutation CREATE_ORGANIZATION($name: String!, $slug: String!, $description: String) {
    organizationCreate(name: $name, slug: $slug, description: $description) {
      ... on Organization {
        id
        name
        slug
        description
        deletionTime
        logoUrl
      }
      ... on OrganizationSlugInvalid {
        __typename
        slug
      }
      ... on OrganizationSlugNotAvailable {
        __typename
        slug
      }
    }
  }
`);

export const useCreateOrganization = () => {
  const mutation = useMutation(CREATE_ORGANIZATION, {
    update(cache, { data }) {
      if (!data) return;
      const createdOrganization = data.organizationCreate;

      if (createdOrganization.__typename !== "Organization") {
        return;
      }

      cache.updateQuery(
        {
          query: GET_CURRENT_USER,
        },
        (cacheData) => {
          if (!cacheData) return undefined;

          const updatedOrganizationList = [createdOrganization, ...cacheData.me.organizations];
          const updatedOrganizationMembershipList: Get_Current_UserQuery["me"]["organizationMemberships"] =
            [
              ...cacheData.me.organizationMemberships,
              {
                organization: { id: createdOrganization.id },
                role: OrganizationMemberRole.Owner,
              },
            ];
          const updatedUser: Get_Current_UserQuery = {
            me: {
              ...cacheData.me,
              organizations: updatedOrganizationList,
              organizationMemberships: updatedOrganizationMembershipList,
            },
          };

          return updatedUser;
        }
      );
    },
  });

  return mutation;
};
