import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_REPOSITORY_COMMIT = graphql(`
  query GET_REPOSITORY_COMMIT($repositoryId: ID!, $commitId: ID!) {
    connectedRepositoryCommit(repositoryId: $repositoryId, commitId: $commitId) {
      ... on Commit {
        __typename
        id
        author {
          email
          name
        }
        authoredAt
        branches
        committedAt
        committer {
          email
          name
        }
        message
        tags
        title
      }
      ... on CommitNotFound {
        __typename
        repositoryId
        commitId
      }
    }
  }
`);

type Props = {
  repositoryId: string;
  commitId: string;
};

export const useGetRepositoryCommit = ({ commitId, repositoryId }: Props) => {
  const result = useQuery(GET_REPOSITORY_COMMIT, {
    variables: { commitId, repositoryId },
    skip: !commitId,
  });

  return result;
};
