import { isDate } from "date-fns";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { CONVERTED_FROM_MAP_STR } from "numerous_constants";
import { mapToObject } from "utils/jsUtils/mapToObject";
import { objectMap } from "utils/jsUtils/objectMap";
import { objectToMap } from "utils/jsUtils/objectToMap";

// type FirestoreValue = string | number | boolean | firebase.firestore.Timestamp;

export const convertToFirestoreFormat = (
  data: { [key: string]: any },
  deleteUndefined?: boolean
) => {
  const cleaner: (val: any) => any = (val: any) => {
    if (
      typeof val === "string" ||
      typeof val === "number" ||
      typeof val === "boolean" ||
      val === null
    )
      return val;
    if (isDate(val)) return firebase.firestore.Timestamp.fromDate(val);
    if (val instanceof Map) {
      const newObj = mapToObject(val);
      newObj[CONVERTED_FROM_MAP_STR] = true;
      return convertToFirestoreFormat(newObj);
    }
    if (Array.isArray(val))
      return val.map(
        (aV) => cleaner(aV) //recursive on array elements
      );
    if (typeof val === "object") return convertToFirestoreFormat(val);
    if (typeof val === "undefined" && deleteUndefined)
      return firebase.firestore.FieldValue.delete();
    if (typeof val === "undefined") return val;
    return "unknown val";
  };

  let cleaned = objectMap(data, cleaner);

  //filter out undefined:
  cleaned = Object.fromEntries(
    Object.entries(cleaned).filter((entry) => typeof entry[1] !== "undefined")
  );
  return cleaned;
};

export const convertFromFirestoreFormat = (data: { [key: string]: any }) => {
  const cleaner = (value: any) => {
    let val = value;
    if (typeof val?.toDate === "function") return val.toDate();
    if (Array.isArray(val)) return val.map((aV) => convertFromFirestoreFormat(aV));
    if (val?.[CONVERTED_FROM_MAP_STR] !== undefined) {
      const mapWithoutKey = { ...val };
      delete mapWithoutKey[CONVERTED_FROM_MAP_STR];
      const map = objectToMap(mapWithoutKey);
      map.forEach((mapVal, key) => {
        map.set(key, cleaner(mapVal));
      });
      return map;
    }
    if (typeof val === "object") return convertFromFirestoreFormat(val);
    return val;
  };
  if (data === null) return data;
  if (typeof data === "object") {
    const cleaned = objectMap(data, cleaner);
    return cleaned;
  }
  return data;
};
