import { SystemModel } from "model/datatypes";
import TableHeader from "thermo/table/TableHeader";
import ToolRow from "./ToolRow";

interface Props {
  tools: SystemModel[];
}

const GRID_TEMPLATE_COLUMNS =
  "4fr minmax(0, 2fr) minmax(8em, 1.2fr) minmax(5em, 1fr) minmax(2em, 4em)";

const ToolsList = ({ tools }: Props) => {
  return (
    <div className="flex flex-col border-b border-gray-200">
      <TableHeader
        columns={["Name", "Created by", "Last edited", "Status"]}
        className="sticky top-14 border-b border-gray-200"
        style={{ gridTemplateColumns: GRID_TEMPLATE_COLUMNS }}
      />
      <div className="flex flex-col divide-y divide-gray-200">
        {tools.map((tool) => (
          <ToolRow key={tool.id} tool={tool} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />
        ))}
      </div>
    </div>
  );
};

export default ToolsList;
