import { DotsThree } from "@phosphor-icons/react";
import { ComponentProps, ReactNode } from "react";
import { Button } from "thermo/button/Button";
import Popover from "thermo/popover/Popover";
import { PartialByProp } from "utils/typeHelpers/PartialByProp";

type ButtonProps = PartialByProp<
  Omit<ComponentProps<typeof Button>, "icon" | "children">,
  "size"
>;

interface Props extends ButtonProps {
  icon?: ReactNode;
  placement?: ComponentProps<typeof Popover.Root>["placement"];
  iconSize?: number;
  children: ReactNode | ((closePopover: () => void) => ReactNode);
}

const PopoverMenu = ({
  icon,
  placement,
  iconSize = 20,
  children,
  size = "sm",
  borderless = true,
  ...buttonProps
}: Props) => {
  return (
    <Popover.Root placement={placement}>
      <Popover.Trigger>
        <Button size={size} borderless={borderless} {...buttonProps}>
          {icon || <DotsThree size={iconSize} weight="bold" />}
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        {(closePopover) =>
          typeof children !== "function" ? children : children(closePopover)
        }
      </Popover.Content>
    </Popover.Root>
  );
};

export default PopoverMenu;
