import Papa from "papaparse";
import { FullDataSet } from "../types";

export const convertDataSetToCSV = (rawData: FullDataSet | undefined) => {
  if (!rawData) return undefined;

  const { data: dataSetData, index } = rawData;

  let csvTags: string[] = ["t"];
  let dataRows: number[][] = [[]];

  for (let i = 0; i < index.length; i += 1) {
    if (dataRows[i]) dataRows[i].push(index[i]);
    else dataRows[i] = [index[i]];
  }

  for (let i = 0; i < dataSetData.length; i += 1) {
    csvTags.push(dataSetData[i].tag);
    for (let j = 0; j < dataSetData[i].values.length; j += 1) {
      const value = dataSetData[i].values[j];
      if (value === null) break;
      if (dataRows[j]) dataRows[j].push(value);
      else dataRows[j] = [value];
    }
  }

  return Papa.unparse([csvTags, ...dataRows], { delimiter: ";" });
};

/**
 *
 * @param data - The processed CSV data of type `string` after passed to `Papa.unparse()`
 * @param fileName - The name of the saved file
 * @example
 * // Prepping data
 * const columns: string[] = ["index", "value"];
 * const data: number[][] = [[1, 2], [50, 55]]
 * const csvData = Papa.unparse([columns, ...data], { delimiter: ";"})
 *
 * saveAsCsv(csvData, "exampleFile")
 */
export const saveAsCSV = (data: string | undefined, fileName: string) => {
  if (!data) return;

  const blob = new Blob([data]);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
