import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react-dom-interactions";
import { ReactNode, useState } from "react";
import { zPopover } from "thermo/zIndex/zIndexValues";
import { getTransformOrigin } from "utils/getTransformOrigin";
import { PopoverContext, PopoverContextType } from "./PopoverContext";

interface Props {
  placement?: Placement;
  children: ReactNode;
}

const PopoverRoot = ({ placement, children }: Props) => {
  const [open, setOpen] = useState(false);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement: floatingPlacement,
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    middleware: [offset(4), flip(), shift({ padding: 10 })],
    whileElementsMounted: autoUpdate,
    strategy: "absolute",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ]);

  const contentTransformOrigin = getTransformOrigin(floatingPlacement);

  const value: PopoverContextType = {
    open,
    openPopover: () => setOpen(true),
    closePopover: () => setOpen(false),
    contentTransformOrigin,
    triggerProps: getReferenceProps({ ref: reference }),
    contentProps: getFloatingProps({
      ref: floating,
      style: {
        position: strategy,
        left: x ?? "",
        top: y ?? "",
        zIndex: zPopover,
        transformOrigin: contentTransformOrigin,
      },
    }),
  };

  return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>;
};

export default PopoverRoot;
