import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const ADD_COLLABORATOR_TO_PROJECT_MUTATION = graphql(`
  mutation ADD_COLLABORATOR_TO_PROJECT_MUTATION($collaboratorId: ID!, $projectId: ID!) {
    projectAddCollaborator(collaboratorId: $collaboratorId, projectId: $projectId)
  }
`);

export const useAddUserToProject = () => {
  const mutation = useMutation(ADD_COLLABORATOR_TO_PROJECT_MUTATION); // TODO Requires an update function once we fetch users on a project through GraphQL

  return mutation;
};
