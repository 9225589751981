import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { CloudProject } from "Components/Providers/Firebase/cloudConfig";
import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGlobalState } from "store";
import { useAuthenticationStatusListener } from "../User/authenticationStatus";
import { useFirebaseCloudInstance } from "./FirebaseManager";

type Props = {
  children: ReactNode;
};

type FirebaseProviderContextType = {
  firebaseInstance: app.app.App;
  cloudInstance: CloudProject;
};

const registerApp = (cloudProjectToRegister: CloudProject) => {
  const firebaseApp = app.initializeApp(cloudProjectToRegister.config);
  if (cloudProjectToRegister.id === "emulator" || window.location.hostname === "localhost") {
    firebaseApp.firestore().settings({
      merge: true,
      experimentalForceLongPolling: true,
    });
    firebaseApp.firestore().useEmulator("localhost", 8080);
    firebaseApp.auth().useEmulator("http://localhost:9099");
  } else {
    firebaseApp.firestore().settings({ merge: true });
  }
};

export const FirebaseProviderContext = createContext<FirebaseProviderContextType | null>(null);

export const FirebaseProvider = ({ children }: Props) => {
  const [firebaseInstance, setFirebaseInstance] = useState<app.app.App>();
  const firebaseInstanceHasBeenRegistered = useRef(false);
  useAuthenticationStatusListener(firebaseInstance);
  const { authStatus } = useGlobalState();

  const cloudInstance = useFirebaseCloudInstance();
  const firebaseApp = app.initializeApp(cloudInstance.config);

  useEffect(() => {
    if (firebaseApp) {
      if (!firebaseInstanceHasBeenRegistered.current) {
        registerApp(cloudInstance);
      }
      setFirebaseInstance(firebaseApp);
      firebaseInstanceHasBeenRegistered.current = true;
    }
  }, [cloudInstance, firebaseApp]);

  if (!firebaseInstance || !authStatus) return <FullScreenLoadingOverlay />;
  return (
    <FirebaseProviderContext.Provider value={{ firebaseInstance, cloudInstance }}>
      {children}
    </FirebaseProviderContext.Provider>
  );
};

export const useCloudInstance = () => {
  const context = useContext(FirebaseProviderContext);
  if (!context?.cloudInstance) throw Error("No cloud instance available");
  return context.cloudInstance;
};
