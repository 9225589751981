import { Pen } from "@phosphor-icons/react";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import React, { useState } from "react";
import { Button } from "./Buttons";
import { Input } from "./Input";

interface Props {
  name: string;
  onChange: (newDisplayName: string) => void;
  className?: string;
  loading?: boolean;
}

const EditableName: React.FC<Props> = ({ name, onChange, className, loading }) => {
  const [editedName, setEditedName] = useState<string | null>(null);

  return editedName !== null ? (
    <div className="flex text-sm">
      <Input
        data-testid="editableNameInput"
        className={`${className || ""}`}
        autoFocus
        value={editedName}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          e.stopPropagation();
          setEditedName(e.target.value);
        }}
      />
      <Button
        data-testid="okEditableNameBtn"
        buttonType="white"
        className={`ml-4 mr-2 ${loading ? "opacity-50" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          onChange(editedName);
          setEditedName(null);
        }}
      >
        OK
      </Button>
      <Button
        data-testid="cancelEditableNameBtn"
        buttonType="white"
        className={` ${loading ? "opacity-50" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          setEditedName(null);
        }}
      >
        cancel
      </Button>
    </div>
  ) : (
    <div className="flex items-center">
      <div
        data-testid="editableNameDisplay"
        className={`flex-none font-bold ${className || ""}`}
      >
        {name}
      </div>
      {loading ? (
        <LoadingIcon className="ml-2" />
      ) : (
        <Pen
          data-testid="editableNameBtn"
          className="ml-2 cursor-pointer text-indigo-900"
          onClick={(e) => {
            e.stopPropagation();
            setEditedName(name);
          }}
        />
      )}
    </div>
  );
};

export default EditableName;
