import ActionModal from "Components/Basic/ActionModal";
import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { Input } from "Components/Basic/Input";
import TagSelector from "Components/Basic/TagSelector";
import { saveFileInFs } from "api/firestore/firestoreAPI";
import { useGetOrganization } from "api/organisation/getOrganization";
import { useFileTags, useFirebase, useIdToken, useSimulationModels } from "api/useFirebase";
import { useGetUsersProjects } from "api/user/getUsersProjects";
import { uploadFile } from "grpc/api/grpcUtilAPI";
import { SimFile } from "model/datatypes";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGlobalState } from "store";
import { UploadFile } from "./types";

interface NewFileProps {
  newFiles: Map<string, UploadFile>;
  setNewFiles: (arg0: Map<string, UploadFile>) => void;
  projectID?: string;
  modelID?: string;
}

const NewFile: React.FC<NewFileProps> = ({ newFiles, setNewFiles, projectID, modelID }) => {
  const fileList: UploadFile[] = [];
  newFiles.forEach((nf) => fileList.push(nf));
  const uploading = fileList.some((uf) => uf.progress !== null);

  const { data } = useGetUsersProjects();
  const myProjects = data?.me.ownedProjects || [];

  const { allCurrentModels } = useSimulationModels();
  const { grpcURL } = useGlobalState();
  const activeOrganization = useGetOrganization().data?.organization;
  const idToken = useIdToken();

  const [selectedProjectID, setSelectedProjectID] = useState(projectID);
  const [selectedModelID, setSelectedModelID] = useState(modelID);
  const [files, setFiles] = useState<Map<string, UploadFile>>(newFiles);
  const [tags, setTags] = useState<string[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    setNewFiles(files);
  }, [files, setNewFiles]);

  const saveNewFile = async (newFile: UploadFile) => {
    if (!idToken || !activeOrganization) return;

    setFiles((prev) => {
      const map = new Map(prev);
      map.set(newFile.id, { ...newFile, progress: 0 });
      return map;
    });

    const allTags = [...tags];
    const type = newFile.type.toLowerCase();
    if (selectedProjectID) allTags.push(`project_${selectedProjectID}`);
    if (selectedModelID) allTags.push(`model_${selectedModelID}`);

    let path = `${activeOrganization.id}/simulation_files/${
      selectedProjectID ? `project/${selectedProjectID}` : "global"
    }`;
    if (selectedModelID) path = `${path}/system/${selectedModelID}`;

    path = `${path}/${newFile.id}`;

    // Upload to bucket
    uploadFile(grpcURL, idToken, activeOrganization.slug, path, newFile.file)
      .then(async () => {
        // Faking progress until Resumable uploads are implemented
        setFiles((prev) => {
          const map = new Map(prev);
          map.set(newFile.id, { ...newFile, progress: 100 });
          return map;
        });

        const customMetadata: { [key: string]: any } = {
          name: newFile.name,
          tags: allTags.toString(),
        };
        if (selectedProjectID) customMetadata.projectID = selectedProjectID;
        if (selectedModelID) customMetadata.modelID = selectedModelID;

        const fs = fb.firestore();
        const uploadedFile: SimFile = {
          id: newFile.id,
          path,
          name: newFile.name,
          tags: allTags,
          type,
          organizationId: activeOrganization.id,
          customMetadata,
        };
        if (selectedProjectID) uploadedFile.projectID = selectedProjectID;
        if (selectedModelID) uploadedFile.modelID = selectedModelID;

        // TODO Return after answer in notion
        await saveFileInFs(fs, uploadedFile, tags, activeOrganization.id);

        setFiles((prev) => {
          const map = new Map(prev);
          map.delete(newFile.id);
          return map;
        });
      })
      .catch((err) => {
        toast.error(`Error uploading file - ${err.message}`);
        console.error(err);
        setFiles((prev) => {
          const map = new Map(prev);
          map.set(newFile.id, { ...newFile, progress: null, uploadFailed: true });
          return map;
        });
      });
  };

  const allTags = useFileTags().tags;

  const checkInputLength = fileList.map((file) => {
    if (file.name.length < 1) return true;
    return false;
  });

  const disableEmptyInput = checkInputLength.includes(true);

  return (
    <ActionModal
      onClose={() => {
        if (!uploading) {
          setNewFiles(new Map());
          setTags([]);
        }
      }}
      onSave={() => {
        if (!uploading) fileList.forEach((f) => saveNewFile(f));
      }}
      saveButtonName={fileList.length > 1 ? `Upload all` : `Upload`}
      blockNavigateAwayIf={uploading || !disableEmptyInput}
      disableConfirmButtonIf={disableEmptyInput}
      className="sm:w-11/12 md:w-1/2"
    >
      <div className="p-4">
        <div className="font-bold">Add new file</div>
        <div className="flex flex-col">
          <div className="flex">
            <div className="mr-2 flex-1 text-sm font-medium">Name</div>
            <div className="ml-2 flex-1 text-sm font-medium">Status</div>
          </div>
        </div>
        <div className="flex max-h-50vh overflow-y-auto flex-col">
          {fileList.map((file) => {
            const { id, name, progress } = file;
            return (
              <div key={id} className="my-1 flex items-center">
                <div className="mr-2 flex-1">
                  <Input
                    type="text"
                    className="w-full text-sm"
                    value={name}
                    onChange={(e) => {
                      const newVal = e.target.value;
                      setFiles((prev) => {
                        const map = new Map(prev);
                        map.set(id as string, { ...file, name: newVal } as UploadFile);
                        return map as Map<string, UploadFile>;
                      });
                    }}
                  />
                </div>
                <div className="ml-2 flex-1">
                  {progress !== null && (
                    <div className="relative h-6 w-full overflow-hidden rounded border border-gray-400 bg-white">
                      <div className="h-full bg-green-400" style={{ width: `${progress}%` }} />
                      <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center text-sm font-medium italic">
                        Uploading
                      </div>
                    </div>
                  )}
                  {progress === null && fileList.length > 0 && !file.uploadFailed && (
                    <div className="text-sm italic">Ready</div>
                  )}
                  {progress === null && file.uploadFailed && (
                    <div className="relative h-6 w-full overflow-hidden rounded border border-red-50 bg-white">
                      <div className="h-full w-full bg-red-500" />
                      <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center text-sm font-bold italic text-white">
                        Failed
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-2 w-full">
          <div className="text-sm font-medium">Tags</div>
          <TagSelector
            tags={allTags}
            selectedTagIDs={tags}
            selectTag={(newTag) => setTags((prev) => [...prev, newTag])}
            removeTag={(removeTag) =>
              setTags((prev) => prev.filter((tag) => tag !== removeTag))
            }
            canAddNewTag
            showInlineTags
          />
        </div>

        <div className="my-2 flex text-sm">
          <div className="mr-2 flex-1">
            <div className="text-sm font-medium">Attached project</div>
            <Dropdown
              placeholder="Select project"
              selectedID={selectedProjectID}
              onSelect={(option) => {
                setSelectedProjectID(option.value);
              }}
              options={[
                ...myProjects.map((p) => ({
                  id: p.id,
                  display: p.name,
                  value: p.id,
                })),
                { id: "none", display: "No Project", value: undefined },
              ]}
            />
          </div>
          <div className="ml-2 flex-1">
            <div className="text-sm font-medium">Attached model</div>
            <Dropdown
              placeholder="Select model"
              selectedID={selectedModelID}
              onSelect={(option) => {
                setSelectedModelID(option.value);
              }}
              options={[
                ...allCurrentModels.map((simModel) => ({
                  id: simModel.id,
                  display: simModel.displayName,
                  value: simModel.id,
                })),
                { id: "none", display: "No model", value: undefined },
              ]}
            />
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default NewFile;
