import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useDeprecatedFirestore } from "api/useFirebase";
import { PublicLink } from "model/datatypes";
import { useEffect, useState } from "react";

export const usePublicLinks = (scenarioID: string) => {
  const [publicLinks, setPublicLinks] = useState<PublicLink[]>([]);

  const activeOrganizationId = useActiveOrganizationId();
  const fs = useDeprecatedFirestore();

  useEffect(() => {
    if (!activeOrganizationId) return undefined;
    return fs
      .collection("Links")
      .where("organisation", "==", activeOrganizationId)
      .where("scenario", "==", scenarioID)
      .onSnapshot((snap) => {
        const links: PublicLink[] = [];
        snap.docs.forEach((doc) => {
          const link = doc.data() as PublicLink;
          links.push(link);
        });
        setPublicLinks(links);
      });
  }, [scenarioID, fs, activeOrganizationId]);

  return publicLinks;
};
