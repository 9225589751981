import {
  AnyLayer,
  GeoJSONSource,
  LngLatLike,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
} from "mapbox-gl";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Map, { ControlPosition, Layer, MapRef, Source } from "react-map-gl";
import { calcMapAnimationDuration } from "./Utils/calcMapAnimationDuration";
import { MapSource, MapCamera, MapMoveTo, MapSettings, MapClick } from "./types";

const STYLE_URL = "mapbox://styles/lassebtenergymachines/cl16c4qcl000914l70xviw7ux"; // stylesheet from mapbox studio
const MAPBOX_TOKEN = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN;
const BASIC_MAP_SETTINGS: MapSettings = {
  dragRotate: false,
  touchPitch: false,
  touchZoomRotate: false,
  logoPosition: "bottom-right" as ControlPosition,
  trackResize: true,
};
const DEFAULT_ZOOM = 11;

interface Props {
  source?: MapSource;
  layers?: AnyLayer[];
  popup?: JSX.Element;
  initialCamera?: MapCamera;
  moveTo?: MapMoveTo;
  onMapClick?: (feature: MapClick) => void;
  mapSettings?: MapSettings;
  mapIsLoaded?: (isLoaded: true) => void;
  cursorPointerOnHover?: true;
}

/**
 * Generic map component. Will render a Mapbox map component.
 *
 * @ref {MapRef} ref - (optional) a forwardRef that will provide a ref to the map component.
 * @property {MapSource} source - (optional) The source containing marker data points.
 * @property {AnyLayer[]} layers - (optional) An array of layers used for styling the source data.
 * @property {JSX.Element} popup - (optional) A popup that will be displayed on the map.
 * @property {MapCamera} initialCamera - (optional) Initial settings for the map camera. Will default to a view of Europe/Scandinavia.
 * @property {MapMoveTo} moveTo - (optional) Will trigger the camera to move to the passed location/zoom.
 * @property {function} onMapClick - (optional) Function that is called whenever the map is clicked. Will pass a marker object if a marker was clicked, otherwise passes the clicked longitude and latitude. The passed object will always include a prop called `isFeatureClick` - will be `true` if the click event was on a feature.
 * @property {MapboxProps} mapSettings - (optional) Additional settings to add to the map component. Some properties may be overwritten by the default map implementation.
 * @property {function} mapIsLoaded - (optional) Function that is called once the map component finishes loading.
 * @property {true} cursorPointerOnHover - (optional) Prop that will set `cursor-pointer` when hovering standard features on the map (roads, buildings, etc.) instead of `source` features
 * @example
 * return (
 *   <MapComponent />
 * )
 */

const MapComponent = React.forwardRef<MapRef, Props>(function MapComponent(
  {
    source,
    layers,
    popup,
    initialCamera,
    moveTo,
    onMapClick = () => {},
    mapSettings,
    mapIsLoaded = () => {},
    cursorPointerOnHover,
  },
  forwardRef
) {
  const [cursor, setCursor] = useState("auto");
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewState, setViewState] = useState<MapCamera>(
    initialCamera || {
      latitude: 57,
      longitude: 10,
      zoom: 2.5,
    }
  );

  const mapRef = useRef<MapRef>(null);
  // Enables using the local ref and forwardRef on the same element
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useImperativeHandle(forwardRef, () => mapRef.current as MapRef, [isLoaded]);

  const interactiveLayerIds = useMemo(() => {
    if (!cursorPointerOnHover && source && layers) return layers.map(({ id }) => id);
    if (cursorPointerOnHover && mapRef.current)
      return mapRef.current.getStyle().layers.map(({ id }) => id);
    return undefined;
  }, [cursorPointerOnHover, layers, source]);

  const isMovingCamera = useMemo(
    () =>
      mapRef.current?.isEasing() || mapRef.current?.isMoving() || mapRef.current?.isZooming(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapRef.current?.isEasing(), mapRef.current?.isMoving(), mapRef.current?.isZooming()]
  );

  // Updates map camera when prop moveTo is updated from parent
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !moveTo || isMovingCamera) {
      if (moveTo) moveTo.clear();
      return;
    }

    const mapMoveTo = map[moveTo.type];

    let { center, duration, zoom } = moveTo.settings;

    zoom = zoom || DEFAULT_ZOOM;

    duration =
      duration || calcMapAnimationDuration({ from: viewState, to: { ...center, zoom } });

    mapMoveTo({
      duration,
      zoom,
      center: [center.longitude, center.latitude] as LngLatLike,
    });

    moveTo.clear();
  }, [moveTo, isMovingCamera, viewState]);

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);

  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  const handleClusterClick = useCallback(
    ({ feature, clusterID }: { feature: MapboxGeoJSONFeature; clusterID: number }) => {
      const map = mapRef.current;
      if (!map) return;

      const mapSource = map?.getSource(feature.source) as GeoJSONSource;

      mapSource.getClusterExpansionZoom(clusterID, (err, zoom) => {
        if (err) return;

        map.easeTo({ center: (feature.geometry as any).coordinates, zoom, duration: 300 });
      });
    },
    []
  );

  const handleMarkerClick = useCallback(
    ({ feature, map }: { feature: MapboxGeoJSONFeature; map: MapRef }) => {
      const getZoomLevel = (): { zoom: number; moveType: "easeTo" | "flyTo" } => {
        let zoom = DEFAULT_ZOOM;
        let moveType: "easeTo" | "flyTo" = "flyTo";
        if (!map) return { zoom, moveType };

        const currentZoom = map.getZoom();

        if (currentZoom > zoom) {
          zoom = currentZoom;
          moveType = "easeTo";
        }
        return { zoom, moveType };
      };

      const { coordinates } = feature?.geometry as any;
      const { zoom, moveType } = getZoomLevel();

      const duration = calcMapAnimationDuration({
        from: viewState,
        to: { latitude: coordinates[1], longitude: coordinates[0], zoom },
      });

      map[moveType]({
        center: coordinates,
        duration,
        zoom,
      });
    },
    [viewState]
  );

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const feature = event.features && event.features[0];
      const isFeatureClick = source && source.id === feature?.source;

      if (!source || !feature || !isFeatureClick) {
        onMapClick({
          latitude: event.lngLat.lat,
          longitude: event.lngLat.lng,
          isFeatureClick: false,
        });
        return;
      }

      const clusterID = feature?.properties?.cluster_id;
      if (clusterID) {
        handleClusterClick({ feature, clusterID });
        return;
      }

      const map = mapRef.current;
      if (!map) return;

      handleMarkerClick({ feature, map });
      onMapClick({ ...feature, isFeatureClick: true });
    },
    [handleClusterClick, handleMarkerClick, onMapClick, source]
  );

  return (
    <Map
      {...viewState}
      ref={mapRef}
      mapboxAccessToken={MAPBOX_TOKEN}
      {...BASIC_MAP_SETTINGS}
      {...mapSettings}
      style={{ width: "100%", height: "100%" }}
      mapStyle={STYLE_URL}
      cursor={cursor}
      onMove={(event) => setViewState(event.viewState)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      interactiveLayerIds={interactiveLayerIds}
      onLoad={() => {
        setIsLoaded(true);
        mapIsLoaded(true);
      }}
    >
      {source && (
        <Source {...source}>
          {layers?.map((layer) => (
            <Layer key={layer.id} {...layer} />
          ))}
        </Source>
      )}
      {popup}
    </Map>
  );
});

export default MapComponent;
