import React, { useState, useRef } from "react";
import toast from "react-hot-toast";

interface Props {
  className?: string;
  allowedTypes: "all" | string;
  onFilesAdded: (File: File[], rawFileList: FileList) => void;
  children: (dropHovered: boolean, clickToAdd: () => void) => React.ReactNode;
  multiFile?: boolean;
  onError?: (errorMsg: string) => void;
}

const Dropzone: React.FC<Props> = ({
  className = "",
  onFilesAdded,
  children,
  allowedTypes,
  multiFile,
  onError,
}) => {
  const [dropHovered, setdropHovered] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFilesInput = (files: FileList) => {
    const fileArray: File[] = [];
    for (let i = 0; i < files.length; i += 1) {
      const file = files.item(i);
      const extension = file?.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      if (file && extension && (allowedTypes === "all" || allowedTypes === extension))
        fileArray.push(file);
    }

    if (fileArray.length > 1 && !multiFile) {
      if (onError) onError("No valid files found");
      toast.error("Only one file is allowed at a time");
    } else if (fileArray.length === 0) {
      if (onError) onError("No valid files found");
      toast.error("No valid files found");
    } else {
      onFilesAdded(fileArray, files);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setdropHovered(false);
    event.preventDefault();
    const { files } = event.dataTransfer;
    setTimeout(() => onFilesInput(files), 1); // yeah yeah, I know. Prevents state update on unmounted component warn
  };

  const manualOpen = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <div
      onDrop={onDrop}
      onDragOver={(e) => {
        e.preventDefault();
        setdropHovered(true);
      }}
      onDragLeave={() => {
        setdropHovered(false);
      }}
      className={className}
    >
      <input
        className="hidden"
        ref={fileInputRef}
        type="file"
        onChange={(e) => {
          if (e.target.files) onFilesInput(e.target.files);
        }}
      />
      {children(dropHovered, manualOpen)}
    </div>
  );
};

Dropzone.propTypes = {};

export default Dropzone;
