import { Warning } from "@phosphor-icons/react";
import { OrganizationLogo } from "Components/Basic/OrganizationLogo";
import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useGetUserPendingInvitations } from "api/user/getUserPendingInvitations";
import { motion } from "framer-motion";
import Popover from "thermo/popover/Popover";
import Text from "thermo/typography/Typography";
import { OrganizationPickerDropdown } from "./OrganizationPickerDropdown";

export const OrganizationPicker = () => {
  const { organizations } = useGlobalUser();
  const activeOrganizationId = useActiveOrganizationId();
  const activeOrganization = organizations.find((org) => org.id === activeOrganizationId);
  const { data: pendingInvitations } = useGetUserPendingInvitations();

  if (activeOrganization?.name) {
    window.document.title = `${activeOrganization.name} - Numerous - Cloud Based Engineering Workspace`;
  }

  const organizationInvitations = pendingInvitations?.filter((invitation) => {
    return !invitation.isExpired;
  });

  return (
    <div className="truncate">
      <Popover.Root placement="bottom-start">
        <Popover.Trigger>
          <button
            data-testid="activeOrganization"
            className="p-2 w-full flex items-center hover:bg-gray-100 rounded-[4px]"
          >
            {activeOrganization ? (
              <>
                <OrganizationLogo logoUrl={activeOrganization.logoUrl} />
                <Text.Base
                  data-testid="activeOrganizationName"
                  className="ml-2 truncate text-left w-56 text-gray-800"
                >
                  {activeOrganization?.name}
                </Text.Base>
              </>
            ) : (
              <>
                <Warning size={14} weight="fill" className="text-warning-500" />
                <Text.Base className="text-gray-700 ml-2">No organization found</Text.Base>
              </>
            )}
            {organizationInvitations && organizationInvitations.length > 0 && (
              <motion.div
                className="w-4 aspect-square shrink-0 flex items-center justify-center bg-error-400 rounded-full"
                initial={{ marginLeft: 0, opacity: 0 }}
                animate={{ marginLeft: "1rem", opacity: 1 }}
                transition={{ delay: 0.3 }}
                data-testid="pendingInvitationsNotification"
              >
                <Text.XSmall className="font-medium text-white">
                  {organizationInvitations.length}
                </Text.XSmall>
              </motion.div>
            )}
          </button>
        </Popover.Trigger>
        <Popover.Content>
          {(closePopover) => (
            <OrganizationPickerDropdown
              allOrganizations={organizations}
              closePopover={closePopover}
              pendingInvitations={organizationInvitations}
            />
          )}
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};
