import React from "react";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  columns: string[];
  rounded?: true;
}

const TableHeader = ({ columns, rounded, ...htmlProps }: Props) => {
  return (
    <div
      {...htmlProps}
      className={classNames(
        "grid grid-flow-col py-2 bg-gray-50 select-none px-4",
        rounded && "rounded-t",
        htmlProps.className
      )}
    >
      {columns.map((column) => (
        <Text.Small key={column} className="text-gray-600">
          {column}
        </Text.Small>
      ))}
    </div>
  );
};

export default TableHeader;
