import { Plus } from "@phosphor-icons/react";
import { SubCompRules } from "model/datatypes";
import React from "react";
import { Button } from "thermo/button/Button";
import Popover from "thermo/popover/Popover";
import InputInstance from "./InputInstance";

const RemoveIcon = ({ onClick }: { onClick: () => void }) => (
  <svg
    className="flex-none cursor-pointer"
    onClick={() => onClick()}
    xmlns="http://www.w3.org/2000/svg"
    height="14"
    viewBox="0 0 24 24"
    width="14"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
  </svg>
);

const EditableSubCompRules = ({
  subcompRules,
  onRemove,
  onEdit,
  displayName,
}: SubCompProps) => (
  <div className="mb-2 flex w-full rounded border border-gray-300 bg-white px-2 py-2 text-sm shadow-md">
    <div className="flex-grow ">
      <div className="truncate text-sm font-medium">{displayName}</div>
    </div>
    <div className="flex flex-col pr-2">
      <div className="flex items-center justify-end">
        <div className="pr-1">Mininum</div>
        <InputInstance
          type="min"
          value={subcompRules.min}
          onChange={(newVal) => onEdit({ ...subcompRules, min: newVal || 0 })}
        />
        <div className="pl-1">Instance</div>
      </div>
      <div className="flex items-center justify-end">
        <div className="pr-1">Default</div>
        <InputInstance
          type="default"
          value={subcompRules.default}
          onChange={(newVal) => onEdit({ ...subcompRules, default: newVal || 0 })}
        />
        <div className="pl-1">Instance</div>
      </div>
      <div className="flex items-center justify-end">
        <div className="pr-1">Maximum</div>
        <InputInstance
          type="max"
          value={subcompRules.max}
          onChange={(newVal) => onEdit({ ...subcompRules, max: newVal })}
        />
        <div className="pl-1">Instance</div>
      </div>
    </div>
    {!subcompRules.fixed && <RemoveIcon onClick={() => onRemove()} />}
  </div>
);

interface Props {
  attachedSubCompRules: { displayName: string; subCompRules: SubCompRules }[];
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  onAddSubComponent: (option: SubCompRules) => void;
  onRemoveSubComponent: (option: SubCompRules) => void;
  onEditRules: (newRules: SubCompRules) => void;
}

const SubComponentsRules: React.FC<Props> = ({
  attachedSubCompRules,
  subCompOptions,
  onAddSubComponent,
  onRemoveSubComponent,
  onEditRules,
}) => (
  <>
    <div className="mb-2 text-sm font-bold">Sub Components</div>
    {attachedSubCompRules && (
      <div className="flex flex-col">
        {attachedSubCompRules.map((subcomp) => (
          <EditableSubCompRules
            key={subcomp.subCompRules.id}
            displayName={subcomp.displayName}
            subcompRules={subcomp.subCompRules}
            onRemove={() => {
              onRemoveSubComponent(subcomp.subCompRules);
            }}
            onEdit={(newRules) => {
              onEditRules(newRules);
            }}
          />
        ))}
      </div>
    )}

    <Popover.Root placement="right">
      <Popover.Trigger className="w-fit">
        <Button size="sm">
          <Plus className="mx-2 h-4 w-4" />
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        {() => {
          const options = subCompOptions?.filter(
            (option) =>
              !attachedSubCompRules?.some(
                (attachedRule) => attachedRule.subCompRules.id === option.subCompRules.id
              )
          );
          return (
            <div className="scrollbar-light max-h-64 divide-y divide-gray-200 overflow-auto text-sm">
              {!options || options.length === 0 ? (
                <div className="px-4 py-1 select-none text-gray-500">
                  no components available
                </div>
              ) : (
                options.map((option) => (
                  <Popover.Button
                    key={option.subCompRules.id}
                    className="w-full px-4 py-1 hover:bg-indigo-400 hover:text-white focus:outline-none"
                    onClick={() => onAddSubComponent(option.subCompRules)}
                  >
                    {option.displayName}
                  </Popover.Button>
                ))
              )}
            </div>
          );
        }}
      </Popover.Content>
    </Popover.Root>
  </>
);

export default SubComponentsRules;

type SubCompProps = {
  subcompRules: SubCompRules;
  displayName: string;
  onEdit: (newRules: SubCompRules) => void;
  onRemove: () => void;
};
