import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const ARCHIVE_SCENARIO = graphql(`
  mutation SCENARIO_ARCHIVE($projectId: ID!, $scenarioId: ID!) {
    scenarioArchive(projectId: $projectId, scenarioId: $scenarioId) {
      ... on Scenario {
        id
        name
        archived
      }
    }
  }
`);

export const useArchiveScenario = () => {
  const mutation = useMutation(ARCHIVE_SCENARIO);

  return mutation;
};
