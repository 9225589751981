import { CalendarBlank, ClipboardText, GitCommit, User } from "@phosphor-icons/react";
import { useGetRepositoryCommit } from "api/repository/getCommit";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import Text from "thermo/typography/Typography";
import Badge from "../../../Basic/Badge";
import { CommitCardError } from "./CommitCardError";
import { CommitCardSkeleton } from "./CommitCardSkeleton";

export const CommitCard = () => {
  const { repositoryID, commitID } = useParams<{ repositoryID: string; commitID: string }>();
  const { data, loading, error } = useGetRepositoryCommit({
    commitId: commitID || "",
    repositoryId: repositoryID || "",
  });
  const repositoryCommit = data?.connectedRepositoryCommit;

  const shouldDisplayError =
    error || repositoryCommit?.__typename === "CommitNotFound" || !commitID;

  return (
    <div className="p-3">
      <Text.H4 className="font-medium mb-2">Current commit</Text.H4>
      <div className="flex flex-col px-4 py-4 rounded-[4px] border border-gray-200 bg-white min-w-[300px] gap-3 text-gray-800">
        {loading && <CommitCardSkeleton />}

        {shouldDisplayError && <CommitCardError commitId={commitID} />}

        {!loading && !error && repositoryCommit?.__typename === "Commit" && (
          <>
            <div className="flex items-center">
              <Text.H4 data-testid="commitTitle" className="font-medium mr-4 truncate">
                {repositoryCommit?.title}
              </Text.H4>
              <div className="flex items-center text-gray-500">
                <Text.Small data-testid="commitId">
                  {repositoryCommit?.id.slice(0, 8)}
                </Text.Small>
                <ClipboardText size={14} className="ml-0.5" weight="bold" />
              </div>
            </div>
            <div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <User fill="bold" className="text-gray-600" size={14} />
                  <Text.Small data-testid="commitAuthorName">
                    {repositoryCommit?.author.name}
                  </Text.Small>
                </div>

                <div className="flex items-center gap-1">
                  <CalendarBlank fill="bold" className="text-gray-600" size={14} />
                  <Text.Small data-testid="commitTimestamp">
                    {format(repositoryCommit.committedAt, "HH:mm MMM dd yyyy")}
                  </Text.Small>
                </div>
              </div>
            </div>
            <div className="line-clamp-3 text-gray-700 mt-1">
              <Text.Small data-testid="commitMessage">{repositoryCommit?.message}</Text.Small>
            </div>
            <div className="flex flex-col overflow-y-auto">
              <div className="flex gap-1 items-center mb-1">
                <GitCommit className="text-gray-600 shrink-0" weight="bold" size={16} />
                <Text.Base className="font-medium">Branches</Text.Base>
              </div>
              <div className="flex gap-2 flex-wrap max-h-32 overflow-y-auto">
                {repositoryCommit.branches?.map((branch) => (
                  <Badge
                    key={branch}
                    data-testid="branchName"
                    text={branch}
                    backgroundColor="bg-gray-100"
                    textColor="text-gray-600"
                    hideDot
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
