import { Paginated_Scheduled_DatasetsQuery } from "GraphQL/gql/graphql";
import useGetPaginatedScheduledDataSets from "api/dataSet/getPaginatedScheduledDataSets";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

type Project = Extract<
  Paginated_Scheduled_DatasetsQuery["project"],
  { __typename?: "Project" }
>;
type ScheduledDataSet = Extract<
  ArrayElement<Project["dataSets"]["edges"]>["node"],
  { __typename: "ScheduledDataSet" }
>;

export const useGetAllScheduledDataSets = () => {
  const { projectID = "" } = useParams<{ projectID: string }>();

  const { data, fetchMore } = useGetPaginatedScheduledDataSets({
    first: 5,
    projectId: projectID,
  });

  useEffect(() => {
    const canFetchMoreTeamCardPages = data?.project?.dataSets.pageInfo.hasNextPage;
    if (canFetchMoreTeamCardPages) {
      fetchMore({
        variables: {
          after: data?.project?.dataSets.pageInfo.endCursor,
        },
      });
    }
  }, [
    data?.project?.dataSets.pageInfo.hasNextPage,
    data?.project?.dataSets.pageInfo.endCursor,
    fetchMore,
  ]);

  return (
    data?.project?.dataSets.edges
      .filter((edge) => edge.node.__typename === "ScheduledDataSet")
      .map((edge) => edge.node as ScheduledDataSet) || []
  );
};
