import { ClipboardText, X } from "@phosphor-icons/react";
import React from "react";
import ContextMenu from "thermo/ContextMenu/ContextMenu";
import { ContextMenuSeparator } from "thermo/ContextMenu/ContextMenuSeparator";
import useCopyToClipboard from "utils/clipboard/copyToClipboard";

type Props = {
  itemRoute: string;
  unpinFn: () => void;
};

export const PinnedItemContextMenu = ({ itemRoute, unpinFn }: Props) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const link = `${window.location.protocol}//${window.location.host}${itemRoute}`;

  return (
    <>
      <ContextMenu.Button
        onClick={() => copyToClipboard(link)}
        icon={<ClipboardText size={15} weight="bold" />}
      >
        Copy link
      </ContextMenu.Button>
      <ContextMenuSeparator />
      <ContextMenu.Button onClick={unpinFn} icon={<X size={15} weight="bold" />}>
        Unpin
      </ContextMenu.Button>
    </>
  );
};
