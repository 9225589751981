import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const USER_RESET_PASSWORD_SEND_EMAIL = graphql(`
  mutation USER_RESET_PASSWORD_SEND_EMAIL($email: String!) {
    userResetPasswordSendEmail(email: $email)
  }
`);

export const useResetPasswordSendEmail = () => {
  const mutation = useMutation(USER_RESET_PASSWORD_SEND_EMAIL);

  return mutation;
};
