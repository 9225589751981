import {
  ArrowSquareOut,
  DownloadSimple,
  Eye,
  Globe,
  LinkBreak,
  Link as LinkIcon,
} from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import FileIcon from "Components/Basic/FileIcon";
import { useCreatePublicLink } from "Components/Simulations/overview/api/createPublicLink";
import { useRemovePublicLink } from "Components/Simulations/overview/api/removePublicLink";
import { useTogglePublishFile } from "Components/Simulations/overview/api/togglePublishFile";
import { usePublicLinks } from "Components/Simulations/overview/api/usePublicLinks";
import ReportEditor from "Components/Simulations/reportEditor/ReportEditor";
import { useProject } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import { ScenarioOutputFile, SimulationScenario } from "model/datatypes";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "thermo/button/Button";
import Popover from "thermo/popover/Popover";
import Tooltip from "thermo/tooltip/Tooltip";
import useCopyToClipboard from "utils/clipboard/copyToClipboard";
import { useDownloadFile } from "./api/downloadFile";

type Props = {
  fileID: string;
  file: ScenarioOutputFile;
  scenario: SimulationScenario;
};

const ReportItem = ({ file, scenario, fileID }: Props) => {
  const { projectID } = useParams<{
    projectID: string;
  }>();
  const navigate = useNavigateApp();
  const { project } = useProject(projectID);
  const [viewingReport, setviewingReport] = useState<ScenarioOutputFile | null>(null);
  const { downloadFile } = useDownloadFile();
  const [, copyToClipboard] = useCopyToClipboard({ itemTypeToCopy: "The link" });
  const publicLinks = usePublicLinks(scenario.id);
  const publicLink = publicLinks.find((link) => link.file.fileName === file.fileName);

  const { togglePublishFile } = useTogglePublishFile();
  const { removePublicLink } = useRemovePublicLink();
  const { createPublicLink } = useCreatePublicLink({
    publicLink,
    file,
    scenario,
  });

  const isPublished = useMemo(() => scenario.publishedFileID === fileID, [scenario, fileID]);

  const publicLinkUrl = useMemo(() => {
    if (!publicLink) return null;
    const url = `${window.location.protocol}//${window.location.host}`;
    return `${url}/links/${publicLink.id}`;
  }, [publicLink]);

  return (
    <>
      <Popover.Root>
        <Popover.Trigger>
          <Button size="sm" borderless>
            <Tooltip label={file.fileName}>
              <FileIcon
                fileType={file.fileType}
                className="z-10 h-6 w-6 cursor-pointer text-green-700"
              />
            </Tooltip>
          </Button>
        </Popover.Trigger>
        <Popover.Content>
          {file.fileType === "html" && (
            <>
              <Popover.Button
                onClick={() => setviewingReport(file)}
                icon={<Eye size="95%" weight="bold" />}
              >
                View report
              </Popover.Button>
              <Popover.Button
                onClick={() =>
                  navigate(`reports/${scenario.projectID}/${scenario.id}/${fileID}`, {
                    openInNewTab: true,
                  })
                }
                icon={<ArrowSquareOut size="95%" weight="bold" />}
              >
                Open in new tab
              </Popover.Button>
              <Popover.Button
                onClick={() =>
                  togglePublishFile(!isPublished, scenario.projectID, scenario.id, fileID)
                }
                icon={<Globe size="90%" weight="regular" />}
                className={isPublished ? "text-red-500" : ""}
              >
                {isPublished ? "Make report private" : "Publish report"}
              </Popover.Button>
              <Popover.Button
                onClick={() => {
                  //create link
                  if (publicLink) removePublicLink(publicLink);
                  else createPublicLink();
                }}
                icon={
                  publicLink ? (
                    <LinkBreak size="90%" weight="bold" />
                  ) : (
                    <LinkIcon size="90%" weight="bold" />
                  )
                }
              >
                {publicLink ? "Remove" : "Create"} public link
              </Popover.Button>
              {publicLinkUrl && (
                <Popover.Button
                  onClick={() =>
                    //create link
                    publicLinkUrl && copyToClipboard(publicLinkUrl, true)
                  }
                  icon={<LinkIcon size="90%" weight="bold" />}
                >
                  Copy public link to clipboard
                </Popover.Button>
              )}
            </>
          )}
          {file.fileType !== "html" && (
            <Popover.Button
              onClick={() => downloadFile(file)}
              icon={<DownloadSimple size="95%" weight="bold" />}
            >
              Download {file.fileName}
            </Popover.Button>
          )}
        </Popover.Content>
      </Popover.Root>
      <AnimatePresence>
        {viewingReport && (
          <ReportEditor
            sourcetype="scenario"
            sourceID={scenario.id}
            onClose={() => setviewingReport(null)}
            report_download={viewingReport}
            useModal={!!viewingReport}
            reportName={`${project?.projectName}_${scenario.scenarioName}`}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ReportItem;
