const colorOptions = [
  { backgroundColor: "bg-green-200", textColor: "text-green-800" },
  { backgroundColor: "bg-red-200", textColor: "text-red-800" },
  { backgroundColor: "bg-teal-200", textColor: "text-teal-800" },
  { backgroundColor: "bg-orange-200", textColor: "text-orange-800" },
];
const colorOptionsLength = colorOptions.length;
type User = { [key: string]: any; fullName: string };
export const getUserColor = (user: User) =>
  colorOptions[user.fullName.length % colorOptionsLength];
