import { CommitEdge, useGetCommits } from "api/repository/getCommits";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "thermo/Alert/Alert";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";
import { CommitListCard } from "./CommitListCard";
import { CommitListCardSkeleton } from "./CommitListCardSkeleton";

type Props = {
  searchTerm: string;
};

export const CommitList = ({ searchTerm }: Props) => {
  const { repositoryID, commitID } = useParams<{ repositoryID: string; commitID: string }>();
  const [loadingMore, setLoadingMore] = useState(false);

  const { data, loading, error, fetchMore } = useGetCommits({
    repositoryId: repositoryID || "",
    first: 6,
    query: searchTerm,
  });

  if (loading) {
    return <CommitListCardSkeleton />;
  }
  if (error) {
    console.error(error);
    return <Alert type="error" text="There was an error getting the commit list" />;
  }
  if (!data?.connectedRepositoryCommits) return null;

  // Sort active commit to the top of the list
  const sortFn = (a: CommitEdge, b: CommitEdge) => {
    if (a.node.id === commitID) return -1;
    if (b.node.id === commitID) return 1;
    return 0;
  };

  const commitListSortedByChosen = [...data.connectedRepositoryCommits.edges].sort(sortFn);

  if (commitListSortedByChosen.length < 1) {
    return (
      <Text.Small data-testid="noCommitsMessage" className="italic">
        This repository has no commits
      </Text.Small>
    );
  }
  return (
    <div className="flex flex-col items-center max-h-60">
      <div className="divide-y w-full">
        {commitListSortedByChosen.map(({ node }) => (
          <CommitListCard
            key={node.id}
            branch={node.branches}
            commitAuthor={node.author.name}
            commitId={node.id}
            title={node.title}
            committedAt={node.committedAt}
          />
        ))}
      </div>
      {data.connectedRepositoryCommits.pageInfo.hasNextPage && (
        <div className="my-6">
          <Button
            loading={loadingMore}
            data-testid="loadMoreCommitsButton"
            onClick={() => {
              setLoadingMore(true);
              fetchMore({
                variables: {
                  after: data.connectedRepositoryCommits.pageInfo.endCursor,
                },
              }).finally(() => setLoadingMore(false));
            }}
            size="md"
          >
            Load more commits
          </Button>
        </div>
      )}
    </div>
  );
};
