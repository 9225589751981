import { CaretRight } from "@phosphor-icons/react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import React, { ReactNode } from "react";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";
import { ContextMenuItem } from "./ContextMenuItem";

type Props = {
  children: ReactNode;
  triggerText: string;
  triggerIcon: JSX.Element;
};

/**
 * Do not import from here. Import from ContextMenu.tsx
 */
export const ContextMenuSub = ({ triggerText, triggerIcon, children }: Props) => {
  return (
    <ContextMenu.Sub>
      <ContextMenu.SubTrigger
        data-testid="contextMenuSubTrigger"
        className="data-[state=open]:bg-slate-100 hover:bg-slate-100 mx-1 rounded-lg focus:outline-none focus:bg-slate-100"
      >
        <ContextMenuItem icon={triggerIcon}>
          <div className="flex items-center justify-between ">
            <span>{triggerText}</span>
            <CaretRight className="text-gray-500" size={11} weight="bold" />
          </div>
        </ContextMenuItem>
      </ContextMenu.SubTrigger>
      <ContextMenu.Portal>
        <ContextMenu.SubContent
          data-testid="contextMenuSubContent"
          className={classNames(
            getZIndexClassName("zDropdown"),
            "py-1 min-w-fit bg-white rounded-lg shadow-[0px_1px_1px_rgb(0,0,0,0.09%)] border-gray-200 border"
          )}
        >
          {children}
        </ContextMenu.SubContent>
      </ContextMenu.Portal>
    </ContextMenu.Sub>
  );
};
