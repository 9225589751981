import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";

type Props = {
  title: string;
  branch: string[];
  commitAuthor: string;
  commitId: string;
  committedAt: Date;
};

export const CommitListCard = ({
  branch,
  commitAuthor,
  commitId,
  committedAt,
  title,
}: Props) => {
  const navigate = useNavigateApp();
  const { commitID, repositoryID } = useParams<{ commitID: string; repositoryID: string }>();
  const isCurrentCommit = commitID === commitId;

  return (
    <div
      data-testid="commitListCard"
      className="flex items-center space-x-2 py-2 justify-between"
    >
      <div>
        <div className="space-y-1">
          <Text.Base
            data-testid="commitListCardTitle"
            className="font-medium text-gray-700 max-w-[350px]"
            truncate
          >
            {title}
          </Text.Base>

          <div className="flex gap-1">
            <Text.Small data-testid="commitListCardAuthor" className="text-gray-600">
              {commitAuthor}
            </Text.Small>
            <Text.Small className="text-gray-600" data-testid="commitListCardCommitId">
              {commitId.slice(0, 8)}
            </Text.Small>
            <Text.Small data-testid="commitListCardCommittedAt" className="text-gray-600">
              {formatDistanceToNow(committedAt)}
            </Text.Small>
          </div>
        </div>

        <div>
          <Text.Small
            data-testid="commitListCardBranch"
            className="text-gray-600 gap-x-2 gap-y-1 flex flex-wrap mt-1"
          >
            {branch.map((br) => (
              <span className="rounded-md p-0.5 bg-gray-100" key={br}>
                {br}
              </span>
            ))}
          </Text.Small>
        </div>
      </div>
      <div>
        {isCurrentCommit ? (
          <Text.XSmall data-testid="commitListCardCurrent" className="mr-2.5">
            Current
          </Text.XSmall>
        ) : (
          <Button
            onClick={() => navigate(`/repositories/${repositoryID}/${commitId}`)}
            data-testid="commitListCardSelectButton"
            size="sm"
          >
            Select
          </Button>
        )}
      </div>
    </div>
  );
};
