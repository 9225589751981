import { useUnleashContext } from "@unleash/proxy-client-react";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import FeatureHighlights from "Components/FeatureHighlights/FeatureHighlights";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useRecentlyViewedQueue } from "Components/Sidebar/RecentlyViewed/RecentlyViewedQueue";
import { Sidebar } from "Components/Sidebar/Sidebar";
import { AnimatePresence } from "framer-motion";
import Gleap from "gleap";
import React, { Suspense, lazy, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useGlobalState } from "store";

const Tutorial = lazy(() => import("Components/Onboarding/Tutorial"));

const App: React.FC = () => {
  const { darkModeEnabled } = useGlobalState();
  const user = useGlobalUser();
  useRecentlyViewedQueue();

  useEffect(() => {
    if (user) {
      Gleap.identify(user?.id, {
        name: user.firstName,
        email: user.mail,
      });
    }
  }, [user]);

  const updateContext = useUnleashContext();

  useEffect(() => {
    // Update unleash context to use email as userID. This is used for userID activation strategies.
    if (!user) return;
    updateContext({ userId: user.mail });
  }, [updateContext, user]);

  return (
    <div
      data-testid="theApp"
      id="appRoot"
      className={`relative flex h-screen ${darkModeEnabled && "dark"}`}
    >
      <Sidebar />

      <Suspense fallback={<LoadingOverlay />}>
        <AnimatePresence mode="wait" initial={false}>
          <div data-testid="appContent" className="w-full overflow-y-auto bg-slate-100">
            <Outlet />
          </div>
        </AnimatePresence>
      </Suspense>
      <Tutorial />
      <FeatureHighlights />
    </div>
  );
};

export default App;
