import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UPDATE_PROJECT_NAME = graphql(`
  mutation UPDATE_PROJECT_NAME($name: String!, $projectId: ID!) {
    projectUpdateName(name: $name, projectId: $projectId) {
      ... on Project {
        id
        name
      }
    }
  }
`);

export const useUpdateProjectName = () => {
  const mutation = useMutation(UPDATE_PROJECT_NAME);

  return mutation;
};
