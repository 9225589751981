import ActionModal from "Components/Basic/ActionModal";
import { Button } from "Components/Basic/Buttons";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import ComponentEditor from "Components/Systems/editSystem/componentEditor/ComponentEditor";
import { AnimatePresence } from "framer-motion";
import { ComponentType } from "model/datatypes";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useComponentLibrary } from "./api/componentLibrary";
import { EditSystemStateProvider } from "./editSystem/EditSystemStore";

const ComponentLibrary = () => {
  const { componentLib, removeComponentFromLib, updateLibraryComponent } =
    useComponentLibrary();
  const [selectedComp, setSelectedComp] = useState<ComponentType | null>(null);
  const [removeComponentModalOpen, setRemoveComponentModalOpen] = useState(false);
  const [editingComponent, setEditingComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleRemoveComponent = useCallback(
    async (comp: ComponentType) => {
      if (!comp) return;
      setLoading(true);
      const removePromise = removeComponentFromLib(comp.id).finally(() => {
        setLoading(false);
        setRemoveComponentModalOpen(false);
      });
      const stateMessages = {
        loading: (
          <p>
            Removing <span className="font-medium">{comp.displayName}</span> from the
            library...
          </p>
        ),
        success: (
          <p>
            Successfully removed <span className="font-medium">{comp.displayName}</span> from
            the library.
          </p>
        ),
        error: (
          <p>
            Could not remove <span className="font-medium">{comp.displayName}</span> from the
            library.
          </p>
        ),
      };
      toast.promise(removePromise, stateMessages);
    },
    [removeComponentFromLib]
  );
  return (
    <>
      <div className="mb-4 font-medium">Component library</div>
      <div className="scrollbar-light flex max-h-50vh flex-wrap overflow-y-scroll">
        {componentLib.map((libComp) => {
          const isSelected = libComp.id === selectedComp?.id;
          return (
            <div
              key={libComp.id}
              className={`mr-4 mb-4 cursor-pointer overflow-hidden rounded bg-white shadow-md hover:opacity-100
              ${!isSelected && !!selectedComp ? "opacity-75" : ""}
            `}
              onClick={() => setSelectedComp(isSelected ? null : libComp)}
            >
              <div
                className={`overflow-hidden border-4 px-2 py-2 ${
                  isSelected ? "border-green-300" : "border-white"
                }`}
              >
                <div className="font-medium">{libComp.displayName}</div>
                <div className="mt-3 text-sm">
                  {libComp.parameters ? libComp.parameters.length : 0} parameters
                </div>
                <div className="text-sm">{libComp.inputVariables.length} input variables</div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Button
          buttonType="white"
          className={`
            ${selectedComp ? "" : "opacity-50"} mr-2`}
          onClick={() => selectedComp && setRemoveComponentModalOpen(true)}
        >
          Remove
        </Button>
        <Button
          buttonType="white"
          className={`
            ${selectedComp ? "" : "opacity-50"} mr-2`}
          onClick={() => setEditingComponent(true)}
        >
          Edit
        </Button>
      </div>

      <AnimatePresence>
        {editingComponent && selectedComp && (
          <ActionModal
            onClose={() => setEditingComponent(false)}
            key="editLibraryComponentModal"
            className="h-auto max-h-[calc(100vh-4rem)] w-auto max-w-[90%] overflow-y-auto"
            onSave={() => {
              if (!loading) {
                setLoading(true);
                updateLibraryComponent(selectedComp.id, selectedComp)
                  .then(() => {
                    setEditingComponent(false);
                    setLoading(false);
                  })
                  .catch(() => {
                    toast.error("There was an error updating the library component");
                    setLoading(false);
                  });
              }
            }}
          >
            <EditSystemStateProvider>
              <ComponentEditor
                notCardView
                systemRef={undefined}
                comp={selectedComp}
                updateComponent={(componentID: string, updates: Partial<ComponentType>) => {
                  setSelectedComp({ ...selectedComp, ...updates });
                }}
                subCompOptions={[]}
                allowedParamRefs={[]}
              />
            </EditSystemStateProvider>
          </ActionModal>
        )}
        {removeComponentModalOpen && selectedComp && (
          <ConfirmModal
            loading={loading}
            key="removeLibraryComponentModal"
            warning
            description={
              <>
                Do you want to delete{" "}
                <span className="font-medium">{selectedComp.displayName}</span>? This
                can&apos;t be undone.
              </>
            }
            onConfirm={() => handleRemoveComponent(selectedComp)}
            onCancel={() => setRemoveComponentModalOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ComponentLibrary;
