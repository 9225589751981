import { downloadFileFromUrl } from "@/utils/downloadFileFromUrl";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useIdToken } from "api/useFirebase";
import { getDownloadFileUrl } from "grpc/api/grpcUtilAPI";
import { ScenarioOutputFile } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";
import { useGlobalState } from "store";

export const useDownloadFile = () => {
  const [loadingFile, setLoadingFile] = useState(false);
  const idToken = useIdToken();
  const { grpcURL } = useGlobalState();
  const organizationSlug = useOrganizationSlugFromParams();
  const downloadFile = async (file: ScenarioOutputFile) => {
    try {
      if (loadingFile || !idToken || !organizationSlug) return;

      setLoadingFile(true);
      const url = await getDownloadFileUrl(
        grpcURL,
        idToken,
        organizationSlug,
        file.report_path
      );

      downloadFileFromUrl(url, file.fileName)

      setLoadingFile(false);
    } catch (error: any) {
      toast.error(error);
      console.error(error);
      setLoadingFile(false);
    }
  };
  return { downloadFile, loadingFile };
};
