import { GitlabLogoSimple, PuzzlePiece } from "@phosphor-icons/react";
import Text from "thermo/typography/Typography";
import ConnectToGitLabApi from "./Gitlab/ConnectToGitLabApi";

const NotConnectedToGitLab = () => {
  const fullURL = window.location.href;

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex justify-center text-gray-700 mb-4">
        <Text.H3 className="flex items-center gap-4 text-center font-medium">
          <PuzzlePiece size={24} /> Repositories on numerous
        </Text.H3>
      </div>
      <Text.Small className="text-gray-500 text-center mb-10">
        Numerous repositories lets you bring your GitLab repositories to numerous. Connect to
        your GitLab account by clicking the button below and start bringing your models to
        numerous.
      </Text.Small>

      <ConnectToGitLabApi redirectUri={fullURL}>
        <button
          type="submit"
          className="text-gray-700 flex flex-col justify-center items-center rounded border border-gray-400 border-dashed py-11 px-28 w-max gap-4 hover:cursor-pointer"
        >
          <GitlabLogoSimple size={24} className="text-gray-500" />
          Connect to GitLab
        </button>
      </ConnectToGitLabApi>
    </div>
  );
};

export default NotConnectedToGitLab;
