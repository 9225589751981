import app from "firebase/compat/app";
import { useEffect } from "react";
import { useGlobalDispatch } from "store";

export const useAuthenticationStatusListener = (firebase: app.app.App | undefined) => {
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    if (!firebase) return;
    firebase.auth().onAuthStateChanged((u) => {
      dispatch({ type: "SET_AUTH_STATUS", payload: "pending" });
      if (u && u.uid) {
        return dispatch({ type: "SET_AUTH_STATUS", payload: "authenticated" });
      }
      return dispatch({ type: "SET_AUTH_STATUS", payload: "not-authenticated" });
    });
  }, [dispatch, firebase]);
};
