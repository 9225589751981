import { useFirebaseCloudInstance } from "Components/Providers/Firebase/FirebaseManager";
import { useIdToken } from "api/useFirebase";
import { ReactNode, forwardRef } from "react";

type Props = {
  redirectUri: string;
  children: ReactNode;
};

/**
 * Use to trigger the connection between numerous to GitLab.
 *
 * @param redirectUri - The full URL you want to be redirected to in numerous after Authorize/Deny the GitLab connection.
 * @param children - The trigger for the connection flow.
 * @external To revoke the authorized access: In GitLab > Preferences > Sidebar Applications > Bottom page Authorized applications > Revoke
 * @example
 * <ConnectToGitLabApi redirectUri={fullURL}>
 *   <Button size="lg" variant="brandColor" >
 *      Connect to GitLab
 *   </Button>
 * </ConnectToGitLabApi>
 */

const ConnectToGitLabApi = forwardRef<HTMLFormElement, Props>(function ConnectToGitLabApi(
  { redirectUri, children },
  formRef
) {
  const idToken = useIdToken();

  const firebaseCloudInstance = useFirebaseCloudInstance();

  return (
    <form action={firebaseCloudInstance.gitlab?.authorizeUrl} method="POST" ref={formRef}>
      <input readOnly type="hidden" name="id_token" value={`${idToken}`} />
      <input readOnly type="hidden" name="redirect_uri" value={redirectUri} />
      {children}
    </form>
  );
});

export default ConnectToGitLabApi;
