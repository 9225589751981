import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { ComponentParamType, ComponentParameter } from "model/datatypes";

export const SelectorParameter: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParameter) => void;
  className?: string;
}> = ({ parameter, onUpdate, className }) => (
  <Dropdown
    className={`${className} bg-white`}
    options={parameter.selecterOptions || []}
    onSelect={(dropdownOption) => {
      onUpdate({
        ...parameter,
        value: dropdownOption.value,
        tag: dropdownOption.id,
        displayValue: dropdownOption.display,
      });
    }}
    selectedID={parameter.tag}
    placeholder="Select option"
  />
);
