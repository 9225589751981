import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UNARCHIVE_PROJECT = graphql(`
  mutation UNARCHIVE_PROJECT($projectId: ID!) {
    projectUnarchive(projectId: $projectId) {
      ... on Project {
        id
        archived
      }
    }
  }
`);

export const useUnarchiveProject = () => {
  const mutation = useMutation(UNARCHIVE_PROJECT);
  return mutation;
};
