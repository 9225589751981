import { useMutation } from "@apollo/client";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { graphql } from "GraphQL/gql";
import { Create_GroupMutation } from "GraphQL/gql/graphql";
import { GET_GROUPS } from "./getGroups";

const CREATE_GROUP = graphql(`
  mutation CREATE_GROUP($groupName: String!, $projectID: ID!, $description: String!) {
    groupCreate(name: $groupName, projectId: $projectID, description: $description) {
      __typename
      ... on Group {
        __typename
        description
        id
        name
        scenarios
        createdAt
        createdBy {
          ... on User {
            __typename
            id
            mail
            lastName
            fullName
            firstName
          }
          ... on UserNotFound {
            __typename
            userId
          }
        }
      }
      ... on ProjectDoesNotAllowGroupCreation {
        projectId
        __typename
      }
    }
  }
`);

export const useCreateGroup = () => {
  const user = useGlobalUser();
  const mutation = useMutation(CREATE_GROUP, {
    optimisticResponse: (v) => {
      if (!user) {
        return undefined;
      }

      const groupMutation: Create_GroupMutation = {
        groupCreate: {
          __typename: "Group",
          createdAt: new Date(),
          createdBy: user,
          id: "temp group id",
          description: v.description,
          name: v.groupName,
          scenarios: [],
        },
      };
      return groupMutation;
    },

    update: (cache, { data }, context) => {
      const mutationResult = data?.groupCreate;
      if (
        !mutationResult ||
        !context.variables?.projectID ||
        mutationResult.__typename === "ProjectDoesNotAllowGroupCreation"
      )
        return;

      cache.updateQuery(
        {
          query: GET_GROUPS,
          variables: {
            projectId: context.variables.projectID,
          },
        },
        (cacheData) => {
          if (!cacheData) return undefined;
          let updatedGroups = [...cacheData.groups, mutationResult];
          if (mutationResult.__typename === "Group")
            return { ...cacheData, groups: updatedGroups };
          return cacheData;
        }
      );
    },
  });

  return mutation;
};
