// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,generate_dependencies
// @generated from protobuf file "spm.proto" (syntax proto3)
// tslint:disable
import { FileManager } from "./spm_pb";
import type { FileUrls } from "./spm_pb";
import { BuildManager } from "./spm_pb";
import type { JobImageReferenceUpdate } from "./spm_pb";
import type { BuildUpdate } from "./spm_pb";
import type { BuildInfo } from "./spm_pb";
import type { BuildInfoRequest } from "./spm_pb";
import type { BuildResponse } from "./spm_pb";
import type { BuildReply } from "./spm_pb";
import type { BuildRequest } from "./spm_pb";
import type { PullResponse } from "./spm_pb";
import type { PullRequest } from "./spm_pb";
import type { History } from "./spm_pb";
import type { HistoryRequest } from "./spm_pb";
import type { HistoryReply } from "./spm_pb";
import type { HistoryUpdate } from "./spm_pb";
import type { SnapshotReply } from "./spm_pb";
import type { Snapshot } from "./spm_pb";
import { TokenManager } from "./spm_pb";
import type { RefreshRequest } from "./spm_pb";
import type { Token } from "./spm_pb";
import type { TokenRequest } from "./spm_pb";
import type { RefreshUserResponse } from "./spm_pb";
import type { RefreshUserRequest } from "./spm_pb";
import type { AuthenticateUserResponse } from "./spm_pb";
import type { AuthenticateUserRequest } from "./spm_pb";
import { JobManager } from "./spm_pb";
import type { ExecutionAndSchedule } from "./spm_pb";
import type { Schedule } from "./spm_pb";
import type { ExecutionState } from "./spm_pb";
import type { ExecutionStateMessage } from "./spm_pb";
import type { StartLocalJobResponse } from "./spm_pb";
import type { StartLocalJobRequest } from "./spm_pb";
import type { JobTimeSchedule } from "./spm_pb";
import type { JobSchedule } from "./spm_pb";
import type { JobTime } from "./spm_pb";
import type { Job } from "./spm_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SPM } from "./spm_pb";
import type { GetDataStreamStatsResponse } from "./spm_pb";
import type { GetDataStreamStatsRequest } from "./spm_pb";
import type { ReadDataStreamResponse } from "./spm_pb";
import type { ReadDataStreamRequest } from "./spm_pb";
import type { WriteDataStreamResponse } from "./spm_pb";
import type { WriteDataStreamRequest } from "./spm_pb";
import type { ProbeResponse } from "./spm_pb";
import type { ProbeInformation } from "./spm_pb";
import type { ExecutionId } from "./spm_pb";
import type { Json } from "./spm_pb";
import type { ExecutionMessage } from "./spm_pb";
import type { CompleteExecutionMessage } from "./spm_pb";
import type { SubscriptionMessage } from "./spm_pb";
import type { Subscription } from "./spm_pb";
import type { ScenarioResults } from "./spm_pb";
import type { ScenarioUploadURL } from "./spm_pb";
import type { ScenarioFilePath } from "./spm_pb";
import type { SignedUrl } from "./spm_pb";
import type { Link } from "./spm_pb";
import type { FileSignedUrls } from "./spm_pb";
import type { FilePaths } from "./spm_pb";
import type { ScenarioFormattedError } from "./spm_pb";
import type { ScenarioError } from "./spm_pb";
import type { ScenarioProgress } from "./spm_pb";
import type { SystemRequest } from "./spm_pb";
import type { ProjectDocument } from "./spm_pb";
import type { Project } from "./spm_pb";
import type { GroupDocument } from "./spm_pb";
import type { Group } from "./spm_pb";
import type { User } from "./spm_pb";
import type { UserRequest } from "./spm_pb";
import type { ScenarioResultsDocument } from "./spm_pb";
import type { ScenarioDocument } from "./spm_pb";
import type { ExeLogEntry } from "./spm_pb";
import type { ExecutionReadLogs } from "./spm_pb";
import type { LogEntries } from "./spm_pb";
import type { DuplicateScenarioRequest } from "./spm_pb";
import type { Model } from "./spm_pb";
import type { ScenarioStats } from "./spm_pb";
import type { ScenarioStatsRequest } from "./spm_pb";
import type { ScenarioCustomMetaData } from "./spm_pb";
import type { ScenarioMetaData } from "./spm_pb";
import type { SetOptimizationIterationScoreRequest } from "./spm_pb";
import type { SetOptimizationResultRequest } from "./spm_pb";
import type { IndexedDataList } from "./spm_pb";
import type { DuplexStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { Empty } from "./spm_pb";
import type { ScenarioDataTags } from "./spm_pb";
import type { DataCompleted } from "./spm_pb";
import type { DataList } from "./spm_pb";
import type { ReadScenario } from "./spm_pb";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { Closed } from "./spm_pb";
import type { Scenario } from "./spm_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { NoopContent } from "./spm_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service SPM
 */
export interface ISPMClient {
    /**
     * @generated from protobuf rpc: Noop(NoopContent) returns (NoopContent);
     */
    noop(input: NoopContent, options?: RpcOptions): UnaryCall<NoopContent, NoopContent>;
    /**
     * @generated from protobuf rpc: GetDataSetClosed(Scenario) returns (Closed);
     */
    getDataSetClosed(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Closed>;
    /**
     * @generated from protobuf rpc: ClearData(Scenario) returns (Scenario);
     */
    clearData(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Scenario>;
    /**
     * @generated from protobuf rpc: ReadData(ReadScenario) returns (stream DataList);
     */
    readData(input: ReadScenario, options?: RpcOptions): ServerStreamingCall<ReadScenario, DataList>;
    /**
     * @generated from protobuf rpc: CloseData(DataCompleted) returns (Scenario);
     */
    closeData(input: DataCompleted, options?: RpcOptions): UnaryCall<DataCompleted, Scenario>;
    /**
     * @generated from protobuf rpc: ClearDataTags(ScenarioDataTags) returns (Empty);
     */
    clearDataTags(input: ScenarioDataTags, options?: RpcOptions): UnaryCall<ScenarioDataTags, Empty>;
    /**
     * @generated from protobuf rpc: WriteDataList(stream IndexedDataList) returns (stream Scenario);
     */
    writeDataList(options?: RpcOptions): DuplexStreamingCall<IndexedDataList, Scenario>;
    /**
     * @generated from protobuf rpc: PushDataList(IndexedDataList) returns (Scenario);
     */
    pushDataList(input: IndexedDataList, options?: RpcOptions): UnaryCall<IndexedDataList, Scenario>;
    /**
     * @generated from protobuf rpc: SetOptimizationResult(SetOptimizationResultRequest) returns (Scenario);
     */
    setOptimizationResult(input: SetOptimizationResultRequest, options?: RpcOptions): UnaryCall<SetOptimizationResultRequest, Scenario>;
    /**
     * @generated from protobuf rpc: SetOptimizationIterationScore(SetOptimizationIterationScoreRequest) returns (Empty);
     */
    setOptimizationIterationScore(input: SetOptimizationIterationScoreRequest, options?: RpcOptions): UnaryCall<SetOptimizationIterationScoreRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetScenarioMetaData(Scenario) returns (ScenarioMetaData);
     */
    getScenarioMetaData(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioMetaData>;
    /**
     * @generated from protobuf rpc: SetScenarioMetaData(ScenarioMetaData) returns (Scenario);
     */
    setScenarioMetaData(input: ScenarioMetaData, options?: RpcOptions): UnaryCall<ScenarioMetaData, Scenario>;
    /**
     * @generated from protobuf rpc: GetScenarioCustomMetaData(ScenarioCustomMetaData) returns (ScenarioCustomMetaData);
     */
    getScenarioCustomMetaData(input: ScenarioCustomMetaData, options?: RpcOptions): UnaryCall<ScenarioCustomMetaData, ScenarioCustomMetaData>;
    /**
     * @generated from protobuf rpc: SetScenarioCustomMetaData(ScenarioCustomMetaData) returns (ScenarioCustomMetaData);
     */
    setScenarioCustomMetaData(input: ScenarioCustomMetaData, options?: RpcOptions): UnaryCall<ScenarioCustomMetaData, ScenarioCustomMetaData>;
    /**
     * @generated from protobuf rpc: GetScenarioDataStats(ScenarioStatsRequest) returns (ScenarioStats);
     */
    getScenarioDataStats(input: ScenarioStatsRequest, options?: RpcOptions): UnaryCall<ScenarioStatsRequest, ScenarioStats>;
    /**
     * @generated from protobuf rpc: GetModel(Model) returns (Model);
     */
    getModel(input: Model, options?: RpcOptions): UnaryCall<Model, Model>;
    /**
     * @generated from protobuf rpc: DuplicateScenario(DuplicateScenarioRequest) returns (Scenario);
     */
    duplicateScenario(input: DuplicateScenarioRequest, options?: RpcOptions): UnaryCall<DuplicateScenarioRequest, Scenario>;
    /**
     * @generated from protobuf rpc: PushExecutionLogEntries(LogEntries) returns (Empty);
     */
    pushExecutionLogEntries(input: LogEntries, options?: RpcOptions): UnaryCall<LogEntries, Empty>;
    /**
     * @generated from protobuf rpc: ReadExecutionLogEntries(ExecutionReadLogs) returns (stream ExeLogEntry);
     */
    readExecutionLogEntries(input: ExecutionReadLogs, options?: RpcOptions): ServerStreamingCall<ExecutionReadLogs, ExeLogEntry>;
    /**
     * @generated from protobuf rpc: GetScenario(Scenario) returns (ScenarioDocument);
     */
    getScenario(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioDocument>;
    /**
     * @generated from protobuf rpc: SetScenarioResultDocument(ScenarioResultsDocument) returns (Empty);
     */
    setScenarioResultDocument(input: ScenarioResultsDocument, options?: RpcOptions): UnaryCall<ScenarioResultsDocument, Empty>;
    /**
     * @generated from protobuf rpc: GetScenarioResultDocument(Scenario) returns (ScenarioResultsDocument);
     */
    getScenarioResultDocument(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioResultsDocument>;
    /**
     * @generated from protobuf rpc: GetUser(UserRequest) returns (User);
     */
    getUser(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, User>;
    /**
     * @generated from protobuf rpc: GetGroup(Group) returns (GroupDocument);
     */
    getGroup(input: Group, options?: RpcOptions): UnaryCall<Group, GroupDocument>;
    /**
     * @generated from protobuf rpc: GetProject(Project) returns (ProjectDocument);
     */
    getProject(input: Project, options?: RpcOptions): UnaryCall<Project, ProjectDocument>;
    /**
     * @generated from protobuf rpc: DeleteScenario(Scenario) returns (Scenario);
     */
    deleteScenario(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Scenario>;
    /**
     * @generated from protobuf rpc: DeleteProject(Project) returns (Project);
     */
    deleteProject(input: Project, options?: RpcOptions): UnaryCall<Project, Project>;
    /**
     * @generated from protobuf rpc: DeleteSystem(SystemRequest) returns (Empty);
     */
    deleteSystem(input: SystemRequest, options?: RpcOptions): UnaryCall<SystemRequest, Empty>;
    /**
     * @generated from protobuf rpc: DeleteUser(UserRequest) returns (Empty);
     */
    deleteUser(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, Empty>;
    /**
     * @generated from protobuf rpc: ListenScenario(Scenario) returns (stream ScenarioDocument);
     */
    listenScenario(input: Scenario, options?: RpcOptions): ServerStreamingCall<Scenario, ScenarioDocument>;
    /**
     * @generated from protobuf rpc: SetScenarioProgress(ScenarioProgress) returns (Scenario);
     */
    setScenarioProgress(input: ScenarioProgress, options?: RpcOptions): UnaryCall<ScenarioProgress, Scenario>;
    /**
     * @generated from protobuf rpc: SetScenarioDataTags(ScenarioDataTags) returns (Scenario);
     */
    setScenarioDataTags(input: ScenarioDataTags, options?: RpcOptions): UnaryCall<ScenarioDataTags, Scenario>;
    /**
     * @generated from protobuf rpc: PushScenarioError(ScenarioError) returns (Scenario);
     */
    pushScenarioError(input: ScenarioError, options?: RpcOptions): UnaryCall<ScenarioError, Scenario>;
    /**
     * @generated from protobuf rpc: PushScenarioFormattedError(ScenarioFormattedError) returns (Scenario);
     */
    pushScenarioFormattedError(input: ScenarioFormattedError, options?: RpcOptions): UnaryCall<ScenarioFormattedError, Scenario>;
    /**
     * @generated from protobuf rpc: GetSignedURLs(FilePaths) returns (FileSignedUrls);
     */
    getSignedURLs(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, FileSignedUrls>;
    /**
     * @generated from protobuf rpc: GetPublicLink(Link) returns (SignedUrl);
     */
    getPublicLink(input: Link, options?: RpcOptions): UnaryCall<Link, SignedUrl>;
    /**
     * @generated from protobuf rpc: GenerateScenarioUploadSignedURL(ScenarioFilePath) returns (ScenarioUploadURL);
     */
    generateScenarioUploadSignedURL(input: ScenarioFilePath, options?: RpcOptions): UnaryCall<ScenarioFilePath, ScenarioUploadURL>;
    /**
     * @generated from protobuf rpc: ClearScenarioResults(Scenario) returns (Scenario);
     */
    clearScenarioResults(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Scenario>;
    /**
     * @generated from protobuf rpc: SetScenarioResults(ScenarioResults) returns (Scenario);
     */
    setScenarioResults(input: ScenarioResults, options?: RpcOptions): UnaryCall<ScenarioResults, Scenario>;
    /**
     * @generated from protobuf rpc: GetScenarioResults(Scenario) returns (ScenarioResults);
     */
    getScenarioResults(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioResults>;
    /**
     * @generated from protobuf rpc: SubscribeForUpdates(Subscription) returns (stream SubscriptionMessage);
     */
    subscribeForUpdates(input: Subscription, options?: RpcOptions): ServerStreamingCall<Subscription, SubscriptionMessage>;
    /**
     * @generated from protobuf rpc: PublishSubscriptionMessage(SubscriptionMessage) returns (Empty);
     */
    publishSubscriptionMessage(input: SubscriptionMessage, options?: RpcOptions): UnaryCall<SubscriptionMessage, Empty>;
    /**
     * @generated from protobuf rpc: CompleteExecution(CompleteExecutionMessage) returns (Empty);
     */
    completeExecution(input: CompleteExecutionMessage, options?: RpcOptions): UnaryCall<CompleteExecutionMessage, Empty>;
    /**
     * @generated from protobuf rpc: CompleteExecutionIgnoreInstance(ExecutionMessage) returns (Empty);
     */
    completeExecutionIgnoreInstance(input: ExecutionMessage, options?: RpcOptions): UnaryCall<ExecutionMessage, Empty>;
    /**
     * @generated from protobuf rpc: UpdateExecution(Json) returns (Empty);
     */
    updateExecution(input: Json, options?: RpcOptions): UnaryCall<Json, Empty>;
    /**
     * @generated from protobuf rpc: ClearExecutionMemory(Scenario) returns (Empty);
     */
    clearExecutionMemory(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Empty>;
    /**
     * @generated from protobuf rpc: RemoveExecutionData(Scenario) returns (Empty);
     */
    removeExecutionData(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Empty>;
    /**
     * @generated from protobuf rpc: GetLatestMainExecution(Scenario) returns (ExecutionId);
     */
    getLatestMainExecution(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ExecutionId>;
    /**
     * Garbage collection
     *
     * @generated from protobuf rpc: UpdateProbe(ProbeInformation) returns (ProbeResponse);
     */
    updateProbe(input: ProbeInformation, options?: RpcOptions): UnaryCall<ProbeInformation, ProbeResponse>;
    /**
     * Data-Port stream wrapper
     *
     * @generated from protobuf rpc: WriteDataStream(stream WriteDataStreamRequest) returns (stream WriteDataStreamResponse);
     */
    writeDataStream(options?: RpcOptions): DuplexStreamingCall<WriteDataStreamRequest, WriteDataStreamResponse>;
    /**
     * @generated from protobuf rpc: ReadDataStream(stream ReadDataStreamRequest) returns (stream ReadDataStreamResponse);
     */
    readDataStream(options?: RpcOptions): DuplexStreamingCall<ReadDataStreamRequest, ReadDataStreamResponse>;
    /**
     * @generated from protobuf rpc: GetDataStreamStats(GetDataStreamStatsRequest) returns (GetDataStreamStatsResponse);
     */
    getDataStreamStats(input: GetDataStreamStatsRequest, options?: RpcOptions): UnaryCall<GetDataStreamStatsRequest, GetDataStreamStatsResponse>;
}
/**
 * @generated from protobuf service SPM
 */
export class SPMClient implements ISPMClient, ServiceInfo {
    typeName = SPM.typeName;
    methods = SPM.methods;
    options = SPM.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Noop(NoopContent) returns (NoopContent);
     */
    noop(input: NoopContent, options?: RpcOptions): UnaryCall<NoopContent, NoopContent> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<NoopContent, NoopContent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDataSetClosed(Scenario) returns (Closed);
     */
    getDataSetClosed(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Closed> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, Closed>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ClearData(Scenario) returns (Scenario);
     */
    clearData(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Scenario> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReadData(ReadScenario) returns (stream DataList);
     */
    readData(input: ReadScenario, options?: RpcOptions): ServerStreamingCall<ReadScenario, DataList> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReadScenario, DataList>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CloseData(DataCompleted) returns (Scenario);
     */
    closeData(input: DataCompleted, options?: RpcOptions): UnaryCall<DataCompleted, Scenario> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DataCompleted, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ClearDataTags(ScenarioDataTags) returns (Empty);
     */
    clearDataTags(input: ScenarioDataTags, options?: RpcOptions): UnaryCall<ScenarioDataTags, Empty> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioDataTags, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WriteDataList(stream IndexedDataList) returns (stream Scenario);
     */
    writeDataList(options?: RpcOptions): DuplexStreamingCall<IndexedDataList, Scenario> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<IndexedDataList, Scenario>("duplex", this._transport, method, opt);
    }
    /**
     * @generated from protobuf rpc: PushDataList(IndexedDataList) returns (Scenario);
     */
    pushDataList(input: IndexedDataList, options?: RpcOptions): UnaryCall<IndexedDataList, Scenario> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<IndexedDataList, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetOptimizationResult(SetOptimizationResultRequest) returns (Scenario);
     */
    setOptimizationResult(input: SetOptimizationResultRequest, options?: RpcOptions): UnaryCall<SetOptimizationResultRequest, Scenario> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetOptimizationResultRequest, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetOptimizationIterationScore(SetOptimizationIterationScoreRequest) returns (Empty);
     */
    setOptimizationIterationScore(input: SetOptimizationIterationScoreRequest, options?: RpcOptions): UnaryCall<SetOptimizationIterationScoreRequest, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetOptimizationIterationScoreRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScenarioMetaData(Scenario) returns (ScenarioMetaData);
     */
    getScenarioMetaData(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioMetaData> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, ScenarioMetaData>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetScenarioMetaData(ScenarioMetaData) returns (Scenario);
     */
    setScenarioMetaData(input: ScenarioMetaData, options?: RpcOptions): UnaryCall<ScenarioMetaData, Scenario> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioMetaData, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScenarioCustomMetaData(ScenarioCustomMetaData) returns (ScenarioCustomMetaData);
     */
    getScenarioCustomMetaData(input: ScenarioCustomMetaData, options?: RpcOptions): UnaryCall<ScenarioCustomMetaData, ScenarioCustomMetaData> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioCustomMetaData, ScenarioCustomMetaData>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetScenarioCustomMetaData(ScenarioCustomMetaData) returns (ScenarioCustomMetaData);
     */
    setScenarioCustomMetaData(input: ScenarioCustomMetaData, options?: RpcOptions): UnaryCall<ScenarioCustomMetaData, ScenarioCustomMetaData> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioCustomMetaData, ScenarioCustomMetaData>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScenarioDataStats(ScenarioStatsRequest) returns (ScenarioStats);
     */
    getScenarioDataStats(input: ScenarioStatsRequest, options?: RpcOptions): UnaryCall<ScenarioStatsRequest, ScenarioStats> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioStatsRequest, ScenarioStats>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetModel(Model) returns (Model);
     */
    getModel(input: Model, options?: RpcOptions): UnaryCall<Model, Model> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<Model, Model>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DuplicateScenario(DuplicateScenarioRequest) returns (Scenario);
     */
    duplicateScenario(input: DuplicateScenarioRequest, options?: RpcOptions): UnaryCall<DuplicateScenarioRequest, Scenario> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<DuplicateScenarioRequest, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PushExecutionLogEntries(LogEntries) returns (Empty);
     */
    pushExecutionLogEntries(input: LogEntries, options?: RpcOptions): UnaryCall<LogEntries, Empty> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<LogEntries, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReadExecutionLogEntries(ExecutionReadLogs) returns (stream ExeLogEntry);
     */
    readExecutionLogEntries(input: ExecutionReadLogs, options?: RpcOptions): ServerStreamingCall<ExecutionReadLogs, ExeLogEntry> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionReadLogs, ExeLogEntry>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScenario(Scenario) returns (ScenarioDocument);
     */
    getScenario(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioDocument> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, ScenarioDocument>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetScenarioResultDocument(ScenarioResultsDocument) returns (Empty);
     */
    setScenarioResultDocument(input: ScenarioResultsDocument, options?: RpcOptions): UnaryCall<ScenarioResultsDocument, Empty> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioResultsDocument, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScenarioResultDocument(Scenario) returns (ScenarioResultsDocument);
     */
    getScenarioResultDocument(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioResultsDocument> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, ScenarioResultsDocument>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUser(UserRequest) returns (User);
     */
    getUser(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, User> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserRequest, User>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGroup(Group) returns (GroupDocument);
     */
    getGroup(input: Group, options?: RpcOptions): UnaryCall<Group, GroupDocument> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<Group, GroupDocument>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProject(Project) returns (ProjectDocument);
     */
    getProject(input: Project, options?: RpcOptions): UnaryCall<Project, ProjectDocument> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<Project, ProjectDocument>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteScenario(Scenario) returns (Scenario);
     */
    deleteScenario(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Scenario> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProject(Project) returns (Project);
     */
    deleteProject(input: Project, options?: RpcOptions): UnaryCall<Project, Project> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<Project, Project>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteSystem(SystemRequest) returns (Empty);
     */
    deleteSystem(input: SystemRequest, options?: RpcOptions): UnaryCall<SystemRequest, Empty> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<SystemRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteUser(UserRequest) returns (Empty);
     */
    deleteUser(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, Empty> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListenScenario(Scenario) returns (stream ScenarioDocument);
     */
    listenScenario(input: Scenario, options?: RpcOptions): ServerStreamingCall<Scenario, ScenarioDocument> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, ScenarioDocument>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetScenarioProgress(ScenarioProgress) returns (Scenario);
     */
    setScenarioProgress(input: ScenarioProgress, options?: RpcOptions): UnaryCall<ScenarioProgress, Scenario> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioProgress, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetScenarioDataTags(ScenarioDataTags) returns (Scenario);
     */
    setScenarioDataTags(input: ScenarioDataTags, options?: RpcOptions): UnaryCall<ScenarioDataTags, Scenario> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioDataTags, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PushScenarioError(ScenarioError) returns (Scenario);
     */
    pushScenarioError(input: ScenarioError, options?: RpcOptions): UnaryCall<ScenarioError, Scenario> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioError, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PushScenarioFormattedError(ScenarioFormattedError) returns (Scenario);
     */
    pushScenarioFormattedError(input: ScenarioFormattedError, options?: RpcOptions): UnaryCall<ScenarioFormattedError, Scenario> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioFormattedError, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSignedURLs(FilePaths) returns (FileSignedUrls);
     */
    getSignedURLs(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, FileSignedUrls> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<FilePaths, FileSignedUrls>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPublicLink(Link) returns (SignedUrl);
     */
    getPublicLink(input: Link, options?: RpcOptions): UnaryCall<Link, SignedUrl> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<Link, SignedUrl>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GenerateScenarioUploadSignedURL(ScenarioFilePath) returns (ScenarioUploadURL);
     */
    generateScenarioUploadSignedURL(input: ScenarioFilePath, options?: RpcOptions): UnaryCall<ScenarioFilePath, ScenarioUploadURL> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioFilePath, ScenarioUploadURL>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ClearScenarioResults(Scenario) returns (Scenario);
     */
    clearScenarioResults(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Scenario> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetScenarioResults(ScenarioResults) returns (Scenario);
     */
    setScenarioResults(input: ScenarioResults, options?: RpcOptions): UnaryCall<ScenarioResults, Scenario> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<ScenarioResults, Scenario>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScenarioResults(Scenario) returns (ScenarioResults);
     */
    getScenarioResults(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ScenarioResults> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, ScenarioResults>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SubscribeForUpdates(Subscription) returns (stream SubscriptionMessage);
     */
    subscribeForUpdates(input: Subscription, options?: RpcOptions): ServerStreamingCall<Subscription, SubscriptionMessage> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<Subscription, SubscriptionMessage>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PublishSubscriptionMessage(SubscriptionMessage) returns (Empty);
     */
    publishSubscriptionMessage(input: SubscriptionMessage, options?: RpcOptions): UnaryCall<SubscriptionMessage, Empty> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<SubscriptionMessage, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CompleteExecution(CompleteExecutionMessage) returns (Empty);
     */
    completeExecution(input: CompleteExecutionMessage, options?: RpcOptions): UnaryCall<CompleteExecutionMessage, Empty> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompleteExecutionMessage, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CompleteExecutionIgnoreInstance(ExecutionMessage) returns (Empty);
     */
    completeExecutionIgnoreInstance(input: ExecutionMessage, options?: RpcOptions): UnaryCall<ExecutionMessage, Empty> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionMessage, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateExecution(Json) returns (Empty);
     */
    updateExecution(input: Json, options?: RpcOptions): UnaryCall<Json, Empty> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<Json, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ClearExecutionMemory(Scenario) returns (Empty);
     */
    clearExecutionMemory(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Empty> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveExecutionData(Scenario) returns (Empty);
     */
    removeExecutionData(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, Empty> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLatestMainExecution(Scenario) returns (ExecutionId);
     */
    getLatestMainExecution(input: Scenario, options?: RpcOptions): UnaryCall<Scenario, ExecutionId> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<Scenario, ExecutionId>("unary", this._transport, method, opt, input);
    }
    /**
     * Garbage collection
     *
     * @generated from protobuf rpc: UpdateProbe(ProbeInformation) returns (ProbeResponse);
     */
    updateProbe(input: ProbeInformation, options?: RpcOptions): UnaryCall<ProbeInformation, ProbeResponse> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<ProbeInformation, ProbeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Data-Port stream wrapper
     *
     * @generated from protobuf rpc: WriteDataStream(stream WriteDataStreamRequest) returns (stream WriteDataStreamResponse);
     */
    writeDataStream(options?: RpcOptions): DuplexStreamingCall<WriteDataStreamRequest, WriteDataStreamResponse> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<WriteDataStreamRequest, WriteDataStreamResponse>("duplex", this._transport, method, opt);
    }
    /**
     * @generated from protobuf rpc: ReadDataStream(stream ReadDataStreamRequest) returns (stream ReadDataStreamResponse);
     */
    readDataStream(options?: RpcOptions): DuplexStreamingCall<ReadDataStreamRequest, ReadDataStreamResponse> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReadDataStreamRequest, ReadDataStreamResponse>("duplex", this._transport, method, opt);
    }
    /**
     * @generated from protobuf rpc: GetDataStreamStats(GetDataStreamStatsRequest) returns (GetDataStreamStatsResponse);
     */
    getDataStreamStats(input: GetDataStreamStatsRequest, options?: RpcOptions): UnaryCall<GetDataStreamStatsRequest, GetDataStreamStatsResponse> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDataStreamStatsRequest, GetDataStreamStatsResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service JobManager
 */
export interface IJobManagerClient {
    /**
     * Start Job endpoints
     *
     * @generated from protobuf rpc: StartJob(Job) returns (ExecutionId);
     */
    startJob(input: Job, options?: RpcOptions): UnaryCall<Job, ExecutionId>;
    /**
     * @generated from protobuf rpc: StartJobWithTime(JobTime) returns (Job);
     */
    startJobWithTime(input: JobTime, options?: RpcOptions): UnaryCall<JobTime, Job>;
    /**
     * @generated from protobuf rpc: StartJobWithSchedule(JobSchedule) returns (Job);
     */
    startJobWithSchedule(input: JobSchedule, options?: RpcOptions): UnaryCall<JobSchedule, Job>;
    /**
     * @generated from protobuf rpc: StartJobWithTimeAndSchedule(JobTimeSchedule) returns (Job);
     */
    startJobWithTimeAndSchedule(input: JobTimeSchedule, options?: RpcOptions): UnaryCall<JobTimeSchedule, Job>;
    /**
     * @generated from protobuf rpc: StartLocalJob(StartLocalJobRequest) returns (StartLocalJobResponse);
     */
    startLocalJob(input: StartLocalJobRequest, options?: RpcOptions): UnaryCall<StartLocalJobRequest, StartLocalJobResponse>;
    /**
     * @generated from protobuf rpc: TerminateJob(Job) returns (Job);
     */
    terminateJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job>;
    /**
     * @generated from protobuf rpc: ResetJob(Job) returns (Job);
     */
    resetJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job>;
    /**
     * @generated from protobuf rpc: GetExecutionStatus(ExecutionId) returns (Json);
     */
    getExecutionStatus(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Json>;
    /**
     * @generated from protobuf rpc: GetExecutionState(ExecutionStateMessage) returns (ExecutionState);
     */
    getExecutionState(input: ExecutionStateMessage, options?: RpcOptions): UnaryCall<ExecutionStateMessage, ExecutionState>;
    /**
     * @generated from protobuf rpc: DeleteExecution(ExecutionId) returns (Empty);
     */
    deleteExecution(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Empty>;
    /**
     * Job scheduling
     *
     * @generated from protobuf rpc: AddJobSchedule(JobSchedule) returns (Empty);
     */
    addJobSchedule(input: JobSchedule, options?: RpcOptions): UnaryCall<JobSchedule, Empty>;
    /**
     * @generated from protobuf rpc: UpdateJobSchedule(JobSchedule) returns (Empty);
     */
    updateJobSchedule(input: JobSchedule, options?: RpcOptions): UnaryCall<JobSchedule, Empty>;
    /**
     * @generated from protobuf rpc: RemoveJobSchedule(Job) returns (Empty);
     */
    removeJobSchedule(input: Job, options?: RpcOptions): UnaryCall<Job, Empty>;
    /**
     * @generated from protobuf rpc: GetJobSchedule(Job) returns (Schedule);
     */
    getJobSchedule(input: Job, options?: RpcOptions): UnaryCall<Job, Schedule>;
    /**
     * Execution scheduling
     *
     * @generated from protobuf rpc: AddExecutionSchedule(ExecutionAndSchedule) returns (Empty);
     */
    addExecutionSchedule(input: ExecutionAndSchedule, options?: RpcOptions): UnaryCall<ExecutionAndSchedule, Empty>;
    /**
     * @generated from protobuf rpc: UpdateExecutionSchedule(ExecutionAndSchedule) returns (Empty);
     */
    updateExecutionSchedule(input: ExecutionAndSchedule, options?: RpcOptions): UnaryCall<ExecutionAndSchedule, Empty>;
    /**
     * @generated from protobuf rpc: RemoveExecutionSchedule(ExecutionId) returns (Empty);
     */
    removeExecutionSchedule(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Empty>;
    /**
     * @generated from protobuf rpc: GetExecutionSchedule(ExecutionId) returns (Schedule);
     */
    getExecutionSchedule(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Schedule>;
    /**
     * Hibernation
     *
     * @generated from protobuf rpc: ResumeJob(Job) returns (Job);
     */
    resumeJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job>;
    /**
     * @generated from protobuf rpc: HibernateJob(Job) returns (Job);
     */
    hibernateJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job>;
}
/**
 * @generated from protobuf service JobManager
 */
export class JobManagerClient implements IJobManagerClient, ServiceInfo {
    typeName = JobManager.typeName;
    methods = JobManager.methods;
    options = JobManager.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Start Job endpoints
     *
     * @generated from protobuf rpc: StartJob(Job) returns (ExecutionId);
     */
    startJob(input: Job, options?: RpcOptions): UnaryCall<Job, ExecutionId> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, ExecutionId>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartJobWithTime(JobTime) returns (Job);
     */
    startJobWithTime(input: JobTime, options?: RpcOptions): UnaryCall<JobTime, Job> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobTime, Job>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartJobWithSchedule(JobSchedule) returns (Job);
     */
    startJobWithSchedule(input: JobSchedule, options?: RpcOptions): UnaryCall<JobSchedule, Job> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobSchedule, Job>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartJobWithTimeAndSchedule(JobTimeSchedule) returns (Job);
     */
    startJobWithTimeAndSchedule(input: JobTimeSchedule, options?: RpcOptions): UnaryCall<JobTimeSchedule, Job> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobTimeSchedule, Job>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartLocalJob(StartLocalJobRequest) returns (StartLocalJobResponse);
     */
    startLocalJob(input: StartLocalJobRequest, options?: RpcOptions): UnaryCall<StartLocalJobRequest, StartLocalJobResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<StartLocalJobRequest, StartLocalJobResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: TerminateJob(Job) returns (Job);
     */
    terminateJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, Job>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ResetJob(Job) returns (Job);
     */
    resetJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, Job>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetExecutionStatus(ExecutionId) returns (Json);
     */
    getExecutionStatus(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Json> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionId, Json>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetExecutionState(ExecutionStateMessage) returns (ExecutionState);
     */
    getExecutionState(input: ExecutionStateMessage, options?: RpcOptions): UnaryCall<ExecutionStateMessage, ExecutionState> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionStateMessage, ExecutionState>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteExecution(ExecutionId) returns (Empty);
     */
    deleteExecution(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionId, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Job scheduling
     *
     * @generated from protobuf rpc: AddJobSchedule(JobSchedule) returns (Empty);
     */
    addJobSchedule(input: JobSchedule, options?: RpcOptions): UnaryCall<JobSchedule, Empty> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobSchedule, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateJobSchedule(JobSchedule) returns (Empty);
     */
    updateJobSchedule(input: JobSchedule, options?: RpcOptions): UnaryCall<JobSchedule, Empty> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobSchedule, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveJobSchedule(Job) returns (Empty);
     */
    removeJobSchedule(input: Job, options?: RpcOptions): UnaryCall<Job, Empty> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJobSchedule(Job) returns (Schedule);
     */
    getJobSchedule(input: Job, options?: RpcOptions): UnaryCall<Job, Schedule> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, Schedule>("unary", this._transport, method, opt, input);
    }
    /**
     * Execution scheduling
     *
     * @generated from protobuf rpc: AddExecutionSchedule(ExecutionAndSchedule) returns (Empty);
     */
    addExecutionSchedule(input: ExecutionAndSchedule, options?: RpcOptions): UnaryCall<ExecutionAndSchedule, Empty> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionAndSchedule, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateExecutionSchedule(ExecutionAndSchedule) returns (Empty);
     */
    updateExecutionSchedule(input: ExecutionAndSchedule, options?: RpcOptions): UnaryCall<ExecutionAndSchedule, Empty> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionAndSchedule, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveExecutionSchedule(ExecutionId) returns (Empty);
     */
    removeExecutionSchedule(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Empty> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionId, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetExecutionSchedule(ExecutionId) returns (Schedule);
     */
    getExecutionSchedule(input: ExecutionId, options?: RpcOptions): UnaryCall<ExecutionId, Schedule> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExecutionId, Schedule>("unary", this._transport, method, opt, input);
    }
    /**
     * Hibernation
     *
     * @generated from protobuf rpc: ResumeJob(Job) returns (Job);
     */
    resumeJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, Job>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: HibernateJob(Job) returns (Job);
     */
    hibernateJob(input: Job, options?: RpcOptions): UnaryCall<Job, Job> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<Job, Job>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service TokenManager
 */
export interface ITokenManagerClient {
    /**
     * @generated from protobuf rpc: AuthenticateUser(AuthenticateUserRequest) returns (AuthenticateUserResponse);
     */
    authenticateUser(input: AuthenticateUserRequest, options?: RpcOptions): UnaryCall<AuthenticateUserRequest, AuthenticateUserResponse>;
    /**
     * @generated from protobuf rpc: RefreshUser(RefreshUserRequest) returns (RefreshUserResponse);
     */
    refreshUser(input: RefreshUserRequest, options?: RpcOptions): UnaryCall<RefreshUserRequest, RefreshUserResponse>;
    /**
     * @generated from protobuf rpc: CreateRefreshToken(TokenRequest) returns (Token);
     */
    createRefreshToken(input: TokenRequest, options?: RpcOptions): UnaryCall<TokenRequest, Token>;
    /**
     * @generated from protobuf rpc: GetAccessToken(RefreshRequest) returns (Token);
     */
    getAccessToken(input: RefreshRequest, options?: RpcOptions): UnaryCall<RefreshRequest, Token>;
}
/**
 * @generated from protobuf service TokenManager
 */
export class TokenManagerClient implements ITokenManagerClient, ServiceInfo {
    typeName = TokenManager.typeName;
    methods = TokenManager.methods;
    options = TokenManager.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AuthenticateUser(AuthenticateUserRequest) returns (AuthenticateUserResponse);
     */
    authenticateUser(input: AuthenticateUserRequest, options?: RpcOptions): UnaryCall<AuthenticateUserRequest, AuthenticateUserResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateUserRequest, AuthenticateUserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RefreshUser(RefreshUserRequest) returns (RefreshUserResponse);
     */
    refreshUser(input: RefreshUserRequest, options?: RpcOptions): UnaryCall<RefreshUserRequest, RefreshUserResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<RefreshUserRequest, RefreshUserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateRefreshToken(TokenRequest) returns (Token);
     */
    createRefreshToken(input: TokenRequest, options?: RpcOptions): UnaryCall<TokenRequest, Token> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<TokenRequest, Token>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccessToken(RefreshRequest) returns (Token);
     */
    getAccessToken(input: RefreshRequest, options?: RpcOptions): UnaryCall<RefreshRequest, Token> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<RefreshRequest, Token>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service BuildManager
 */
export interface IBuildManagerClient {
    /**
     * @generated from protobuf rpc: CreateSnapshot(Snapshot) returns (SnapshotReply);
     */
    createSnapshot(input: Snapshot, options?: RpcOptions): UnaryCall<Snapshot, SnapshotReply>;
    /**
     * @generated from protobuf rpc: CreateHistoryUpdate(HistoryUpdate) returns (HistoryReply);
     */
    createHistoryUpdate(input: HistoryUpdate, options?: RpcOptions): UnaryCall<HistoryUpdate, HistoryReply>;
    /**
     * @generated from protobuf rpc: GetHistory(HistoryRequest) returns (History);
     */
    getHistory(input: HistoryRequest, options?: RpcOptions): UnaryCall<HistoryRequest, History>;
    /**
     * @generated from protobuf rpc: PullSnapshot(PullRequest) returns (PullResponse);
     */
    pullSnapshot(input: PullRequest, options?: RpcOptions): UnaryCall<PullRequest, PullResponse>;
    /**
     * @generated from protobuf rpc: LaunchBuild(BuildRequest) returns (BuildReply);
     */
    launchBuild(input: BuildRequest, options?: RpcOptions): UnaryCall<BuildRequest, BuildReply>;
    /**
     * @generated from protobuf rpc: Builder(stream BuildResponse) returns (stream Json);
     */
    builder(options?: RpcOptions): DuplexStreamingCall<BuildResponse, Json>;
    /**
     * @generated from protobuf rpc: GetBuildInfo(BuildInfoRequest) returns (BuildInfo);
     */
    getBuildInfo(input: BuildInfoRequest, options?: RpcOptions): UnaryCall<BuildInfoRequest, BuildInfo>;
    /**
     * @generated from protobuf rpc: UpdateBuild(BuildUpdate) returns (Empty);
     */
    updateBuild(input: BuildUpdate, options?: RpcOptions): UnaryCall<BuildUpdate, Empty>;
    /**
     * @generated from protobuf rpc: UpdateJobImageReference(JobImageReferenceUpdate) returns (Empty);
     */
    updateJobImageReference(input: JobImageReferenceUpdate, options?: RpcOptions): UnaryCall<JobImageReferenceUpdate, Empty>;
}
/**
 * @generated from protobuf service BuildManager
 */
export class BuildManagerClient implements IBuildManagerClient, ServiceInfo {
    typeName = BuildManager.typeName;
    methods = BuildManager.methods;
    options = BuildManager.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateSnapshot(Snapshot) returns (SnapshotReply);
     */
    createSnapshot(input: Snapshot, options?: RpcOptions): UnaryCall<Snapshot, SnapshotReply> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Snapshot, SnapshotReply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateHistoryUpdate(HistoryUpdate) returns (HistoryReply);
     */
    createHistoryUpdate(input: HistoryUpdate, options?: RpcOptions): UnaryCall<HistoryUpdate, HistoryReply> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<HistoryUpdate, HistoryReply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetHistory(HistoryRequest) returns (History);
     */
    getHistory(input: HistoryRequest, options?: RpcOptions): UnaryCall<HistoryRequest, History> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<HistoryRequest, History>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PullSnapshot(PullRequest) returns (PullResponse);
     */
    pullSnapshot(input: PullRequest, options?: RpcOptions): UnaryCall<PullRequest, PullResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<PullRequest, PullResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: LaunchBuild(BuildRequest) returns (BuildReply);
     */
    launchBuild(input: BuildRequest, options?: RpcOptions): UnaryCall<BuildRequest, BuildReply> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<BuildRequest, BuildReply>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Builder(stream BuildResponse) returns (stream Json);
     */
    builder(options?: RpcOptions): DuplexStreamingCall<BuildResponse, Json> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<BuildResponse, Json>("duplex", this._transport, method, opt);
    }
    /**
     * @generated from protobuf rpc: GetBuildInfo(BuildInfoRequest) returns (BuildInfo);
     */
    getBuildInfo(input: BuildInfoRequest, options?: RpcOptions): UnaryCall<BuildInfoRequest, BuildInfo> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<BuildInfoRequest, BuildInfo>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateBuild(BuildUpdate) returns (Empty);
     */
    updateBuild(input: BuildUpdate, options?: RpcOptions): UnaryCall<BuildUpdate, Empty> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<BuildUpdate, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateJobImageReference(JobImageReferenceUpdate) returns (Empty);
     */
    updateJobImageReference(input: JobImageReferenceUpdate, options?: RpcOptions): UnaryCall<JobImageReferenceUpdate, Empty> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<JobImageReferenceUpdate, Empty>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service FileManager
 */
export interface IFileManagerClient {
    /**
     * @generated from protobuf rpc: GetDownloadUrls(FilePaths) returns (FileUrls);
     */
    getDownloadUrls(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, FileUrls>;
    /**
     * @generated from protobuf rpc: GetUploadUrls(FilePaths) returns (FileUrls);
     */
    getUploadUrls(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, FileUrls>;
    /**
     * @generated from protobuf rpc: DeleteFiles(FilePaths) returns (Empty);
     */
    deleteFiles(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, Empty>;
}
/**
 * @generated from protobuf service FileManager
 */
export class FileManagerClient implements IFileManagerClient, ServiceInfo {
    typeName = FileManager.typeName;
    methods = FileManager.methods;
    options = FileManager.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetDownloadUrls(FilePaths) returns (FileUrls);
     */
    getDownloadUrls(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, FileUrls> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<FilePaths, FileUrls>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUploadUrls(FilePaths) returns (FileUrls);
     */
    getUploadUrls(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, FileUrls> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<FilePaths, FileUrls>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteFiles(FilePaths) returns (Empty);
     */
    deleteFiles(input: FilePaths, options?: RpcOptions): UnaryCall<FilePaths, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<FilePaths, Empty>("unary", this._transport, method, opt, input);
    }
}
