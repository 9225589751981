import firebase from "firebase/compat/app";
import { SimFile, SimulationScenario } from "model/datatypes";
import {
  convertFromFirestoreFormat,
  convertToFirestoreFormat,
} from "../../utils/firebase/firestoreFormatter";

export const saveFileInFs = (
  fs: firebase.firestore.Firestore,
  uploadedFile: SimFile,
  tags: string[],
  organizationId: string
) => {
  const batch = fs.batch();

  const organizationFileLib = fs
    .collection("organizations")
    .doc(organizationId)
    .collection("FileLibrary")
    .doc(uploadedFile.organizationId);

  const fileLibDoc = fs
    .collection("organizations")
    .doc(organizationId)
    .collection("files")
    .doc(uploadedFile.id);
  batch.set(fileLibDoc, uploadedFile); //store the file reference

  batch.set(
    organizationFileLib,
    {
      tags: firebase.firestore.FieldValue.arrayUnion(...tags),
      types: firebase.firestore.FieldValue.arrayUnion(uploadedFile.type),
    },
    { merge: true }
  );

  return batch.commit();
};

export const getScenario = async (
  projectID: string,
  scenarioID: string,
  fs: firebase.firestore.Firestore,
  organizationId: string
) => {
  const scenarioDoc = await fs
    .collection("organizations")
    .doc(organizationId)
    .collection("projects")
    .doc(projectID)
    .collection("scenarios")
    .doc(scenarioID)
    .get();

  if (scenarioDoc.exists) {
    return convertFromFirestoreFormat({
      ...scenarioDoc.data(),
      id: scenarioDoc.id,
    }) as SimulationScenario;
  }
  return undefined;
};

// NUM-499
export const removeProjectType = (fs: firebase.firestore.Firestore, projectTypeID: string) =>
  fs
    .collection("ProjectTypes")
    .doc(projectTypeID)
    .update(convertToFirestoreFormat({ deleted: new Date() }));
