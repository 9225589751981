import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useDeprecatedFirestore } from "api/useFirebase";
import { SystemModel } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";

export const useArchiveSystem = (system: SystemModel, type: string) => {
  const activeOrganizationId = useActiveOrganizationId();
  const [archivingSystem, setArchivingSystem] = useState(false);
  const fs = useDeprecatedFirestore();

  const archiveSystem = () => {
    if (archivingSystem || !activeOrganizationId) return;
    const newStatus = system.status === "archived" ? "published" : "archived";
    try {
      setArchivingSystem(true);
      const promise = fs
        .collection("organizations")
        .doc(activeOrganizationId)
        .collection("systems")
        .doc(system.id)
        .update({ status: newStatus });
      const toastMessages =
        newStatus === "archived"
          ? {
              success: `The ${type} has been archived`,
              loading: `Archiving the ${type}...`,
              error: `Could not archive the ${type}`,
            }
          : {
              success: `The ${type} has been unarchived`,
              loading: `Unarchiving the ${type}...`,
              error: `Could not unarchive the ${type}`,
            };
      toast.promise(promise, toastMessages);
    } catch (error) {
      console.error(error);
    } finally {
      setArchivingSystem(false);
    }
  };

  return { archiveSystem, archivingSystem };
};
