import { Placement } from "@floating-ui/react-dom-interactions";
import { Content, PopperContentProps, Portal, Root, Trigger } from "@radix-ui/react-tooltip";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { zHoverToolTip } from "thermo/zIndex/zIndexValues";
import { getTransformOrigin } from "utils/getTransformOrigin";

interface Props {
  label: ReactNode;
  placement?: Placement;
  disableTooltip?: boolean;
  children: ReactNode;
}

const Tooltip = ({ label, placement, disableTooltip, children }: Props) => {
  const [side, align] = placement
    ? (placement.split("-") as [PopperContentProps["side"], PopperContentProps["align"]])
    : [];

  if (disableTooltip) return children as JSX.Element;
  return (
    <Root delayDuration={200}>
      <Trigger asChild data-testid="tooltipTrigger">
        <div>{children}</div>
      </Trigger>
      <Portal>
        <Content
          hideWhenDetached
          side={side}
          align={align}
          sideOffset={8}
          collisionPadding={10}
          style={{
            zIndex: zHoverToolTip,
          }}
        >
          <motion.div
            style={{
              transformOrigin: getTransformOrigin(placement || "top"),
            }}
            onClick={(e) => e.stopPropagation()}
            data-testid="tooltip"
            className="bg-slate-800 text-slate-50 rounded-md py-2 px-4 text-[13px] shadow-md max-w-lg select-none"
            initial={{
              opacity: 0,
              scale: 0.85,
            }}
            animate={{
              opacity: 0.95,
              scale: 1,
              transition: {
                delay: 0.3,
              },
            }}
            transition={{ duration: 0.07 }}
          >
            {label}
          </motion.div>
        </Content>
      </Portal>
    </Root>
  );
};

export default Tooltip;
