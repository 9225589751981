import { useDeprecatedFirestore } from "api/useFirebase";
import { PublicLink } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";

export const useRemovePublicLink = () => {
  const [removingPublicLink, setRemovingPublicLink] = useState(false);
  const fs = useDeprecatedFirestore();
  const removePublicLink = (publicLink: PublicLink) => {
    if (removingPublicLink || !publicLink) return;

    setRemovingPublicLink(true);
    fs.collection("Links")
      .doc(publicLink.id)
      .delete()
      .then(() => {
        setRemovingPublicLink(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error removing the link");
        setRemovingPublicLink(false);
      });
  };
  return { removePublicLink, removingPublicLink };
};
