import { Plus } from "@phosphor-icons/react";
import RepositionList from "Components/Basic/RepositionList";
import { InputVar } from "model/datatypes";
import React, { useMemo } from "react";
import { Button } from "thermo/button/Button";
import getUUID from "utils/jsUtils/getUUID";
import InputVariableEditable from "./InputVariableEditable";

interface Props {
  componentVariables: InputVar[];
  onUpdate: (updatedVar: InputVar) => void;
  onDelete: (input: InputVar) => void;
  onUpdateAll: (updatedVars: InputVar[]) => void;
  useExperimental?: boolean;
}

const InputEditor: React.FC<Props> = ({
  componentVariables,
  onUpdate,
  onDelete,
  onUpdateAll,
  useExperimental,
}) => {
  const inputList = useMemo(
    () =>
      componentVariables.map((v) => ({
        id: v.uuid,
        element: (
          <InputVariableEditable
            key={v.uuid}
            value={v}
            onUpdate={(newVal) => {
              onUpdate(newVal);
            }}
            onDeleteInput={() => {
              onDelete(v);
            }}
            useExperimental={useExperimental}
          />
        ),
        val: v,
      })),
    [componentVariables, onDelete, onUpdate, useExperimental]
  );

  return (
    <div className="flex flex-col">
      <div className="flex-none">
        <div className="border-b border-gray-200 text-sm font-bold">Input variables</div>
        <div className="flex w-full items-center px-2 py-2">
          <div className="w-1/8 pr-4 text-sm font-medium">ID</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Display name</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Tooltip</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Unit</div>
          <div className="w-1/8 pr-4 text-sm font-medium" />
          <div className="w-1/8 pr-4 text-sm font-medium">Standard value</div>
          {useExperimental && <div className="w-1/6 pr-4 text-sm font-medium">Type</div>}
        </div>
      </div>
      <div className="flex-grow text-sm">
        <RepositionList
          list={inputList}
          onReposition={(updatedList) => {
            const updatedVars = updatedList.map((li) => li.val) as InputVar[];
            onUpdateAll(updatedVars);
          }}
        />
        {componentVariables.length === 0 && (
          <div className="px-2 text-sm italic">No variables added</div>
        )}
      </div>
      <div className="flex-none py-2 px-2">
        <Button
          size="sm"
          icon={<Plus size={14} />}
          onClick={() => {
            const newCompVariable: InputVar = {
              uuid: getUUID(),
              id: "",
              display: "",
              dataSourceType: "static",
              scaling: 1,
              offset: 0,
              unit: "",
              value: 0,
            };
            onUpdate(newCompVariable);
          }}
          className="w-16 rounded border border-gray-200 bg-white shadow-md focus:outline-none"
        >
          Add input variable
        </Button>
      </div>
    </div>
  );
};

export default InputEditor;
