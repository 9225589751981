import {
  Copy,
  DownloadSimple,
  Link as LinkIcon,
  Tag,
  Trash,
  UploadSimple,
} from "@phosphor-icons/react";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import RemoveTag from "Components/Basic/icons/RemoveTag";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import DuplicateScenario from "Components/Simulations/newSimulation/DuplicateScenario";
import { useToggleArchiveScenario } from "Components/TopBar/api/archiveScenario";
import { useGetGroup } from "api/group/getGroup";
import useDeleteScenario from "api/scenario/deleteScenario";
import { useUserRole } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import { SimulationScenario } from "model/datatypes";
import React, { useState } from "react";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import useCopyToClipboard from "utils/clipboard/copyToClipboard";
import AddScenarioLabel from "../Label/AddScenarioLabel";
import { useDeleteScenarioMark } from "../Label/api/useDeleteScenarioMark";
import { useGetScenarioMark } from "../Label/api/useGetScenarioMark";

type Props = {
  scenario: SimulationScenario;
};

const ScenarioRowOptions: React.FC<Props> = ({ scenario }) => {
  const [showDeleteScenarioModal, setShowDeleteScenarioModal] = useState(false);
  const [duplicatingScenario, setDuplicatingScenario] = useState<false | SimulationScenario>(
    false
  );
  const [showRemoveLabelModal, setShowRemoveLabelModal] = useState(false);
  const [addScenarioLabel, setAddScenarioLabel] = useState(false);
  const [showArchiveScenarioLabelModal, setShowArchiveScenarioLabelModal] = useState(false);
  const [deleteScenario, { loading: deletingScenario }] = useDeleteScenario();

  const { data: groupData } = useGetGroup(scenario.projectID, scenario.groupID);
  const { isProjectOwner } = useUserRole();
  const { toggleArchiveScenario } = useToggleArchiveScenario({
    scenarioIsArchived: scenario.isArchived,
    scenarioId: scenario.id,
    projectId: scenario.projectID,
  });
  const [, copyToClipboard] = useCopyToClipboard({ itemTypeToCopy: "The link" });
  const organizationSlug = useOrganizationSlugFromParams();

  const copyScenarioLinkToClipboard = () => {
    copyToClipboard(
      `${window.location.protocol}//${window.location.host}/${organizationSlug}/project/${scenario.projectID}/${scenario.id}`,
      true
    );
  };

  const { handleDeleteMark: handleDeleteLabel, deletingMark: deletingLabel } =
    useDeleteScenarioMark({ scenario });

  const labelId = scenario?.label?.label_id;
  const { labelDetails } = useGetScenarioMark({ markId: labelId });

  const deleteScenarioDescription = (
    <>
      Do you want to delete{" "}
      <span data-testid="deleteModelName" className="font-medium">
        {scenario?.scenarioName}
      </span>
      ? <br />
      {`This can't be undone.`}
    </>
  );

  const removeLabelDescription = (
    <>
      Do you want to remove label{" "}
      <span data-testid="deleteModelName" className="font-medium capitalize">
        {labelDetails && labelDetails.title}
      </span>
      ?
      <br />
      {`This can't be undone.`}
    </>
  );

  return (
    <>
      <PopoverMenu placement="left-start">
        <Popover.Button
          data-testid="copyLinkToScenariofromRow"
          icon={<LinkIcon width={100} height="95%" />}
          onClick={() => copyScenarioLinkToClipboard()}
        >
          Copy link to scenario
        </Popover.Button>
        <Popover.Button
          data-testid="duplicateScenariofromRow"
          icon={<Copy width={100} height="95%" />}
          onClick={() => setDuplicatingScenario(scenario)}
        >
          Duplicate scenario
        </Popover.Button>
        <Popover.Button
          data-testid="labelScenariofromRow"
          icon={scenario.label ? <RemoveTag /> : <Tag width={100} height="95%" />}
          onClick={() => {
            if (scenario.label) setShowRemoveLabelModal(true);
            else setAddScenarioLabel(true);
          }}
        >
          {scenario.label ? "Remove label" : "Label scenario"}
        </Popover.Button>
        {isProjectOwner && (
          <>
            <Popover.Button
              data-testid="archiveScenarioButton"
              icon={
                scenario.isArchived ? (
                  <UploadSimple width={100} size="95%" weight="bold" />
                ) : (
                  <DownloadSimple width={100} size="95%" weight="bold" />
                )
              }
              onClick={() => {
                if (scenario.label) {
                  setShowArchiveScenarioLabelModal(true);
                } else {
                  toggleArchiveScenario();
                }
              }}
            >
              {scenario.isArchived ? "Unarchive scenario" : "Archive scenario"}
            </Popover.Button>
            <Popover.Button
              onClick={() => setShowDeleteScenarioModal(true)}
              icon={<Trash width="100%" height="100%" />}
              className="text-red-500"
              data-testid="deleteScenarioButton"
            >
              Delete
            </Popover.Button>
          </>
        )}
      </PopoverMenu>
      <AnimatePresence>
        {duplicatingScenario && groupData?.group.__typename === "Group" && (
          <DuplicateScenario
            key={`${duplicatingScenario.projectID}newScenarioModal`}
            projectID={duplicatingScenario.projectID}
            onFinish={() => {
              setDuplicatingScenario(false);
            }}
            fromScenario={duplicatingScenario}
            group={groupData.group}
          />
        )}
        {showDeleteScenarioModal && (
          <ConfirmModal
            key={`${scenario.projectID}confirmModal`}
            loading={deletingScenario}
            disableConfirmButtonIf={!!scenario.label}
            onConfirm={async () => {
              if (deletingScenario) return;
              await deleteScenario({
                variables: {
                  projectId: scenario.projectID,
                  scenarioId: scenario.id,
                },
              });
              setShowDeleteScenarioModal(false);
            }}
            onCancel={() => setShowDeleteScenarioModal(false)}
            warning
            description={
              scenario.label
                ? "You can not delete a labeled scenario."
                : deleteScenarioDescription
            }
            headline={scenario.label ? "Can not delete" : "Delete scenario?"}
          />
        )}
        {addScenarioLabel && (
          <AddScenarioLabel
            scenarioID={scenario.id}
            scenarioName={scenario.scenarioName}
            projectID={scenario.projectID}
            onFinish={() => {
              setAddScenarioLabel(false);
            }}
          />
        )}
        {showRemoveLabelModal && (
          <ConfirmModal
            key={`${scenario.id}confirmModal`}
            loading={deletingLabel}
            onConfirm={() => {
              handleDeleteLabel();
              setShowRemoveLabelModal(false);
            }}
            onCancel={() => setShowRemoveLabelModal(false)}
            warning
            description={removeLabelDescription}
            headline="Remove label?"
          />
        )}
        {showArchiveScenarioLabelModal && (
          <ConfirmModal
            key={`${scenario.id}confirmModal`}
            disableConfirmButtonIf
            onConfirm={() => {}}
            onCancel={() => setShowArchiveScenarioLabelModal(false)}
            warning
            description="You can not archive a labeled scenario."
            headline="Can not archive"
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ScenarioRowOptions;
