import { CSVData } from "Components/Input/types";

/**
 * Used to check if a column of CSV data is fitted as the index column
 * Requirements for an index column is:
 *   - variableType is number
 *   - included is true
 *   - cannot start on negative numbers
 *   - value of row increases
 *
 * @param column [CSVData] This is a column in CSV Data
 * @returns [boolean] True if column is valid as index column
 */

export const isValidAsIndexColumn = (column: CSVData): boolean => {
  const { variableType, included, values } = column;
  const { length } = values;

  if (variableType !== "number" || !included) return false;

  // Ensure values does not start on negative numbers
  if (length <= 0 || Number(values[0]) < 0) return false;

  // Check values are increasing
  for (let i = 1; i < length; i += 1) {
    const currentValue = values[i];
    const previousValue = values[i - 1];
    if (currentValue <= previousValue) return false;
  }
  return true;
};
