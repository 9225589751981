import Editor from "@monaco-editor/react";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import React, { useEffect, useState } from "react";

interface Props {
  defaultCode: string;
  onUpdate?: (updated: string) => void;
  language: string;
  classNames?: string;
  readOnly?: boolean;
  minimap?: boolean;
}

const CodeEditor: React.FC<Props> = ({
  defaultCode,
  onUpdate = () => {},
  language,
  classNames = "",
  readOnly = false,
  minimap = true,
}) => {
  const [editorReady, setEditorReady] = useState(false);
  const [value, setValue] = useState(defaultCode);

  useEffect(() => {
    setValue(defaultCode);
  }, [defaultCode]);

  return (
    <div className={`relative rounded border border-gray-200 py-1 ${classNames}`}>
      <Editor
        value={value}
        language={language}
        options={{
          readOnly,
          scrollBeyondLastLine: false,
          minimap: { enabled: minimap, maxColumn: 60, size: "fit" },
          formatOnPaste: true,
          detectIndentation: true,
          emptySelectionClipboard: true,
          lineDecorationsWidth: 2,
          scrollbar: { verticalScrollbarSize: 6, horizontalScrollbarSize: 6 },
          smoothScrolling: true,
        }}
        onMount={(editor) => {
          setEditorReady(true);
          setTimeout(() => {
            editor.getAction("editor.action.formatDocument")?.run(); // autoformats code on open
          }, 10);
        }}
        onChange={(val) => {
          setValue(val || "");
          if (val) {
            onUpdate(val);
          }
        }}
      />
      {!editorReady && <LoadingOverlay />}
    </div>
  );
};

export default CodeEditor;
