import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { Button } from "Components/Basic/Buttons";
import { RadioGroupInput } from "Components/Basic/Form/RadioGroup";
import TextInput from "Components/Basic/Form/TextInput";
import { useInviteNewUser } from "Components/Organisation/api/inviteNewUser";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { OrganizationMemberRole } from "GraphQL/gql/graphql";
import { Form, Formik } from "formik";
import { ROLE_OPTIONS } from "numerous_constants";
import * as Yup from "yup";
import AnimatePageTransition from "./utils/AnimatePageTransition";

const getInviteUserRoles = (currentUserRole?: OrganizationMemberRole) => {
  if (!currentUserRole) return [];

  let availableUserRoles = [...ROLE_OPTIONS];

  const hierarchy = availableUserRoles.find(({ id }) => id === currentUserRole)?.hierarchy;
  return availableUserRoles.filter(
    ({ id }) => hierarchy?.includes(id) || id === currentUserRole
  );
};

type FormProps = {
  newUserEmail: string;
  userRoleOption: {
    id: OrganizationMemberRole;
    display: string;
    description: string;
  };
};

const validationSchema = Yup.object({
  newUserEmail: Yup.string().required("Email is required").email("Email must be valid"),
});

const InviteNewUser = () => {
  const { activeOrganizationRole } = useGlobalUser();
  const { sendAutomatedInvite, sendingInvitation } = useInviteNewUser();
  const navigate = useNavigateApp();

  const submitForm = (values: FormProps) => {
    const valueFormatted = { ...values, newUserEmail: values.newUserEmail.toLowerCase() };
    sendAutomatedInvite(valueFormatted).then(() => {
      navigate("/organization/members", { replace: true });
    });
  };

  const filteredRoleOptions = getInviteUserRoles(activeOrganizationRole);

  return (
    <AnimatePageTransition title="Invite a new user">
      <Formik
        initialValues={{
          newUserEmail: "",
          userRoleOption: filteredRoleOptions[0],
        }}
        onSubmit={(values, { resetForm }) => {
          submitForm(values);
          resetForm();
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form data-testid="signupForm" className="w-3/4 space-y-4">
            <div className="w-80">
              <TextInput
                type="email"
                data-testid="newUserEmail"
                label="Email"
                name="newUserEmail"
              />
            </div>

            <RadioGroupInput
              name="userRoleOption"
              options={filteredRoleOptions}
              groupLabel="User role"
              setValue={formik.setFieldValue}
            />

            <div className="flex">
              <Button
                loading={sendingInvitation}
                type="submit"
                data-testid="submitInvitationButton"
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                buttonType="primary"
              >
                Send invitation
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AnimatePageTransition>
  );
};

export default InviteNewUser;
