import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const GET_ORGANIZATION_CREDIT_BALANCE = graphql(`
  query GET_ORGANIZATION_CREDIT_BALANCE {
    organization {
      id
      creditBalance
    }
  }
`);

export const useGetOrganizationCreditBalance = () => {
  const result = useQuery(GET_ORGANIZATION_CREDIT_BALANCE);

  return result;
};
