import React from "react";
import Skeleton from "thermo/skeleton/Skeleton";

export const LoadingActivityList = () => {
  return (
    <div className="space-y-3">
      <Skeleton.TextLine typography="Small" />
      <Skeleton.TextLine typography="Small" />
      <Skeleton.TextLine typography="Small" />
      <Skeleton.TextLine typography="Small" />
    </div>
  );
};
