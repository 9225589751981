import { Clipboard, Copy, DownloadSimple, Trash, UploadSimple } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useUserRole } from "api/useFirebase";
import { SystemModel } from "model/datatypes";
import React, { HTMLAttributes } from "react";
import { copyContentToClipboard } from "utils/clipboard/ClipboardContent";
import classNames from "utils/jsUtils/className";
import { useArchiveSystem } from "../api/archiveSystem";

interface IContextMenuButton {
  icon: React.ReactNode;
  text: string;
  type?: "warning";
  onClick?: HTMLAttributes<HTMLDivElement>["onClick"];
}

const ContextMenuButton: React.FC<IContextMenuButton> = ({ icon, text, type, onClick }) => {
  return (
    <div
      data-testid="toolOptionsMenuButton"
      onClick={onClick}
      className={classNames(
        type === "warning"
          ? "text-slate-900 hover:bg-error-100 hover:text-error-600"
          : "text-slate-900 hover:bg-gray-100",
        "flex rounded transition-colors duration-75 cursor-pointer items-center whitespace-nowrap px-3 py-2 text-sm"
      )}
    >
      {icon}
      <span className="ml-2">{text}</span>
    </div>
  );
};

interface ToolContextMenuProps {
  tool: SystemModel;
  onDelete: () => void;
  onClose: () => void;
}

const ToolOptionsMenu = ({ tool, onDelete, onClose }: ToolContextMenuProps) => {
  const { id: userId } = useGlobalUser();
  const { hasDeveloperAccess } = useUserRole();
  const type = tool.modelType === "optimization_model" ? "optimization type" : "tool";
  const { archiveSystem } = useArchiveSystem(tool, type);

  const isToolOwner = tool.ownerId === userId;

  return (
    <div className="p-1">
      <Link
        to="tools/create-tool"
        state={{
          tool,
          type,
        }}
      >
        <ContextMenuButton text={`Duplicate ${type}`} icon={<Copy size={16} />} />
      </Link>
      <ContextMenuButton
        text={`Copy ${type} to clipboard`}
        icon={<Clipboard size={16} />}
        onClick={(e) => {
          e.preventDefault();
          copyContentToClipboard(
            { contentType: "tool", content: tool },
            `The ${type} has been copied to the clipboard`
          );
          onClose();
        }}
      />

      {(isToolOwner || hasDeveloperAccess) && (
        <ContextMenuButton
          text={tool.status === "archived" ? "Remove from archive" : `Archive ${type}`}
          onClick={(e) => {
            e.preventDefault();
            archiveSystem();
            onClose();
          }}
          icon={
            tool.status === "archived" ? (
              <UploadSimple size={16} />
            ) : (
              <DownloadSimple size={16} />
            )
          }
        />
      )}

      {(isToolOwner || hasDeveloperAccess) && (
        <>
          <hr className="my-1 mx-2" />
          <ContextMenuButton
            type="warning"
            text={`Delete ${type}`}
            onClick={(e) => {
              e.preventDefault();
              onDelete();
              onClose();
            }}
            icon={<Trash size={16} />}
          />
        </>
      )}
    </div>
  );
};

export default ToolOptionsMenu;
