import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { WORKSPACES } from "./getWorkspaces";

export const DELETE_WORKSPACE = graphql(`
  mutation DELETE_WORKSPACE($workspaceId: ID!) {
    workspaceDelete(workspaceId: $workspaceId) {
      id
    }
  }
`);

export const useDeleteWorkspace = () => {
  const mutation = useMutation(DELETE_WORKSPACE, {
    update(cache, { data }) {
      const deletedWorkspaceId = data?.workspaceDelete.id;

      const workspaceList = cache.readQuery({
        query: WORKSPACES,
      });

      if (!workspaceList) return;
      let updatedWorkspaceList = workspaceList.workspaces.filter(
        (workspace) => workspace.id !== deletedWorkspaceId
      );

      cache.writeQuery({
        query: WORKSPACES,
        data: {
          workspaces: updatedWorkspaceList,
        },
      });
    },
  });

  return mutation;
};
