import { RawDataReport, SimulationJobType, SystemModel } from "model/datatypes";
import toast from "react-hot-toast";

type ClipboardContent =
  | {
      contentType: "tool";
      content: SystemModel;
    }
  | {
      contentType: "data_report";
      content: RawDataReport;
    };

export const copyContentToClipboard = async (
  content: ClipboardContent | string,
  msg?: string
) => {
  let promise: Promise<void>;
  if (typeof content === "string") promise = navigator.clipboard.writeText(content);
  else {
    const con = JSON.stringify(content);
    promise = navigator.clipboard.writeText(con);
  }
  toast.promise(promise, {
    success: msg || "Copied to clipboard",
    loading: "Copying data to clipboard",
    error: "Error copying data to clipboard",
  });
};

export const readFromClipboard = async () => {
  const textContent = await navigator.clipboard.readText();
  const parsed = JSON.parse(textContent) as ClipboardContent;
  if (!parsed || !parsed.contentType) return null;

  //convert dates front string to dates:
  if (parsed.contentType === "tool") {
    const jobs = Object.fromEntries(
      Object.entries(parsed.content.jobs).map(([id, job]) => {
        const parsedJob: SimulationJobType = { ...job };
        if (job.runSettings) {
          parsedJob.runSettings = {
            ...job.runSettings,
            endDate: new Date(job.runSettings.endDate),
            startDate: new Date(job.runSettings.startDate),
          };
        }
        return [id, parsedJob];
      })
    );

    parsed.content = {
      ...parsed.content,
      jobs,
    };
  }

  return parsed;
};
