import {
  PeriodSetting,
  PeriodSettingInput,
  PeriodTypeInput,
  Schedule,
  ScheduleInput,
  Timeslot,
  TimeslotInput,
} from "GraphQL/gql/graphql";

const convertTimeslotToInput = (timeslot: Timeslot): TimeslotInput => {
  const newTimeslot = { ...timeslot };
  delete newTimeslot.__typename;
  return newTimeslot;
};

export const convertPeriodSettingToInput = (period: PeriodSetting): PeriodSettingInput => ({
  defaultValue: period.defaultValue,
  periodType: period.periodType,
  timeslots: period.timeslots.map(convertTimeslotToInput),
});

const DefaultWeekendSettings: PeriodSettingInput = {
  periodType: PeriodTypeInput.Weekends,
  timeslots: [],
  defaultValue: 0,
};

const DefaultWeekdaySettings: PeriodSettingInput = {
  periodType: PeriodTypeInput.Weekdays,
  timeslots: [],
  defaultValue: 0,
};

export const scheduleToScheduleInput = (startSchedule?: Schedule): ScheduleInput => {
  if (!startSchedule) {
    return {
      tag: "",
      periodSettings: [DefaultWeekdaySettings, DefaultWeekendSettings],
    };
  }

  const periodSettings = startSchedule.periods.map(convertPeriodSettingToInput);
  return {
    tag: startSchedule.tag,
    periodSettings,
  };
};

export const scheduleInputToSchedule = ({ tag, periodSettings }: ScheduleInput): Schedule => {
  return {
    tag,
    periods: periodSettings.map((setting) => ({
      defaultValue: setting.defaultValue,
      periodType:
        setting.periodType === PeriodTypeInput.Weekdays
          ? PeriodTypeInput.Weekdays
          : PeriodTypeInput.Weekends,
      timeslots: setting.timeslots,
    })),
  };
};

export const convertScheduleInputToGraphQLFormat = (input: ScheduleInput) => {
  const convertedPeriodsSettings = input.periodSettings.map((periodSetting) => {
    const convertedTimeslots = periodSetting.timeslots.map((timeslot) => ({
      ...timeslot,
      endTime: timeslot.endTime.toGraphQL(),
      startTime: timeslot.startTime.toGraphQL(),
    }));
    return { ...periodSetting, timeslots: convertedTimeslots };
  });
  return { ...input, periodSettings: convertedPeriodsSettings };
};
