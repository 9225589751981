import React, { ReactNode, useEffect, useRef, useState } from "react";
import Tooltip from "thermo/tooltip/Tooltip";

interface Props {
  className?: string;
  maxLines: number;
  dataTestId?: string;
  children: string | JSX.Element | ReactNode;
  style?: React.CSSProperties;
}

const ParagraphLineClamp: React.FC<Props> = ({
  className,
  maxLines: maxLinesProp,
  dataTestId = "",
  children,
  style = {},
}) => {
  const maxLines = maxLinesProp < 1 ? 1 : maxLinesProp;
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    if (paragraphRef.current)
      setIsClamped(paragraphRef.current?.scrollHeight !== paragraphRef.current?.clientHeight);
  }, [paragraphRef, children]);

  return (
    <Tooltip label={children} disableTooltip={!isClamped}>
      <div className={`relative ${className}`}>
        <div
          data-testid={dataTestId}
          className="paragraph-line-clamp"
          style={{ lineClamp: maxLines, WebkitLineClamp: maxLines, ...style }}
          ref={paragraphRef}
        >
          {children}
        </div>
      </div>
    </Tooltip>
  );
};

export default ParagraphLineClamp;
