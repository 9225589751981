import { MagnifyingGlass } from "@phosphor-icons/react";
import axios from "axios";
import { SelectOption } from "model/datatypes";
import React, { useRef, useState, useCallback, useEffect, useLayoutEffect } from "react";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import { useMaxHeightTransitionAutoAnimate } from "utils/hooks/useMaxHeightTransitionAutoAnimate";
import highlightMatchingSubString from "utils/jsUtils/highlightMatchingSubString";
import { getScrollToPosition } from "../../../utils/getScrollToPosition";
import { GeoCodingResponse } from "../types";

const MAX_SEARCH_LENGTH = 256;
const MAPBOX_TOKEN = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN;

interface Props {
  placeholder?: string;
  onSelectItem?: (selected: SelectOption) => void;
}

const LocationSearch: React.FC<Props> = ({ placeholder = "", onSelectItem = () => {} }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [keyboardController, setKeyboardController] = useState(true);
  const [focusedIndex, setFocusedIndex] = useState(0);

  const [geoResults, setGeoResults] = useState<SelectOption[] | undefined>();

  useEffect(() => {
    if (searchTerm.length < 3 || searchTerm.length >= MAX_SEARCH_LENGTH) return;

    const fetchData = async () => {
      let querySearchTerm = searchTerm.replace(";", ""); // querying using the semicolon character is not allowed by API
      querySearchTerm = encodeURI(querySearchTerm);
      const geocodingURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${querySearchTerm}.json?access_token=${MAPBOX_TOKEN}`;

      const response = await axios.get(geocodingURL);
      return response;
    };

    fetchData().then((response) => {
      if (response.status !== 200) return undefined;

      let options: SelectOption[] = [];

      (response.data as GeoCodingResponse).features?.forEach((feature) => {
        const option: SelectOption = {
          id: feature.id,
          display: feature.place_name,
          value: feature.center,
        };

        options.push(option);
      });

      return setGeoResults(options);
    });
  }, [searchTerm]);

  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { open, setOpen, isOverflown } = useMaxHeightTransitionAutoAnimate(
    "0px",
    "260px",
    100,
    false,
    listRef
  );

  const closeDropdown = useCallback(() => {
    setOpen(false);
    setFocusedIndex(0);
  }, [setOpen, setFocusedIndex]);

  useClickOutsideEffect(ref, closeDropdown);

  const handleItemClick = (item: SelectOption) => {
    onSelectItem(item);
    setOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const ArrowUp = !isOverflown ? "ArrowUp" : "ArrowDown";
    const ArrowDown = ArrowUp === "ArrowUp" ? "ArrowDown" : "ArrowUp";
    setKeyboardController(true);
    if (geoResults)
      switch (e.code) {
        case ArrowDown:
          e.preventDefault();
          setFocusedIndex((index) => (index >= geoResults.length - 1 ? 0 : index + 1));
          break;
        case ArrowUp:
          e.preventDefault();
          setFocusedIndex((index) => (index <= 0 ? geoResults.length - 1 : index - 1));
          break;
        case "Enter":
          if (geoResults[focusedIndex]) handleItemClick(geoResults[focusedIndex]);
          setFocusedIndex((index) => (index > 0 ? index - 1 : index));
          (e.target as HTMLInputElement).blur();
          break;
        case "Escape":
        case "Tab":
          if (open) e.stopPropagation();
          setFocusedIndex(0);
          setOpen(false);
          (e.target as HTMLInputElement).blur();
          break;
        default:
          break;
      }
  };

  // Auto-scrolls listRef on keyboard input to keep focused element visible
  useLayoutEffect(() => {
    const el = document.querySelectorAll(
      '[data-testid="focused-itemElement"]'
    )[0] as HTMLDivElement;

    if (!listRef.current || !el) return;
    const containerScrollPos = getScrollToPosition({ child: el, container: listRef.current });

    if (containerScrollPos)
      listRef.current.scrollTo({ top: containerScrollPos, behavior: "smooth" });
  }, [focusedIndex]);

  return (
    <div className="relative w-full text-sm" ref={ref}>
      <div className="flex w-full overflow-visible rounded border bg-white px-1">
        <div className="flex items-center py-1">
          <MagnifyingGlass weight="bold" />
        </div>
        <input
          data-testid="itemList"
          ref={inputRef}
          placeholder={placeholder}
          className="flex-grow truncate p-1 focus:outline-none"
          onFocus={(e) => {
            setSearchTerm(placeholder);
            setOpen(true);
            e.target.select();
          }}
          onBlur={() => setSearchTerm("")}
          onClick={() => setOpen(true)}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {open && (
          <div
            style={{
              minHeight: "0px",
            }}
            onMouseMove={() => setKeyboardController(false)}
            onMouseLeave={() => setKeyboardController(true)}
            ref={listRef}
            className={`absolute left-0 z-50 mt-8 flex w-full min-w-min overflow-y-scroll rounded border border-gray-200 bg-white text-sm shadow
              ${
                isOverflown
                  ? " -top-12 -translate-y-full transform flex-col-reverse"
                  : " -bottom-1 translate-y-full transform flex-col"
              }
            `}
          >
            {geoResults?.map((item, index) => (
              <div
                data-testid={
                  index === focusedIndex && keyboardController
                    ? "focused-itemElement"
                    : "itemElement"
                }
                key={item.id}
                className={`flex w-full cursor-pointer border-b border-gray-100 px-4 py-1 text-trueGray-700 transition-colors
                              ${
                                index === focusedIndex &&
                                keyboardController &&
                                " bg-indigo-200"
                              }
                              ${
                                keyboardController
                                  ? "duration-150"
                                  : "duration-75 hover:bg-indigo-100"
                              }
                              `}
                onClick={() => {
                  handleItemClick(item);
                }}
                onMouseMove={() => setFocusedIndex(index)}
              >
                {highlightMatchingSubString(item.display, searchTerm)}
              </div>
            ))}
            {geoResults && geoResults.length === 0 && searchTerm.length >= 3 && (
              <div className="w-full py-2 text-center text-gray-600">No matches found</div>
            )}
            {!geoResults && searchTerm.length < 3 && (
              <div className="w-full py-2 text-center text-gray-600">
                Input at least 3 characters to search
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSearch;
