import { Upload } from "@phosphor-icons/react";
import Dropzone from "Components/Basic/upload/Dropzone";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import getUUID from "utils/jsUtils/getUUID";
import NewFile from "./NewFile";
import { UploadFile } from "./types";

interface FileUploaderProps {
  projectID?: string;
  modelID?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({ projectID, modelID }) => {
  const [newFiles, setNewFiles] = useState<Map<string, UploadFile>>(new Map());
  return (
    <>
      <AnimatePresence>
        {newFiles.size > 0 && (
          <NewFile
            newFiles={newFiles}
            setNewFiles={setNewFiles}
            projectID={projectID}
            modelID={modelID}
          />
        )}
      </AnimatePresence>
      <Dropzone
        allowedTypes="all"
        className=""
        onFilesAdded={(files) => {
          const fileMap = new Map<string, UploadFile>();
          files.forEach((file) => {
            const fileID = getUUID();
            const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
            fileMap.set(fileID, {
              id: fileID,
              file,
              progress: null,
              name: file.name,
              type: extension,
              uploadFailed: false,
            });
          });
          setNewFiles(fileMap);
        }}
        multiFile
      >
        {(dropHovered, manualOpen) => (
          <div
            className={`flex h-32 my-4 cursor-pointer flex-col items-center justify-center rounded-lg border-4 border-dashed border-gray-400 ${
              dropHovered ? "bg-green-200 shadow-lg" : ""
            }`}
            onClick={() => manualOpen()}
          >
            <div className="mb-2 text-gray-700">
              <Upload size={35} weight="bold" />
            </div>
            <div className="font-medium text-gray-700">Drop files or click here to upload</div>
          </div>
        )}
      </Dropzone>
    </>
  );
};

export default FileUploader;
