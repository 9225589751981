import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Remote_RepositoriesQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

const GET_REMOTE_REPOSITORIES = graphql(`
  query GET_REMOTE_REPOSITORIES($first: Int!, $after: String) {
    remoteRepositories(first: $first, after: $after) @connection(key: "RemoteRepositories") {
      edges {
        node {
          remoteRepositoryProvider
          id
          path
          displayName
          updatedAt
          description
          url
          defaultBranch
          isPublic
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
    }
  }
`);

export const useGetRemoteRepositories = () => {
  const result = useQuery(GET_REMOTE_REPOSITORIES, {
    variables: {
      first: 10,
      after: null,
    },
  });
  return result;
};

export type RemoteRepository = ArrayElement<
  Get_Remote_RepositoriesQuery["remoteRepositories"]["edges"]
>["node"];

export type RemoteRepositories = Get_Remote_RepositoriesQuery["remoteRepositories"]["edges"];
