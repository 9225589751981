import React from "react";

interface Props {
  className?: string;
}

const NumerousLogo = ({ className = "" }: Props) => (
  <svg width="24" height="24" viewBox="0 0 22 24" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19 3H20V20H15V8L8 8V20H3V8V4V3H15H19Z" />
  </svg>
);

export default NumerousLogo;
