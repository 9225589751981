import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const USER_ACTIVATE = graphql(`
  mutation USER_ACTIVATE($activationToken: String!) {
    userActivate(activationToken: $activationToken) {
      ... on UserActivated {
        __typename
        activationToken
      }
      ... on UserActivationTokenInvalid {
        __typename
        activationToken
      }
    }
  }
`);

export const useUserActivate = () => {
  const mutation = useMutation(USER_ACTIVATE);

  return mutation;
};
