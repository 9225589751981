import { CircleWavyWarning, GitBranch } from "@phosphor-icons/react";
import LoadingOverlay from "Components/Basic/LoadingOverlay";
import { TopBarWrapper } from "Components/TopBar/TopBarWrapper";
import { useGetGitlabStatus } from "api/integrations/getGitlabStatus";
import { useGetConnectedRepository } from "api/repository/getConnectedRepository";
import { RepositoryFile } from "api/repository/getDirectory";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "thermo/breadcrumbs/Breadcrumbs";
import { Button } from "../../../thermo/button/Button";
import { useNavigateApp } from "../../AppNavigation/useNavigateApp";
import NotConnectedToGitLab from "../NotConnectedToGitLab";
import { CommitCard } from "./CommitCard/CommitCard";
import { RepositoryDrawer } from "./Drawer/RepositoryDrawer";
import FileExplorer from "./FileExplorer/FileExplorer";
import FileViewer from "./FileViewer/FileViewer";
import RepositoryBarOptions from "./RepositoryBarOptions";

export const RepositoryPage = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<RepositoryFile | undefined>();
  const { repositoryID } = useParams<{ repositoryID: string }>();
  const navigate = useNavigateApp();

  const { data: gitlabStatusData, loading } = useGetGitlabStatus();
  const isConnectedToGitlab =
    gitlabStatusData?.me.gitlabIntegration?.__typename === "GitLabUser";

  const {
    data: repositoryData,
    loading: loadingConnectedRepository,
    error,
  } = useGetConnectedRepository(repositoryID || "");
  const repository =
    repositoryData?.connectedRepository.__typename === "ConnectedRepository"
      ? repositoryData.connectedRepository
      : undefined;

  if (loading || loadingConnectedRepository) return <LoadingOverlay />;
  if (!isConnectedToGitlab) return <NotConnectedToGitLab />;
  if (error)
    return (
      <div
        data-testid="pageNotFound"
        className="flex h-screen w-full items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <CircleWavyWarning size={40} className="text-red-800" />
          <h1 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Repository not available
          </h1>
          <p className="mt-4 text-base text-gray-500">
            This happens when a repository does not exist or you do not have access to view it.
          </p>
          <div className="mt-6">
            <Button
              size="md"
              onClick={() => {
                navigate("/repositories");
              }}
            >
              Back to repository overview
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="bg-white h-full">
      <TopBarWrapper
        pageTitle={
          <Breadcrumbs>
            <Breadcrumbs.Item link="/repositories">
              <GitBranch size={16} weight="fill" />
            </Breadcrumbs.Item>
            <Breadcrumbs.Item label="Repository">{repository?.path}</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      >
        <RepositoryBarOptions
          repository={repository}
          showDrawer={showDrawer}
          toggleDrawer={() => setShowDrawer((prev) => !prev)}
        />
      </TopBarWrapper>
      <div className="relative flex justify-between">
        <div className="relative grid lg:grid-cols-2 w-full">
          <div className="flex flex-col w-full">
            <CommitCard />
            <FileExplorer setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
          </div>

          <div>
            <FileViewer file={selectedFile} />
          </div>
        </div>
        <RepositoryDrawer
          isOpen={showDrawer}
          setIsOpen={() => setShowDrawer((prev) => !prev)}
        />
      </div>
    </div>
  );
};
