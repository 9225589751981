import { FileArrowDown } from "@phosphor-icons/react";
import TagSelector from "Components/Basic/TagSelector";
import useGetDataSetTimeSeries from "api/dataSet/getDataSetTimeSeries";
import { DataSetPageNode } from "api/dataSet/getPaginatedDataSets";
import { useState } from "react";
import BaseModal from "thermo/Modal/BaseModal";
import { Button } from "thermo/button/Button";
import Text from "thermo/typography/Typography";
import LoadingIcon from "../Basic/LoadingIcon/LoadingIcon";
import { Graph } from "./Graph";
import { convertDataSetToCSV, saveAsCSV } from "./utils/saveDataSetAsCSV";

interface Props {
  dataSet: DataSetPageNode;
  selectedTag?: string;
  onFinish: () => void;
}

export const DataSetViewer: React.FC<Props> = ({ dataSet, selectedTag, onFinish }) => {
  const [selectedTags, setSelectedTags] = useState<string[]>(selectedTag ? [selectedTag] : []);

  const { data, loading, error } = useGetDataSetTimeSeries({
    dataSetId: dataSet.id,
    projectId: dataSet.projectId,
    tags: selectedTags,
  });

  if (error) console.error(error);

  const fullDataSet =
    data &&
    (data.dataSet.__typename === "ScheduledDataSet" ||
      data.dataSet.__typename === "StaticDataSet") &&
    data.dataSet;

  return (
    <BaseModal open onClose={onFinish} className="relative min-h-64 w-11/12">
      <div className="flex justify-between mb-3">
        <div className="flex gap-2 items-center">
          <Text.H4 className="font-medium">View data</Text.H4>
          {loading && <LoadingIcon />}
        </div>
        <Button
          size="sm"
          icon={<FileArrowDown size={12} />}
          borderless
          onClick={() => {
            if (!fullDataSet) return;

            const csvData = convertDataSetToCSV(fullDataSet);
            saveAsCSV(csvData, fullDataSet.name);
          }}
          disabled={!selectedTags.length}
        >
          Save to <span className="capitalize">CSV</span>
        </Button>
      </div>
      <div className="flex w-full flex-grow flex-col overflow-hidden">
        <div className="w-full">
          <TagSelector
            inputPlaceholder="Select tags to display in graph"
            tags={dataSet.tags}
            selectedTagIDs={selectedTags}
            selectTag={(tagID) => {
              setSelectedTags((prev) => [...prev, tagID]);
            }}
            removeTag={(tagID) => {
              setSelectedTags((prev) => prev.filter((tag) => tag !== tagID));
            }}
            showInlineTags
          />
        </div>
        <div className="mt-4 flex h-full w-full items-center rounded overflow-hidden">
          <Graph
            fields={selectedTags}
            data={(fullDataSet && fullDataSet?.data) || []}
            t={(fullDataSet && fullDataSet?.index) || []}
          />
        </div>
      </div>
    </BaseModal>
  );
};
