function isObject(object: any) {
  return object != null && typeof object === "object";
}

export const shallowEqual = (
  object1: { [key: string]: any },
  object2: { [key: string]: any }
) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (
  object1: { [key: string]: any },
  object2: { [key: string]: any }
) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
};

/**
 * Performs a shallow equal comparison of two arrays.
 * @param {any[]} arr1 - First array to compare.
 * @param {any[]} arr2 - Second array to compare.
 * @returns True if the arrays are equal, otherwise returns false.
 */
export const shallowEqualArrays = (arr1: any[], arr2: any[]) =>
  arr1.every((el) => arr2.includes(el)) && arr2.every((el) => arr1.includes(el));

/**
 * Performs a shallow equal comparison of two arrays. Also checks if the array elements are in the same order.
 * @param {any[]} arr1 - First array to compare.
 * @param {any[]} arr2 - Second array to compare.
 * @returns True if the arrays are equal, otherwise returns false.
 */
export const shallowEqualArraysInOrder = (arr1: any[], arr2: any[]): boolean =>
  arr1.length === arr2.length && arr1.every((el, index) => el === arr2[index]);

/**
 * Performs a deep equal comparison of two arrays.
 * @param {any[]} arr1 - First array to compare.
 * @param {any[]} arr2 - Second array to compare.
 * @returns True if the arrays are equal, otherwise returns false.
 */
export const deepEqualArrays = (arr1: any[], arr2: any[]) =>
  arr1.every((el1) => arr2.some((el2) => deepEqual(el1, el2))) &&
  arr2.every((el2) => arr1.some((el1) => deepEqual(el1, el2)));
