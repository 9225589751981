import { Check, Upload, X } from "@phosphor-icons/react";
import { Button } from "Components/Basic/Buttons";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import Modal from "Components/Basic/Modal";
import ProgressBar from "Components/Basic/ProgressBar";
import Dropzone from "Components/Basic/upload/Dropzone";
import { saveFileInFs } from "api/firestore/firestoreAPI";
import { useGetOrganization } from "api/organisation/getOrganization";
import { useFirebase, useIdToken } from "api/useFirebase";
import app from "firebase/compat/app";
import { uploadFile } from "grpc/api/grpcUtilAPI";
import { FileQuery, SimFile, FileRef } from "model/datatypes";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useGlobalState } from "store";
import Text from "thermo/typography/Typography";
import { v4 as uuid } from "uuid";

interface Props {
  fileQuery: FileQuery;
  onFinish: (uploadedFile?: FileRef) => void;
}

type UploadFile = {
  id: string;
  file: File;
  name: string;
  type: string;
};

const FileParameterUploader: React.FC<Props> = ({ fileQuery, onFinish }) => {
  const { grpcURL } = useGlobalState();
  const activeOrganization = useGetOrganization().data?.organization;
  const { projectID } = useParams<{ projectID: string }>();
  const idToken = useIdToken();
  const fb = useFirebase();

  const [newFile, setNewFile] = useState<UploadFile | null>(null);
  const [uploadedFileRef, setUploadedFileRef] = useState<null | FileRef>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const [uploadErrorOcurred, setUploadErrorOcurred] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);
  const uploadTask = useRef<app.storage.UploadTask | null>(null);

  const saveFile = async (nf: UploadFile) => {
    if (!projectID || !idToken || !activeOrganization) {
      toast.error(
        "There was an error uploading the file - please report this bug to the numerous team"
      );
      return;
    }

    setProgress(0);
    setUploading(true);

    const allTags = [...fileQuery.tags, `project_${projectID}`];
    const type = nf.type.toLowerCase();

    //UPLOAD To buckett with metadata:
    let path = `${activeOrganization.id}/simulation_files/project/${projectID}`;

    if (fileQuery.modelRef?.id) path = `${path}/system/${fileQuery.modelRef.id}`;

    path = `${path}/${nf.id}`;
    const customMetadata: { [key: string]: any } = { name: nf.name, tags: allTags.toString() };
    customMetadata.projectID = projectID;
    if (fileQuery.modelRef?.id) customMetadata.modelID = fileQuery.modelRef.id;

    uploadFile(grpcURL, idToken, activeOrganization.slug, path, nf.file)
      .then(async () => {
        const fs = fb.firestore();
        const uploadedSimFile: SimFile = {
          id: nf.id,
          path,
          name: nf.name,
          tags: allTags,
          type,
          projectID,
          organizationId: activeOrganization.id,
        };
        if (fileQuery.modelRef?.id) uploadedSimFile.modelID = fileQuery.modelRef.id;
        // TODO Return after answer in notion
        await saveFileInFs(fs, uploadedSimFile, allTags, activeOrganization.id);
        setProgress(100);
        setUploadedFileRef({
          id: uploadedSimFile.id,
          name: uploadedSimFile.name,
          path: uploadedSimFile.path,
        });
        setUploading(false);
      })
      .catch((err) => {
        setProgress(null);
        setUploading(false);
        setUploadErrorOcurred(true);
        toast.error(err.message);
        console.error(err);
      });
  };

  const renderUploadBtn = (dropHovered: boolean, manualOpen: () => void) => (
    <div
      className={`flex h-32 cursor-pointer flex-col items-center justify-center rounded-lg border-4 border-dashed border-gray-400 ${
        dropHovered ? "bg-green-200 shadow-lg" : ""
      }`}
      onClick={() => manualOpen()}
    >
      <div className="h-12 w-12 text-gray-700">
        <Upload />
      </div>
      <div className="font-medium text-gray-700">Drop file or click to upload</div>
      {fileQuery.type && (
        <div className="text-sm italic">File must have extension: {fileQuery.type}</div>
      )}
    </div>
  );

  const renderAddingFile = () => {
    if (!newFile) return undefined;

    return (
      <div className="flex h-32 flex-col">
        <div className="flex w-full flex-grow flex-col items-center justify-center">
          <div className="h-16 w-64 text-center">
            <div className="flex justify-center items-center">
              <div>
                <ProgressBar progress={progress || 0} />
              </div>
              <div>
                {progress === 100 && <Check />}
                {uploading && <LoadingIcon className="text-green-400" />}
                {uploadErrorOcurred && <X weight="bold" className="text-red-500" />}
              </div>
            </div>
            {uploading && (
              <Text.Small className="text-success-500">File upload was succesful</Text.Small>
            )}
            {uploadErrorOcurred && (
              <Text.Small className="text-error-500 ">File upload failed</Text.Small>
            )}
          </div>
          <div className="text-sm font-medium">{newFile.name}</div>
        </div>
        {uploadedFileRef && (
          <Button
            buttonType="white"
            onClick={() => onFinish(uploadedFileRef)}
            className="w-full"
          >
            Ok
          </Button>
        )}
        {uploading && (
          <Button
            buttonType="white"
            onClick={() => {
              if (uploadTask.current?.cancel()) {
                setNewFile(null);
                setUploading(false);
                setProgress(null);
              }
            }}
            className={` w-full`}
          >
            Cancel
          </Button>
        )}
      </div>
    );
  };

  return (
    <Modal
      onClose={() => {
        if (!uploading) {
          if (uploadedFileRef) onFinish(uploadedFileRef);
          else onFinish();
        }
      }}
      className="w-1/2 p-2"
      noPadding
    >
      {/* <div className="z-30 bg-white rounded shadow-lg p-4 w-1/2"> */}
      <Dropzone
        allowedTypes={fileQuery.type || "all"}
        className=""
        onFilesAdded={(files) => {
          const file = files[0];
          const fileID = uuid().replace(/-/gi, "_");
          const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
          const tempUploadFile: UploadFile = {
            id: fileID,
            file,
            name: file.name,
            type: extension,
          };
          setNewFile(tempUploadFile);
          saveFile(tempUploadFile);
        }}
        onError={(msg) => {
          toast.error(`${msg}`);
        }}
      >
        {(dropHovered, manualOpen) => {
          if (!newFile) return renderUploadBtn(dropHovered, manualOpen);
          return renderAddingFile();
        }}
      </Dropzone>
      {/* </div> */}
    </Modal>
  );
};

export default FileParameterUploader;
