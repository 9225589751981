import Link from "Components/AppNavigation/Link";
import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { useUserSignUpOrganizationInvitation } from "api/organisation/getUserSignUpOrganizationInvitation";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import NumerousLogo from "resources/NumerousLogo";
import { useGlobalState } from "store";
import Text from "thermo/typography/Typography";
import { FailedInvitationFetchWarning } from "./FailedInvitationFetchWarning";
import { FinishedSignUpScreen } from "./FinishedSignUpScreen";
import { TermsAndConditionsDisclaimer } from "./TermsAndConditionsDisclaimer";
import { UserSignUpForm } from "./UserSignupForm/UserSignUpForm";

export const UserSignUp = () => {
  const { authStatus } = useGlobalState();

  const [userFinishedSignUp, setUserFinishedSignUp] = useState(false);

  const { invitationToken } = useParams<{ invitationToken: string }>();
  // If invitationToken is in url params, we want to query for the email attached to the invitation
  const { data, loading: fetchingInvitation } = useUserSignUpOrganizationInvitation({
    invitationToken,
  });

  const invitationEmail =
    (data?.userSignUpOrganizationInvitation.__typename ===
      "UserSignUpOrganizationInvitation" &&
      data.userSignUpOrganizationInvitation.email) ||
    null;

  const failedToFetchInvitation =
    data?.userSignUpOrganizationInvitation.__typename ===
      "UserSignUpOrganizationInvitationError" && !fetchingInvitation;

  if (authStatus === "pending" || fetchingInvitation) return <FullScreenLoadingOverlay />;
  if (authStatus === "authenticated") return <Navigate to="/" replace />;
  if (userFinishedSignUp) return <FinishedSignUpScreen />;
  return (
    <div
      data-testid="userSignUpPage"
      className="bg-white w-screen h-screen justify-center items-center flex flex-col relative "
    >
      {failedToFetchInvitation && <FailedInvitationFetchWarning />}

      <div className="w-96">
        <div className="mb-6 items-center flex flex-col">
          <NumerousLogo className="mb-6 w-32" />
          <Text.H1 className="text-gray-800 font-semibold">Create an account</Text.H1>
          <Text.Small className="text-gray-700 mt-2">
            Get ready to build and use great engineering tools.
          </Text.Small>
        </div>

        <UserSignUpForm
          invitationEmail={invitationEmail}
          setUserFinishedSignUp={() => setUserFinishedSignUp(true)}
          failedToFetchInvitation={failedToFetchInvitation}
        />

        <div className="mt-3 flex justify-center">
          <Text.Small className="flex gap-1 text-gray-700">
            Already have an account?{" "}
            <Link to="/login" useAsCompletePath>
              <Text.Small className="text-primary-600"> Sign in</Text.Small>
            </Link>
          </Text.Small>
        </div>

        <TermsAndConditionsDisclaimer />
      </div>
    </div>
  );
};
