import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UNPIN_SCENARIO = graphql(`
  mutation UNPIN_SCENARIO($projectId: ID!, $scenarioId: ID!) {
    userUnpinScenario(projectId: $projectId, scenarioId: $scenarioId) {
      ... on User {
        ...PinnedItems
      }
    }
  }
`);

export const useUnpinScenario = () => {
  const mutation = useMutation(UNPIN_SCENARIO);

  return mutation;
};
