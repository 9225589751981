import { ArrowLeft, Backspace, BoundingBox, Pen, Plus } from "@phosphor-icons/react";
import React from "react";
import classNames from "utils/jsUtils/className";
import AnimateLoadingButtonInlay from "./AnimateLoadingButtonInlay";

type Props = {
  icon?: "plus" | "arrow-left" | "bounding-box" | "trash" | "edit";
  buttonType: "primary" | "secondary" | "tertiary" | "white" | "small" | "warning";
  disabled?: boolean;
  backgroundColor?: string;
  textSize?:
    | `text-${"xxs" | "xs" | "base" | "sm" | "lg" | "xl"}`
    | `text-${2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}xl`;
  loading?: boolean;
};

type ButtonProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  className,
  icon,
  children,
  disabled,
  buttonType,
  backgroundColor,
  textSize = "text-sm",
  loading,
  ...htmlProps
}) => {
  const renderIcon = () => {
    switch (icon) {
      case "arrow-left":
        return <ArrowLeft className="-ml-1 mr-2 h-4 w-4" direction="left" />;
      case "plus":
        return <Plus className="-ml-1 mr-2 h-4 w-4" />;
      case "bounding-box":
        return <BoundingBox className="-ml-1 mr-2 h-4 w-4" />;
      case "trash":
        return <Backspace className="-ml-1 mr-2 h-4 w-4 text-red-500" />;
      case "edit":
        return <Pen weight="bold" className="-ml-1 mr-2 h-4 w-4" />;
      default:
        return null;
    }
  };

  return (
    <button
      disabled={disabled}
      {...htmlProps}
      className={classNames(
        className,
        `inline-flex items-center rounded-md px-3 py-1 font-medium leading-5 focus:outline-none`,
        textSize,
        buttonType === "primary" &&
          `shadow-md ${
            backgroundColor || "bg-indigo-600"
          } border border-indigo-500 text-trueGray-50 hover:border-indigo-800 hover:bg-indigo-800`,
        buttonType === "warning" &&
          `shadow-md ${
            backgroundColor || "bg-red-400"
          } border border-red-400 text-trueGray-50 hover:border-red-600 hover:bg-red-500`,
        buttonType === "secondary" &&
          `border border-indigo-600 bg-white text-indigo-600 hover:bg-indigo-100 focus:bg-indigo-200`,
        buttonType === "tertiary" && `text-indigo-600 hover:text-indigo-800`,
        buttonType === "white" &&
          `${
            backgroundColor || "bg-white"
          } border border-gray-200 text-trueGray-600 shadow-sm hover:bg-gray-50`,
        buttonType === "small" &&
          `${backgroundColor || "bg-white"} ${
            className ? "" : "text-trueGray-600"
          } border border-transparent hover:border-gray-200 hover:bg-gray-50 hover:shadow-sm`,
        disabled && "cursor-not-allowed opacity-40"
      )}
    >
      {icon && renderIcon()}
      <div className="flex flex-grow  items-center justify-center capitalize">{children}</div>
      <AnimateLoadingButtonInlay
        showLoading={loading || false}
        iconClassName={`ml-4 ${buttonType === "primary" ? "text-white" : "text-gray-500"}`}
      />
    </button>
  );
};

type TabButtonProps = {
  active: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const TabButton: React.FC<TabButtonProps> = ({ children, active, ...htmlProps }) => (
  <button
    {...htmlProps}
    type="button"
    className={classNames(
      active
        ? "border-indigo-500 text-indigo-600"
        : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700",
      "group mr-4 inline-flex items-center border-b-2 py-1.5 px-1 text-sm font-medium"
    )}
  >
    {children}
  </button>
);
