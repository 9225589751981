import React from "react";
import Text from "thermo/typography/Typography";
import { RecentProjectItem } from "./RecentItemVariations/RecentProjectItem";
import { RecentScenarioItem } from "./RecentItemVariations/RecentScenarioItem";
import { RecentToolItem } from "./RecentItemVariations/RecentToolItem";
import { useRecentlyViewedQueue } from "./RecentlyViewedQueue";

type Props = {
  closePopover: () => void;
};

export const RecentlyViewedOverview = ({ closePopover }: Props) => {
  const { recentItems } = useRecentlyViewedQueue();
  return (
    <div className="px-2 py-3">
      <Text.Base className="text-gray-500 mb-2 px-2">Last viewed</Text.Base>

      <div className="flex gap-0.5 flex-col">
        {recentItems.length > 0 ? (
          recentItems.map((item) => {
            return (
              <div key={item.id}>
                {item.type === "project" && (
                  <RecentProjectItem
                    type={item.type}
                    close={() => closePopover()}
                    projectId={item.id}
                  />
                )}
                {item.type === "scenario" && (
                  <RecentScenarioItem
                    type={item.type}
                    close={() => closePopover()}
                    scenarioId={item.id}
                    projectId={item.projectId}
                  />
                )}
                {item.type === "tool" && (
                  <RecentToolItem
                    type={item.type}
                    close={() => closePopover()}
                    toolId={item.id}
                  />
                )}
              </div>
            );
          })
        ) : (
          <Text.Small data-testid="recentlyViewedNoViewHistoryInfo" className="ml-2">
            No view history
          </Text.Small>
        )}
      </div>
    </div>
  );
};
