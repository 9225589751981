import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_All_TeamsQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

const GET_ALL_TEAMS = graphql(`
  query GET_ALL_TEAMS {
    __typename
    teams {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          organizationId
          owner {
            ... on User {
              __typename
              id
              fullName
            }
            ... on UserNotFound {
              __typename
            }
          }
          members {
            __typename
            id
            fullName
          }
        }
      }
    }
  }
`);

export type Team = Extract<
  ArrayElement<
    Extract<Get_All_TeamsQuery["teams"], { __typename: "TeamConnection" }>["edges"]
  >["node"],
  { __typename: "Team" }
>;

export type TeamMember = ArrayElement<Team["members"]>;

export const useGetAllTeams = () => {
  const result = useQuery(GET_ALL_TEAMS);
  return result;
};
