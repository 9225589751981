import { FlagProvider } from "@unleash/proxy-client-react";
import AppWrapper from "AppWrapper";
import { createRoot } from "react-dom/client";
import { mockUnleashClient, unleashClient } from "./lib/unleash";
import "./styles/tailwind.css";

const isLocalHost = window.location.port === "3000";
if (!isLocalHost) {
  unleashClient.start();
}

const unleashClientToUse = !isLocalHost ? unleashClient : mockUnleashClient;

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <FlagProvider unleashClient={unleashClientToUse} startClient={false}>
    <AppWrapper />
  </FlagProvider>
);
