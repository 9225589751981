import { Button } from "Components/Basic/Buttons";
import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { InputExpandable } from "Components/Basic/Input";
import { ParameterEditValue } from "Components/Scenario/ModelComponents/Parameters/ParameterEditValue";
import {
  ComponentParameter,
  ComponentParamType,
  FileQuery,
  ComponentTypeReference,
} from "model/datatypes";
import React from "react";
import {
  ReferenceSelecter,
  ParamEditMode,
  FileQuerySelecter,
  SelecterParamSetup,
} from "./ParamSystemInputs";
import ParamTableColumnEdit from "./ParamTableColumnEdit";

const parameterTypes: ComponentParameter["type"][] = [
  "number",
  "string",
  "boolean",
  "selecter",
  "month",
  "config",
  "file",
  "table",
  "time_value",
  "json",
];

const parameterDefaultValues = new Map<
  ComponentParamType["type"],
  Partial<ComponentParamType>
>([
  ["boolean", { value: false }],
  ["config", { value: "", tag: "" }],
  ["currency", { value: { code: "EUR", name: "Euro" } }],
  ["file", { fileQuery: { tags: [] }, value: null }],
  ["json", { value: {} }],
  ["number", { value: 0 }],
  ["reference", { value: "", displayMode: "hidden" }],
  ["string", { value: "" }],
  ["selecter", { selecterOptions: [], tag: "", value: null }],
  ["time_value", { value: { value: 3600, unit: "hours" } }],
  ["table", { value: {}, tableColumns: [] }],
  ["map", { value: null }],
  ["month", { value: null }],
]);

export const EditableParameterRow: React.FC<{
  systemRef: FileQuery["modelRef"];
  param: ComponentParamType;
  onUpdateParam: (newParam: ComponentParamType) => void;
  onDeleteParam: () => void;
  allowedComponentRefs?: ComponentTypeReference[];
  includeMapType?: boolean;
  includeCurrencyType?: boolean;
}> = ({
  param,
  onUpdateParam,
  onDeleteParam,
  allowedComponentRefs,
  includeMapType,
  includeCurrencyType,
  systemRef,
}) => {
  const types = [...parameterTypes];
  if (allowedComponentRefs) types.push("reference");
  if (includeMapType) types.push("map");
  if (includeCurrencyType) types.push("currency");

  return (
    <div className="flex w-full items-center border-t border-gray-200 py-2 px-2 text-sm hover:bg-gray-200">
      {/* ID */}
      <div data-testid="paramID" className="w-1/8 pr-4">
        <InputExpandable
          value={param.id}
          readOnly={param.fixedID}
          className={`px-2 py-1 focus:outline-none ${param.fixedID ? "bg-gray-100" : ""}`}
          onChange={(val) => {
            if (!param.fixedID) onUpdateParam({ ...param, id: val });
          }}
        />
      </div>

      {/* Display name */}
      <div data-testid="paramName" className="w-1/8 pr-4">
        <InputExpandable
          value={param.displayName}
          className="w-full"
          onChange={(val) => {
            onUpdateParam({ ...param, displayName: val });
          }}
        />
      </div>

      {/* Tooltip */}
      <div data-testid="paramTooltip" className="w-1/8 pr-4">
        <InputExpandable
          value={param.tooltip || ""}
          className="w-full"
          onChange={(val) => {
            onUpdateParam({ ...param, tooltip: val });
          }}
        />
      </div>

      {/* Type */}
      <div data-testid="paramType" className="w-1/8 pr-4">
        <Dropdown
          className="bg-white text-sm"
          closeOnDisappear
          options={types.map((type) => ({
            id: type,
            display: type,
            value: type,
          }))}
          selectedID={param.type}
          onSelect={(option) => {
            const previousParamType = param.type;
            const newParamType: ComponentParamType["type"] = option.value;
            let updatedParam: ComponentParamType = {
              ...param,
              type: newParamType,
            };

            const paramTypeChanged = newParamType !== previousParamType;
            if (!paramTypeChanged) return onUpdateParam(updatedParam);

            if (previousParamType === "reference") updatedParam.displayMode = "normal";
            else if (previousParamType === "file") updatedParam.fileQuery = undefined;
            const defaultValues = parameterDefaultValues.get(newParamType);
            updatedParam = { ...updatedParam, ...defaultValues };
            return onUpdateParam(updatedParam);
          }}
        />
      </div>

      {/* Tag / Setup */}
      <div className="w-1/8 pr-4">
        {param.type === "config" && (
          <InputExpandable
            value={param.tag || ""}
            className="w-full"
            onChange={(val) => {
              onUpdateParam({ ...param, tag: val });
            }}
          />
        )}
        {param.type === "file" && (
          <FileQuerySelecter modelRef={systemRef} parameter={param} onUpdate={onUpdateParam} />
        )}
        {param.type === "table" && (
          <ParamTableColumnEdit parameter={param} onUpdate={onUpdateParam} />
        )}
        {param.type === "selecter" && (
          <SelecterParamSetup parameter={param} onUpdate={onUpdateParam} />
        )}
      </div>

      {/* Default value */}
      <div className="w-1/8 pr-4">
        {param.type === "reference" && allowedComponentRefs && (
          <ReferenceSelecter
            allowedParamRefs={allowedComponentRefs}
            parameter={param}
            onUpdate={onUpdateParam}
          />
        )}
        {param.type !== "reference" && (
          <ParameterEditValue parameter={param} paramType={param} onUpdate={onUpdateParam} />
        )}
      </div>

      {/* Access */}
      <div className="w-1/12 pr-4">
        <ParamEditMode parameter={param} onUpdate={onUpdateParam} />
      </div>

      {/* Optional */}
      <div className="w-1/12 pr-4">
        <input
          data-test="paramOptional"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          checked={!!param.optional}
          onChange={(e) => {
            onUpdateParam({ ...param, optional: e.target.checked ? true : undefined });
          }}
        />
      </div>

      {/* Remove parameter */}
      <div className="flex w-1/12 justify-end">
        {!param.fixedID && (
          <Button buttonType="white" onClick={onDeleteParam}>
            Remove
          </Button>
        )}
      </div>
    </div>
  );
};
