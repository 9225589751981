import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { PaginatedProjectsQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const PAGINATED_PROJECTS = graphql(/* GraphQL */ `
  query PaginatedProjects($after: String, $first: Int, $workspaceId: ID) {
    workspace(workspaceId: $workspaceId) {
      ... on Workspace {
        id
        name
        projects(after: $after, first: $first) @connection(key: "PaginatedProjects") {
          ...ProjectFields
        }
      }
      ... on GeneralWorkspace {
        __typename
        projects(after: $after, first: $first) @connection(key: "PaginatedProjects") {
          ...ProjectFields
        }
      }
    }
  }
  fragment ProjectFields on ProjectConnection {
    __typename
    edges {
      __typename
      cursor
      node {
        __typename
        name
        mine
        mostRecentRun
        id
        archived
        isPinned
        scenarioCount
        description
        createdAt
        workspaceId
        owner {
          ... on User {
            id
            __typename
            fullName
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      startCursor
    }
  }
`);

type Props = {
  workspaceId: string | null;
  first: number;
};

export const useGetPaginatedProjects = ({ first, workspaceId }: Props) => {
  const result = useQuery(PAGINATED_PROJECTS, {
    variables: {
      first,
      after: null,
      workspaceId,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  return result;
};

export type WorkspaceProject = ArrayElement<
  PaginatedProjectsQuery["workspace"]["projects"]["edges"]
>["node"];
