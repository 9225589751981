export const toCamelCase = (string: string) => {
  const words = string.split(" ");

  return words.reduce((previous, current) => {
    if (!previous) return current.toLowerCase();

    const [firstLetter, ...rest] = current;
    const currentWord = firstLetter.toUpperCase().concat(rest.join("").toLowerCase());

    return previous.concat(currentWord);
  }, "");
};
