import { Warning } from "@phosphor-icons/react";
import { RepositoryFile, useGetRepositoryDirectory } from "api/repository/getDirectory";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ScrollShadow from "thermo/elevation/ScrollShadow";
import Text from "thermo/typography/Typography";
import ExplorerBreadcrumbs from "./ExplorerBreadcrumbs";
import ExplorerRow from "./ExplorerRow";
import FileExplorerSkeleton from "./FileExplorerSkeleton";

interface Props {
  setSelectedFile: (file: RepositoryFile) => void;
  selectedFile?: RepositoryFile | undefined;
}

const FileExplorer = ({ setSelectedFile, selectedFile }: Props) => {
  const [path, setPath] = useState("");
  const { repositoryID, commitID } = useParams<{
    repositoryID: string;
    commitID: string;
  }>();

  const {
    data: repositoryDirectoryData,
    loading,
    error,
  } = useGetRepositoryDirectory({
    repositoryId: repositoryID || "",
    commitId: commitID || "",
    path,
  });

  useEffect(() => {
    if (selectedFile) return;

    const readMeFile = repositoryDirectoryData?.find(
      (item) =>
        item.__typename === "RepositoryFile" && item.name.toLocaleLowerCase() === "readme.md"
    ) as RepositoryFile | undefined;

    if (readMeFile) setSelectedFile(readMeFile);
  }, [repositoryDirectoryData, setSelectedFile, selectedFile]);

  return (
    <div className="p-3 w-full">
      <Text.H4 className="font-medium mb-2">Repository files</Text.H4>
      {loading && <FileExplorerSkeleton />}
      {!loading && (
        <>
          <div
            className="bg-white border border-gray-200 rounded-t"
            data-testid="fileExplorerContent"
          >
            <ExplorerBreadcrumbs fullPath={path} navigateTo={setPath} />

            {/* Table header */}
            <div
              className="bg-gray-100 grid py-1"
              style={{
                gridTemplateColumns: "8% 36% 36% 20%",
              }}
            >
              <div />
              <Text.Small className="text-gray-500">Name</Text.Small>
              <Text.Small className="text-gray-500">Last commit</Text.Small>
              <Text.Small className="text-gray-500">Last update</Text.Small>
            </div>
          </div>
          {/* Explorer */}
          {repositoryDirectoryData && repositoryDirectoryData.length > 0 && (
            <ScrollShadow>
              <div className="border border-t-0 border-gray-200 rounded-b overflow-hidden max-h-[41vh] overflow-y-auto">
                {repositoryDirectoryData?.map((item) => (
                  <ExplorerRow
                    key={item.name}
                    item={item}
                    selectedFile={selectedFile}
                    onClick={(selectedItem) => {
                      if (selectedItem.__typename === "RepositoryDirectory")
                        setPath(selectedItem.path);
                      else setSelectedFile(selectedItem);
                    }}
                  />
                ))}
              </div>
            </ScrollShadow>
          )}
          {repositoryDirectoryData && repositoryDirectoryData.length < 1 && (
            <div
              className="bg-white border grid py-1 border-t-0 border-gray-200 rounded-b overflow-hidden"
              style={{ gridTemplateColumns: "8% 1fr" }}
              data-testid="fileExplorerEmpty"
            >
              <Text.Base className="col-start-2 italic">No files or folders</Text.Base>
            </div>
          )}
          {error && (
            <div
              className="bg-white border grid py-1 border-t-0 border-gray-200 rounded-b overflow-hidden items-center"
              style={{ gridTemplateColumns: "8% 1fr" }}
              data-testid="fileExplorerError"
            >
              <span className="col-start-2 flex items-center gap-2">
                <Warning className="text-warning-600" />
                <Text.Base className="italic">There was an issue getting the files</Text.Base>
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FileExplorer;
