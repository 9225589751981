import { UsersThree } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { getUserColor } from "Components/Basic/UserCircleColorOptions";
import { useGetAllUsersInOrganization } from "api/userManagement/getAllUsersInOrganisation";
import { useAddEditorToWorkspace } from "api/workspace/addEditorToWorkspace";
import { Workspace } from "api/workspace/getWorkspaces";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import classNames from "utils/jsUtils/className";

interface Props {
  workspace: Workspace;
  closeModal: () => void;
}

export const InviteWorkspaceUsers = ({ workspace, closeModal }: Props) => {
  const navigate = useNavigateApp();

  const {
    data: usersInOrganization,
    loading: loadingOrgUsers,
    fetchMore,
  } = useGetAllUsersInOrganization({ first: 30 });

  useEffect(() => {
    const canFetchMoreUsers = usersInOrganization?.organization.members.pageInfo.hasNextPage;
    const endCursor = usersInOrganization?.organization.members.pageInfo.endCursor;

    if (canFetchMoreUsers && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    fetchMore,
    usersInOrganization?.organization.members.pageInfo.endCursor,
    usersInOrganization?.organization.members.pageInfo.hasNextPage,
  ]);

  const [addEditorToWorkspace] = useAddEditorToWorkspace();

  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const existingWorkspaceMemberIds = new Set(
    workspace.members.map((member) => member.user?.id)
  );
  const inviteableUsers = usersInOrganization?.organization.members.edges
    // Filter away users already in the workspace
    .filter((edge) => !existingWorkspaceMemberIds.has(edge.node.user.id))
    .map(({ node: { user } }) => user)
    .filter((user) => user.fullName.toLowerCase().match(searchTerm.toLowerCase()));

  return (
    <div className="rounded-md">
      <input
        type="text"
        placeholder="Add workspace user.."
        className="rounded-t-md border-none p-2 text-sm focus:ring-0"
        autoFocus
        ref={inputRef}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <div className="h-px bg-gray-100" />
      <div className="flex max-h-52 flex-col overflow-y-auto">
        {loadingOrgUsers && <LoadingIcon className="my-2 w-full text-gray-500" />}

        {!loadingOrgUsers &&
          inviteableUsers &&
          inviteableUsers.map((invitableUser) => (
            <button
              className="flex cursor-pointer items-center py-1.5 pr-2 text-sm hover:bg-gray-100"
              onClick={() => {
                addEditorToWorkspace({
                  variables: { userId: invitableUser.id, workspaceId: workspace.id },
                }).catch((err) => {
                  console.error(err);
                  toast.error("The user could not be added to the workspace");
                });
                setSearchTerm("");
                inputRef.current?.focus();
              }}
              key={invitableUser.id}
              data-testid="inviteableWorkspaceUserRow"
            >
              <div
                className={classNames(
                  `${getUserColor(invitableUser).backgroundColor}`,
                  `${getUserColor(invitableUser).textColor}`,
                  "mx-2 mr-2 flex h-6 w-6 items-center justify-center rounded-full text-sm font-medium uppercase"
                )}
              >
                {invitableUser.firstName && invitableUser.lastName
                  ? invitableUser.firstName.slice(0, 1) + invitableUser.lastName.slice(0, 1)
                  : "N/A"}
              </div>
              <div>{invitableUser.fullName}</div>
            </button>
          ))}
        {inviteableUsers && inviteableUsers.length > 0 && <div className="h-px bg-gray-100" />}
        <button
          onClick={() => {
            closeModal();
            navigate("/administration");
          }}
          className="flex items-center rounded-b-md border-t border-gray-200 px-3 py-2 text-sm hover:bg-gray-100"
        >
          <UsersThree className="mr-2" size={16} />
          <div>Invite to numerous</div>
        </button>
      </div>
    </div>
  );
};
