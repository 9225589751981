import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const USER_RESET_PASSWORD = graphql(`
  mutation USER_RESET_PASSWORD($newPassword: String!, $resetToken: String!) {
    userResetPassword(newPassword: $newPassword, resetToken: $resetToken) {
      ... on UserPasswordResetExpired {
        __typename
        resetToken
      }
      ... on UserPasswordResetInvalid {
        __typename
        resetToken
      }
      ... on UserPasswordResetCompleted {
        __typename
        email
      }
    }
  }
`);

export const useUserResetPassword = () => {
  const mutation = useMutation(USER_RESET_PASSWORD);

  return mutation;
};
