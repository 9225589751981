import { WarningOctagon } from "@phosphor-icons/react";
import React from "react";
import Tooltip from "thermo/tooltip/Tooltip";
import classNames from "utils/jsUtils/className";
import { Button } from "../Buttons";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  content: string | JSX.Element;
  buttonText?: string | JSX.Element;
  buttonOnClick?: () => void;
  disableButton?: boolean;
  hideButton?: boolean;
  tooltip?: string;
}

const ToggleContainerCell: React.FC<Props> = ({
  title,
  content,
  buttonText,
  buttonOnClick = () => {},
  disableButton,
  hideButton,
  tooltip,
  className,
  ...htmlProps
}) => {
  return (
    <div {...htmlProps} className={classNames(className, "border-r border-b p-4")}>
      <div className="flex items-center justify-between">
        <dt className="flex items-center py-1 text-sm font-medium leading-5 text-gray-500">
          {title}
          {tooltip && (
            <Tooltip label={tooltip}>
              <WarningOctagon className="h-4 w-4 ml-2" />
            </Tooltip>
          )}
        </dt>
        {buttonText && !hideButton && (
          <Button
            onClick={buttonOnClick}
            className="text-sm leading-4 text-indigo-700"
            buttonType="small"
            data-testid="changeSystemButton"
            disabled={disableButton}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <dd className="mt-1 w-full text-sm">{content}</dd>
    </div>
  );
};

export default ToggleContainerCell;
