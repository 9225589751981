import { DotsThree } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { UserPendingInvitation } from "api/user/getUserPendingInvitations";
import { isBefore } from "date-fns";
import { useMemo, useState } from "react";
import Text from "thermo/typography/Typography";
import { OrganizationPickerDropdownItem } from "../OrganizationPicker/OrganizationPickerDropdownItem";

interface Props {
  pendingInvitations: UserPendingInvitation[];
}
const OrganizationInvitationsList = ({ pendingInvitations }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigateApp();

  const invitations = useMemo(
    () =>
      [...pendingInvitations]
        .sort((a, b) => (isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1))
        .slice(0, showAll ? undefined : 3),
    [pendingInvitations, showAll]
  );

  const numberOfInvitations = pendingInvitations.length;

  return (
    <div data-testid="pendingInvitationsSection">
      <Text.XSmall className="p-2 text-gray-400 select-none">
        {numberOfInvitations} pending invitation{numberOfInvitations !== 1 ? "s" : ""}
      </Text.XSmall>
      {invitations.map((invitation) => (
        <OrganizationPickerDropdownItem
          key={invitation.organization.id}
          onClick={() =>
            navigate(`${invitation.organization.slug}/invitation/${invitation.id}`, {
              useAsCompletePath: true,
            })
          }
          text={invitation.organization.name}
        />
      ))}
      {numberOfInvitations > 3 && !showAll && (
        <OrganizationPickerDropdownItem
          onClick={() => setShowAll(true)}
          icon={<DotsThree size={14} />}
          text="Show all invitations"
        />
      )}
    </div>
  );
};

export default OrganizationInvitationsList;
