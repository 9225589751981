import { useFlag } from "@unleash/proxy-client-react";
import Text from "thermo/typography/Typography";

export const MaintenanceWarning = () => {
  const backendIsUnderMaintenance = useFlag("backend_is_under_maintenance");

  if (!backendIsUnderMaintenance) return null;
  return (
    <div className="elevation-raised flex gap-1 flex-col border-gray-200 border rounded-[4px] p-3 mb-4">
      <div className="flex items-center">
        <Text.H4>Service update</Text.H4>
        <div className="relative ml-2">
          <div className="w-2 h-2 absolute rounded-full bg-indigo-300 animate-ping" />
          <div className="w-2 h-2 rounded-full bg-indigo-300" />
        </div>
      </div>
      <Text.Small>You may experience loss of service while we perform maintenance.</Text.Small>
    </div>
  );
};
