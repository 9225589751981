import { CaretLeft } from "@phosphor-icons/react";
import Skeleton from "thermo/skeleton/Skeleton";
import Text from "thermo/typography/Typography";

const FileExplorerSkeleton = () => {
  return (
    <>
      <div className="bg-white border border-gray-200 rounded">
        <div
          className="w-full border-b border-gray-200 grid py-1"
          style={{ gridTemplateColumns: "8% 1fr" }}
        >
          <CaretLeft className="justify-self-center text-gray-400" />
          <Skeleton.TextLine typography="Small" width="w-1/4" />
        </div>

        <div
          className="bg-gray-100 border-b border-gray-200 grid py-1"
          style={{
            gridTemplateColumns: "8% 36% 36% 20%",
          }}
        >
          <div />
          <Text.Small className="text-gray-500">Name</Text.Small>
          <Text.Small className="text-gray-500">Last commit</Text.Small>
          <Text.Small className="text-gray-500">Last update</Text.Small>
        </div>
      </div>

      <div className="border border-t-0 border-gray-200 rounded-b overflow-hidden">
        {Array(4)
          .fill("")
          .map((e, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="grid bg-white py-1 select-none"
              style={{
                gridTemplateColumns: "8% 36% 36% 20%",
              }}
            >
              <div className="justify-self-center text-gray-500" />
              <Skeleton.TextLine typography="Small" width="w-3/4" />
              <Skeleton.TextLine typography="Small" width="w-3/4" />
              <Skeleton.TextLine typography="Small" width="w-3/4" />
            </div>
          ))}
      </div>
    </>
  );
};

export default FileExplorerSkeleton;
