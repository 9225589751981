import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Repository_ActivityQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

const GET_REPOSITORY_ACTIVITY = graphql(`
  query GET_REPOSITORY_ACTIVITY($after: DateTime, $first: Int!, $repositoryId: ID!) {
    repositoryActivities(repositoryId: $repositoryId, after: $after, first: $first) {
      edges {
        node {
          ... on RepositoryActivityPushToBranch {
            commitId
            __typename
            activityDate
            userName
            branchName
            message
            url
          }
          ... on RepositoryActivityPushNewBranch {
            userName
            branchName
            url
            __typename
            activityDate
          }
          ... on RepositoryActivityDeleteBranch {
            __typename
            activityDate
            branchName
            userName
            url
          }
          ... on RepositoryActivityPushNewTag {
            __typename
            activityDate
            userName
            tagName
            commitRef
            message
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
    }
  }
`);

type Props = {
  first: number;
  repositoryId: string;
  after?: Date | null;
  skip: boolean;
};

export const useGetRepositoryActivity = ({ first, repositoryId, after, skip }: Props) => {
  const result = useQuery(GET_REPOSITORY_ACTIVITY, {
    variables: { after, repositoryId, first },
    skip,
    fetchPolicy: "network-only",
  });
  return result;
};

export type RepositoryActivity = ArrayElement<
  Get_Repository_ActivityQuery["repositoryActivities"]["edges"]
>["node"];

export type ActivityType = RepositoryActivity["__typename"];
