import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useDeprecatedFirestore } from "api/useFirebase";
import { useEffect, useState } from "react";

/**
 * Hook to get the IDs of all portfolios where the project is an entity.
 * @param projectID The id of the project to use as reference
 * @returns getProjectPortfolioIDs function. Call to return portfolio IDs.
 */
export const useGetProjectPortfolioIDs = (projectID: string) => {
  const activeOrganizationId = useActiveOrganizationId();
  const fs = useDeprecatedFirestore();
  const [portfolioIDs, setPortfolioIDs] = useState<string[]>([]);

  useEffect(() => {
    if (!activeOrganizationId) return;
    fs.collection("organizations")
      .doc(activeOrganizationId)
      .collection("Portfolios")
      .get()
      .then(async (portfolioDocs) => {
        const idPromises = portfolioDocs.docs.map((portfolioDoc) =>
          portfolioDoc.ref
            .collection("entities")
            .limit(1)
            .where("project_id.val", "==", projectID)
            .get()
            .then((entities) => (entities.empty ? null : portfolioDoc.id))
        );
        let ids: string[] = [];
        Promise.allSettled(idPromises).then((resolvedIds) => {
          resolvedIds.forEach((id) => {
            if (id.status === "fulfilled" && id.value !== null) ids.push(id.value);
          });
          setPortfolioIDs(ids);
        });
      });
  }, [activeOrganizationId, fs, projectID]);

  return { portfolioIDs };
};
