import Badge from "Components/Basic/Badge";
import { SimulationScenario } from "model/datatypes";
import React, { useMemo } from "react";
import SimulationProgress from "./SimulationProgress";

type Props = {
  scenario?: SimulationScenario;
};

const ProgressColumn = ({ scenario }: Props) => {
  const mainJob = useMemo(
    () => scenario?.jobs && Object.entries(scenario.jobs)?.find(([, job]) => job.isMain),
    [scenario?.jobs]
  );

  return mainJob ? (
    <SimulationProgress job={mainJob[1]} />
  ) : (
    <Badge text="No main job found" backgroundColor="bg-gray-100" textColor="text-gray-600" />
  );
};

export default ProgressColumn;
