import { Warning } from "@phosphor-icons/react";
import { useGetAllUsersInOrganization } from "api/userManagement/getAllUsersInOrganisation";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tooltip from "thermo/tooltip/Tooltip";
import { useGetScenarioMark } from "./api/useGetScenarioMark";
import { ScenarioLabel } from "./types";

type Props = {
  labelInfo: ScenarioLabel;
};

const ScenarioLabelTag = ({ labelInfo }: Props) => {
  const [showLabelDetails, setShowLabelDetails] = useState(false);
  const { data, fetchMore } = useGetAllUsersInOrganization({ first: 30 });

  useEffect(() => {
    const canFetchMoreUsers = data?.organization.members.pageInfo.hasNextPage;
    const endCursor = data?.organization.members.pageInfo.endCursor;

    if (canFetchMoreUsers && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    fetchMore,
    data?.organization.members.pageInfo.endCursor,
    data?.organization.members.pageInfo.hasNextPage,
  ]);

  const allUsersInOrganization = data?.organization.members.edges;
  const { scenarioID } = useParams<{ scenarioID: string }>();
  const labelId = labelInfo.label_id;
  const { labelDetails, hasLabelError } = useGetScenarioMark({ markId: labelId });
  const labelOwner = allUsersInOrganization?.find(
    ({ node }) => node.user.id === labelInfo?.owner_id
  );
  const labelBorderColor = `border-${labelDetails?.color}`;
  const labelBgColor = `bg-${labelDetails?.color}`;

  if (hasLabelError)
    return (
      <Tooltip label="Label information not found">
        <Warning size={20} className="text-warning-600" />
      </Tooltip>
    );
  if (!labelDetails) return <div />;

  return (
    <span
      className={`relative ml-0.5 min-w-max cursor-pointer whitespace-nowrap rounded py-0.5 px-2 text-sm font-medium uppercase tracking-wide text-gray-800 shadow ${
        labelInfo && labelBgColor
      }`}
      data-testid="scenarioLabel"
      onMouseEnter={() => setShowLabelDetails(true)}
      onMouseLeave={() => setShowLabelDetails(false)}
    >
      {labelDetails && labelDetails.title}
      <AnimatePresence>
        {showLabelDetails && (
          <motion.div
            key="content"
            initial="closed"
            animate="open"
            exit="closed"
            data-testid="newLabelModal"
            className={`absolute ${
              scenarioID ? "-top-10" : "-top-60"
            } -right-64 z-50 flex w-56 flex-col whitespace-normal rounded bg-slate-50 py-5 px-6 normal-case shadow-lg`}
            transition={{
              bounce: 0,
              ease: "easeInOut",
            }}
            variants={{
              open: { overflow: "visible", opacity: 1 },
              closed: { overflow: "hidden", opacity: 0 },
            }}
          >
            <div
              data-testid="newLabelColor"
              className={`mb-4 border-b-2 text-lg ${labelBorderColor}`}
            >
              Label details
            </div>
            <div className="flex flex-col items-start text-sm">
              <label className="mb-1 text-gray-400">Title</label>
              <div
                data-testid="newLabelTitle"
                className="mb-3 font-normal text-gray-900 first-letter:capitalize"
              >
                {labelInfo && labelDetails.title}
              </div>
              {labelInfo && labelDetails.description && (
                <>
                  <label className="mb-1 text-gray-400">Description</label>
                  <p
                    data-testid="newLabelDescription"
                    className="mb-3 font-normal text-gray-900 first-letter:capitalize"
                  >
                    {labelDetails.description}
                  </p>
                </>
              )}
              <label className="mb-1 text-gray-400">Created by</label>
              <div
                data-testid="newLabelOwner"
                className="mb-3 font-normal capitalize text-gray-900"
              >
                {labelOwner?.node.user.fullName || "Unknown user"}
              </div>
              <label className="mb-1 text-gray-400">Added</label>
              <div className="mb-3 font-normal text-gray-900">
                {labelInfo.created ? format(labelInfo.created, "HH:mm dd MMM yy") : "N/A"}
              </div>
              {labelInfo && labelInfo.comment && (
                <>
                  <label className="mb-1 text-gray-400">Comment</label>
                  <p
                    data-testid="newLabelComment"
                    className="mb-3 font-normal text-gray-900 first-letter:capitalize"
                  >
                    {labelInfo.comment}
                  </p>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </span>
  );
};

export default ScenarioLabelTag;
