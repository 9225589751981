import React from "react";
import ToggleContainerCell from "Components/Basic/ToggleContainer/ToggleContainerCell";
import { ParameterEditValue } from "Components/Scenario/ModelComponents/Parameters/ParameterEditValue";
import { ComponentParameter, ComponentParamType } from "model/datatypes";
import classNames from "utils/jsUtils/className";
import MapParameter from "Components/Scenario/ModelComponents/Parameters/MapParameter";

interface Props {
  parameter: ComponentParameter;
  parameterType?: ComponentParamType;
  onUpdate: (parameter: ComponentParameter) => void;
}
const ProjectParameter: React.FC<Props> = ({ parameter, parameterType, onUpdate }) => {
  const isFixed = parameter.displayMode === "fixed";
  const isMissing = parameter.value === null && !parameter.optional;
  const isMap = parameter.type === "map";

  return (
    <ToggleContainerCell
      title={parameter.displayName}
      tooltip={parameter.tooltip}
      className={classNames(
        isMap ? "row col-start-1 row-span-3 row-start-1" : "",
        isMissing && "bg-red-100"
      )}
      data-testid="projectParameter"
      content={
        <>
          {!isFixed && (
            <ParameterEditValue
              parameter={parameter}
              data-testid="projectParameter"
              paramType={parameterType}
              onUpdate={onUpdate}
            />
          )}
          {isFixed && <div>{parameter.value?.toString()}</div>}
          {isMap && (
            <MapParameter parameter={parameter} onUpdate={onUpdate} className="h-[200px]" />
          )}
        </>
      }
    />
  );
};

export default ProjectParameter;
