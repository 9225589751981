import { createColumnHelper } from "@tanstack/react-table";
import Link from "Components/AppNavigation/Link";
import Badge from "Components/Basic/Badge";
import { Button } from "Components/Basic/Buttons";
import DataOutputColumn from "Components/Scenario/ProjectOverview/DataOutputColumn";
import ProgressColumn from "Components/Scenario/ProjectOverview/ProgressColumn";
import ReportColumn from "Components/Scenario/ProjectOverview/ReportColumn";
import ScenarioRowOptions from "Components/Scenario/ProjectOverview/ScenarioRowOptions";
import { Group } from "api/group/getGroups";
import { useProjectType, useScenarios } from "api/useFirebase";
import { format } from "date-fns";
import { Project } from "model/datatypes";
import { useMemo, useState } from "react";
import ToggleContainer from "../Basic/ToggleContainer/ToggleContainer";
import GroupInformation from "./GroupInformation";
import GroupOptions from "./GroupOptions";
import ScenarioLastChangedCell from "./ScenarioLastChangedCell";
import ScenarioNameCell from "./ScenarioNameCell";
import ScenarioTable from "./ScenarioTable";
import { IScenarioTable } from "./types";

type Props = {
  group: Group;
  project: Project;
};

const ProjectGroup = ({ group, project }: Props) => {
  const projectType = useProjectType(project.projectType);
  const [showArchivedScenarios, setShowArchivedScenarios] = useState(false);

  const fixedGroup = useMemo(() => {
    if (projectType?.constrains === "groups")
      return projectType.fixedGroups?.find((g) => g.id === group.id);
    return undefined;
  }, [projectType, group.id]);

  const groupScenarioBtnText = useMemo(() => {
    if (fixedGroup) return fixedGroup.simulationName || "scenario";
    if (projectType?.constrains === "system") return projectType.simulationName || "scenario";
    return "scenario";
  }, [projectType, fixedGroup]);

  const { scenarios: fetchedScenarios, loadingScenarios } = useScenarios(
    project.id,
    group.id || ""
  );
  const sortedScenarios = fetchedScenarios
    ?.filter(({ isArchived }) => (showArchivedScenarios ? true : !isArchived))
    .sort((a, b) => {
      if (a.isArchived === b.isArchived) return 0;
      if (a.isArchived > b.isArchived) return 1;
      return -1;
    });

  const subtitle = useMemo(() => {
    let newSubtitle = <span>No scenarios</span>;
    if (fetchedScenarios?.length) {
      const activeScenarios = fetchedScenarios?.filter((scenario) => !scenario.isArchived);
      const archivedScenarios = fetchedScenarios.filter((scenario) => scenario.isArchived);
      let numberOfActive = `${activeScenarios?.length || "No"} scenarios`;
      if (activeScenarios.length === 1) numberOfActive = "1 scenario";

      let numberOfArchived = `${archivedScenarios.length} archived scenarios`;
      if (archivedScenarios.length === 0) numberOfArchived = "";
      if (archivedScenarios.length === 1) numberOfArchived = "1 archived scenario";

      newSubtitle = (
        <span>
          {numberOfActive}
          {numberOfArchived && (
            <>
              <span className="mx-2">|</span>
              <span>{numberOfArchived}</span>
            </>
          )}
        </span>
      );
    }
    return newSubtitle;
  }, [fetchedScenarios]);

  //*** Scenario Table ***/
  const data: IScenarioTable[] | undefined = useMemo(
    () =>
      sortedScenarios &&
      sortedScenarios.map((scenario) => {
        let tempObj = {
          id: scenario.id,
          scenarioName: scenario.scenarioName,
          dataOutput: scenario.data_available,
          reports: scenario,
          progress: scenario,
          lastChanged: scenario.lastChanged ? scenario.lastChanged : scenario.created,
          lastChangedBy: scenario.lastChangedBy || "",
          scenarioObj: scenario,
          systemID: scenario.systemID,
          label: scenario.label,
        };
        return tempObj;
      }),
    [sortedScenarios]
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<IScenarioTable>();
    return [
      columnHelper.accessor("scenarioName", {
        header: "Scenario name",
        cell: (cell) => <ScenarioNameCell cell={cell} />,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("dataOutput", {
        header: "Data output",
        cell: ({ getValue }) => {
          return <DataOutputColumn available={getValue()} />;
        },
        sortingFn: "basic",
      }),
      columnHelper.accessor("reports", {
        header: "Reports",
        cell: ({ getValue }) => {
          return (
            <div className="flex overflow-x-auto">
              {getValue().output_files ? (
                <ReportColumn files={getValue().output_files} scenario={getValue()} />
              ) : (
                <Badge text="N/A" backgroundColor="bg-gray-100" textColor="text-gray-600" />
              )}
            </div>
          );
        },
        enableSorting: false,
      }),
      columnHelper.accessor("progress", {
        header: "Progress",
        cell: ({ getValue }) => {
          return <ProgressColumn scenario={getValue()} />;
        },
        enableSorting: false,
      }),
      columnHelper.accessor("lastChanged", {
        header: "Last changed",
        cell: ({ getValue }) => {
          const lastChanged = getValue();
          return (
            <div className="flex min-w-max flex-row whitespace-nowrap text-sm">
              <span className="first-letter:capitalize">
                {lastChanged ? (
                  format(lastChanged, "dd/MM/yyyy - HH:mm")
                ) : (
                  <Badge text="N/A" backgroundColor="bg-gray-100" textColor="text-gray-600" />
                )}
              </span>
            </div>
          );
        },
        sortingFn: "basic",
      }),
      columnHelper.accessor("lastChangedBy", {
        header: "Edited by",
        cell: ({ getValue }) => {
          const cellValue = getValue();
          return <ScenarioLastChangedCell userID={cellValue} />;
        },
        sortingFn: "basic",
      }),
      columnHelper.display({
        id: "rowActions",
        cell: (cell) => <ScenarioRowOptions scenario={cell.row.original.scenarioObj} />,
        enableSorting: false,
      }),
    ];
  }, []);

  return (
    <ToggleContainer
      contentId={group.id || ""}
      title={group.name}
      data-testid="groupCard"
      subtitle={subtitle}
      containerOptions={
        <div className="flex items-center">
          <div className="mx-2">
            <GroupOptions
              showArchivedScenarios={showArchivedScenarios}
              toggleShowArchivedScenarios={() => {
                setShowArchivedScenarios((prev) => !prev);
              }}
              project={project}
              group={group}
            />
          </div>
          <Link to={`/create-scenario/${project.id}/${group.id}`}>
            <Button data-testid="addScenarioButton" icon="bounding-box" buttonType="secondary">
              Add {groupScenarioBtnText}
            </Button>
          </Link>
        </div>
      }
    >
      <>
        <div className="divide-x border-gray-200">
          <GroupInformation
            loadingScenarios={loadingScenarios}
            projectId={project.id}
            group={group}
          />
        </div>

        {sortedScenarios && data && sortedScenarios?.length > 0 && (
          <ScenarioTable columns={columns} data={data} />
        )}
      </>
    </ToggleContainer>
  );
};

export default ProjectGroup;
