import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_PinsQuery } from "GraphQL/gql/graphql";

export const GET_PINS = graphql(`
  query GET_PINS {
    me {
      ...PinnedItems
    }
  }
`);

type Props = {
  shouldSkip: boolean;
};

export const useGetPins = ({ shouldSkip }: Props) => {
  const result = useQuery(GET_PINS, {
    skip: shouldSkip,
  });
  return result;
};

export type Pinned = Get_PinsQuery["me"]["pinned"];
