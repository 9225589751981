import {
  ArrowsDownUp,
  CaretRight,
  Copy,
  FloppyDisk,
  Info,
  Trash,
} from "@phosphor-icons/react";
import BooleanDropdown from "Components/Basic/BooleanDropdown";
import EditableName from "Components/Basic/EditableName";
import { Input } from "Components/Basic/Input";
import {
  ComponentParamType,
  ComponentType,
  ComponentTypeReference,
  FileQuery,
  SubCompRules,
} from "model/datatypes";
import React, { useMemo, useState } from "react";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import Tooltip from "thermo/tooltip/Tooltip";
import { deepEqual, shallowEqual } from "utils/jsUtils/equality";
import { updateArrayVal, updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { useEditSystemDispatch } from "../EditSystemStore";
import ParametersEditTable from "./parameters/ParametersEditTable";
import SubComponentsRules from "./subcomponents/SubComponentsRules";
import InputEditor from "./variable/InputEditor";

export const ComponentTypeOptions: React.FC<{
  comp: ComponentType;
  onMove?: () => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  removeComponent?: (componentID: string) => void;
}> = ({ comp, onDuplicate, onMove, onSaveToLib, removeComponent }) => (
  <div className="flex items-center">
    <div className="mr-1 text-sm font-medium">{comp.order}</div>
    <PopoverMenu placement="left-start">
      {onMove && (
        <Popover.Button
          onClick={() => onMove()}
          icon={<ArrowsDownUp width="100%" height="100%" />}
        >
          Reposition
        </Popover.Button>
      )}
      {onDuplicate && (
        <Popover.Button
          onClick={() => onDuplicate()}
          icon={<Copy width="100%" height="100%" />}
        >
          Duplicate component
        </Popover.Button>
      )}
      {onSaveToLib && (
        <Popover.Button
          onClick={() => onSaveToLib()}
          icon={<FloppyDisk size="110%" weight="regular" />}
        >
          Save to library
        </Popover.Button>
      )}
      {removeComponent && (
        <Popover.Button
          onClick={() => removeComponent(comp.id)}
          className="text-red-500"
          icon={<Trash width="100%" height="100%" />}
        >
          Delete component
        </Popover.Button>
      )}
    </PopoverMenu>
  </div>
);

export const CompTypeHeadEditor: React.FC<{
  comp: ComponentType;
  updateComponent: (updates: Partial<ComponentType>) => void;
  removeComponent?: (componentID: string) => void;
  onMove?: () => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  open: boolean;
  setOpen: (updated: boolean) => void;
  alwaysOpen?: true;
}> = ({
  comp,
  updateComponent,
  onDuplicate,
  onMove,
  onSaveToLib,
  removeComponent,
  open,
  setOpen,
  alwaysOpen,
}) => {
  const instantiationRules = useMemo(
    () => comp.instantiationRules || { isMain: true, allowDisabling: false },
    [comp.instantiationRules]
  );

  const renderComponentSettings = () => (
    <div className="flex pt-2">
      <div className="mr-4 flex flex-col">
        <div className="text-sm font-medium">Name</div>
        <Input
          className="text-sm"
          type="string"
          value={comp.name}
          onChange={(e) => {
            updateComponent({
              name: e.target.value,
              type: e.target.value,
            });
          }}
        />

        <div className="flex flex-col">
          <div className="mt-2 text-sm font-medium">Item class</div>

          <Input
            type="text"
            className="text-sm"
            value={comp.item_class}
            onChange={(e) => {
              updateComponent({ item_class: e.target.value });
            }}
          />

          {comp.type === "iframe" && (
            <>
              <div className="mt-2 text-sm font-medium">Custom application URL</div>
              <Input
                type="text"
                className="text-sm w-80"
                value={comp.iframeURL || ""}
                onChange={(e) => {
                  updateComponent({ iframeURL: e.target.value });
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="mr-4 flex w-40 flex-col">
        <label className="text-sm font-medium">Allow disabling</label>
        <BooleanDropdown
          className="mb-2 w-40 text-sm"
          value={instantiationRules.allowDisabling}
          onChange={(newVal) => {
            updateComponent({
              instantiationRules: { ...instantiationRules, allowDisabling: newVal },
            });
          }}
        />
        {instantiationRules.allowDisabling && (
          <>
            <label className="text-sm font-medium">Enabled by default</label>
            <BooleanDropdown
              className="mb-2 w-40 text-sm"
              value={!!instantiationRules.defaultEnabled}
              onChange={(newVal) => {
                updateComponent({
                  instantiationRules: {
                    ...instantiationRules,
                    defaultEnabled: newVal,
                  },
                });
              }}
            />
          </>
        )}
      </div>
      <div className=" flex flex-col">
        <div className="flex items-center text-sm font-medium">
          <span className="mr-1">Instantiate on start</span>
          <Tooltip label="If enabled component will be added to top level of new scenarios.">
            <Info className="h-4 w-4" />
          </Tooltip>
        </div>
        <BooleanDropdown
          className="mb-2 w-40 text-sm"
          value={instantiationRules.isMain}
          onChange={(newVal) => {
            updateComponent({
              instantiationRules: {
                ...instantiationRules,
                isMain: newVal,
              },
            });
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="py-4 px-4">
      <div className="flex items-center justify-between">
        <EditableName
          className="w-26"
          loading={false}
          name={comp.displayName}
          onChange={(newName) => {
            updateComponent({
              displayName: newName,
            });
          }}
        />
        <div className="inline-flex items-center">
          <ComponentTypeOptions
            comp={comp}
            onDuplicate={onDuplicate}
            onMove={onMove}
            onSaveToLib={onSaveToLib}
            removeComponent={removeComponent}
          />
          {!alwaysOpen && (
            <CaretRight
              data-testid="expandComponent"
              className={`${open ? "rotate-90" : ""} cursor-pointer`}
              onClick={() => setOpen(!open)}
            />
          )}
        </div>
      </div>
      {(open || alwaysOpen) && renderComponentSettings()}
    </div>
  );
};

type Props = {
  comp: ComponentType;
  updateComponent: (componentID: string, updates: Partial<ComponentType>) => void;
  removeComponent?: (componentID: string) => void;
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  allowedParamRefs: ComponentTypeReference[];
  onMove?: () => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  systemRef: FileQuery["modelRef"];
  notCardView?: true;
};

const ComponentEditor: React.FC<Props> = ({
  comp,
  subCompOptions,
  updateComponent: updateComponentProp,
  removeComponent,
  onMove,
  allowedParamRefs,
  onDuplicate,
  onSaveToLib,
  systemRef,
  notCardView,
}) => {
  const editSystemDispatch = useEditSystemDispatch();

  const updateComponent = (newComponentProps: Partial<ComponentType>) => {
    const compToUpdate = { ...comp, ...newComponentProps };
    updateComponentProp(comp.id, newComponentProps);
    editSystemDispatch({ type: "UPDATE_COMPONENT_TYPE", payload: compToUpdate });
  };

  const [open, setOpen] = useState(false);

  const attachedSubCompRules = useMemo(
    () =>
      comp.subCompRules?.map((rule) => {
        const displayName =
          subCompOptions.find((option) => option.subCompRules.id === rule.id)?.displayName ||
          "Error..";
        return {
          subCompRules: rule,
          displayName,
        };
      }) || [],
    [comp.subCompRules, subCompOptions]
  );

  return (
    <div
      data-testid="systemComponentEditor"
      className={`${
        !notCardView && "my-4 rounded border border-gray-300 bg-white shadow"
      } flex w-full flex-col `}
    >
      <CompTypeHeadEditor
        comp={comp}
        updateComponent={updateComponent}
        onDuplicate={onDuplicate}
        onSaveToLib={onSaveToLib}
        removeComponent={removeComponent}
        onMove={onMove}
        open={open}
        alwaysOpen={notCardView}
        setOpen={setOpen}
      />
      {(open || notCardView) && (
        <div className="flex flex-grow flex-col px-4 pb-4">
          <ParametersEditTable
            componentParameters={comp.parameters}
            systemRef={systemRef}
            onUpdateParam={(updated) => {
              const updatedParameters = updateArrayValUUID(
                comp.parameters,
                updated
              ) as ComponentParamType[];
              updateComponent({
                parameters: updatedParameters,
              });
            }}
            onUpdateAllParams={(updated) => {
              updateComponent({
                parameters: updated,
              });
            }}
            onDelete={(param) => {
              updateComponent({
                parameters: comp.parameters.filter((cp) => cp.uuid !== param.uuid),
              });
            }}
            allowedComponentRefs={allowedParamRefs}
          />
          <div className="my-4" />
          <InputEditor
            componentVariables={comp.inputVariables}
            onDelete={(inputVar) => {
              updateComponent({
                inputVariables: comp.inputVariables.filter((cv) => cv.uuid !== inputVar.uuid),
              });
            }}
            onUpdate={(updatedInputVar) => {
              const updatedVariables = updateArrayValUUID(
                comp.inputVariables,
                updatedInputVar
              );
              updateComponent({
                inputVariables: updatedVariables,
              });
            }}
            onUpdateAll={(updatedVars) => {
              updateComponent({
                inputVariables: updatedVars,
              });
            }}
          />
          <div className="my-4" />

          <SubComponentsRules
            attachedSubCompRules={attachedSubCompRules}
            subCompOptions={subCompOptions?.filter(
              (option) => option.subCompRules.id !== comp.id
            )}
            onAddSubComponent={(rule) => {
              const prevRules = comp.subCompRules || [];
              updateComponent({
                subCompRules: [...prevRules, rule],
              });
            }}
            onRemoveSubComponent={(rule) => {
              if (!comp.subCompRules) {
                console.error(
                  "The subcomponent rules did not allow removing this subcomponent"
                );
                return;
              }
              updateComponent({
                subCompRules: comp.subCompRules.filter((c) => c.id !== rule.id),
              });
            }}
            onEditRules={(editedRule) => {
              if (!comp.subCompRules) {
                console.error(
                  "The subcomponent rules did not allow editing this subcomponents rules"
                );
                return;
              }
              updateComponent({
                subCompRules: updateArrayVal(comp.subCompRules, editedRule),
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

const compareProps = (prev: Props, next: Props) => {
  let isEqual = true;
  if (!shallowEqual(prev.allowedParamRefs, next.allowedParamRefs)) isEqual = false;
  else if (!deepEqual(prev.comp, next.comp)) isEqual = false;
  return isEqual;
};

export default React.memo(ComponentEditor, compareProps);
// export default ComponentEditer;
