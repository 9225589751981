import { CircleNotch } from "@phosphor-icons/react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  size: "sm" | "md";
}

const LoadingSpinner = ({ size, ...htmlProps }: Props) => {
  const spinnerSize = size === "sm" ? 16 : 24;

  return (
    <CircleNotch
      {...htmlProps}
      size={spinnerSize}
      data-testid="loadingSpinner"
      className="animate-spin text-gray-500 dark:text-gray-200"
    />
  );
};

export default LoadingSpinner;
