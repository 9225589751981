import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UPDATE_GROUP_NAME_AND_DESCRIPTION = graphql(`
  mutation UPDATE_GROUP_NAME_AND_DESCRIPTION(
    $description: String!
    $groupId: ID!
    $projectId: ID!
    $name: String!
  ) {
    groupUpdateName(groupId: $groupId, projectId: $projectId, name: $name) {
      ... on Group {
        id
        name
      }
      ... on GroupNotFound {
        __typename
        groupId
      }
    }
    groupUpdateDescription(
      description: $description
      groupId: $groupId
      projectId: $projectId
    ) {
      ... on Group {
        id
        description
      }
      ... on GroupNotFound {
        __typename
        groupId
      }
    }
  }
`);

export const useUpdateGroupNameAndDescription = () => {
  const mutation = useMutation(UPDATE_GROUP_NAME_AND_DESCRIPTION);

  return mutation;
};
