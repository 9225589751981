import { Table } from "@tanstack/react-table";
import { TagInline } from "Components/Basic/TagInline";
import { TableTextFilter } from "Components/Portfolio/Dashboard/TableFilters/TableTextFilter";
import { useFileTags } from "api/useFirebase";
import { FileQuery } from "model/datatypes";
import React from "react";

interface IFileFilter {
  table: Table<any>;
  query: FileQuery;
  updateQuery: (newQuery: FileQuery) => void;
}

const FileFilter: React.FC<IFileFilter> = ({ table, query, updateQuery }) => {
  const { types } = useFileTags();

  const renderType = (type: string) => {
    if (type.trim().length < 1) return undefined;
    const isSelected = type === query.type;
    return (
      <div
        key={type}
        className={`mx-1 my-1 cursor-pointer rounded border-2 border-blue-600 px-3 py-1 text-sm shadow ${
          isSelected ? "bg-blue-600 text-white" : "text-gray-600"
        }`}
        onClick={() => {
          const newQuery = { ...query };
          if (isSelected) delete newQuery.type;
          else newQuery.type = type;
          updateQuery(newQuery);
        }}
      >
        {type}
      </div>
    );
  };

  return (
    <div className="px-4 py-4 text-sm">
      <div className="mb-2 flex w-full items-start">
        <div className="ml-2 -mt-0.5 flex flex-wrap">
          {query.tags.map((tag) => (
            <TagInline
              tag={{ displayName: tag, id: tag }}
              key={tag}
              removeTag={() =>
                updateQuery({ ...query, tags: query.tags.filter((t) => t !== tag) })
              }
            />
          ))}
        </div>
      </div>
      <div className="font-medium">Filter by file type</div>
      <div className="mb-2 flex flex-wrap">{types.map(renderType)}</div>
      <div className="mb-2 flex items-center space-x-2">
        <div className="h-10">
          <TableTextFilter
            searchValue={table.getState().globalFilter as string}
            onChange={table.setGlobalFilter}
            debounce={200}
          />
        </div>
      </div>
    </div>
  );
};

export default FileFilter;
