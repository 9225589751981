import { Button } from "Components/Basic/Buttons";
import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { InputExpandable, InputNumber } from "Components/Basic/Input";
import { InputVar } from "model/datatypes";
import React from "react";

interface Props {
  value: InputVar;
  onUpdate: (newVal: InputVar) => void;
  onDeleteInput: () => void;
  useExperimental?: boolean;
}

const InputVariableEditable: React.FC<Props> = ({
  value,
  onUpdate,
  onDeleteInput,
  useExperimental,
}) => (
  <div className="border-t border-gray-200 py-2  px-2 hover:bg-gray-200">
    <div className="flex w-full items-center text-sm">
      <div className="w-1/8 pr-4">
        <InputExpandable
          value={value.id}
          readOnly={value.fixedID}
          className={`w-full ${value.fixedID ? "bg-gray-100" : ""}`}
          onChange={(val) => {
            onUpdate({ ...value, id: val });
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        <InputExpandable
          value={value.display}
          className={`w-full `}
          onChange={(val) => {
            onUpdate({ ...value, display: val });
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        <InputExpandable
          value={value.tooltip || ""}
          className="w-full"
          onChange={(val) => {
            onUpdate({ ...value, tooltip: val });
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        <InputExpandable
          value={value.unit || ""}
          className="w-full"
          onChange={(val) => {
            onUpdate({ ...value, unit: val });
          }}
        />
      </div>
      <div className="w-1/8 pr-4" />
      <div className="w-1/8 pr-4">
        <InputNumber
          className="w-full"
          value={value.value}
          onUpdate={(num) => onUpdate({ ...value, value: num })}
        />
      </div>

      <div className="w-1/8 pr-4">
        {useExperimental && (
          <Dropdown
            className="bg-white text-sm"
            closeOnDisappear
            selectedID={value.type || "input"}
            options={[
              { id: "input", display: "Input" },
              { id: "internal", display: "Internal state" },
              { id: "constant", display: "Constant" },
            ]}
            onSelect={(option) => {
              onUpdate({ ...value, type: option.id as InputVar["type"] });
            }}
          />
        )}
      </div>

      <div className="flex w-1/12 justify-end">
        {!value.fixedID && (
          <Button
            buttonType="white"
            onClick={() => {
              onDeleteInput();
            }}
          >
            Remove
          </Button>
        )}
      </div>
    </div>
  </div>
);

export default InputVariableEditable;
