import { useUpdateWorkspaceInformation } from "api/workspace/updateWorkspaceInformation";
import React, { useState } from "react";
import { Button } from "thermo/button/Button";
import classNames from "utils/jsUtils/className";

type Props = {
  isWorkspaceOwner: boolean;
  workspaceId: string;
  workspaceDescription: string;
};

export const WorkspaceDescriptionSection = ({
  isWorkspaceOwner,
  workspaceDescription,
  workspaceId,
}: Props) => {
  const [updateWorkspaceDescription, { loading: updatingWorkspaceDescription }] =
    useUpdateWorkspaceInformation();

  const [editedWorkspaceDescription, setEditedWorkspaceDescription] =
    useState(workspaceDescription);

  return (
    <div data-testid="workspaceDescriptionSection">
      <div className="flex items-center justify-between">
        <div>
          <div className="mb-1 text-sm">Description</div>
          <div className="mb-1 text-sm text-slate-600">
            Add a short description of this workspace.
          </div>
        </div>
        {isWorkspaceOwner && (
          <Button
            onClick={async () =>
              updateWorkspaceDescription({
                variables: {
                  workspaceId,
                  description: editedWorkspaceDescription,
                },
              })
            }
            size="sm"
            data-testid="updateWorkspaceDescriptionButton"
            className="mt-2 h-8"
            loading={updatingWorkspaceDescription}
          >
            Update
          </Button>
        )}
      </div>
      <textarea
        value={editedWorkspaceDescription}
        onChange={(e) => setEditedWorkspaceDescription(e.target.value)}
        disabled={!isWorkspaceOwner}
        data-testid="workspaceUpdateDescriptionInputField"
        className={classNames(
          "mt-2 block w-96 min-w-0 flex-1 rounded-md border border-gray-200 px-3 py-2 text-sm text-gray-700 disabled:text-gray-500 focus:ring-0"
        )}
      />
    </div>
  );
};
