import React from "react";

const RadioButton: React.FC<{ active: boolean; onClick?: () => void }> = ({
  active,
  onClick,
}) => (
  <div
    onClick={() => {
      if (onClick) onClick();
    }}
    className={`flex h-4 w-4 cursor-pointer items-center  justify-center rounded-full border ${
      active ? "border-green-numerous " : "border-gray-400"
    }`}
  >
    {active && <div className="h-2 w-2 rounded-full bg-green-numerous shadow" />}
  </div>
);

export default RadioButton;
