import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_GroupsQuery } from "GraphQL/gql/graphql";
import { Unarray } from "types/helperTypes";

export const GET_GROUPS = graphql(`
  query GET_GROUPS($projectId: ID!) {
    groups(projectId: $projectId) {
      __typename
      createdAt
      createdBy {
        ... on User {
          id
          mail
          lastName
          fullName
          firstName
        }
        ... on UserNotFound {
          __typename
          userId
        }
      }
      description
      id
      name
      scenarios
    }
  }
`);

export type Group = Extract<Unarray<Get_GroupsQuery["groups"]>, { __typename: "Group" }>;

export const useGroups = (projectId: string) => {
  const result = useQuery(GET_GROUPS, {
    variables: {
      projectId,
    },
  });

  return result;
};
