import RootPortal from "Components/Basic/RootPortal";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import { zModal } from "thermo/zIndex/zIndexValues";
import { useKeyPressAction } from "utils/hooks/useKeyPress";
import classNames from "utils/jsUtils/className";

export interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  className?: HTMLDivElement["className"];
  noPadding?: true;
  children: ReactNode;
}

const BACKDROP_TRANSITION_DURATION = 0.1;
const MODAL_TRANSITION_DURATION = 0.15;

const BaseModal = ({ open, onClose, className, noPadding, children }: BaseModalProps) => {
  useKeyPressAction({ targetKey: "Escape", fn: onClose });

  // Below useEffect does not seem to be necessary to block scroll based on component tests
  // leaving it here until the modal is used in-app and we can verify

  // Prevent body scroll while modal is open
  // useEffect(() => {
  //   const html: HTMLElement = document.documentElement;

  //   const scrollAlreadyLocked = html.style.cssText.indexOf("overflow: hidden;") >= 0;
  //   if (!open || scrollAlreadyLocked) return undefined;
  //   html.style.cssText = "overflow: hidden; padding-right: 0px;";

  //   return () => {
  //     html.style.cssText = "";
  //   };
  // }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <RootPortal>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: BACKDROP_TRANSITION_DURATION } }}
            exit={{
              opacity: 0,
              transition: {
                delay: MODAL_TRANSITION_DURATION,
                duration: BACKDROP_TRANSITION_DURATION,
              },
            }}
            className="fixed inset-0 overflow-y-auto"
            style={{ zIndex: zModal }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              className="fixed inset-0 bg-gray-800/20 flex items-center justify-center"
              data-testid="modalBackdrop"
            >
              <motion.div
                data-testid="modal"
                initial={{ top: "0.7rem", opacity: 0 }}
                animate={{
                  top: 0,
                  opacity: 1,
                  transition: {
                    delay: BACKDROP_TRANSITION_DURATION,
                    duration: MODAL_TRANSITION_DURATION,
                  },
                }}
                exit={{
                  top: "0.7rem",
                  opacity: 0,
                  transition: { duration: MODAL_TRANSITION_DURATION },
                }}
                className={classNames(
                  className,
                  "relative overflow-hidden bg-white rounded-md elevation-raised",
                  !noPadding && "p-3"
                )}
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </motion.div>
            </div>
          </motion.div>
        </RootPortal>
      )}
    </AnimatePresence>
  );
};

export default BaseModal;
