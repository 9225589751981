import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const UPDATE_DATASET_NAME = graphql(`
  mutation UPDATE_DATASET_NAME($dataSetId: ID!, $name: String!, $projectId: ID!) {
    dataSetUpdateName(dataSetId: $dataSetId, name: $name, projectId: $projectId) {
      ... on StaticDataSet {
        id
        name
      }
      ... on ScheduledDataSet {
        id
        name
      }
    }
  }
`);

export const useUpdateDataSetName = () => {
  const mutation = useMutation(UPDATE_DATASET_NAME);

  return mutation;
};
