import { useState } from "react";
import toast from "react-hot-toast";

type CopiedValue = string | null;
type CopyFn = (textToCopy: string, encodeURL?: boolean) => Promise<boolean>; // Return success
interface Props {
  itemTypeToCopy?: string;
}
/**
 * Hooks used to copy content to clipboard.
 * It handles toast itself if you pass in a string that represents what you want to copy.
 * First value of return array is null or the string you copied.
 * Second value is an async function returning true if it was succesfully copied
 * @param itemTypeToCopy - string that represents what content you are copying
 * @returns [CopiedValue: string || null, CopyFn: function => boolean]
 *
 * @example
 * // The hook will handle toast for you
 * const LinkButtonHandleToastForMe = ({link}) => {
 * const [, copyToClipboard] = useCopyToClipboard({itemTypeToCopy: "invitation link"});
 * return (<Button onClick={() => copyToClipboard(link)}>
 *           Copy link and display toast
 *         </Button>);
 * }
 * @example
 * // As  itemTypeToCopy isn't passed in, custom toast is possible.
 * const LinkButtonCustomToast = ({link}) => {
 *  const [value, copyToClipboard] = useCopyToClipboard();
 *  const customCopyToast = async () => {
 *    if(value) return toast.error("It's only allowed to copy this link once.");
 *    const success = await copyToClipboard(link);
 *    if (success) toast.succes("Link was copied");
 *    else toast.error("Failed to copy")
 *  }
 *  return (<Button onClick={customCopy}> Copy link </Button>)
 * }
 */
function useCopyToClipboard({ itemTypeToCopy }: Props = {}): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text, encodeURL = false) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }
    if (!text && itemTypeToCopy) {
      toast.error("Couldn't retrieve the information to copy to clipboard");
      return false;
    }
    const textToCopy = encodeURL ? encodeURI(text) : text;
    // Try to save to clipboard then save it in the state if worked
    try {
      const promise = navigator.clipboard.writeText(textToCopy);
      if (itemTypeToCopy) {
        const itemToCopyLower = itemTypeToCopy.toLowerCase();
        const itemToCopyFirstUpper =
          itemToCopyLower[0].toUpperCase() + itemToCopyLower.slice(1);
        toast.promise(promise, {
          success: `${itemToCopyFirstUpper} has been copied to the clipboard`,
          loading: `Copying ${itemToCopyLower} to the clipboard...`,
          error: `Error copying ${itemToCopyLower} to the clipboard.`,
        });
      }
      await promise;
      setCopiedText(textToCopy);

      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export default useCopyToClipboard;
