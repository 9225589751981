import { CSVData } from "Components/Input/types";
import { parseInput } from "utils/parseInputFormater";
import { toast } from "react-hot-toast";

export const parseCSV = (res: Papa.ParseResult<any>): CSVData[] => {
  //create variable to put data in:
  const headlineRow = res.data[0];
  let data: CSVData[] = headlineRow.map((headline: string) => ({
    tag: headline,
    values: [],
    included: true,
  }));
  //populate with row values:
  res.data.forEach((row: string[], rowI) => {
    //skip headline
    if (rowI === 0) {
      return;
    }

    row.forEach((colVal, i) => {
      const parsedVal = parseInput(colVal);
      data[i].values.push(parsedVal);
    });
  });

  let shortest = data[0].values.length;
  let longest = data[0].values.length;
  data.forEach((col) => {
    const { length } = col.values;
    if (length < shortest) shortest = length;
    if (length > longest) longest = length;
  });
  if (shortest < longest) {
    data = data.map((col) => ({ ...col, values: col.values.slice(0, shortest) }));
    toast(`Different row lengths. Truncated data to ${shortest} rows`);
  }
  const isNumberColumn = (column: CSVData) => typeof column.values[0] === "number";
  return data.map((column) => ({
    ...column,
    variableType: isNumberColumn(column) ? "number" : "string",
    included: isNumberColumn(column),
  }));
};
