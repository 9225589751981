import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const TRANSFER_WORKSPACE_OWNERSHIP = graphql(`
  mutation TRANSFER_WORKSPACE_OWNERSHIP($newOwnerId: ID!, $workspaceId: ID!) {
    workspaceTransferOwnership(newOwnerId: $newOwnerId, workspaceId: $workspaceId) {
      ... on Workspace {
        id
        members {
          id
          role
          user {
            activeWorkspaceId
            firstName
            fullName
            id
            lastName
            mail
          }
        }
      }
      ... on UserNotMember {
        userId
      }
      ... on UserNotFound {
        userId
      }
    }
  }
`);

export const useUpdateWorkspaceOwnership = () => {
  const mutation = useMutation(TRANSFER_WORKSPACE_OWNERSHIP);

  return mutation;
};
