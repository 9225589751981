import FileFilter from "Components/Files/FileFilter";
import FileUploader from "Components/Files/FileUploader";
import PageNotFound from "Components/Pages/PageNotFound";
import { TopBarWrapper } from "Components/TopBar/TopBarWrapper";
import { useGetOrganizationCreditBalance } from "api/organisation/credit/getOrganizationCreditBalance";
import { useSimFiles, useUserRole } from "api/useFirebase";
import { FileQuery } from "model/datatypes";
import { useState } from "react";
import { Alert } from "thermo/Alert/Alert";
import FileTable from "./FileTable";
import { useSetFilesTable } from "./util/setFilesTable";

const FilePage = () => {
  const { hasSimulatorAccess } = useUserRole();
  const [fileQuery, setFileQuery] = useState<FileQuery>({ tags: [] });
  const { simFiles } = useSimFiles(fileQuery);

  const { table } = useSetFilesTable({ simFiles });

  const { data, error } = useGetOrganizationCreditBalance();
  const insufficientCredits = !!error || data?.organization.creditBalance <= 0;

  if (!hasSimulatorAccess) return <PageNotFound />;
  return (
    <div data-testid="filePage">
      <TopBarWrapper pageTitle="Files" />
      <div className="flex h-full flex-col bg-white shadow-sm">
        <div className="flex w-full items-center">
          <div className="w-4/6">
            <FileFilter
              table={table}
              query={fileQuery || { tags: [] }}
              updateQuery={(newQuery) => {
                setFileQuery(newQuery);
              }}
            />
          </div>
          {insufficientCredits && (
            <div className="pr-2">
              <Alert
                type="error"
                text="Insufficient organization credits"
                subText="Cannot upload files due to insufficient credits. Contact your organization owner to add more credits."
              />
            </div>
          )}
          {!insufficientCredits && (
            <div className="mr-10 flex w-2/6 items-center justify-end">
              <div className="w-80">
                <FileUploader />
              </div>
            </div>
          )}
        </div>

        <FileTable table={table} />
      </div>
    </div>
  );
};

export default FilePage;
