import { Button } from "Components/Basic/Buttons";
import Modal from "Components/Basic/Modal";
import { ComponentParamType, ParameterTable } from "model/datatypes";
import React, { useState } from "react";
import toast from "react-hot-toast";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";

type ParamTableColumn = { uuid: string; colKey: string; colDisplay: string };

const TableColumn: React.FC<{
  column: ParamTableColumn;
  updateCol: (newCol: ParamTableColumn) => boolean;
  onDelete: () => void;
}> = ({ column, updateCol, onDelete }) => {
  const [editedCol, setEditedCol] = useState<ParamTableColumn | null>(null);
  const renderDisplayCol = () => (
    <>
      <div className="flex h-8 items-center border border-gray-100 bg-gray-100 px-2">
        {column.colDisplay}
      </div>
      <div className="flex h-8 items-center border border-white bg-white px-2">
        {column.colKey}
      </div>
      <div className="bg-gray-100 px-2 py-2">
        <button
          onClick={() => {
            setEditedCol(column);
          }}
          className="mb-2 w-full rounded border border-gray-200  focus:outline-none"
        >
          Edit
        </button>
        <button
          onClick={() => {
            onDelete();
          }}
          className="w-full rounded border border-gray-200 focus:outline-none"
        >
          Delete
        </button>
      </div>
    </>
  );

  const renderEditCol = () => (
    <>
      <div className="flex h-8 items-center bg-gray-100 px-1">
        <input
          type="text"
          className="w-full rounded border px-1 focus:outline-none"
          value={editedCol?.colDisplay}
          onChange={(e) => {
            if (editedCol) {
              setEditedCol({ ...editedCol, colDisplay: e.target.value });
            }
          }}
        />
      </div>
      <div className="flex h-8 items-center px-1">
        <input
          type="text"
          className="w-full rounded border px-1 focus:outline-none"
          value={editedCol?.colKey}
          onChange={(e) => {
            if (editedCol) {
              setEditedCol({ ...editedCol, colKey: e.target.value });
            }
          }}
        />
      </div>
      <div className="relative bg-gray-100 px-2 py-2">
        <button
          onClick={() => {
            if (editedCol) {
              const success = updateCol(editedCol);
              if (success) setEditedCol(null);
              else toast.error("The column could not be updated");
            }
          }}
          className="mb-2 w-full rounded border border-gray-200 focus:outline-none"
        >
          Save
        </button>
        <button
          onClick={() => {
            setEditedCol(null);
          }}
          className="w-full rounded border border-gray-200 focus:outline-none"
        >
          Cancel
        </button>
      </div>
    </>
  );

  return (
    <div className="mx-px w-24 text-sm">
      {editedCol ? renderEditCol() : renderDisplayCol()}
    </div>
  );
};

const ParamTableColumnEdit: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ onUpdate, parameter }) => {
  const { tableColumns } = parameter;

  const updateCol = (newCol: ParamTableColumn) => {
    const prevCol = tableColumns?.find((col) => col.uuid === newCol.uuid);
    const keyAlreadyTaken = tableColumns?.some(
      (col) => col.colKey === newCol.colKey && col.uuid !== newCol.uuid
    );
    if (keyAlreadyTaken) return false; //

    if (tableColumns && prevCol) {
      const updatedCols = updateArrayValUUID(tableColumns, newCol);
      //update the key in the actual data default if exists:
      const val = parameter.value as null | ParameterTable;
      if (val && newCol.colKey !== prevCol.colKey) {
        const values = val[prevCol.colKey];
        delete val[prevCol.colKey];
        val[newCol.colKey] = values;
      }
      onUpdate({ ...parameter, tableColumns: updatedCols, value: val });
      return true;
    }
    return false;
  };

  const addNewCol = (newCol: ParamTableColumn) => {
    if (!tableColumns || tableColumns.length < 1) {
      onUpdate({ ...parameter, tableColumns: [newCol], value: { [newCol.colKey]: [] } });
    } else {
      const updatedEntries = Object.entries(parameter.value as ParameterTable);
      updatedEntries.push([newCol.colKey, new Array(updatedEntries[0][1].length).fill(0)]);
      const updatedCols = [...tableColumns, newCol];
      onUpdate({
        ...parameter,
        tableColumns: updatedCols,
        value: Object.fromEntries(updatedEntries),
      });
    }
  };

  const deleteCol = (deletedCol: ParamTableColumn) => {
    if (tableColumns) {
      const updatedValTable = parameter.value as ParameterTable;
      delete updatedValTable[deletedCol.colKey];
      const tableCols = tableColumns.filter((c) => c.uuid !== deletedCol.uuid);
      onUpdate({
        ...parameter,
        tableColumns: tableCols.length > 0 ? tableCols : undefined,
        value: updatedValTable,
      });
    }
  };

  const [columnEditorOpen, setColumnEditorOpen] = useState(false);

  return (
    <>
      <Button buttonType="white" onClick={() => setColumnEditorOpen(true)} className="w-full">
        Edit columns
      </Button>
      <Modal
        open={columnEditorOpen}
        onClose={() => setColumnEditorOpen(false)}
        noPadding
        className="w-5/6"
      >
        <div className="p-4">
          <div className="font-medium">Edit columns</div>
          <div className="my-2 flex w-full">
            <div className="text-sm">
              <div className="flex h-8 items-center whitespace-nowrap px-4">Display Name</div>
              <div className="flex h-8 items-center px-4 py-2">Key</div>
            </div>
            <div className="flex flex-wrap gap-y-2">
              {tableColumns?.map((col) => (
                <TableColumn
                  key={col.uuid}
                  column={col}
                  updateCol={updateCol}
                  onDelete={() => {
                    deleteCol(col);
                  }}
                />
              ))}
            </div>
          </div>
          <Button
            buttonType="white"
            onClick={() => {
              let colNr = 1;
              if (tableColumns) colNr += tableColumns.length;
              const newCol = {
                uuid: getUUID(),
                colKey: `new_key_${colNr}`,
                colDisplay: "New col",
              };
              addNewCol(newCol);
            }}
            className="mt-4"
          >
            Add column
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ParamTableColumnEdit;
