import { SimulationScenario } from "model/datatypes";
import React, { useMemo } from "react";
import ReportItem from "./ReportItem";

type Props = {
  files: SimulationScenario["output_files"];
  scenario: SimulationScenario;
};

const ReportColumn = ({ files, scenario }: Props) => {
  const outputFiles = useMemo(() => {
    if (typeof files === "object") return Object.entries(files);
    return [];
  }, [files]);

  const sortedOutputFiles = outputFiles?.sort((a, b) => {
    if (a[1].timestamp === b[1].timestamp) return 0;
    if (a[1].timestamp > b[1].timestamp) return 1;
    return -1;
  });

  return (
    sortedOutputFiles && (
      <>
        {sortedOutputFiles.map(([fileID, file]) => (
          <ReportItem key={fileID} fileID={fileID} file={file} scenario={scenario} />
        ))}
      </>
    )
  );
};

export default ReportColumn;
