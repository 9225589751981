import { Combobox } from "@headlessui/react";
import { CaretDown } from "@phosphor-icons/react";
import { ExtendedOption, SelectOption } from "model/datatypes";
import React, { FocusEvent, useMemo, useState } from "react";
import LoadingSpinner from "thermo/loadingSpinner/LoadingSpinner";
import classNames from "utils/jsUtils/className";
import DropdownSearchOption from "./DropdownSearchOption";

export type SectionedOptions = {
  sections: Record<string, ExtendedOption[] | SelectOption[]>[];
};

interface Props {
  label?: string;
  options: SelectOption[] | SectionedOptions;
  selectedOption: SelectOption | ExtendedOption;
  setSelectedOption: (selected: SelectOption | ExtendedOption) => void;
  inlineButtons?: JSX.Element;
  rightAlignDropdown?: true;
  dropdownWidthFit?: true;
  compactInput?: true;
  className?: string;
  loading?: boolean;
  "data-testid"?: string;
  readOnly?: boolean;
}

const DropdownSearch: React.FC<Props> = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
  inlineButtons,
  rightAlignDropdown,
  dropdownWidthFit,
  compactInput,
  loading,
  className = "",
  readOnly = false,
  ...htmlProps
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const isSectionedOptions = "sections" in options;

  const filteredOptions: SelectOption[] | SectionedOptions = useMemo(() => {
    if (isSectionedOptions) {
      return searchQuery === ""
        ? options
        : ({
            sections: options.sections.map((section) => {
              const key = Object.keys(section)[0];
              return {
                [key]: section[key]?.filter(
                  (option: SelectOption | ExtendedOption) =>
                    "shouldAlwaysDisplay" in option ||
                    option.display.toLowerCase().includes(searchQuery.toLocaleLowerCase())
                ),
              };
            }),
          } as SectionedOptions);
    }

    return searchQuery === ""
      ? options
      : options.filter((option) =>
          option.display.toLowerCase().includes(searchQuery.toLowerCase())
        );
  }, [searchQuery, isSectionedOptions, options]);

  const searchHasMatches = isSectionedOptions
    ? (filteredOptions as SectionedOptions).sections.some((section) => {
        const key = Object.keys(section)[0];
        return section[key].length > 0;
      })
    : (filteredOptions as SelectOption[]).length > 0;

  return (
    <Combobox
      data-testid="dropdownSearch"
      {...htmlProps}
      as="div"
      value={selectedOption}
      onChange={setSelectedOption}
      className={classNames("w-full", className)}
    >
      <div className="flex gap-2 items-center">
        {label && (
          <Combobox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Combobox.Label>
        )}
      </div>
      <div className="relative">
        <div
          className={classNames(
            compactInput ? "rounded" : "rounded-md",
            "flex overflow-hidden border border-gray-300 relative"
          )}
        >
          <Combobox.Input
            className={classNames(
              compactInput ? "py-1 text-sm" : "py-2 text-base",
              "w-full truncate border-none bg-white pl-3 pr-3 shadow-sm focus:border-none focus:ring-0"
            )}
            data-testid="dropdownInput"
            disabled={readOnly}
            readOnly={readOnly}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
            displayValue={(option: SelectOption) => (loading ? "" : option.display)}
          />
          <div className="flex items-center p-2 w-8 absolute left-0 top-0">
            {loading && <LoadingSpinner size="sm" />}
          </div>
          <div className="flex bg-white">
            {inlineButtons}
            <Combobox.Button
              type="button"
              data-testid="toggleDropdown"
              // disabled={readOnly}
              className={classNames(
                "flex items-center rounded-r-md px-2 text-gray-400 transition-colors duration-100 hover:bg-indigo-600 hover:text-white focus:outline-none",
                readOnly && "hidden",
                compactInput ? "rounded-r-sm" : "rounded-r-md"
              )}
            >
              <CaretDown className="h-4 w-4" weight="bold" aria-hidden="true" />
            </Combobox.Button>
          </div>
        </div>

        {/* OPTIONS DROPDOWN */}
        <Combobox.Options
          data-testid="dropdownOptions"
          className={classNames(
            rightAlignDropdown && "right-0",
            dropdownWidthFit ? "w-fit" : "w-full",
            "absolute z-20 mt-1 max-h-80 divide-y divide-gray-200 overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          )}
        >
          {searchHasMatches &&
            (isSectionedOptions
              ? (filteredOptions as SectionedOptions).sections.map((section) => {
                  const key = Object.keys(section)[0];
                  return (
                    section[key].length > 0 && (
                      <div key={key} data-testid={`section-${key}`}>
                        {key !== "undefined" && (
                          <div className="ml-3 mt-3 mb-1 text-sm font-bold text-indigo-600 first-letter:capitalize">
                            {key}
                          </div>
                        )}
                        {section[key].map((option) => (
                          <DropdownSearchOption
                            key={option.id}
                            option={option}
                            selectedOptionID={selectedOption.id}
                          />
                        ))}
                      </div>
                    )
                  );
                })
              : (filteredOptions as SelectOption[]).map((option) => (
                  <DropdownSearchOption
                    key={option.id}
                    option={option}
                    selectedOptionID={selectedOption.id}
                  />
                )))}
          {!searchHasMatches && (
            <div className="relative cursor-default select-none py-2 pl-3 pr-9 font-normal text-gray-500 italic">
              No matches found
            </div>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default DropdownSearch;
