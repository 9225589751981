import { OrganizationUser } from "api/userManagement/getAllUsersInOrganisation";
import { ROLE_OPTIONS } from "numerous_constants";
import React from "react";

interface Props {
  collaborator: OrganizationUser;
  hasEditAccess: boolean;
  onRemove: () => void;
}

const UserCollaborator: React.FC<Props> = ({ collaborator, hasEditAccess, onRemove }) => {
  const userRole = ROLE_OPTIONS.find(({ id }) => id === collaborator.role)?.display;

  return (
    <div
      data-testid="userCollaborator"
      key={collaborator.user.id}
      className="flex justify-between py-3 px-5"
    >
      <div className="flex flex-col">
        <span className="text-sm font-medium leading-5 text-gray-700">
          {collaborator.user.fullName}
        </span>
        <span className="text-sm font-normal leading-5 text-gray-500">{userRole}</span>
      </div>
      {hasEditAccess && (
        <button
          data-testid="removeUserCollaborator"
          onClick={onRemove}
          className="text-sm font-medium leading-4 text-red-400 hover:text-red-600"
        >
          Remove
        </button>
      )}
    </div>
  );
};

export default UserCollaborator;
