import { FlagBanner } from "@phosphor-icons/react";
import Gleap from "gleap";
import Text from "thermo/typography/Typography";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";

export const ReportIssue = () => {
  const handleFeedbackFlow = () => {
    if (Gleap.isOpened()) {
      Gleap.close();
    } else {
      Gleap.open();
    }
  };

  return (
    <div data-testid="reportIssueComponent" className="flex justify-start py-1 px-2">
      <button
        data-testid="reportIssueButton"
        onClick={handleFeedbackFlow}
        className={classNames(
          getZIndexClassName("zFeedbackButton"),
          "flex items-center text-indigo-600 hover:text-indigo-800 transition-colors duration-75 select-none"
        )}
      >
        <FlagBanner data-testid="reportIssueFlagBannerIcon" className="mr-2" />
        <Text.Small>Report an issue</Text.Small>
      </button>
    </div>
  );
};
