import { Notepad, Rows, Toolbox } from "@phosphor-icons/react";
import { useUnpinProject } from "api/project/unpinProject";
import { useUnpinScenario } from "api/scenario/unpinScenario";
import { useUnpinTool } from "api/tool/unpinTool";
import { Pinned } from "api/user/getPins";
import toast from "react-hot-toast";
import { PinnedItem } from "./PinnedItem";

type Props = {
  pinnedItems: Pinned;
};

export const PinnedItemsList = ({ pinnedItems }: Props) => {
  const [unpinProject] = useUnpinProject();
  const [unpinScenario] = useUnpinScenario();
  const [unpinTool] = useUnpinTool();

  return (
    <div className="w-full flex flex-col gap-0.5">
      {pinnedItems.map((item) => {
        if (item.__typename === "Scenario")
          return (
            <PinnedItem
              icon={<Rows size={14} weight="bold" />}
              itemRoute={`/project/${item.projectId}/${item.id}`}
              pinnedItem={item}
              key={item.id}
              unpinFn={() =>
                unpinScenario({
                  variables: { projectId: item.projectId, scenarioId: item.id },
                }).catch((err) => {
                  toast.error("The scenario could not be unpinned");
                  console.error(err);
                })
              }
            />
          );
        if (item.__typename === "Project")
          return (
            <PinnedItem
              icon={<Notepad size={14} weight="bold" />}
              itemRoute={`/project/${item.id}`}
              pinnedItem={item}
              key={item.id}
              unpinFn={() =>
                unpinProject({ variables: { projectId: item.id } }).catch((err) => {
                  toast.error("The project could not be unpinned");
                  console.error(err);
                })
              }
            />
          );
        if (item.__typename === "SystemModel") {
          return (
            <PinnedItem
              icon={<Toolbox size={14} weight="bold" />}
              itemRoute={`/tools/${item.id}`}
              pinnedItem={item}
              key={item.id}
              unpinFn={() =>
                unpinTool({ variables: { systemId: item.id } }).catch((err) => {
                  toast.error("The tool could not be unpinned");
                  console.error(err);
                })
              }
            />
          );
        }
        return null;
      })}
    </div>
  );
};
