import { ScheduleInput, PeriodSettingInput } from "GraphQL/gql/graphql";
import { useState } from "react";
import getUUID from "utils/jsUtils/getUUID";

interface Params {
  activePeriod: PeriodSettingInput | undefined;
  schedule: ScheduleInput;
}

/**
 * Custom hook that enables overwriting a schedule with a copy of another schedule
 * @property {PeriodSettingInput | undefined} activePeriod - The period that should be overwritten ("weekdays" or "weekend")
 * @property {ScheduleInput} schedule - The schedule
 * @returns The function copyFromSchedule
 */
const useCopyFromSchedule = ({ activePeriod, schedule }: Params) => {
  const [confirmCopy, setConfirmCopy] = useState(false);

  const copyFromSchedule = () => {
    if (!activePeriod) return undefined;

    // if active is not empty and action is not confirmed yet, require confirm action through modal
    if (!!activePeriod.timeslots.length && !confirmCopy) {
      setConfirmCopy(true);
      return undefined;
    }
    setConfirmCopy(false);

    const notActivePeriod = schedule.periodSettings.find(
      (period) => period.periodType !== activePeriod.periodType
    );

    const copiedDefault = notActivePeriod?.defaultValue;
    const copiedTimeslots = notActivePeriod?.timeslots.map((timeslot) => ({
      ...timeslot,
      id: getUUID(),
    }));
    const periodSettings: PeriodSettingInput[] = schedule.periodSettings.map((setting) => {
      if (activePeriod.periodType !== setting.periodType) return setting;
      return {
        ...setting,
        defaultValue: copiedDefault || 0,
        timeslots: copiedTimeslots || [],
      };
    });
    const copiedSchedule = {
      ...schedule,
      periodSettings,
    };

    return copiedSchedule;
  };

  return { copyFromSchedule, confirmCopy, setConfirmCopy };
};

export default useCopyFromSchedule;
