import DropdownSearch from "Components/Basic/Selector/DropdownSearch";
import currencyOptions from "Components/Systems/utils/Currency";
import { ComponentParameter, Currency, SelectOption } from "model/datatypes";
import React from "react";

interface Props {
  parameter: ComponentParameter;
  onUpdate: (newParam: ComponentParameter) => void;
}

const CurrencySelector: React.FC<Props> = ({ parameter, onUpdate }) => {
  return (
    <DropdownSearch
      options={currencyOptions}
      selectedOption={
        currencyOptions.find(
          (option) => (option.value as Currency).name === (parameter.value as Currency).name
        ) || ({} as SelectOption)
      }
      setSelectedOption={(selected) => {
        onUpdate({ ...parameter, value: selected.value });
      }}
      rightAlignDropdown
      dropdownWidthFit
      compactInput
    />
  );
};

export default CurrencySelector;
