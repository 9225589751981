import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { Table, flexRender } from "@tanstack/react-table";
import ParagraphLineClamp from "Components/Basic/ParagraphLineClamp";
import TablePagination from "Components/Basic/table/TablePagination";
import "regenerator-runtime/runtime";
import classNames from "utils/jsUtils/className";

type Props = {
  table: Table<any>;
};

const FileTable: React.FC<Props> = ({ table }) => {
  const { getHeaderGroups, getRowModel } = table;

  return (
    <div className="max-h-full grow" data-testid="fileTable">
      <div className="flex h-full w-full flex-col">
        <table className="w-full max-w-full table-fixed divide-y divide-gray-300 overflow-y-scroll text-gray-600">
          <thead className="z-10 bg-gray-100 shadow-sm">
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="h-10">
                {headerGroup.headers.map((header) => (
                  <th
                    onClick={header.column.getToggleSortingHandler()}
                    key={header.id}
                    title={`Sort ${header.id}`}
                    className={classNames(
                      header.id === "name" && "w-3/12",
                      header.id === "tags" && "w-3/12",
                      header.id === "type" && "w-1/12",
                      header.id === "project" && "w-2/12",
                      header.id === "system" && "w-2/12",
                      header.id === "action" && "w-1/12",
                      "sticky whitespace-nowrap px-4 py-2 text-left text-sm font-normal text-gray-600 ",
                      header.column.getCanSort() && "cursor-pointer select-none"
                    )}
                  >
                    <div className="flex flex-row">
                      <span data-testid={header.id}>
                        {header.id === "action"
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </span>
                      {{
                        asc: (
                          <CaretUp
                            weight="bold"
                            data-testid="sortArrow"
                            className={classNames(
                              "ml-2 h-4 w-4 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                            )}
                          />
                        ),
                        desc: (
                          <CaretDown
                            weight="bold"
                            data-testid="sortArrow"
                            className={classNames(
                              "ml-2 h-4 w-4 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                            )}
                          />
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="h-full grow border-collapse divide-y divide-gray-200
             overflow-y-scroll bg-white"
          >
            {getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  data-testid="scenarioSortingTableRow"
                  className="h-12 hover:bg-gray-50"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="w-max truncate py-2 pl-4 text-sm text-gray-600"
                    >
                      {cell.column.id === "File name" ? (
                        <ParagraphLineClamp maxLines={1}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </ParagraphLineClamp>
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination table={table} />
      </div>
    </div>
  );
};

export default FileTable;
