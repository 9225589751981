import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import AnimatePageTransition from "Components/Pages/utils/AnimatePageTransition";
import {
  RemoteRepository,
  useGetRemoteRepositories,
} from "api/repository/getRemoteRepositories";
import React, { useEffect, useState } from "react";
import Text from "thermo/typography/Typography";
import { ConnectRemoteRepository } from "./ConnectRemoteRepository/ConnectRemoteRepository";
import { RemoteRepositoryById } from "./RemoteRepositoryById/RemoteRepositoryById";
import { RemoteRepositoryList } from "./RemoteRepositoryList/RemoteRepositoryList";
import { RepositoryConnectionInformation } from "./RepositoryConnectionInformation";

export const AddRepository = () => {
  const [selectedRemoteRepositoryId, setselectedRemoteRepositoryId] = useState<
    RemoteRepository["id"] | null
  >(null);
  let [searchingByProjectId, setSearchingByProjectId] = useState(false);

  const { data, loading, error, fetchMore } = useGetRemoteRepositories();

  useEffect(() => {
    const canFetchMoreRemoteRepositories = data?.remoteRepositories.pageInfo.hasNextPage;
    const endCursor = data?.remoteRepositories.pageInfo.endCursor;

    if (canFetchMoreRemoteRepositories && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    data?.remoteRepositories.pageInfo.endCursor,
    data?.remoteRepositories.pageInfo.hasNextPage,
    fetchMore,
  ]);

  const remoteRepositories = data?.remoteRepositories.edges;

  return (
    <AnimatePageTransition title="Add repository">
      <div className="flex items-start gap-10">
        <div className="w-1/2 flex flex-col gap-2">
          <Text.Base className="font-medium text-gray-600">
            Insert repository ID or choose from the list
          </Text.Base>

          <div>
            <RemoteRepositoryById
              setSelectedRemoteRepositoryId={(id) => setselectedRemoteRepositoryId(id)}
              selectedRemoteRepositoryId={selectedRemoteRepositoryId}
              setIsSearchingByProjectId={setSearchingByProjectId}
            />

            {loading && (
              <div className="flex items-center justify-center my-4">
                <LoadingIcon />
              </div>
            )}
            {error && (
              <div className="p-3 border border-warning-300 mt-2 rounded-md bg-warning-50">
                <Text.Base>There was an issue getting the remote repositories</Text.Base>
              </div>
            )}
            {!loading && !error && (
              <RemoteRepositoryList
                remoteRepositories={remoteRepositories}
                selectedRemoteRepositoryId={selectedRemoteRepositoryId}
                setSelectedRemoteRepositoryId={(id) => setselectedRemoteRepositoryId(id)}
                blockSelection={searchingByProjectId}
              />
            )}
          </div>

          <ConnectRemoteRepository
            repositoryProvider="gitlab.com"
            repositoryBeingLinkedTo={
              remoteRepositories?.find((rr) => rr.node.id === selectedRemoteRepositoryId)?.node
                .displayName
            }
            selectedRemoteRepositoryId={selectedRemoteRepositoryId}
          />
        </div>

        <RepositoryConnectionInformation />
      </div>
    </AnimatePageTransition>
  );
};
