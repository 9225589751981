import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { createClient as createWSClient } from "graphql-ws";
import { useMemo } from "react";
import schema from "./clientSchema.graphql";
import { typePolicies } from "./typePolicies";

export type ApolloClientInfo = {
  gqlURL: string;
  token: string | null;
  organizationSlug: string | null;
};

type Props = {
  clientInfo: ApolloClientInfo;
};

export const memoryCache = new InMemoryCache({ typePolicies });

export const useApolloClient = ({ clientInfo }: Props) => {
  const { gqlURL, token, organizationSlug } = clientInfo;

  const apolloClient = useMemo(() => {
    const apolloLink = createUploadLink({
      uri: gqlURL,
    });
    const wsLink = new GraphQLWsLink(
      createWSClient({
        url: gqlURL.replace("http", "ws"),
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" && definition.operation === "subscription"
        );
      },
      wsLink,
      apolloLink
    );

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          "numerous-organization": organizationSlug,
        },
      };
    });

    const aClient = new ApolloClient({
      connectToDevTools: true,
      link: authLink.concat(splitLink),
      uri: gqlURL,
      cache: memoryCache,
      typeDefs: schema,
    });

    return aClient;
  }, [gqlURL, organizationSlug, token]);

  return apolloClient;
};
