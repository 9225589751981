import { Clock, Pen, Trash } from "@phosphor-icons/react";
import ActionModal from "Components/Basic/ActionModal";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import TextInput from "Components/Basic/Form/TextInput";
import { useDeleteDataSet } from "api/dataSet/deleteDataSet";
import { DataSetPageNode } from "api/dataSet/getPaginatedDataSets";
import { useUpdateDataSetName } from "api/dataSet/updateDataSetName";
import { Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import { object, string } from "yup";
import ScheduleInput from "../Input/newSchedule/ScheduleInput";

const validationSchema = object({
  newDatasetName: string().required("Input the new dataset name"),
});

type Props = {
  dataSet: DataSetPageNode;
};

const DataSetOptions = ({ dataSet }: Props) => {
  const [deleteDataset, { loading: deletingDataset }] = useDeleteDataSet();
  const [updateDatasetName, { loading: updatingDatasetName }] = useUpdateDataSetName();

  const [addingSchedule, setAddingSchedule] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [renamingDataset, setRenamingDataset] = useState(false);

  const isScheduleDataset = dataSet.__typename === "ScheduledDataSet";

  const removeDatasetDescription = (
    <>
      Scenarios using{" "}
      <span data-testid="deleteModelName" className="font-medium capitalize">
        {dataSet.name}
      </span>{" "}
      dataset will no longer be able to run.
    </>
  );

  const handleDelete = async () => {
    if (deletingDataset) return;
    try {
      const response = await deleteDataset({
        variables: {
          datasetId: dataSet.id,
          projectId: dataSet.projectId,
        },
      });
      setDeleteOpen(false);
      const responseType = response.data?.dataSetDelete?.__typename;
      if (responseType === "DeletedDataSet") {
        toast.success("The dataset was deleted");
      } else if (responseType === "DataSetNotFound") {
        toast.error("The dataset could not be deleted - Please try again later.");
      } else {
        toast.error(
          "Something went wrong! Please try again later. The dataset could not be deleted"
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong! Please try again later. The dataset could not be deleted"
      );
    }
  };

  return (
    <>
      <PopoverMenu>
        {isScheduleDataset && (
          <Popover.Button
            onClick={() => setAddingSchedule(true)}
            icon={<Clock width="100%" height="100%" />}
          >
            Add schedule
          </Popover.Button>
        )}
        <Popover.Button
          onClick={() => setRenamingDataset(true)}
          icon={<Pen width="100%" height="100%" />}
        >
          Edit dataset name
        </Popover.Button>
        <Popover.Button
          onClick={() => setDeleteOpen(true)}
          data-testid="deleteDataset"
          icon={<Trash width="100%" height="100%" />}
          className="text-red-500"
        >
          Delete dataset
        </Popover.Button>
      </PopoverMenu>
      <AnimatePresence>
        {deleteOpen && (
          <ConfirmModal
            key={`${dataSet.id}-deleteModal`}
            onCancel={() => {
              setDeleteOpen(false);
            }}
            warning
            loading={deletingDataset}
            onConfirm={handleDelete}
            headline="Delete dataset?"
            description={removeDatasetDescription}
          />
        )}
        {addingSchedule && isScheduleDataset && (
          <ScheduleInput
            key={`${dataSet.id}-scheduleInput`}
            dataSet={dataSet}
            onFinished={() => {
              setAddingSchedule(false);
            }}
          />
        )}
        {renamingDataset && (
          <Formik
            initialValues={{
              newDatasetName: dataSet.name,
            }}
            onSubmit={(values) => {
              updateDatasetName({
                variables: {
                  projectId: dataSet.projectId,
                  dataSetId: dataSet.id,
                  name: values.newDatasetName,
                },
              });
              setRenamingDataset(false);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <ActionModal
                saveButtonType="submit"
                className=""
                onClose={() => setRenamingDataset(false)}
                onSave={formik.submitForm}
                saveButtonName="Update name"
                disableConfirmButtonIf={
                  !formik.isValid || !formik.dirty || formik.isSubmitting
                }
                loading={updatingDatasetName}
              >
                <div className="flex w-96 px-6 py-7">
                  <div className="flex w-1/4 items-center justify-center pr-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-100">
                      <Pen className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="w-3/4">
                    <div className="mb-2 text-lg font-normal leading-6 text-gray-900">
                      Edit dataset name
                    </div>
                    <TextInput autoFocus name="newDatasetName" />
                  </div>
                </div>
              </ActionModal>
            )}
          </Formik>
        )}
      </AnimatePresence>
    </>
  );
};

export default DataSetOptions;
