import { DotsThree, Trash } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { ConnectedRepository } from "api/repository/getConnectedRepositories";
import { format, formatDistance } from "date-fns";
import { useState } from "react";
import ContextMenu from "thermo/ContextMenu/ContextMenu";
import Popover from "thermo/popover/Popover";
import Tooltip from "thermo/tooltip/Tooltip";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";
import RemoveRepositoryModal from "../RemoveRepository/RemoveRepositoryModal";

interface Props {
  repository: ConnectedRepository;
}

const RepositoryRow = ({ repository }: Props) => {
  const [showRemoveRepositoryModal, setShowRemoveRepositoryModal] = useState(false);
  return (
    <>
      <ContextMenu.Wrapper
        menuContent={
          <ContextMenu.Button
            icon={<Trash />}
            onClick={() => setShowRemoveRepositoryModal(true)}
          >
            Remove repository
          </ContextMenu.Button>
        }
      >
        <Link
          to={`${repository.id}${
            repository.lastCommit.__typename === "Commit" ? `/${repository.lastCommit.id}` : ""
          }`}
          appendToCurrentPath
          className="grid h-12 bg-white group hover:bg-gray-50 px-4 items-center"
          data-testid="repositoryRow"
          style={{ gridTemplateColumns: "3fr 4fr 3fr 2fr minmax(4em, 1fr)" }}
        >
          <Text.Small className="text-gray-600 font-medium" data-testid="titleColumn">
            {repository.displayName}
          </Text.Small>
          <Text.Small className="text-gray-600">
            {repository.path}
          </Text.Small>
          <Text.Small className="text-gray-600" data-testid="createdByColumn">
            {repository.createdBy.fullName}
          </Text.Small>
          <Tooltip
            label={format(repository.updatedAt, "dd LLL yyyy HH:mm")}
            placement="top-start"
          >
            <Text.Small className="text-gray-600" data-testid="lastUpdatedColumn">
              {formatDistance(repository.updatedAt, new Date(), { addSuffix: true })}
            </Text.Small>
          </Tooltip>
          <div className="flex items-center justify-end">
            <Popover.Root>
              <Popover.Trigger>
                {(open) => (
                  <DotsThree
                    onClick={(e) => e.preventDefault()}
                    data-testid="repositoryRowOptions"
                    className={classNames(
                      !open && "hidden",
                      "cursor-pointer rounded text-gray-500 hover:text-gray-700 hover:bg-gray-200 group-hover:block"
                    )}
                    size={22}
                    weight="bold"
                  />
                )}
              </Popover.Trigger>
              <Popover.Content>
                <Popover.Button icon={<Trash size={16} />} onClick={() => setShowRemoveRepositoryModal(true)}>Remove repository</Popover.Button>
              </Popover.Content>
            </Popover.Root>
          </div>
        </Link>
      </ContextMenu.Wrapper>
      {showRemoveRepositoryModal && (
        <RemoveRepositoryModal
          repositoryId={repository.id}
          repositoryName={repository.path}
          closeModal={() => setShowRemoveRepositoryModal(false)}
        />
      )}
    </>
  );
};

export default RepositoryRow;
