import { X } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { zModal } from "thermo/zIndex/zIndexValues";
import { useKeyPressAction } from "utils/hooks/useKeyPress";

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children: React.ReactNode;
}

/**
 * Right side drawer that opens as:
 **   an overlay on screens smaller than 1800px.
 **   a block on the right side on screens 1800px and larger.

 * @readonly Parent component must have the classNames 'flex flex-row justify-between' for the drawer layout to work properly.
 * @param {boolean} isOpen - The current open/close state of the drawer from the parent component.
 * @param setIsOpen - Sets the open/close state of the drawer in the parent component.
 * @param children - The JSX or component(s) will be the content of the drawer.
 * @example
 * return (
 *    <div className="flex flex-row justify-between">
 *        <div>Regular page</div>
 *        <Drawer isOpen={showDrawer} setIsOpen={(open) => setShowDrawer(open)}>{children}</Drawer>
 *    </div>
 * )
 */

const Drawer = ({ isOpen, setIsOpen, children }: Props) => {
  useKeyPressAction({ targetKey: "Escape", fn: () => setIsOpen(false) });
  const [drawerHeight, setDrawerHeight] = useState<string>("100vh");
  const topBarWrapperHeight = "56px";

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const calculatedHeight = `calc(${windowHeight}px - ${topBarWrapperHeight})`;
    setDrawerHeight(calculatedHeight);
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="drawer-sidebar"
          initial={{ x: "2rem", opacity: 0 }}
          animate={{ x: "0rem", opacity: 1 }}
          exit={{ x: "2rem", opacity: 0 }}
          transition={{ duration: 0.15 }}
          style={{ height: drawerHeight, zIndex: zModal }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          data-testid="drawer"
          className="absolute right-0 top-0 bottom-0 w-1/2 xxxl:sticky xxxl:w-1/2 xxxl:z-0"
        >
          <div
            data-testid="drawerContent"
            className="absolute xxxl:w-full right-0 bottom-0 top-0 inset-y-0 p-6 shadow-[0px_8px_16px_0px_rgba(0,0,0,0.1)] xxxl:shadow-none overflow-auto bg-white border-gray-200 border-l border-b"
          >
            <span
              data-testid="drawerClose"
              onClick={() => setIsOpen(false)}
              className="p-4 cursor-pointer absolute top-0 right-0"
            >
              <X size={16} weight="bold" className="text-gray-600" />
            </span>
            <div>{children}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
