import React from "react";

interface Props {
  selected: boolean;
  onCheck: (newVal: boolean) => void;
}

const BooleanTick = ({ selected, onCheck: onClick }: Props) => (
  <div className="flex items-center">
    <span
      className="mr-3 truncate py-2 pl-4 text-sm text-gray-600"
      onClick={() => onClick(!selected)}
    >
      {selected ? "Enabled" : "Disabled"}
    </span>
    <input
      type="checkbox"
      className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
      checked={selected}
      onChange={(e) => {
        onClick(e.target.checked);
      }}
    />
  </div>
);

export default BooleanTick;
