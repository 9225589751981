import { ArrowsOut } from "@phosphor-icons/react";
import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import classNames from "utils/jsUtils/className";

export const TextArea: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  className = "",
  value,
  onChange,
  ...htmlProps
}) => {
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textArea = ref.current;
    if (textArea && cursor !== null) textArea.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  return (
    <textarea
      data-testid="textAreaInput"
      {...htmlProps}
      ref={ref}
      value={value}
      onChange={(e) => {
        setCursor(e.currentTarget.selectionStart);
        if (onChange) onChange(e);
      }}
      className={`resize-none rounded border border-gray-300 px-2 py-1 outline-none focus:ring focus:ring-blue-400  ${className}`}
    />
  );
};

interface InputTextProps {
  value: string;
  className?: string;
  placeholder?: string;
  onChange: (v: string) => void;
  onEnter?: () => void;
  readOnly?: true;
}

export const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className = "",
  value,
  onChange,
  ...htmlProps
}) => {
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input && cursor !== null) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  return (
    <input
      {...htmlProps}
      ref={ref}
      onChange={(e) => {
        setCursor(e.currentTarget.selectionStart);
        if (onChange) onChange(e);
      }}
      value={value}
      className={`rounded border border-gray-300 px-2 py-1 outline-none focus:ring focus:ring-blue-400 ${className}`}
    />
  );
};

interface SelectionObject {
  start: number | null;
  end: number | null;
  direction?: "forward" | "backward" | "none";
}
export const InputExpandable: React.FC<InputTextProps> = ({
  value,
  onChange,
  placeholder,
  className,
  onEnter,
  readOnly,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const hiddenTextRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [selection, setSelection] = useState<SelectionObject>();

  const closeExpanded = useCallback(() => {
    setSelection(undefined);
    setExpanded(false);
  }, []);

  const selectionEventHandler = (
    e: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement, Event>
  ) => {
    const { selectionStart, selectionEnd, selectionDirection } = e.currentTarget;
    setSelection({
      start: selectionStart || e.currentTarget.value.length,
      end: selectionEnd || e.currentTarget.value.length,
      direction: selectionDirection || undefined,
    });
  };
  const setSelectionRange = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    //If selection undefined, set selection to the end.
    if (!selection || !selection.start || !selection.end)
      return e.currentTarget.setSelectionRange(
        e.currentTarget.value.length,
        e.currentTarget.value.length
      );

    e.currentTarget.setSelectionRange(selection.start, selection.start, selection.direction);
    return undefined;
  };
  useLayoutEffect(() => {
    const textWidth = hiddenTextRef.current?.clientWidth;
    const width = ref.current?.offsetWidth;
    if (width && textWidth && value.length > 0) setHasOverflow(width - 20 < textWidth);
    else setHasOverflow(false);
  }, [value]);

  const popupRef = useRef<HTMLDivElement>(null);

  useClickOutsideEffect([ref, popupRef], closeExpanded);

  return (
    <div className="relative w-full" ref={ref}>
      {hasOverflow && (
        <ArrowsOut
          onClick={() => {
            setExpanded(true);
          }}
          className="absolute top-0 right-0 z-10 mt-1 opacity-25 hover:cursor-pointer hover:opacity-100"
        />
      )}
      {expanded && (
        <div ref={popupRef}>
          <TextArea
            className="absolute top-0 left-0 z-20 h-20 w-full min-w-[16rem]  text-sm"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={setSelectionRange}
            autoFocus
            onSelect={selectionEventHandler}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setExpanded(false);
              }
            }}
          />
        </div>
      )}
      <Input
        className={`relative w-full border border-gray-300 text-sm outline-none focus:border-none focus:outline-none focus:ring ${
          className || ""
        }`}
        type="text"
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(e) => e.key === "Enter" && onEnter && onEnter()}
        onSelect={selectionEventHandler}
      />
      {/* Hidden input box for getting width */}
      <div
        className="whitespace-no-wrap absolute text-sm"
        style={{ visibility: "hidden" }}
        ref={hiddenTextRef}
      >
        {value}
      </div>
    </div>
  );
};

type InputNumberProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: number;
  onUpdate: (updated: number) => void;
  disabled?: boolean;
  className?: string;
  headless?: true;
  optional?: boolean;
};

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  function InputNumber(
    { value, onUpdate, disabled = false, className, headless, optional, ...htmlProps },
    ref
  ) {
    const [displayVal, setDisplayVal] = useState(value?.toString());
    const latestValRef = useRef(value);
    const [error, setError] = useState(false);

    //if external changes to number from outside:
    useEffect(() => {
      if (value !== latestValRef.current) {
        latestValRef.current = value;
        setDisplayVal(value?.toString());
      }
    }, [value, displayVal]);

    return (
      <input
        ref={ref}
        {...htmlProps}
        className={classNames(
          "focus:ring-blue-40 rounded border-gray-300 px-2 py-1 outline-none focus:ring",
          className,
          "w-full border-gray-300 text-sm outline-none focus:border-none focus:outline-none",
          !headless &&
            "rounded border px-2 py-1 outline-none disabled:border-trueGray-300 disabled:bg-trueGray-100 disabled:text-trueGray-400",
          !headless && error && "ring ring-red-400",
          !headless && !error && "focus:ring focus:ring-blue-400"
        )}
        type="number"
        disabled={disabled}
        value={displayVal}
        pattern="^-?[0-9]\d*\.?\d*$"
        onChange={(e) => {
          setDisplayVal(e.target.value);
          const newVal = parseFloat(e.target.value);
          if (!Number.isNaN(newVal)) {
            latestValRef.current = newVal;
            onUpdate(newVal);
            setError(false);
          } else if (!optional) {
            setError(true);
          }
        }}
      />
    );
  }
);
