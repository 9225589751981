import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { UnsavedStateModal } from "./UnsavedStateModal";

type Props = {
  unsavedState: boolean;
};

const BlockRouteModal = ({ unsavedState }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const blocker = useBlocker(() => {
    if (unsavedState) {
      setOpenModal(true);
      return true;
    }
    setOpenModal(false);
    return false;
  });

  const handleConfirmNavigation = () => {
    setOpenModal(false);
    blocker.proceed?.();
  };

  const handlePreventNavigation = () => {
    setOpenModal(false);
  };

  return (
    <AnimatePresence>
      {openModal && (
        <UnsavedStateModal
          cancel={handlePreventNavigation}
          confirmUnsafeNavigation={handleConfirmNavigation}
        />
      )}
    </AnimatePresence>
  );
};

export default BlockRouteModal;
