import { FileCsv, Upload } from "@phosphor-icons/react";
import Dropzone from "Components/Basic/upload/Dropzone";
import React from "react";

interface IFileUploader {
  onFileAdded: (file: File) => void;
}

const FileUploader = ({ onFileAdded }: IFileUploader) => {
  return (
    <>
      <span className="mb-2 inline-flex items-end text-sm">
        Upload a time series csv data file
        <FileCsv className="ml-2" weight="light" size={20} />
      </span>

      <Dropzone
        allowedTypes="csv"
        onFilesAdded={(files) => {
          if (files[0]) onFileAdded(files[0]);
        }}
      >
        {(dropHovered, manualOpen) => (
          <div
            className={`flex h-32 cursor-pointer flex-col items-center justify-center rounded-lg border-4 border-dashed border-gray-400 ${
              dropHovered ? "bg-green-200 shadow-lg" : ""
            }`}
            onClick={manualOpen}
          >
            <div className="h-12 w-12 text-gray-700">
              <Upload size={30} />
            </div>
            <div className="text-sm font-medium text-gray-700">
              Drop files or click here to upload
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
};

export default FileUploader;
