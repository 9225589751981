import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const REMOVE_WORKSPACE_EDITOR = graphql(`
  mutation REMOVE_WORKSPACE_EDITOR($userID: ID!, $workspaceID: ID!) {
    workspaceRemoveEditor(userId: $userID, workspaceId: $workspaceID) {
      ... on Workspace {
        id
        name
        members {
          id
          user {
            firstName
            fullName
            activeWorkspaceId
            id
            lastName
            mail
          }
        }
      }
    }
  }
`);

// TODO : Does not work
export const useRemoveWorkspaceUser = () => {
  const mutation = useMutation(REMOVE_WORKSPACE_EDITOR);

  return mutation;
};
