import { useArchiveScenario } from "api/scenario/archiveScenario";
import { useUnarchiveScenario } from "api/scenario/unarchiveScenario";
import { Project, SimulationScenario } from "model/datatypes";

type Props = {
  scenarioIsArchived: SimulationScenario["isArchived"];
  projectId: Project["id"];
  scenarioId: SimulationScenario["id"];
};

export const useToggleArchiveScenario = ({
  scenarioIsArchived,
  projectId,
  scenarioId,
}: Props) => {
  const [archiveScenario] = useArchiveScenario();
  const [unarchiveScenario] = useUnarchiveScenario();

  const toggleArchiveScenario = () => {
    if (scenarioIsArchived) {
      unarchiveScenario({ variables: { projectId, scenarioId } });
    } else {
      archiveScenario({ variables: { projectId, scenarioId } });
    }
  };
  return { toggleArchiveScenario };
};
