import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { useUserActivate } from "api/user/userActivate";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Text from "thermo/typography/Typography";

export const ActivateNewUser = () => {
  const [activateUser, { loading: activatingUser, data }] = useUserActivate();
  const { activationToken } = useParams<{ activationToken: string }>();
  const navigate = useNavigateApp();

  useEffect(() => {
    if (activationToken) {
      activateUser({ variables: { activationToken } }).then((res) => {
        if (res.data?.userActivate.__typename === "UserActivated") {
          toast.success("The account has been activated - login to get started");
          navigate("/", { useAsCompletePath: true });
        }
      });
    }
    // intentionally not using deps to force a one-time function call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (activatingUser) return <FullScreenLoadingOverlay message="Activating account" />;

  if (data?.userActivate.__typename === "UserActivationTokenInvalid") {
    setTimeout(() => {
      navigate("/login");
    }, 5000);
  }

  if (data?.userActivate.__typename === "UserActivationTokenInvalid") {
    return (
      <div
        data-testid="failedActivationMessage"
        className="w-screen h-screen flex justify-center items-center"
      >
        <Text.Base>The account activation is invalid - redirecting to login page.</Text.Base>
      </div>
    );
  }
  return null;
};
