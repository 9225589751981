import { useDebounce } from "Components/hooks/useDebounce";
import React, { useState } from "react";
import { SearchBar } from "thermo/searchbar/SearchBar";
import { CommitList } from "./CommitList/CommitList";

export const RepositoryCommits = () => {
  const [commitSearchTerm, setCommitSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(commitSearchTerm, 300);
  return (
    <div className="mr-2">
      <SearchBar
        placeholder="Search for commits"
        searchValue={commitSearchTerm}
        setSearchValue={setCommitSearchTerm}
      />
      <CommitList searchTerm={debouncedSearchTerm} />
    </div>
  );
};
