import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { missionControlFBConfig } from "Components/Providers/Firebase/missionControlFBConfig";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useGlobalDispatch } from "store";
import Text from "thermo/typography/Typography";
import { CloudProject, DevConfig } from "./cloudConfig";

export const localEmulatorProjects: CloudProject = {
  id: "emulator",
  name: "Testing environment",
  api: "http://localhost:8008", // use this when local backend is running
  cli_api: "http://localhost:50051",
  config: DevConfig,
  graphql_api: "http://localhost",
  gitlab: {
    appId: "4bcee79f0e5d950c2bf1601fba442c4b64626d35db26d7b9498e09abc2bd85af",
    authorizeUrl: "http://localhost:8002/gitlab.com/authorize",
  },
  environment_id: "localhost",
  // Permissions are used to disable organization creation on specific environments.
  // permissions: {
  //   disallowLoggedInOrganizationCreation: true,
  //   disallowSignUpOrganizationCreation: false,
  // },
};

type Props = {
  children: ReactNode;
};

type FirebaseManagerContextType = {
  cloudInstanceToUseInApp?: CloudProject;
};

export const FirebaseManagerContext = createContext<FirebaseManagerContextType | null>(null);

const firebaseManagerApp = initializeApp(missionControlFBConfig, "firebaseManager");
const db = getFirestore(firebaseManagerApp);

const getCloudInstances = async () => {
  const instancesCollection = collection(db, "numerous_instances");
  const instancesSnapshot = await getDocs(instancesCollection);
  const instancesList = instancesSnapshot.docs.map((doc) => doc.data());

  return instancesList as CloudProject[];
};

export const FirebaseManager = ({ children }: Props) => {
  const dispatch = useGlobalDispatch();
  const [cloudInstanceToUseInApp, setCloudInstanceToUseInApp] = useState<CloudProject>();
  const [cloudDomainIsInvalid, setCloudDomainIsInvalid] = useState(false);

  useEffect(() => {
    if (window.location.hostname === "emulator.localhost") {
      setCloudInstanceToUseInApp(localEmulatorProjects);
      dispatch({ type: "SET_CLOUD_PROJECT", payload: localEmulatorProjects });
      return;
    }

    getCloudInstances()
      .then((cloudInstanceList) => {
        //set based on url:
        let urlPrefix = window.location.hostname.split(/[\\.]/)[0].toLowerCase().trim();

        const matchedCloudProject = cloudInstanceList.find((cloud) => cloud.id === urlPrefix);

        if (!matchedCloudProject) {
          setCloudDomainIsInvalid(true);
          return;
        }

        setCloudInstanceToUseInApp(matchedCloudProject);
        dispatch({ type: "SET_CLOUD_PROJECT", payload: matchedCloudProject });
      })
      .catch((err) => console.error(err));
  }, [dispatch]);

  if (cloudDomainIsInvalid) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Text.H4>
          The URL prefix{" "}
          <span className="font-medium">
            {window.location.hostname.split(/[\\.]/)[0].toLowerCase().trim()}
          </span>{" "}
          is not valid. Either use{" "}
          <a className="text-blue-800" href="https://app.numerous.com/">
            app.numerous.com
          </a>{" "}
          or a custom domain if you have one. If you believe this is an error, please contact
          the numerous team.
        </Text.H4>
      </div>
    );
  }
  if (!cloudInstanceToUseInApp) return <FullScreenLoadingOverlay message="Setting cloud" />;
  return (
    <FirebaseManagerContext.Provider value={{ cloudInstanceToUseInApp }}>
      {children}
    </FirebaseManagerContext.Provider>
  );
};

export const useFirebaseCloudInstance = () => {
  const context = useContext(FirebaseManagerContext);
  if (!context?.cloudInstanceToUseInApp) throw Error("No cloud instance match");

  return context.cloudInstanceToUseInApp;
};
