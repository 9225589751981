import { Database } from "@phosphor-icons/react";
import { ConditionalWrapper } from "Components/Basic/ConditionalWrapper";
import React from "react";
import Tooltip from "thermo/tooltip/Tooltip";
import classNames from "utils/jsUtils/className";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import { CSVData } from "../types";

const PREVIEW_LENGTH = 10;

const CSVPreviewer: React.FC<{
  csvData: CSVData[];
  onUpdate: (newData: CSVData[]) => void;
  indexColumnTag: string;
  indexValues: number[];
}> = ({ csvData, onUpdate, indexColumnTag, indexValues }) => {
  const onToggleParam = (param: CSVData, index: number) => {
    const updatedParam = { ...param, included: !param.included };
    onUpdate(immutableSplice(csvData, index, 1, updatedParam));
  };

  const renderIndexCol = () => (
    <div className="flex-none overflow-hidden border-r border-gray-200">
      <div className="flex items-center border-b bg-gray-500 py-2 px-2 text-white">
        <Tooltip label="Index coloumn">
          <Database className="mr-1 h-3 w-3" />
        </Tooltip>
        <div className="text-sm font-medium ">_index</div>
      </div>
      <div
        className="bg-gray-500 px-2 text-sm  italic text-white"
        data-testid="csvPreviewerIndexColumn"
      >
        {indexValues.slice(0, PREVIEW_LENGTH).map((val) => (
          <div key={val}>{val}</div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="relative max-w-full overflow-hidden">
      <div className="scrollbar-vertical flex overflow-x-auto rounded border border-gray-200">
        {indexColumnTag === "_index" && renderIndexCol()}
        {csvData.map((param, i) => {
          const active = param.included && param.variableType === "number";
          const isIndex = indexColumnTag === param.tag;
          const canBeIncluded = param.variableType === "number";
          const amountOHiddenRows = param.values.length - PREVIEW_LENGTH;
          return (
            <div
              key={param.tag}
              data-testid="dataColumn"
              className={`flex-none overflow-hidden border-r border-gray-200
              ${active ? "" : "opacity-50"}
            `}
            >
              <div
                className={`flex items-center border-b py-2 px-2 ${
                  isIndex ? "bg-gray-500 text-white" : ""
                }`}
              >
                {isIndex && (
                  <Tooltip label="Index coloumn">
                    <Database className="mr-1 h-3 w-3" />
                  </Tooltip>
                )}
                <div className="text-sm font-medium ">{param.tag}</div>
              </div>
              <div className="px-2 text-sm italic">
                {param.values.slice(0, PREVIEW_LENGTH).map((val, rowIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${val}_${rowIndex}`}>{val}</div>
                ))}
                {amountOHiddenRows > 0 && <div>...{amountOHiddenRows} more rows</div>}
              </div>

              <ConditionalWrapper
                condition={param.variableType !== "number"}
                wrapper={(children) => (
                  <Tooltip label="Only number columns can be included">{children}</Tooltip>
                )}
              >
                <div
                  className={classNames(
                    param.variableType === "number" && "cursor-pointer",
                    "flex items-center bg-gray-100 px-2 py-1"
                  )}
                  data-testid="includeColumnToggle"
                  onClick={() => param.variableType === "number" && onToggleParam(param, i)}
                >
                  <input
                    className={classNames(canBeIncluded && "cursor-pointer", "ring-0")}
                    type="checkbox"
                    checked={active}
                    readOnly
                  />
                  <label
                    className={classNames(
                      canBeIncluded && "cursor-pointer",
                      "ml-2 text-sm font-medium"
                    )}
                  >
                    include
                  </label>
                </div>
              </ConditionalWrapper>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CSVPreviewer;
