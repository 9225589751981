import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const USER_SIGN_UP = graphql(`
  mutation USER_SIGN_UP(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    userSignUp(
      userSignUp: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      ... on UserSignUpActivationEmailSent {
        __typename
        email
      }
      ... on EmailAlreadyInUse {
        __typename
        email
      }
      ... on EmailFormatInvalid {
        __typename
        email
      }
      ... on UserSignUpWeakPassword {
        __typename
        email
        failedPasswordRequirements {
          requiredLength
        }
      }
    }
  }
`);

export const useUserSignUp = () => {
  const mutation = useMutation(USER_SIGN_UP);

  return mutation;
};
