import { Plus } from "@phosphor-icons/react";
import RepositionList, { RepositionListItem } from "Components/Basic/RepositionList";
import {
  ComponentType,
  ComponentParamType,
  ComponentParameter,
  ComponentTypeReference,
} from "model/datatypes";
import React, { useMemo } from "react";
import { Button } from "thermo/button/Button";
import getUUID from "utils/jsUtils/getUUID";
import { EditableParameterRow } from "./EditableParameter";

interface Props {
  componentParameters: ComponentParamType[];
  systemRef?: {
    id: string;
    displayName: string;
  };
  onUpdateParam: (updatedParam: ComponentParamType) => void;
  onUpdateAllParams: (updatedParams: ComponentType["parameters"]) => void;
  onDelete: (param: ComponentParamType) => void;
  allowedComponentRefs?: ComponentTypeReference[];
  includeMapType?: true;
  includeCurrencyType?: true;
}

const ParametersEditTable: React.FC<Props> = ({
  componentParameters,
  onDelete,
  onUpdateParam,
  systemRef,
  allowedComponentRefs,
  onUpdateAllParams,
  includeMapType = false,
  includeCurrencyType = false,
}) => {
  //sort the component parameters?
  const parameterList = useMemo<RepositionListItem[]>(
    () =>
      componentParameters.map((param) => ({
        id: param.uuid,
        element: (
          <EditableParameterRow
            systemRef={systemRef}
            key={param.uuid}
            param={param}
            onUpdateParam={onUpdateParam}
            onDeleteParam={() => {
              onDelete(param);
            }}
            allowedComponentRefs={allowedComponentRefs}
            includeMapType={includeMapType}
            includeCurrencyType={includeCurrencyType}
          />
        ),
        val: param,
      })),
    [
      componentParameters,
      systemRef,
      onUpdateParam,
      allowedComponentRefs,
      includeMapType,
      includeCurrencyType,
      onDelete,
    ]
  );

  return (
    <div className="flex flex-col">
      <div className="flex-none">
        <div className="text-sm font-bold">Parameters</div>
        <div className="flex w-full border-t border-gray-200 py-2  px-2">
          <div className="w-1/8 pr-4 text-sm font-medium">ID</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Display name</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Tooltip</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Type</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Tag / setup</div>
          <div className="w-1/8 pr-4 text-sm font-medium">Default value</div>
          <div className="w-1/12 pr-4 text-sm font-medium">Access</div>
          <div className="w-1/12 pr-4 text-sm font-medium">Optional</div>
          <div className="" />
        </div>
      </div>
      <RepositionList
        list={parameterList}
        onReposition={(updatedList) => {
          const updatedParameters = updatedList.map(
            (li) => li.val
          ) as ComponentType["parameters"];
          onUpdateAllParams(updatedParameters);
        }}
      />
      {componentParameters.length === 0 && (
        <div className="px-2 text-sm italic">No parameters added</div>
      )}
      <div className="flex-none py-2 px-2">
        <Button
          size="sm"
          icon={<Plus size={14} />}
          data-testid="addParameter"
          onClick={() => {
            const newParam: ComponentParameter = {
              uuid: getUUID(),
              id: "",
              value: "",
              type: "string",
              displayName: "",
            };
            onUpdateParam(newParam);
          }}
          className="w-16"
        >
          Add parameter
        </Button>
      </div>
    </div>
  );
};

export default ParametersEditTable;
