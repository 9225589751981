import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_GroupQuery } from "GraphQL/gql/graphql";

export const GET_GROUP = graphql(`
  query GET_GROUP($groupId: ID!, $projectId: ID!) {
    group(groupId: $groupId, projectId: $projectId) {
      ... on Group {
        __typename
        id
        name
        description
        createdAt
        scenarios
        createdBy {
          ... on User {
            id
          }
        }
      }
    }
  }
`);

export type GetGroup = Extract<Get_GroupQuery["group"], { __typename: "Group" }>;

export const useGetGroup = (projectId: string, groupId: string) => {
  const result = useQuery(GET_GROUP, {
    variables: {
      projectId,
      groupId,
    },
  });

  return result;
};
