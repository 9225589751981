import CollaboratorsModal from "Components/Collaborators/Modal/CollaboratorsModal";
import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useAddTeamToProject } from "api/project/addTeamToProject";
import { useAddUserToProject } from "api/project/addUserToProject";
import { useRemoveTeamFromProject } from "api/project/removeTeamFromProject";
import { useRemoveUserFromProject } from "api/project/removeUserFromProject";
import { useUserRole } from "api/useFirebase";
import { Project } from "model/datatypes";
import React from "react";

interface Props {
  project: Project;
  onClose: () => void;
}

const ProjectCollaboration: React.FC<Props> = ({ project, onClose }) => {
  const { hasProjectEditAccess } = useUserRole();
  const activeOrganizationId = useActiveOrganizationId();

  const [addUserToProject] = useAddUserToProject();
  const [addTeamToProject] = useAddTeamToProject();

  const [removeUserFromProject] = useRemoveUserFromProject();
  const [removeTeamFromProject] = useRemoveTeamFromProject();

  const handleAddCollaborator = ({ id, type }: { id: string; type: "user" | "team" }) => {
    if (type === "user")
      addUserToProject({
        variables: {
          projectId: project.id,
          collaboratorId: id,
        },
      });
    else if (type === "team")
      addTeamToProject({
        variables: {
          projectId: project.id,
          teamId: id,
        },
      });
  };

  const handleRemoveCollaborator = ({ id, type }: { id: string; type: "user" | "team" }) => {
    if (type === "user")
      removeUserFromProject({
        variables: {
          projectId: project.id,
          collaboratorId: id,
        },
      });
    else if (type === "team")
      removeTeamFromProject({
        variables: {
          projectId: project.id,
          teamId: id,
        },
      });
  };

  if (!activeOrganizationId) return null;
  return (
    <CollaboratorsModal
      addedUserCollaborators={project.collaborators}
      addedTeams={project.teams}
      onAddCollaborator={handleAddCollaborator}
      onRemoveCollaborator={handleRemoveCollaborator}
      onClose={onClose}
      context="project"
      hasEditAccess={hasProjectEditAccess}
    />
  );
};

export default ProjectCollaboration;
