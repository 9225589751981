import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Paginated_DatasetsQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const PAGINATED_DATASETS = graphql(`
  query PAGINATED_DATASETS($after: String, $first: Int, $projectId: ID!) {
    project(projectId: $projectId) {
      ... on Project {
        dataSets(after: $after, first: $first) @connection(key: "PaginatedDataSets") {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              ... on ScheduledDataSet {
                __typename
                id
                projectId
                name
                tags
              }
              ... on StaticDataSet {
                __typename
                id
                projectId
                name
                tags
              }
            }
          }
        }
      }
    }
  }
  fragment DataSetFromPage on DataSet {
    __typename
    id
    projectId
    name
    tags
  }
`);

export type DataSetPageNode = ArrayElement<
  Extract<Paginated_DatasetsQuery["project"], { __typename?: "Project" }>["dataSets"]["edges"]
>["node"];

type Props = {
  first: number;
  projectId: string;
};

const useGetPaginatedDataSets = ({ first, projectId }: Props) => {
  const query = useQuery(PAGINATED_DATASETS, {
    variables: { first, projectId, after: null },
    notifyOnNetworkStatusChange: true,
  });

  return query;
};

export default useGetPaginatedDataSets;
