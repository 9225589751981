import { WarningOctagon } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import ActionModal from "Components/Basic/ActionModal";
import { useRemoveConnectedRepository } from "api/repository/removeConnectedRepository";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

interface Props {
  repositoryId: string;
  repositoryName: string;
  closeModal: () => void;
}

const RemoveRepositoryModal = ({ repositoryId, repositoryName, closeModal }: Props) => {
  const [removeRepository, { loading }] = useRemoveConnectedRepository(repositoryId);
  const location = useLocation();
  const navigate = useNavigateApp();

  const isRepositoryOverview = location.pathname.split("/").at(-1) === "repositories";

  return (
    <ActionModal
      saveButtonName="Remove repository"
      onClose={closeModal}
      onSave={() => {
        removeRepository()
          .then(() => toast.success("The repository was removed"))
          .catch((error) => {
            console.error(error);
            toast.error("An error occurred while removing the repository");
          })
          .finally(() => {
            closeModal();
            if (!isRepositoryOverview) navigate("repositories");
          });
      }}
      loading={loading}
    >
      <div data-testid="blockRouteModal" className="flex px-6 py-7">
        <div className="flex items-center justify-end pr-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
            <WarningOctagon
              weight="regular"
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
        </div>
        <div>
          <div className="mb-2 text-lg font-normal leading-6 text-gray-900">
            Remove repository
          </div>
          <div className="text-sm text-gray-500">
            Are you sure you want to remove{" "}
            <span className="font-medium text-gray-700">{repositoryName}</span> from numerous?
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default RemoveRepositoryModal;
