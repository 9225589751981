import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { SelectOption, SimTimeUnit, TimeValue } from "model/datatypes";
import React, { useMemo, useState } from "react";
import {
  convertRawTimeFromUnitToSeconds,
  convertRawTimeToUnit,
} from "utils/dataTransform/timeConvert";

export const SimTimeUnitSelector: React.FC<{
  unit: SimTimeUnit;
  onUpdate: (updated: SimTimeUnit) => void;
  className?: string;
  headlessStyle?: true;
}> = ({ unit, onUpdate, className, headlessStyle }) => {
  const timeOptions: SelectOption[] = useMemo(
    () =>
      [
        { id: "years", display: "Years" },
        { id: "months", display: "Months" },
        { id: "days", display: "Days" },
        { id: "hours", display: "Hours" },
        { id: "minutes", display: "Minutes" },
        { id: "seconds", display: "Seconds" },
      ] as { id: SimTimeUnit; display: string }[],
    []
  );

  return (
    <Dropdown
      headlessStyle={headlessStyle}
      drawerID="time_val_selector"
      className={className || "bg-white text-sm "}
      selectedID={unit}
      options={timeOptions}
      onSelect={(option) => {
        const newUnit = option.id as SimTimeUnit;
        onUpdate(newUnit);
      }}
    />
  );
};

interface Props {
  timeValue: TimeValue;
  updateValue: (updated: number) => void;
  update: (updated: TimeValue) => void;
}

const TimeValueInput: React.FC<Props> = ({ timeValue, updateValue, update }) => {
  const [displayDuration, setDisplayDuration] = useState(
    convertRawTimeToUnit(timeValue.value, timeValue.unit).toString()
  );

  return (
    <div className="flex w-full overflow-hidden rounded text-sm">
      <input
        className={`w-1/2 border-none px-2 py-1 text-sm focus:outline-none `}
        type="number"
        value={displayDuration}
        onWheel={(e) => e.currentTarget.blur()}
        min="0"
        pattern="^-?[0-9]\d*\.?\d*$"
        onChange={(e) => {
          setDisplayDuration(e.target.value);
          const newVal = parseFloat(e.target.value);
          if (!Number.isNaN(newVal)) {
            updateValue(convertRawTimeFromUnitToSeconds(newVal, timeValue.unit));
          } else updateValue(0);
        }}
      />
      <SimTimeUnitSelector
        className="w-32 bg-white py-1 pl-3 pr-2 text-sm"
        unit={timeValue.unit}
        onUpdate={(updated) => {
          const newDurationVal = convertRawTimeFromUnitToSeconds(
            parseInt(displayDuration, 10),
            updated
          );
          update({ value: newDurationVal, unit: updated });
        }}
        headlessStyle
      />
    </div>
  );
};

export default TimeValueInput;
