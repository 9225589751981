import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { UserProvider } from "Components/Providers/User/UserProvider";
import { useGetCurrentUser } from "api/user/getCurrentUser";
import { Suspense, useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useGlobalDispatch, useGlobalState } from "store";
import OrganizationSlugNotFound from "../OrganizationSlugNotFound";

const ProtectedRoute = () => {
  const {
    data: userData,
    loading: fetchingUser,
    error: failedToFetchUser,
  } = useGetCurrentUser();

  const user = useMemo(() => userData?.me, [userData?.me]);

  const dispatch = useGlobalDispatch();
  const location = useLocation();
  const { authStatus } = useGlobalState();
  const organizationId = useActiveOrganizationId();
  const organizationSlug = useOrganizationSlugFromParams();
  const matchingOrganizationSlug = useMemo(
    () => user?.organizations.some(({ slug }) => slug === organizationSlug),
    [organizationSlug, user?.organizations]
  );
  if (authStatus === "pending" || fetchingUser) return <FullScreenLoadingOverlay />;
  if (authStatus === "error") {
    console.error("Authstatus error", { authStatus });
    return <Navigate to="/login" replace />;
  }

  if (failedToFetchUser && authStatus === "authenticated") {
    dispatch({ type: "SET_AUTH_STATUS", payload: "error" });
    console.error("Failed to fetch user", { failedToFetchUser });
    return <Navigate to="/login" replace />;
  }

  if (!user || authStatus === "not-authenticated") {
    console.error(
      "User was null/undefined or authstatus was not-authenticated, redirecting to login",
      { user, authStatus, fetchingUser, userData }
    );
    return (
      <Navigate
        to="/login"
        state={{
          navigateTo:
            location.pathname !== "/" && location.pathname !== "/login"
              ? location.pathname
              : undefined,
        }}
        replace
      />
    );
  }

  if (organizationSlug && !matchingOrganizationSlug) return <OrganizationSlugNotFound />;

  const activeOrganizationUserRole = user.organizationMemberships.find(
    ({ organization }) => organization.id === organizationId
  )?.role;

  return (
    <Suspense fallback={<FullScreenLoadingOverlay />}>
      <UserProvider activeOrganizationRole={activeOrganizationUserRole}>
        <Outlet />
      </UserProvider>
    </Suspense>
  );
};

export default ProtectedRoute;
