import { OrganizationMemberRole } from "./GraphQL/gql/graphql";

export const LATEST_SCENARIO_VERSION = 2;
export const OPTIMIZATION_SYSTEM_PARAMETERS_COMP_UUID = "scenario_parameters";
export const CONVERTED_FROM_MAP_STR = "__CONVERTED_FROM_MAP";
export const ROLE_OPTIONS = [
  {
    id: OrganizationMemberRole.Developer,
    display: "Developer",
    description: "Developers have no restrictions in the workspace.",
    hierarchy: [
      OrganizationMemberRole.Simulator,
      OrganizationMemberRole.Guest,
      OrganizationMemberRole.PortfolioUser,
      OrganizationMemberRole.PortfolioManager,
    ], // Lists user roles that are below in the hierarchy. Mainly used to determine available user roles when inviting new users.
  },
  {
    id: OrganizationMemberRole.Simulator,
    display: "Simulator",
    description:
      "Simulators can view results, reports and run simulations from projects they created themselves.",
    hierarchy: [OrganizationMemberRole.Guest],
  },
  {
    id: OrganizationMemberRole.Guest,
    display: "Guest",
    description: "Guests can view results and reports from projects they are invited to.",
    hierarchy: [],
  },
  {
    id: OrganizationMemberRole.PortfolioUser,
    display: "Dashboard user",
    description:
      "Dashboard users can only access the dashboard page with restricted access to editing individual dashboards.",
    hierarchy: [],
  },
  {
    id: OrganizationMemberRole.PortfolioManager,
    display: "Dashboard manager",
    description:
      "Dashboard managers has the same access rights as Dashboard users but with additional edit rights.",
    hierarchy: [OrganizationMemberRole.PortfolioUser],
  },
  {
    id: OrganizationMemberRole.Owner,
    display: "Owner",
    description:
      "Owners have no restrictions, they can even edit their organization customizations.",
    hierarchy: [
      OrganizationMemberRole.Developer,
      OrganizationMemberRole.Simulator,
      OrganizationMemberRole.Guest,
      OrganizationMemberRole.PortfolioUser,
      OrganizationMemberRole.PortfolioManager,
      OrganizationMemberRole.Owner,
    ],
  },
];
