import Badge from "Components/Basic/Badge";
import { Button } from "Components/Basic/Buttons";
import { TextArea } from "Components/Basic/Input";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import ToggleContainer from "Components/Basic/ToggleContainer/ToggleContainer";
import { useTriggerPortfolioUpdate } from "Components/Projects/api/triggerPortfolioUpdate";
import { MoveProjectToWorkspace } from "Components/Workspace/Projects/MoveProjectToWorkspace";
import { useUpdateProjectDescription } from "api/project/updateProjectDescription";
import { useUpdateProjectName } from "api/project/updateProjectName";
import { useGetUser } from "api/userManagement/getUser";
import { useGetWorkspace } from "api/workspace/getWorkspace";
import { useUpdateWorkspaceOfProject } from "api/workspace/updateWorkspaceOfProject";
import { Project } from "model/datatypes";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGlobalState } from "store";

type Props = {
  project: Project;
};

const ProjectInformation = ({ project }: Props) => {
  const projectOwnerData = useGetUser(project.ownerId).data?.user;
  const projectOwner = projectOwnerData?.__typename === "User" ? projectOwnerData : undefined;
  const { activeWorkspaceId } = useGlobalState();

  const { data, loading: fetchingWorkspace } = useGetWorkspace({
    workspaceId: project.workspace_id || null,
  });
  const [updateProjectName, { loading: updatingProjectName }] = useUpdateProjectName();
  const [updateProjectDescription, { loading: updatingProjectDescription }] =
    useUpdateProjectDescription();

  const [editingProjectName, setEditingProjectName] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState<string>(project.projectName);
  const triggerUpdate = useTriggerPortfolioUpdate({ projectID: project.id });

  const [editingDescription, setEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState<string>(
    project.description || ""
  );

  const [transferProjectToNewWorkspace] = useUpdateWorkspaceOfProject({
    currentWorkspaceId: activeWorkspaceId,
  });

  useEffect(() => {
    setEditedProjectName(project.projectName);
  }, [project.projectName]);

  return (
    <ToggleContainer
      contentId={`scenarioInformation_${project.id}`}
      toggledByDefault
      title="Information"
    >
      <dl className="grid grid-cols-6">
        <div className="col-span-full border-r border-b p-4 lg:col-span-4">
          <div className="flex items-center justify-between">
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            {!editingProjectName && (
              <Button
                onClick={() => setEditingProjectName(true)}
                className="pr-2 text-sm leading-4 text-indigo-700"
                buttonType="small"
                data-testid="editProjectNameButton"
              >
                Edit name
              </Button>
            )}
          </div>
          {editingProjectName ? (
            <div className="mt-2 flex items-center">
              <TextArea
                data-testid="scenarioProjectNameInput"
                className="w-full text-sm"
                value={editedProjectName}
                rows={3}
                onChange={(e) => {
                  setEditedProjectName(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  updateProjectName({
                    variables: { name: editedProjectName, projectId: project.id },
                  })
                    .catch((err) => {
                      console.error(err);
                      toast.error("The project name could not be changed");
                    })
                    .finally(() => setEditingProjectName(false));

                  triggerUpdate();
                }}
                buttonType="white"
                loading={updatingProjectName}
                className="ml-1"
                data-testid="updateProjectNameButton"
              >
                Update
              </Button>
            </div>
          ) : (
            <dd className="mt-1 text-sm truncate" data-testid="projectInformationName">
              {editedProjectName || (
                <Badge backgroundColor="bg-gray-200" textColor="text-gray-800" text="N/A" />
              )}
            </dd>
          )}
        </div>
        <div className="col-span-full border-b p-4 lg:col-span-2">
          <div className="flex items-center justify-between">
            <dt className="text-sm font-medium text-gray-500">Workspace</dt>
            <MoveProjectToWorkspace
              triggerButton={
                <Button
                  data-testid="moveProjectButton"
                  className="pr-2 text-sm leading-4 text-indigo-700"
                  buttonType="small"
                >
                  Move
                </Button>
              }
              projectWorkspaceID={project.workspace_id}
              updateWorkspaceOfResourceHandler={(workspaceID) => {
                transferProjectToNewWorkspace({
                  variables: {
                    projectId: project.id,
                    workspaceId: workspaceID,
                  },
                }).catch((err) => {
                  console.error(err);
                  toast.error("The project could not be moved to another workspace");
                });
              }}
            />
          </div>
          <span>
            {fetchingWorkspace ? (
              <LoadingIcon />
            ) : (
              <dd className="mt-1 text-sm h-5" data-testid="projectCurrentWorkspaceName">
                {data?.workspace.__typename === "GeneralWorkspace" ? (
                  <span>General</span>
                ) : (
                  <span>{data?.workspace.name}</span>
                )}
              </dd>
            )}
          </span>
        </div>
        <div className="col-span-full lg:border-r p-4 lg:col-span-4 border-b lg:border-b-0">
          <div className="flex items-center justify-between">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            {!editingDescription && (
              <Button
                data-testid="editProjectDescriptionButton"
                onClick={() => setEditingDescription(true)}
                className="pr-2 text-sm leading-4 text-indigo-700"
                buttonType="small"
              >
                Edit description
              </Button>
            )}
          </div>
          {editingDescription ? (
            <div className="mt-2 flex items-center">
              <TextArea
                data-testid="scenarioDescriptionInput"
                className="w-full text-sm"
                value={editedDescription}
                rows={3}
                onChange={(e) => {
                  setEditedDescription(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  updateProjectDescription({
                    variables: {
                      projectId: project.id,
                      description: editedDescription,
                    },
                  })
                    .catch((err) => {
                      console.error(err);
                      toast.error("The description could not be updated");
                    })
                    .finally(() => {
                      setEditingDescription(false);
                    });
                }}
                buttonType="white"
                className="ml-1"
                loading={updatingProjectDescription}
                data-testid="updateDescriptionButton"
              >
                Update
              </Button>
            </div>
          ) : (
            <span data-testid="projectDescription" className="mt-1 text-sm text-gray-900">
              {project.description ? (
                project.description
              ) : (
                <span className="text-sm italic">No description</span>
              )}
            </span>
          )}
        </div>
        <div className="col-span-full border-b lg:border-b-0 p-4 lg:col-span-2">
          <div className="flex items-center justify-between">
            <dt className="text-sm font-medium text-gray-500">Created by</dt>
          </div>
          <dd className="mt-1 text-sm text-gray-900">{projectOwner?.fullName}</dd>
        </div>
      </dl>
    </ToggleContainer>
  );
};

export default ProjectInformation;
