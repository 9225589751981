import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { useGetAllScheduledDataSets } from "api/dataSet/getAllScheduledDataSets";
import { SelectOption } from "model/datatypes";
import React, { useMemo } from "react";

type Props = {
  selectedId: string | undefined;
  onAddNew: () => void;
  onSelect: (selected: SelectOption) => void;
  newDataSetName: string;
};

export const ScheduleDataSetOptions = ({
  selectedId,
  onAddNew,
  onSelect,
  newDataSetName,
}: Props) => {
  const allScheduledDataSets = useGetAllScheduledDataSets();
  const scheduleDatasetOptions: SelectOption[] = useMemo(() => {
    let tempOptions: SelectOption[] = [];
    if (newDataSetName) tempOptions.push({ id: newDataSetName, display: newDataSetName });
    allScheduledDataSets.forEach((option) => {
      const dataSetOptionIsAdded = tempOptions.some(({ id }) => id === option.id);
      if (!dataSetOptionIsAdded)
        tempOptions.push({
          id: option.id,
          display: option.name,
          value: option,
        });
    });

    return tempOptions;
  }, [allScheduledDataSets, newDataSetName]);

  return (
    <div className="flex w-1/3 flex-col text-sm">
      <div className="mb-2 text-sm font-medium">Dataset</div>
      <Dropdown
        options={scheduleDatasetOptions}
        onSelect={(selected) => onSelect(selected)}
        selectedID={selectedId}
        onAddNew={onAddNew}
      />
    </div>
  );
};
