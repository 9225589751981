import { Download, Link as LinkIcon, Trash } from "@phosphor-icons/react";
import { ConfirmModal } from "Components/Basic/ConfirmModal";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useDeleteGroup } from "api/group/deleteGroup";
import { GetGroup } from "api/group/getGroup";
import { useHasEditAccess } from "api/useFirebase";
import { AnimatePresence } from "framer-motion";
import { Project } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import useCopyToClipboard from "utils/clipboard/copyToClipboard";

type Props = {
  project: Project;
  group: GetGroup | undefined;
  showArchivedScenarios: boolean;
  toggleShowArchivedScenarios: () => void;
};

const GroupOptions = ({
  project,
  group,
  showArchivedScenarios,
  toggleShowArchivedScenarios,
}: Props) => {
  const [deleteGroup, { loading: deletingGroup }] = useDeleteGroup();
  const [showDeletingGroupModal, setShowDeletingGroupModal] = useState(false);
  const hasGroupEditAccess = useHasEditAccess(
    group?.createdBy.__typename === "User" ? group.createdBy.id : ""
  );
  const organizationSlug = useOrganizationSlugFromParams();
  const [, copyToClipboard] = useCopyToClipboard({ itemTypeToCopy: "The link" });
  const copyGroupLinkToClipboard = () => {
    const link = `${window.location.protocol}//${window.location.host}/${organizationSlug}/project/${project.id}/`;
    copyToClipboard(link, true);
  };

  return (
    <>
      <PopoverMenu placement="left-start">
        <Popover.Button
          onClick={() => copyGroupLinkToClipboard()}
          icon={<LinkIcon width="100%" height="100%" />}
          data-testid="copyGroupLink"
        >
          Copy link to group
        </Popover.Button>

        <Popover.Button
          onClick={() => toggleShowArchivedScenarios()}
          icon={<Download width="100%" height="100%" />}
        >
          <span>{showArchivedScenarios ? "Hide" : "Show"} archived scenarios</span>
        </Popover.Button>

        <hr />

        <Popover.Button
          className="text-red-500"
          data-testid="deleteGroup"
          icon={<Trash width="100%" height="100%" />}
          onClick={() => {
            if (group?.scenarios && group.scenarios.length > 0) {
              toast.error("Only empty groups can be deleted");
            } else if (!hasGroupEditAccess) {
              toast.error("You do not have permission to delete this group");
            } else {
              setShowDeletingGroupModal(true);
            }
          }}
        >
          Delete group
        </Popover.Button>
      </PopoverMenu>

      <AnimatePresence>
        {showDeletingGroupModal && (
          <ConfirmModal
            open={showDeletingGroupModal}
            onCancel={() => {
              setShowDeletingGroupModal(false);
            }}
            warning
            loading={deletingGroup}
            onConfirm={() => {
              if (group?.scenarios && group.scenarios.length === 0 && project.id) {
                deleteGroup({ variables: { groupId: group.id, projectId: project.id } });
              }
              setShowDeletingGroupModal(false);
            }}
            headline="Delete group?"
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default GroupOptions;
