import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import AnimateLoadingButtonInlay from "Components/Basic/AnimateLoadingButtonInlay";
import { Button } from "Components/Basic/Buttons";
import TextArea from "Components/Basic/Form/TextArea";
import TextInput from "Components/Basic/Form/TextInput";
import { useCreateWorkspace } from "api/workspace/createWorkspace";
import { Form, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { useGlobalDispatch } from "store";
import * as Yup from "yup";
import AnimatePageTransition from "../../Pages/utils/AnimatePageTransition";

type FormProps = {
  workspaceName: string;
  workspaceDescription: string;
};

const validationSchema = Yup.object({
  workspaceName: Yup.string()
    .required("Name is required")
    .trim()
    .min(1, "Name must be at least one character long"),
});

const AddNewWorkspace: React.FC = () => {
  const navigate = useNavigateApp();
  const dispatch = useGlobalDispatch();

  const [createWorkspace, { loading: creatingWorkspace }] = useCreateWorkspace();

  const submitForm = async (values: FormProps) => {
    const createdWorkspace = await createWorkspace({
      variables: {
        name: values.workspaceName,
        description: values.workspaceDescription,
      },
    });
    const newWorkspaceId = createdWorkspace.data?.workspaceCreate.id;

    if (createdWorkspace.errors) {
      console.error(createdWorkspace.errors);
      toast.error("The workspace could not be created");
    }

    // Update the users current workspace to be displayed
    if (newWorkspaceId) {
      dispatch({ type: "SET_ACTIVE_WORKSPACE_ID", payload: newWorkspaceId });
    }

    navigate("/workspaces", { replace: true });
  };

  return (
    <AnimatePageTransition title="Add a new workspace">
      <Formik
        initialValues={{
          workspaceName: "",
          workspaceDescription: "",
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form
            data-testid="signupForm"
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <div className="mb-4 w-full">
              <div className="w-5/12 min-w-[30rem] space-y-4">
                <div>
                  <TextInput type="text" label="Name" name="workspaceName" autoFocus />
                </div>
                <div>
                  <TextArea label="Description" name="workspaceDescription" />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <Button
                type="submit"
                data-testid="addNewWorkspaceButton"
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                buttonType="primary"
                loading={creatingWorkspace}
              >
                Add workspace
                <AnimateLoadingButtonInlay showLoading={false} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AnimatePageTransition>
  );
};

export default AddNewWorkspace;
