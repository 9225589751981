import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import toast from "react-hot-toast";
import useCopyToClipboard from "./copyToClipboard";

/**
 * A hook for copying project link to clipboard, can both have projectID passed in when invoking the hook or when calling the function
 * If you pass in ID when calling the function, that ID will prioritize over the one passed in the hook
 * @param props Object with projectID field OR undefined
 * @returns copyProjectLinkToClipboard in Object
 *
 * @example
 * const { copyProjectLinkToClipboard } = useCopyProjectLinkToClipboard({projectID: "project1"});
 * copyProjectLinkToClipboard(); // Copies a link for project with id "project1"
 * copyProjectLinkToClipboard("project2"); // Copies a link for project with id "project2", even though the hook was invoked with another project
 */
export const useCopyProjectLinkToClipboard = (props: { projectID?: string } = {}) => {
  const [, copyToClipboard] = useCopyToClipboard({ itemTypeToCopy: "Project link" });
  const organizationSlug = useOrganizationSlugFromParams();

  const copyProjectLinkToClipboard = (projectID?: string) => {
    if (!projectID && !props.projectID)
      toast.error("Error copying project, the ID of the project wasn't provided");
    const id = projectID || props.projectID;

    copyToClipboard(
      `${window.location.protocol}//${window.location.host}/${organizationSlug}/project/${id}`,
      true
    );
  };
  return { copyProjectLinkToClipboard };
};
