import { TimeslotInput } from "GraphQL/gql/graphql";
import { v4 as uuid } from "uuid";

//Inserts new timeslot into previous and makes sure no previous values overlap.
export const getCleanTimeslots = (timeslots: TimeslotInput[], newTimeslot: TimeslotInput) => {
  const cleanTSStart: TimeslotInput[] = [];

  const cleanTS = timeslots.reduce((prev, cur) => {
    //if cur is the one being updated:
    if (cur.id === newTimeslot.id) return prev;

    const isCurrentStartTimeWithinNewTimeSlot = cur.startTime.isBetween(
      newTimeslot.startTime,
      newTimeslot.endTime
    );

    const isCurrentEndTimeWithinNewTimeSlot = cur.endTime.isBetween(
      newTimeslot.startTime,
      newTimeslot.endTime
    );

    //if cur is between newTS completely, remove it
    if (isCurrentStartTimeWithinNewTimeSlot && isCurrentEndTimeWithinNewTimeSlot) return prev;

    //if cur overlaps newTS completely, split it into two
    if (
      cur.startTime.isBefore(newTimeslot.startTime) &&
      cur.endTime.isAfter(newTimeslot.endTime)
    )
      return [
        ...prev,
        { ...cur, id: uuid().replace(/-/gi, "_"), endTime: newTimeslot.startTime },
        { ...cur, startTime: newTimeslot.endTime },
      ];

    //If overlap only one time with new TS:
    if (isCurrentStartTimeWithinNewTimeSlot)
      return [...prev, { ...cur, startTime: newTimeslot.endTime.clone() }];

    if (isCurrentEndTimeWithinNewTimeSlot)
      return [...prev, { ...cur, endTime: newTimeslot.startTime.clone() }];

    //no overlap..
    return [...prev, cur];
  }, cleanTSStart);
  const res = [...cleanTS, newTimeslot];
  return res;
};
