import { ArrowLeft } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { motion } from "framer-motion";
import React from "react";

interface Props {
  title: string;
}

const AnimatePageTransition: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  const navigate = useNavigateApp();

  return (
    <motion.div
      className="flex h-full flex-col justify-center px-8 py-20"
      initial={{ opacity: 0, x: 8 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -8 }}
    >
      <button
        data-testid="backButton"
        onClick={() => navigate(-1)}
        className="mb-4 flex w-16 items-center text-indigo-900"
      >
        <ArrowLeft className="mr-1" weight="bold" />
        <span className="font-base">Back</span>
      </button>

      <div className="mb-4 text-4xl font-medium text-gray-800">{title}</div>
      {children}
    </motion.div>
  );
};

export default AnimatePageTransition;
