import LoadingOverlay from "Components/Basic/LoadingOverlay";
import { useGroups } from "api/group/getGroups";
import { Project } from "model/datatypes";
import toast from "react-hot-toast";
import ProjectGroup from "./ProjectGroup";

type Props = {
  project: Project;
};

const ProjectGroups = ({ project }: Props) => {
  const { data, loading, error } = useGroups(project.id);

  if (error) {
    toast.error(error.message);
    console.error(error);
  }
  return (
    <div data-testid="projectGroups">
      {data &&
        data?.groups.map((group) => (
          <ProjectGroup project={project} key={group.id} group={group} />
        ))}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default ProjectGroups;
