import { OrganizationMemberRole } from "GraphQL/gql/graphql";
import { useGetPendingOrganizationInvitations } from "api/organisation/getPendingOrganizationInvitations";
import { useUserInvitationCreate } from "api/userManagement/createUserInvitation";
import { useGetAllUsersInOrganization } from "api/userManagement/getAllUsersInOrganisation";
import { useEffect } from "react";
import toast from "react-hot-toast";

type FormProps = {
  newUserEmail: string;
  userRoleOption: {
    id: OrganizationMemberRole;
    display: string;
    description: string;
  };
};

export const useInviteNewUser = () => {
  const [sendInvitation, { loading: sendingInvitation }] = useUserInvitationCreate();

  const { data: { organization } = {} } = useGetPendingOrganizationInvitations();

  const { data, fetchMore } = useGetAllUsersInOrganization({ first: 30 });

  useEffect(() => {
    const canFetchMoreUsers = data?.organization.members.pageInfo.hasNextPage;
    const endCursor = data?.organization.members.pageInfo.endCursor;

    if (canFetchMoreUsers && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    fetchMore,
    data?.organization.members.pageInfo.endCursor,
    data?.organization.members.pageInfo.hasNextPage,
  ]);

  const usersByOrganization = data?.organization.members.edges;

  const sendAutomatedInvite = async (values: FormProps) => {
    try {
      if (usersByOrganization?.some(({ node }) => node.user.mail === values.newUserEmail)) {
        toast.error("A user with this email is already in the organization");
        return;
      }
      if (
        organization?.pendingInvitations?.some(
          (invitation) => invitation.email === values.newUserEmail
        )
      ) {
        toast.error("An existing invitation is using this email");
        return;
      }

      const result = await sendInvitation({
        variables: {
          email: values.newUserEmail,
          role: values.userRoleOption.id,
        },
      });
      if (!result.data) throw new Error("No results from graphql mutation");
      toast.success(`An invitation has been sent`);
    } catch (error) {
      console.error(error);
      toast.error("An error ocurred. No invitation has been sent");
    }
  };

  return { sendAutomatedInvite, sendingInvitation };
};
