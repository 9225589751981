import { useField, FieldHookConfig } from "formik";
import { motion } from "framer-motion";
import React from "react";

interface Props {
  label?: string;
  readOnly?: boolean;
  maxHeight?: string;
}

const TextArea = ({
  label,
  readOnly,
  maxHeight = "",
  ...props
}: Props & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label && (
        <label
          data-testid="formLabel"
          className="block text-sm font-medium text-gray-700"
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
      )}
      <textarea
        readOnly={readOnly}
        className={`block w-full appearance-none ${maxHeight} rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
        {...field}
        data-testid="formTextInput"
      />
      {meta.touched && meta.error ? (
        <motion.div
          initial={{ y: -4, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          data-testid="formErrorMessage"
          className="mt-1 text-sm font-medium text-red-700"
        >
          {meta.error}
        </motion.div>
      ) : null}
    </>
  );
};

export default TextArea;
