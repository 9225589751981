import { Calendar, Database, Plus, Trash } from "@phosphor-icons/react";
import { DataSetViewer } from "Components/Input/DataSetViewer";
import { DataSetPageNode } from "api/dataSet/getPaginatedDataSets";
import { AnimatePresence } from "framer-motion";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import { sortAlphabetical } from "utils/jsUtils/sortAlphabetical";
import { useDeleteDataSetSchedule } from "../../api/dataSet/deleteSchedule";
import { AddScheduleModal } from "./AddScheduleModal";
import DataSetOptions from "./DataSetOptions";
import { ScheduleInputModal } from "./ScheduleInputModal";

type Props = {
  dataSet: DataSetPageNode;
};

const DataSet = ({ dataSet }: Props) => {
  const [editingSchedule, setEditingSchedule] = useState(false);
  const [addingSchedule, setAddingSchedule] = useState(false);
  const [viewDataSetData, setViewDataSetData] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");

  const [deleteDataSetSchedule] = useDeleteDataSetSchedule();

  const isValidDataSet =
    dataSet &&
    (dataSet?.__typename === "ScheduledDataSet" || dataSet?.__typename === "StaticDataSet");

  const sortedTags = useMemo(() => {
    return [...dataSet.tags].sort(sortAlphabetical);
  }, [dataSet]);
  const amountOfTags = sortedTags.length;

  const isScheduledDataSet = dataSet?.__typename === "ScheduledDataSet";

  return (
    <div data-testid="dataset">
      <div className="rounded-lg bg-white shadow-md">
        <div className="border-b">
          <div className="flex items-center justify-between p-3 px-5">
            <div className="flex h-8 w-2/4 items-center">
              {isScheduledDataSet ? (
                <Calendar size={20} weight="regular" />
              ) : (
                <Database size={20} weight="regular" />
              )}
              <div className="ml-2 truncate text-base font-medium text-gray-700">
                {dataSet.name}
                {isScheduledDataSet && (
                  <div className="text-sm">
                    {amountOfTags === 1
                      ? `${amountOfTags} schedule in dataset`
                      : `${amountOfTags} schedules in dataset`}
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-2/4 items-center justify-end">
              <div
                className="mr-3  cursor-pointer text-sm font-medium text-indigo-600"
                onClick={() => setViewDataSetData(true)}
              >
                View {amountOfTags} tag{amountOfTags === 1 ? "s" : ""}
              </div>
              <div>
                <DataSetOptions dataSet={dataSet} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-40 overflow-y-auto">
          {isScheduledDataSet && (
            <>
              <div>
                {sortedTags.map((tag) => (
                  <div
                    key={tag}
                    onClick={() => {
                      setSelectedTag(tag);
                      setEditingSchedule(true);
                    }}
                    className="flex cursor-pointer items-center justify-between border-b px-3 py-2 text-sm hover:bg-indigo-50"
                  >
                    <div className="capitalize text-gray-700">{tag}</div>
                    <PopoverMenu iconSize={14}>
                      <Popover.Button
                        icon={<Trash width="100%" height="100%" className="text-red-600" />}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteDataSetSchedule({
                            variables: {
                              dataSetId: dataSet.id,
                              projectId: dataSet.projectId,
                              scheduleTag: tag,
                            },
                          }).catch((err) => {
                            toast.error("The schedule could not be deleted");
                            console.error(err);
                          });
                        }}
                      >
                        Delete
                      </Popover.Button>
                    </PopoverMenu>
                  </div>
                ))}
              </div>
              <div
                onClick={() => setAddingSchedule(true)}
                className="flex cursor-pointer items-center justify-center border-b bg-gray-50 px-3 py-2 text-sm font-medium text-indigo-800 hover:bg-indigo-50"
              >
                <Plus size={12} weight="bold" className="mr-1 text-indigo-800" />{" "}
                <div className="">Add schedule</div>
              </div>
            </>
          )}

          {!isScheduledDataSet && (
            <div>
              {sortedTags.map((tag) => (
                <div
                  key={tag}
                  onClick={() => {
                    setSelectedTag(tag);
                    setViewDataSetData(true);
                  }}
                  className="flex cursor-pointer items-center justify-between border-b px-3 py-2 text-sm capitalize text-gray-700 hover:bg-indigo-50"
                >
                  {tag}
                </div>
              ))}
            </div>
          )}

          {!amountOfTags && (
            <div className="mt-16 text-center text-sm text-gray-500">
              No {isScheduledDataSet ? "schedules" : "tags"} in this dataset
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {viewDataSetData && isValidDataSet && (
          <DataSetViewer
            dataSet={dataSet}
            onFinish={() => {
              setViewDataSetData(false);
              setSelectedTag("");
            }}
            selectedTag={selectedTag}
          />
        )}
        {isScheduledDataSet && addingSchedule && (
          <AddScheduleModal
            dataSetId={dataSet.id}
            closeModal={() => setAddingSchedule(false)}
            projectId={dataSet.projectId}
          />
        )}
        {isScheduledDataSet && editingSchedule && (
          <ScheduleInputModal
            closeModal={() => setEditingSchedule(false)}
            dataSetId={dataSet.id}
            editingSchedule={selectedTag}
            projectId={dataSet.projectId}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default DataSet;
