import App from "App";
import FilePage from "Components/Files/FilePage";
import { FullScreenLoadingOverlay } from "Components/FullScreenLoadingOverlay/FullScreenLoadingOverlay";
import LoginPage from "Components/Login/LoginPage";
import { PerformPasswordReset } from "Components/Login/PasswordReset/PerformPasswordReset/PerformPasswordReset";
import { CreateOrganization } from "Components/Pages/CreateOrganization";
import { ErrorBoundary } from "Components/Pages/ErrorBoundary/GlobalErrorBoundaryPage";
import InviteNewUser from "Components/Pages/InviteNewUser";
import ProjectPage from "Components/Pages/ProjectPage";
import DashboardProtectedRoute from "Components/Pages/ProtectedRoute/Dashboard/DashboardProtectedRoute";
import ProtectedRoute from "Components/Pages/ProtectedRoute/ProtectedRoute";
import ToolsOverview from "Components/Pages/ToolsPage";
import { AddRepository } from "Components/Repositories/AddRepository/AddRepository";
import { RepositoriesOverview } from "Components/Repositories/Overview/RepositoriesOverview";
import { RepositoryPage } from "Components/Repositories/Repository/RepositoryPage";
import { ActivateNewUser } from "Components/SignUp/ActivateNewUser/ActivateNewUser";
import { UserSignUp } from "Components/SignUp/UserSignUp";
import AddNewWorkspace from "Components/Workspace/Administration/AddNewWorkspace";
import React, { Suspense, lazy } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Protected } from "../Pages/ProtectedRoute/Protected";
import { AddToolFromClipboard } from "../Systems/AddToolFromClipboard";

const ReportViewPage = lazy(() => import("Components/Pages/ReportViewPage"));
const WorkspaceOverview = lazy(() => import("Components/Workspace/WorkspaceOverview"));

const AddNewJob = lazy(
  () => import("Components/Systems/SystemBuilder/JobsComponents/AddNewJob")
);
const LinkPage = lazy(() => import("Components/Pages/LinkPage"));
const ScenarioPage = lazy(() => import("Components/Pages/ScenarioPage"));
const OptimizationResultPage = lazy(() => import("Components/Pages/OptimizationResult/index"));
const PageNotFound = lazy(() => import("Components/Pages/PageNotFound"));
const DashboardPage = lazy(() => import("Components/Portfolio/Dashboard/Dashboard"));
const PortfolioDashboard = lazy(() => import("Components/Pages/PortfolioDashboardPage"));
const CreatePortfolio = lazy(
  () => import("Components/Portfolio/Overview/CreatePortfolioPage")
);
const AddDashboard = lazy(() => import("Components/Portfolio/Dashboard/AddDashboard"));
const AddNewProject = lazy(() => import("Components/Pages/AddNewProject/AddNewProject"));
const CreateScenarioFromTool = lazy(() => import("Components/Tools/CreateScenarioFromTool"));
const AddNewScenario = lazy(() => import("Components/Group/AddNewScenario"));
const ProjectTemplatePage = lazy(
  () => import("Components/Pages/ProjectTemplate/ProjectTemplatePage")
);
const AddNewProjectTemplate = lazy(
  () => import("Components/ProjectTemplates/AddNewProjectTemplate")
);
const SelectOrganization = lazy(() => import("Components/Pages/SelectOrganization"));

const SettingsPage = lazy(() => import("Components/Pages/Settings/SettingsPage"));
const AccountSettings = lazy(
  () => import("Components/Pages/Settings/Account/AccountSettings")
);
const PreferencesSettings = lazy(
  () => import("Components/Pages/Settings/Preferences/PreferencesSettings")
);
const TutorialsSettings = lazy(
  () => import("Components/Pages/Settings/Tutorials/TutorialsSettings")
);
const IntegrationsSettings = lazy(
  () => import("Components/Pages/Settings/Integrations/IntegrationsSettings")
);
const GitlabIntegrationSettings = lazy(
  () => import("Components/Pages/Settings/Integrations/GitlabIntegrationSettings")
);
const AdvancedSettings = lazy(
  () => import("Components/Pages/Settings/Advanced/AdvancedSettings")
);

const EditSystemPage = lazy(() => import("Components/Systems/editSystem/EditSystemPage"));
const AddNewTool = lazy(() => import("Components/Systems/AddNewTool"));
const SystemBuilderPage = lazy(
  () => import("Components/Systems/SystemBuilder/SystemBuilderPage")
);
const OrganizationPage = lazy(() => import("Components/Pages/Organization/OrganizationPage"));
const OrganizationMembers = lazy(
  () => import("Components/Pages/Organization/OrganizationMembers")
);
const OrganizationSettings = lazy(
  () => import("Components/Organisation/Settings/GeneralOrganizationSettings")
);
const OrganizationCredit = lazy(
  () => import("Components/Organisation/Credits/OrganizationCredit")
);
const OrganizationSubscription = lazy(
  () => import("Components/Organisation/Subscription/SubscriptionPage")
);
const OrganizationInvitationPage = lazy(
  () => import("Components/Pages/Organization/OrganizationInvitationPage")
);
const ToolsGalleryPage = lazy(() => import("Components/Tools/ToolsGallery"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="password-reset/:resetToken" element={<PerformPasswordReset />} />
      <Route path="signup" element={<UserSignUp />} />
      <Route path="signup/:invitationToken" element={<UserSignUp />} />
      <Route path="activate-account/:activationToken" element={<ActivateNewUser />} />
      <Route path="login" element={<LoginPage />} />
      {/* For public reports */}
      <Route path="links/:linkID" element={<LinkPage />} />

      <Route element={<ProtectedRoute />} errorElement={<ErrorBoundary />}>
        <Route path="/" element={<SelectOrganization />} />
        <Route path=":organizationSlug" element={<App />}>
          {/* SIMULATOR ACCESS */}
          <Route element={<Protected condition="hasSimulatorAccess" />}>
            <Route index element={<ToolsGalleryPage />} />
          </Route>

          <Route element={<Protected condition="hasDeveloperAccess" />}>
            <Route path="tools" element={<ToolsOverview />} />
            <Route path="tools/:simModelID" element={<EditSystemPage />} />
            <Route path="tools/create-tool" element={<AddNewTool />} />
            <Route
              path="tools/create-tool-from-clipboard"
              element={<AddToolFromClipboard />}
            />
            <Route path="create-project-template" element={<AddNewProjectTemplate />} />
            <Route
              path="tools/system-builder/:simModelID/create-job"
              element={<AddNewJob />}
            />
            <Route path="tools/system-builder/:simModelID" element={<SystemBuilderPage />} />
            <Route
              path="/:organizationSlug/create-project-template"
              element={<AddNewProjectTemplate />}
            />
            <Route
              path="/:organizationSlug/tools/system-builder/:simModelID/create-job"
              element={<AddNewJob />}
            />
            <Route
              path="/:organizationSlug/tools/system-builder/:simModelID"
              element={<SystemBuilderPage />}
            />
            <Route
              path="/:organizationSlug/tools/project-template/:projectTemplateID"
              element={<ProjectTemplatePage />}
            />

            {/* REPOSITORIES */}
            <Route path="repositories" element={<RepositoriesOverview />} />
            <Route path="repositories/:repositoryID" element={<RepositoryPage />} />
            <Route path="repositories/:repositoryID/:commitID" element={<RepositoryPage />} />
            <Route path="repositories/add-repository" element={<AddRepository />} />

            <Route path="files" element={<FilePage />} />
          </Route>

          {/* DASHBOARD */}
          <Route element={<DashboardProtectedRoute />}>
            <Route path="dashboard" element={<PortfolioDashboard />} />
            <Route path="dashboard/:portfolioID/:dashboardID" element={<DashboardPage />} />
            <Route path="dashboard/create-portfolio" element={<CreatePortfolio />} />
            <Route path="dashboard/create-dashboard/:portfolioID" element={<AddDashboard />} />
          </Route>

          {/* ORGANIZATION */}
          <Route element={<Protected condition="hasPortfolioManagerAccess" />}>
            <Route path="organization" element={<OrganizationPage />}>
              <Route path="members" element={<OrganizationMembers />} />
              <Route path="settings" element={<OrganizationSettings />} />
              <Route path="invite-new-user" element={<InviteNewUser />} />

              <Route element={<Protected condition="hasOwnerAccess" />}>
                <Route path="credit" element={<OrganizationCredit />} />
                <Route path="subscription" element={<OrganizationSubscription />} />
              </Route>
            </Route>
          </Route>

          <Route element={<Protected condition="hasProjectAccess" />}>
            {/* WORKSPACE */}
            <Route path="workspaces" element={<WorkspaceOverview />} />
            <Route path="add-workspace" element={<AddNewWorkspace />} />

            {/* PROJECT */}
            <Route path="project/:projectID" element={<ProjectPage />} />
            <Route path="create-project" element={<AddNewProject />} />

            {/* SCENARIO */}
            <Route path="project/:projectID/:scenarioID" element={<ScenarioPage />} />
            <Route
              path="project/:projectID/:scenarioID/optimization-results/:executionID"
              element={<OptimizationResultPage />}
            />
            <Route
              path="reports/:projectID/:scenarioID/:fileID"
              element={<ReportViewPage />}
            />
            <Route path="create-scenario-tool/:toolID" element={<CreateScenarioFromTool />} />
            <Route path="create-scenario/:projectID/:groupID" element={<AddNewScenario />} />
          </Route>

          {/* SETTINGS */}
          <Route element={<Protected condition="hasPortfolioManagerAccess" />}>
            <Route path="settings" element={<SettingsPage />}>
              <Route path="account" element={<AccountSettings />} />
              <Route path="preferences" element={<PreferencesSettings />} />
              <Route path="tutorials" element={<TutorialsSettings />} />
              <Route path="integrations" element={<IntegrationsSettings />}>
                <Route path="gitlab" element={<GitlabIntegrationSettings />} />
              </Route>
              <Route path="advanced" element={<AdvancedSettings />} />
            </Route>
          </Route>

          {/* OTHER */}
          <Route path="links/:linkID" element={<LinkPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="create-organization" element={<CreateOrganization />} />
      </Route>

      <Route
        path=":organizationSlug/invitation/:invitationId"
        element={<OrganizationInvitationPage />}
      />
    </>
  )
);

export const AppRoutes = () => {
  return (
    <Suspense fallback={<FullScreenLoadingOverlay />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
