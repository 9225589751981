import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Current_UserQuery, OrganizationMemberRole } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_CURRENT_USER = graphql(`
  query GET_CURRENT_USER {
    me {
      __typename
      id
      firstName
      lastName
      fullName
      mail
      organizations {
        id
        name
        description
        slug
        description
        deletionTime
        logoUrl
      }
      organizationMemberships {
        role
        organization {
          id
        }
      }
      gitlabIntegration {
        id
        username
      }
      shutDownOrganizations {
        id
        slug
        name
        description
        deletionTime
      }
      preferences {
        hideTutorial
        enableExperimentalFeatures
        disableCustomUiByDefault
      }
    }
  }
`);

export type User = Extract<Get_Current_UserQuery["me"], { __typename: "User" }> & {
  activeOrganizationRole?: OrganizationMemberRole;
};

export type UserOrganization = ArrayElement<User["organizations"]>;

export const useGetCurrentUser = (skip = false) => {
  const result = useQuery(GET_CURRENT_USER, {
    skip,
  });

  return result;
};
