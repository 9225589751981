import { File, FolderSimple } from "@phosphor-icons/react";
import { RepositoryDirectory, RepositoryFile } from "api/repository/getDirectory";
import { format, formatDistance } from "date-fns";
import Tooltip from "thermo/tooltip/Tooltip";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

interface Props {
  item: RepositoryFile | RepositoryDirectory;
  onClick: (selectedItem: RepositoryFile | RepositoryDirectory) => void;
  selectedFile?: RepositoryFile | undefined;
}

const ExplorerRow = ({ item, onClick, selectedFile }: Props) => {
  return (
    <div
      onClick={() => onClick(item)}
      data-testid="explorerRow"
      className={classNames(
        "grid bg-white py-1 select-none hover:bg-gray-50 cursor-pointer",
        selectedFile === item ? "font-semibold" : "font-normal"
      )}
      style={{
        gridTemplateColumns: "8% 36% 36% 20%",
      }}
    >
      <div className="justify-self-center text-gray-500">
        {item.__typename === "RepositoryFile" ? (
          <File weight="light" />
        ) : (
          <FolderSimple weight="light" />
        )}
      </div>
      <Text.Small className="pr-3" truncate>
        {item.name}
      </Text.Small>
      <Text.Small className="pr-3" truncate>
        {item.__typename === "RepositoryFile" && item.lastCommit.__typename === "Commit"
          ? item.lastCommit.title
          : ""}
      </Text.Small>
      <Text.Small>
        {item.__typename === "RepositoryFile" && item.lastCommit.__typename === "Commit" ? (
          <Tooltip label={format(item.lastCommit.committedAt, "HH:mm MMM dd yyyy")}>
            <span className="truncate">
              {formatDistance(item.lastCommit.committedAt, new Date(), { addSuffix: true })}
            </span>
          </Tooltip>
        ) : (
          ""
        )}
      </Text.Small>
    </div>
  );
};

export default ExplorerRow;
