import {
  Root,
  List,
  Trigger as TabTrigger,
  Content as TabsContent,
} from "@radix-ui/react-tabs";
import React from "react";
import Text from "thermo/typography/Typography";

type TriggerProps = {
  value: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

const Trigger = ({ value, children, disabled, onClick, ...dataProps }: TriggerProps) => {
  return (
    <TabTrigger
      {...dataProps}
      value={value}
      disabled={disabled}
      onPointerDown={onClick}
      className="data-[state=active]:border-b-indigo-700 data-[state=active]:text-indigo-700 border-b-2 pb-1 px-3 disabled:cursor-not-allowed"
    >
      <Text.Small className="font-medium">{children}</Text.Small>
    </TabTrigger>
  );
};

type TabsProps = {
  children: React.ReactNode;
  triggers: React.ReactNode;
  defaultValue: string;
};

const Tabs = ({ children, triggers, defaultValue }: TabsProps) => {
  return (
    <Root defaultValue={defaultValue}>
      <List className="flex items-center" data-testid="tabs">
        {triggers}
      </List>
      {children}
    </Root>
  );
};

type ContentProps = {
  value: string;
  children: React.ReactNode;
};

const Content = ({ value, children }: ContentProps) => {
  return <TabsContent value={value}>{children}</TabsContent>;
};

Tabs.Trigger = Trigger;
Tabs.Content = Content;

export default Tabs;
