import Badge from "Components/Basic/Badge";
import { SimulationJob } from "model/datatypes";
import React, { useMemo } from "react";

type Props = {
  job: SimulationJob;
};

const SimulationProgress = ({ job }: Props) => {
  const { running, bgColor, textColor } = useMemo(() => {
    let isRunning = false;
    let colorBg = "bg-indigo-400";
    let colorText = "text-indigo-800";

    switch (job.status?.status) {
      case "finished":
        colorBg = "bg-green-100";
        colorText = "text-green-800";
        break;
      case "failed":
        colorBg = "bg-red-100";
        colorText = "text-red-800";
        break;
      case "Waiting":
        colorBg = "bg-indigo-100";
        colorText = "text-indigo-600";
        break;
      case "requested":
        colorBg = "bg-indigo-100";
        colorText = "text-indigo-600";
        break;
      case "running":
        isRunning = true;
        colorBg = "bg-indigo-100";
        colorText = "text-indigo-600";
        break;
      case "initializing":
        colorBg = "bg-indigo-100";
        colorText = "text-indigo-600";
        break;
      case "Model Initializing":
        colorBg = "bg-indigo-100";
        colorText = "text-indigo-600";
        break;
      case "Environment Initializing":
        colorBg = "";
        colorText = "";
        break;
      case "Equations Assembly":
        colorBg = "bg-indigo-100";
        colorText = "text-indigo-600";
        break;
      case "hibernating":
        colorBg = "bg-gray-100";
        colorText = "text-gray-600";
        break;
      default:
        colorBg = "bg-gray-100";
        colorText = "text-gray-700";
    }
    return { running: isRunning, textColor: colorText, bgColor: colorBg };
  }, [job.status.status]);

  return (
    <>
      {running && (
        <div className="flex items-center pr-4">
          <div className="h-2 w-full rounded-full bg-indigo-200">
            <div
              className={` h-2 rounded-full bg-indigo-600`}
              style={{ width: `${job.status.progress}%` }}
            />
          </div>
          <div className="ml-4 text-gray-500">{job.status.progress?.toFixed(2)}%</div>
        </div>
      )}
      {!running && (
        <div>
          <Badge backgroundColor={bgColor} textColor={textColor} text={job.status.status} />
        </div>
      )}
    </>
  );
};

export default SimulationProgress;
