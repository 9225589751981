import { CaretRight } from "@phosphor-icons/react";
import React from "react";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";

type Props = {
  toggleShowPinned: () => void;
  showPinnedItems: boolean;
};

export const PinnedHeading = ({ toggleShowPinned, showPinnedItems }: Props) => {
  return (
    <div
      data-testid="pinnedSection"
      className="flex text-gray-500 px-2 mb-0.5 py-1 items-center justify-between w-full mt-4"
      onClick={toggleShowPinned}
    >
      <div className="flex items-center">
        <CaretRight
          data-testid="pinnedSectionCollapseIcon"
          size={14}
          className={classNames(showPinnedItems && "rotate-90", "transition-all duration-150")}
        />
        <Text.Base className="ml-2" data-testid="pinnedSectionHeader">
          Pinned
        </Text.Base>
      </div>
    </div>
  );
};
