import TextArea from "Components/Basic/Form/TextArea";
import TextInput from "Components/Basic/Form/TextInput";
import AnimatePageTransition from "Components/Pages/utils/AnimatePageTransition";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useGetUser } from "../../api/userManagement/getUser";
import { SystemModel } from "../../model/datatypes";
import { Alert } from "../../thermo/Alert/Alert";
import { Button } from "../../thermo/button/Button";
import { readFromClipboard } from "../../utils/clipboard/ClipboardContent";
import { useNavigateApp } from "../AppNavigation/useNavigateApp";
import { useAddNewToolFromClipboard } from "./api/addNewToolFromClipboard";

type FormProps = {
  displayName: string;
  description: string | undefined;
};

const validationSchema = Yup.object({
  displayName: Yup.string()
    .required("Name is required")
    .trim()
    .min(1, "Name must be at least on character long"),
});

export const AddToolFromClipboard = () => {
  const navigate = useNavigateApp();
  const [clipboardSystem, setClipboardSystem] = useState<null | SystemModel>(null);
  const [error, setError] = useState(false);
  const { addModelFromClipboard, loading } = useAddNewToolFromClipboard();

  const { data } = useGetUser(clipboardSystem?.ownerId || "");

  useEffect(() => {
    readFromClipboard()
      .then((content) => {
        if (content && content.contentType === "tool") {
          setClipboardSystem(content.content);
          setError(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });
  }, []);

  const submitForm = ({ displayName, description }: FormProps) => {
    if (!clipboardSystem) return;
    addModelFromClipboard(clipboardSystem, displayName, description).then((toolId) => {
      if (!toolId) return;
      navigate(`tools/${toolId}`, { replace: true });
    });
  };

  return (
    <AnimatePageTransition title="Create new tool from clipboard">
      {error ? (
        <Alert
          text="A tool definition could not be read from your clipboard"
          subText="Try to copy the tool definition from the Tools overview page and return to this page."
          type="error"
        />
      ) : (
        <Formik
          initialValues={{
            displayName: "",
            description: "",
          }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form
              onKeyDown={(e) => {
                if (e.code === "Enter") e.preventDefault();
              }}
            >
              <div className="mb-4 w-full">
                <div className="w-5/12 min-w-[30rem] space-y-4">
                  <Alert
                    type="default"
                    text="Previous tool"
                    subText={
                      <div className="flex gap-8">
                        <div>
                          <p className="font-medium">Name</p>
                          <p>{clipboardSystem?.displayName}</p>
                        </div>
                        {data?.user.__typename === "User" && data.user.fullName && (
                          <div>
                            <p className="font-medium">Created by</p>
                            <p>{data.user.fullName}</p>
                          </div>
                        )}
                      </div>
                    }
                  />
                  <div>
                    <TextInput
                      type="text"
                      data-testid="toolName"
                      label="Name"
                      name="displayName"
                      autoFocus
                    />
                  </div>

                  <div>
                    <TextArea
                      data-testid="toolDescription"
                      label="Description"
                      name="description"
                      maxHeight="max-h-32"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="brandColor"
                  size="lg"
                  type="submit"
                  data-testid="submitButton"
                  loading={loading}
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                >
                  Add tool from clipboard
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </AnimatePageTransition>
  );
};
