import React from "react";
import Skeleton from "thermo/skeleton/Skeleton";

export const CommitCardSkeleton = () => {
  return (
    <>
      <Skeleton.TextLine
        data-testid="commitCardSkeletonLine"
        typography="Small"
        width="w-60"
      />

      <Skeleton.TextLine typography="H4" width="w-80" />

      <Skeleton.TextLine typography="Small" width="w-40" />

      <div className="mt-1 gap-1 flex flex-col">
        <Skeleton.TextLine typography="Small" />
        <Skeleton.TextLine typography="Small" />
        <Skeleton.TextLine typography="Small" />
      </div>
    </>
  );
};
