import { ConfirmModal } from "Components/Basic/ConfirmModal";
import { useUpdateWorkspaceOwnership } from "api/workspace/updateWorkspaceOwnership";
import React from "react";
import toast from "react-hot-toast";

type Props = {
  closeModal: () => void;
  newOwnerId: string;
  workspaceId: string;
};

const TransferOwnerModal = ({ closeModal, newOwnerId, workspaceId }: Props) => {
  const [transferWorkspaceOwnership, { loading: transferringOwnership }] =
    useUpdateWorkspaceOwnership();
  return (
    <ConfirmModal
      onCancel={closeModal}
      loading={transferringOwnership}
      onConfirm={() => {
        transferWorkspaceOwnership({
          variables: { newOwnerId, workspaceId },
        })
          .then(closeModal)
          .catch((err) => {
            toast.error("There was an error while transferring ownership");
            console.error(err);
          });
      }}
      confirmBtnText="Transfer ownership"
      headline="Set workspace owner"
      description="Are you sure you want to transfer ownership of this workspace?"
    />
  );
};

export default TransferOwnerModal;
