import LoadingOverlay from "Components/Basic/LoadingOverlay";
import useGetPaginatedDataSets from "api/dataSet/getPaginatedDataSets";
import { useEffect } from "react";
import DataSet from "./DataSet";

type Props = {
  projectID: string;
};

const ProjectDataSets = ({ projectID }: Props) => {
  const { data, error, fetchMore, loading } = useGetPaginatedDataSets({
    first: 5,
    projectId: projectID,
  });

  useEffect(() => {
    const canFetchMoreTeamCardPages = data?.project?.dataSets.pageInfo.hasNextPage;
    if (!canFetchMoreTeamCardPages) return;
    fetchMore({
      variables: {
        after: data?.project?.dataSets.pageInfo.endCursor,
      },
    });
  }, [
    data?.project?.dataSets.pageInfo.hasNextPage,
    data?.project?.dataSets.pageInfo.endCursor,
    fetchMore,
  ]);

  const dataSets = data?.project?.dataSets.edges;

  if (loading) return <LoadingOverlay />;
  if (dataSets?.length === 0 || !dataSets)
    return (
      <div className="py-10 text-center text-sm text-gray-700">
        No datasets in this project
      </div>
    );

  return (
    <div className="relative">
      {error && <p className="pt-2 text-md text-gray-700">Could not retrieve datasets... </p>}

      <div
        data-testid="datasets"
        key="datasets"
        className="grid w-full grid-cols-1 gap-5 lg:grid-cols-2"
      >
        {dataSets.map((edge) => (
          <DataSet key={edge.cursor} dataSet={edge.node} />
        ))}
      </div>

      {loading && <LoadingOverlay />}
    </div>
  );
};

export default ProjectDataSets;
