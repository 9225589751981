import React, { FC } from "react";

interface TagInlineProps {
  tag: { id: string; displayName: string } | string;
  removeTag?: (tagId: string) => void;
}
export const TagInline: FC<TagInlineProps> = ({ tag, removeTag }) => {
  const id = typeof tag === "string" ? tag : tag.id;
  const displayName = typeof tag === "string" ? tag : tag.displayName;
  return (
    <div
      key={id}
      data-testid="inlineTag"
      className={`flexs items-centers mx-1 my-1 w-auto max-w-full whitespace-nowrap rounded-full border-2 border-green-numerous bg-green-numerous px-1 text-sm text-white shadow ${
        removeTag !== undefined
          ? "cursor-pointer transition-all duration-200 hover:border-red-500 hover:bg-red-500 hover:opacity-75"
          : ""
      }`}
      onClick={() => removeTag && removeTag(id)}
    >
      <p className="overflow-hidden overflow-ellipsis pr-1 pl-1">{displayName}</p>
    </div>
  );
};
