import { motion } from "framer-motion";
import { SelectOption } from "model/datatypes";
import React from "react";

interface Props {
  optionOne: string;
  onClickOne: () => void;
  optionTwo: string;
  onClickTwo: () => void;
  active?: "one" | "two";
}

export const DualButton: React.FC<Props> = ({
  active,
  onClickOne,
  onClickTwo,
  optionOne,
  optionTwo,
}) => (
  <div
    style={{ padding: "2px" }}
    className="flex w-64 rounded-full border border-indigo-200 bg-indigo-600"
  >
    <div
      className="relative h-5 w-1/2 cursor-pointer text-center text-sm"
      onClick={onClickOne}
    >
      <span
        className={`absolute inset-0 z-50 pt-0.5 font-normal transition-colors duration-150 ${
          active === "one" ? "font-semibold text-indigo-600" : "text-white"
        }`}
      >
        {optionOne}
      </span>
      {active === "one" ? (
        <motion.div className="h-full w-full rounded-full bg-white" layoutId="highlight" />
      ) : null}
    </div>

    <div
      className="relative h-5 w-1/2 cursor-pointer text-center text-sm"
      onClick={onClickTwo}
    >
      <span
        className={`absolute inset-0 z-50 pt-0.5 font-normal transition-colors duration-150 ${
          active === "two" ? "font-semibold text-indigo-600" : "text-white"
        }`}
      >
        {optionTwo}
      </span>
      {active === "two" ? (
        <motion.div className="h-full w-full rounded-full bg-white" layoutId="highlight" />
      ) : null}
    </div>
  </div>
);

export const MultiToggleButton: React.FC<{
  active?: string;
  options: SelectOption[];
  onClick: (option: SelectOption) => void;
}> = ({ active, options, onClick }) => (
  <div
    style={{ padding: "2px", width: `${8 * options.length}rem` }}
    className="flex rounded-full bg-gray-200"
  >
    {options.map((option) => (
      <button
        key={option.id}
        className={` flex-1 overflow-hidden py-px px-2 text-sm focus:outline-none ${
          active === option.id ? "rounded-full bg-white shadow-md" : ""
        }`}
        onClick={() => {
          onClick(option);
        }}
      >
        {option.display}
      </button>
    ))}
  </div>
);
