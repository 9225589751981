import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { GET_GROUPS } from "./getGroups";

const DELETE_GROUP = graphql(`
  mutation DELETE_GROUP($groupId: ID!, $projectId: ID!) {
    groupDelete(groupId: $groupId, projectId: $projectId) {
      ... on Group {
        id
        createdAt
      }
      ... on GroupNotFound {
        groupId
      }
    }
  }
`);

export const useDeleteGroup = () => {
  const mutation = useMutation(DELETE_GROUP, {
    update: (cache, { data }, context) => {
      const mutationResult = data?.groupDelete;
      if (!mutationResult || !context.variables?.projectId) return;

      cache.updateQuery(
        {
          query: GET_GROUPS,
          variables: {
            projectId: context.variables.projectId,
          },
        },
        (cacheData) => {
          if (!cacheData) return undefined;
          let updatedGroups = [...cacheData.groups];
          updatedGroups = updatedGroups.filter((g) => g.id !== context.variables?.groupId);
          return { ...cacheData, groups: updatedGroups };
        }
      );
    },
  });

  return mutation;
};
