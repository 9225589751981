import { useApolloClient } from "@apollo/client";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useFirebase } from "api/useFirebase";
import { useGlobalDispatch } from "store";

export const useLogOut = () => {
  const fb = useFirebase();
  const client = useApolloClient();
  const navigate = useNavigateApp();
  const dispatch = useGlobalDispatch();

  const logOut = async () => {
    try {
      const promise = fb.auth().signOut();
      await promise;
      client.clearStore();
      localStorage.clear();
      dispatch({ type: "SET_AUTH_STATUS", payload: "not-authenticated" });
      navigate("/login", { useAsCompletePath: true });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return { logOut };
};
