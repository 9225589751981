import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const GET_ORGANISATION_LABELS = graphql(`
  query GET_ORGANISATION_LABELS {
    organization {
      id
      labels {
        edges {
          node {
            id
            title
            description
            color
          }
        }
      }
    }
  }
`);

export const useGetOrganizationLabels = () => {
  const result = useQuery(GET_ORGANISATION_LABELS);

  return result;
};
