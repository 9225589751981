import { MagnifyingGlass, User as UserIcon, UsersThree } from "@phosphor-icons/react";
import TagSelector from "Components/Basic/TagSelector";
import { Team } from "api/team/getAllTeams";
import { OrganizationUser } from "api/userManagement/getAllUsersInOrganisation";
import { Tag } from "model/datatypes";
import React, { useMemo } from "react";
import TeamCollaborator from "./TeamCollaborator";
import UserCollaborator from "./UserCollaborator";

interface Props {
  allCollaborators: (Team | OrganizationUser)[];
  addedCollaboratorIDs: string[] | undefined;
  addCollaborator: (id: string) => void;
  removeCollaborator: (id: string) => void;
  hasEditAccess: boolean;
  type: "user" | "team";
  context: string;
}

/**
 * Generic component for displaying, editing and adding user or team collaborators.
 *
 * @param allCollaborators - An array of all available (added and not added) collaborators of type `User` or `Team`.
 * @param addedCollaboratorIDs - An array of the IDs of added collaborators.
 * @param addCollaborator - Function called when a collaborator/team is added.
 * @param removeCollaborator - Function called when a collaborator/team is removed.
 * @param hasEditAccess - Boolean determining whether the user should be able to add/delete collaborators.
 * @param type - Accepts `"user"` or `"team"`. Determines if working with user or team collaborators.
 * @param context - The context of displaying or modifying collaborators, e.g. `"project"` or `"dashboard"`.
 */
const Collaborators: React.FC<Props> = ({
  allCollaborators,
  addedCollaboratorIDs = [],
  addCollaborator,
  removeCollaborator,
  hasEditAccess,
  type,
  context,
}) => {
  const collaborators = useMemo(
    () =>
      allCollaborators.filter((collaborator) =>
        addedCollaboratorIDs.includes(
          collaborator.__typename === "Team" ? collaborator.id : collaborator.user.id
        )
      ),
    [addedCollaboratorIDs, allCollaborators]
  );

  const collaboratorTags: Tag[] = useMemo(
    () =>
      allCollaborators.map((collaborator) => {
        switch (collaborator.__typename) {
          case "Team":
            return { id: collaborator.id, displayName: collaborator.name };
          default:
            return { id: collaborator.user.id, displayName: collaborator.user.fullName };
        }
      }),
    [allCollaborators]
  );

  return (
    <div className="w-1/2" data-testid={type.concat("Section")}>
      <div className="rounded-md bg-white">
        <div className="flex items-center gap-2 border-b border-gray-200 p-5">
          {type === "user" ? (
            <UserIcon weight="bold" size={16} />
          ) : (
            <UsersThree weight="bold" size={16} />
          )}
          <div className="text-lg font-medium capitalize leading-6 text-gray-700">{type}s</div>
        </div>

        <div
          data-testid="collaboratorSection"
          className="h-80 divide-y divide-gray-200 overflow-auto"
        >
          <div className="flex items-center gap-2 py-1 px-5">
            <MagnifyingGlass className="w-4 h-4 text-gray-500" />
            <TagSelector
              selectTag={addCollaborator}
              selectedTagIDs={addedCollaboratorIDs}
              tags={collaboratorTags}
              inputPlaceholder={`Add ${type}`}
              type={`${type}s`}
              headless
              className="flex"
            />
          </div>
          {collaborators.map((collaborator) => {
            if (collaborator.__typename === "OrganizationMembership")
              return (
                <UserCollaborator
                  key={collaborator.user.id}
                  collaborator={collaborator}
                  hasEditAccess={hasEditAccess}
                  onRemove={() => removeCollaborator(collaborator.user.id)}
                />
              );
            if (collaborator.__typename === "Team")
              return (
                <TeamCollaborator
                  key={collaborator.id}
                  collaborator={collaborator}
                  hasEditAccess={hasEditAccess}
                  onRemove={() => removeCollaborator(collaborator.id)}
                />
              );
            return null;
          })}
          {collaborators.length <= 0 && (
            <div className="flex h-full w-full items-center justify-center text-sm text-gray-600">
              No {type}s collaborating on this {context}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Collaborators;
