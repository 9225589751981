import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Get_Connected_RepositoriesQuery } from "GraphQL/gql/graphql";
import { GET_CONNECTED_REPOSITORIES } from "./getConnectedRepositories";

export const CREATE_CONNECTED_REPOSITORY = graphql(`
  mutation CREATE_CONNECTED_REPOSITORY(
    $remoteRepositoryId: ID!
    $remoteRepositoryProvider: String!
  ) {
    connectedRepositoryCreate(
      remoteRepositoryId: $remoteRepositoryId
      remoteRepositoryProvider: $remoteRepositoryProvider
    ) {
      ... on ConnectedRepository {
        __typename
        id
        displayName
        path
        description
        updatedAt
        createdBy {
          __typename
          fullName
        }
        lastCommit {
          ... on Commit {
            __typename
            id
          }
        }
      }
      ... on RemoteRepositoryNotFound {
        __typename
        remoteRepositoryId
      }
      ... on RemoteRepositoryProviderNotSupported {
        __typename
        unsupportedRemoteRepositoryProvider
      }
      ... on RemoteRepositoryAlreadyConnected {
        __typename
        remoteRepositoryId
      }
    }
  }
`);

export const useCreateConnectedRepository = () => {
  const mutation = useMutation(CREATE_CONNECTED_REPOSITORY, {
    update(cache, { data }) {
      if (!data) return;
      if (data.connectedRepositoryCreate.__typename !== "ConnectedRepository") return;

      const newConnectedRepository = data.connectedRepositoryCreate;

      cache.updateQuery({ query: GET_CONNECTED_REPOSITORIES }, (cacheData) => {
        if (!cacheData) return undefined;

        const updatedConnectedRepositories: Get_Connected_RepositoriesQuery["connectedRepositories"] =
          {
            ...cacheData.connectedRepositories,
            edges: [
              ...cacheData.connectedRepositories.edges,
              {
                __typename: "ConnectedRepositoryEdge",
                cursor: newConnectedRepository.id,
                node: newConnectedRepository,
              },
            ],
          };

        const updatedData: Get_Connected_RepositoriesQuery = {
          ...cacheData,
          connectedRepositories: updatedConnectedRepositories,
        };

        return updatedData;
      });
    },
  });
  return mutation;
};
