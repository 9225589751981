import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { GET_GROUP } from "api/group/getGroup";

const DUPLICATE_SCENARIO = graphql(`
  mutation DUPLICATE_SCENARIO(
    $newDescription: String!
    $newGroupId: ID!
    $newName: String!
    $newProjectId: ID!
    $projectId: ID!
    $scenarioId: ID!
    $includeOptimization: Boolean!
  ) {
    scenarioDuplicate(
      newDescription: $newDescription
      newGroupId: $newGroupId
      newName: $newName
      newProjectId: $newProjectId
      projectId: $projectId
      scenarioId: $scenarioId
      includeOptimization: $includeOptimization
    ) {
      ... on Scenario {
        id
        groupId
        name
        description
      }
      ... on GroupNotFound {
        __typename
        notFoundGroupId: groupId
        projectId
      }
      ... on ScenarioNotFound {
        __typename
        projectId
        scenarioId
      }
      ... on ProjectNotFound {
        __typename
        projectId
      }
    }
  }
`);

export const useDuplicateScenario = () => {
  const mutation = useMutation(DUPLICATE_SCENARIO, {
    update(cache, result, { variables }) {
      const scenario = result.data?.scenarioDuplicate;
      if (scenario?.__typename !== "Scenario" || !variables) return;

      cache.updateQuery(
        {
          query: GET_GROUP,
          variables: {
            groupId: variables.newGroupId,
            projectId: variables.newProjectId,
          },
        },
        (data) => {
          let updatedGroup = structuredClone(data?.group);
          if (data === null || updatedGroup?.__typename !== "Group") return data;
          updatedGroup.scenarios = [...(updatedGroup.scenarios || []), scenario.id];

          return { ...data, group: updatedGroup };
        }
      );
    },
  }); // TODO update get scenarios in a project when scenario is duplicated/new scenario

  return mutation;
};
