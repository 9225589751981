import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const ADD_EDITOR_TO_WORKSPACE = graphql(`
  mutation ADD_EDITOR_TO_WORKSPACE($userId: ID!, $workspaceId: ID!) {
    workspaceAddEditor(userId: $userId, workspaceId: $workspaceId) {
      ... on Workspace {
        id
        members {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
      ... on WorkspaceEditorNotAdded {
        __typename
      }
    }
  }
`);

export const useAddEditorToWorkspace = () => {
  const mutation = useMutation(ADD_EDITOR_TO_WORKSPACE);

  return mutation;
};
