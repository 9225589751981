import LoadingOverlay from "Components/Basic/LoadingOverlay";
import ScheduleInput from "Components/Input/newSchedule/ScheduleInput";
import useGetDataSet from "api/dataSet/getDataSet";
import React from "react";
import { toast } from "react-hot-toast";

type Props = {
  dataSetId: string;
  projectId: string;
  closeModal: () => void;
};

export const AddScheduleModal = ({ dataSetId, projectId, closeModal }: Props) => {
  const { data, loading, error } = useGetDataSet({
    dataSetId,
    projectId,
  });

  if (data?.dataSet.__typename !== "ScheduledDataSet") return null;

  const { dataSet } = data;

  if (loading) return <LoadingOverlay />;
  if (error) {
    console.error(error);
    toast.error("Could not read data set");
    closeModal();
  }
  return (
    <ScheduleInput
      key={`${dataSet.id}-scheduleInput`}
      dataSet={dataSet}
      onFinished={closeModal}
    />
  );
};
