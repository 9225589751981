import { ButtonHTMLAttributes, ReactNode } from "react";
import LoadingSpinner from "thermo/loadingSpinner/LoadingSpinner";
import classNames from "utils/jsUtils/className";

const getSizeStyling = (size: BaseButtonProps["size"]) => {
  switch (size) {
    case "sm":
      return "text-sm px-2.5 py-1 max-h-[30px]";
    case "md":
      return "text-sm px-2.5 py-1 max-h-[34px]";
    case "lg":
      return "text-base px-3 py-2 max-h-[36px]";
    default:
      return "";
  }
};

interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: "sm" | "md" | "lg";
  icon?: JSX.Element;
  loading?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
}

const BaseButton = ({
  size,
  icon,
  loading,
  fullWidth,
  children,
  ...htmlProps
}: BaseButtonProps) => {
  return (
    <button
      {...htmlProps}
      className={classNames(
        "m-0 whitespace-nowrap relative flex-shrink-0 flex justify-center items-center transition-all duration-75 disabled:cursor-not-allowed disabled:text-gray-300 rounded-md select-none",
        fullWidth && "w-full",
        getSizeStyling(size),
        htmlProps.className
      )}
    >
      <div
        className={`${loading ? "invisible" : ""} flex gap-1.5 items-center justify-center`}
      >
        {icon && <div className="">{icon}</div>}
        {children && <div className="first-letter:uppercase lowercase">{children}</div>}
      </div>
      {loading && (
        <div className="absolute inset-0 m-auto w-fit h-fit">
          <LoadingSpinner size="sm" />
        </div>
      )}
    </button>
  );
};

interface ButtonProps extends BaseButtonProps {
  variant?: "brandColor" | "dashed" | "warning";
  borderless?: boolean;
}

export const Button = ({ variant, borderless, ...props }: ButtonProps) => {
  switch (variant) {
    case "brandColor":
      return (
        <BaseButton
          {...props}
          className={classNames(
            props.className,
            "bg-primary-600 hover:bg-primary-700 disabled:bg-primary-100 disabled:hover:bg-primary-100 [&:not([disabled])]:shadow-[0px_1px_1px_rgb(0,0,0,11%)] border border-primary-600 disabled:border-primary-100 text-white"
          )}
        />
      );
    case "warning":
      return (
        <BaseButton
          {...props}
          className={classNames(
            props.className,
            "bg-error-600 hover:bg-error-700 disabled:bg-error-100 disabled:hover:bg-error-100 [&:not([disabled])]:shadow-[0px_1px_1px_rgb(0,0,0,11%)] text-white"
          )}
        />
      );
    case "dashed":
      return (
        <BaseButton
          {...props}
          className={classNames(
            props.className,
            "bg-white hover:bg-gray-100 disabled:bg-white border border-dashed border-gray-300 disabled:border-gray-200 text-gray-700"
          )}
        />
      );
    default:
      return (
        <BaseButton
          {...props}
          className={classNames(
            "bg-white hover:bg-gray-100 disabled:bg-gray-100",
            !borderless &&
              "[&:not([disabled])]:shadow-[0px_1px_1px_rgb(0,0,0,11%)] border border-gray-300 disabled:border-gray-200 text-gray-700"
          )}
        />
      );
  }
};
