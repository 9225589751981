import { PeriodSettingInput } from "GraphQL/gql/graphql";
import { DayTime } from "utils/DayTime";

export const applyScalingAndOffset = (value: number, scaling?: number, offset?: number) => {
  let result = value;
  if (typeof scaling === "number") result *= scaling;
  if (typeof offset === "number") result += offset;
  return result;
};

export const getDayData = ({ timeslots, defaultValue }: PeriodSettingInput) => {
  //Get data for all hours during a single day
  const data: { setting: number; time: DayTime }[] = [];
  const time = new DayTime();

  const endTime = time.clone().addHours(24).addSeconds(1);

  const findTimeslot = (t: DayTime) =>
    timeslots.find((ts) => t.isBetween(ts.startTime, ts.endTime, "[)"));

  while (time.isBefore(endTime)) {
    try {
      const slot = findTimeslot(time);
      const value = slot ? slot.value : defaultValue;
      data.push({ setting: value, time: time.clone() });
      data.push({ setting: value, time: time.clone().addMinutes(59) });

      time.addHours(1);
    } catch (err) {
      break;
    }
  }

  return data;
};

const time = new DayTime();

const ticks = Array.from({ length: 25 }, () => {
  const tick = { value: time.inSeconds(), text: time.format("HH:mm") };
  time.addHours(1);
  return tick;
});

export const tickTexts = ticks.map(({ text }) => text);
export const tickValues = ticks.map(({ value }) => value);
