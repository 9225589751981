import {
  Files,
  GearFine,
  GitBranch,
  Graph,
  Table,
  Toolbox,
  UsersThree,
  Warning,
} from "@phosphor-icons/react";
import { useFlag } from "@unleash/proxy-client-react";
import { useAllowDashboardAccess } from "Components/Pages/ProtectedRoute/Dashboard/utils/allowDashboardAccess";
import { useGetOrganizationCreditBalance } from "api/organisation/credit/getOrganizationCreditBalance";
import { useUserRole } from "api/useFirebase";
import { useMemo } from "react";
import NavigationButton from "thermo/navigationButton/NavigationButton";
import Tooltip from "thermo/tooltip/Tooltip";

export const GlobalNavigationButtons = () => {
  const enableCredits = useFlag("enableCredit");
  const enableGitlabFunctionality = useFlag("showGitlabIntegration");
  const { hasDeveloperAccess, hasSimulatorAccess, hasOwnerAccess, hasPortfolioManagerAccess } =
    useUserRole();
  const { data: orgCreditBalance } = useGetOrganizationCreditBalance();
  const allowDashboardAccess = useAllowDashboardAccess();

  const hasLowCreditBalance = useMemo(() => {
    if (orgCreditBalance?.organization.__typename !== "Organization") return undefined;
    if (orgCreditBalance?.organization.creditBalance <= 10)
      return (
        <Tooltip data-testid="dasd" label="The organization credit balance is low">
          <Warning size={14} weight="bold" className="text-warning-600" />
        </Tooltip>
      );

    return undefined;
  }, [orgCreditBalance]);

  return (
    <div data-testid="globalNavigationButtons" className="w-full flex flex-col gap-0.5">
      {hasSimulatorAccess && (
        <NavigationButton
          buttonText="Tools"
          navigateTo=""
          icon={<Toolbox size={14} weight="fill" />}
        />
      )}
      {hasDeveloperAccess && (
        <NavigationButton
          buttonText="Development"
          navigateTo="tools"
          icon={<Graph size={14} weight="fill" />}
        />
      )}
      {hasDeveloperAccess && enableGitlabFunctionality && (
        <NavigationButton
          buttonText="Repositories"
          navigateTo="repositories"
          icon={<GitBranch size={14} weight="fill" />}
        />
      )}
      {hasDeveloperAccess && (
        <NavigationButton
          buttonText="Files"
          navigateTo="files"
          icon={<Files size={14} weight="fill" />}
        />
      )}
      {allowDashboardAccess && (
        <NavigationButton
          buttonText="Dashboard"
          navigateTo="dashboard"
          icon={<Table size={14} weight="fill" />}
        />
      )}
      {hasPortfolioManagerAccess && (
        <NavigationButton
          buttonText="Organization"
          navigateTo="organization"
          icon={<UsersThree size={14} weight="fill" />}
          extraIcon={hasLowCreditBalance}
        >
          <NavigationButton buttonText="Members" navigateTo="organization/members" />
          <NavigationButton buttonText="Settings" navigateTo="organization/settings" />
          {enableCredits && hasOwnerAccess && (
            <>
              <NavigationButton buttonText="Credits" navigateTo="organization/credit" />
              <NavigationButton
                buttonText="Subscription"
                navigateTo="organization/subscription"
              />
            </>
          )}
        </NavigationButton>
      )}
      {hasPortfolioManagerAccess && (
        <NavigationButton
          buttonText="Settings"
          navigateTo="settings"
          icon={<GearFine size={14} weight="fill" />}
        >
          <NavigationButton buttonText="Account" navigateTo="settings/account" />
          <NavigationButton buttonText="Preferences" navigateTo="settings/preferences" />
          <NavigationButton buttonText="Tutorials" navigateTo="settings/tutorials" />
          <NavigationButton buttonText="Integrations" navigateTo="settings/integrations">
            <NavigationButton buttonText="GitLab" navigateTo="settings/integrations/gitlab" />
          </NavigationButton>
          <NavigationButton buttonText="Advanced" navigateTo="settings/advanced" />
        </NavigationButton>
      )}
    </div>
  );
};
