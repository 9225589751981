import { format } from "date-fns";
import React, { useMemo } from "react";
import Tooltip from "thermo/tooltip/Tooltip";
import classNames from "utils/jsUtils/className";

interface Props {
  startDate: Date;
  endDate: Date;
  startRange?: Date;
  endRange?: Date | "continuous";
  displayDates?: true;
  endStrategy: "repeat" | "wait" | "end";
  color?: "blue";
}

const TimeRangeDisplayLine: React.FC<Props> = ({
  startDate,
  endDate,
  endRange,
  startRange,
  displayDates,
  color,
  endStrategy,
}) => {
  const { after, before, during } = useMemo(() => {
    let tempBefore = 0;
    let tempAfter = 0;

    let rangeStart = startRange?.valueOf() || startDate.valueOf();
    let rangeEnd =
      typeof endRange === "string"
        ? endDate.valueOf() + (endDate.valueOf() - rangeStart) / 4
        : endRange?.valueOf() || endDate.valueOf();
    const totalTR = rangeEnd - rangeStart;

    if (startRange) {
      tempBefore = ((startDate.valueOf() - startRange.valueOf()) / totalTR) * 100;
    }
    if (endRange) {
      tempAfter = ((rangeEnd - endDate.valueOf()) / totalTR) * 100;
    }
    let tempDuring: number = 100 - tempBefore - tempAfter;

    return { before: tempBefore, during: tempDuring, after: tempAfter };
  }, [startDate, endDate, endRange, startRange]);

  const renderFullDateTime = (date: Date, position: "center" | "right" | "left") => (
    <div
      className={classNames(
        "flex w-16 flex-col py-1 text-sm leading-tight",
        position === "center" && "items-center",
        position === "right" && "items-end"
      )}
    >
      <div>{format(date, "HH:mm")}</div>
      <div>{format(date, "dd/MM yyyy")}</div>
    </div>
  );
  const renderDateIcon = (date: Date) => (
    <div className="text-sm">
      <Tooltip label={renderFullDateTime(date, "center")}>
        <div>{format(date, "dd/MM yyyy")}</div>
      </Tooltip>
    </div>
  );

  const endStrategyDecription = useMemo(() => {
    if (endStrategy === "repeat") return "Will repeat.";
    if (endStrategy === "wait") return "Will wait for further data";
    return "End of simulation";
  }, [endStrategy]);

  return (
    <div className="flex items-center pt-2 pb-6">
      <div style={{ width: `${before}%` }} className="border-b-4 border-gray-200" />
      <div
        style={{ width: `${during}%` }}
        className={`relative  border-b-4 ${color ? "border-blue-400" : "border-green-400"} `}
      >
        <div
          className={classNames(
            "absolute top-0 left-0 mt-2",
            before > 0 && (displayDates ? "-ml-8" : "-ml-3")
          )}
        >
          {displayDates
            ? renderFullDateTime(startDate, before > 0 ? "center" : "left")
            : renderDateIcon(startDate)}
        </div>
        <div
          className={classNames(
            "absolute top-0 right-0 mt-2",
            after > 0 && (displayDates ? "-mr-8" : "-mr-3")
          )}
        >
          {displayDates
            ? renderFullDateTime(endDate, after > 0 ? "center" : "right")
            : renderDateIcon(endDate)}
        </div>
      </div>
      <Tooltip label={endStrategyDecription}>
        <div
          className={classNames(
            "border-b-4",
            endStrategy === "wait" && "border-dashed",
            endStrategy === "end" && "border-gray-300",
            endStrategy !== "end" && color ? "border-blue-300" : "border-green-300"
          )}
        />
      </Tooltip>
    </div>
  );
};

export default TimeRangeDisplayLine;
