import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useDeprecatedFirestore } from "api/useFirebase";
import { useState } from "react";
import toast from "react-hot-toast";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

export const useTogglePublishFile = () => {
  const [loading, setLoading] = useState(false);
  const fs = useDeprecatedFirestore();
  const activeOrganizationId = useActiveOrganizationId();

  const togglePublishFile = async (
    publish: boolean,
    projectID: string,
    scenarioID: string,
    fileID: string
  ) => {
    if (loading || !activeOrganizationId) return;
    setLoading(true);
    try {
      const scenarioDoc = fs
        .collection("organizations")
        .doc(activeOrganizationId)
        .collection("projects")
        .doc(projectID)
        .collection("scenarios")
        .doc(scenarioID);
      await scenarioDoc.update(
        convertToFirestoreFormat({
          publishedFileID: publish ? fileID : null,
          hasFilePublished: publish,
        })
      );
      setLoading(false);
    } catch (error) {
      toast.error("There was an error updating the scenario");
      console.error(error);
      setLoading(false);
    }
  };
  return { togglePublishFile };
};
