import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { Workspace } from "api/workspace/getWorkspaces";
import ExistingMemberRow from "./ExistingMemberRow";

type Props = {
  searchTerm: string;
  workspace: Workspace;
};

const ExistingWorkspaceMembers = ({ workspace, searchTerm }: Props) => {
  const loggedInUser = useGlobalUser();

  // Filter away users that don't match search term and sort by role and name
  const filteredAndSortedWorkspaceUsers = workspace.members
    .filter((user) => user.user?.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      // Sort by role
      if (a.role === "EDITOR") {
        return 1;
      }
      if (a.role === "OWNER") {
        return -1;
      }

      // Sort by name
      if (!a.user || !b.user) return 0;
      if (a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase()) return 1;
      if (a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase()) return -1;
      return 0;
    });

  const loggedInUserIsWorkspaceOwner = workspace.members.some(
    (member) => member.user?.id === loggedInUser.id && member.role === "OWNER"
  );

  return (
    <div className="grid max-h-44 gap-4 overflow-y-auto">
      {filteredAndSortedWorkspaceUsers.map((user) => {
        return (
          <ExistingMemberRow
            loggedInUser={loggedInUser}
            loggedInUserIsWorkspaceOwner={loggedInUserIsWorkspaceOwner}
            workspaceMember={user}
            workspaceID={workspace.id}
            key={user.user?.id}
          />
        );
      })}
    </div>
  );
};

export default ExistingWorkspaceMembers;
