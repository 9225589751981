import { CaretRight } from "@phosphor-icons/react";
import Link from "Components/AppNavigation/Link";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Text from "thermo/typography/Typography";
import classNames from "utils/jsUtils/className";
import { toCamelCase } from "utils/jsUtils/stringToCamelCase";

/**
 * Checks if each array element (in order) of `arrayOne` is equal to the matching element in `arrayTwo`
 */
const matchArrayElements = (arrayOne?: string[], arrayTwo?: string[]) => {
  if (!arrayOne || !arrayTwo) return false;

  let index = 0;
  let elementsMatch = true;

  while (elementsMatch && index < arrayOne.length) {
    elementsMatch = arrayOne[index] === arrayTwo[index];
    index += 1;
  }

  return elementsMatch;
};

interface Props {
  buttonText: string;
  icon?: ReactNode;
  extraIcon?: ReactNode | undefined;
  navigateTo: string;
  children?: ReactNode;
}

/**
 * @param buttonText - The text displayed in the navigation button.
 * @param icon - The icon displayed in the navigation button.
 * @param extraIcon - An extra icon displayed to the far right.
 * @param navigateTo - The path of the navigation item.
 * @param children - Nested subcategory navigation items.
 */
const NavigationButton = ({ buttonText, icon, extraIcon, navigateTo, children }: Props) => {
  const { pathname } = useLocation();
  const organizationSlug = useOrganizationSlugFromParams();
  const isActive = matchArrayElements(
    navigateTo.split("/"),
    (pathname.split(`${organizationSlug}/`)[1] || "").split("/")
  );

  return (
    <>
      <div
        data-testid="navigationButton"
        className={classNames(
          isActive && "bg-slate-100",
          "flex-1 hover:bg-slate-100 text-gray-700 group/sidebar group/item transition-colors duration-100 cursor-pointer rounded-[4px] select-none"
        )}
      >
        <Link to={navigateTo} className="flex items-center justify-between px-2 py-1">
          <div className="flex items-center">
            {icon && (
              <span
                data-testid="navigationButtonIcon"
                className="transition-all duration-100 group-hover/sidebar:text-gray-900"
              >
                {icon}
              </span>
            )}
            <Text.Base className="ml-2 mr-1">{buttonText}</Text.Base>
            {children && (
              <CaretRight
                size={13}
                className={classNames(
                  "mr-0 ml-auto text-gray-400 transition-transform duration-100",
                  isActive && "rotate-90"
                )}
              />
            )}
          </div>
          {extraIcon && <span data-testid="extraNavigationButtonIcon">{extraIcon}</span>}
        </Link>
      </div>
      {children && (
        <AnimatePresence>
          {isActive && (
            <motion.div
              className="flex flex-col gap-0.5 ml-4 overflow-hidden"
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ duration: 0.1 }}
              data-testid={`${toCamelCase(buttonText)}NavigationChildren`}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default NavigationButton;
