import { Notepad } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useGetProject } from "api/project/getProject";
import { useLocation } from "react-router-dom";
import { RecentItem } from "../types";
import { RecentItemTemplate } from "./RecentItemTemplate";

type Props = {
  projectId: string;
  type: Extract<RecentItem["type"], "project">;
  close: () => void;
};

export const RecentProjectItem = ({ projectId, type, close }: Props) => {
  const { data, loading, error } = useGetProject({ projectId });
  const navigate = useNavigateApp();
  const location = useLocation();

  const isCurrentlyOnItemPage = location.pathname === `/${type}/${projectId}`;

  if (error) return null;
  if (!data?.project) return null;
  return (
    <RecentItemTemplate
      icon={<Notepad size={16} weight="bold" />}
      type={type}
      text={data.project.name}
      loading={loading}
      onClick={() => {
        if (isCurrentlyOnItemPage) return;
        navigate(`/project/${projectId}`);
        close();
      }}
      recentItemIsActivePage={isCurrentlyOnItemPage}
    />
  );
};
