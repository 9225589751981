import { useEffect, useState } from "react";

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const setNetworkStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", setNetworkStatus);
    window.addEventListener("offline", setNetworkStatus);

    return () => {
      window.removeEventListener("online", setNetworkStatus);
      window.removeEventListener("offline", setNetworkStatus);
    };
  }, []);

  return { isOnline };
};
