import React, { ReactNode } from "react";
import Text from "thermo/typography/Typography";
import { getZIndexClassName } from "thermo/zIndex/ZIndex";
import classNames from "utils/jsUtils/className";

type Props = {
  children?: ReactNode;
  pageTitle: string | ReactNode;
};

export const TopBarWrapper = ({ children, pageTitle }: Props) => {
  return (
    <div
      data-testid="topBar"
      className={classNames(
        getZIndexClassName("zNavigation"),
        "sticky top-0 min-w-full bg-white h-14 border-b border-gray-200 flex items-center py-3 px-3"
      )}
    >
      <div data-testid="topbarPageTitleSection" className="flex-1 text-gray-700">
        <Text.Base>{pageTitle}</Text.Base>
      </div>
      <div className="h-full justify-end flex items-center">{children}</div>
    </div>
  );
};
