const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
/**
 * A class to respresent hours, minutes and seconds in a day.
 *
 * It can exceed 24 hours, if it does it:
 * - Affects comparions methods.
 * - Does not affect string formatting.
 */

export class DayTime {
  private _hours: number;

  private _minutes: number;

  private _seconds: number;

  /**
   * Default to start of day ("00:00:00")
   * @param time Optional. String formatted "HH:mm:ss" or DayTime Object
   */
  constructor(time: TimeOfDay | DayTime = "00:00:00") {
    if (typeof time === "string") {
      [this._hours, this._minutes, this._seconds] = time.split(":").map(Number);
    } else {
      this._hours = time._hours;
      this._minutes = time._minutes;
      this._seconds = time._seconds;
    }
  }

  static fromEndHours(time: TimeOfDay | DayTime) {
    const dayTime = new DayTime(time);
    if (dayTime.hours === 0) dayTime.addHours(24);

    return dayTime;
  }

  /**
   *
   * @returns Current Time of Date
   */
  static now() {
    const now = new Date();
    return new DayTime()
      .addHours(now.getHours())
      .addMinutes(now.getMinutes())
      .addSeconds(now.getSeconds());
  }

  // ============================================ Utility functions ============================================
  clone() {
    return new DayTime(this);
  }

  // ===================================================== Getters =====================================================
  get hours() {
    return this._hours;
  }

  get minutes() {
    return this._minutes;
  }

  get seconds() {
    return this._seconds;
  }

  inSeconds() {
    return this._hours * SECONDS_IN_HOUR + this._minutes * SECONDS_IN_MINUTE + this._seconds;
  }

  // ============================================ methods returning strings ============================================
  toString() {
    return [this._hours, this._minutes, this._seconds]
      .map((time) => time.toString().padStart(2, "0"))
      .join(":");
  }

  /**
   * Purpose of this function is to return a format that GraphQL can understands.
   * It says daytime so typescript doesn't complain, but it is a string formatted "HH:mm:ss"
   * @returns string
   */
  toGraphQL(): DayTime {
    return this.format("HH:mm:ss") as any;
  }

  format(format: string) {
    const hours = this._hours % 24;
    let formattedString = format.replace("HH", hours.toString().padStart(2, "0"));
    formattedString = formattedString.replace("mm", this._minutes.toString().padStart(2, "0"));
    return formattedString.replace("ss", this._minutes.toString().padStart(2, "0"));
  }

  // ============================================== Comparison methods ==============================================

  isSameHour(time: DayTime) {
    return this._hours === time.hours;
  }

  isBefore(compareTo: DayTime, inclusive = false) {
    return inclusive
      ? this.inSeconds() <= compareTo.inSeconds()
      : this.inSeconds() < compareTo.inSeconds();
  }

  isAfter(compareTo: DayTime, inclusive = false) {
    return inclusive
      ? this.inSeconds() >= compareTo.inSeconds()
      : this.inSeconds() > compareTo.inSeconds();
  }

  /**
   * Used to check if the time of day is between two other times of the day.
   * @param start
   * @param end
   * @param inclusive square brackets means inclusive, parenthesis means exlusive
   * @returns If the DayTime calling the methods is between the given interval
   */
  isBetween(start: DayTime, end: DayTime, inclusive: "[]" | "()" | "[)" | "(]" = "[]") {
    if (start.isAfter(end)) {
      console.error("Start time must be before end time: ", { start, end });
      throw Error("Start time must be before end time");
    }
    const isStartInclusive = inclusive[0] === "[";
    const isEndInclusive = inclusive[1] === "]";
    return this.isAfter(start, isStartInclusive) && this.isBefore(end, isEndInclusive);
  }

  // ================================================== Mutate Values ==================================================
  private addTime(seconds: number) {
    let newSeconds = this.inSeconds() + seconds;
    if (newSeconds < 0)
      throw new Error("Time will reach less than Zero. This is not allowed.");

    this._hours = Math.floor(newSeconds / SECONDS_IN_HOUR);
    newSeconds %= SECONDS_IN_HOUR;
    this._minutes = Math.floor(newSeconds / SECONDS_IN_MINUTE);
    this._seconds = newSeconds % SECONDS_IN_MINUTE;

    return this;
  }

  /**
   * Adds hours to current time.
   * If it surpasses hour 24, it start over, but a new day is still entered.
   * @example
   * const start = new DayTime();
   * const time = start.clone().addHours(24);
   * time.format("HH") === start.format("HH") // true
   * time.isSameHour(start) // false
   * time.isAfter(start) // true
   * @param hours
   */
  addHours(hours: number) {
    if (hours < 0) {
      return this.addTime(hours * SECONDS_IN_HOUR);
    }
    this._hours += hours;
    return this;
  }

  /**
   * Adds minutes to current time.
   * If it surpasses hour 24, it start over, but a new day is still entered.
   * @example
   * const start = new DayTime();
   * const time = new DayTime("23:59:00").addMinutes(1);
   * time.format("HH") === start.format("HH") // true
   * time.isSameHour(start) // false
   * time.isAfter(start) // true
   * @param minutes
   */
  addMinutes(minutes: number) {
    return this.addTime(minutes * SECONDS_IN_MINUTE);
  }

  /**
   * Adds minutes to current time.
   * If it surpasses hour 24, it start over, but a new day is still entered.
   * @example
   * const start = new DayTime();
   * const time = new DayTime("23:59:50").addSeconds(10);
   * time.format("HH") === start.format("HH") // true
   * time.isSameHour(start) // false
   * time.isAfter(start) // true
   * @param seconds
   */
  addSeconds(seconds: number) {
    return this.addTime(seconds);
  }
}

// Type helpers of for constructing time of day string
type D01 = "0" | "1";
type D03 = D01 | "2" | "3";
type D05 = D03 | "4" | "5";
type D09 = D05 | "6" | "7" | "8" | "9";

type HoursString = `${D01}${D09}` | `2${D03}`;
type MinutesString = `${D05}${D09}`;
type SecondsString = MinutesString;

type TimeOfDay = `${HoursString}:${MinutesString}:${SecondsString}`;
