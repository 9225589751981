import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { Update_Data_SetMutation } from "GraphQL/gql/graphql";

const UPDATE_DATA_SET = graphql(`
  mutation UPDATE_DATA_SET(
    $projectId: ID!
    $dataSetId: ID!
    $initialScheduleTag: String!
    $updatedSchedule: ScheduleInput!
  ) {
    dataSetScheduleDelete(
      projectId: $projectId
      dataSetId: $dataSetId
      scheduleTag: $initialScheduleTag
    ) {
      ... on ScheduledDataSet {
        id
        tags
        schedules {
          ...ScheduleFragment
        }
      }
    }
    dataSetScheduleCreate(
      projectId: $projectId
      dataSetId: $dataSetId
      schedule: $updatedSchedule
    ) {
      ... on ScheduledDataSet {
        id
        tags
        schedules {
          ...ScheduleFragment
        }
      }
    }
  }
`);

type TypeNames =
  | Update_Data_SetMutation["dataSetScheduleCreate"]["__typename"]
  | Update_Data_SetMutation["dataSetScheduleDelete"]["__typename"];

const verifyTypeName = (typename: TypeNames) => {
  switch (typename) {
    case "DataSetNotFound":
      throw new Error("Data set not found");
    case "DataSetNotScheduled":
      throw new Error("Static data sets cannot have schedules");
    case "DataSetScheduleNotFound":
      throw new Error("Schedule you tried to edit does not exist");
    case "ScheduledDataSet":
      return;
    default:
      throw new Error("Error in the backend");
  }
};

export const useUpdateSchedule = () => {
  const mutationTuple = useMutation(UPDATE_DATA_SET);

  const mutationFn = (...args: Parameters<(typeof mutationTuple)[0]>) => {
    return new Promise((resolve, reject) => {
      mutationTuple[0](...args)
        .then((response) => {
          if (response.errors || !response.data) {
            console.error(response.errors);
            reject(new Error("Error in the backend"));
          }
          try {
            verifyTypeName(response.data?.dataSetScheduleCreate.__typename);
            verifyTypeName(response.data?.dataSetScheduleDelete.__typename);
          } catch (error) {
            if (error instanceof Error) reject(error);
            else reject(new Error("Error in the backend"));
          }
          resolve(response);
        })
        .catch(reject);
    });
  };
  return [mutationFn, mutationTuple[1]] as typeof mutationTuple;
};
