import { Navigate, Outlet } from "react-router-dom";
import { useFallbackNavigate } from "../hooks/fallbackNavigate";
import { useAllowDashboardAccess } from "./utils/allowDashboardAccess";

const DashboardProtectedRoute = () => {
  const allowDashboardAccess = useAllowDashboardAccess();
  const fallback = useFallbackNavigate();

  if (!allowDashboardAccess) {
    console.error(`The user does not have access to Dashboards at: ${window.location.href}`);
    return <Navigate to={fallback} replace />;
  }

  return <Outlet />;
};

export default DashboardProtectedRoute;
