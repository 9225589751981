// Add tailwindcolors to this array if we create new custom colors
const tailwindColors = [
  "inherit",
  "current",
  "transparent",
  "black",
  "white",
  "slate",
  "gray",
  "zinc",
  "neutral",
  "stone",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
  "green-numerous",
  "gray",
  "trueGray",
  "lightGreen",
].join("|");

/**
 * Finds out if a type of tailwind class with a color is in a string.
 * @param {string} property
 * The property you are looking for e.g. "text", "ring", "bg"
 * @param {string} stringToSearch
 * The string you are looking in
 * @returns True if the string contains a class of the property with a color attached
 * False if it doesn't contain it
 */
// Can be used to see if a string contains a tailwindclass with a color
// e.g regexMatchTailwindPropertyColor("text", ...) will match if a text-color is defined
export const regexMatchTailwindPropertyWithColor = (
  property: string,
  stringToSearch: string
) => {
  //Spaces are added to RegExp and string to ensure it doesn't catch non-applicable classes e.g. "text-emerald-300text-align"
  const expression = new RegExp(` ${property}-(${tailwindColors})(-[1-9]0{1,2})? `, "g");

  return ` ${stringToSearch} `.search(expression) >= 0;
};
