import { Toolbox, Info, Sliders, UploadSimple } from "@phosphor-icons/react";
import { CellContext } from "@tanstack/react-table";
import Link from "Components/AppNavigation/Link";
import ParagraphLineClamp from "Components/Basic/ParagraphLineClamp";
import ScenarioLabelTag from "Components/Scenario/Label/ScenarioLabelTag";
import { useLocalSimulationModel, useScenario } from "api/useFirebase";
import React from "react";
import Tooltip from "thermo/tooltip/Tooltip";
import { IScenarioTable } from "./types";

interface IScenarioNameCell {
  cell: CellContext<IScenarioTable, string>;
}

const ScenarioNameCell: React.FC<IScenarioNameCell> = ({ cell }) => {
  const system = useLocalSimulationModel(
    cell.row.original.systemID,
    cell.row.original.scenarioObj.projectID,
    cell.row.original.scenarioObj.id
  );
  const optimizationScenarioID = cell.row.original.scenarioObj.optimizationScenarioID || "";
  const { scenario: optimizationScenario } = useScenario(
    cell.row.original.scenarioObj.projectID,
    optimizationScenarioID
  );
  // Get Optimization Type Name
  const optimizationSystem = useLocalSimulationModel(
    optimizationScenario?.systemID,
    optimizationScenario?.projectID,
    optimizationScenario?.id
  );

  return (
    <div data-testid="scenarioRowScenarioName" className="flex items-center justify-between">
      <ParagraphLineClamp maxLines={1}>{cell.getValue()}</ParagraphLineClamp>
      <div className="flex items-center gap-2 text-gray-600">
        {cell.row.original.scenarioObj.isArchived && <UploadSimple size={20} />}
        {cell.row.original.scenarioObj.label?.label_id && (
          <div>
            <ScenarioLabelTag labelInfo={cell.row.original.scenarioObj.label} />
          </div>
        )}
        {cell.row.original.scenarioObj.description && (
          <Tooltip label={cell.row.original.scenarioObj.description}>
            <Info size={20} />
          </Tooltip>
        )}
        {optimizationSystem && optimizationSystem.displayName && (
          <Tooltip label={optimizationSystem.displayName}>
            <div onClick={(e) => e.stopPropagation()}>
              <Link
                to={`/project/${optimizationScenario?.projectID}/${optimizationScenarioID}`}
              >
                <Sliders size={18} />
              </Link>
            </div>
          </Tooltip>
        )}
        {system?.displayName && (
          <Tooltip label={system?.displayName}>
            <Toolbox size={20} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ScenarioNameCell;
