interface Props {
  className?: string;
}

const RemoveTag = ({ className }: Props) => (
  <svg
    width="193"
    height="193"
    viewBox="0 0 193 193"
    fill="none"
    className={className || ""}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_307_14)">
      <path
        d="M40.5653 116.318C39.8683 115.623 20.1587 96.1688 19.871 95.227C19.5832 94.2853 19.5317 93.2872 19.7209 92.3208L31.7959 31.7958L92.3209 19.7208C93.2872 19.5316 94.2853 19.5831 95.2271 19.8709C96.1688 20.1587 115.908 39.5559 116.603 40.253M62.9702 138.709C63.522 139.273 100.882 177.034 101.61 177.341C102.337 177.647 103.119 177.805 103.908 177.805C104.698 177.805 105.479 177.647 106.207 177.341C106.935 177.034 107.594 176.585 108.146 176.021L142.083 142.083L176.021 108.146C176.586 107.594 177.034 106.935 177.341 106.207C177.647 105.479 177.805 104.698 177.805 103.908C177.805 103.119 177.647 102.337 177.341 101.609C177.034 100.882 139.657 63.9267 139.092 63.3749"
        stroke="currentColor"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.2959 75.2958C69.9233 75.2958 75.2959 69.9233 75.2959 63.2958C75.2959 56.6684 69.9233 51.2958 63.2959 51.2958C56.6685 51.2958 51.2959 56.6684 51.2959 63.2958C51.2959 69.9233 56.6685 75.2958 63.2959 75.2958Z"
        fill="currentColor"
      />
      <path
        d="M164.501 36.8538L38.4942 163.132"
        stroke="currentColor"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_307_14">
        <rect
          width="192"
          height="192"
          fill="currentColor"
          transform="translate(0.295876 0.295837)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RemoveTag;
