import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";
import { OrganizationQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const GET_PROJECT_TEMPLATES = graphql(`
  query Organization($after: String, $first: Int!) {
    organization {
      id
      projectTemplates(after: $after, first: $first) @connection(key: "ProjectTemplates") {
        edges {
          cursor
          node {
            ... on FixedGroupsProjectTemplate {
              id
              type
              description
              parameters {
                ...ConfigParameterFragment
                ...BooleanParameterFragment
                ...CurrencyParameterFragment
                ...StringParameterFragment
                ...NumberParameterFragment
                ...MapLocationParameterFragment
                ...JsonParameterFragment
                ...TimeParameterFragment
                ...DataTableParameterFragment
                ...MonthParameterFragment
                ...ComponentReferenceParameterFragment
                ...NumberSelectorParameterFragment
                ...StringSelectorParameterFragment
                ...FileParameterFragment
              }
              groups {
                __typename
                groupName
              }
            }
            ... on FixedSystemsProjectTemplate {
              id
              type
              description
              parameters {
                ...ConfigParameterFragment
                ...BooleanParameterFragment
                ...CurrencyParameterFragment
                ...StringParameterFragment
                ...NumberParameterFragment
                ...MapLocationParameterFragment
                ...JsonParameterFragment
                ...TimeParameterFragment
                ...DataTableParameterFragment
                ...MonthParameterFragment
                ...ComponentReferenceParameterFragment
                ...NumberSelectorParameterFragment
                ...StringSelectorParameterFragment
                ...FileParameterFragment
              }
              scenarioConfig {
                __typename
                simulationName
                defaultSystem {
                  systemId
                  variantId
                }
                systems {
                  systemId
                  variants
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
      }
    }
  }
`);

type Props = {
  first: number;
};

export const useGetProjectTemplates = ({ first }: Props) => {
  const result = useQuery(GET_PROJECT_TEMPLATES, {
    variables: { first, after: null },
    fetchPolicy: "cache-and-network",
  });

  return result;
};

type ProjectTemplates = OrganizationQuery["organization"]["projectTemplates"]["edges"];
export type ProjectTemplate = ArrayElement<ProjectTemplates>["node"];
