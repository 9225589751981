import { useActiveOrganizationId } from "Components/Organisation/hooks/useActiveOrganizationId";
import { useDeprecatedFirestore } from "api/useFirebase";
import { PublicLink, ScenarioOutputFile, SimulationScenario } from "model/datatypes";
import { useState } from "react";
import toast from "react-hot-toast";
import useCopyToClipboard from "utils/clipboard/copyToClipboard";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

type Props = {
  publicLink: PublicLink | undefined;
  scenario: SimulationScenario;
  file: ScenarioOutputFile;
};

export const useCreatePublicLink = ({ publicLink, scenario, file }: Props) => {
  const [creatingPublicLink, setCreatingPublicLink] = useState(false);

  const activeOrganizationId = useActiveOrganizationId();
  const fs = useDeprecatedFirestore();
  const [, copyToClipboard] = useCopyToClipboard({ itemTypeToCopy: "The public link" });
  const createPublicLink = () => {
    if (creatingPublicLink || !!publicLink || !activeOrganizationId) return;

    setCreatingPublicLink(true);
    const linkDoc = fs.collection("Links").doc();
    const link: PublicLink = {
      id: linkDoc.id,
      organisation: activeOrganizationId,
      scenario: scenario.id,
      project: scenario.projectID,
      file,
    };

    linkDoc
      .set(convertToFirestoreFormat(link))
      .then(() => {
        setCreatingPublicLink(false);
        const baseURL = `${window.location.protocol}//${window.location.host}`;
        copyToClipboard(`${baseURL}/links/${link.id}`, true);
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error creating the public link");
        setCreatingPublicLink(false);
      });
  };
  return { createPublicLink, creatingPublicLink };
};
