import { Check } from "@phosphor-icons/react";
import { WorkspacesQuery } from "GraphQL/gql/graphql";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";

export const ProjectMoveWorkspaceListItem: React.FC<{
  workspace: ArrayElement<WorkspacesQuery["workspaces"]>;
  onClick: (workspaceID: string) => void;
  activeWorkspaceID: string | null | undefined;
}> = ({ workspace, onClick, activeWorkspaceID }) => {
  const isProjectsCurrentWorkspace = workspace.id === activeWorkspaceID;

  return (
    <button
      data-testid="moveToWorkspaceSelectButton"
      onClick={() => onClick(workspace.id)}
      className="cursor-pointer whitespace-nowrap rounded-lg text-start flex justify-between items-center px-2 py-1.5 text-sm 
       hover:bg-gray-100 focus:bg-gray-100"
    >
      <div className="">{workspace.name}</div>
      {isProjectsCurrentWorkspace && <Check weight="bold" size={15} />}
    </button>
  );
};
