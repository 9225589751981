import React from "react";
import Dropdown from "./Dropdown/Dropdown";

interface Props {
  value: boolean;
  onChange: (newVal: boolean) => void;
  className?: string;
}

const BooleanDropdown: React.FC<Props> = ({ value, onChange, className = "" }) => (
  <Dropdown
    className={`bg-white ${className}`}
    options={[
      { id: "true", display: "True", value: true },
      { id: "false", display: "False", value: false },
    ]}
    selectedID={value?.toString()}
    onSelect={(selected) => {
      onChange(selected.value);
    }}
  />
);

export default BooleanDropdown;
