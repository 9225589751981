import React from "react";

interface Props {
  progress: number;
}
const ProgressBar = ({ progress }: Props) => {
  if (typeof progress === "number")
    return (
      <div className="relative mr-4 h-5 w-40 overflow-hidden rounded border border-gray-200 bg-gray-400">
        <div className="h-full bg-green-400" style={{ width: `${progress}%` }} />
        <div className="absolute top-0 left-0 z-20 flex h-full w-full items-center justify-center text-sm text-white" />
      </div>
    );
  return <div>Invalid progressbar format...</div>;
};
export default ProgressBar;
