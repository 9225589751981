import Badge from "Components/Basic/Badge";
import React from "react";

type Props = {
  available?: boolean;
};

const DataOutputColumn = ({ available }: Props) => {
  const badgeText = available ? "Available" : "No data yet";
  const textColor = available ? "text-green-800" : "text-yellow-800";
  const backgroundColor = available ? "bg-green-100" : "bg-yellow-100";

  return <Badge text={badgeText} backgroundColor={backgroundColor} textColor={textColor} />;
};

export default DataOutputColumn;
