import { IToggle } from "@unleash/proxy-client-react";
import { UnleashClient } from "unleash-proxy-client";

const localFeatureFlags: IToggle[] = [
  {
    enabled: true,
    name: "enableCredit",
    variant: {
      enabled: false,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: false,
    name: "showTestingPortfolios",
    variant: {
      enabled: false,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: false,
    name: "backend_is_under_maintenance",
    variant: {
      enabled: false,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: false,
    name: "OptimizationResultsGraphQL",
    variant: {
      enabled: false,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: false,
    name: "highlightWorkspaces",
    variant: {
      enabled: false,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: false,
    name: "canUseIFrameComponents",
    variant: {
      enabled: true,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: true,
    name: "showDarkModeToggle",
    variant: {
      enabled: true,
      name: "",
    },
    impressionData: false,
  },
  {
    enabled: true,
    name: "showGitlabIntegration",
    variant: {
      enabled: false,
      name: "",
    },
    impressionData: false,
  },
];

const unleashUrl =
  import.meta.env.MODE === "development"
    ? "https://unleash.numerously.com/proxy/development"
    : "https://unleash.numerously.com/proxy/production";

export const mockUnleashClient = new UnleashClient({
  url: "https://unleash.numerously.com/proxy/development",
  clientKey: "local",
  appName: "local",
  bootstrap: localFeatureFlags, // add local feature flags to this variable to control them in local development
});

export const unleashClient = new UnleashClient({
  url: unleashUrl,
  clientKey: "s$Z6>iRTyot]DRbfkBq}C0a0)bZhb8It",
  refreshInterval: 10,
  environment: "development",
  appName: "numerous",
});
