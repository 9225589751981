import { Gear, Info } from "@phosphor-icons/react";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { SidebarButtonWrapper } from "Components/Sidebar/SidebarButtonWrapper";
import WorkspaceSettings from "Components/Workspace/Administration/Settings/WorkspaceSettings";
import { WorkspaceRole } from "GraphQL/gql/graphql";
import { Workspace as WorkspaceType } from "api/workspace/getWorkspaces";
import { AnimatePresence, Reorder, useDragControls } from "framer-motion";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalDispatch, useGlobalState } from "store";
import Tooltip from "thermo/tooltip/Tooltip";
import Text from "thermo/typography/Typography";

export type GeneralWorkspace = {
  __typename: "GeneralWorkspace";
  id: null;
  name: "General";
  description: string;
};

type Props = {
  workspace: WorkspaceType | GeneralWorkspace;
  icon: JSX.Element;
  refetchWorkspaces: () => void;
};

export const Workspace = ({ workspace, icon, refetchWorkspaces }: Props) => {
  const controls = useDragControls();
  const navigate = useNavigateApp();
  const { pathname } = useLocation();
  const dispatch = useGlobalDispatch();
  const { activeWorkspaceId } = useGlobalState();
  const { id: userId } = useGlobalUser();
  const organizationSlug = useOrganizationSlugFromParams();

  const [showWorkspaceSettings, setShowWorkspaceSettings] = useState(false);

  // The logged in user in the workspace members array
  const userInWorkspace =
    workspace.__typename === "Workspace" &&
    workspace.members &&
    workspace.members.find((member) => member.user?.id === userId);
  const isWorkspaceOwner = userInWorkspace && userInWorkspace.role === WorkspaceRole.Owner;
  const isGeneralWorkspace = workspace.__typename === "GeneralWorkspace";

  return (
    <Reorder.Item
      dragListener={false}
      value={workspace}
      key={workspace.id}
      dragControls={controls}
    >
      <SidebarButtonWrapper
        isActive={
          activeWorkspaceId === workspace.id &&
          (pathname === `/${organizationSlug}/workspaces` ||
            pathname.includes("/project/") ||
            pathname.includes("/create-project"))
        }
      >
        <button
          onClick={() => {
            dispatch({
              type: "SET_ACTIVE_WORKSPACE_ID",
              payload: workspace.id,
            });
            navigate("/workspaces");
            refetchWorkspaces();
          }}
          data-testid="workspaceButton"
          className="flex w-full relative items-center px-2 py-1 select-none"
        >
          {/* To be enabled when workspaces have a sorting order field. NUM-776 */}
          {/* <DotsSixVertical
            onPointerDown={(e) => {
              e.stopPropagation();
              controls.start(e);
            }}
            onClick={(e) => e.stopPropagation()}
            className="absolute cursor-move -left-[0.96rem] top-1/2 -translate-y-1/2
          opacity-0 group-hover/item:opacity-100 transition-opacity duration-75"
          /> */}
          <span
            data-testid="workspaceIcon"
            className="transition-all duration-100 group-hover/sidebar:text-gray-900"
          >
            {icon}
          </span>
          <Text.Base data-testid="workspaceName" className="ml-2 truncate text-left flex-1">
            {workspace.name}
          </Text.Base>
          {workspace.description && (
            <Tooltip data-testid="workspaceDescriptionTooltip" label={workspace.description}>
              <Info
                size={16}
                className="text-gray-500 hover:text-gray-900 opacity-0 group-hover/item:opacity-100 transition-opacity duration-75"
              />
            </Tooltip>
          )}
          {!isGeneralWorkspace && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowWorkspaceSettings(true);
              }}
              className="ml-2 opacity-0 group-hover/item:opacity-100 transition-opacity duration-75"
              data-testid="workspaceSettingsButton"
            >
              <Gear size={14} className="text-gray-500 hover:text-gray-900" />
            </div>
          )}
        </button>

        <AnimatePresence>
          {showWorkspaceSettings && workspace.__typename === "Workspace" && (
            <WorkspaceSettings
              isWorkspaceOwner={isWorkspaceOwner || false}
              workspace={workspace}
              close={() => setShowWorkspaceSettings(false)}
            />
          )}
        </AnimatePresence>
      </SidebarButtonWrapper>
    </Reorder.Item>
  );
};
