import useLocalStorage from "Components/hooks/useLocalStorage";
import { useUserRole } from "api/useFirebase";
import { useGetWorkspaces } from "api/workspace/getWorkspaces";
import { GeneralWorkspace } from "./Workspace/Workspace";
import { WorkspaceListHeader } from "./WorkspaceListHeader/WorkspaceListHeader";
import { Workspaces } from "./Workspaces";

export const generalWorkspace: GeneralWorkspace = {
  __typename: "GeneralWorkspace",
  description: "The General workspace is shared by all members of your organization",
  id: null,
  name: "General",
};

export const WorkspaceList = () => {
  const { data, loading, error, refetch } = useGetWorkspaces();
  const [showWorkspaceList, setShowWorkspaceList] = useLocalStorage("showWorkspaceList", true);
  const { hasProjectAccess } = useUserRole();

  if (error) {
    console.error(error);
    return null;
  }
  if (!hasProjectAccess) return null;
  return (
    <div className="mt-2">
      <WorkspaceListHeader
        isShowingWorkspaceList={showWorkspaceList}
        toggleShowWorkspaceList={() => setShowWorkspaceList((prev) => !prev)}
        loading={loading}
        error={!!error}
      />
      {showWorkspaceList && data && (
        <Workspaces
          refetchWorkspaces={() => refetch()}
          key={data.workspaces.toString()}
          workspaces={[generalWorkspace, ...data.workspaces]}
        />
      )}
    </div>
  );
};
