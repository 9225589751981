import { ActivityList } from "Components/Repositories/Activity/ActivityList";
import React from "react";
import Drawer from "thermo/drawer/Drawer";
import Tabs from "thermo/tabs/Tabs";
import { RepositoryCommits } from "../Commits/RepositoryCommits";

const TAB_REPOSITORY_ACTIVITY = "repositoryActivity";
const TAB_COMMITS = "commits";
// const TAB_DETAILS = "details";

type Props = {
  isOpen: boolean;
  setIsOpen: () => void;
};

export const RepositoryDrawer = ({ isOpen, setIsOpen }: Props) => {
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="mr-4 w-96">
        <Tabs
          defaultValue={TAB_REPOSITORY_ACTIVITY}
          triggers={
            <>
              <Tabs.Trigger value={TAB_REPOSITORY_ACTIVITY}>Activity</Tabs.Trigger>
              <Tabs.Trigger value={TAB_COMMITS}>Commits</Tabs.Trigger>
              {/* <Tabs.Trigger value={TAB_DETAILS}>Details</Tabs.Trigger> */}
            </>
          }
        >
          <div className="mt-4">
            <Tabs.Content value={TAB_REPOSITORY_ACTIVITY}>
              <ActivityList />
            </Tabs.Content>
            <Tabs.Content value={TAB_COMMITS}>
              <RepositoryCommits />
            </Tabs.Content>
            {/* <Tabs.Content value={TAB_DETAILS}>Details</Tabs.Content> */}
          </div>
        </Tabs>
      </div>
    </Drawer>
  );
};
