import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { zModal } from "thermo/zIndex/zIndexValues";
import { useKeyPressAction } from "utils/hooks/useKeyPress";
import classNames from "utils/jsUtils/className";
import { ConditionalWrapper } from "./ConditionalWrapper";
import RootPortal from "./RootPortal";

interface Props {
  onClose: () => void;
  className?: string;
  open?: boolean;
  noPadding?: true;
  cardColor?: string;
}

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  children,
  className = "",
  open: openProp,
  noPadding,
  cardColor = "white",
  ...htmlProps
}) => {
  // Prevent body scroll while modal is open
  useEffect(() => {
    const html: HTMLElement = document.documentElement;

    const scrollAlreadyLocked = html.style.cssText.indexOf("overflow: hidden;") >= 0;
    if (openProp === false || scrollAlreadyLocked) return undefined;
    html.style.cssText = "overflow: hidden; padding-right: 0px;";

    return () => {
      html.style.cssText = "";
    };
  }, [openProp]);

  useKeyPressAction({ targetKey: "Escape", fn: onClose });

  return (
    <ConditionalWrapper
      condition={openProp !== undefined}
      wrapper={(conditionalWrapperChildren) => (
        <AnimatePresence initial={false} mode="wait">
          {conditionalWrapperChildren}
        </AnimatePresence>
      )}
    >
      {(openProp || openProp === undefined) && (
        <RootPortal>
          <motion.div
            key="numerous-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.175 }}
            className="fixed inset-0 overflow-y-auto"
            style={{ zIndex: zModal }}
          >
            <div
              id="numerous-modal"
              className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                className="fixed top-0 left-0 h-full w-full overflow-hidden bg-gray-800 opacity-20"
                data-testid="modalBackdrop"
              />
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <motion.div
                key="numerous-modal-card"
                initial={{ y: "1rem" }}
                animate={{ y: "0rem" }}
                exit={{ y: "1rem" }}
                onClick={(e) => e.stopPropagation()}
                transition={{ bounce: 1, duration: 0.15 }}
                {...htmlProps}
                className={classNames(
                  `sm:max-w-11/12 inline-block  rounded-lg text-left align-bottom shadow-xl sm:my-8 sm:align-middle`,
                  !noPadding && "px-2 pt-3 pb-2 sm:p-6",
                  `bg-${cardColor}`,
                  className
                )}
                data-testid="modal"
              >
                {children}
              </motion.div>
            </div>
          </motion.div>
        </RootPortal>
      )}
    </ConditionalWrapper>
  );
};

export default Modal;
