import { CloudProject } from "Components/Providers/Firebase/cloudConfig";
import React, { createContext, useContext, useReducer } from "react";

type AuthStatusTypes = "pending" | "authenticated" | "error" | "not-authenticated";

export type AppState = {
  cloudProject?: CloudProject;
  grpcURL: string;
  inLocalDevMode?: boolean;
  tutorialOpen: boolean;
  activeWorkspaceId: string | null;
  darkModeEnabled: boolean;
  authStatus: AuthStatusTypes | null;
};

export type ActionType =
  | {
      type: "SET_CLOUD_PROJECT";
      payload: CloudProject;
    }
  | { type: "SET_IN_DEV_MODE" }
  | {
      type: "TOGGLE_TUTORIAL_OPEN";
      payload: boolean;
    }
  | { type: "SET_ACTIVE_WORKSPACE_ID"; payload: string | null }
  | { type: "TOGGLE_SIDEBAR_OPEN"; payload: undefined | boolean }
  | { type: "TOGGLE_DARK_MODE" }
  | { type: "SET_AUTH_STATUS"; payload: AuthStatusTypes };

const initialState: AppState = {
  cloudProject: undefined,
  grpcURL: "",
  tutorialOpen: true,
  activeWorkspaceId: localStorage.getItem("activeWorkspaceId"),
  darkModeEnabled: localStorage.getItem("darkModeEnabled") === "true",
  authStatus: null,
};

type GlobalStateReducer = (state: AppState, action: ActionType) => AppState;

const reducer: GlobalStateReducer = (state: AppState, action: ActionType): AppState => {
  switch (action.type) {
    case "SET_IN_DEV_MODE":
      return { ...state, inLocalDevMode: true };
    case "SET_CLOUD_PROJECT":
      return {
        ...state,
        cloudProject: action.payload,
        grpcURL: action.payload.api,
      };
    case "TOGGLE_TUTORIAL_OPEN":
      return {
        ...state,
        tutorialOpen: action.payload,
      };
    case "SET_ACTIVE_WORKSPACE_ID":
      if (action.payload) {
        localStorage.setItem("activeWorkspaceId", action.payload);
      } else {
        localStorage.removeItem("activeWorkspaceId");
      }
      return {
        ...state,
        activeWorkspaceId: action.payload,
      };
    case "TOGGLE_DARK_MODE": {
      const { darkModeEnabled } = state;
      localStorage.setItem("darkModeEnabled", (!darkModeEnabled).toString());
      return {
        ...state,
        darkModeEnabled: !darkModeEnabled,
      };
    }
    case "SET_AUTH_STATUS":
      return {
        ...state,
        authStatus: action.payload,
      };
    default:
      return state;
  }
};

type ContextState = { state: AppState; dispatch: React.Dispatch<ActionType> };

const store = createContext<ContextState>({ state: initialState, dispatch: () => {} });

const StateProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <store.Provider value={{ state, dispatch }}>{children}</store.Provider>;
};

const useGlobalState = () => {
  const { state } = useContext(store);
  return state;
};

const useGlobalDispatch = () => {
  const { dispatch } = useContext(store);
  return dispatch;
};

export { store, StateProvider, useGlobalState, useGlobalDispatch };
