/* eslint-disable import/no-cycle */
import { useOrganizationSlugFromParams } from "Components/Organisation/hooks/useOrganizationSlugFromParams";
import { useGlobalUser } from "Components/Providers/User/UserProvider";
import { useIdToken } from "api/useFirebase";
import { fromUnixTime } from "date-fns";
import { ReadScenario, ScenarioMetaData } from "grpc/client/spm_pb";
import { TimeInfo } from "model/datatypes";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useGlobalState } from "store";
import getUUID from "utils/jsUtils/getUUID";
import {
  getDatasetClosed,
  getGRPCClient,
  getOptions,
  readDataExample,
  readLatestMainExecution,
  readTimeInfo,
} from "./grpcClient";

export type Dataframe = {
  [key: string]: number[];
};

const useLatestExecutionID = (
  projectID?: string,
  scenarioID?: string,
  organizationSlug?: string
) => {
  const { grpcURL } = useGlobalState();

  const idToken = useIdToken();
  const [executionID, setExecutionID] = useState<string | null>(null);
  useEffect(() => {
    if (!idToken || !projectID || !scenarioID || !organizationSlug) return;
    readLatestMainExecution(grpcURL, idToken, projectID, scenarioID, organizationSlug)
      .then((executionId) => {
        setExecutionID(executionId);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [scenarioID, projectID, grpcURL, idToken, organizationSlug]);
  return executionID;
};

const streamDataFromScenario = (
  configuration: {
    grpcURL: string;
    idToken: string;
    scenarioID: string;
    projectID: string;
    executionID?: string;
    tags: string[];
    listen: boolean;
    organizationSlug: string;
  },
  onReadData: (updated: Dataframe) => void,
  onEnd: () => void,
  onError: (error: any) => void
) => {
  const {
    executionID,
    grpcURL,
    idToken,
    listen,
    projectID,
    scenarioID,
    tags,
    organizationSlug,
  } = configuration;
  const abortController = new AbortController();
  const readScenario = ReadScenario.create({
    listen,
    project: projectID,
    scenario: scenarioID,
    tags,
    ...(executionID ? { execution: executionID } : {}),
  });

  const stream = getGRPCClient(grpcURL, idToken, organizationSlug).readData(readScenario, {
    ...getOptions(idToken, organizationSlug),
    abort: abortController.signal,
  });

  const data: Dataframe = {};
  let hasHadFirstContact = false;
  let hasReceivedNewResponse = false;

  const interval = setInterval(() => {
    if (hasReceivedNewResponse) {
      onReadData({ ...data });
      hasReceivedNewResponse = false;
    }
  }, 2000);

  const unsubOnNext = stream.responses.onNext((list) => {
    if (!list) return;

    list.data.forEach((block) => {
      const prev = data[block.tag];
      if (Array.isArray(prev)) data[block.tag] = [...prev, ...block.values];
      else data[block.tag] = block.values;
    });
    if (!hasHadFirstContact) {
      onReadData({ ...data });
      hasHadFirstContact = true;
    } else {
      hasReceivedNewResponse = true;
    }
  });
  const unsubOnError = stream.responses.onError((error) => {
    clearInterval(interval);
    onError(error);
  });

  stream.status.then((status) => {
    unsubOnError();
    unsubOnNext();
    clearInterval(interval);
    if (status.code === "OK") {
      onReadData({ ...data });
      onEnd();
    } else {
      console.error(status.code);
      console.error(status.detail);
    }
  });

  return () => {
    abortController.abort();
    clearInterval(interval);
  };
};

export const useGRPCData = (
  tags: string[],
  projectID: string,
  scenarioID: string,
  executionID?: string,
  organizationSlug?: string
) => {
  const cachedData = useRef<Dataframe>({});

  const user = useGlobalUser();
  const { grpcURL } = useGlobalState();
  const idToken = useIdToken();

  const [loadingData, setloadingData] = useState(false);
  const [data, setData] = useState<Dataframe | null>(null);
  const [time, setTime] = useState<number[] | null>(null);

  const latestExecutionID = useLatestExecutionID(projectID, scenarioID, organizationSlug);

  useEffect(() => {
    const loadData = async () => {
      try {
        //Load through GRPC!:
        if (
          tags.length === 0 ||
          !user ||
          !executionID ||
          !latestExecutionID ||
          !idToken ||
          !organizationSlug
        ) {
          setloadingData(false);
          setData(null);
          setTime(null);
          return;
        }

        const cached: Dataframe = {};
        const missing: string[] = [];
        tags.forEach((tag) => {
          const cacheData = cachedData.current[tag];
          if (cacheData) {
            cached[tag] = cacheData;
          } else missing.push(tag);
        });
        if (missing.length === 0) {
          setData(cached);
          return;
        }

        //Load the data missing:
        setloadingData(true);

        const loadedData = await readDataExample({
          grpcURL,
          idToken,
          organizationSlug,
          scenarioID,
          projectID,
          executionID: executionID || latestExecutionID,
          tags,
        });

        setloadingData(false);
        setData({ ...loadedData, ...cached });
        const t = loadedData._index;
        if (t) setTime(t);
        cachedData.current = { ...cachedData.current, ...loadedData };
      } catch (error) {
        setloadingData(false);
        setData(null);
        setTime(null);
        toast.error(`The data could not be loaded - ${error}`);
      }
    };
    loadData();
  }, [
    tags,
    scenarioID,
    projectID,
    latestExecutionID,
    executionID,
    grpcURL,
    user,
    idToken,
    organizationSlug,
  ]);
  return { loadingData, data, time };
};

export const useGRPCDataStream = (
  configuration: {
    listen: boolean;
    scenarioID: string;
    projectID: string;
    executionID?: string;
    organizationSlug?: string;
  },
  tags: string[]
) => {
  const { grpcURL } = useGlobalState();

  const latestExecutionID = useLatestExecutionID(
    configuration.projectID,
    configuration.scenarioID,
    configuration.organizationSlug
  );

  const executionID = useMemo(
    () => configuration.executionID || latestExecutionID || undefined,
    [latestExecutionID, configuration.executionID]
  );

  const [data, setData] = useState<Dataframe | null>(null);
  const [time, setTime] = useState<number[] | null>(null);

  //internal hook variables for keeping track of open streams
  const existingTagsRef = useRef<string[]>([]); //tags in open connection
  const connectionRef = useRef<{ id: string; close: () => void }[]>([]); //functions for closing connections
  const [activeStreams, setActiveStreams] = useState<string[]>([]); //active streams to see if any connections are open

  const streamActive = useMemo(() => activeStreams.length > 0, [activeStreams]);

  const idToken = useIdToken();

  const [conf, setConf] = useState<{
    listen: boolean;
    scenarioID: string;
    projectID: string;
    executionID: string;
    grpcURL: string;
    idToken: string;
  } | null>(null);

  //sett configuration hook, and close the connection when the configuration changes.
  useEffect(() => {
    if (executionID && grpcURL && idToken) {
      setConf({
        listen: configuration.listen,
        scenarioID: configuration.scenarioID,
        projectID: configuration.projectID,
        executionID,
        grpcURL,
        idToken,
      });
    }

    return () => {
      setData(null);
      setTime(null);
      //close connections on change
      connectionRef.current.forEach((connection) => {
        connection.close();
      });
      connectionRef.current = [];
      existingTagsRef.current = [];
    };
  }, [
    configuration.listen,
    configuration.scenarioID,
    configuration.projectID,
    executionID,
    grpcURL,
    idToken,
  ]);

  //where the data loading happens:
  useEffect(() => {
    const orgSlug = configuration.organizationSlug;
    if (tags.length === 0 || !conf || !orgSlug) return;
    //get all tags to open a new connection for:s
    const newTags = tags.filter((tag) => !existingTagsRef.current.some((t) => t === tag));
    existingTagsRef.current = [...existingTagsRef.current, ...newTags];

    //create a connection with supplied tags and set as active connection until finished or closed
    const createConnection = (_tags: string[]) => {
      const connectionID = getUUID();
      setActiveStreams((prev) => [...prev, connectionID]);

      const onReadData = (updated: Dataframe) => {
        const t = updated._index;
        if (t) {
          setTime((prev) => {
            if (prev && prev.length > t.length) return prev;
            return [...t];
          });
        }
        setData((prev) => {
          if (!prev) return { ...updated };
          return { ...prev, ...updated };
        });
      };

      const onEnd = () => {
        setActiveStreams((prev) => prev.filter((id) => id !== connectionID));
        connectionRef.current = connectionRef.current.filter((con) => con.id !== connectionID);
      };

      const onError = (error: any) => {
        if (error === "timeout") {
          toast.error("The stream timed out before receiving an initial response");
        } else {
          toast.error("There was an error loading the data");
        }
        setActiveStreams((prev) => prev.filter((id) => id !== connectionID));
        connectionRef.current = connectionRef.current.filter((con) => con.id !== connectionID);
      };

      const cancelStream = streamDataFromScenario(
        {
          executionID: conf.executionID,
          scenarioID: conf.scenarioID,
          projectID: conf.projectID,
          listen: conf.listen,
          tags: _tags,
          grpcURL: conf.grpcURL,
          idToken: conf.idToken,
          organizationSlug: orgSlug,
        },
        onReadData,
        onEnd,
        onError
      );

      connectionRef.current = [
        ...connectionRef.current,
        {
          id: connectionID,
          close: cancelStream,
        },
      ];
    };

    if (newTags.length > 0) createConnection(newTags);
  }, [conf, tags, configuration.organizationSlug]);

  return { data, time, streamActive };
};

export const useDataInfo = (
  projectId: string,
  scenarioId: string,
  executionId?: string,
  customOffset?: number
) => {
  const { grpcURL } = useGlobalState();

  const idToken = useIdToken();
  const organizationSlug = useOrganizationSlugFromParams();
  const [timeInfo, setTimeInfo] = useState<TimeInfo | null>(null);
  const [scenarioMetadata, setScenarioMetadata] = useState<ScenarioMetaData | null>(null);
  const [producingData, setProducingData] = useState<null | boolean>(null);

  const latestExecutionID = useLatestExecutionID(projectId, scenarioId, organizationSlug);

  const activeExecution = useMemo(
    () => executionId || latestExecutionID,
    [executionId, latestExecutionID]
  );

  //set the offsets based on custom value and loaded time info
  useEffect(() => {
    if (!idToken || !activeExecution || !organizationSlug) return;
    readTimeInfo(grpcURL, idToken, organizationSlug, projectId, scenarioId, activeExecution)
      .then((res) => {
        setTimeInfo(res.timeInfo);
        setScenarioMetadata(res.rawMetadata);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [grpcURL, idToken, projectId, scenarioId, activeExecution, organizationSlug]);

  //set the offsets based on custom value and loaded time info
  useEffect(() => {
    if (customOffset && timeInfo && timeInfo.offset !== customOffset) {
      const startTimeVal = timeInfo.scenarioStats.min + customOffset;
      const endTimeVal = timeInfo.scenarioStats.max + customOffset;
      setTimeInfo({
        ...timeInfo,
        offset: customOffset,
        startTime: fromUnixTime(startTimeVal),
        endTime: fromUnixTime(endTimeVal),
      });
    }
  }, [customOffset, timeInfo]);

  useEffect(() => {
    if (!idToken || !organizationSlug) return;
    getDatasetClosed(grpcURL, idToken, organizationSlug, projectId, scenarioId, executionId)
      .then((closed) => {
        setProducingData(!closed);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [grpcURL, idToken, projectId, scenarioId, executionId, organizationSlug]);

  return { timeInfo, scenarioMetadata, producingData };
};
