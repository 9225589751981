import { CaretRight, UsersThree } from "@phosphor-icons/react";
import Modal from "Components/Basic/Modal";
import { useGetAllTeams } from "api/team/getAllTeams";
import { useGetAllUsersInOrganization } from "api/userManagement/getAllUsersInOrganisation";
import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import classNames from "utils/jsUtils/className";
import Collaborators from "./Collaborators";

interface Props {
  addedUserCollaborators: string[] | undefined;
  addedTeams: string[] | undefined;
  onAddCollaborator: (updated: { id: string; type: "team" | "user" }) => void;
  onRemoveCollaborator: (updated: { id: string; type: "team" | "user" }) => void;
  onClose: () => void;
  modalHeader?: JSX.Element | string;
  hasEditAccess?: boolean;
  context: string;
}

/**
 * Generic component for displaying, editing and adding user or team collaborators.
 *
 * @param addedUserCollaborators - An array of the IDs of added user collaborators.
 * @param addedTeams - An array of the IDs of added teams collaborators.
 * @param onAddCollaborator - Function called when a collaborator/team is added.
 * @param onRemoveCollaborator - Function called when a collaborator/team is removed.
 * @param onClose - Function called when the modal is closed.
 * @param modalHeader - (optional) JSX.Element or string displayed in the modals header.
 * @param hasEditAccess - (optional) Boolean determining whether the user should be able to add/delete collaborators. Defaults to `true`.
 * @param context - The context of displaying or modifying collaborators, e.g. `"project"` or `"dashboard"`.
 * @param organizationId = The ID of the organization the user is currently in.
 */
const CollaboratorsModal: React.FC<Props> = ({
  addedUserCollaborators,
  addedTeams,
  onAddCollaborator: onAdd,
  onRemoveCollaborator: onRemove,
  onClose,
  modalHeader,
  hasEditAccess = true,
  context,
}) => {
  const { data: allUsers, fetchMore } = useGetAllUsersInOrganization({ first: 30 });

  useEffect(() => {
    const canFetchMoreUsers = allUsers?.organization.members.pageInfo.hasNextPage;
    const endCursor = allUsers?.organization.members.pageInfo.endCursor;

    if (canFetchMoreUsers && endCursor !== undefined) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => console.error(err));
    }
  }, [
    fetchMore,
    allUsers?.organization.members.pageInfo.endCursor,
    allUsers?.organization.members.pageInfo.hasNextPage,
  ]);
  const { data, error } = useGetAllTeams();
  const allTeams = useMemo(() => {
    if (error) {
      toast.error("There was a problem getting the teams");
    }
    if (!data) return [];

    const tempTeams = data.teams.edges.map((edge) => edge.node);
    return tempTeams;
  }, [data, error]);

  return (
    <Modal noPadding onClose={onClose} className="w-3/5">
      <div className={classNames(modalHeader && "flex-col divide-y", "flex divide-gray-200")}>
        {modalHeader && (
          <div className="flex w-full items-center p-4">
            <UsersThree size={24} className="mr-4" />
            Collaboration
            <CaretRight size={18} className="mx-4" />
            {modalHeader}
          </div>
        )}
        <div className="flex w-full divide-x">
          <Collaborators
            allCollaborators={
              allUsers?.organization.members.edges.map(({ node }) => node) || []
            }
            addedCollaboratorIDs={addedUserCollaborators}
            hasEditAccess={hasEditAccess}
            addCollaborator={(id) => onAdd({ id, type: "user" })}
            removeCollaborator={(id) => onRemove({ id, type: "user" })}
            type="user"
            context={context}
          />
          <Collaborators
            allCollaborators={allTeams}
            addedCollaboratorIDs={addedTeams}
            hasEditAccess={hasEditAccess}
            addCollaborator={(id) => onAdd({ id, type: "team" })}
            removeCollaborator={(id) => onRemove({ id, type: "team" })}
            type="team"
            context={context}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CollaboratorsModal;
