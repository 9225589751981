import { Check } from "@phosphor-icons/react";
import { useGetWorkspaces } from "api/workspace/getWorkspaces";
import React, { useState } from "react";
import Popover from "thermo/popover/Popover";
import { ProjectMoveWorkspaceListItem } from "./ProjectMoveWorkspaceListItem";

interface IMoveProjectToWorkspace {
  updateWorkspaceOfResourceHandler: (workspaceID: string | null) => void;
  projectWorkspaceID: string | null | undefined;
  triggerButton: JSX.Element;
}

export const MoveProjectToWorkspace: React.FC<IMoveProjectToWorkspace> = ({
  updateWorkspaceOfResourceHandler,
  projectWorkspaceID,
  triggerButton,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data } = useGetWorkspaces();

  const workspacesAvailableToBeMovedTo = data?.workspaces
    .filter((ws) => ws.name.toLowerCase().match(searchTerm.toLowerCase()))
    .sort((a) => (a.id === projectWorkspaceID ? 0 : 1));

  return (
    <Popover.Root>
      <Popover.Trigger>{triggerButton}</Popover.Trigger>
      <Popover.Content>
        {(closePopover) => (
          <div className="text-gray-700">
            <input
              placeholder="Move to workspace..."
              className="text-sm mx-0.5 p-2 focus:ring-0 focus:outline-none text-gray-600 rounded-t-md"
              autoFocus
              data-testid="moveWorkspacePopoverInput"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="h-[0.05rem] bg-gray-200" />
            <div className="max-h-60 overflow-y-auto mx-0.5 py-0.5">
              {/* If there is an active workspaceID we allow the user to choose the general workspace */}
              {"General".toLowerCase().match(searchTerm.toLowerCase()) && (
                <button
                  data-testid="moveToWorkspaceSelectButton"
                  onClick={() => {
                    updateWorkspaceOfResourceHandler(null);
                    closePopover();
                  }}
                  className="cursor-pointer whitespace-nowrap w-full rounded-lg text-start flex justify-between items-center px-2 py-1.5 text-sm
                hover:bg-gray-100"
                >
                  <div>General</div>
                  {!projectWorkspaceID && <Check weight="bold" size={15} />}
                </button>
              )}
              <div className="grid grid-cols-1">
                {workspacesAvailableToBeMovedTo?.map((workspace) => (
                  <ProjectMoveWorkspaceListItem
                    key={workspace.id}
                    onClick={(workspaceID) => {
                      updateWorkspaceOfResourceHandler(workspaceID);
                      closePopover();
                    }}
                    workspace={workspace}
                    activeWorkspaceID={projectWorkspaceID}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </Popover.Content>
    </Popover.Root>
  );
};
