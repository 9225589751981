import Badge from "Components/Basic/Badge";
import { getUserColor } from "Components/Basic/UserCircleColorOptions";
import { User } from "api/user/getCurrentUser";
import { Workspace } from "api/workspace/getWorkspaces";
import { useRemoveWorkspaceUser } from "api/workspace/removeWorkspaceUser";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";
import Popover from "thermo/popover/Popover";
import PopoverMenu from "thermo/popoverMenu/PopoverMenu";
import classNames from "utils/jsUtils/className";
import { ArrayElement } from "utils/typeHelpers/ArrayElement";
import TransferOwnerModal from "./TransferOwnerModal";

type Props = {
  workspaceMember: ArrayElement<Workspace["members"]>;
  workspaceID: string;
  loggedInUserIsWorkspaceOwner: boolean;
  loggedInUser: User;
};

const ExistingMemberRow = ({
  workspaceMember,
  workspaceID,
  loggedInUserIsWorkspaceOwner,
  loggedInUser,
}: Props) => {
  const [removeWorkspaceUser] = useRemoveWorkspaceUser();

  const [showTransferOwnershipConfirmModal, setShowTransferOwnershipConfirmModal] =
    useState(false);

  const workspaceUser = workspaceMember.user;
  const isCurrentUser = loggedInUser.id === workspaceUser?.id;

  if (!workspaceUser) {
    return <div className="text-sm text-gray-600">User could not be found</div>;
  }
  const circleColors = getUserColor(workspaceUser);

  return (
    <div data-testid="existingWorkspaceMemberRow" key={workspaceUser.id}>
      <div className="flex items-center text-sm">
        <div className="flex w-4/6 items-center">
          <div
            className={classNames(
              circleColors.backgroundColor,
              circleColors.textColor,
              "uppercase mr-4 flex h-7 w-7 items-center justify-center rounded-full text-sm font-medium"
            )}
          >
            {workspaceUser.firstName.slice(0, 1) + workspaceUser.lastName.slice(0, 1)}
          </div>
          <div className="flex flex-col items-start">
            <div>{workspaceUser.fullName}</div>
            <div className="truncate text-slate-600">{workspaceUser.mail}</div>
          </div>
        </div>

        <Badge
          text={
            workspaceMember.role.substring(0, 1) +
            workspaceMember.role.substring(1).toLowerCase()
          }
          backgroundColor="bg-gray-100"
          textColor="text-gray-600"
          hideDot
          className="first-letter:capitalize"
        />
        <div className="flex w-1/6 justify-end">
          {loggedInUserIsWorkspaceOwner && !isCurrentUser && (
            <PopoverMenu>
              <Popover.Button onClick={() => setShowTransferOwnershipConfirmModal(true)}>
                Transfer ownership
              </Popover.Button>
              <Popover.Button
                className="text-red-500"
                onClick={() =>
                  removeWorkspaceUser({
                    variables: {
                      userID: workspaceUser.id,
                      workspaceID,
                    },
                  }).catch((err) => {
                    toast.error(err.message);
                    console.error(err);
                  })
                }
              >
                Remove
              </Popover.Button>
            </PopoverMenu>
          )}
        </div>
      </div>
      <AnimatePresence>
        {showTransferOwnershipConfirmModal && (
          <TransferOwnerModal
            closeModal={() => setShowTransferOwnershipConfirmModal(false)}
            newOwnerId={workspaceUser.id}
            workspaceId={workspaceID}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ExistingMemberRow;
