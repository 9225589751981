import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const DELETE_FILE = graphql(`
  mutation FILE_DELETE($fileId: ID!) {
    deleteFile(fileId: $fileId)
  }
`);

export const useDeleteFile = () => {
  const mutation = useMutation(DELETE_FILE);
  return mutation;
};
