import { useMutation } from "@apollo/client";
import { graphql } from "GraphQL/gql";

const REMOVE_TEAM_FROM_PROJECT_MUTATION = graphql(`
  mutation REMOVE_TEAM_FROM_PROJECT_MUTATION($projectId: ID!, $teamId: ID!) {
    projectRemoveTeam(projectId: $projectId, teamId: $teamId)
  }
`);

export const useRemoveTeamFromProject = () => {
  const mutation = useMutation(REMOVE_TEAM_FROM_PROJECT_MUTATION); // TODO Requires an update function once we fetch teams on a project through GraphQL

  return mutation;
};
