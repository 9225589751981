import { MagnifyingGlass } from "@phosphor-icons/react";
import React from "react";

type Props = {
  searchValue: string;
  setSearchValue: (newSearchValue: string) => void;
  placeholder: string;
};

export const SearchBar = ({ searchValue, setSearchValue, placeholder }: Props) => {
  return (
    <div className="relative flex rounded-md border border-gray-300 bg-white max-h-[30px]">
      <span className="inline-flex items-center pl-2 text-gray-600 sm:text-sm">
        <MagnifyingGlass className="text-gray-600" size={16} />
      </span>
      <input
        data-testid="searchBarInput"
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        className="block w-40 min-w-0 flex-1 px-2 py-1 rounded-none rounded-r-md border-none text-sm text-gray-600 focus:ring-0"
        placeholder={placeholder}
      />
    </div>
  );
};
