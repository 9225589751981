const THERMO_PROPERTY = ["bg", "text", "border", "ring", "fill"] as const;
type ThermoProperty = (typeof THERMO_PROPERTY)[number];

const THERMO_NON_STRENGTH_COLOR = [
  "black",
  "white",
  "transparent",
  "current",
  "green-numerous",
] as const;
type NonStrengthColor = (typeof THERMO_NON_STRENGTH_COLOR)[number];

const THERMO_COLOR = [
  "indigo",
  "purple",
  "rose",
  "fuchsia",
  "red",
  "yellow",
  "orange",
  "lightGreen",
  "green",
  "cyan",
  "teal",
  "blue",
  "slate",
  "gray",
  "trueGray",
  "primary",
  "success",
  "warning",
  "error",
] as const;
type ThermoColor = (typeof THERMO_COLOR)[number];

const THERMO_STRENGTH = [
  "50",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
  "950",
] as const;
type ThermoStrength = (typeof THERMO_STRENGTH)[number];

type ThermoPropertyWithColorAndStrength = `${ThermoProperty}-${ThermoColor}-${ThermoStrength}`;
type ThermoPropertyWithNonStrengthColor = `${ThermoProperty}-${NonStrengthColor}`;
type ThermoColorWithStrength = `${ThermoColor}-${ThermoStrength}`;
type ThermoColorScheme = ThermoColor | NonStrengthColor;
type ThermoColorWithStrengthAndNonStrengthColor = ThermoColorWithStrength | NonStrengthColor;

const isThermoColor = (color: string): color is ThermoColorScheme =>
  [...THERMO_COLOR, ...THERMO_NON_STRENGTH_COLOR].includes(color as ThermoColorScheme);

export type {
  ThermoPropertyWithColorAndStrength,
  ThermoPropertyWithNonStrengthColor,
  ThermoColorWithStrength,
  ThermoColorScheme,
  ThermoColorWithStrengthAndNonStrengthColor,
};

export { isThermoColor };
