import { CaretRight } from "@phosphor-icons/react";
import { Team, TeamMember as TeamMemberType } from "api/team/getAllTeams";
import React, { useState } from "react";
import TeamMember from "./TeamMember";

interface Props {
  collaborator: Team;
  hasEditAccess: boolean;
  onRemove: () => void;
}

const TeamCollaborator: React.FC<Props> = ({ collaborator, hasEditAccess, onRemove }) => {
  const [showMembers, setShowMembers] = useState(false);

  const allTeamMembers = [...collaborator.members, collaborator.owner as TeamMemberType];

  return (
    <>
      <div
        data-testid="teamCollaborator"
        onClick={() => setShowMembers((prev) => !prev)}
        className="flex border-collapse cursor-pointer items-center justify-between py-3 px-5"
      >
        <div key={collaborator.id} className="flex flex-col">
          <span className="text-sm font-medium uppercase leading-5 text-gray-700">
            {collaborator.name}
          </span>
        </div>
        <div className="flex items-center">
          {hasEditAccess && (
            <button
              data-testid="removeCollaboratingTeam"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              className="text-sm font-medium leading-4 text-red-400 hover:text-red-600"
            >
              Remove
            </button>
          )}
          <CaretRight
            className={`ml-6 h-4 w-4 text-gray-500 transition-all ${
              showMembers && "rotate-90"
            }`}
          />
        </div>
      </div>
      {showMembers &&
        allTeamMembers.map((member) => (
          <TeamMember
            key={member.id}
            member={member}
            isOwner={
              collaborator.owner.__typename === "User" && member.id === collaborator.owner.id
            }
          />
        ))}
    </>
  );
};

export default TeamCollaborator;
