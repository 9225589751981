import { useQuery } from "@apollo/client";
import { graphql } from "GraphQL/gql";

export const USER_SIGN_UP_ORGANIZATIION_INVITATION = graphql(`
  query USER_SIGN_UP_ORGANIZATIION_INVITATION($invitationToken: String!) {
    userSignUpOrganizationInvitation(invitationToken: $invitationToken) {
      __typename
      ... on UserSignUpOrganizationInvitation {
        __typename
        email
      }
      ... on UserSignUpOrganizationInvitationError {
        __typename
        invitationToken
      }
    }
  }
`);

type Props = {
  invitationToken?: string;
};

export const useUserSignUpOrganizationInvitation = ({ invitationToken }: Props) => {
  const result = useQuery(USER_SIGN_UP_ORGANIZATIION_INVITATION, {
    skip: !invitationToken,
    variables: {
      invitationToken: invitationToken || "",
    },
  });

  return result;
};
