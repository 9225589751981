import { File } from "@phosphor-icons/react";
import MDEditor from "@uiw/react-md-editor";
import CodeEditor from "Components/Systems/editSystem/pythonCode/CodeEditor";
import { RepositoryFile } from "api/repository/getDirectory";
import { Buffer } from "buffer";
import { useLayoutEffect, useRef, useState } from "react";
import ScrollShadow from "thermo/elevation/ScrollShadow";
import Text from "thermo/typography/Typography";

const getFilePath = (filePath: string | undefined, shouldCompressPath: boolean) => {
  if (!filePath) return undefined;

  const directoryPath = filePath.split("/").slice(0, -1);
  if (shouldCompressPath) return `.../${directoryPath.at(-1)}/`;
  const isRootFile = !filePath.includes("/");
  return isRootFile ? undefined : `${directoryPath.join("/")}/`;
};

const getFileExtension = (file: string) => {
  return file.slice(file.lastIndexOf(".") + 1);
};

const getCodeLanguage = (fileExtension: string) => {
  switch (fileExtension) {
    case "py":
      return "python";
    case "js":
    case "jsx":
      return "javascript";
    case "ts":
    case "tsx":
      return "javascript";
    case "md":
      return "markdown";
    case "json":
      return "json";
    default:
      return "";
  }
};

const decodeBase64 = (string: string) => Buffer.from(string, "base64").toString();

const COMPRESS_THRESHOLD = 8.1;

interface Props {
  file: RepositoryFile | undefined;
}

const FileViewer = ({ file }: Props) => {
  const [shouldCompressPath, setShouldCompressPath] = useState(false);
  const [markdownWidth, setMarkdownWidth] = useState(800);
  const containerRef = useRef<HTMLDivElement>(null);

  const filePath = getFilePath(file?.path, shouldCompressPath);
  const fileLanguage = getCodeLanguage(getFileExtension(file?.name || ""));
  const parsedContent = decodeBase64(file?.content || "");

  useLayoutEffect(() => {
    if (!containerRef.current) return undefined;

    const setPathCompression = () => {
      if (!containerRef.current || !file) return;
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const pathCharacterLength = file.path.length + file.name.length;

      if (containerWidth / pathCharacterLength < COMPRESS_THRESHOLD)
        setShouldCompressPath(true);
      else setShouldCompressPath(false);
    };

    setMarkdownWidth(containerRef.current.getBoundingClientRect().width);
    setPathCompression();
    window.addEventListener("resize", setPathCompression);

    return () => window.removeEventListener("resize", setPathCompression);
  }, [file]);

  if (!file) return null;

  return (
    <div className="p-3">
      <div
        ref={containerRef}
        className="bg-white px-4 py-2 rounded-t border border-gray-200 flex items-center gap-2"
      >
        <File weight="regular" className="text-gray-500" />
        <div className="flex items-center">
          {filePath && <Text.Base className="text-gray-400">{filePath}</Text.Base>}
          <Text.Base>{file.name}</Text.Base>
        </div>
      </div>
      <div className="bg-gray-100 border border-t-0 p-1 border-gray-200 rounded-b">
        {fileLanguage !== "markdown" && (
          <CodeEditor
            classNames="h-[80vh] m-1"
            defaultCode={parsedContent}
            language={fileLanguage}
            readOnly
            minimap
          />
        )}
        {fileLanguage === "markdown" && (
          <ScrollShadow>
            <div
              className="bg-white h-[80vh] overflow-auto border border-gray-200 rounded"
              style={{
                width: markdownWidth - 10,
                maxWidth: "100%",
              }}
              data-color-mode="light"
            >
              <MDEditor.Markdown
                className="bg-white w-full py-3 px-5 text-gray-700"
                source={parsedContent}
              />
            </div>
          </ScrollShadow>
        )}
      </div>
    </div>
  );
};

export default FileViewer;
