import React, { ReactNode } from "react";
import classNames from "utils/jsUtils/className";

type Props = {
  textColor: string;
  backgroundColor: string;
  text: string | React.ReactNode;
  hideDot?: true;
  fontWeight?: "normal" | "medium";
  className?: string;
  icon?: ReactNode;
};

const Badge = ({
  textColor,
  backgroundColor,
  text,
  hideDot,
  fontWeight = "medium",
  className,
  icon,
  ...htmlProps
}: Props) => (
  <span
    {...htmlProps}
    className={classNames(
      `inline-flex first-letter:capitalize ${textColor} items-center rounded-xl px-2 py-0.5 text-sm font-${fontWeight} ${backgroundColor}`,
      className
    )}
  >
    {!hideDot && (
      <svg className="mr-1.5 h-2 w-2" viewBox="0 0 8 8">
        <circle cx={4} cy={4} r={3} />
      </svg>
    )}
    {icon && <span className="mr-1">{icon}</span>}
    {text}
  </span>
);

export default Badge;
