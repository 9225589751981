import { useField, FieldHookConfig } from "formik";
import { motion } from "framer-motion";
import React from "react";
import classNames from "utils/jsUtils/className";

interface Props {
  label?: string;
  readOnly?: boolean;
  className?: string;
  errorOnBorder?: true;
}

/**
 * A text input component for use in formik forms.
 *
 * @property {string} label - (optional) Label displayed above input field
 * @property {boolean} readOnly - (optional) Set to true for readOnly text input fields
 * @property {string} className - (optional) Classnames added to the input element
 */
const TextInput = ({
  label,
  className,
  errorOnBorder,
  ...props
}: Props & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);

  return (
    <div>
      {label && (
        <label
          data-testid="formLabel"
          className="block text-sm font-medium text-gray-700"
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
      )}
      <input
        readOnly={props.readOnly}
        type={props.type}
        className={classNames(
          `block w-full appearance-none disabled:text-gray-500 disabled:cursor-not-allowed h-9 rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`,
          className,
          props.readOnly && "bg-gray-50",
          errorOnBorder &&
            meta.error &&
            "border-red-400 ring-2 ring-red-400 focus:border-red-400 focus:ring-red-400"
        )}
        {...field}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        data-testid="formTextInput"
      />
      {!errorOnBorder && meta.touched && meta.error ? (
        <motion.div
          initial={{ y: -4, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          data-testid="formErrorMessage"
          className="mt-1 text-sm font-medium text-red-700"
        >
          {meta.error}
        </motion.div>
      ) : null}
    </div>
  );
};

export default TextInput;
