import { useUserRole } from "@/api/useFirebase";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useFallbackNavigate } from "./hooks/fallbackNavigate";

type ConditionKey = keyof ReturnType<typeof useUserRole>;

interface Props {
  condition: ConditionKey;
}

export const Protected = ({ condition }: Props) => {
  const conditions = useUserRole();
  const fallback = useFallbackNavigate();

  if (!conditions[condition]) {
    console.error(`The user does not have access at: ${window.location.href}`);
    return <Navigate to={fallback} replace />;
  }

  return <Outlet />;
};
