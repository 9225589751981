import { CaretDown, CaretUp } from "@phosphor-icons/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigateApp } from "Components/AppNavigation/useNavigateApp";
import LoadingIcon from "Components/Basic/LoadingIcon/LoadingIcon";
import { SimulationScenario } from "model/datatypes";
import { useState } from "react";
import classNames from "utils/jsUtils/className";

interface IScenarioTable {
  columns: ColumnDef<any, any>[];
  data: {
    id: string;
    scenarioName: string;
    dataOutput: SimulationScenario["data_available"];
    reports: SimulationScenario;
    progress: SimulationScenario;
    lastChanged: Date | undefined;
    lastChangedBy: string;
    scenarioObj: SimulationScenario;
    label?: SimulationScenario["label"];
  }[];
}

const ScenarioTable = ({ columns, data }: IScenarioTable) => {
  const navigate = useNavigateApp();
  const [sorting, setSorting] = useState<SortingState>([{ id: "lastChanged", desc: true }]);

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <table
      data-testid="scenarioSortingTable"
      className="w-full table-fixed divide-y divide-gray-300"
    >
      <thead className="sticky top-0 z-10 bg-gray-100 shadow-sm">
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="h-10 w-full">
            {headerGroup.headers.map((header) => (
              <th
                onClick={header.column.getToggleSortingHandler()}
                key={header.id}
                title={`Sort ${header.id}`}
                className={classNames(
                  header.id === "scenarioName" && "w-4/12",
                  header.id === "dataOutput" && "w-2/12",
                  header.id === "reports" && "w-2/12",
                  header.id === "progress" && "w-2/12",
                  header.id === "lastChanged" && "w-2/12",
                  header.id === "lastChangedBy" && "w-2/12",
                  header.id === "rowActions" && "w-1/12",
                  "sticky whitespace-nowrap px-4 py-2 text-left text-sm font-medium uppercase tracking-wider text-gray-700",
                  header.column.getCanSort() && "cursor-pointer select-none"
                )}
              >
                <div className="flex flex-row">
                  <span data-testid={header.id}>
                    {header.id === "rowActions"
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                  {{
                    asc: (
                      <CaretUp
                        weight="bold"
                        data-testid="sortArrow"
                        className={classNames(
                          "ml-2 h-4 w-4 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                        )}
                      />
                    ),
                    desc: (
                      <CaretDown
                        weight="bold"
                        data-testid="sortArrow"
                        className={classNames(
                          "ml-2 h-4 w-4 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                        )}
                      />
                    ),
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              </th>
            ))}
            <th className="w-auto" aria-label="Empty header" />
          </tr>
        ))}
      </thead>

      <tbody className="border-collapse divide-y divide-gray-200 overflow-visible bg-white">
        {getRowModel().rows.map((row) => {
          return row ? (
            <tr
              data-testid="scenarioSortingTableRow"
              key={row.original.id}
              id={row.original.id}
              onClick={(e) => {
                if ((e.target as HTMLElement).id === "numerous-modal") return;
                const isMiddleButtonClick = e.type === "auxclick" && e.button === 1;
                const isCtrlOrCmdClick = e.button === 0 && (e.metaKey || e.ctrlKey);
                if (isMiddleButtonClick || isCtrlOrCmdClick)
                  navigate(row.original.id, {
                    appendToCurrentPath: true,
                    openInNewTab: true,
                  });
                else navigate(`${row.original.id}`, { appendToCurrentPath: true });
              }}
              className="h-12 cursor-pointer hover:bg-gray-50"
            >
              {row.getAllCells().map((cell) => {
                return (
                  <td
                    key={cell.row.id + cell.column.id}
                    className={`${classNames(
                      "w-max py-2 pl-4 text-sm text-gray-600",
                      cell.column.id !== "reports" ? "overflow-visible" : "overflow-x-auto"
                    )}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ) : (
            <td>
              <span className="italic">
                <LoadingIcon />
              </span>
            </td>
          );
        })}
      </tbody>
    </table>
  );
};

export default ScenarioTable;
