import { Button } from "Components/Basic/Buttons";
import Dropdown from "Components/Basic/Dropdown/Dropdown";
import { Input, InputNumber } from "Components/Basic/Input";
import Modal from "Components/Basic/Modal";
import RadioButton from "Components/Basic/RadioButton";
import { TagInline } from "Components/Basic/TagInline";
import TagSelector from "Components/Basic/TagSelector";
import ToggleButton from "Components/Basic/ToggleButton";
import { useFileTags } from "api/useFirebase";
import { ComponentParamType, ComponentTypeReference, FileQuery } from "model/datatypes";
import React, { useState } from "react";
import Popover from "thermo/popover/Popover";
import classNames from "utils/jsUtils/className";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal } from "utils/jsUtils/imutableArray";

export const ReferenceSelecter: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
  allowedParamRefs: ComponentTypeReference[];
}> = ({ parameter, onUpdate, allowedParamRefs }) => {
  const options = allowedParamRefs.map((ref) => ({
    id: ref.id,
    display: ref.displayName,
  }));
  return (
    <Dropdown
      className="bg-white"
      options={options}
      onSelect={(dropdownOption) => {
        onUpdate({ ...parameter, value: dropdownOption.id });
      }}
      selectedID={parameter.value === null ? "none" : (parameter.value as string)}
      placeholder={
        options.length > 0 ? "Select component to reference" : "No allowed references"
      }
      emptyMsg="No allowed referenes found"
    />
  );
};

const Tag: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={classNames("mx-1 my-1 flex items-center rounded-lg px-1 text-white", className)}
  >
    {children}
  </div>
);

const FileTagSelecter: React.FC<{
  query: FileQuery;
  updateQuery: (newQuery: FileQuery) => void;
  modelRef: FileQuery["modelRef"];
  onClose: () => void;
}> = ({ query, updateQuery, onClose, modelRef }) => {
  const { tags, types } = useFileTags();

  const renderType = (type: string) => {
    if (type.trim().length < 1) return null;
    const isSelected = type === query.type;
    return (
      <div
        key={type}
        className={`mx-1 my-1 cursor-pointer rounded border-2 border-blue-600 px-3 py-1 text-sm shadow ${
          isSelected ? "bg-blue-600 text-white" : "text-gray-600"
        }`}
        onClick={() => {
          const newQuery = { ...query };
          if (isSelected) {
            delete newQuery.type;
          } else {
            newQuery.type = type;
          }
          updateQuery(newQuery);
        }}
      >
        {type}
      </div>
    );
  };

  return (
    <div className="px-4 py-4 text-sm">
      <div className="font-medium">Search for tags (OR)</div>
      <div className="mb-2 w-full">
        <TagSelector
          tags={tags}
          selectedTagIDs={query.tags}
          selectTag={(tag) => updateQuery({ ...query, tags: [...query.tags, tag] })}
          removeTag={(tag) =>
            updateQuery({ ...query, tags: query.tags.filter((t) => t !== tag) })
          }
        />
        <div className="flex flex-row flex-wrap">
          {query.tags.map((tag) => (
            <TagInline
              key={tag}
              tag={tag}
              removeTag={(tagToRemove) =>
                updateQuery({ ...query, tags: query.tags.filter((t) => t !== tagToRemove) })
              }
            />
          ))}
        </div>
      </div>
      <div className="font-medium">Filter by type</div>
      <div className="mb-2 flex flex-wrap">{types.map(renderType)}</div>
      {modelRef && (
        <>
          <div className="font-medium">Hide global files</div>
          <ToggleButton
            active={!!query.modelRef}
            onChange={() => {
              const newQuery = { ...query };

              if (query.modelRef) {
                delete newQuery.modelRef;
              } else {
                newQuery.modelRef = modelRef;
              }

              updateQuery(newQuery);
            }}
          />
        </>
      )}
      <Button buttonType="white" className="mt-2 w-full" onClick={onClose}>
        Close
      </Button>
    </div>
  );
};

export const FileQuerySelecter: React.FC<{
  parameter: ComponentParamType;
  modelRef: FileQuery["modelRef"];
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ parameter, onUpdate, modelRef }) => (
  <Popover.Root placement="bottom">
    <Popover.Trigger>
      <div className="relative flex w-full min-h-[1.75rem] cursor-pointer flex-wrap items-center rounded border border-gray-200 bg-white text-sm shadow">
        {parameter.fileQuery?.projectRef && (
          <Tag className={` bg-yellow-600`}>{parameter.fileQuery.projectRef.displayName}</Tag>
        )}
        {parameter.fileQuery?.type && (
          <Tag className={` bg-blue-500`}>{parameter.fileQuery.type}</Tag>
        )}
        {parameter.fileQuery?.modelRef && (
          <Tag className={` bg-purple-500`}>{parameter.fileQuery.modelRef.displayName}</Tag>
        )}
        {parameter.fileQuery?.tags.map((tag) => (
          <TagInline key={tag} tag={tag} />
        ))}
        {(!parameter.fileQuery ||
          (parameter.fileQuery.tags.length <= 0 && !parameter.fileQuery?.type)) && (
          <div className="w-full text-center text-sm">Configure</div>
        )}
      </div>
    </Popover.Trigger>
    <Popover.Content>
      {(closePopover) => (
        <FileTagSelecter
          modelRef={modelRef}
          query={parameter.fileQuery || { tags: [] }}
          updateQuery={(updatedQuery) =>
            onUpdate({ ...parameter, fileQuery: updatedQuery, value: null })
          }
          onClose={closePopover}
        />
      )}
    </Popover.Content>
  </Popover.Root>
);

export const ParamEditMode: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ parameter, onUpdate }) => {
  let options = ["normal", "fixed", "advanced", "hidden"].map((o) => ({ id: o, display: o }));
  if (parameter.type === "reference") {
    //only allow selecting hidden for reference parameters
    options = options.filter((o) => o.id === "hidden");
  }
  const selectedID = parameter.displayMode ? parameter.displayMode : "normal";
  return (
    <Dropdown
      className="bg-white"
      closeOnDisappear
      options={options}
      onSelect={(option) => {
        onUpdate({
          ...parameter,
          displayMode: option.id as "normal" | "fixed" | "hidden" | "advanced",
        });
      }}
      selectedID={selectedID}
    />
  );
};

export const SelecterParamSetup: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ onUpdate, parameter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedSelectedID, setEditedSelectedID] = useState(parameter.tag);
  const [editedOptions, setEditedOptions] = useState(parameter.selecterOptions || []);
  const options = parameter.selecterOptions;
  const [type, setType] = useState<"number" | "string">(
    options && options.length > 0 && typeof options[0].value === "string" ? "string" : "number"
  );

  const updateDataType = (newType: "number" | "string") => {
    //transform the data if parameters
    setEditedOptions(
      editedOptions.map((option) => {
        let val = option.value;
        if (newType === "number" && typeof val !== "number") val = parseFloat(val);
        else if (typeof val !== "string") val = val.toString();
        return {
          ...option,
          value: val,
        };
      })
    );
    //update type
    setType(newType);
  };

  return (
    <>
      <Button
        buttonType="white"
        onClick={() => {
          setEditedSelectedID(parameter.tag);
          setEditedOptions(parameter.selecterOptions || []);
          setIsOpen(true);
        }}
        className="w-full"
      >
        Edit options
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} className="z-30 w-2/3 lg:w-1/2">
        <div className="font-medium">Edit options</div>
        <div className="text-sm font-medium">Data type</div>
        <Dropdown
          className="text-sm"
          selectedID={type}
          options={[
            { id: "string", display: "string" },
            { id: "number", display: "number" },
          ]}
          onSelect={(option) => {
            if (option.id !== type) updateDataType(option.id as "string" | "number");
          }}
        />
        <div className="mt-4 flex border-b border-gray-200 text-sm font-bold">
          <div className="w-1/3 pr-2">Display</div>
          <div className="w-1/3 px-2">Value</div>
          <div className="w-1/6 px-2">Default</div>
          <div className="w-1/6 pl-2 text-right">Remove</div>
        </div>
        {editedOptions.map((option) => (
          <div className="mt-2 flex text-sm" key={option.id}>
            <div className="w-1/3 pr-2">
              <Input
                className="w-full"
                value={option.display}
                onChange={(e) => {
                  const editedOption = { ...option, display: e.target.value };
                  setEditedOptions(updateArrayVal(editedOptions, editedOption));
                }}
              />
            </div>
            <div className="w-1/3 px-2">
              {type === "number" ? (
                <InputNumber
                  className="w-full"
                  value={option.value as number}
                  onUpdate={(updated) => {
                    const editedOption = { ...option, value: updated };
                    setEditedOptions(updateArrayVal(editedOptions, editedOption));
                  }}
                />
              ) : (
                <Input
                  className="w-full"
                  value={option.value}
                  onChange={(e) => {
                    const editedOption = { ...option, value: e.target.value };
                    setEditedOptions(updateArrayVal(editedOptions, editedOption));
                  }}
                />
              )}
            </div>
            <div className="flex  w-1/3 justify-between pl-2">
              <RadioButton
                active={editedSelectedID === option.id}
                onClick={() => {
                  setEditedSelectedID(option.id);
                }}
              />
              <Button
                buttonType="white"
                onClick={() => {
                  setEditedOptions(editedOptions.filter((o) => o.id !== option.id));
                  if (editedSelectedID === option.id) setEditedSelectedID(undefined);
                }}
              >
                -
              </Button>
            </div>
          </div>
        ))}
        <div className="mt-2">
          <Button
            buttonType="white"
            onClick={() => {
              setEditedOptions([...editedOptions, { id: getUUID(), display: "", value: "" }]);
            }}
          >
            + Add option
          </Button>
        </div>
        <div className="mt-4 flex ">
          <Button
            buttonType="white"
            onClick={() => {
              const selectedOption = editedOptions.find((o) => o.id === editedSelectedID);
              onUpdate({
                ...parameter,
                tag: editedSelectedID,
                selecterOptions: editedOptions,
                value: selectedOption?.value || null,
                displayValue: selectedOption?.display,
              });

              setIsOpen(false);
            }}
            className="mr-2 flex-1"
          >
            Save updated
          </Button>
          <Button
            buttonType="white"
            onClick={() => {
              setIsOpen(false);
            }}
            className="ml-2 flex-1"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};
